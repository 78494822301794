import * as React from 'react';
import { generatePath } from 'react-router-dom';
import { paths } from 'routes';

import { CRow, CSmartTable } from '@coreui/react-pro';

import { Customer } from 'types/Customer';
import { Estimate } from 'types/Estimate';

import { compactDateDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

import { ActionsMenu } from 'components/ActionsMenu';
import { Pill } from 'components/Pill';

interface Props {
  estimates?: Estimate[];
  customer?: Customer;
}

export const EstimatesList: React.FC<Props> = ({ estimates, customer }) => {
  return (
    <CRow className="mb-3">
      <CSmartTable
        tableHeadProps={{ color: 'dark' }}
        items={estimates}
        tableProps={{ 'aria-label': 'Estimates' }}
        columns={[
          { key: 'id', label: 'ID' },
          { key: 'created_at', label: 'Estimate Date' },
          'name',
          'status',
          'total',
          'actions'
        ]}
        scopedColumns={{
          id: (estimate: Estimate) => <td>#{estimate.id}</td>,
          created_at: (estimate: Estimate) => <td>{compactDateDisplay(estimate.created_at)}</td>,
          status: (estimate: Estimate) => (
            <td>
              <Pill label={estimate.status} />
            </td>
          ),
          total: (estimate: Estimate) => <td>{estimate.total && toCurrency(estimate.total)}</td>,
          actions: (estimate: Estimate) => (
            <td>
              <ActionsMenu
                items={[{ label: 'View', href: generatePath(paths.estimateDetails, { id: estimate.id }) }]}
              />
            </td>
          )
        }}
      />
    </CRow>
  );
};
