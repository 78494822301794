import { add, parseISO } from 'date-fns';

import { Employee } from './Employee';
import { HealthPlan } from './HealthPlan';
import { Product } from './Product';

export type AnimalHealthPlan = {
  animal_id: number;
  fulfilled_at?: string;
  fulfilled_by_id?: number;
  fulfilled_by_type?: string;
  fulfilled_by?: Product;
  health_plan: HealthPlan;
  health_plan_id: number;
  id: number;
  next_due_date?: string;
  internal_notes?: string;
  patient_notes?: string;
  status: HealthPlanStatus;
  created_at: string;
  updated_at: string;
  updated_by_employee: Employee | null;
};
export const healthPlanStatuses = ['active', 'disabled', 'fulfilled'] as const;
export type HealthPlanStatus = (typeof healthPlanStatuses)[number];

export function isDue(animalHealthPlan: AnimalHealthPlan): boolean {
  if (!animalHealthPlan.next_due_date) {
    return false;
  } else {
    const future = add(new Date(), { days: 30 });
    const dueDate: Date = parseISO(animalHealthPlan.next_due_date);
    const isDue = dueDate > new Date() && dueDate < future;
    return isDue;
  }
}

export function isPastDue(animalHealthPlan: AnimalHealthPlan): boolean {
  if (!animalHealthPlan.next_due_date) {
    return false;
  } else {
    const dueDate: Date = parseISO(animalHealthPlan.next_due_date);
    const isDue = dueDate < new Date();
    return isDue;
  }
}
