import * as React from 'react';
import type { SVGProps } from 'react';

const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 16" {...props}>
    <path
      fill="currentColor"
      d="M13.495 3.908H2.117c-.302 0-.591.123-.805.342-.213.22-.333.517-.333.827V6.83c0 .31.12.607.333.826.214.22.503.343.805.343h.517v5.846c0 .465.18.91.5 1.24s.755.513 1.208.514h6.83c.453 0 .887-.185 1.207-.514.32-.33.5-.775.501-1.24V8h.615c.302 0 .591-.124.805-.343.213-.22.333-.516.333-.826V5.077c0-.31-.12-.607-.333-.827a1.13 1.13 0 0 0-.805-.342m-1.753 9.938c0 .155-.06.304-.167.413a.56.56 0 0 1-.403.172h-6.83a.56.56 0 0 1-.403-.172.6.6 0 0 1-.167-.413V8h7.97zm.569-7.015H2.117V5.077h11.378V6.83zM4.7 2.738v-.584c0-.465.18-.911.5-1.24S5.955.4 6.408.4h2.505c.453 0 .887.185 1.207.514s.5.775.5 1.24v.584a.6.6 0 0 1-.17.406.56.56 0 0 1-.797 0 .6.6 0 0 1-.171-.406v-.584a.6.6 0 0 0-.167-.414.56.56 0 0 0-.402-.17H6.408a.56.56 0 0 0-.402.17.6.6 0 0 0-.167.414v.584c0 .155-.06.304-.167.414a.56.56 0 0 1-.805 0 .6.6 0 0 1-.167-.414m.814 9.946L5.48 9.761a.6.6 0 0 1 .162-.415.56.56 0 0 1 .805-.01c.108.107.17.255.172.41l.035 2.923a.6.6 0 0 1-.162.416.56.56 0 0 1-.805.01.6.6 0 0 1-.172-.41m3.416 0-.035-2.923a.6.6 0 0 1 .161-.415.56.56 0 0 1 .805-.01c.108.107.17.255.172.41l.035 2.923a.6.6 0 0 1-.162.416.56.56 0 0 1-.805.01.6.6 0 0 1-.171-.41"
    />
  </svg>
);
export default SvgTrash;
