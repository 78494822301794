import * as React from 'react';
import { useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';
import { EstimateDocument } from 'views/documents/EstimateDocument';

import { CButton, CRow, CSmartTable } from '@coreui/react-pro';

import { fetchFinancialConsults } from 'api/Consults';
import { createDocument, updateDocument } from 'api/Documents';
import { createEstimate, fetchEstimate, updateEstimate } from 'api/Estimates';

import { ActionsMenuItem } from 'types/ActionsMenuItem';
import { Consult } from 'types/Consult';
import { Customer } from 'types/Customer';
import { Estimate } from 'types/Estimate';
import { ListFilter } from 'types/ListFilter';
import { calculatePages, Pagination } from 'types/Pagination';

import { useKey } from 'hooks/useKey';

import { compactDateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

import SvgPencil from 'assets/images/SvgPencil';
import SvgPlus from 'assets/images/SvgPlus';
import SvgViewConversation from 'assets/images/SvgViewConversation';

import { ActionsMenu } from 'components/ActionsMenu';
import { EstimateForm } from 'components/EstimateForm';
import Filters from 'components/Filters';
import { IconButton } from 'components/IconButton';
import { Pill } from 'components/Pill';

type Form = 'new' | 'edit';

type Props = {
  customer: Customer;
  estimates?: Estimate[];
  pagination: Pagination;
  updatePage?: (page: number) => void;
  fetchEstimates: () => void;
  filters: ListFilter[];
  handleFilterChange: (filters: ListFilter[]) => void;
  scrollUp: () => void;
};

export const EstimatesList = ({
  fetchEstimates,
  estimates,
  customer,
  pagination,
  updatePage,
  filters,
  handleFilterChange,
  scrollUp
}: Props) => {
  const [visibleForm, setVisibleForm] = useState<Form>();
  const [estimate, setEstimate] = useState<Partial<Estimate>>();
  const [consults, setConsults] = useState<Consult[]>();

  const [formKey, remountForm] = useKey();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFinancialConsults({ params: { customer_id: customer.id }, onSuccess: setConsults });
  }, [customer]);

  const hideForm = () => {
    setVisibleForm(undefined);
  };

  const initializeEstimate = () => {
    const initializedEstimate = {
      animal_id: customer.animals?.length === 1 ? customer.animals[0].id : undefined,
      client_id: customer.id,
      effective_date: new Date().toISOString(),
      urgent_care: false,
      status: 'pending'
    } as Partial<Estimate>;

    setEstimate(initializedEstimate);
  };

  const handleNewEstimateClick = () => {
    remountForm();
    scrollUp();

    initializeEstimate();
    setVisibleForm('new');
  };

  const handleEditEstimateClick = (item: Estimate) => () => {
    remountForm();
    scrollUp();

    fetchEstimate(item.id, (fullEstimate) => {
      setEstimate(fullEstimate);
      setVisibleForm('edit');
    });
  };

  const handleCreateEstimateSuccess = (estimate: Partial<Estimate>) => {
    hideForm();
    toast.success('Estimate created!');
    fetchEstimates();
  };

  const handleUpdateEstimateSuccess = (estimate: Partial<Estimate>) => {
    hideForm();
    toast.success('Estimate updated!');
    fetchEstimates();
  };

  const handleCreateEstimate = async () => {
    if (!estimate) return;
    return createEstimate(estimate, handleCreateEstimateSuccess);
  };

  const handleUpdateEstimate = async () => {
    if (!estimate?.id) return;

    return updateEstimate(
      estimate.id,
      { ...estimate, estimate_items_attributes: estimate.estimate_items },
      { onSuccess: handleUpdateEstimateSuccess }
    );
  };

  const actionsMenuItems = (item: Estimate) => {
    return [
      {
        label: 'Edit',
        icon: <SvgPencil className="primaryColor" />,
        onClick: handleEditEstimateClick(item)
      },
      {
        label: 'View',
        href: generatePath(paths.estimateDetails, { id: item.id })
      },
      item.consult_id && {
        label: 'View Consult',
        icon: <SvgViewConversation className="primaryColor" />,
        href: `/consults/${item.consult_id}?step=finances`
      },
      item.status !== 'rejected' &&
        item.status !== 'approved' && {
          label: 'Sign In Clinic',
          onClick: () => handleNewSignableDocumentClick(item)
        }
    ].filter(Boolean) as ActionsMenuItem[];
  };

  const handleNewSignableDocumentClick = (estimate: Estimate, shouldNavigate = true) => {
    if (!estimate || !customer) return null;

    fetchEstimate(estimate.id, (fullEstimate) => {
      if (!fullEstimate || !estimate.animal) return;
      const compiledContent = renderToStaticMarkup(
        <EstimateDocument animal={estimate.animal} customer={customer} estimate={fullEstimate} />
      );

      const params = {
        customer_id: customer.id,
        animal_id: estimate.animal_id,
        consult_id: fullEstimate.consult_id,
        content: compiledContent,
        name: ['Estimate', fullEstimate.name].join(' - '),
        signed: false,
        pdf: false
      };

      if (fullEstimate.document_id) {
        updateDocument(fullEstimate.document_id, params, {
          onSuccess: (document) => {
            if (shouldNavigate) navigate(generatePath(paths.documentDetails, { id: document.id }));
          },
          onError: toast.error
        });
      } else {
        createDocument(params, {
          onSuccess: (document) => {
            updateEstimate(
              fullEstimate.id,
              { ...fullEstimate, document_id: document.id },
              {
                onSuccess: () => {
                  if (shouldNavigate) navigate(generatePath(paths.documentDetails, { id: document.id }));
                }
              }
            );
          },
          onError: toast.error
        });
      }
    });
  };

  const renderForm = (form?: Form) => {
    if (!customer || !estimate || !consults) return null;

    switch (form) {
      case 'new':
        return (
          <EstimateForm
            key={formKey}
            estimate={estimate}
            consults={consults}
            animals={customer.animals?.length && customer.animals?.length > 1 ? customer.animals : undefined}
            animal_id={customer.animals?.length === 1 ? customer.animals[0].id : undefined}
            setEstimate={setEstimate}
            handleSubmit={handleCreateEstimate}
            handleCancel={hideForm}
          />
        );
      case 'edit':
        return (
          <EstimateForm
            key={formKey}
            estimate={estimate}
            setEstimate={setEstimate}
            consults={consults}
            animals={customer.animals?.length && customer.animals?.length > 1 ? customer.animals : undefined}
            animal_id={customer.animals?.length === 1 ? customer.animals[0].id : undefined}
            handleSubmit={handleUpdateEstimate}
            handleCancel={hideForm}
          />
        );
      default:
        return null;
    }
  };

  return (
    <CRow className="mb-3">
      <div className="d-flex align-items-center justify-content-end mb-3">
        <Filters setFilters={handleFilterChange} filters={filters} useRange />
        <IconButton onClick={handleNewEstimateClick} icon={SvgPlus} label="New Treatment Plan" className="ms-2" />
      </div>

      {renderForm(visibleForm)}

      <CSmartTable
        pagination={{ external: true }}
        paginationProps={{
          activePage: pagination.page,
          pages: calculatePages(pagination),
          align: 'center'
        }}
        itemsPerPage={pagination.perPage}
        itemsNumber={pagination.total}
        onActivePageChange={updatePage}
        tableHeadProps={{ color: 'dark' }}
        items={estimates}
        tableProps={{ 'aria-label': 'Estimates' }}
        columns={[
          { key: 'id', label: 'ID' },
          'effective_date',
          'status',
          'name',
          { key: 'animal_name', label: 'Patient' },
          'total',
          'actions'
        ]}
        scopedColumns={{
          id: (estimate: Estimate) => (
            <td>
              <CButton color="link" className="p-0" onClick={handleEditEstimateClick(estimate)}>
                #{estimate.id}
              </CButton>
            </td>
          ),
          effective_date: (estimate: Estimate) => (
            <td>{estimate.effective_date && compactDateTimeDisplay(estimate.effective_date)}</td>
          ),
          name: (estimate: Estimate) => <td>{estimate.name}</td>,
          animal_name: (estimate: Estimate) => (
            <td>{estimate.animal && <Link to={`/animals/${estimate.animal.id}`}>{estimate.animal.name}</Link>}</td>
          ),
          status: (estimate: Estimate) => (
            <td>
              <Pill label={estimate.status} />
            </td>
          ),
          total: (estimate: Estimate) => <td>{estimate.total && toCurrency(estimate.total)}</td>,
          actions: (estimate: Estimate) => (
            <td>
              <ActionsMenu items={actionsMenuItems(estimate)} />
            </td>
          )
        }}
      />
    </CRow>
  );
};
