import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';

import { createConsultTemplate } from 'api/ConsultTemplates';

import { ConsultTemplate } from 'types/ConsultTemplate';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import Form from './Form';

const New = (): JSX.Element => {
  const navigate = useNavigate();
  const [template, setTemplate] = useState<Partial<ConsultTemplate>>({
    status: 'active',
    type: 'DocumentTemplate'
  } as Partial<ConsultTemplate>);

  useDocumentTitle('New Document Template');

  const handleSubmit = () => {
    createConsultTemplate(template, createSuccess);
  };

  const createSuccess = (template: ConsultTemplate) => {
    toast.success('Document template created!');
    navigate(`/document_templates/${template.id}`);
  };

  const handleCancel = () => {
    navigate(`/document_templates`);
  };

  return (
    <CCard>
      <CCardHeader>
        <h3>New Document Template</h3>
      </CCardHeader>
      <CCardBody>
        <Form template={template} handleSubmit={handleSubmit} handleCancel={handleCancel} setTemplate={setTemplate} />
      </CCardBody>
    </CCard>
  );
};

export default New;
