import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';
import { EstimateDocument } from 'views/documents/EstimateDocument';
import { NewTaskButton } from 'views/employee_tasks/NewTaskButton';

import { fetchCustomer } from 'api/Customers';
import { createDocument, updateDocument } from 'api/Documents';
import { createEstimate, fetchEstimate, fetchEstimatesForConsult, updateEstimate } from 'api/Estimates';

import { ActionsMenuItem } from 'types/ActionsMenuItem';
import { Consult } from 'types/Consult';
import { Customer } from 'types/Customer';
import { Estimate, EstimateStatus } from 'types/Estimate';

import { useKey } from 'hooks/useKey';
import { usePoll } from 'hooks/usePoll';

import { compactDateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';
import { toOption } from 'utils/selectOptions';

import SvgPlus from 'assets/images/SvgPlus';

import { ActionsMenu } from 'components/ActionsMenu';
import { EstimateForm } from 'components/EstimateForm';
import { IconButton } from 'components/IconButton';
import { MedicalHistoryTableV2 } from 'components/MedicalHistoryTableV2';
import { Pill } from 'components/Pill';
import { TableAuditData } from 'components/TableAuditData';

type Props = {
  consult: Consult;
  scrollUp: () => void;
};

type Form = 'new-estimate' | 'edit-estimate';

export const TreatmentPlansStep = ({ consult, scrollUp }: Props) => {
  const navigate = useNavigate();
  const [estimates, setEstimates] = useState<Estimate[]>([]);
  const [customer, setCustomer] = useState<Customer>();

  const [estimate, setEstimate] = useState<Partial<Estimate>>();

  const [visibleForm, setVisibleForm] = useState<Form>();

  const [isEstimateLoading, setIsEstimateLoading] = useState(false);

  const [formKey, remountForm] = useKey();

  const initializeEstimate = useCallback(() => {
    if (customer) {
      const initializedEstimate = {
        animal_id: consult.animal_id,
        client_id: customer.id,
        consult_id: consult.id,
        effective_date: new Date(consult.appointment?.start_time || '').toISOString(),
        urgent_care: false,
        status: 'pending'
      } as Partial<Estimate>;
      setEstimate(initializedEstimate);
    }
  }, [consult, customer]);

  const fetchEstimates = useCallback(
    () =>
      fetchEstimatesForConsult(consult.id, (estimates) => {
        if (estimates.length === 0) {
          if (!estimate) initializeEstimate();
          setVisibleForm('new-estimate');
        }

        setEstimates(estimates);
      }),
    [consult.id, estimate, initializeEstimate]
  );
  usePoll(fetchEstimates);

  useEffect(() => {
    fetchCustomer(consult.customer_id, setCustomer);
  }, [consult]);

  const handleEstimateSuccess = (estimate: Partial<Estimate>) => {
    fetchEstimates();
    handleSuccess();
    setIsEstimateLoading(false);
  };

  const handleSuccess = () => {
    let message: string;
    switch (visibleForm) {
      case 'new-estimate':
        message = 'Estimate created!';
        break;
      case 'edit-estimate':
        message = 'Estimate updated!';
        break;
      default:
        return '';
    }

    toast.success(message);
    hideForm();

    fetchEstimates();
  };

  const handleEstimateError = (message: string) => {
    setIsEstimateLoading(false);
    toast.error(message);
  };

  const handleCreateEstimate = async () => {
    if (!estimate) return;
    setIsEstimateLoading(true);
    return createEstimate(estimate, handleEstimateSuccess, handleEstimateError);
  };

  const persistEstimateUpdates = async () => {
    if (!estimate?.id) return;
    setIsEstimateLoading(true);
    return updateEstimate(
      estimate.id,
      { ...estimate, estimate_items_attributes: estimate.estimate_items },
      {
        onSuccess: (updatedEstimate) => {
          handleEstimateSuccess(updatedEstimate);
          if (updatedEstimate.document_id && estimate.status !== 'approved' && estimate.status !== 'rejected')
            handleNewSignableDocumentClick(updatedEstimate, false);
        },
        onError: handleEstimateError
      }
    );
  };

  const hideForm = () => {
    setVisibleForm(undefined);
    setEstimate(undefined);
  };

  const handleNewEstimateClick = () => {
    remountForm();
    scrollUp();

    initializeEstimate();
    setVisibleForm('new-estimate');
  };

  const handleTableAuditDataClick = (item: Estimate) => () => {
    if (['approved', 'rejected'].includes(item.status)) {
      navigate(generatePath(paths.estimateDetails, { id: item.id }));
      return;
    }

    remountForm();
    scrollUp();

    fetchEstimate(item.id, (fullEstimate) => {
      setEstimate(fullEstimate);
      setVisibleForm('edit-estimate');
    });
  };

  const persistEstimateStatusChange = ({ item, status }: { item: Estimate; status: EstimateStatus }) => {
    setIsEstimateLoading(true);
    updateEstimate(
      item.id,
      { status: status },
      {
        onSuccess: handleEstimateSuccess,
        onError: handleEstimateError
      }
    );
  };

  const handleNewSignableDocumentClick = (estimate: Estimate, shouldNavigate = true) => {
    if (!estimate || !customer) return null;

    fetchEstimate(estimate.id, (fullEstimate) => {
      if (!fullEstimate || !consult.animal) return;
      const compiledContent = renderToStaticMarkup(
        <EstimateDocument animal={consult.animal} customer={customer} estimate={fullEstimate} />
      );

      const params = {
        customer_id: customer.id,
        animal_id: consult.animal_id,
        consult_id: fullEstimate.consult_id,
        content: compiledContent,
        name: ['Estimate', fullEstimate.name].join(' - '),
        signed: false,
        pdf: false
      };

      if (fullEstimate.document_id) {
        updateDocument(fullEstimate.document_id, params, {
          onSuccess: (document) => {
            if (shouldNavigate) navigate(generatePath(paths.documentDetails, { id: document.id }));
          },
          onError: toast.error
        });
      } else {
        createDocument(params, {
          onSuccess: (document) => {
            updateEstimate(
              fullEstimate.id,
              { ...fullEstimate, document_id: document.id },
              {
                onSuccess: () => {
                  if (shouldNavigate) navigate(generatePath(paths.documentDetails, { id: document.id }));
                }
              }
            );
          },
          onError: toast.error
        });
      }
    });
  };

  const rejectStatuses = (item: Estimate, statuses: EstimateStatus[]) => {
    return !statuses.includes(item.status);
  };

  const actionsMenuItems = (item: Estimate) => {
    return [
      rejectStatuses(item, ['approved', 'rejected']) && {
        label: 'Edit',
        onClick: handleTableAuditDataClick(item)
      },
      {
        label: 'View',
        href: generatePath(paths.estimateDetails, { id: item.id })
      },
      rejectStatuses(item, ['approved']) && {
        label: 'Approve',
        onClick: () => persistEstimateStatusChange({ item, status: 'approved' })
      },
      rejectStatuses(item, ['pending']) && {
        label: 'Make Pending',
        onClick: () => persistEstimateStatusChange({ item, status: 'pending' })
      },
      rejectStatuses(item, ['rejected']) && {
        label: 'Reject',
        onClick: () => persistEstimateStatusChange({ item, status: 'rejected' })
      },
      rejectStatuses(item, ['approved', 'rejected']) && {
        label: 'Sign In Clinic',
        onClick: () => handleNewSignableDocumentClick(item)
      }
    ].filter(Boolean) as ActionsMenuItem[];
  };

  const renderForm = (form?: Form) => {
    if (!customer) return null;

    switch (form) {
      case 'new-estimate':
        if (!estimate) return null;
        return (
          <EstimateForm
            key={formKey}
            estimate={estimate}
            handleSubmit={handleCreateEstimate}
            handleCancel={hideForm}
            setEstimate={setEstimate}
            isLoading={isEstimateLoading}
          />
        );
      case 'edit-estimate':
        if (!estimate) return null;
        return (
          <EstimateForm
            key={formKey}
            estimate={estimate}
            handleSubmit={persistEstimateUpdates}
            handleCancel={hideForm}
            setEstimate={setEstimate}
            isLoading={isEstimateLoading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <MedicalHistoryTableV2
      name="Treatment Plans"
      items={estimates}
      form={renderForm(visibleForm)}
      columns={['updated', 'effective_date', 'status', 'name', 'total', 'actions']}
      scopedColumns={{
        updated: (item: Estimate) => <TableAuditData item={item} handleClick={handleTableAuditDataClick(item)} />,
        effective_date: (item: Estimate) => (
          <td>{item.effective_date && compactDateTimeDisplay(item.effective_date)}</td>
        ),
        total: (item: Estimate) => <td>{item.total && toCurrency(item.total)}</td>,
        status: (item: Estimate) => (
          <td>
            <Pill label={item.status} />
          </td>
        ),
        actions: (item: Estimate) => (
          <td>
            <ActionsMenu isLoading={isEstimateLoading} items={actionsMenuItems(item)} />
          </td>
        )
      }}
      newButton={<IconButton onClick={handleNewEstimateClick} icon={SvgPlus} label="New Treatment Plan" />}
      secondaryButton={
        <NewTaskButton
          buttonText="New Task"
          contextOptions={estimates.map(toOption)}
          contextType={estimates.length > 0 ? 'Estimate' : undefined}
          consult_id={consult.id}
          animal_id={consult.animal_id}
          initialReason="FinancialTask"
          disablePresets
        />
      }
    />
  );
};
