import * as React from 'react';

import { cilCloudDownload } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CSmartTable } from '@coreui/react-pro';

import { Attachment } from 'types/Attachment';
import { calculatePages, Pagination } from 'types/Pagination';

import { compactDateTimeDisplay } from 'utils/dates';

import SvgClipboard from 'assets/images/SvgClipboard';

import { IconButton } from 'components/IconButton';
import { Pill } from 'components/Pill';

type Props = {
  attachments?: Attachment[];
  pagination: Pagination;
  updatePage: (page: number) => void;
  tableControl: React.ReactNode;
  handleNewAttachmentClick?: () => void;
  visible?: boolean;
};

export const AttachmentsList = ({
  handleNewAttachmentClick,
  attachments,
  pagination,
  updatePage,
  tableControl,
  visible = true
}: Props) => {
  if (!visible) {
    return null;
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        {tableControl}
        <IconButton
          className="ms-auto mt-2"
          onClick={handleNewAttachmentClick}
          label="Upload Attachment"
          icon={SvgClipboard}
        />
      </div>

      <CSmartTable
        items={attachments ?? []}
        pagination={{ external: true }}
        paginationProps={{
          activePage: pagination.page,
          pages: calculatePages(pagination),
          align: 'center'
        }}
        itemsPerPage={pagination.perPage}
        itemsNumber={pagination.total}
        onActivePageChange={updatePage}
        tableHeadProps={{ color: 'dark' }}
        tableProps={{ 'aria-label': 'Attachments' }}
        columns={[{ key: 'id', label: 'ID' }, 'created_at', 'status', 'attachment_type', 'name', 'actions']}
        scopedColumns={{
          id: (item: Attachment) => <td>#{item.id}</td>,
          created_at: (item: Attachment) => <td>{compactDateTimeDisplay(item.created_at)}</td>,
          status: (item: Attachment) => <td>{item.status && <Pill label={item.status} />}</td>,
          attachment_type: (item: Attachment) => <td>{item.attachment_type?.name}</td>,
          actions: (item: Attachment) => (
            <td>
              <CButton
                variant="outline"
                className="ms-2 py-0 px-1"
                shape="rounded-pill"
                href={item.private_url}
                aria-label="download pdf"
              >
                <CIcon icon={cilCloudDownload} />
              </CButton>
            </td>
          )
        }}
      />
    </>
  );
};
