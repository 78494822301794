import * as React from 'react';
import { useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';

import { CButton, CLoadingButton, CRow, CSmartTable } from '@coreui/react-pro';

import { fetchFinancialConsults } from 'api/Consults';
import { createInvoice, fetchInvoice, updateInvoice } from 'api/Invoices';

import { ActionsMenuItem } from 'types/ActionsMenuItem';
import { Animal } from 'types/Animal';
import { Consult } from 'types/Consult';
import { Customer } from 'types/Customer';
import { Invoice } from 'types/Invoice';
import { ListFilter } from 'types/ListFilter';
import { calculatePages, Pagination } from 'types/Pagination';

import { useKey } from 'hooks/useKey';

import { compactDateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

import SvgCalendar from 'assets/images/SvgCalendar';
import SvgPencil from 'assets/images/SvgPencil';
import SvgPlus from 'assets/images/SvgPlus';
import SvgView from 'assets/images/SvgView';
import SvgViewConversation from 'assets/images/SvgViewConversation';

import { ActionsMenu } from 'components/ActionsMenu';
import Filters from 'components/Filters';
import { IconButton } from 'components/IconButton';
import { InvoiceForm } from 'components/InvoiceForm';
import { PaymentInvoiceModal } from 'components/PaymentInvoiceModal';
import { Pill } from 'components/Pill';

type Form = 'new' | 'edit';

type Props = {
  animal: Animal;
  customer: Customer;
  fetchInvoices: () => void;
  handleResyncInvoices?: () => void;
  invoices?: Invoice[];
  isLoading?: boolean;
  pagination: Pagination;
  updatePage: (page: number) => void;
  filters: ListFilter[];
  handleFilterChange: (filters: ListFilter[]) => void;
  scrollUp: () => void;
};

export const InvoicesList = ({
  animal,
  customer,
  fetchInvoices,
  handleResyncInvoices,
  invoices,
  isLoading,
  pagination,
  updatePage,
  filters,
  handleFilterChange,
  scrollUp
}: Props) => {
  const [visibleForm, setVisibleForm] = useState<Form>();
  const [invoice, setInvoice] = useState<Partial<Invoice>>();
  const [invoiceToPay, setInvoiceToPay] = useState<Invoice>();
  const [consults, setConsults] = useState<Consult[]>();

  const [formKey, remountForm] = useKey();

  useEffect(() => {
    fetchFinancialConsults({ params: { animal_id: animal.id }, onSuccess: setConsults });
  }, [animal]);

  const initializeInvoice = () => {
    const initializedInvoice: Partial<Invoice> = {
      active: true,
      animal_id: animal.id,
      animal: animal,
      customer: customer,
      customer_id: customer.id,
      fully_paid: false,
      payments: [],
      subtotal: 0,
      total: 0,
      tax: 0,
      total_paid: 0,
      unpaid_total: 0,
      status: 'draft'
    };

    setInvoice(initializedInvoice);
  };

  const hideForm = () => {
    setVisibleForm(undefined);
  };

  const handleNewInvoiceClick = () => {
    remountForm();
    scrollUp();

    initializeInvoice();
    setVisibleForm('new');
  };

  const handleEditInvoiceClick = (item: Invoice) => () => {
    remountForm();
    scrollUp();

    fetchInvoice(item.id, (fullInvoice) => {
      setInvoice(fullInvoice);
      setVisibleForm('edit');
    });
  };

  const handleCreateInvoiceSuccess = (invoice: Partial<Invoice>) => {
    hideForm();
    toast.success('Invoice created!');
    fetchInvoices();
  };

  const handleUpdateInvoiceSuccess = (invoice: Partial<Invoice>) => {
    hideForm();
    toast.success('Invoice updated!');
    fetchInvoices();
  };

  const handleCreateInvoice = async () => {
    if (!invoice) return;
    return createInvoice(invoice, handleCreateInvoiceSuccess);
  };

  const handleUpdateInvoice = async () => {
    if (!invoice?.id) return;

    return updateInvoice(
      invoice.id,
      { ...invoice, invoice_items_attributes: invoice.invoice_items },
      { onSuccess: handleUpdateInvoiceSuccess }
    );
  };

  const handlePaymentSuccess = (invoice: Invoice) => {
    setInvoiceToPay(undefined);
    fetchInvoices();
  };

  const actionsMenuItems = (invoice: Invoice) => {
    return [
      !invoice.fully_paid && {
        label: 'Edit',
        icon: <SvgPencil className="primaryColor" />,
        onClick: handleEditInvoiceClick(invoice)
      },
      {
        label: 'View',
        icon: <SvgView className="primaryColor" />,
        href: generatePath(paths.invoiceDetails, { id: invoice.id })
      },
      invoice.status === 'approved' &&
        !invoice.fully_paid && {
          label: 'Pay Now',
          onClick: () => setInvoiceToPay(invoice)
        },
      invoice.consult_id && {
        label: 'View Consult',
        icon: <SvgViewConversation className="primaryColor" />,
        href: `/consults/${invoice.consult_id}?step=finances`
      },
      invoice.appointment_id && {
        label: 'View Appointment',
        icon: <SvgCalendar className="primaryColor" />,
        href: `/appointments/${invoice.appointment_id}`
      }
    ].filter(Boolean) as ActionsMenuItem[];
  };

  const renderForm = (form?: Form) => {
    if (!customer || !invoice || !consults) return null;

    switch (form) {
      case 'new':
        return (
          <InvoiceForm
            key={formKey}
            invoice={invoice}
            setInvoice={setInvoice}
            handleSubmit={handleCreateInvoice}
            handleCancel={hideForm}
            consults={consults}
          />
        );
      case 'edit':
        return (
          <InvoiceForm
            key={formKey}
            invoice={invoice}
            setInvoice={setInvoice}
            handleSubmit={handleUpdateInvoice}
            handleCancel={hideForm}
            consults={consults}
          />
        );
      default:
        return null;
    }
  };

  return (
    <CRow className="mb-3">
      <div className="mb-3 d-grid gap-4 d-flex align-items-center justify-content-end">
        <div className="d-flex w-100 justify-content-end">
          {handleResyncInvoices && (
            <CLoadingButton
              className="mb-2"
              color="primary"
              size="sm"
              disabledOnLoading={true}
              loading={isLoading}
              disabled={isLoading}
              onClick={handleResyncInvoices}
            >
              Resync
            </CLoadingButton>
          )}
          <Filters setFilters={handleFilterChange} filters={filters} useRange />
          <IconButton onClick={handleNewInvoiceClick} className="ms-2" label="New Invoice" icon={SvgPlus} />
        </div>
      </div>

      {renderForm(visibleForm)}

      <CSmartTable
        pagination={{ external: true }}
        paginationProps={{
          activePage: pagination.page,
          pages: calculatePages(pagination),
          align: 'center'
        }}
        itemsPerPage={pagination.perPage}
        itemsNumber={pagination.total}
        onActivePageChange={updatePage}
        tableHeadProps={{ color: 'dark' }}
        items={invoices ?? []}
        tableProps={{ 'aria-label': 'Invoices' }}
        columns={[
          { key: 'id', label: 'ID' },
          { key: 'created_at', label: 'Effective Date' },
          'status',
          'name',
          'total',
          { key: 'total_paid', label: 'Amount Paid' },
          { key: 'unpaid_total', label: 'Amount Due' },
          { key: 'payment_status' },
          { key: 'actions' }
        ]}
        scopedColumns={{
          id: (invoice: Invoice) => (
            <td>
              <CButton color="link" className="p-0" onClick={handleEditInvoiceClick(invoice)}>
                #{invoice.id}
              </CButton>
            </td>
          ),
          created_at: (invoice: Invoice) => (
            <td>
              {invoice.created_at && (
                <Link to={`/invoices/${invoice.id}`}>{compactDateTimeDisplay(invoice.created_at)}</Link>
              )}
            </td>
          ),
          patient: (invoice: Invoice) => (
            <td>
              <Link to={`/animals/${invoice.animal_id}`}>{invoice.animal_name}</Link>
            </td>
          ),
          status: (invoice: Invoice) => (
            <td>
              <Pill label={invoice.status} />
            </td>
          ),
          total: (invoice: Invoice) => <td>{toCurrency(invoice.total)}</td>,
          total_paid: (invoice: Invoice) => <td>{toCurrency(invoice.total_paid)}</td>,
          unpaid_total: (invoice: Invoice) => <td>{toCurrency(invoice.unpaid_total)}</td>,
          payment_status: (item: Invoice) => (
            <td>
              <Pill label={item.fully_paid ? 'Paid' : 'Unpaid'} />
            </td>
          ),
          actions: (invoice: Invoice) => (
            <td>
              <ActionsMenu items={actionsMenuItems(invoice)} />
            </td>
          )
        }}
      />

      {invoiceToPay && (
        <PaymentInvoiceModal
          invoice={invoiceToPay}
          customer={customer}
          isVisible={!!invoiceToPay}
          onClose={() => setInvoiceToPay(undefined)}
          onConfirm={handlePaymentSuccess}
        />
      )}
    </CRow>
  );
};
