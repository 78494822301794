import * as React from 'react';

import {
  CButton,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle
} from '@coreui/react-pro';

import styles from './ConfirmationModal.module.scss';

type Props = {
  isLoading?: boolean;
  isDisabled?: boolean;
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  modalBody?: string;
  confirmButtonLabel: string;
  modalHeader: string;
  cancelButtonLabel?: string;
  children?: React.ReactNode;
};

export const ConfirmationModal: React.FC<Props> = ({
  isLoading = false,
  isDisabled = false,
  isVisible,
  onClose,
  onConfirm,
  modalBody,
  modalHeader,
  confirmButtonLabel,
  cancelButtonLabel = 'Cancel',
  children
}) => (
  <CModal alignment="center" visible={isVisible} onClose={onClose}>
    <CModalHeader closeButton>
      <CModalTitle data-testid="modal-header">{modalHeader}</CModalTitle>
    </CModalHeader>
    <CModalBody data-testid="modal-body">
      {modalBody}
      {modalBody && <br />}
      {children}
    </CModalBody>
    <CModalFooter>
      <CLoadingButton
        className={styles.button}
        shape="rounded-pill"
        color="primary"
        onClick={onConfirm}
        loading={isLoading}
        disabled={isDisabled}
      >
        {confirmButtonLabel}
      </CLoadingButton>
      <CButton className={styles.button} variant="outline" shape="rounded-pill" onClick={onClose}>
        {cancelButtonLabel}
      </CButton>
    </CModalFooter>
  </CModal>
);
