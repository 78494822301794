import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { fetchBreeds } from 'api/Breeds';
import { fetchMasterProblemsByQuery } from 'api/MasterProblems';
import { fetchProducts } from 'api/Products';
import { fetchSexes } from 'api/Sex';
import { fetchSpecies } from 'api/Species';

import { Breed } from 'types/Breed';
import { HealthPlan } from 'types/HealthPlan';
import { HealthPlanTrigger } from 'types/HealthPlanTrigger';
import { MasterProblem } from 'types/MasterProblem';
import { PetSex } from 'types/PetSex';
import { PetSpecies } from 'types/PetSpecies';
import { Product } from 'types/Product';

import { toOption } from 'utils/selectOptions';

type AddTriggerFormProps = {
  handleSubmit: (healthPlanTrigger: HealthPlanTrigger) => void;
  handleCancel: () => void;
  healthPlan: HealthPlan;
  setHealthPlanTrigger?: (healthPlanTrigger: HealthPlanTrigger) => void;
  healthPlanTrigger?: HealthPlanTrigger;
};

interface SelectOption {
  value: string;
  label: string;
}

const ProductForm = ({
  handleSubmit,
  handleCancel,
  healthPlan,
  healthPlanTrigger
}: AddTriggerFormProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [triggerError, setTriggerError] = useState<string>();
  const [initialValues, setInitialValues] = useState<SelectOption[]>();

  const [triggerType, setTriggerType] = useState<string | undefined>();
  const [selectedTrigger, setSelectedTrigger] = useState<SelectOption>();
  const [exclude, setExclude] = useState<boolean>(healthPlanTrigger ? healthPlanTrigger.exclude : false);

  const [selectedBreed, setSelectedBreed] = useState<SelectOption>();
  const [breeds, setBreeds] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (
      healthPlanTrigger &&
      healthPlanTrigger.trigger_type === 'Breed' &&
      healthPlanTrigger.trigger &&
      'species_id' in healthPlanTrigger.trigger &&
      healthPlanTrigger.trigger.species_id
    ) {
      const species_id = healthPlanTrigger.trigger.species_id;
      setTriggerType('Species');
      setSelectedBreed({ label: healthPlanTrigger.trigger.name, value: healthPlanTrigger.trigger_id.toString() });

      fetchSpecies((species: PetSpecies[]) => {
        const breedSpecies = species.find((s) => s.id === species_id);
        if (breedSpecies) setSelectedTrigger(toOption(breedSpecies));
      });

      fetchBreeds(species_id, (breeds: Breed[]) => {
        setBreeds(breeds.map(toOption));
      });
    } else if (healthPlanTrigger && healthPlanTrigger.trigger_type && healthPlanTrigger.trigger) {
      setTriggerType(healthPlanTrigger.trigger_type);
      setSelectedTrigger(toOption(healthPlanTrigger.trigger));
    }
  }, [healthPlanTrigger]);

  useEffect(() => {
    switch (triggerType) {
      case 'MasterProblem':
        fetchMasterProblemsByQuery('').then((masterProblems: MasterProblem[]) => {
          setInitialValues(masterProblems.map(toOption));
        });
        break;
      case 'Product':
        fetchProducts('', (products: Product[]) => {
          setInitialValues(products.map(toOption));
        });
        break;
      case 'Species':
        fetchSpecies((species: PetSpecies[]) => {
          setInitialValues(species.map(toOption));
        });
        break;
      case 'Sex':
        fetchSexes((sexes: PetSex[]) => {
          setInitialValues(sexes.map(toOption));
        });
        break;
      default:
        break;
    }
  }, [triggerType]);

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    setTriggerError(undefined);

    if (triggerType && selectedTrigger) {
      handleSubmit({
        trigger_type: selectedBreed && selectedBreed.value !== '' ? 'Breed' : triggerType,
        trigger_id:
          selectedBreed && selectedBreed.value !== '' ? Number(selectedBreed.value) : Number(selectedTrigger.value),
        exclude: exclude,
        health_plan_id: healthPlan.id
      });
    } else {
      setIsSubmitting(false);
      setTriggerError('Trigger is required');
    }
  };

  const handleTriggerTypeSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTriggerType(event.target.value);
    setBreeds([]);
    setSelectedBreed({ label: '', value: '' });
    setSelectedTrigger({ label: '', value: '' });
  };

  const handleExcludedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExclude(event.target.checked);
  };

  const handleBreedSelected = (selected: SelectOption) => {
    if (selected) {
      setSelectedBreed(selected);
    } else {
      setSelectedBreed({ label: '', value: '' });
    }
  };

  const handleTriggerSelected = (selected: SelectOption) => {
    if (selected && selected.value) {
      setSelectedBreed({ label: '', value: '' });
      setSelectedTrigger(selected);
      setTriggerError(undefined);

      if (triggerType === 'Species') {
        fetchBreeds(Number(selected.value), (breeds: Breed[]) => {
          setBreeds(breeds.map(toOption));
        });
      }
    }
  };

  return (
    <CForm onSubmit={submit}>
      <CRow className="mb-3">
        <CCol>
          <CFormLabel htmlFor="name" className="col-sm-3 col-form-label">
            Health Plan
          </CFormLabel>
          <CFormInput type="text" id="name" value={healthPlan.name} disabled />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol xs={6}>
          <CFormLabel htmlFor="type" className="col-sm-3 col-form-label">
            Type
          </CFormLabel>
          <CFormSelect
            multiple={false}
            id="trigger_type"
            aria-label="Trigger Type"
            onChange={(selected) => handleTriggerTypeSelected(selected)}
            value={triggerType}
            options={[
              { label: '', value: '' },
              { label: 'Master Problem', value: 'MasterProblem' },
              { label: 'Product', value: 'Product' },
              { label: 'Species', value: 'Species' },
              { label: 'Sex', value: 'Sex' }
            ]}
            required
          />
        </CCol>
        <CCol xs={6}>
          <CFormLabel htmlFor="type" className="col-sm-3 col-form-label">
            Trigger
          </CFormLabel>
          <AsyncSelect<SelectOption>
            id="trigger_id"
            aria-label="Trigger"
            onChange={(selected) => handleTriggerSelected(selected as SelectOption)}
            defaultOptions={initialValues}
            isDisabled={!triggerType}
            loadOptions={(inputValue, callback) => {
              switch (triggerType) {
                case 'MasterProblem':
                  fetchMasterProblemsByQuery(inputValue).then((masterProblems: MasterProblem[]) => {
                    callback(masterProblems.map(toOption));
                  });
                  break;
                case 'Product':
                  fetchProducts(inputValue, (products: Product[]) => {
                    callback(products.map(toOption));
                  });
                  break;
                case 'Species':
                  fetchSpecies((species: PetSpecies[]) => {
                    callback(species.map(toOption));
                  });
                  break;
                case 'Sex':
                  fetchSexes((sexes: PetSex[]) => {
                    callback(sexes.map(toOption));
                  });
                  break;
                default:
                  break;
              }
            }}
            placeholder="Search for a trigger..."
            isSearchable
            value={
              selectedTrigger
                ? selectedTrigger
                : healthPlanTrigger && healthPlanTrigger.trigger
                ? toOption(healthPlanTrigger.trigger)
                : undefined
            }
          />
          {triggerError && (
            <CFormLabel htmlFor="product_id" className="mb-0 mt-1" style={{ color: 'var(--cui-danger)' }}>
              {triggerError}
            </CFormLabel>
          )}
        </CCol>
      </CRow>
      {(triggerType === 'Species' || triggerType === 'Breed') && breeds.length > 0 && (
        <CRow className="mb-3">
          <CCol>
            <CFormLabel htmlFor="breed">Breed, optional</CFormLabel>
            <Select<SelectOption>
              id="breed"
              aria-label="Breed, optional"
              onChange={(selected) => handleBreedSelected(selected as SelectOption)}
              placeholder="Leave blank to include all breeds of this species"
              options={breeds}
              isClearable
              isSearchable
              value={selectedBreed}
            />
          </CCol>
        </CRow>
      )}
      <CRow className="mb-3">
        <CCol>
          <CFormSwitch
            id="exclude"
            name="exclude"
            aria-label="Exclude"
            label="Is excluded? Used to prevent an animal that matches the trigger from being added to a HealthPlan."
            onChange={handleExcludedChange}
            checked={exclude}
          />
        </CCol>
      </CRow>

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
        <CButton color="secondary" disabled={isSubmitting} onClick={handleCancel}>
          Cancel
        </CButton>
        <CLoadingButton color="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Save
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default ProductForm;
