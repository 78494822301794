import { useEffect } from 'react';

type TitleParts = (string | null | undefined)[];

/**
 * useDocumentTitle - Custom React hook to set the document title dynamically.
 *
 * This hook accepts multiple title parts, which can be strings, null, or undefined.
 * It concatenates all provided title parts with a " - " separator and sets the document's title.
 * Any null or undefined values in the title parts are filtered out.
 */
export const useDocumentTitle = (...titleParts: TitleParts) => {
  useEffect(() => {
    const title = [titleParts].flat().filter(Boolean).join(' - ');

    if (title) {
      document.title = [title, 'Dr. Pim'].join(' - ');
    }

    return () => {
      document.title = 'Dr. Pim';
    };
  }, [titleParts]);
};
