import * as React from 'react';
import type { SVGProps } from 'react';

const SvgBrokenBone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 14" {...props}>
    <path
      fill="currentColor"
      d="M2.858 13.076A1.884 1.884 0 0 1 .97 11.157a1.882 1.882 0 1 1 1.84-3.284.77.77 0 0 0 .76-.01L7.1 5.825a.386.386 0 0 1 .567.435L7.34 7.482l1.222.328a.387.387 0 0 1 .093.708l-3.53 2.038a.76.76 0 0 0-.388.649 1.89 1.89 0 0 1-.941 1.619 1.87 1.87 0 0 1-.938.252m-1.1-2.054a1.11 1.11 0 1 0 2.206.178c.003-.536.3-1.04.775-1.314l2.721-1.57-.694-.187a.387.387 0 0 1-.273-.473l.186-.694-2.721 1.57a1.55 1.55 0 0 1-1.528.014 1.109 1.109 0 0 0-1.263 1.812q.087-.174.21-.332a.387.387 0 1 1 .607.48 1.1 1.1 0 0 0-.227.516M15.143 11.923a1.89 1.89 0 0 1-1.88-1.872.76.76 0 0 0-.388-.649l-3.53-2.038a.39.39 0 0 1-.18-.234l-.427-1.596a.387.387 0 0 1 .273-.474l1.596-.427a.39.39 0 0 1 .293.038l3.53 2.038a.77.77 0 0 0 .76.01 1.883 1.883 0 1 1 1.84 3.284c.006.335-.077.673-.254.978a1.87 1.87 0 0 1-1.143.877 2 2 0 0 1-.49.065M9.871 6.775l3.39 1.957c.476.275.773.778.776 1.314.003.393.215.759.555.955.256.148.555.187.841.11.286-.076.526-.26.674-.517a1.11 1.11 0 0 0-.09-1.242.387.387 0 0 1 .606-.48q.124.158.21.332a1.11 1.11 0 0 0-1.262-1.812 1.55 1.55 0 0 1-1.528-.013l-3.39-1.958-1.068.286zM8.559 4.091a.387.387 0 0 1-.385-.355l-.098-1.192a.387.387 0 0 1 .353-.417l.421-.035-.062-.75a.387.387 0 1 1 .771-.063l.093 1.135a.387.387 0 0 1-.353.418l-.421.034.066.807a.387.387 0 0 1-.385.418M6.775 4.72a.39.39 0 0 1-.295-.136l-.524-.617-.322.273a.387.387 0 0 1-.545-.045l-.737-.869a.387.387 0 0 1 .59-.5l.486.574.322-.273a.387.387 0 0 1 .545.045l.774.912a.387.387 0 0 1-.294.636"
    />
  </svg>
);
export default SvgBrokenBone;
