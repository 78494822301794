import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';

import { fetchHealthPlan } from 'api/HealthPlans';
import { fetchHealthPlanTrigger, updateHealthPlanTrigger } from 'api/HealthPlanTrigger';

import { HealthPlan } from 'types/HealthPlan';
import { HealthPlanTrigger } from 'types/HealthPlanTrigger';

import TriggerForm from './TriggerForm';

const EditTrigger = (): JSX.Element => {
  const navigate = useNavigate();
  type DetailsParams = {
    planId: string;
    triggerId: string;
  };
  const { planId, triggerId } = useParams<keyof DetailsParams>() as DetailsParams;
  const [healthPlan, setHealthPlan] = React.useState<HealthPlan>();
  const [healthPlanTrigger, setHealthPlanTrigger] = React.useState<HealthPlanTrigger>({} as HealthPlanTrigger);

  useEffect(() => {
    fetchHealthPlan(planId, setHealthPlan);
    fetchHealthPlanTrigger(Number(triggerId), setHealthPlanTrigger);
  }, [planId, triggerId]);

  const handleSubmit = (trigger: HealthPlanTrigger) => {
    updateHealthPlanTrigger(Number(triggerId), trigger, handleResolve);
  };

  const handleResolve = () => {
    navigate(`/health_plans/${healthPlan?.id}`);
  };

  return (
    <div>
      {healthPlan && (
        <CCard style={{ width: '35rem' }}>
          <CCardHeader>
            <h3>Edit Trigger on Plan</h3>
          </CCardHeader>
          <CCardBody>
            <TriggerForm
              handleSubmit={handleSubmit}
              handleCancel={handleResolve}
              setHealthPlanTrigger={setHealthPlanTrigger}
              healthPlan={healthPlan}
              healthPlanTrigger={healthPlanTrigger}
            />
          </CCardBody>
        </CCard>
      )}
    </div>
  );
};

export default EditTrigger;
