import * as React from 'react';
import type { SVGProps } from 'react';

const SvgRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.5 9c0 4.14-3.36 7.5-7.5 7.5s-6.667-4.17-6.667-4.17m0 0h3.39m-3.39 0v3.75M1.5 9c0-4.14 3.33-7.5 7.5-7.5 5.002 0 7.5 4.17 7.5 4.17m0 0V1.92m0 3.75h-3.33"
    />
  </svg>
);
export default SvgRefresh;
