import React from 'react';

import { CListGroup, CListGroupItem } from '@coreui/react-pro';

import { Customer } from 'types/Customer';

import { compactDateTimeDisplay, dateTimeSecondDisplay } from 'utils/dates';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar-blue.svg';
import { ReactComponent as PersonalCardIcon } from 'assets/images/personalcard.svg';

import styles from './AccountDetails.module.scss';

export const AccountDetails = ({ customer }: { customer: Customer }) => {
  return (
    <CListGroup>
      <CListGroupItem className={styles.detail}>
        <span className={styles.left}>
          <CalendarIcon /> Created On
        </span>
        <span className={styles.right}>{compactDateTimeDisplay(customer.created_at)}</span>
      </CListGroupItem>
      <CListGroupItem className={styles.detail}>
        <span className={styles.left}>
          <CalendarIcon /> Updated On
        </span>
        <span className={styles.right}>{compactDateTimeDisplay(customer.updated_at)}</span>
      </CListGroupItem>
      <CListGroupItem className={styles.detail}>
        <span className={styles.left}>
          <PersonalCardIcon /> PIM ID
        </span>
        <span className={styles.right}>{customer.pim_id}</span>
      </CListGroupItem>
      <CListGroupItem className={styles.detail}>
        <span className={styles.left}>
          <CalendarIcon /> Last Synced At
        </span>
        <span className={styles.right}>
          {customer?.last_synced_at ? dateTimeSecondDisplay(customer.last_synced_at) : 'Never'}
        </span>
      </CListGroupItem>
    </CListGroup>
  );
};
