import { isValid, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { DateTime } from 'luxon';

import { Clinic } from 'types/Clinic';

interface TimeDisplayOptions {
  timezone?: string;
}

const prepareDate = (formatString: string) => (date: string, options?: TimeDisplayOptions) => {
  const parsedDate = DateTime.fromISO(date, { zone: options?.timezone });
  return parsedDate.toFormat(formatString);
};

const formats = {
  dateDisplay: 'MMMM d, y', // e.g. January 1, 2020
  compactDateDisplay: 'M/d/yy', // e.g. 1/1/20
  dateTimeDisplay: 'MMMM d, y h:mm a ZZZZ', // e.g. January 1, 2020 12:00 AM PDT
  compactDateTimeDisplay: 'M/d/yy h:mm a ZZZZ', // e.g. 1/1/20 12:00 AM PDT
  dateTimeSecondDisplay: 'M/d/yy h:mm:ss a ZZZZ', // e.g. January 1, 2020 12:00:00 AM PDT
  timeDisplay: 'h:mm a ZZZZ', // e.g. 12:00 AM PDT
  pickerTimeDisplay: 'HH:mm' // e.g. 19:00
};

export const dateDisplay = prepareDate(formats.dateDisplay);
export const compactDateDisplay = prepareDate(formats.compactDateDisplay);
export const dateTimeDisplay = prepareDate(formats.dateTimeDisplay);
export const compactDateTimeDisplay = prepareDate(formats.compactDateTimeDisplay);
export const dateTimeSecondDisplay = prepareDate(formats.dateTimeSecondDisplay);
export const timeDisplay = prepareDate(formats.timeDisplay);
export const pickerTimeDisplay = prepareDate(formats.pickerTimeDisplay);

export function isValidDate(date: string | undefined | null): boolean {
  if (!date) {
    return false;
  }

  const dateObject: Date = parseISO(date);
  return isValid(dateObject);
}

export function getPaddedTime(date: Date) {
  return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padEnd(2, '0')}`;
}

export const extractDate = (date: Date) => {
  return date.toISOString().split('T')[0];
};

export const zonedToUtc = (date: Date | null | undefined, clinic: Clinic | undefined) => {
  if (date && clinic) {
    const clinicTimezone = clinic?.time_zone;
    return zonedTimeToUtc(date, clinicTimezone).toISOString();
  }
};
