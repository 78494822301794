import { useEffect } from 'react';

export const usePoll = (callback: () => void, interval = 5000) => {
  useEffect(() => {
    callback();

    const id = setInterval(callback, interval);
    return () => clearInterval(id);
  }, [callback, interval]);
};
