import axios from 'axios';

import { Animal } from 'types/Animal';
import { AnimalPreviousClinic } from 'types/AnimalPreviousClinic';
import { PreviousClinic } from 'types/PreviousClinic';

const getPreviousClinicsQueue = (onSuccess: (animals: Animal[]) => void) => {
  const url = '/pim/animal_previous_clinics/queue';
  axios.get<Animal[]>(url).then((data) => onSuccess(data.data.record));
};

const updateAnimalPreviousClinic = (
  animalPreviousClinicId: number,
  params: object,
  onSuccess: (animalPreviousClinic: AnimalPreviousClinic) => void
) => {
  const url = `/pim/animal_previous_clinics/${animalPreviousClinicId}`;
  axios.put<AnimalPreviousClinic>(url, params).then((data) => onSuccess(data.data.record));
};

const getPreviousClinic = (previousClinicId: string, onSuccess: (previousClinic: PreviousClinic) => void) => {
  const url = `/pim/previous_clinics/${previousClinicId}`;
  axios.get<PreviousClinic>(url).then((data) => onSuccess(data.data.record));
};

const updatePreviousClinic = (
  previousClinicId: string,
  params: PreviousClinic,
  onSuccess: (previousClinic: PreviousClinic) => void
) => {
  const url = `/pim/previous_clinics/${previousClinicId}`;
  axios.put<PreviousClinic>(url, params).then((data) => onSuccess(data.data.record));
};

export { getPreviousClinicsQueue, updateAnimalPreviousClinic, getPreviousClinic, updatePreviousClinic };
