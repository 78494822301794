import * as React from 'react';
import { useEffect, useState } from 'react';

import { CFormLabel, CFormSelect, CFormTextarea } from '@coreui/react-pro';

import { fetchAppointmentCancelReasons } from 'api/CancelReasons';

import { CancelReason } from 'types/CancelReason';

import { ConfirmationModal } from './ConfirmationModal';

type Props = {
  isLoading?: boolean;
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onReasonSelected: (reason: CancelReason | undefined) => void;
  modalBody: string;
  confirmButtonLabel: string;
  modalHeader: string;
  cancelButtonLabel?: string;
};

type Option = {
  value: string;
  label: string;
};

export const CancelAppointmentModal = ({
  isLoading = false,
  isVisible,
  onClose,
  onConfirm,
  onReasonSelected,
  modalBody,
  modalHeader,
  confirmButtonLabel,
  cancelButtonLabel = 'Cancel'
}: Props) => {
  const [selectedCancelReason, setSelectedCancelReason] = useState<Option | undefined>();
  const [cancelReasons, setCancelReasons] = useState<Option[] | undefined>();
  const [otherNotes, setOtherNotes] = useState<string | undefined>();
  const [errorText, setErrorText] = useState<string | undefined>();

  const handleReasonSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const cancelReason = cancelReasons?.find((reason) => reason.value === event.target.value);
    setSelectedCancelReason(cancelReason);
    if (cancelReason?.value === 'none') {
      onReasonSelected(undefined);
      setOtherNotes(undefined);
      setErrorText('Cancel reason is required');
    } else if (cancelReason?.value === 'other') {
      setOtherNotes('');
      onReasonSelected({ id: undefined, name: '' });
      setErrorText(undefined);
    } else {
      setOtherNotes('');
      onReasonSelected({ id: Number(cancelReason?.value), name: otherNotes || '' });
      setErrorText(undefined);
    }
  };

  const handleOtherNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherNotes(event.target.value);
    onReasonSelected({ id: Number(selectedCancelReason?.value), name: event.target.value });
  };

  const onConfirmSelected = () => {
    if (!selectedCancelReason || selectedCancelReason?.value === 'none') {
      setErrorText('Cancel reason is required');
    } else {
      setErrorText(undefined);
      onConfirm();
    }
  };

  useEffect(() => {
    fetchAppointmentCancelReasons((reasons: CancelReason[]) =>
      setCancelReasons([
        { label: '', value: 'none' },
        ...reasons.map((reason) => ({ value: String(reason.id), label: reason.name })),
        { label: 'Other', value: 'other' }
      ])
    );
  }, []);

  return (
    <ConfirmationModal
      isVisible={isVisible}
      onClose={onClose}
      onConfirm={onConfirmSelected}
      modalBody={modalBody}
      confirmButtonLabel={confirmButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      modalHeader={modalHeader}
      isLoading={isLoading}
    >
      <CFormLabel htmlFor="cancel_reason" className="mt-3 mb-1">
        Cancel Reason
      </CFormLabel>
      <CFormSelect
        id="cancel_reason"
        data-testid="reason-select"
        options={cancelReasons}
        value={selectedCancelReason?.value}
        onChange={handleReasonSelected}
        feedbackInvalid={errorText}
        invalid={errorText !== undefined}
      />
      {selectedCancelReason && selectedCancelReason?.value !== 'none' && (
        <CFormTextarea
          id="other_notes"
          value={otherNotes}
          onChange={handleOtherNotesChange}
          placeholder="Why was this appointment canceled?"
          rows={2}
          className="mt-2"
        />
      )}
    </ConfirmationModal>
  );
};
