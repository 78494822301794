import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { CButton } from '@coreui/react-pro';

import { updateAnimalMasterProblem } from 'api/AnimalMasterProblems';

import { AnimalMasterProblem } from 'types/AnimalMasterProblem';

import { compactDateDisplay } from 'utils/dates';
import { statusAction } from 'utils/status';

import { ReactComponent as DownArrowIcon } from 'assets/images/down-arrow.svg';

import { ActionsMenu } from 'components/ActionsMenu';
import { Pill } from 'components/Pill';

import styles from './MasterProblemItem.module.scss';

type Props = {
  item: AnimalMasterProblem;
  handleFlagClick: () => void;
  handleStatusError: () => void;
  handleStatusSuccess: (message: string) => void;
  handleEditClick: () => void;
};

export const MasterProblemItem = ({
  item,
  handleEditClick,
  handleFlagClick,
  handleStatusError,
  handleStatusSuccess
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={cn(styles.itemContainer, { [styles.important]: item.important })} key={item.id}>
      <div className={cn('d-flex justify-content-between align-items-center', styles.topRow, styles.row)}>
        <div className="d-flex">
          <div className="d-flex me-2">
            <div className={cn('me-1', styles.idLabel)}>ID</div>
            <div className={styles.id}>{item.id}</div>
          </div>
          <div className={cn('me-1', styles.name)}>{item.master_problem.name}</div>
        </div>
        <CButton
          aria-label="toggle details"
          className={styles.toggleButton}
          shape="rounded-pill"
          onClick={toggleExpanded}
        >
          <DownArrowIcon className={cn({ [styles.rotated]: isExpanded })} />
        </CButton>
      </div>

      <div className={cn('d-flex align-items-center justify-content-between', styles.row)}>
        <div className="d-flex">
          <div className={styles.label}>Started</div>
          <div className={styles.value}>{item.started_on && compactDateDisplay(item.started_on)}</div>
        </div>

        <ActionsMenu
          items={[
            { label: 'Edit', onClick: handleEditClick },
            {
              label: item.important ? 'Unflag' : 'Flag',
              onClick: handleFlagClick
            },
            statusAction({
              item,
              name: 'Master problem',
              updater: updateAnimalMasterProblem,
              handleSuccess: handleStatusSuccess,
              handleError: handleStatusError
            })
          ]}
        />
      </div>

      <div className={cn(isExpanded ? 'd-flex justify-content-between' : 'd-none', styles.row)}>
        <div className="d-flex">
          <div className={styles.label}>Updated</div>
          <div className={styles.value}>{compactDateDisplay(item.updated_at)}</div>
        </div>
        <Pill label={item.status} />
      </div>

      <div className={cn(isExpanded ? 'd-flex justify-content-between' : 'd-none', styles.row)}>
        <div>Employee</div>
        <div className={styles.value}>{item.employee.full_name_with_title}</div>
      </div>

      {item.specifics && (
        <div className={cn(isExpanded ? 'd-flex flex-column' : 'd-none', styles.row)}>
          <div className={styles.notesLabel}>Specifics</div>
          <div className={styles.notes} dangerouslySetInnerHTML={{ __html: item.specifics }} />
        </div>
      )}
    </div>
  );
};
