import React, { useEffect, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';
import SignaturePad from 'signature_pad';

import { CButton, CCard, CCardBody, CCardHeader, CForm, CSpinner } from '@coreui/react-pro';

import { fetchDocument, updateDocument } from 'api/Documents';

import { Document } from 'types/Document';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { replaceInputs, replaceSignature } from 'utils/documents';

import SvgIncomingUnderlined from 'assets/images/SvgIncoming';
import SvgSms from 'assets/images/SvgMail';
import SvgPencil from 'assets/images/SvgPencil';

import styles from './Details.module.scss';

import { EditDocumentModal } from './EditDocumentModal';
import { EmailDocumentModal } from './EmailDocumentModal';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [documentToSign, setDocumentToSign] = useState<Document>();
  const [transformedDocument, setTransformedDocument] = useState<string>();
  const [signaturePads, setSignaturePads] = useState<SignaturePad[]>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  useDocumentTitle('Document', documentToSign?.name);

  useEffect(() => {
    fetchDocument(id, { onSuccess: handleDocumentSuccess, onError: () => setDocumentToSign(undefined) });
  }, [id]);

  const handleDocumentSuccess = (doc: Document) => {
    setDocumentToSign(doc);

    if (doc.content) {
      const readyToSign = replaceSignature(replaceInputs(doc.content));
      setTransformedDocument(readyToSign);
    }
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const onDocumentEditSuccess = (doc: Document) => {
    setDocumentToSign(doc);
    setIsEditModalOpen(false);
    if (doc.content) {
      const readyToSign = replaceSignature(replaceInputs(doc.content));
      setTransformedDocument(readyToSign);
    }
  };

  useEffect(() => {
    if (!transformedDocument) return;

    const canvasElements = document.querySelectorAll('canvas');
    const sigPads = [];
    for (const canvasElement of canvasElements) {
      // height and width need to be manually set for high dpi screens,
      //  otherwise the resulting svg will be cut off
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      const width = window.innerWidth * 0.5;
      const height = 100;
      canvasElement.style.width = `${width}px`;
      canvasElement.style.height = `${height}px`;
      canvasElement.width = width * ratio;
      canvasElement.height = height * ratio;
      canvasElement.getContext('2d')?.scale(ratio, ratio);
      sigPads.push(new SignaturePad(canvasElement as HTMLCanvasElement));
    }
    setSignaturePads(sigPads);
  }, [transformedDocument]);

  const save = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (signaturePads && signaturePads.length > 0) {
      const hasEmptySignatures = signaturePads.some((signaturePad) => signaturePad.isEmpty());
      if (hasEmptySignatures) return toast.error('Please sign the document before saving.');

      setIsSaving(true);
      for (const signaturePad of signaturePads) {
        const parser = new DOMParser();
        document.querySelectorAll('input').forEach((input) => {
          const inputToText = parser.parseFromString(`<u>${input.value}</u>`, 'text/html').documentElement;
          input.parentNode?.replaceChild(inputToText, input);
        });

        const pngSignature = signaturePad?.toDataURL('image/png', 0.5);
        const canvas = document.querySelector('canvas');
        if (canvas && pngSignature) {
          const width = window.innerWidth * 0.5;
          const pngElement = parser.parseFromString(
            `<img width="${width}px" height="100px" src="${pngSignature}" />`,
            'text/html'
          ).documentElement;
          canvas.parentNode?.replaceChild(pngElement, canvas);
        }
      }
    }

    const finalForm = document.getElementById('document-content');
    setTransformedDocument(finalForm?.innerHTML || '');
    updateDocument(
      id,
      { ...documentToSign, content: finalForm?.innerHTML, signed: true, pdf: true },
      {
        onSuccess: (finalizedDocument) => {
          setTransformedDocument(finalizedDocument.content);
          setDocumentToSign(finalizedDocument);
          toast.success('Document signed!');
          setIsSaving(false);
        },
        onError: () => {
          setIsSaving(false);
        }
      }
    );
  };

  return (
    <>
      {documentToSign ? (
        <CCard>
          <CCardHeader className="d-flex flex-row align-items-center justify-content-between">
            <strong style={{ fontSize: '30px' }}>
              {documentToSign.name}
              {documentToSign.animal
                ? ` for ${documentToSign.animal.name}`
                : documentToSign.customer
                ? ` for ${documentToSign.customer.first_name} ${documentToSign.customer.last_name}`
                : ''}
            </strong>
            <div>
              {documentToSign.attachment && (
                <CButton
                  variant="outline"
                  className="ms-2 py-0 px-1"
                  shape="rounded-pill"
                  href={documentToSign.attachment.private_url}
                  title="Download PDF"
                >
                  <SvgIncomingUnderlined className="primaryColor" width={16} />
                </CButton>
              )}
              {documentToSign.attachment && (
                <CButton
                  variant="outline"
                  className="ms-2 py-0 px-1"
                  shape="rounded-pill"
                  onClick={() => setIsEmailModalOpen(true)}
                  title="Email Document"
                >
                  <SvgSms className="primaryColor" width={16} />
                </CButton>
              )}
              {!documentToSign.signed && (
                <CButton
                  variant="outline"
                  className="ms-2 py-0 px-1"
                  shape="rounded-pill"
                  onClick={handleEdit}
                  title="Edit Document"
                >
                  <SvgPencil className="primaryColor" width={16} />
                </CButton>
              )}
            </div>
          </CCardHeader>
          <CCardBody>
            <CForm onSubmit={save}>
              {transformedDocument && (
                <div
                  id="document-content"
                  dangerouslySetInnerHTML={{ __html: transformedDocument }}
                  className={styles.documentRoot}
                ></div>
              )}
              {documentToSign && documentToSign.requires_signature && !documentToSign.signed && (
                <CButton type="submit" disabled={isSaving}>
                  {isSaving && <CSpinner size="sm" className="me-2" />}
                  Save
                </CButton>
              )}
              {documentToSign && documentToSign.estimate && (
                <Link
                  style={{ float: 'right' }}
                  to={generatePath(paths.estimateDetails, { id: documentToSign.estimate.id })}
                >
                  View original estimate
                </Link>
              )}
            </CForm>
          </CCardBody>
        </CCard>
      ) : (
        <CSpinner />
      )}
      {documentToSign && isEmailModalOpen && (
        <EmailDocumentModal
          document={documentToSign}
          onClose={() => setIsEmailModalOpen(false)}
          onSuccess={() => setIsEmailModalOpen(false)}
        />
      )}
      {documentToSign && isEditModalOpen && (
        <EditDocumentModal
          document={documentToSign}
          onClose={() => setIsEditModalOpen(false)}
          onSuccess={onDocumentEditSuccess}
        />
      )}
    </>
  );
};
export default Details;
