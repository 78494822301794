import * as React from 'react';

import { CModal, CModalBody } from '@coreui/react-pro';

import { AnimalMasterProblem } from 'types/AnimalMasterProblem';

import { AnimalMasterProblemForm } from './AnimalMasterProblemForm';

type Props = {
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  animalMasterProblem: AnimalMasterProblem;
  animalMasterProblems: AnimalMasterProblem[];
};

export const EditAnimalMasterProblemModal = ({ hideModal, ...rest }: Props) => {
  return (
    <CModal backdrop="static" className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
      <CModalBody>
        <AnimalMasterProblemForm hideModal={hideModal} {...rest} />
      </CModalBody>
    </CModal>
  );
};
