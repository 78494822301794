import * as React from 'react';
import type { SVGProps } from 'react';

const SvgMessages = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M5.667 12.667h-.333c-2.667 0-4-.667-4-4V5.333q0-4 4-4h5.333q4 0 4 4v3.334q0 4-4 4h-.333c-.207 0-.407.1-.533.266l-1 1.334c-.44.586-1.16.586-1.6 0l-1-1.334a.75.75 0 0 0-.534-.266"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.666 5.333h6.667M4.666 8.667h4"
    />
  </svg>
);
export default SvgMessages;
