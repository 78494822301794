import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { cilBolt, cilOptions, cilPencil, cilSync, cilX } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CLoadingButton,
  CSmartTable,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchAppointmentTypes } from 'api/AppointmentTypes';
import { updateHealthPlanProduct } from 'api/HealthPlanProduct';
import { fetchHealthPlan, fetchHealthPlanAnimalCount, updateHealthPlan } from 'api/HealthPlans';
import { updateHealthPlanTrigger } from 'api/HealthPlanTrigger';

import { AppointmentType } from 'types/AppointmentType';
import { HealthPlan, importanceColor, typeDisplay } from 'types/HealthPlan';
import { HealthPlanProduct } from 'types/HealthPlanProduct';
import { HealthPlanTrigger } from 'types/HealthPlanTrigger';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { compactDateTimeDisplay } from 'utils/dates';
import { renderMarkdown } from 'utils/markdown';

import { ConfirmationModal } from 'components/ConfirmationModal';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [healthPlan, setHealthPlan] = useState<HealthPlan>();
  const [amountToEnroll, setAmountToEnroll] = useState<number>();
  const [triggerToRemove, setTriggerToRemove] = useState<HealthPlanTrigger>();
  const [productToRemove, setProductToRemove] = useState<HealthPlanProduct>();
  const [showDisableModal, setShowDisableModal] = useState<boolean>(false);
  const [showActivateModal, setShowActivateModal] = useState<boolean>(false);
  const [isLoadingAnimalEnrollCount, setIsLoadingAnimalEnrollCount] = useState<boolean>(false);
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>([]);

  useDocumentTitle(healthPlan ? `Health Plan Details - ${healthPlan.name}` : 'Health Plan Details');

  useEffect(() => {
    fetchHealthPlan(id, setHealthPlan);
    fetchHealthPlanAnimalCount(id, setAmountToEnroll);
  }, [id]);

  useMemo(() => {
    if (appointmentTypes.length === 0) {
      fetchAppointmentTypes(setAppointmentTypes);
    }
  }, [appointmentTypes]);

  const refreshAnimalEnrollCount = () => {
    setIsLoadingAnimalEnrollCount(true);
    fetchHealthPlanAnimalCount(
      id,
      (count: number) => {
        setAmountToEnroll(count);
        setIsLoadingAnimalEnrollCount(false);
      },
      (_: string) => {
        setIsLoadingAnimalEnrollCount(false);
      }
    );
  };

  const removeTrigger = () => {
    if (triggerToRemove && triggerToRemove.id)
      updateHealthPlanTrigger(triggerToRemove.id, { ...triggerToRemove, status: 'disabled' }, () => {
        fetchHealthPlan(id, setHealthPlan);
        setTriggerToRemove(undefined);
        toast.success('Trigger removed!');
        refreshAnimalEnrollCount();
      });
  };

  const removeProduct = () => {
    if (productToRemove && productToRemove.id)
      updateHealthPlanProduct(productToRemove.id, { ...productToRemove, status: 'disabled' }, () => {
        fetchHealthPlan(id, setHealthPlan);
        setProductToRemove(undefined);
        toast.success('Product removed!');
      });
  };

  const disableHealthPlan = () => {
    if (healthPlan)
      updateHealthPlan(id, { ...healthPlan, status: 'disabled' }, () => {
        fetchHealthPlan(id, setHealthPlan);
        setShowDisableModal(false);
        toast.success('Health plan disabled!');
      });
  };

  const activateHealthPlan = () => {
    if (healthPlan)
      updateHealthPlan(id, { ...healthPlan, status: 'active' }, () => {
        setShowActivateModal(false);
        fetchHealthPlan(id, setHealthPlan);
        toast.success('Health plan activated!');
      });
  };

  return (
    <>
      {healthPlan && (
        <CCard>
          <CCardHeader className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h1>{healthPlan.name}</h1>
              {healthPlan.status === 'disabled' ? (
                <CBadge
                  className="ms-3 fs-6"
                  color="secondary"
                  shape="rounded-pill"
                  aria-label="disabled-status"
                  aria-hidden={true}
                  size={'sm'}
                >
                  disabled
                </CBadge>
              ) : healthPlan.status === 'draft' ? (
                <CBadge
                  className="ms-3 fs-6"
                  color="info"
                  shape="rounded-pill"
                  aria-label="draft-status"
                  aria-hidden={true}
                  size={'sm'}
                >
                  draft
                </CBadge>
              ) : null}
            </div>

            <CDropdown alignment="end">
              <CDropdownToggle color="transparent" caret={false} className="p-0">
                <CIcon icon={cilOptions} aria-label="options" className="text-high-emphasis" />
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem
                  role="button"
                  className="align-items-center"
                  href={`/health_plans/${healthPlan.id}/edit`}
                >
                  <CIcon icon={cilPencil} aria-label="edit" className="me-2 text-high-emphasis" />
                  Edit
                </CDropdownItem>
                {healthPlan.status !== 'disabled' && (
                  <CDropdownItem role="button" className="align-items-center" onClick={() => setShowDisableModal(true)}>
                    <CIcon icon={cilX} aria-label="edit" className="me-2 text-high-emphasis" />
                    Disable
                  </CDropdownItem>
                )}
                {healthPlan.status !== 'active' && (
                  <CDropdownItem
                    role="button"
                    className="align-items-center"
                    onClick={() => {
                      refreshAnimalEnrollCount();
                      setShowActivateModal(true);
                    }}
                  >
                    <CIcon icon={cilBolt} aria-label="edit" className="me-2 text-high-emphasis" />
                    Activate
                  </CDropdownItem>
                )}
              </CDropdownMenu>
            </CDropdown>
          </CCardHeader>
          <CCardBody>
            <h2>Health Plan</h2>
            <CTable>
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell>Type</CTableHeaderCell>
                  <CTableHeaderCell>Importance</CTableHeaderCell>
                  <CTableHeaderCell>Is Recurring</CTableHeaderCell>
                  <CTableHeaderCell>Min Age</CTableHeaderCell>
                  <CTableHeaderCell>Appt. Type</CTableHeaderCell>
                  <CTableHeaderCell>
                    {healthPlan.status === 'draft' ? 'Qualifying Animals' : 'Enrolled Animals'}
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                <CTableRow>
                  <CTableDataCell>{typeDisplay(healthPlan.type)}</CTableDataCell>
                  <CTableDataCell>
                    <CBadge shape="rounded-pill" color={importanceColor(healthPlan.importance)}>
                      {healthPlan.importance.toLowerCase()}
                    </CBadge>
                  </CTableDataCell>
                  <CTableDataCell>{healthPlan.recurring ? 'Yes' : 'No'}</CTableDataCell>
                  <CTableDataCell>{healthPlan.minimum_age_in_months} months</CTableDataCell>
                  <CTableDataCell>
                    {appointmentTypes.find((type) => type.id === healthPlan.appointment_type_id)?.name_in_pim || ''}
                  </CTableDataCell>
                  <CTableDataCell>
                    {healthPlan.status === 'draft' ? amountToEnroll : healthPlan.animal_count}
                    {healthPlan.status === 'draft' && !isLoadingAnimalEnrollCount && (
                      <CLoadingButton
                        title="Refresh animal count"
                        onClick={refreshAnimalEnrollCount}
                        color="link"
                        className="p-0"
                      >
                        <CIcon aria-hidden icon={cilSync} className="ms-2" />
                      </CLoadingButton>
                    )}
                    {healthPlan.status === 'draft' && isLoadingAnimalEnrollCount && (
                      <CSpinner size="sm" className="ms-2 mt-1" />
                    )}
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
            <b>Details</b>
            {healthPlan.details ? renderMarkdown(healthPlan.details) : <p>None provided</p>}

            <b>Why it&apos;s recommended</b>
            {healthPlan.why_recommended ? renderMarkdown(healthPlan.why_recommended) : <p>None provided</p>}

            <div className="d-flex align-items-center justify-content-between mt-5 mb-1">
              <div className="d-flex align-items-center">
                <h2>Triggers</h2>
                <CBadge color="secondary" className="ms-2">
                  {healthPlan.health_plan_triggers.length}
                </CBadge>
              </div>
              <CButton color="primary" size="sm" href={`/health_plans/${healthPlan.id}/triggers/new`}>
                Add Trigger
              </CButton>
            </div>
            <CSmartTable
              tableHeadProps={{ color: 'dark' }}
              items={healthPlan.health_plan_triggers}
              pagination
              columns={[
                'trigger_type',
                'name',
                'exclude',
                'last_updated',
                { key: 'actions', _style: { width: '10%' } }
              ]}
              scopedColumns={{
                trigger_type: (item: HealthPlanTrigger) => (
                  <td>{item.trigger_type === 'MasterProblem' ? 'Master Problem' : item.trigger_type}</td>
                ),
                name: (item: HealthPlanTrigger) => (
                  <td>
                    {item.trigger_type === 'Product' ? (
                      <Link to={`/products/${item.trigger?.id}`}>{item.trigger?.name}</Link>
                    ) : item.trigger_type === 'MasterProblem' ? (
                      <Link to={`/master_problems/${item.trigger?.id}`}>{item.trigger?.name}</Link>
                    ) : (
                      item.trigger?.name
                    )}
                  </td>
                ),
                exclude: (item: HealthPlanTrigger) => <td>{item.exclude ? 'Yes' : 'No'}</td>,
                last_updated: (item: HealthPlanTrigger) => (
                  <td>
                    {item.updated_at ? compactDateTimeDisplay(item.updated_at) : null}{' '}
                    {item.updated_by_employee ? (
                      <Link to={`/employees/${item.updated_by_employee.id}`}>
                        ({item.updated_by_employee.last_name})
                      </Link>
                    ) : null}
                  </td>
                ),
                actions: (item: HealthPlanTrigger) => (
                  <td>
                    <div className="d-flex justify-content-end gap-2">
                      <CButton
                        size="sm"
                        color="info"
                        href={`/health_plans/${item.health_plan_id}/triggers/${item.id}/edit`}
                      >
                        Edit
                      </CButton>
                      <CButton size="sm" color="danger" onClick={() => setTriggerToRemove(item)}>
                        Remove
                      </CButton>
                    </div>
                  </td>
                )
              }}
            />

            <div className="d-flex align-items-center justify-content-between mt-5 mb-1">
              <div className="d-flex align-items-center">
                <h2>Products</h2>
                <CBadge color="secondary" className="ms-2">
                  {healthPlan.health_plan_products.length}
                </CBadge>
              </div>
              <CButton color="primary" size="sm" href={`/health_plans/${healthPlan.id}/products/new`}>
                Add Product
              </CButton>
            </div>
            <CSmartTable
              tableHeadProps={{ color: 'dark' }}
              items={healthPlan.health_plan_products}
              pagination
              columns={[
                'name',
                'fulfillment',
                'quantity',
                'per_quantity',
                'last_updated',
                { key: 'actions', _style: { width: '10%' } }
              ]}
              scopedColumns={{
                name: (item: HealthPlanProduct) => (
                  <td>
                    <Link to={`/products/${item.product_id}`}>{item.product?.name}</Link>
                  </td>
                ),
                fulfillment: (item: HealthPlanProduct) => (
                  <td>
                    {item.fulfillment_duration} {item.fulfillment_duration_unit}
                  </td>
                ),
                quantity: (item: HealthPlanProduct) => <td>{item.quantity || ''}</td>,
                per_quantity: (item: HealthPlanProduct) => <td>{item.per_quantity ? 'Yes' : 'No'}</td>,
                last_updated: (item: HealthPlanProduct) => (
                  <td>
                    {item.updated_at ? compactDateTimeDisplay(item.updated_at) : null}{' '}
                    {item.updated_by_employee ? (
                      <Link to={`/employees/${item.updated_by_employee.id}`}>
                        ({item.updated_by_employee.last_name})
                      </Link>
                    ) : null}
                  </td>
                ),
                actions: (item: HealthPlanProduct) => (
                  <td>
                    <div className="d-flex justify-content-end gap-2">
                      <CButton
                        size="sm"
                        color="info"
                        href={`/health_plans/${item.health_plan_id}/products/${item.id}/edit`}
                      >
                        Edit
                      </CButton>
                      <CButton size="sm" color="danger" onClick={() => setProductToRemove(item)}>
                        Remove
                      </CButton>
                    </div>
                  </td>
                )
              }}
            />
          </CCardBody>
        </CCard>
      )}

      <ConfirmationModal
        isVisible={triggerToRemove !== undefined}
        onClose={() => setTriggerToRemove(undefined)}
        onConfirm={removeTrigger}
        modalBody={`Remove ${triggerToRemove?.trigger?.name} from the health plan?`}
        confirmButtonLabel={'Yes, remove it'}
        modalHeader={'Remove trigger?'}
      />
      <ConfirmationModal
        isVisible={productToRemove !== undefined}
        onClose={() => setProductToRemove(undefined)}
        onConfirm={removeProduct}
        modalBody={`Remove ${productToRemove?.product?.name} from the health plan?`}
        confirmButtonLabel={'Yes, remove it'}
        modalHeader={'Remove product?'}
      />

      <ConfirmationModal
        isVisible={showDisableModal}
        onClose={() => setShowDisableModal(false)}
        onConfirm={disableHealthPlan}
        modalBody={`No new qualifying animals will be added to this health plan. ${
          healthPlan?.status === 'draft'
            ? ''
            : `This plan will be removed from ${healthPlan?.animal_count} animal${
                healthPlan?.animal_count === 1 ? '' : 's'
              }. `
        }The plan can be re-activated at any time.`}
        confirmButtonLabel="Disable"
        cancelButtonLabel="Cancel"
        modalHeader="Disable this health plan?"
      />
      <ConfirmationModal
        isVisible={showActivateModal}
        onClose={() => setShowActivateModal(false)}
        onConfirm={activateHealthPlan}
        modalBody={`${
          healthPlan?.status === 'draft' ? 'The plan cannot go back into draft status, but it can be disabled. ' : ''
        }${amountToEnroll} qualifying animal${amountToEnroll === 1 ? '' : 's'} will now be added to this health plan.`}
        confirmButtonLabel="Activate"
        cancelButtonLabel="Cancel"
        modalHeader="Activate this health plan?"
      />
    </>
  );
};
export default Details;
