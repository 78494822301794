import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CImage,
  CInputGroup,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { signIn } from '../../api/Authentication';
import { Employee } from '../../types/Employee';

interface LoginFormProps {
  onSuccess: (employee: Employee) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setErrorMessage('');
    setLoading(true);
    signIn(email, password, {
      onSuccess: (employee: Employee) => {
        setLoading(false);
        onSuccess(employee);
      },
      onError: () => {
        setLoading(false);
        setErrorMessage('Invalid email or password.');
      }
    });
  };

  return (
    <CContainer>
      <CRow className="justify-content-center">
        <CCol xs={5}>
          <CCard>
            <CCardBody>
              <CForm onSubmit={handleSubmit} autoComplete="off" className="d-flex flex-column align-items-center">
                <CImage width="250px" src="/logo-dark.png" alt="Dr. Treat Logo" className="mt-5 mb-5" />
                <CInputGroup className="mb-4 d-flex align-items-center">
                  <CFormLabel htmlFor="email" style={{ textAlign: 'right', width: '80px' }} className="pe-2">
                    Email
                  </CFormLabel>
                  <CFormInput
                    type="text"
                    id="email"
                    placeholder="Enter your email address"
                    autoComplete="off"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    ref={emailRef}
                  />
                </CInputGroup>
                <CInputGroup className="mb-4 d-flex align-items-center">
                  <CFormLabel htmlFor="password" style={{ textAlign: 'right', width: '80px' }} className="pe-2">
                    Password
                  </CFormLabel>
                  <CFormInput
                    type="password"
                    id="password"
                    placeholder="Enter your password"
                    autoComplete="off"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </CInputGroup>
                {errorMessage.length > 0 && <div className="text-danger mb-3">{errorMessage}</div>}
                <CLoadingButton color="primary" type="submit" className="mb-5" loading={loading}>
                  Login
                </CLoadingButton>
                <Link className="ms-auto" to={'/forgot_password'}>
                  Forgot Password?
                </Link>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default LoginForm;
