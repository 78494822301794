import { addMonths } from 'date-fns';
import * as React from 'react';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CCol, CDatePicker, CFormCheck, CFormInput, CFormSelect, CRow } from '@coreui/react-pro';

import { fetchPrescriptionProducts } from 'api/Products';

import { Option } from 'types/Option';
import { PrescriptionItem } from 'types/PrescriptionItem';
import { Product } from 'types/Product';

import { extractDate } from 'utils/dates';
import { buildInstructions } from 'utils/prescriptionItems';
import { reactSelectStyles } from 'utils/reactSelect';
import { toOption } from 'utils/selectOptions';

import SvgCalendar from 'assets/images/SvgCalendar';
import SvgClipboard from 'assets/images/SvgClipboard';
import SvgClock from 'assets/images/SvgClock';
import SvgFileCabinet from 'assets/images/SvgFileCabinet';
import SvgPawCross from 'assets/images/SvgPawCross';
import SvgPlusMinus from 'assets/images/SvgPlusMinus';

import { IconLabel } from 'components/IconLabel';
import { RichTextEditor } from 'components/RichTextEditor';

import styles from './PrescriptionItemForm.module.scss';

const MIN_QUERY_LENGTH = 3;

type Props = {
  index: number;
  isPrescribedToday: boolean;
  prescriptionItem?: PrescriptionItem;
};

export const PrescriptionItemForm = ({ index, isPrescribedToday, prescriptionItem }: Props) => {
  const initialProductOption = prescriptionItem?.product ? toOption(prescriptionItem.product) : null;
  const [selectedProductOption, setSelectedProductOption] = useState<Option | null>(initialProductOption);
  const [productOptions, setProductOptions] = useState<Product[]>([]);

  const [isCurrent, setIsCurrent] = useState<boolean>(prescriptionItem?.current || isPrescribedToday);
  const [isChronic, setIsChronic] = useState<boolean>(prescriptionItem?.chronic || false);
  const [quantity, setQuantity] = useState<string>(prescriptionItem?.quantity || '');
  const [dispense, setDispense] = useState<string>(prescriptionItem?.dispense || '');
  const [dispenseInstructions, setDispenseInstructions] = useState<string>(
    prescriptionItem?.dispense_instructions || ''
  );
  const [quantityPerUse, setQuantityPerUse] = useState<string>(prescriptionItem?.quantity_per_use || '');
  const [isTakenWithFood, setIsTakenWithFood] = useState<boolean>(prescriptionItem?.taken_with_food || false);
  const [timeIntervalInHours, setTimeIntervalInHours] = useState<string>(
    prescriptionItem?.time_interval_in_hours ? String(prescriptionItem?.time_interval_in_hours) : ''
  );

  const [instructions, setInstructions] = useState<string>(prescriptionItem?.instructions || '');

  const [isRefillable, setIsRefillable] = useState(prescriptionItem ? prescriptionItem.max_refills > 0 : false);
  const [quantityPerRefill, setQuantityPerRefill] = useState<string>(prescriptionItem?.quantity_per_refill || '');
  const [maxRefills, setMaxRefills] = useState<string>(
    prescriptionItem?.max_refills ? String(prescriptionItem?.max_refills) : ''
  );
  const [refillExpiresAt, setRefillExpiresAt] = useState<Date | null>(
    prescriptionItem?.refill_expires_at ? new Date(prescriptionItem.refill_expires_at) : addMonths(new Date(), 6)
  );
  const [refillTimePeriod, setRefillTimePeriod] = useState<string>(prescriptionItem?.refill_time_period || 'Day');
  const [refillTimeUnit, setRefillTimeUnit] = useState<string>(
    prescriptionItem?.refill_time_unit ? String(prescriptionItem.refill_time_unit) : ''
  );

  useEffect(() => {
    if (prescriptionItem) return;
    setIsCurrent(isPrescribedToday);
  }, [isPrescribedToday, prescriptionItem]);

  const generateInstructions = ({
    changedProductOption = selectedProductOption,
    changedIsRefillable = isRefillable,
    changedMaxRefills = maxRefills,
    changedQuantityPerRefill = quantityPerRefill,
    changedRefillTimeUnit = refillTimeUnit,
    changedRefillTimePeriod = refillTimePeriod,
    changedRefillExpiresAt = refillExpiresAt
  }: {
    changedWarning?: string;
    changedProductOption?: Option | null;
    changedQuantityPerUse?: string;
    changedIsTakenWithFood?: boolean;
    changedTimeIntervalInHours?: string;
    changedIsRefillable?: boolean;
    changedMaxRefills?: string;
    changedQuantityPerRefill?: string;
    changedRefillTimeUnit?: string;
    changedRefillTimePeriod?: string;
    changedRefillExpiresAt?: Date | null;
  }) => {
    const product = productOptions.find((product) => String(product.id) === changedProductOption?.value);
    const changedInstructions = buildInstructions({
      product: product,
      isRefillable: changedIsRefillable,
      maxRefills: changedMaxRefills,
      quantityPerRefill: changedQuantityPerRefill,
      refillTimeUnit: changedRefillTimeUnit,
      refillTimePeriod: changedRefillTimePeriod,
      refillExpiresAt: changedRefillExpiresAt
        ? extractDate(changedRefillExpiresAt)
        : refillExpiresAt
        ? extractDate(refillExpiresAt)
        : undefined
    });

    setInstructions(changedInstructions);
  };

  // Begin: values that can change instructions
  const handleProductOptionChange = (option: Option | null) => {
    setSelectedProductOption(option);
    generateInstructions({ changedProductOption: option });
  };

  const handleIsRefillableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedIsRefillable = event.target.checked;
    setIsRefillable(changedIsRefillable);
    generateInstructions({ changedIsRefillable });
  };

  const handleMaxRefillsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedMaxRefills = event.target.value;
    setMaxRefills(changedMaxRefills);
    generateInstructions({ changedMaxRefills });
  };

  const handleQuantityPerRefillChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedQuantityPerRefill = event.target.value;
    setQuantityPerRefill(changedQuantityPerRefill);
    generateInstructions({ changedQuantityPerRefill });
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedQuantity = event.target.value;
    setQuantity(changedQuantity);
    setDispense(changedQuantity);
    setQuantityPerRefill(changedQuantity);

    generateInstructions({ changedQuantityPerRefill: changedQuantity });
  };

  const handleRefillTimeUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedRefillTimeUnit = event.target.value;
    setRefillTimeUnit(changedRefillTimeUnit);
    generateInstructions({ changedRefillTimeUnit });
  };

  const handleRefillTimePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const changedRefillTimePeriod = event.target.value;
    setRefillTimePeriod(changedRefillTimePeriod);
    generateInstructions({ changedRefillTimePeriod });
  };

  const handleRefillExpiresAtChange = (date: Date | null) => {
    setRefillExpiresAt(date);
    generateInstructions({ changedRefillExpiresAt: date });
  };

  const handleInstructionsChange = (changedInstructions: string) => {
    setInstructions(changedInstructions);
  };
  // End: values that can change instructions

  // Begin: values that cannot change instructions
  const handleQuantityPerUseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedQuantityPerUse = event.target.value;
    setQuantityPerUse(changedQuantityPerUse);
  };

  const handleIsTakenWithFoodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedIsTakenWithFood = event.target.checked;
    setIsTakenWithFood(changedIsTakenWithFood);
  };

  const handleTimeIntervalInHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedTimeIntervalInHours = event.target.value;
    setTimeIntervalInHours(changedTimeIntervalInHours);
  };

  const handleIsCurrentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedIsCurrent = event.target.checked;
    setIsCurrent(changedIsCurrent);
  };

  const handleIsChronicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedIsChronic = event.target.checked;
    setIsChronic(changedIsChronic);
  };

  const handleDispenseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedDispense = event.target.value;
    setDispense(changedDispense);
  };

  const handleDispenseInstructionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedDispenseInstructions = event.target.value;
    setDispenseInstructions(changedDispenseInstructions);
  };
  // End: values that cannot change instructions

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const loadProductOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchPrescriptionProducts(inputValue).then((options) => {
      setProductOptions(options);
      callback(options.map(toOption));
    });
  };

  const nestedName = (name: string) => `prescription_items_attributes[${index}][${name}]`;

  return (
    <div>
      <CRow className="mb-3">
        <CCol>
          <CFormInput hidden name={nestedName('id')} value={prescriptionItem?.id} />
          <CFormInput hidden name={nestedName('product_id')} value={selectedProductOption?.value ?? ''} />

          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="medication_name">
              <IconLabel icon={SvgPawCross} label="Medication name" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <AsyncSelect<Option>
            aria-label="Medication name"
            id="medication_name"
            placeholder="Type to search..."
            value={selectedProductOption}
            onChange={handleProductOptionChange}
            loadingMessage={loadingMessage}
            loadOptions={loadProductOptions}
            styles={reactSelectStyles}
            isClearable
            isSearchable
            required
          />
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor={`quantity-${index}`}>
              <IconLabel icon={SvgPlusMinus} label="Quantity" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <CFormInput
            value={quantity}
            onChange={handleQuantityChange}
            name={nestedName('quantity')}
            id={`quantity-${index}`}
            required
          />
        </CCol>

        <CCol>
          <CFormInput
            value={quantityPerUse}
            onChange={handleQuantityPerUseChange}
            name={nestedName('quantity_per_use')}
            label={<IconLabel icon={SvgPlusMinus} label="Quantity Per Use" />}
            id={`quantity-per-use-${index}`}
          />
        </CCol>

        <CCol>
          <CFormInput
            name={nestedName('time_interval_in_hours')}
            value={timeIntervalInHours}
            label={<IconLabel icon={SvgClock} label="Interval (Hours)" />}
            onChange={handleTimeIntervalInHoursChange}
            type="number"
            min={1}
            id={`time-interval-${index}`}
          />
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol>
          <CFormInput
            name={nestedName('dispense')}
            id={`dispense-${index}`}
            label={<IconLabel icon={SvgFileCabinet} label="Dispense" />}
            value={dispense}
            onChange={handleDispenseChange}
          />
        </CCol>

        <CCol>
          <CFormInput
            id={`dispense-instructions-${index}`}
            label={<IconLabel icon={SvgClipboard} label="Dispense Instructions" />}
            name={nestedName('dispense_instructions')}
            value={dispenseInstructions}
            onChange={handleDispenseInstructionsChange}
          />
        </CCol>

        <CCol xs="auto" className="d-flex flex-column align-items-center">
          <label htmlFor={`current-${index}`} className="form-label">
            Current
          </label>
          <CFormCheck
            id={`current-${index}`}
            name={nestedName('current')}
            checked={isCurrent}
            onChange={handleIsCurrentChange}
          />
        </CCol>

        <CCol xs="auto" className="d-flex flex-column align-items-center">
          <label htmlFor={`chronic-${index}`} className="form-label">
            Chronic
          </label>
          <CFormCheck
            id={`chronic-${index}`}
            name={nestedName('chronic')}
            checked={isChronic}
            onChange={handleIsChronicChange}
          />
        </CCol>

        <CCol xs="auto" className="d-flex flex-column align-items-center">
          <label htmlFor={`with-food-${index}`} className="form-label">
            With food
          </label>
          <CFormCheck
            id={`with-food-${index}`}
            name={nestedName('taken_with_food')}
            checked={isTakenWithFood}
            onChange={handleIsTakenWithFoodChange}
          />
        </CCol>

        <CCol xs="auto" className="d-flex flex-column align-items-center">
          <label htmlFor={`refillable-${index}`} className="form-label">
            Refillable
          </label>
          <CFormCheck id={`refillable-${index}`} checked={isRefillable} onChange={handleIsRefillableChange} />
        </CCol>
      </CRow>

      {isRefillable && (
        <>
          <CRow className="mb-3">
            <CCol>
              <div className="d-flex align-items-center justify-content-between form-label">
                <label htmlFor={`max-refills-${index}`}>
                  <IconLabel icon={SvgPlusMinus} label="Max Refills" />
                </label>
                <div className={styles.required}>Required</div>
              </div>

              <CFormInput
                name={nestedName('max_refills')}
                type="number"
                min={1}
                value={maxRefills}
                onChange={handleMaxRefillsChange}
                id={`max-refills-${index}`}
                required
              />
            </CCol>

            <CCol>
              <div className="d-flex align-items-center justify-content-between form-label">
                <label htmlFor={`refill-time-unit-${index}`}>
                  <IconLabel icon={SvgClock} label="Refill Time Unit" />
                </label>
                <div className={styles.required}>Required</div>
              </div>

              <CFormInput
                name={nestedName('refill_time_unit')}
                type="number"
                min={1}
                id={`refill-time-unit-${index}`}
                value={refillTimeUnit}
                onChange={handleRefillTimeUnitChange}
                required
              />
            </CCol>

            <CCol>
              <div className="d-flex align-items-center justify-content-between form-label">
                <label htmlFor={`refill-time-period-${index}`}>
                  <IconLabel icon={SvgClock} label="Refill Time Period" />
                </label>
                <div className={styles.required}>Required</div>
              </div>

              <CFormSelect
                name={nestedName('refill_time_period')}
                value={refillTimePeriod}
                onChange={handleRefillTimePeriodChange}
                id={`refill-time-period-${index}`}
                options={['Day', 'Week', 'Month', 'Year']}
                required
              />
            </CCol>
          </CRow>

          <CRow className="mb-3">
            <CCol xs={4}>
              <div className="d-flex align-items-center justify-content-between form-label">
                <label htmlFor={nestedName(`refill_expires_at`)}>
                  <IconLabel icon={SvgCalendar} label="Refill Expires At" />
                </label>
                <div className={styles.required}>Required</div>
              </div>

              <CDatePicker
                id={nestedName(`refill_expires_at`)}
                date={refillExpiresAt}
                locale="en-US"
                firstDayOfWeek={0}
                format="MMM dd, yyyy"
                onDateChange={handleRefillExpiresAtChange}
                cleaner={false}
              />
            </CCol>

            <CCol xs={4}>
              <div className="d-flex align-items-center justify-content-between form-label">
                <label htmlFor={`quantity-per-refill-${index}`}>
                  <IconLabel icon={SvgPlusMinus} label="Quantity Per Refill" />
                </label>
                <div className={styles.required}>Required</div>
              </div>

              <CFormInput
                value={quantityPerRefill}
                onChange={handleQuantityPerRefillChange}
                name={nestedName('quantity_per_refill')}
                id={`quantity-per-refill-${index}`}
                required
              />
            </CCol>
          </CRow>
        </>
      )}

      <CCol>
        <RichTextEditor
          id={`instructions-${index}`}
          label="Instructions"
          value={instructions}
          onChange={handleInstructionsChange}
          name={nestedName('instructions')}
          required
          text="Required"
        />
      </CCol>
    </div>
  );
};
