import * as React from 'react';
import type { SVGProps } from 'react';

const SvgStethoscope = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g fill="currentColor">
      <path d="M13.25 11.672c.872 0 1.582-.71 1.582-1.582s-.71-1.582-1.582-1.582-1.582.71-1.582 1.582.71 1.582 1.582 1.582M8.504 12.2c0 .872.71 1.581 1.582 1.581s1.582-.71 1.582-1.582-.71-1.582-1.582-1.582-1.582.71-1.582 1.582M16.414 10.617c-.872 0-1.582.71-1.582 1.582s.71 1.582 1.582 1.582 1.582-.71 1.582-1.582-.71-1.582-1.582-1.582" />
      <path d="M13.25 12.727c-1.523 0-2.637 2.08-2.637 3.164H7.906a2.64 2.64 0 0 1-2.636-2.637v-.625a1.58 1.58 0 0 0 1.054-1.484v-.618A1.585 1.585 0 0 0 7.38 9.035c0-.09-.012-.179-.027-.266a4.73 4.73 0 0 0 2.136-3.953v-2.18c0-.872-.71-1.581-1.582-1.581H7.38V.527a.527.527 0 1 0-1.055 0v2.11a.527.527 0 1 0 1.055 0v-.528h.527c.29 0 .528.237.528.528v2.18c0 1.223-.605 2.35-1.598 3.035a1.57 1.57 0 0 0-1.04-.399H3.688c-.399 0-.76.154-1.038.399A3.68 3.68 0 0 1 1.05 4.816v-2.18c0-.29.237-.527.527-.527h.527v.528a.527.527 0 1 0 1.055 0V.527a.527.527 0 1 0-1.055 0v.528h-.527c-.872 0-1.582.71-1.582 1.582v2.18c0 1.605.812 3.078 2.136 3.952a1.6 1.6 0 0 0-.027.266c0 .688.441 1.274 1.055 1.492v.618a1.58 1.58 0 0 0 1.055 1.484v.625a3.696 3.696 0 0 0 3.691 3.691h3.074c.457.628 1.298 1.055 2.27 1.055 1.454 0 2.637-.946 2.637-2.11 0-1.082-1.114-3.163-2.637-3.163" />
    </g>
  </svg>
);
export default SvgStethoscope;
