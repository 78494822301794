import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { CLoadingButton, CModal, CModalBody, CModalFooter, CModalHeader, CSpinner } from '@coreui/react-pro';

import { fetchDocumentTemplates } from 'api/ConsultTemplates';
import { fetchCustomer } from 'api/Customers';
import { createDocument, emailDocument, sendExternalPrescriptionToMixlab } from 'api/Documents';

import { Animal } from 'types/Animal';
import { Consult } from 'types/Consult';
import { ConsultTemplate } from 'types/ConsultTemplate';
import { Customer } from 'types/Customer';
import { Document } from 'types/Document';
import { Prescription } from 'types/Prescription';

import { replaceInputs, replaceKeywords, replaceSignature } from 'utils/documents';

import { RichTextEditor } from 'components/RichTextEditor';

type Props = {
  prescription: Prescription;
  animal: Animal;
  consult?: Consult;
  hideModal: () => void;
};

export const ExternalDispensePrescriptions = ({ prescription, animal, consult, hideModal }: Props) => {
  const [customer, setCustomer] = useState<Customer>();
  const [documentTemplate, setDocumentTemplate] = useState<ConsultTemplate>();
  const [documentPreview, setDocumentPreview] = useState<Partial<Document>>();
  const [isSendingDocument, setIsSendingDocument] = useState<boolean>(false);
  const [dispensingTo, setDispensingTo] = useState<'customer' | 'mixlab'>();

  useEffect(() => {
    fetchDocumentTemplates('external', (templates) =>
      setDocumentTemplate(templates.find((t) => t.external_prescription))
    );
    fetchCustomer(prescription.animal.customer_id, setCustomer);
  }, [prescription.animal.customer_id]);

  useEffect(() => {
    if (documentTemplate && customer) {
      const contextObjects = {
        prescriptions: prescription.prescription_items,
        animal: animal,
        employee: prescription.employee,
        customer: customer
      };

      const replaced = replaceSignature(replaceInputs(replaceKeywords(documentTemplate.content, contextObjects)));
      setDocumentPreview({
        customer_id: customer.id,
        animal_id: animal.id,
        consult_id: consult?.id,
        content: replaced,
        name: documentTemplate.name,
        signed: false,
        requires_signature: false,
        pdf: true
      });
    }
  }, [documentTemplate, customer, animal, prescription, consult?.id]);

  const handleSubmit = (action: 'mixlab' | 'customer') => {
    if (documentPreview) {
      setIsSendingDocument(true);
      setDispensingTo(action);

      const successAction = action === 'mixlab' ? handleSendExternalPrescription : handleEmailToCustomer;
      createDocument(documentPreview, { onSuccess: successAction, onError: handleError });
    }
  };

  const handleSendExternalPrescription = (document: Document) => {
    sendExternalPrescriptionToMixlab(document.id, {
      onSuccess: (savedDoc) => handleSuccess(savedDoc, 'Mixlab'),
      onError: handleError
    });
  };

  const handleEmailToCustomer = (document: Document) => {
    emailDocument(
      document.id,
      { onSuccess: (savedDoc) => handleSuccess(savedDoc, 'customer'), onError: handleError },
      customer?.email
    );
  };

  const handleSuccess = (document: Document, dispensedTo: string) => {
    setIsSendingDocument(false);
    toast.success(`External medication sent to ${dispensedTo}!`);
    hideModal();
  };

  const handleError = () => {
    setIsSendingDocument(false);
    setDispensingTo(undefined);
  };

  return (
    <CModal onClose={hideModal} visible backdrop="static" className="show d-block" size="xl" fullscreen="sm">
      <CModalHeader>
        <h4>Send for External Dispense</h4>
      </CModalHeader>
      <CModalBody>
        {documentPreview?.content ? (
          <RichTextEditor
            id="content"
            label=""
            name="content"
            value={documentPreview.content || ''}
            onChange={(event) => setDocumentPreview({ ...documentPreview, content: event })}
            required
            text="Required"
            editorType="document"
          />
        ) : (
          <CSpinner />
        )}

        <CModalFooter className="p-0 pt-2" style={{ border: 'none' }}>
          <CLoadingButton
            loading={isSendingDocument && dispensingTo === 'customer'}
            onClick={() => handleSubmit('customer')}
            disabled={!documentPreview || isSendingDocument}
          >
            Send to Customer
          </CLoadingButton>
          <CLoadingButton
            loading={isSendingDocument && dispensingTo === 'mixlab'}
            onClick={() => handleSubmit('mixlab')}
            disabled={!documentPreview || isSendingDocument}
          >
            Send to Mixlab
          </CLoadingButton>
        </CModalFooter>
      </CModalBody>
    </CModal>
  );
};
