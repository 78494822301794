import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HealthPlanItem } from 'views/appointments/HealthPlanItem';

import { CButton, CLoadingButton, CNav, CNavItem, CNavLink, CRow, CSmartTable, CSpinner } from '@coreui/react-pro';

import { fetchDueAnimalHealthPlans } from 'api/AnimalHealthPlans';
import { fetchAnimal } from 'api/Animals';
import { fetchAppointment, updateAppointment } from 'api/Appointments';
import { fetchCustomer } from 'api/Customers';
import { fetchForwardBookings } from 'api/ForwardBookings';
import { fetchInvoicesForConsult } from 'api/Invoices';
import { upgradePlan } from 'api/Memberships';
import { fetchPrescriptionItemsForConsult } from 'api/PrescriptionItems';

import { Animal } from 'types/Animal';
import { AnimalHealthPlan } from 'types/AnimalHealthPlan';
import { Appointment } from 'types/Appointment';
import { Customer } from 'types/Customer';
import { DispenseRecord } from 'types/DispenseRecord';
import { ForwardBooking } from 'types/ForwardBooking';
import { Invoice } from 'types/Invoice';
import { PrescriptionItem } from 'types/PrescriptionItem';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { compactDateDisplay } from 'utils/dates';
import { pluralize } from 'utils/strings';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { Pill } from 'components/Pill';

import styles from './AppointmentCheckout.module.scss';

import { CheckoutInvoices } from './CheckoutInvoices';
import { PatientClientAppointmentDetails } from './PatientClientAppointmentDetails';

export const sidebarSteps = ['upcoming', 'fulfilled'] as const;
export type SidebarStep = (typeof sidebarSteps)[number];

const AppointmentCheckout = (): JSX.Element => {
  type AppointmentEditParams = { id: string };
  const { id } = useParams<keyof AppointmentEditParams>() as AppointmentEditParams;

  const [isLoading, setIsLoading] = useState(false);
  const [appointment, setAppointment] = useState<Appointment>();
  const [animal, setAnimal] = useState<Animal>();
  const [customer, setCustomer] = useState<Customer>();
  const [forwardBookings, setForwardBookings] = useState<ForwardBooking[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [prescriptionItems, setPrescriptionItems] = useState<PrescriptionItem[]>([]);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isDepartModalVisible, setIsDepartModalVisible] = useState(false);

  const [sidebarStep, setSidebarStep] = useState<SidebarStep>(sidebarSteps[0]);
  const [activeHealthPlans, setActiveHealthPlans] = useState<AnimalHealthPlan[]>([]);
  const [dueHealthPlans, setDueHealthPlans] = useState<AnimalHealthPlan[]>([]);

  useDocumentTitle('Checkout', appointment?.animal?.name);

  useEffect(() => {
    fetchAppointment(id, setAppointment);
  }, [id]);

  useEffect(() => {
    if (appointment) {
      fetchAnimal(appointment.animal_id, setAnimal);
      fetchDueAnimalHealthPlans(appointment.animal_id, setDueHealthPlansFromAppointment);
      if (appointment.consult_id) {
        fetchForwardBookings(appointment.consult_id, setForwardBookings);
        fetchInvoicesForConsult(appointment.consult_id, setInvoices);

        fetchPrescriptionItemsForConsult(appointment.consult_id, {
          params: { without_injectables: true },
          onSuccess: setPrescriptionItems
        });
      }
      if (appointment.customer_id) fetchCustomer(appointment.customer_id, setCustomer);
    }
  }, [appointment]);

  const setDueHealthPlansFromAppointment = (healthPlans: AnimalHealthPlan[]) => {
    setDueHealthPlans(healthPlans);
    setActiveHealthPlans(healthPlans);
  };

  const confirmDepart = () => {
    setIsDepartModalVisible(true);
  };

  const markDeparted = () => {
    if (appointment) {
      setIsLoading(true);
      updateAppointment(
        appointment.id,
        { status: 'Departed' },
        { onSuccess: onMarkDepartedSuccess, onError: onMarkDepartedError }
      );
    }
  };

  const upgradeCustomer = () => {
    if (customer?.membership && customer.membership.plan.upgrade_plan) {
      setIsLoading(true);
      upgradePlan(customer.membership.id, customer.membership.plan.upgrade_plan?.slug, () => {
        if (appointment) {
          fetchAnimal(appointment?.animal_id, setAnimal);
        }
        fetchCustomer(customer.id, setCustomer);
        setIsConfirmationModalVisible(false);
        setIsLoading(false);
        toast.success('Plan upgraded successfully!');
      });
    }
  };

  const onMarkDepartedSuccess = () => {
    setIsLoading(false);
    toast.success('Appointment marked as departed');
    setIsDepartModalVisible(false);
    fetchAppointment(id, setAppointment);
  };

  const onMarkDepartedError = (message: string) => {
    setIsLoading(false);
    toast.error(`Error while marking appointment as departed: ${message}`);
  };

  const updateSidebarStep = (step: SidebarStep) => {
    setSidebarStep(step);
    if (step === 'upcoming') {
      setActiveHealthPlans(dueHealthPlans);
    } else {
      setActiveHealthPlans(appointment?.animal_health_plans || []);
    }
  };

  const newestStatus = (dispenseRecords: DispenseRecord[]) => {
    const newestDispenseRecord = dispenseRecords.sort(
      (first, second) => new Date(second.updated_at).getTime() - new Date(first.updated_at).getTime()
    )[0];

    return newestDispenseRecord.status;
  };

  const confirmDepartModal = () => {
    const unpaidInvoices =
      invoices.filter((invoice) => invoice.status === 'approved' && !invoice.fully_paid).length || 0;
    const undispensedPrescriptions =
      prescriptionItems.filter(
        (item) =>
          item.dispense_records &&
          item.dispense_records.length > 0 &&
          newestStatus(item.dispense_records) !== 'fulfilled'
      ).length || 0;

    return (
      <ConfirmationModal
        isVisible={isDepartModalVisible}
        isLoading={isLoading}
        modalHeader={`Mark ${appointment?.animal?.name} as Departed`}
        modalBody="Are you sure you want to mark this appointment as departed?"
        onConfirm={markDeparted}
        onClose={() => setIsDepartModalVisible(false)}
        confirmButtonLabel="Confirm"
      >
        {unpaidInvoices > 0 && (
          <div>
            - {unpaidInvoices} {pluralize('invoice', unpaidInvoices)} {unpaidInvoices > 1 ? 'are' : 'is'} still unpaid
          </div>
        )}
        {undispensedPrescriptions > 0 && (
          <div>
            - {undispensedPrescriptions} {pluralize('medication', undispensedPrescriptions)}{' '}
            {undispensedPrescriptions > 1 ? 'are' : 'is'} not yet dispensed
          </div>
        )}
        {unpaidInvoices === 0 && undispensedPrescriptions === 0 && (
          <div>All invoices are paid, and all medications are dispensed.</div>
        )}
      </ConfirmationModal>
    );
  };

  const loading = !appointment && !animal;
  if (loading) {
    return <CSpinner color="primary" />;
  }

  return (
    <div className="d-flex" style={{ gap: '30px' }}>
      <div className={styles.mainPage}>
        <div className={styles.titleRow}>
          <h1 className="m-0">Checkout</h1>
          {appointment && appointment.status.toLowerCase() !== 'complete' && (
            <CLoadingButton
              disabled={appointment.status.toLowerCase() === 'departed'}
              loading={isLoading}
              color="success"
              onClick={confirmDepart}
            >
              Mark Departed
            </CLoadingButton>
          )}
        </div>

        {appointment && animal && appointment.customer && (
          <>
            <CRow>
              <PatientClientAppointmentDetails
                appointment={appointment}
                animal={animal}
                upgradeButton={
                  customer?.membership?.plan.is_upgradable && (
                    <CButton size="sm" onClick={() => setIsConfirmationModalVisible(true)}>
                      Upgrade
                    </CButton>
                  )
                }
              />
            </CRow>

            <CRow className="mt-3">
              <h2>Forward Bookings</h2>
              <CSmartTable
                tableProps={{ 'aria-label': 'Forward Bookings' }}
                tableHeadProps={{ color: 'dark' }}
                columns={[
                  'status',
                  'employee',
                  'due_date_description',
                  'due_date',
                  'appointment_type',
                  'notes',
                  'actions'
                ]}
                items={forwardBookings}
                scopedColumns={{
                  notes: (item: ForwardBooking) => <td dangerouslySetInnerHTML={{ __html: item.notes }} />,
                  due_date_description: (item: ForwardBooking) => <td>{item.due_date_description}</td>,
                  due_date: (item: ForwardBooking) => <td>{compactDateDisplay(item.due_date)}</td>,
                  status: (item: ForwardBooking) => (
                    <td>
                      <Pill label={item.status} />
                    </td>
                  ),
                  appointment_type: (item: ForwardBooking) => (
                    <td>
                      <Link to={`/appointments/types/${item.appointment_type_id}`}>
                        {item.appointment_type.name_in_pim}
                      </Link>
                    </td>
                  ),
                  employee: (item: ForwardBooking) => (
                    <td>
                      <Link to={`/employees/${item.employee_id}`}>{item.employee.full_name_with_title}</Link>
                    </td>
                  ),
                  actions: (item: ForwardBooking) => (
                    <td>
                      <div className="d-flex align-items-center gap-1">
                        {customer?.membership?.plan.is_upgradable && (
                          <CButton size="sm" onClick={() => setIsConfirmationModalVisible(true)}>
                            Upgrade
                          </CButton>
                        )}
                        <CButton size="sm" href={`/appointments/new?forward_booking=${item.id}`} target="_blank">
                          Book Now
                        </CButton>
                      </div>
                    </td>
                  )
                }}
              />

              <h2>Invoices</h2>
              {customer && (
                <CheckoutInvoices
                  invoices={invoices}
                  customer={customer}
                  onInvoicePaid={() => fetchInvoicesForConsult(appointment.consult_id, setInvoices)}
                />
              )}

              <h2>Medications</h2>
              <CSmartTable
                tableProps={{ 'aria-label': 'Medications' }}
                tableHeadProps={{ color: 'dark' }}
                items={prescriptionItems}
                columns={[{ key: 'name' }, { key: 'quantity' }, { key: 'instructions' }, { key: 'fulfillment_status' }]}
                scopedColumns={{
                  name: (item: PrescriptionItem) => <td>{item.product?.name}</td>,
                  instructions: (item: PrescriptionItem) => (
                    <td dangerouslySetInnerHTML={{ __html: item.instructions }} />
                  ),
                  fulfillment_status: (item: PrescriptionItem) => (
                    <td>
                      {item.dispense_records && item.dispense_records.length > 0 && (
                        <Pill label={newestStatus(item.dispense_records)} />
                      )}
                    </td>
                  )
                }}
              />
            </CRow>
          </>
        )}

        <div className="d-flex justify-content-end mb-3">
          {appointment && appointment.status.toLowerCase() !== 'complete' && (
            <CLoadingButton
              disabled={appointment.status.toLowerCase() === 'departed'}
              loading={isLoading}
              color="success"
              onClick={confirmDepart}
            >
              Mark Departed
            </CLoadingButton>
          )}
        </div>
      </div>

      <div className={styles.sidePanel}>
        <div className={styles.mainContent}>
          <h1>Health Plans</h1>
          <CNav
            role="list"
            className="d-flex justify-content-between flex-nowrap"
            variant="pills"
            style={{ marginBottom: '15px' }}
          >
            {sidebarSteps.map((step) => (
              <CNavItem key={step}>
                <CNavLink
                  className="text-capitalize text-nowrap"
                  role="button"
                  active={step === sidebarStep}
                  onClick={() => updateSidebarStep(step)}
                >
                  {step.split('-').join(' ')}
                </CNavLink>
              </CNavItem>
            ))}
          </CNav>

          <div className={styles.healthPlanColumn}>
            {activeHealthPlans.map((healthPlan) => (
              <HealthPlanItem key={healthPlan.id} healthPlan={healthPlan} />
            ))}
            {!activeHealthPlans.length && <div>No health plans</div>}
          </div>
        </div>
      </div>
      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        isLoading={isLoading}
        modalHeader="Upgrade Plan"
        modalBody="Are you sure you want to upgrade?"
        onConfirm={upgradeCustomer}
        onClose={() => setIsConfirmationModalVisible(false)}
        confirmButtonLabel="Confirm"
      />
      {confirmDepartModal()}
    </div>
  );
};

export default AppointmentCheckout;
