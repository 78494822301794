import React, { useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { CButton, CCol, CContainer, CForm, CFormCheck, CFormInput, CRow } from '@coreui/react-pro';

import { fetchAppointmentTypes } from 'api/AppointmentTypes';
import { fetchSchedulingCategory, updateSchedulingCategory } from 'api/SchedulingCategories';

import { AppointmentType } from 'types/AppointmentType';
import { SchedulingCategory } from 'types/SchedulingCategory';
import { SchedulingCategoryInput } from 'types/SchedulingCategoryInput';

const SchedulingCategoryEdit: React.FC = () => {
  const navigate = useNavigate();

  const [schedulingCategoryInput, setSchedulingCategoryInput] = React.useState<SchedulingCategoryInput>({
    name: '',
    color: '#aabbcc',
    appointment_type_ids: []
  });

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      name: '',
      color: '#aabbcc',
      appointment_type_ids: []
    },
    values: schedulingCategoryInput
  });

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    fetchSchedulingCategory(Number(id), setFormValues);
  }, [id]);

  const onSubmit = (data: SchedulingCategoryInput) => {
    if (id) {
      updateSchedulingCategory(Number(id), data, updateSuccess);
    }
  };

  const setFormValues = (schedulingCategory: SchedulingCategory) => {
    const appointmentTypeIds: string[] = schedulingCategory.appointment_types.map((appointmentType: AppointmentType) =>
      appointmentType.id.toString()
    );
    setSchedulingCategoryInput({
      name: schedulingCategory.name,
      color: schedulingCategory.color,
      appointment_type_ids: appointmentTypeIds
    });
  };

  const updateSuccess = () => {
    navigate('/scheduling_categories');
  };

  const [appointmentTypes, setAppointmentTypes] = React.useState<AppointmentType[]>([]);
  useEffect(() => {
    fetchAppointmentTypes(setAppointmentTypes);
  }, []);

  return (
    <CContainer sm>
      <CRow>
        <CCol sm={6}>
          <h1>Edit Scheduling Category</h1>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormInput type="text" required label="Name" {...register('name')} className="mb-3" />
            {appointmentTypes.map((appointmentType, index) => {
              return (
                <div key={index}>
                  <CFormCheck
                    id={`appointmentType-${appointmentType.id}`}
                    value={appointmentType.id}
                    label={appointmentType.name_in_pim}
                    {...register('appointment_type_ids')}
                  />
                </div>
              );
            })}
            <Controller
              name="color"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <HexColorPicker className="mt-3" color={field.value} {...field} />}
            />
            <CButton type="submit" className="mt-5">
              Save
            </CButton>
          </CForm>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default SchedulingCategoryEdit;
