import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';

import { createProduct } from 'api/Products';

import { Product } from 'types/Product';
import { priceToAttributes, ProductPrice } from 'types/ProductPrice';
import { ProductType } from 'types/ProductType';

import Form from './Form';

const New = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<Product>({ status: 'active' } as Product);
  const [productPrices, setProductPrices] = useState<Partial<ProductPrice>[]>([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const name = params.get('name') || '';
    const code = params.get('code') || '';
    const product_type: ProductType = (params.get('product_type') as ProductType) || 'Procedure';
    setProduct((prevProduct) => ({ ...prevProduct, name, code, product_type }));
  }, [location.search]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (event.target.type === 'checkbox') {
      setProduct({
        ...product,
        [event.target.id]: 'checked' in event.target ? (event.target.checked ? 'active' : 'disabled') : null
      });
    } else if (event.target.id === 'product_type' && event.target.value !== 'Medication') {
      setProduct({ ...product, product_type: event.target.value as ProductType, default_instructions: null });
    } else {
      setProduct({ ...product, [event.target.id]: event.target.value });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const productPricesAttributes = productPrices.map((price) => {
      return priceToAttributes(price);
    });
    setIsLoading(true);
    createProduct({ ...product, product_prices_attributes: productPricesAttributes }, updateSuccess, updateError);
  };

  const updateError = () => {
    setIsLoading(false);
    toast.error('Error creating product');
  };

  const updateSuccess = (product: Product) => {
    setIsLoading(false);
    toast.success('Product created!');
    navigate(`/products/${product.id}`);
  };

  const handleCancel = () => {
    navigate(`/products`);
  };

  return (
    <CCard>
      <CCardHeader>
        <h3>New Product</h3>
      </CCardHeader>
      <CCardBody>
        <Form
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleChange={handleChange}
          product={product}
          productPrices={productPrices}
          setProductPrices={setProductPrices}
          isLoading={isLoading}
        />
      </CCardBody>
    </CCard>
  );
};

export default New;
