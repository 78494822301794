import * as React from 'react';

import { CButton } from '@coreui/react-pro';

import { Assessment } from 'types/Assessment';
import { ConsultPlan } from 'types/ConsultPlan';
import { ConsultPresentingProblem } from 'types/ConsultPresentingProblem';
import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { EmployeeTask } from 'types/EmployeeTask';
import { Estimate } from 'types/Estimate';
import { ForwardBooking } from 'types/ForwardBooking';
import { HealthStatus } from 'types/HealthStatus';
import { History } from 'types/History';
import { Invoice } from 'types/Invoice';
import { PhysicalExam } from 'types/PhysicalExam';
import { Prescription } from 'types/Prescription';
import { Procedure } from 'types/Procedure';
import { Vaccination } from 'types/Vaccination';

import { compactDateTimeDisplay } from 'utils/dates';

type Props = {
  item:
    | Assessment
    | ConsultPlan
    | ConsultPresentingProblem
    | DiagnosticRequest
    | EmployeeTask
    | Estimate
    | ForwardBooking
    | HealthStatus
    | History
    | Invoice
    | PhysicalExam
    | Prescription
    | Procedure
    | Vaccination;
  handleClick: () => void;
};

export const TableAuditData = ({ item, handleClick }: Props) => {
  return (
    <td>
      <div>{item.updated_by_employee?.full_name_with_title}</div>
      {item.updated_at && (
        <CButton color="link" className="p-0 text-align-start" onClick={handleClick}>
          {compactDateTimeDisplay(item.updated_at)}
        </CButton>
      )}
    </td>
  );
};
