import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CButton, CCol, CDatePicker, CForm, CFormSwitch, CFormTextarea, CLoadingButton, CRow } from '@coreui/react-pro';

import { updateAnimalHealthPlan } from 'api/AnimalHealthPlans';

import { AnimalHealthPlan, HealthPlanStatus } from 'types/AnimalHealthPlan';

type EditAnimalHealthPlanFormProps = {
  healthPlan: AnimalHealthPlan;
  onSuccess: () => void;
  onCancel: () => void;
};

const EditAnimalHealthPlan = ({ healthPlan, onSuccess, onCancel }: EditAnimalHealthPlanFormProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [status, setStatus] = useState<HealthPlanStatus>(healthPlan.status);
  const [nextDueDate, setNextDueDate] = useState<Date | null>(
    healthPlan.next_due_date ? new Date(healthPlan.next_due_date) : null
  );
  const [internalNotes, setInternalNotes] = useState(healthPlan?.internal_notes ?? '');
  const [patientNotes, setPatientNotes] = useState(healthPlan?.patient_notes ?? '');

  const updateStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ('checked' in event.target) {
      setStatus(event.target.checked ? 'active' : 'disabled');
    }
  };

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    updateAnimalHealthPlan(
      healthPlan.id,
      {
        ...healthPlan,
        next_due_date: nextDueDate?.toString(),
        internal_notes: internalNotes,
        patient_notes: patientNotes,
        status: status
      },
      {
        onSuccess: () => {
          toast.success(`${healthPlan.health_plan.name} updated!`);
          onSuccess();
        },
        onError: (errorMessage: string) => {
          setIsSubmitting(false);
        }
      }
    );
  };

  return (
    <CForm onSubmit={submit}>
      <CRow className="mb-3">
        <CCol>
          <CDatePicker
            label="Next Due Date"
            onDateChange={setNextDueDate}
            id="started"
            locale="en-US"
            date={nextDueDate}
            required
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol>
          <CFormTextarea
            id="internalNotes"
            label="Internal Notes"
            value={internalNotes}
            onChange={(event) => setInternalNotes(event.target.value)}
            placeholder="Notes for internal use..."
            name="internal_notes"
            rows={3}
            text={
              <>
                <Link to="https://daringfireball.net/projects/markdown/">Markdown</Link> supported
              </>
            }
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol>
          <CFormTextarea
            id="patientNotes"
            label="Client Communications"
            onChange={(event) => setPatientNotes(event.target.value)}
            value={patientNotes}
            name="patient_notes"
            rows={3}
            text={
              <>
                <Link to="https://daringfireball.net/projects/markdown/">Markdown</Link> supported
              </>
            }
          />
        </CCol>
      </CRow>
      {status !== 'fulfilled' && (
        <CRow>
          <CCol>
            <CFormSwitch
              id="status"
              label="Is Active?"
              aria-label="Is Active?"
              checked={status === 'active' ?? false}
              onChange={updateStatus}
            />
          </CCol>
        </CRow>
      )}

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
        <CButton color="secondary" disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </CButton>
        <CLoadingButton color="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Update
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default EditAnimalHealthPlan;
