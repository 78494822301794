import React, { useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CButton, CCol, CContainer, CForm, CFormCheck, CFormInput, CRow } from '@coreui/react-pro';

import { fetchAppointmentTypes } from 'api/AppointmentTypes';
import { createSchedulingCategory } from 'api/SchedulingCategories';

import { AppointmentType } from 'types/AppointmentType';
import { SchedulingCategoryInput } from 'types/SchedulingCategoryInput';

const SchedulingCategoryNew: React.FC = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      name: '',
      color: '#aabbcc',
      appointment_type_ids: []
    }
  });

  const onSubmit = (data: SchedulingCategoryInput) => {
    createSchedulingCategory(data, createSuccess);
  };

  const createSuccess = () => {
    navigate('/scheduling_categories');
  };

  const [appointmentTypes, setAppointmentTypes] = React.useState<AppointmentType[]>([]);
  useEffect(() => {
    fetchAppointmentTypes(setAppointmentTypes);
  }, []);

  return (
    <CContainer sm>
      <CRow>
        <CCol sm={6}>
          <h1>New Scheduling Category</h1>
          <CForm onSubmit={handleSubmit(onSubmit)}>
            <CFormInput type="text" required label="Name" {...register('name')} className="mb-3" />
            {appointmentTypes.map((appointmentType, index) => {
              return (
                <div key={index}>
                  <CFormCheck
                    id={`appointmentType-${appointmentType.id}`}
                    value={appointmentType.id}
                    label={appointmentType.name_in_pim}
                    {...register('appointment_type_ids')}
                  />
                </div>
              );
            })}
            <Controller
              name="color"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <HexColorPicker className="mt-3" color={field.value} {...field} />}
            />

            <CButton type="submit" className="mt-5">
              Save
            </CButton>
          </CForm>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default SchedulingCategoryNew;
