import axios from 'axios';

import { AttachmentType } from 'types/AttachmentType';

const fetchAttachmentTypes = ({ onSuccess }: { onSuccess: (attachmentTypes: AttachmentType[]) => void }) => {
  const url = `/pim/attachment_types`;

  axios.get<AttachmentType[]>(url).then((data) => {
    onSuccess(data.data.record);
  });
};

export { fetchAttachmentTypes };
