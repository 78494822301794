import axios from 'axios';

import { PresentingProblem } from 'types/PresentingProblem';

const fetchPresentingProblemsByQuery = (query: string) => {
  const url = '/pim/presenting_problems';

  return axios
    .get<PresentingProblem[]>(url, { params: { query: query } })
    .then((response) => response.data.record)
    .catch((error) => []);
};

export { fetchPresentingProblemsByQuery };
