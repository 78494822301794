import * as React from 'react';
import type { SVGProps } from 'react';

const SvgTwoPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M6.872 8.153a1.4 1.4 0 0 0-.248 0A3.315 3.315 0 0 1 3.422 4.83a3.327 3.327 0 0 1 3.33-3.33 3.327 3.327 0 0 1 .12 6.653M12.308 3a2.623 2.623 0 0 1 2.625 2.625 2.63 2.63 0 0 1-2.527 2.625 1 1 0 0 0-.195 0M3.12 10.92c-1.816 1.215-1.816 3.195 0 4.403 2.062 1.38 5.444 1.38 7.507 0 1.815-1.216 1.815-3.196 0-4.403-2.055-1.373-5.438-1.373-7.508 0M13.754 15c.54-.113 1.05-.33 1.47-.652 1.17-.878 1.17-2.325 0-3.203-.413-.315-.915-.525-1.448-.645" />
    </g>
  </svg>
);
export default SvgTwoPeople;
