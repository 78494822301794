import axios, { AxiosError } from 'axios';

import { ApiResponse } from 'api/ResponseTypes';

import { ConsultPlan } from 'types/ConsultPlan';

const fetchConsultPlansByAnimal = (animalId: string, onSuccess: (consultPlan: ConsultPlan[]) => void) => {
  const url = `/pim/consult_plans?animal_id=${animalId}`;
  axios.get<ConsultPlan[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchConsultPlansForConsult = (consultId: number | string, onSuccess: (consultPlans: ConsultPlan[]) => void) => {
  const url = `/pim/consult_plans`;
  axios.get<ConsultPlan[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const createConsultPlan = (
  consultPlan: Partial<ConsultPlan>,
  { onSuccess, onError }: { onSuccess: (consultPlan: ConsultPlan) => void; onError: (error: string) => void }
) => {
  const url = `/pim/consult_plans`;

  axios
    .post<ConsultPlan>(url, { consult_plan: consultPlan })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<ConsultPlan>>) => onError(error.message));
};

const updateConsultPlan = (
  consultPlanId: number,
  params: object,
  { onSuccess, onError }: { onSuccess: (consultPlan: ConsultPlan) => void; onError: (error: string) => void }
) => {
  const url = `/pim/consult_plans/${consultPlanId}`;

  axios
    .put<ConsultPlan>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<ConsultPlan>>) => onError(error.message));
};

export { fetchConsultPlansForConsult, fetchConsultPlansByAnimal, createConsultPlan, updateConsultPlan };
