import axios from 'axios';

import { Memo } from 'types/Memo';
import { Pagination } from 'types/Pagination';

const createMemo = ({
  params,
  onSuccess,
  onError
}: {
  params: Partial<Memo>;
  onSuccess: (memos: Memo) => void;
  onError?: (error: string) => void;
}) => {
  const url = `/pim/memos`;
  axios
    .post<Memo>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};

const fetchMemos = ({
  onSuccess,
  setPagination,
  page
}: {
  onSuccess: (memos: Memo[]) => void;
  setPagination: (pagination: Pagination) => void;
  page?: number;
}) => {
  const url = `/pim/memos`;

  axios
    .get<Memo[]>(url, {
      params: {
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
    });
};

const replyToMemo = ({
  memo_id,
  body,
  onSuccess,
  onError
}: {
  memo_id: number;
  body: string;
  onSuccess: (memos: Memo) => void;
  onError?: (error: string) => void;
}) => {
  const url = `/pim/memos/${memo_id}/reply`;
  axios
    .post<Memo>(url, { memo_id, body })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};

const markRead = ({
  memo_id,
  onSuccess,
  onError
}: {
  memo_id: number;
  onSuccess?: (memos: Memo) => void;
  onError?: (error: string) => void;
}) => {
  const url = `/pim/memos/${memo_id}/mark_read`;
  axios
    .put<Memo>(url)
    .then((data) => {
      if (onSuccess) onSuccess(data.data.record);
    })
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};
export { createMemo, fetchMemos, replyToMemo, markRead };
