import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';

import { fetchHealthPlan } from 'api/HealthPlans';
import { createHealthPlanTrigger } from 'api/HealthPlanTrigger';

import { HealthPlan } from 'types/HealthPlan';
import { HealthPlanTrigger } from 'types/HealthPlanTrigger';

import TriggerForm from './TriggerForm';

const AddTrigger = (): JSX.Element => {
  const navigate = useNavigate();
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [healthPlan, setHealthPlan] = React.useState<HealthPlan>();

  useEffect(() => {
    fetchHealthPlan(id, setHealthPlan);
  }, [id]);

  const handleSubmit = (trigger: HealthPlanTrigger) => {
    createHealthPlanTrigger({ ...trigger, health_plan_id: Number(id) }, handleSuccess);
  };

  const handleSuccess = () => {
    navigate(`/health_plans/${healthPlan?.id}`);
  };

  const handleCancel = () => {
    navigate(`/health_plans/${healthPlan?.id}`);
  };

  return (
    <div>
      {healthPlan && (
        <CCard style={{ width: '35rem' }}>
          <CCardHeader>
            <h3>Add Trigger to Plan</h3>
          </CCardHeader>
          <CCardBody>
            <TriggerForm handleSubmit={handleSubmit} handleCancel={handleCancel} healthPlan={healthPlan} />
          </CCardBody>
        </CCard>
      )}
    </div>
  );
};

export default AddTrigger;
