import * as React from 'react';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { createCommunicationLog, fetchCommunicationLogsForConsult } from 'api/CommunicationLogs';
import { fetchContextsForConsult } from 'api/Contexts';

import { CommunicationLog } from 'types/CommunicationLog';
import { CommunicationType } from 'types/CommunicationType';
import { Consult } from 'types/Consult';
import { Context } from 'types/Context';

import { usePoll } from 'hooks/usePoll';

import SvgMessages from 'assets/images/SvgMessages';
import SvgOutgoing from 'assets/images/SvgOutgoing';

import { CommunicationLogItem } from 'components/CommunicationLogItem';
import { IconButton } from 'components/IconButton';
import { NewLogModal } from 'components/NewLogModal';
import { NewMessageModal } from 'components/NewMessageModal';

type Modals = 'new-message' | 'new-log';

type Props = {
  consult: Consult;
};

export const CommunicationsStep = ({ consult }: Props) => {
  const [communicationLogs, setCommunicationLogs] = useState<CommunicationLog[]>([]);
  const [contexts, setContexts] = useState<Context[]>([]);
  const [visibleModal, setVisibleModal] = useState<Modals>();
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(() => fetchCommunicationLogsForConsult(consult.id, setCommunicationLogs), [consult]);
  usePoll(fetch);

  const hideModal = () => setVisibleModal(undefined);

  const handleSuccess = (message: string) => {
    fetch();
    setLoading(false);
    hideModal();
    toast.success(message);
  };

  const handleError = () => setLoading(false);

  const handleCreateMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = {
      communication_type: 'message' as CommunicationType,
      consult_id: consult.id,
      ...Object.fromEntries(formData.entries())
    };

    createCommunicationLog(formJson, { onSuccess: () => handleSuccess('Message sent!'), onError: handleError });
  };

  const handleCreateLog = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);

    const formJson = {
      consult_id: consult.id,
      ...Object.fromEntries(formData.entries())
    };

    createCommunicationLog(formJson, { onSuccess: () => handleSuccess('Log created!'), onError: handleError });
  };

  const renderModal = (visibleModal?: Modals) => {
    switch (visibleModal) {
      case 'new-message':
        return (
          <NewMessageModal contexts={contexts} hideModal={hideModal} loading={loading} onSubmit={handleCreateMessage} />
        );
      case 'new-log':
        return <NewLogModal contexts={contexts} hideModal={hideModal} loading={loading} onSubmit={handleCreateLog} />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderModal(visibleModal)}

      <div className="d-flex align-items-center justify-content-end gap-2 mb-3">
        <IconButton
          onClick={() => {
            fetchContextsForConsult(consult.id, (contexts: Context[]) => {
              setContexts(contexts);
              setVisibleModal('new-log');
            });
          }}
          label="New Log"
          icon={SvgOutgoing}
        />
        <IconButton
          onClick={() => {
            fetchContextsForConsult(consult.id, (contexts: Context[]) => {
              setContexts(contexts);
              setVisibleModal('new-message');
            });
          }}
          label="New Message"
          icon={SvgMessages}
        />
      </div>

      {communicationLogs.length ? (
        <div>
          {communicationLogs.map((log) => (
            <CommunicationLogItem key={log.id} communicationLog={log} />
          ))}
        </div>
      ) : (
        <div>Communications will appear here.</div>
      )}
    </>
  );
};
