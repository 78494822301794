import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { CCol, CFormInput, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';

import { payInvoice } from 'api/Invoices';

import { Customer } from 'types/Customer';
import { Invoice } from 'types/Invoice';
import { Option } from 'types/Option';
import { CashPayment, Payment, PaymentMethod } from 'types/Payment';

import { toCurrency, toPennies } from 'utils/price';
import { PhysicalPayments } from 'utils/stripePayments';

import { ConfirmationModal } from 'components/ConfirmationModal';

type Props = {
  invoice: Invoice;
  customer: Customer;
  isVisible: boolean;
  onClose: () => void;
  onConfirm: (invoice: Invoice) => void;
};

export const PaymentInvoiceModal = ({ invoice, customer, isVisible, onClose, onConfirm }: Props) => {
  const [paymentAmount, setPaymentAmount] = useState<number>(invoice.unpaid_total / 100);
  const [paymentAmountPennies, setPaymentAmountPennies] = useState<number>(invoice.unpaid_total);
  const [paymentIsInvalid, setPaymentIsInvalid] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>('CardOnFile');
  const [paymentIdentifier, setPaymentIdentifier] = useState<string>();
  const [paymentMethodOptions] = useState<Option[]>([
    { label: `Card on file (x${customer.membership?.credit_card_last_4})`, value: 'CardOnFile' },
    { label: 'Physical Payment', value: 'PhysicalCard' },
    { label: 'Cash', value: 'Cash' },
    { label: 'Care Credit', value: 'CareCredit' }
  ]);

  const [physicalPayment, setPhysicalPayment] = useState<PhysicalPayments>();
  const [terminalStatus, setTerminalStatus] = useState<string>();

  const handlePaymentMethodelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value;
    setSelectedPaymentMethod(selected as PaymentMethod);
  };

  const onCancelSelected = () => {
    physicalPayment?.cancelPaymentCollection();
    setIsLoading(false);
    setTerminalStatus(undefined);
    onClose();
  };

  const onConfirmSelected = () => {
    if (paymentIsInvalid) {
      toast.error('Payment amount is invalid.');
      return;
    }
    if (selectedPaymentMethod === 'CardOnFile') chargeCardOnFile();
    else if (selectedPaymentMethod === 'PhysicalCard') launchPhysicalPayment();
    else if (selectedPaymentMethod === 'Cash' || selectedPaymentMethod === 'CareCredit') manualPay();
  };

  useEffect(() => {
    const pennies = toPennies(paymentAmount);
    setPaymentAmountPennies(pennies);
    if (selectedPaymentMethod === 'Cash') {
      setPaymentIsInvalid(!paymentAmount || paymentAmount <= 0);
    } else {
      setPaymentIsInvalid(!paymentAmount || paymentAmount <= 0 || pennies > invoice.unpaid_total);
    }
  }, [invoice.unpaid_total, paymentAmount, selectedPaymentMethod]);

  const chargeCardOnFile = () => {
    setIsLoading(true);
    if (invoice.id) payInvoice(invoice.id, paymentAmountPennies, paymentSuccessful, paymentFailed);
  };

  const launchPhysicalPayment = () => {
    setIsLoading(true);
    const physicalPayment = new PhysicalPayments(
      paymentAmountPennies,
      invoice,
      setTerminalStatus,
      paymentFailed,
      paymentSuccessful
    );
    setPhysicalPayment(physicalPayment);
    physicalPayment.beginPhysicalCardPayment();
  };

  const manualPay = () => {
    setIsLoading(true);
    if (invoice.id)
      payInvoice(
        invoice.id,
        paymentAmountPennies,
        paymentSuccessful,
        paymentFailed,
        selectedPaymentMethod,
        paymentIdentifier
      );
  };

  const paymentFailed = (errorMessage: string) => {
    setIsLoading(false);
    setTerminalStatus(undefined);
    toast.error(errorMessage);
  };

  const paymentSuccessful = (payment: Payment | CashPayment | Invoice) => {
    if ('change_due' in payment && payment.change_due > 0) {
      toast.info('Customer is due change of ' + toCurrency(payment.change_due), { autoClose: false });
    } else {
      toast.success('Payment of ' + toCurrency(paymentAmountPennies) + ' successful!');
    }
    setIsLoading(false);
    setTerminalStatus(undefined);
    onConfirm(invoice);
  };

  return (
    <ConfirmationModal
      isVisible={isVisible}
      onClose={onCancelSelected}
      onConfirm={onConfirmSelected}
      confirmButtonLabel={
        terminalStatus
          ? terminalStatus
          : !paymentAmount
          ? 'Charge'
          : selectedPaymentMethod === 'CardOnFile'
          ? `Charge ${toCurrency(paymentAmountPennies)} to card`
          : `Accept payment for ${toCurrency(paymentAmountPennies)}`
      }
      cancelButtonLabel="Cancel"
      modalHeader="Pay Invoice"
      isLoading={isLoading}
      isDisabled={paymentIsInvalid || terminalStatus !== undefined}
    >
      <CFormLabel htmlFor="payment_method" className="mb-1">
        Select payment method
      </CFormLabel>
      <CFormSelect
        id="payment_method"
        options={paymentMethodOptions}
        value={selectedPaymentMethod}
        onChange={handlePaymentMethodelectChange}
      />

      <CRow className="mt-2 mb-2">
        <CCol className="text-end">Invoice Total</CCol>
        <CCol className="me-3 pe-4">{toCurrency(invoice.total)}</CCol>
      </CRow>
      {invoice.total_paid > 0 && (
        <div>
          <CRow className="mb-2">
            <CCol className="text-end">Paid</CCol>
            <CCol className="me-3 pe-4">{toCurrency(invoice.total_paid)}</CCol>
          </CRow>
          <CRow className="mb-1">
            <CCol className="text-end">Amount Due</CCol>
            <CCol className="me-3 pe-4">{toCurrency(invoice.unpaid_total)}</CCol>
          </CRow>
        </div>
      )}
      <CRow className="mb-1 d-flex align-items-center">
        <CCol className="text-end" style={{ fontWeight: 'bold' }}>
          Payment Amount
        </CCol>
        <CCol className="d-flex align-items-center text-end justify-content-end me-3 pe-4">
          $
          <CFormInput
            className="ms-1 pe-0"
            type="number"
            id="payment_amount"
            aria-label="Payment Amount"
            value={paymentAmount}
            required
            step={0.01}
            min={0}
            invalid={paymentIsInvalid}
            onChange={(event) => setPaymentAmount(Number(event.target.value))}
          />
        </CCol>
      </CRow>
      {paymentAmount !== undefined && (
        <CRow className="mb-1">
          <CCol className="text-end">Remainder Due</CCol>
          <CCol className="me-3 pe-4">{toCurrency(invoice.total - (invoice.total_paid + paymentAmountPennies))}</CCol>
        </CRow>
      )}

      {selectedPaymentMethod === 'CareCredit' && (
        <>
          <CFormLabel htmlFor="payment_id" className="mt-3 mb-1">
            Payment ID (optional)
          </CFormLabel>
          <CFormInput
            className="ms-1 pe-0"
            id="payment_id"
            value={paymentIdentifier}
            onChange={(event) => setPaymentIdentifier(event.target.value)}
          />
        </>
      )}
    </ConfirmationModal>
  );
};
