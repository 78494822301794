import axios from 'axios';

import { AppointmentType } from 'types/AppointmentType';

function fetchAppointmentTypes(
  onSuccess: (appointmentTypes: AppointmentType[]) => void,
  onError?: (error: string) => void
) {
  const url = '/pim/appointment_types';
  axios
    .get<AppointmentType[]>(url)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

function fetchAppointmentType(
  appointmentTypeId: string,
  onSuccess: (appointmentTypes: AppointmentType) => void,
  onError?: (error: string) => void
) {
  const url = `/pim/appointment_types/${appointmentTypeId}`;
  axios
    .get<AppointmentType>(url)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

function updateAppointmentType(
  appointmentTypeId: string,
  params: AppointmentType,
  onSuccess: (appointmentTypes: AppointmentType) => void,
  onError?: (error: string) => void
) {
  const url = `/pim/appointment_types/${appointmentTypeId}`;
  axios
    .put<AppointmentType>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

export { fetchAppointmentTypes, fetchAppointmentType, updateAppointmentType };
