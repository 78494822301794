import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { CButton } from '@coreui/react-pro';

import { AnimalHealthPlan, isPastDue } from 'types/AnimalHealthPlan';

import { compactDateDisplay } from 'utils/dates';

import { ReactComponent as DownArrowIcon } from 'assets/images/down-arrow.svg';

import { Pill } from 'components/Pill';

import styles from './HealthPlanItem.module.scss';

type Props = {
  healthPlan: AnimalHealthPlan;
};

export const HealthPlanItem = ({ healthPlan }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={cn(styles.itemContainer, { [styles.important]: isPastDue(healthPlan) })} key={healthPlan.id}>
      <div className={cn('d-flex justify-content-between align-items-center', styles.topRow, styles.row)}>
        <div className="d-flex">
          <div className={styles.left}>
            <Link to={`/health_plans/${healthPlan.health_plan_id}`}>{healthPlan.health_plan.name}</Link>
          </div>
        </div>
        <CButton
          aria-label="toggle details"
          className={styles.toggleButton}
          shape="rounded-pill"
          onClick={toggleExpanded}
        >
          <DownArrowIcon className={cn({ [styles.rotated]: isExpanded })} />
        </CButton>
      </div>

      <div className={cn('d-flex align-items-center justify-content-between', styles.row)}>
        <div className="d-flex justify-content-between gap-2">
          <div className={styles.label}>Next Due</div>
          <div className={cn(styles.value, { [styles.due]: isPastDue(healthPlan) })}>
            {healthPlan.next_due_date && compactDateDisplay(healthPlan.next_due_date)}
          </div>
          <Pill label={healthPlan.health_plan.importance} />
        </div>
      </div>

      <div className={cn(isExpanded ? 'd-flex justify-content-between' : 'd-none', styles.row)}>
        <div>Updated By</div>
        <div className={styles.value}>
          {healthPlan.updated_by_employee && `${healthPlan.updated_by_employee.full_name_with_title}`}
        </div>
      </div>

      {healthPlan.patient_notes && (
        <div className={cn(isExpanded ? 'd-flex flex-column' : 'd-none', styles.row)}>
          <div>Client Communications</div>
          <div className={styles.notes} dangerouslySetInnerHTML={{ __html: healthPlan.patient_notes }} />
        </div>
      )}

      {healthPlan.internal_notes && (
        <div className={cn(isExpanded ? 'd-flex flex-column' : 'd-none', styles.row)}>
          <div>Internal Notes</div>
          <div className={styles.notes} dangerouslySetInnerHTML={{ __html: healthPlan.internal_notes ?? '' }} />
        </div>
      )}
    </div>
  );
};
