import * as React from 'react';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CCol, CFormInput, CRow } from '@coreui/react-pro';

import { fetchDiagnostics } from 'api/Diagnostics';
import { fetchDiagnosticProducts } from 'api/Products';

import { Diagnostic } from 'types/Diagnostic';
import { DiagnosticRequestItem } from 'types/DiagnosticRequestItem';
import { Option } from 'types/Option';

import { reactSelectStyles } from 'utils/reactSelect';
import { productCodeToOption, toOption } from 'utils/selectOptions';

import SvgCart from 'assets/images/SvgCart';
import SvgPawCross from 'assets/images/SvgPawCross';

import { IconLabel } from 'components/IconLabel';

import styles from './DiagnosticRequestItemForm.module.scss';

type Props = {
  index: number;
  requestItem?: DiagnosticRequestItem;
};

export const DiagnosticRequestItemForm = ({ index, requestItem }: Props) => {
  const [selectedDiagnostic, setSelectedDiagnostic] = useState<Option | null>(
    requestItem?.diagnostic ? toOption(requestItem?.diagnostic) : null
  );
  const [selectedProduct, setSelectedProduct] = useState<Option | null>(
    requestItem?.diagnostic.product ? productCodeToOption(requestItem?.diagnostic.product) : null
  );
  const [diagnosticsList, setDiagnosticsList] = useState<Diagnostic[]>([]);

  const handleDiagnosticSelectChange = (selectedOption: Option | null) => {
    setSelectedDiagnostic(selectedOption);
  };

  useEffect(() => {
    const fullDiagnostic = diagnosticsList.find((diagnostic) => diagnostic.id === Number(selectedDiagnostic?.value));
    if (fullDiagnostic?.product) {
      setSelectedProduct(productCodeToOption(fullDiagnostic.product));
    }
  }, [diagnosticsList, selectedDiagnostic]);

  const handleProductSelectChange = (selectedOption: Option | null) => {
    setSelectedProduct(selectedOption);
  };

  const nestedName = (name: string) => `diagnostic_request_items[${index}][${name}]`;

  return (
    <CRow className="mb-4">
      <CFormInput hidden id="id" name={nestedName('id')} value={requestItem?.id} />
      <CCol>
        <CFormInput hidden id="diagnostic" name={nestedName('diagnostic')} value={selectedDiagnostic?.value ?? ''} />
        <div className="d-flex align-items-center justify-content-between form-label">
          <label>
            <IconLabel icon={SvgPawCross} label="Diagnostic" />
          </label>
          <div className={styles.required}>Required</div>
        </div>

        <AsyncSelect<Option>
          aria-label="Diagnostic"
          placeholder="Type to search..."
          value={selectedDiagnostic}
          onChange={handleDiagnosticSelectChange}
          loadingMessage={(input: { inputValue: string }) => 'Loading...'}
          defaultOptions
          loadOptions={(inputValue, callback) => {
            fetchDiagnostics(inputValue, false, (options) => {
              setDiagnosticsList(options);
              callback(options.map(toOption));
            });
          }}
          styles={reactSelectStyles}
          isClearable
          isSearchable
          required
        />
      </CCol>

      <CCol>
        <CFormInput hidden id="product" name={nestedName('product')} value={selectedProduct?.value ?? ''} />
        <div className="d-flex align-items-center justify-content-between form-label">
          <label>
            <IconLabel icon={SvgCart} label="Product" />
          </label>
          <div className={styles.required}>Required</div>
        </div>

        <AsyncSelect<Option>
          aria-label="Product"
          placeholder="Type to search..."
          value={selectedProduct}
          onChange={handleProductSelectChange}
          loadingMessage={(input: { inputValue: string }) => 'Loading...'}
          defaultOptions
          loadOptions={(inputValue, callback) => {
            fetchDiagnosticProducts(inputValue).then((options) => {
              callback(options.map(productCodeToOption));
            });
          }}
          styles={reactSelectStyles}
          isClearable
          isSearchable
          required
        />
      </CCol>
    </CRow>
  );
};
