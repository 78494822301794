import axios from 'axios';

import { DiagnosticResult } from 'types/DiagnosticResult';
import { Pagination } from 'types/Pagination';

const fetchDiagnosticResults = (
  params: object,
  {
    onSuccess,
    page,
    setPagination
  }: {
    onSuccess: (diagnosticResults: DiagnosticResult[]) => void;
    page?: number;
    setPagination: (pagination: Pagination) => void;
  }
) => {
  const url = '/pim/diagnostic_results';
  axios
    .get<DiagnosticResult[]>(url, {
      params: {
        ...params,
        page: page || 1
      }
    })
    .then((data) => {
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
      onSuccess(data.data.record);
    });
};

const fetchUnassignedDiagnosticResults = (
  params: object,
  {
    onSuccess,
    page,
    setPagination
  }: {
    onSuccess: (diagnosticResults: DiagnosticResult[]) => void;
    page?: number;
    setPagination: (pagination: Pagination) => void;
  }
) => {
  const url = '/pim/diagnostic_results/unassigned';
  axios
    .get<DiagnosticResult[]>(url, {
      params: {
        ...params,
        page: page || 1
      }
    })
    .then((data) => {
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
      onSuccess(data.data.record);
    });
};

const fetchDiagnosticResultsByDiagnosticRequest = (
  diagnosticRequestId: string,
  onSuccess: (diagnosticResult: DiagnosticResult[]) => void
) => {
  const url = `/pim/diagnostic_results?diagnostic_request_id=${diagnosticRequestId}`;
  axios.get<DiagnosticResult[]>(url).then((data) => onSuccess(data.data.record));
};

const createDiagnosticResult = (
  formData: FormData,
  { onSuccess, onError }: { onSuccess: (diagnosticResult: DiagnosticResult) => void; onError: (error: string) => void }
) => {
  const url = '/pim/diagnostic_results/';

  axios
    .post<DiagnosticResult>(url, formData)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError(error.response.data.error));
};

const updateDiagnosticResult = (
  diagnosticResultId: number,
  formData: FormData,
  { onSuccess, onError }: { onSuccess: (diagnosticResult: DiagnosticResult) => void; onError: (error: string) => void }
) => {
  const url = `/pim/diagnostic_results/${diagnosticResultId}`;

  axios
    .put<DiagnosticResult>(url, formData)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError(error.response.data.error));
};

export {
  fetchDiagnosticResults,
  fetchUnassignedDiagnosticResults,
  fetchDiagnosticResultsByDiagnosticRequest,
  createDiagnosticResult,
  updateDiagnosticResult
};
