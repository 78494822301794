import * as React from 'react';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { CNav, CNavItem, CNavLink } from '@coreui/react-pro';

import { createAnimalHealthPlan, fetchAnimalHealthPlans, updateAnimalHealthPlan } from 'api/AnimalHealthPlans';

import { AnimalHealthPlan, HealthPlanStatus } from 'types/AnimalHealthPlan';
import { Consult } from 'types/Consult';

import { usePoll } from 'hooks/usePoll';

import SvgPlus from 'assets/images/SvgPlus';

import { IconButton } from 'components/IconButton';

import { DisableAnimalHealthPlanModal } from './DisableAnimalHealthPlanModal';
import { EditAnimalHealthPlanModal } from './EditAnimalHealthPlanModal';
import { HealthPlanItem } from './HealthPlanItem';
import { NewAnimalHealthPlanModal } from './NewAnimalHealthPlanModal';

const importances = ['core', 'all'] as const;
type Importance = (typeof importances)[number];

type Modals = 'new-animal-health-plan' | 'edit-animal-health-plan' | 'disable-animal-health-plan';

type Props = {
  consult: Consult;
};

export const HealthPlansList = ({ consult }: Props) => {
  const [animalHealthPlans, setAnimalHealthPlans] = useState<AnimalHealthPlan[]>([]);

  const [importanceFilter, setImportanceFilter] = useState<Importance>(importances[0]);
  const [editingItem, setEditingItem] = useState<AnimalHealthPlan>();
  const [visibleModal, setVisibleModal] = useState<Modals>();
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    () => fetchAnimalHealthPlans({ animal_id: consult.animal_id, status: 'active' }, setAnimalHealthPlans),
    [consult]
  );
  usePoll(fetch);

  const hideModal = () => {
    setVisibleModal(undefined);
    setEditingItem(undefined);
  };

  const handleSuccess = (message: string) => {
    fetch();

    toast.success(message);
    hideModal();
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
  };

  const handleCreateAnimalHealthPlan = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = { animal_id: consult.animal_id, ...Object.fromEntries(formData.entries()) };

    createAnimalHealthPlan(formJson, {
      onSuccess: () => handleSuccess('Health plan created!'),
      onError: handleError
    });
  };

  const handleUpdateAnimalHealthPlan = (event: React.FormEvent<HTMLFormElement>) => {
    if (!editingItem) return;
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    updateAnimalHealthPlan(editingItem.id, formJson, {
      onSuccess: () => handleSuccess('Health plan updated!'),
      onError: handleError
    });
  };

  const handleDisableAnimalHealthPlan = (event: React.FormEvent<HTMLFormElement>) => {
    if (!editingItem) return;
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const formJson = { status: 'disabled' as HealthPlanStatus, ...Object.fromEntries(formData.entries()) };

    updateAnimalHealthPlan(editingItem.id, formJson, {
      onSuccess: () => handleSuccess('Health plan disabled!'),
      onError: handleError
    });
  };

  const handleActivateAnimalHealthPlan = (item: AnimalHealthPlan) => {
    updateAnimalHealthPlan(
      item.id,
      { status: 'active' },
      {
        onSuccess: () => handleSuccess('Health plan active!'),
        onError: () => handleError()
      }
    );
  };

  const statusAction = (item: AnimalHealthPlan) => {
    const handleToggleStatus = (item: AnimalHealthPlan) => {
      const isDisabling = item.status === 'active';

      if (isDisabling) {
        setEditingItem(item);
        setVisibleModal('disable-animal-health-plan');
      } else {
        handleActivateAnimalHealthPlan(item);
      }
    };

    return { label: item.status === 'disabled' ? 'Activate' : 'Disable', onClick: () => handleToggleStatus(item) };
  };

  const filterByImportance = (animalHealthPlans: AnimalHealthPlan[]) => {
    return animalHealthPlans.filter((plan) => {
      return importanceFilter === 'all' || ['core', 'required'].includes(plan.health_plan.importance);
    });
  };

  const renderModal = (visibleModal?: Modals) => {
    switch (visibleModal) {
      case 'new-animal-health-plan':
        return (
          <NewAnimalHealthPlanModal hideModal={hideModal} loading={loading} onSubmit={handleCreateAnimalHealthPlan} />
        );
      case 'edit-animal-health-plan':
        if (editingItem) {
          return (
            <EditAnimalHealthPlanModal
              animalHealthPlan={editingItem}
              hideModal={hideModal}
              loading={loading}
              onSubmit={handleUpdateAnimalHealthPlan}
            />
          );
        }
        break;
      case 'disable-animal-health-plan':
        if (editingItem) {
          return (
            <DisableAnimalHealthPlanModal
              animalHealthPlan={editingItem}
              hideModal={hideModal}
              loading={loading}
              onSubmit={handleDisableAnimalHealthPlan}
            />
          );
        }
        break;
      default:
        return null;
    }
  };

  return (
    <>
      {renderModal(visibleModal)}

      <div className="d-flex align-items-center justify-content-between mb-3">
        <CNav role="list" variant="underline">
          {importances.map((importance) => (
            <CNavItem key={importance}>
              <CNavLink
                className="text-capitalize"
                active={importance === importanceFilter}
                role="button"
                onClick={() => setImportanceFilter(importance)}
              >
                {importance}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>

        <IconButton icon={SvgPlus} onClick={() => setVisibleModal('new-animal-health-plan')} label="New" />
      </div>

      {filterByImportance(animalHealthPlans).map((item) => (
        <HealthPlanItem
          key={item.id}
          healthPlan={item}
          statusAction={statusAction}
          handleEditClick={() => {
            setEditingItem(item);
            setVisibleModal('edit-animal-health-plan');
          }}
        />
      ))}
    </>
  );
};
