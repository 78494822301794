import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CBadge,
  CButton,
  CModal,
  CModalBody,
  CModalHeader
} from '@coreui/react-pro';

import { updateEmployeeTask } from 'api/EmployeeTasks';

import { CommunicationLog } from 'types/CommunicationLog';
import { EmployeeTask } from 'types/EmployeeTask';

import { CommunicationLogItem } from 'components/CommunicationLogItem';

import { TaskCommunicationForm } from './TaskCommunicationForm';
import { TaskForm } from './TaskForm';

type Props = {
  task: EmployeeTask;
  setTask: (task: EmployeeTask) => void;
  hideModal: () => void;
};

export const EditTaskModal = ({ task, setTask, hideModal }: Props) => {
  const [loading, setLoading] = useState(false);
  const [communicationForm, setCommunicationForm] = useState<'log' | 'message'>();

  const onSubmit = (taskToUpdate: EmployeeTask) => {
    setLoading(true);
    if (taskToUpdate.id)
      updateEmployeeTask(
        taskToUpdate.id,
        { employee_task: taskToUpdate },
        { onSuccess: handleUpdateSuccess, onError: handleError }
      );
  };

  const handleUpdateSuccess = (task: EmployeeTask) => {
    const action = task.status === 'pending' ? 'updated' : task.status === 'completed' ? 'completed' : 'disabled';
    toast.success(`Task ${action} successfully`);
    setLoading(false);
    hideModal();
  };

  const handleError = (error: string) => {
    setLoading(false);
  };

  const handleCommunicationSuccess = (communicationLog: CommunicationLog) => {
    toast.success(`Message successfully ${communicationLog.communication_type === 'message' ? 'sent' : 'logged'}`);
    const newLogs = task.communication_logs ? [communicationLog, ...task.communication_logs] : [communicationLog];
    setTask({ ...task, communication_logs: newLogs });
    setCommunicationForm(undefined);
  };

  return (
    <CModal className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
      <CModalHeader>
        <h4 className="text-capitalize mb-0">{task.status === 'pending' ? 'Edit Task' : `View ${task.status} Task`}</h4>
      </CModalHeader>
      <CModalBody>
        <TaskForm
          task={task}
          setTask={setTask}
          hideModal={hideModal}
          isLoading={loading}
          onSubmit={onSubmit}
          disablePresets
        />
        <CAccordion className="mb-3 mt-2">
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              Communications
              {task.communication_logs && (
                <CBadge color="secondary" className="ms-2">
                  {task.communication_logs.length}
                </CBadge>
              )}
            </CAccordionHeader>
            <CAccordionBody>
              {communicationForm === undefined ? (
                <div className="d-flex justify-content-end mb-3">
                  <CButton
                    size="sm"
                    className="me-2"
                    onClick={() => {
                      setCommunicationForm('message');
                    }}
                  >
                    New Message
                  </CButton>
                  <CButton
                    size="sm"
                    onClick={() => {
                      setCommunicationForm('log');
                    }}
                  >
                    New Log
                  </CButton>
                </div>
              ) : (
                <div>
                  <TaskCommunicationForm
                    task={task}
                    method={communicationForm}
                    onCancel={() => setCommunicationForm(undefined)}
                    onSuccess={handleCommunicationSuccess}
                  />
                </div>
              )}
              {task.communication_logs &&
                task.communication_logs.map((communication, index) => (
                  <CommunicationLogItem communicationLog={communication} key={communication.id} />
                ))}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CModalBody>
    </CModal>
  );
};
