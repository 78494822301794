import * as React from 'react';

import { CModal, CModalBody } from '@coreui/react-pro';

import { AnimalHealthPlan } from 'types/AnimalHealthPlan';

import { AnimalHealthPlanForm } from './AnimalHealthPlanForm';

type Props = {
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  animalHealthPlan: AnimalHealthPlan;
};

export const EditAnimalHealthPlanModal = ({ hideModal, onSubmit, loading, animalHealthPlan }: Props) => {
  return (
    <CModal backdrop="static" className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
      <CModalBody>
        <AnimalHealthPlanForm
          onSubmit={onSubmit}
          animalHealthPlan={animalHealthPlan}
          hideModal={hideModal}
          loading={loading}
        />
      </CModalBody>
    </CModal>
  );
};
