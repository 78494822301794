import axios from 'axios';

import { Membership } from 'types/Membership';

export interface CancelMembershipParams {
  cancelReasons: number[];
  cancelReasonText?: string;
}

const upgradePlan = (membershipId: number, planSlug: string, onSuccess: () => void) => {
  const url = `/pim/memberships/${membershipId}/upgrade_plan?plan=${planSlug}`;
  axios.put<Membership>(url, {}).then((data) => onSuccess());
};

const reactivatePlan = (membershipId: number, onSuccess: () => void) => {
  const url = `/pim/memberships/${membershipId}`;
  axios.put<Membership>(url, {}).then((data) => onSuccess());
};

const cancelPlan = (membershipId: number, params: CancelMembershipParams, onSuccess: () => void) => {
  const url = `/pim/memberships/${membershipId}`;
  axios
    .delete<Membership>(url, {
      params: {
        cancel_reason_ids: params.cancelReasons,
        cancel_reason_text: params.cancelReasonText ?? ''
      }
    })
    .then((data) => onSuccess());
};

export { upgradePlan, cancelPlan, reactivatePlan };
