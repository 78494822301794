import { DraggableProvided } from '@hello-pangea/dnd';
import * as React from 'react';
import { forwardRef, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CButton, CFormInput, CFormSelect, CInputGroup, CTableDataCell, CTableRow } from '@coreui/react-pro';

import { fetchProducts } from 'api/Products';

import { InvoiceItem } from 'types/InvoiceItem';
import { Option } from 'types/Option';
import { Product } from 'types/Product';

import { toCurrency } from 'utils/price';
import { reactSelectStyles } from 'utils/reactSelect';
import { toOption } from 'utils/selectOptions';

import SvgDrag from 'assets/images/SvgDrag';

import styles from './InvoiceItemForm.module.scss';

import { ActionsMenu } from './ActionsMenu';

type Props = {
  index: number;
  invoiceItem?: InvoiceItem;
  handleChange: (invoiceItem: InvoiceItem, key: number | undefined) => void;
  removeItem: (key: number | undefined, id: number | undefined) => void;
  isOrgAdmin?: boolean;
  isDragging: boolean;
  draggableProvided: DraggableProvided;
  isDisabled: boolean;
};

export const InvoiceItemForm = forwardRef(function InvoiceItemForm(
  { index, invoiceItem, handleChange, removeItem, isOrgAdmin, isDragging, draggableProvided, isDisabled }: Props,
  ref: React.Ref<HTMLTableRowElement>
) {
  const [productList, setProductsList] = useState<Product[]>([]);
  const [productPresentation, setProductPresentation] = useState<'button' | 'select'>(
    invoiceItem?.product ? 'button' : 'select'
  );

  const [selectedProductOption, setSelectedProductOption] = useState<Option | null>();
  const [discount, setDiscount] = useState<number | undefined>(
    invoiceItem?.discount_fixed ? invoiceItem?.discount_fixed / 100 : invoiceItem?.discount_percentage || undefined
  );
  const [discountType, setDiscountType] = useState<string>(invoiceItem?.discount_fixed ? 'fixed' : 'percentage');

  useEffect(() => {
    if (invoiceItem?.product && invoiceItem.product.id) {
      setSelectedProductOption(toOption(invoiceItem.product));
    }
  }, [invoiceItem?.product]);

  const handleProductSelectChange = async (selectedOption: Option | null) => {
    if (selectedOption?.value) {
      const selectedProduct = productList.find((product) => product.id === Number(selectedOption.value));
      setSelectedProductOption(selectedProductOption);

      handleChange(
        {
          ...invoiceItem,
          product: selectedProduct,
          product_id: Number(selectedProduct?.id),
          quantity: invoiceItem?.quantity || 1
        },
        invoiceItem?.key
      );

      setProductPresentation('button');
    }
  };

  const updateQuantity = (newQuantity: number) => {
    handleChange({ ...invoiceItem, quantity: newQuantity }, invoiceItem?.key);
  };

  const updateDiscount = async (newDiscount: number) => {
    setDiscount(newDiscount);
    handleChange(
      {
        ...invoiceItem,
        discount_percentage: discountType === 'percentage' ? newDiscount : undefined,
        discount_fixed: discountType === 'fixed' && newDiscount ? newDiscount * 100 : undefined
      },
      invoiceItem?.key
    );
  };

  const handleProductClick = () => setProductPresentation('select');

  return (
    <CTableRow
      color={isDragging ? 'secondary' : 'default'}
      ref={ref}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
    >
      <CTableDataCell style={{ width: '3%' }}>
        {!isDisabled && <SvgDrag height={20} className={styles.dragSvg} />}
      </CTableDataCell>
      <CTableDataCell style={{ width: '40%' }}>
        {productPresentation === 'button' && invoiceItem?.product && (
          <CButton
            color="light"
            shape="rounded-pill"
            className="w-100 fw-normal text-start"
            onClick={handleProductClick}
            disabled={isDisabled}
          >
            {invoiceItem.product.name}
          </CButton>
        )}

        {productPresentation === 'select' && (
          <div contentEditable>
            <AsyncSelect<Option>
              aria-label={`Product ${index + 1}`}
              placeholder="Type to search..."
              value={selectedProductOption}
              onChange={handleProductSelectChange}
              loadingMessage={(input: { inputValue: string }) => 'Loading...'}
              styles={reactSelectStyles}
              defaultOptions
              autoFocus
              loadOptions={(inputValue, callback) => {
                fetchProducts(inputValue, (products: Product[]) => {
                  setProductsList(products);
                  callback(products.map(toOption));
                });
              }}
              isSearchable
              required
              isDisabled={isDisabled}
            />
          </div>
        )}
      </CTableDataCell>

      <CTableDataCell style={{ width: '10%' }}>
        <CFormInput
          type="number"
          id="quantity"
          aria-label="Quantity"
          value={invoiceItem?.quantity}
          step={0.01}
          min={0}
          required
          onChange={(event) => updateQuantity(parseFloat(event.target.value))}
          disabled={isDisabled}
        />
      </CTableDataCell>

      <CTableDataCell style={{ width: '10%' }}>
        {invoiceItem?.price ? toCurrency(invoiceItem?.price) : null}
      </CTableDataCell>

      <CTableDataCell style={{ width: '20%' }}>
        {isOrgAdmin ? (
          <CInputGroup className="flex-nowrap">
            <CFormInput
              type="number"
              id={`discount-${index}`}
              aria-label="Discount"
              max={discountType === 'percentage' ? 100 : undefined}
              value={discount}
              step={0.01}
              min={0}
              onChange={(event) => updateDiscount(parseFloat(event.target.value))}
              className="w-50"
              disabled={isDisabled}
            />
            <CFormSelect
              id={`discount_type-${index}`}
              name="discount_type"
              aria-label="Discount type"
              options={[
                { value: 'percentage', label: '%' },
                { value: 'fixed', label: '$' }
              ]}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                setDiscountType(event.target.value as 'fixed' | 'percentage')
              }
              value={discountType}
              disabled={isDisabled}
            />
          </CInputGroup>
        ) : (
          <div>{discountType === 'percentage' ? `${discount}%` : `$${discount}`}</div>
        )}
      </CTableDataCell>

      <CTableDataCell style={{ width: '10%' }}>
        {invoiceItem?.subtotal ? toCurrency(invoiceItem.subtotal) : null}
      </CTableDataCell>

      {!isDisabled && (
        <CTableDataCell style={{ width: '70%' }}>
          <ActionsMenu items={[{ label: 'Delete', onClick: () => removeItem(invoiceItem?.key, invoiceItem?.id) }]} />
        </CTableDataCell>
      )}
    </CTableRow>
  );
});
