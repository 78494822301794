import React, { useEffect, useMemo, useState } from 'react';
import SignaturePad from 'signature_pad';

import { cilWarning } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CFormTextarea,
  CImage,
  CLoadingButton,
  CRow,
  CSpinner
} from '@coreui/react-pro';

import { fetchClinics } from 'api/Clinics';
import { fetchEmployee } from 'api/Employees';
import { fetchRoles } from 'api/Roles';

import { Clinic } from 'types/Clinic';
import { Employee, isOrganizationAdmin } from 'types/Employee';
import { PimResource, PimResourceStatus } from 'types/PimResource';
import { Role } from 'types/Role';

import { useAuth } from 'hooks/useAuth';

import { generateCloudinaryPath, uploadFileToCloudinary } from 'utils/cloudinary';
import { getPasswordStrength } from 'utils/passwordStrength';

type EmployeeFormProps = {
  handleSubmit: (employee: Employee) => Promise<boolean>;
  handleCancel: () => void;
  employeeId?: string;
  updateDeletedPimResources: (pimResource: Partial<PimResource>) => void;
  updateCreatedPimResources: (pimResource: Partial<PimResource>) => void;
};

const Form = ({
  handleSubmit,
  handleCancel,
  employeeId,
  updateDeletedPimResources,
  updateCreatedPimResources
}: EmployeeFormProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordScore, setPasswordScore] = useState<number>(0);
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string | undefined>();
  const [emailError, setEmailError] = useState<string | undefined>();
  const [photoError, setPhotoError] = useState<string | undefined>();
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();
  const [signaturePad, setSignaturePad] = useState<SignaturePad>();
  const [signatureWarning, setSignatureWarning] = useState<string>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [employee, setEmployee] = useState<Employee>({ idle_timeout_in_minutes: 120 } as Employee);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clinics, setClinics] = useState<Clinic[]>([]);

  const auth = useAuth();

  useEffect(() => {
    fetchRoles(setRoles);
    fetchClinics(setClinics);
  }, []);

  useEffect(() => {
    if (employeeId) {
      fetchEmployee(employeeId, (employee: Employee) => {
        setEmployee(employee);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [employeeId]);

  useEffect(() => {
    if (!isLoading) {
      const canvasElement = document.querySelector('canvas');
      if (canvasElement) {
        // height and width need to be manually set for high dpi screens,
        // otherwise the resulting svg will be cut off
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        const width = 400;
        const height = 100;
        canvasElement.style.width = `${width}px`;
        canvasElement.style.height = `${height}px`;
        canvasElement.width = width * ratio;
        canvasElement.height = height * ratio;
        canvasElement.getContext('2d')?.scale(ratio, ratio);

        const sigPad = new SignaturePad(canvasElement);
        sigPad.addEventListener(
          'endStroke',
          () => {
            if (employee.signature_photo_provider_id) {
              setSignatureWarning('This signature will replace the current one on file.');
            }
          },
          { once: true }
        );
        setSignaturePad(sigPad);
      }
    }
  }, [isLoading, employee.signature_photo_provider_id]);

  const avatarSource = useMemo(() => {
    return {
      uri:
        employee?.photo_provider_id &&
        generateCloudinaryPath({
          imagePublicId: employee.photo_provider_id
        })
    };
  }, [employee?.photo_provider_id]);

  useEffect(() => {
    if (employee.password !== employee.password_confirmation) {
      setPasswordConfirmationError('Passwords do not match');
    } else {
      setPasswordConfirmationError(undefined);
    }
  }, [employee.password, employee.password_confirmation]);

  useEffect(() => {
    if (!employee.password) {
      return;
    }
    const result = getPasswordStrength(employee.password);
    setPasswordScore(result.score);

    const resultFeedback = result.feedback.warning || result.feedback.suggestions.join(' ');
    setPasswordStrength(`Password too weak. ${resultFeedback}`);
  }, [employee.password]);

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!employee.email) {
      setEmailError('Email is required');
      setIsSubmitting(false);
      return;
    } else {
      setEmailError(undefined);
    }

    try {
      setPhotoError(undefined);
      const photoId = await saveImage();
      if (photoId) {
        employee.photo_provider_id = photoId;
      }
    } catch (error) {
      setPhotoError('Error uploading photo, please try again.');
      setIsSubmitting(false);
      return;
    }

    // if the signature field is not empty, upload the image.
    if (signaturePad && !signaturePad.isEmpty()) {
      const pngSignature = signaturePad?.toDataURL('image/png', 0.5);
      if (pngSignature) {
        const signaturePhotoId = await uploadFileToCloudinary({
          file: pngSignature,
          uploadPreset: 'human_photo',
          cloudName: 'drtreat-com'
        });
        employee.signature_photo_provider_id = signaturePhotoId.public_id;
      }
    }

    const submitStatus = await handleSubmit(employee);
    setIsSubmitting(!submitStatus);
  };

  function imageChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const filelist = event.target.files;
    if (filelist === null || filelist.length < 1) {
      return;
    }
    const file = filelist.item(0);
    if (file === null) {
      return;
    }
    setFileToUpload(file);
  }

  async function saveImage() {
    if (fileToUpload) {
      const res = await uploadFileToCloudinary({
        file: fileToUpload,
        uploadPreset: 'human_photo',
        cloudName: 'drtreat-com'
      });
      return res.public_id;
    } else return undefined;
  }

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const roleId = Number(event.target.value);
    const checked = event.target.checked;

    if (checked) {
      const roleToSelect = roles.find((role) => role.id === roleId);
      if (roleToSelect) {
        if (employee.roles) {
          setEmployee({ ...employee, roles: [...employee.roles, roleToSelect] });
        } else {
          setEmployee({ ...employee, roles: [roleToSelect] });
        }
      }
    } else {
      setEmployee({ ...employee, roles: employee.roles.filter((role) => role.id !== roleId) });
    }
  };

  const handleClinicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const clinicId = Number(event.target.value);
    const checked = event.target.checked;

    if (checked) {
      setEmployee((prevEmployee) => {
        if (!prevEmployee.pim_resources) {
          return prevEmployee;
        } else {
          const existingPimResource = prevEmployee.pim_resources.find(
            (pimResource) => pimResource.clinic_id === clinicId
          );
          if (existingPimResource) {
            // If the pim resource is already in the list, just update the status
            return {
              ...prevEmployee,
              pim_resources: prevEmployee.pim_resources.map((pimResource) => {
                if (pimResource.clinic_id === clinicId) {
                  return { ...pimResource, status: PimResourceStatus.Active };
                } else {
                  return pimResource;
                }
              })
            };
          } else {
            return {
              ...prevEmployee,
              pim_resources: [
                ...prevEmployee.pim_resources,
                {
                  clinic_id: clinicId,
                  status: PimResourceStatus.Active
                }
              ]
            };
          }
        }
      });

      updateCreatedPimResources({
        clinic_id: clinicId,
        status: PimResourceStatus.Active
      });
    } else {
      const pimResourceToDelete = employee.pim_resources.find((pimResource) => pimResource.clinic_id === clinicId);
      if (pimResourceToDelete) {
        const updatedPimResources = employee.pim_resources.map((pimResource) => {
          if (pimResource.id === pimResourceToDelete.id) {
            return { ...pimResource, status: PimResourceStatus.Disabled };
          } else {
            return pimResource;
          }
        });
        setEmployee((prevEmployee) => {
          if (prevEmployee.pim_resources) {
            return {
              ...prevEmployee,
              pim_resources: updatedPimResources
            };
          } else {
            return prevEmployee;
          }
        });
        updateDeletedPimResources(pimResourceToDelete);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.type === 'checkbox') {
      setEmployee({ ...employee, [event.target.id]: 'checked' in event.target ? event.target.checked : false });
    } else {
      setEmployee({ ...employee, [event.target.id]: event.target.value });
    }
  };

  if (isLoading) {
    return <CSpinner color="primary" />;
  }

  return (
    <CForm onSubmit={submit}>
      <CRow className="mb-3">
        {avatarSource.uri && (
          <CCol>
            <CFormLabel className="col-form-label w-100">Current Photo</CFormLabel>
            <CImage alt={employee.full_name_with_title} fluid src={avatarSource.uri} style={{ height: '200px' }} />
          </CCol>
        )}
        {fileToUpload && (
          <CCol>
            <CFormLabel className="col-form-label w-100">Photo to Upload</CFormLabel>
            <CImage
              alt="File to upload"
              fluid
              src={window.URL.createObjectURL(fileToUpload)}
              style={{ height: '200px', objectFit: 'cover' }}
            />
          </CCol>
        )}
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="formFile" className="col-sm-3 col-form-label">
          Photo
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput
            type="file"
            id="formFile"
            accept="image/*"
            aria-label="Upload a picture"
            feedbackInvalid={photoError}
            valid={!!photoError}
            invalid={photoError !== undefined}
            onChange={imageChanged}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="title" className="col-sm-3 col-form-label">
          Title
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput type="text" id="title" value={employee.title || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="first_name" className="col-sm-3 col-form-label">
          First Name
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput type="text" id="first_name" value={employee.first_name || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="last_name" className="col-sm-3 col-form-label">
          Last Name
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput type="text" id="last_name" value={employee.last_name || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="email" className="col-sm-3 col-form-label">
          Email
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput
            type="email"
            id="email"
            value={employee.email || ''}
            onChange={handleChange}
            feedbackInvalid={emailError}
            valid={!!emailError}
            invalid={emailError !== undefined}
            required
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="password" className="col-sm-3 col-form-label">
          Password
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput
            autoComplete="new-password"
            type="password"
            id="password"
            onChange={handleChange}
            feedbackInvalid={passwordStrength}
            valid={!!employee.password && passwordScore >= 2}
            invalid={!!employee.password && passwordScore < 2}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="password_confirmation" className="col-sm-3 col-form-label">
          Confirm Password
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput
            autoComplete="new-password"
            type="password"
            id="password_confirmation"
            onChange={handleChange}
            invalid={passwordConfirmationError !== undefined}
            valid={!!employee.password_confirmation && passwordConfirmationError === undefined}
            feedbackInvalid={passwordConfirmationError}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="bio" className="col-sm-3 col-form-label">
          Bio
        </CFormLabel>
        <CCol sm={9}>
          <CFormTextarea id="bio" value={employee.bio || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="qualifications" className="col-sm-3 col-form-label">
          Qualifications
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput type="text" id="qualifications" value={employee.qualifications || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="registration_number" className="col-sm-3 col-form-label">
          Registration Number
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput
            type="text"
            id="registration_number"
            value={employee.registration_number || ''}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="active" className="col-sm-3 col-form-label">
          Is Active
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="active" aria-label="Active" checked={employee.active ?? false} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="schedulable" className="col-sm-3 col-form-label">
          Is Schedulable
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch
            id="schedulable"
            aria-label="Is Schedulable"
            checked={employee.schedulable ?? false}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="vet" className="col-sm-3 col-form-label">
          Is Vet
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="vet" checked={employee.vet ?? false} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="vet_tech" className="col-sm-3 col-form-label">
          Is Vet Tech
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="vet_tech" checked={employee.vet_tech ?? false} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="csr" className="col-sm-3 col-form-label">
          Is CSR
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="csr" checked={employee.csr ?? false} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="idle_timeout_in_minutes" className="col-sm-3 col-form-label">
          Idle Timeout in Minutes
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput
            type="number"
            id="idle_timeout_in_minutes"
            max={4320}
            min={1}
            value={employee.idle_timeout_in_minutes}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="signature" className="col-sm-3 col-form-label">
          Signature
        </CFormLabel>
        <CCol sm={9}>
          <canvas
            style={{ touchAction: 'none', userSelect: 'none', border: '1px solid lightgray', borderRadius: '6px' }}
            width="400"
            height="100"
            id="employee-signature"
          />
          {signatureWarning && (
            <CFormLabel>
              <CIcon icon={cilWarning} className="text-danger me-2" />
              {signatureWarning}
            </CFormLabel>
          )}
        </CCol>
      </CRow>

      {auth.employee && isOrganizationAdmin(auth.employee) && (
        <>
          <CRow className="mb-3 align-items-center">
            <CCol sm={6}>
              <h2 className="mb-3">Roles</h2>
              {roles?.map((role) => (
                <CFormCheck
                  className="text-capitalize"
                  key={role.id}
                  id={`role-${role.id}`}
                  value={role.id}
                  label={role.name}
                  onChange={handleRoleChange}
                  checked={employee.roles?.map((role) => role.name).includes(role.name)}
                />
              ))}
            </CCol>
            {employeeId && (
              <CCol sm={6}>
                <h2 className="mb-3">Schedulable Clinics</h2>
                {clinics?.map((clinic) => (
                  <CFormCheck
                    className="text-capitalize"
                    key={clinic.id}
                    id={`clinic-${clinic.id}`}
                    value={clinic.id}
                    label={clinic.name}
                    onChange={handleClinicChange}
                    checked={
                      employee.pim_resources?.some(
                        (pimResource) => pimResource.clinic_id === clinic.id && pimResource.status === 'active'
                      ) || false
                    }
                  />
                ))}
              </CCol>
            )}
          </CRow>
        </>
      )}

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end mb-3">
        <CButton color="secondary" disabled={isSubmitting} onClick={handleCancel}>
          Cancel
        </CButton>
        <CLoadingButton color="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          {employeeId ? 'Update' : 'Create'}
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default Form;
