import axios from 'axios';

import { HospitalEvent } from 'types/HospitalEvent';

const fetchHospitalEvent = (hospitalEventId: string | number, onSuccess: (HospitalEvent: HospitalEvent) => void) => {
  const url = `/pim/hospital_events/${hospitalEventId}`;
  axios.get<HospitalEvent>(url).then((data) => onSuccess(data.data.record));
};

const createHospitalEvent = (params: Partial<HospitalEvent>, onSuccess: (HospitalEvent: HospitalEvent) => void) => {
  const url = `/pim/hospital_events`;
  axios.post<HospitalEvent>(url, { hospital_event: params }).then((data) => onSuccess(data.data.record));
};

const updateHospitalEvent = (
  hospitalEventId: string | number,
  params: Partial<HospitalEvent>,
  onSuccess: (HospitalEvent: HospitalEvent) => void
) => {
  const url = `/pim/hospital_events/${hospitalEventId}`;
  axios.put<HospitalEvent>(url, { hospital_event: params }).then((data) => onSuccess(data.data.record));
};

const createBatchHospitalEvents = (
  params: Partial<HospitalEvent>[],
  onSuccess: (HospitalEvent: HospitalEvent[]) => void
) => {
  const url = `/pim/hospital_events`;
  axios.post<HospitalEvent[]>(url, { hospital_events: params }).then((data) => onSuccess(data.data.record));
};

const updateBatchHospitalEvents = (
  firstHospitalEventId: string | number,
  params: Partial<HospitalEvent>[],
  onSuccess: (HospitalEvent: HospitalEvent[]) => void
) => {
  const url = `/pim/hospital_events/${firstHospitalEventId}`;
  axios.put<HospitalEvent[]>(url, { hospital_events: params }).then((data) => onSuccess(data.data.record));
};

export {
  fetchHospitalEvent,
  createHospitalEvent,
  updateHospitalEvent,
  createBatchHospitalEvents,
  updateBatchHospitalEvents
};
