import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react-pro';

import { Animal } from 'types/Animal';
import { Appointment } from 'types/Appointment';

import { dateTimeDisplay, timeDisplay } from 'utils/dates';

import styles from './AppointmentSummary.module.scss';

import { AppointmentStatusSelect } from './AppointmentStatusSelect';
import { PossibleParvoBadge } from './PossibleParvoBadge';

type Props = {
  appointment: Appointment;
  animal: Animal;
};

export const AppointmentSummary = ({ animal, appointment }: Props): JSX.Element => {
  return (
    <div>
      <h2 className={styles.header}>
        Appointment
        <PossibleParvoBadge animal={animal} appointment={appointment} />
      </h2>
      <CCard>
        <CCardHeader className="d-flex align-items-center justify-content-between">
          <div>
            <Link className={styles.appointmentType} to={`/appointments/${appointment.id}`}>
              {appointment.appointment_type.name_in_pim}
            </Link>
            <div className={styles.value}>{dateTimeDisplay(appointment.start_time)}</div>
          </div>
          <CButton
            className="text-nowrap"
            shape="rounded-pill"
            href={`/animals/${appointment.animal_id}/medical_history`}
            target="_blank"
          >
            Full Medical History
          </CButton>
        </CCardHeader>

        <CCardBody className={cn('d-flex', styles.body)}>
          <div className={styles.rows}>
            <div className={styles.column}>
              <div className="d-flex justify-content-between mb-3">
                <div>Consult Time</div>
                <div className={styles.value}>
                  {timeDisplay(appointment.start_time)}-{timeDisplay(appointment.end_time)}
                </div>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <div>Case Owner</div>
                <div className={styles.value}>
                  {appointment.case_owner && (
                    <Link to={`/employees/${appointment.case_owner.id}`}>
                      {appointment.case_owner.full_name_with_title}
                    </Link>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <div>Visit Reasons</div>
                <div className={styles.value}>{appointment.visit_reason_list.join(', ')}</div>
              </div>
            </div>

            <div className={styles.column}>
              <div className="d-flex justify-content-between mb-3">
                <div>Dropoff</div>
                <div className={styles.value}>{appointment.is_dropoff ? 'Yes' : 'No'}</div>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <div>Employee</div>
                <div className={styles.value}>
                  <Link to={`/employees/${appointment.employee.id}`}>{appointment.employee.full_name_with_title}</Link>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>Status</div>
                <div className={styles.value}>
                  <AppointmentStatusSelect appointment={appointment} />
                </div>
              </div>
            </div>
          </div>
          <div>Notes</div>
          <div className={cn(styles.value, styles.notes)}>{appointment.notes}</div>
        </CCardBody>
      </CCard>
    </div>
  );
};
