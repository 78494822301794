import axios from 'axios';

import { Pagination } from 'types/Pagination';
import { Product } from 'types/Product';

function fetchProducts(
  queryText: string,
  onSuccess: (products: Product[]) => void,
  setPagination?: (pagination: Pagination) => void,
  type?: string,
  status?: string,
  page?: number
) {
  const url = `/pim/products`;
  axios
    .get<Product[]>(url, {
      params: {
        type: type,
        query: queryText,
        page: page || 1,
        status
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      if (setPagination)
        setPagination({
          page: page || 1,
          perPage: Number(data.headers['per-page']),
          total: Number(data.headers.total)
        });
    });
}

function fetchProduct(productId: string, onSuccess: (problem: Product) => void) {
  const url = `/pim/products/${productId}`;
  axios.get<Product>(url).then((data) => onSuccess(data.data.record));
}

function updateProduct(
  productId: string,
  params: Product,
  onSuccess: (product: Product) => void,
  onError?: (error: string) => void
) {
  const url = `/pim/products/${productId}`;
  axios
    .put<Product>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError && onError(error.response.data.message));
}

function createProduct(params: Product, onSuccess: (product: Product) => void, onError?: (error: string) => void) {
  const url = `/pim/products`;
  axios
    .post<Product>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

function fetchProcedureProduct(query: string) {
  const url = '/pim/products';

  return axios
    .get<Product[]>(url, { params: { type: 'procedure', query: query } })
    .then((response) => response.data.record)
    .catch((error) => []);
}

function fetchVaccineProducts(query: string) {
  const url = '/pim/products';

  return axios
    .get<Product[]>(url, { params: { type: 'vaccination', query: query } })
    .then((response) => response.data.record)
    .catch((error) => []);
}

function fetchPrescriptionProducts(query: string) {
  const url = '/pim/products';

  return axios
    .get<Product[]>(url, { params: { type: 'medication', query: query } })
    .then((response) => response.data.record)
    .catch((error) => []);
}

async function fetchDiagnosticProducts(query: string) {
  const url = '/pim/products';

  return axios
    .get<Product[]>(url, { params: { type: 'diagnostic', query: query } })
    .then((response) => response.data.record)
    .catch((error) => []);
}

export {
  createProduct,
  fetchDiagnosticProducts,
  fetchPrescriptionProducts,
  fetchProcedureProduct,
  fetchProduct,
  fetchProducts,
  fetchVaccineProducts,
  updateProduct
};
