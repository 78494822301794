import axios from 'axios';

import { VisitSummary } from 'types/VisitSummary';

const fetchVisitSummaryForConsult = (
  consultId: number,
  { params, onSuccess }: { params?: { generate?: boolean }; onSuccess: (visitSummary: VisitSummary) => void }
) => {
  const url = `/pim/visit_summaries/find_by_consult`;

  axios
    .get<VisitSummary>(url, { params: { consult_id: consultId, ...params } })
    .then((data) => onSuccess(data.data.record));
};

const updateVisitSummary = (
  visitSummaryId: number,
  visitSummary: Partial<VisitSummary>,
  { onSuccess, onError }: { onSuccess: (visitSummary: VisitSummary) => void; onError?: (message: string) => void }
) => {
  const url = `/pim/visit_summaries/${visitSummaryId}`;

  axios
    .put<VisitSummary>(url, { visit_summary: visitSummary })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError) onError(error.response.data.message);
    });
};

export { fetchVisitSummaryForConsult, updateVisitSummary };
