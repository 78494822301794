import * as React from 'react';

import { CButton, CForm, CFormInput, CFormSelect, CLoadingButton } from '@coreui/react-pro';

import { AttachmentType } from 'types/AttachmentType';

import { toOption } from 'utils/selectOptions';

import styles from './AttachmentForm.module.scss';

type Props = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  attachmentTypes: AttachmentType[];
  hideModal: () => void;
  loading: boolean;
};

export const AttachmentForm = ({ onSubmit, attachmentTypes, hideModal, loading }: Props) => {
  return (
    <CForm onSubmit={onSubmit}>
      <div className="mb-4">
        <div className="d-flex align-items-center justify-content-between form-label">
          <label htmlFor="attachments[]">Files</label>
          <div className={styles.required}>Required</div>
        </div>

        <CFormInput type="file" id="attachments[]" name="attachments[]" multiple required />
      </div>

      <div className="mb-4">
        <div className="d-flex align-items-center justify-content-between form-label">
          <label htmlFor="attachment_type_id">Attachment Type</label>
          <div className={styles.required}>Required</div>
        </div>

        <CFormSelect
          options={[{ label: '', value: '' }, ...attachmentTypes.map(toOption)]}
          id="attachment_type_id"
          name="attachment_type_id"
          required
        />
      </div>

      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <CLoadingButton loading={loading} color="primary" shape="rounded-pill" type="submit">
          Upload
        </CLoadingButton>
        <CButton variant="outline" type="button" shape="rounded-pill" onClick={hideModal}>
          Cancel
        </CButton>
      </div>
    </CForm>
  );
};
