import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { cilCheckAlt, cilTask } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CBadge,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalHeader,
  CMultiSelect,
  CRow,
  CSmartTable,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableRow
} from '@coreui/react-pro';

import { updateAnimal } from 'api/Animals';
import { createAttachmentsV2 } from 'api/Attachments';
import { fetchAttachmentTypes } from 'api/AttachmentTypes';
import { getPreviousClinicsQueue, updateAnimalPreviousClinic } from 'api/PreviousClinics';

import { Animal } from 'types/Animal';
import { AnimalPreviousClinic } from 'types/AnimalPreviousClinic';
import { Attachment } from 'types/Attachment';
import { AttachmentType } from 'types/AttachmentType';

import { compactDateTimeDisplay, dateTimeDisplay } from 'utils/dates';
import { formatPhoneNumber } from 'utils/phoneNumbers';
import { pluralize } from 'utils/strings';

interface QueueProps {
  animal: Animal;
  patient_name: string;
  queue_status: string;
  membership_date: string;
  membership_pretty: string;
  id: number;
}

const Queue = (): JSX.Element => {
  const [queue, setQueue] = useState<QueueProps[]>();
  const [currentAnimal, setCurrentAnimal] = useState<Animal | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isLoadingQueue, setIsLoadingQueue] = useState<boolean>(true);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const [attachmentTypeId, setAttachmentTypeId] = useState<number>();

  useEffect(() => {
    if (!queue) getPreviousClinicsQueue(loadingQueueComplete);
  });

  useEffect(() => {
    fetchAttachmentTypes({ onSuccess: handleFetchAttachmentTypesSuccess });
  }, []);

  const handleFetchAttachmentTypesSuccess = (attachmentTypes: AttachmentType[]) => {
    const medicalRecordAttachmentType = attachmentTypes.find(
      (attachmentType) => attachmentType.name === 'Medical Record'
    );
    setAttachmentTypeId(medicalRecordAttachmentType?.id);
  };

  function loadingQueueComplete(animals: Animal[]) {
    updateQueue(animals);
    setIsLoadingQueue(false);
  }

  function updateQueue(animals: Animal[]) {
    setQueue(
      animals.map((animal: Animal) => {
        return {
          animal: animal,
          patient_name: `"${animal.name}" ${animal.customer.last_name}`,
          queue_status: getStatus(animal),
          membership_date: animal.membership?.created_at || '',
          membership_pretty: animal.membership?.created_at ? compactDateTimeDisplay(animal.membership.created_at) : '',
          id: animal.id
        };
      })
    );
  }

  function markCompletedSuccess(animal: Animal) {
    setCurrentAnimal(null);
    if (queue && queue.length > 1) {
      setIsFormVisible(false);
      setQueue(queue.filter((a: QueueProps) => a.id !== animal.id));
    } else {
      setIsFormVisible(false);
      getPreviousClinicsQueue(loadingQueueComplete);
    }
  }

  function handleMarkCompleted(animalId: number) {
    updateAnimal(
      animalId,
      { previous_clinics_processed: true },
      {
        onSuccess: markCompletedSuccess,
        onError: () => {
          return;
        }
      }
    );
  }

  function handleUploadClick(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!currentAnimal) return;
    if (!attachmentTypeId) return;

    setIsUploading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);

    formData.append('record_type', 'Animal');
    formData.append('record_id', String(currentAnimal.id));
    formData.append('attachment_type_id', String(attachmentTypeId));

    createAttachmentsV2(formData, { onSuccess: handleUploadSuccess, onError: handleUploadError });
  }

  function handleUploadSuccess(attachments: Attachment[]) {
    setIsUploading(false);
    setIsFormVisible(false);

    const message = pluralize('Attachment', attachments.length) + ' uploaded!';
    toast.success(message);

    getPreviousClinicsQueue(loadingQueueComplete);
  }

  function handleUploadError() {
    setIsUploading(false);
  }

  function updateAnimalPreviousClinicSuccess(animalPreviousClinic: AnimalPreviousClinic) {
    if (!queue) return;
    const updatedQueue = queue.map((q: QueueProps) => {
      const animal = q.animal;

      if (animal.id === animalPreviousClinic.animal_id) {
        const updatedAnimal = {
          ...animal,
          animal_previous_clinics: animal.animal_previous_clinics.map((previousClinic: AnimalPreviousClinic) => {
            if (previousClinic.id === animalPreviousClinic.id) {
              return animalPreviousClinic;
            } else {
              return previousClinic;
            }
          })
        };
        setCurrentAnimal(updatedAnimal);
        return updatedAnimal;
      } else {
        return animal;
      }
    });
    updateQueue(updatedQueue);
    setIsFormVisible(false);
  }

  function handleRecordsRequested(animalPreviousClinicId: number) {
    updateAnimalPreviousClinic(
      animalPreviousClinicId,
      { records_requested_at: new Date().toISOString() },
      updateAnimalPreviousClinicSuccess
    );
  }

  function updateAnimalClinicsRequestedSuccess(updated: Animal) {
    if (!queue) return;
    const updatedQueue = queue.map((q: QueueProps) => {
      const animal = q.animal;

      if (animal.id === updated.id) {
        const updatedAnimal = {
          ...animal,
          previous_clinics_requested: true
        };
        setCurrentAnimal(updatedAnimal);
        return updatedAnimal;
      } else {
        return animal;
      }
    });
    updateQueue(updatedQueue);
    setIsFormVisible(false);
  }

  function handleClinicNamesRequested(animalId: number) {
    updateAnimal(
      animalId,
      { previous_clinics_requested: true },
      {
        onSuccess: updateAnimalClinicsRequestedSuccess,
        onError: () => {
          return;
        }
      }
    );
  }

  function selectAnimal(queueRow: QueueProps) {
    setCurrentAnimal(queueRow.animal);
    setIsFormVisible(true);
  }

  function previousClinicRow(animalPreviousClinic: AnimalPreviousClinic) {
    return (
      <Fragment key={animalPreviousClinic.id}>
        <CTableRow>
          <CTableDataCell className="fw-bold">
            <Link to={`/previous_clinics/${animalPreviousClinic.previous_clinic.id}`}>
              {animalPreviousClinic.previous_clinic.name}
            </Link>
          </CTableDataCell>
          <CTableDataCell>
            {animalPreviousClinic.previous_clinic.phone &&
              formatPhoneNumber(animalPreviousClinic.previous_clinic.phone)}
          </CTableDataCell>
          <CTableDataCell>
            {animalPreviousClinic.previous_clinic.street1} {animalPreviousClinic.previous_clinic.street2}
          </CTableDataCell>
          <CTableDataCell>
            {animalPreviousClinic.previous_clinic.city}, {animalPreviousClinic.previous_clinic.state}{' '}
            {animalPreviousClinic.previous_clinic.zip_code}
          </CTableDataCell>
          <CTableDataCell>
            {animalPreviousClinic.records_requested_at ? (
              `Records requested: ${dateTimeDisplay(animalPreviousClinic.records_requested_at)}`
            ) : (
              <CButton
                size="sm"
                onClick={() => {
                  handleRecordsRequested(animalPreviousClinic.id);
                }}
              >
                Records Requested
              </CButton>
            )}
          </CTableDataCell>
        </CTableRow>
        <CTableRow>
          {animalPreviousClinic.records_requested_at && (
            <CTableDataCell colSpan={5}>
              <CForm onSubmit={handleUploadClick} className="pb-2">
                <CRow>
                  <CCol>
                    <CFormInput required type="file" id="attachments[]" name="attachments[]" multiple />
                  </CCol>
                  <CCol>
                    <CLoadingButton disabled={isUploading} loading={isUploading} type="submit">
                      Upload
                    </CLoadingButton>
                  </CCol>
                </CRow>
              </CForm>

              {animalPreviousClinic.attachments && (
                <CTable color="secondary">
                  <CTableBody>
                    {animalPreviousClinic.attachments &&
                      animalPreviousClinic.attachments.map((attachment: Attachment) => (
                        <CTableRow key={attachment.id}>
                          <CTableDataCell>{attachment.filename}</CTableDataCell>
                        </CTableRow>
                      ))}
                  </CTableBody>
                </CTable>
              )}
            </CTableDataCell>
          )}
        </CTableRow>
      </Fragment>
    );
  }

  function animalClinicsTable(animal: Animal) {
    return (
      <CTable>
        <CTableBody>
          {animal.animal_previous_clinics.map((animalPreviousClinic: AnimalPreviousClinic) =>
            previousClinicRow(animalPreviousClinic)
          )}
        </CTableBody>
      </CTable>
    );
  }

  function getStatus(animal: Animal) {
    const pendingClinicCount = animal.animal_previous_clinics.filter((animalPreviousClinic: AnimalPreviousClinic) => {
      return !animalPreviousClinic.records_requested_at;
    }).length;
    const requestedClinicCount = animal.animal_previous_clinics.filter((animalPreviousClinic: AnimalPreviousClinic) => {
      return animalPreviousClinic.records_requested_at;
    }).length;

    if (pendingClinicCount > 0) {
      return 'Not Requested';
    } else if (requestedClinicCount > 0) {
      return 'Records Requested';
    } else if (animal.previous_clinics_requested) {
      return 'Clinics Requested';
    } else {
      return 'No Records';
    }
  }

  function getStatusColor(status: string) {
    if (status === 'Not Requested') {
      return 'warning';
    } else if (status === 'Records Requested') {
      return 'primary';
    } else if (status === 'Clinics Requested') {
      return 'info';
    } else {
      return 'dark';
    }
  }

  function animalForm(animal: Animal) {
    return (
      <>
        <CModalHeader>
          <h4>
            <Link to={`/animals/${animal.id}`}>{animal.name}</Link> -{' '}
            <Link to={`/customers/${animal.customer.id}`}>
              {animal.customer.first_name} {animal.customer.last_name}
            </Link>
          </h4>
        </CModalHeader>
        <CModalBody>
          {animal.animal_previous_clinics.length > 0 && animalClinicsTable(animal)}
          <div className="d-sm-flex justify-content-around">
            {animal.animal_previous_clinics.length === 0 ? (
              !animal.previous_clinics_requested ? (
                <CButton
                  size="sm"
                  onClick={() => {
                    handleClinicNamesRequested(animal.id);
                  }}
                >
                  Clinic Names Requested
                </CButton>
              ) : (
                <div className="d-sm-flex align-items-center">
                  Clinic Names Requested <CIcon aria-label="task" className="ms-2 icon-xl" icon={cilCheckAlt} />
                </div>
              )
            ) : null}
            <CButton
              size="sm"
              color="dark"
              onClick={() => {
                handleMarkCompleted(animal.id);
              }}
            >
              All Records Uploaded
            </CButton>
          </div>
        </CModalBody>
      </>
    );
  }

  return (
    <>
      <h4 className="pb-4">
        <CIcon aria-label="task" className="me-2 icon-xl" icon={cilTask} />
        Previous Clinics Queue
      </h4>

      <p>Select an animal from the list below.</p>
      <CSmartTable
        items={queue}
        itemsPerPageSelect
        loading={isLoadingQueue}
        itemsPerPage={20}
        columnSorter
        columnFilter
        clickableRows
        onRowClick={(queue) => selectAnimal(queue as QueueProps)}
        pagination
        tableHeadProps={{ color: 'dark' }}
        tableProps={{ bordered: true }}
        columns={[
          {
            key: 'patient_name',
            label: 'Patient',
            sorter: false
          },
          {
            key: 'queue_status',
            label: 'Status',
            sorter: false,
            filter: (_, onChange) => {
              const unique = ['Not Requested', 'Clinics Requested', 'Records Requested', 'No Records'];
              return (
                <CMultiSelect
                  size="sm"
                  onChange={(selected) => {
                    const _selected = selected.map((element) => {
                      return element.value;
                    });
                    onChange((item: string) => {
                      return Array.isArray(_selected) && _selected.length ? _selected.includes(item) : true;
                    });
                  }}
                  options={unique.map((element) => {
                    return {
                      value: element,
                      text: element
                    };
                  })}
                />
              );
            }
          },
          {
            key: 'membership_date',
            label: 'Membership Created On',
            filter: false
          }
        ]}
        scopedColumns={{
          queue_status: (animal: QueueProps) => {
            return (
              <td>
                <CBadge color={getStatusColor(animal.queue_status)} shape="rounded-pill">
                  {animal.queue_status}
                </CBadge>
              </td>
            );
          },
          membership_date: (animal: QueueProps) => {
            return <td className="py-2">{animal.membership_pretty}</td>;
          }
        }}
      />

      <CModal
        className="show d-block"
        visible={isFormVisible}
        size="lg"
        fullscreen="sm"
        onClose={() => setIsFormVisible(false)}
      >
        {currentAnimal && animalForm(currentAnimal)}
      </CModal>
    </>
  );
};

export default Queue;
