import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { CButton, CFormTextarea, CSpinner } from '@coreui/react-pro';

import { updateAnimal } from 'api/Animals';

import { Animal } from 'types/Animal';

import { renderMarkdown } from 'utils/markdown';

import SvgPencil from 'assets/images/SvgPencil';

import styles from './InternalNote.module.scss';

type Props = {
  animal: Animal;
  onNoteSaved: (animal: Animal) => void;
  title?: string;
};

export const InternalNote = ({ animal, onNoteSaved, title }: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [animalNotes, setAnimalNotes] = useState(animal.internal_notes);

  const handleCancel = () => {
    setIsEditing(false);
    setAnimalNotes(animal.internal_notes);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    updateAnimal(
      animal.id,
      { ...animal, internal_notes: animalNotes },
      {
        onSuccess: updateSuccess,
        onError: updateError
      }
    );
  };

  const updateSuccess = (updatedAnimal: Animal) => {
    setIsSubmitting(false);
    setIsEditing(false);
    onNoteSaved(updatedAnimal);
  };

  const updateError = () => {
    setIsSubmitting(false);
  };

  const handleAnimalNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAnimalNotes(event.target.value);
  };

  return (
    <div className={styles.root}>
      <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
        <h2 className={styles.header}>{title ? title : 'Internal Note'}</h2>
        {!isEditing && (
          <CButton className={styles.editButton} shape="rounded-pill" onClick={() => setIsEditing(true)}>
            <SvgPencil height={17} />
          </CButton>
        )}
      </div>
      {isEditing ? (
        <div className={cn('d-flex flex-column align-items-start')}>
          <CFormTextarea
            id="animal_notes"
            rows={6}
            placeholder="Notes about the animal are only visible to staff"
            value={animalNotes || undefined}
            onChange={handleAnimalNotesChange}
            className={styles.textarea}
            text={<Link to="https://daringfireball.net/projects/markdown/">Markdown supported</Link>}
          />

          <div className="d-flex align-items-center justify-content-end gap-2 w-100 mt-2">
            <CButton
              color="primary"
              variant="outline"
              shape="rounded-pill"
              size="sm"
              className="px-3"
              onClick={handleCancel}
            >
              Cancel
            </CButton>
            <CButton
              color="primary"
              shape="rounded-pill"
              type="submit"
              size="sm"
              className="px-3"
              onClick={handleSubmit}
            >
              {isSubmitting ? <CSpinner size="sm" /> : 'Save'}
            </CButton>
          </div>
        </div>
      ) : animal.internal_notes ? (
        <div className={styles.animalNotes}>{renderMarkdown(animal.internal_notes)}</div>
      ) : (
        <div>
          <i>None entered</i>
        </div>
      )}
    </div>
  );
};
