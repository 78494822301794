import * as React from 'react';
import type { SVGProps } from 'react';

const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.5 13.5s-.234.943-.5 1.5c-.304.635-1 1.5-1 1.5 2.749-1.178 5.5-3.5 6-4.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.5 13.5H7c-3.75 0-5-1.6-5-4.35v-3.3C2 3.1 3.5 2 7.25 2h4.5C15.5 2 17 3.1 17 5.85v3.3C17 11 16 12 16 12"
    />
    <path
      fill="currentColor"
      d="M14 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0M10.5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
    />
  </svg>
);
export default SvgChat;
