import * as React from 'react';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import DocumentInputs from 'views/document_templates/DocumentInputs';

import { CButton, CModal, CModalBody, CModalHeader, CSpinner } from '@coreui/react-pro';

import { fetchConsultTemplate, fetchDocumentTemplates } from 'api/ConsultTemplates';
import { createDocument } from 'api/Documents';

import { ConsultTemplate } from 'types/ConsultTemplate';
import { Document } from 'types/Document';
import { DocumentContextObjects } from 'types/DocumentContextObjects';
import { Option } from 'types/Option';

import { replaceKeywords } from 'utils/documents';
import { reactSelectStyles } from 'utils/reactSelect';
import { toOption } from 'utils/selectOptions';

import { RichTextEditor } from 'components/RichTextEditor';

type Props = {
  onClose: () => void;
  onSuccess: (document: Document) => void;
  docContextObjects: DocumentContextObjects;
  isVisible: boolean;
};

export const NewDocumentModal = ({ onClose, onSuccess, docContextObjects, isVisible }: Props) => {
  const [documentPreview, setDocumentPreview] = useState<Partial<Document>>();
  const [template, setTemplate] = useState<ConsultTemplate>();
  const [isSavingDocument, setIsSavingDocument] = useState<boolean>(false);
  const [contextObjects, setContextObjects] = useState<DocumentContextObjects>(docContextObjects);

  const handleDocumentSelected = (document: Option | null) => {
    if (document && document.value)
      fetchConsultTemplate(document.value, (template) => {
        setTemplate(template);
        // replace all {{variable}}s with their values
        const replaced = replaceKeywords(template.content, contextObjects);
        setDocumentPreview({
          customer_id: contextObjects.customer?.id,
          animal_id: contextObjects.animal?.id,
          consult_id: contextObjects.consult?.id,
          content: replaced,
          name: template.name,
          signed: false
        });
      });
  };

  useEffect(() => {
    updateDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextObjects]);

  const updateDocument = () => {
    if (!template) return;
    const replaced = replaceKeywords(template.content, contextObjects);
    setDocumentPreview({
      customer_id: contextObjects.customer?.id,
      animal_id: contextObjects.animal?.id,
      consult_id: contextObjects.consult?.id,
      content: replaced,
      name: template.name,
      signed: false
    });
  };

  const handleCreateDocument = () => {
    setIsSavingDocument(true);
    let needsSignature = false;
    if (
      documentPreview?.content &&
      (documentPreview.content.indexOf('#INPUT#') > -1 || documentPreview.content.indexOf('#SIGNATURE#') > -1)
    )
      needsSignature = true;

    if (documentPreview)
      createDocument(
        { ...documentPreview, pdf: !needsSignature },
        { onSuccess: handleDocumentSuccess, onError: handleDocumentError }
      );
  };

  const handleDocumentSuccess = (document: Document) => {
    setDocumentPreview(undefined);
    setIsSavingDocument(false);
    onSuccess(document);
  };

  const handleDocumentError = () => {
    setIsSavingDocument(false);
  };

  return (
    <CModal
      visible={isVisible}
      onClose={() => {
        setDocumentPreview(undefined);
        onClose();
      }}
      size="xl"
      backdrop="static"
    >
      <CModalHeader>
        <h4>Choose Document Type</h4>
      </CModalHeader>
      <CModalBody>
        <DocumentInputs contextObjects={contextObjects} setContextObjects={setContextObjects} />

        <label className="form-label mb-0">Document</label>
        <AsyncSelect<Option>
          aria-label="Choose Document Type"
          placeholder="Type to search..."
          styles={reactSelectStyles}
          onChange={(value) => handleDocumentSelected(value)}
          loadingMessage={(input: { inputValue: string }) => 'Loading...'}
          defaultOptions
          loadOptions={(inputValue, callback) => {
            fetchDocumentTemplates(inputValue, (templates: ConsultTemplate[]) => {
              callback(templates.map(toOption));
            });
          }}
          isSearchable
          required
        />

        {documentPreview && (
          <div className="mt-3">
            <RichTextEditor
              id="document-preview"
              label="Document Preview"
              name="document preview"
              editorType="document"
              value={documentPreview.content}
              onChange={(value) => setDocumentPreview({ ...documentPreview, content: value })}
              required
            />
            <CButton onClick={handleCreateDocument} disabled={!documentPreview || isSavingDocument}>
              {isSavingDocument && <CSpinner size="sm" className="me-2" />}
              Create
            </CButton>
          </div>
        )}
      </CModalBody>
    </CModal>
  );
};
