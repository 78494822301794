import React from 'react';

const AnimalDetails = React.lazy(() => import('./views/animals/Details'));
const AnimalMedicalHistory = React.lazy(() => import('./views/animals/MedicalHistory'));
const AnimalList = React.lazy(() => import('./views/animals/List'));
const AppointmentApprovalQueue = React.lazy(() => import('./views/appointments/AppointmentApprovalQueue'));
const AppointmentCalendar = React.lazy(() => import('./views/appointments/Calendar'));
const AppointmentCheckout = React.lazy(() => import('./views/checkout/AppointmentCheckout'));
const AppointmentDetails = React.lazy(() => import('./views/appointments/Details'));
const AppointmentEdit = React.lazy(() => import('./views/appointments/Edit'));
const AppointmentTypesList = React.lazy(() => import('./views/appointment_types/List'));
const AppointmentTypeDetails = React.lazy(() => import('./views/appointment_types/Details'));
const AppointmentTypeEdit = React.lazy(() => import('./views/appointment_types/Edit'));
const BreedList = React.lazy(() => import('./views/breeds/List'));
const ClinicEdit = React.lazy(() => import('./views/clinics/Edit'));
const ClinicsList = React.lazy(() => import('./views/clinics/List'));
const ClinicNew = React.lazy(() => import('./views/clinics/New'));
const CustomerDetails = React.lazy(() => import('./views/customers/Details'));
const CustomerEdit = React.lazy(() => import('./views/customers/Edit'));
const ConsultDetails = React.lazy(() => import('./views/consults/Details'));
const ConsultTemplatesDetails = React.lazy(() => import('./views/consult_templates/Details'));
const ConsultTemplatesEdit = React.lazy(() => import('./views/consult_templates/Edit'));
const ConsultTemplatesList = React.lazy(() => import('./views/consult_templates/List'));
const ConsultTemplatesNew = React.lazy(() => import('./views/consult_templates/New'));
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const DiagnosticEdit = React.lazy(() => import('./views/diagnostics/Edit'));
const DiagnosticsList = React.lazy(() => import('./views/diagnostics/List'));
const DiagnosticNew = React.lazy(() => import('./views/diagnostics/New'));
const DiagnosticsQueue = React.lazy(() => import('./views/diagnostics/DiagnosticsQueue'));
const DispenseQueue = React.lazy(() => import('./views/dispense/Queue'));
const DocumentDetails = React.lazy(() => import('./views/documents/Details'));
const DocumentTemplatesDetails = React.lazy(() => import('./views/document_templates/Details'));
const DocumentTemplatesEdit = React.lazy(() => import('./views/document_templates/Edit'));
const DocumentTemplatesList = React.lazy(() => import('./views/document_templates/List'));
const DocumentTemplatesNew = React.lazy(() => import('./views/document_templates/New'));
const EmployeesList = React.lazy(() => import('./views/employees/List'));
const EmployeeDetails = React.lazy(() => import('./views/employees/Details'));
const EmployeeEdit = React.lazy(() => import('./views/employees/Edit'));
const EmployeeNew = React.lazy(() => import('./views/employees/New'));
const EstimateDetails = React.lazy(() => import('./views/estimates/Details'));
const EstimateTemplatesList = React.lazy(() => import('./views/estimate_templates/List'));
const EstimateTemplatesNew = React.lazy(() => import('./views/estimate_templates/New'));
const EstimateTemplatesDetails = React.lazy(() => import('./views/estimate_templates/Details'));
const EstimateTemplatesEdit = React.lazy(() => import('./views/estimate_templates/Edit'));
const GuidedForm = React.lazy(() => import('./views/appointments/GuidedForm'));
const HealthPlansList = React.lazy(() => import('./views/health_plans/List'));
const HealthPlansNew = React.lazy(() => import('./views/health_plans/New'));
const HealthPlansEdit = React.lazy(() => import('./views/health_plans/Edit'));
const HealthPlansDetails = React.lazy(() => import('./views/health_plans/Details'));
const HealthPlansAddProduct = React.lazy(() => import('./views/health_plans/AddProduct'));
const HealthPlansEditProduct = React.lazy(() => import('./views/health_plans/EditProduct'));
const HealthPlansAddTrigger = React.lazy(() => import('./views/health_plans/AddTrigger'));
const HealthPlansEditTrigger = React.lazy(() => import('./views/health_plans/EditTrigger'));
const HospitalSheetDetails = React.lazy(() => import('./views/whiteboard/HospitalSheetDetails'));
const HuddleList = React.lazy(() => import('./views/huddle/HuddleList'));
const MasterProblemsDetail = React.lazy(() => import('./views/master_problems/Details'));
const MasterProblemsList = React.lazy(() => import('./views/master_problems/List'));
const MasterProblemsNew = React.lazy(() => import('./views/master_problems/New'));
const MasterProblemsEdit = React.lazy(() => import('./views/master_problems/Edit'));
const MemosInbox = React.lazy(() => import('./views/memos/Inbox'));
const InvoiceDetails = React.lazy(() => import('./views/invoices/Details'));
const PrescriptionDetails = React.lazy(() => import('./views/prescriptions/Details'));
const PreviousClinicDetails = React.lazy(() => import('./views/previous_clinics/Details'));
const PreviousClinicEdit = React.lazy(() => import('./views/previous_clinics/Edit'));
const PreviousClinicsQueue = React.lazy(() => import('./views/previous_clinics_queue/Queue'));
const ProductsList = React.lazy(() => import('./views/products/List'));
const ProductsDetails = React.lazy(() => import('./views/products/Details'));
const ProductsEdit = React.lazy(() => import('./views/products/Edit'));
const ProductsNew = React.lazy(() => import('./views/products/New'));
const Schedule = React.lazy(() => import('./views/schedule/Schedule'));
const SearchResults = React.lazy(() => import('./views/search/SearchResults'));
const RefillsQueue = React.lazy(() => import('./views/refills/Queue'));
const RefillsQueueV2 = React.lazy(() => import('./views/refills/QueueV2'));
const SchedulingCategoryList = React.lazy(() => import('./views/scheduling_categories/List'));
const SchedulingCategoryEdit = React.lazy(() => import('./views/scheduling_categories/Edit'));
const SchedulingCategoryNew = React.lazy(() => import('./views/scheduling_categories/New'));
const SchedulingResourcesSort = React.lazy(() => import('./views/scheduling_resources/Sort'));
const TasksQueue = React.lazy(() => import('./views/employee_tasks/Queue'));
const WhiteboardOverview = React.lazy(() => import('./views/whiteboard/WhiteboardOverview'));

export const paths = {
  home: '/*',
  appointmentDetails: '/appointments/:id',
  appointmentApprovalQueue: '/appointments_queue',
  animalDetails: '/animals/:id',
  clinicsList: `/clinics`,
  customerDetails: '/customers/:id',
  diagnosticsList: '/diagnostics',
  documentDetails: '/documents/:id',
  employeeDetails: '/employees/:id',
  invoiceDetails: '/invoices/:id',
  estimateDetails: '/estimates/:id',
  consultDetails: '/consults/:id',
  huddleList: '/huddle',
  searchResults: '/search'
};

const routes = [
  { path: paths.home, name: 'Home' },
  { path: '/appointments', name: 'Appointments', component: AppointmentCalendar },
  { path: '/appointments/new', name: 'New Appointment', component: GuidedForm },
  { path: '/appointments/calendar', name: 'Appointment Calendar', component: AppointmentCalendar },
  { path: '/appointments/:id/checkout', name: 'Appointment Calendar', component: AppointmentCheckout },
  { path: paths.appointmentDetails, name: 'Appointment Details', component: AppointmentDetails },
  { path: '/appointments/:id/edit', name: 'Edit Appointment', component: AppointmentEdit },
  { path: '/appointments/types', name: 'Appointment Types', component: AppointmentTypesList },
  { path: '/appointments/types/:id', name: 'Appointment Type Details', component: AppointmentTypeDetails },
  { path: '/appointments/types/:id/edit', name: 'Edit Appointment Type', component: AppointmentTypeEdit },
  { path: paths.appointmentApprovalQueue, name: 'Appointment Approval Queue', component: AppointmentApprovalQueue },
  { path: '/breeds', name: 'Breeds', component: BreedList },
  { path: '/clinics', name: 'Clinics', component: ClinicsList },
  { path: '/clinics/new', name: 'New Clinic', component: ClinicNew },
  { path: '/clinics/:id/edit', name: 'Edit Clinic', component: ClinicEdit },
  { path: '/dashboard', name: 'Customers', component: Dashboard },
  { path: '/diagnostics_queue', name: 'Diagnostics Queue', component: DiagnosticsQueue },
  { path: '/diagnostics', name: 'Diagnostics', component: DiagnosticsList },
  { path: '/diagnostics/new', name: 'New Diagnostic', component: DiagnosticNew },
  { path: '/diagnostics/:id/edit', name: 'Edit Diagnostic', component: DiagnosticEdit },
  { path: '/dispense', name: 'Dispense', component: DispenseQueue },
  { path: paths.documentDetails, name: 'Document Details', component: DocumentDetails },
  { path: '/document_templates/:id', name: 'Document Template Details', component: DocumentTemplatesDetails },
  { path: '/document_templates/:id/edit', name: 'Edit Document Template', component: DocumentTemplatesEdit },
  { path: '/document_templates', name: 'Document Templates', component: DocumentTemplatesList },
  { path: '/document_templates/new', name: 'New Document Template', component: DocumentTemplatesNew },
  { path: '/customers', name: 'Customers', component: Dashboard },
  { path: paths.customerDetails, name: 'Customer Details', component: CustomerDetails },
  { path: '/customers/:id/edit', name: 'Edit Customer', component: CustomerEdit },
  { path: paths.estimateDetails, name: 'Estimate Details', component: EstimateDetails },
  { path: paths.consultDetails, name: 'Consult Details', component: ConsultDetails },
  { path: '/consult_templates/:id', name: 'Consult Template Details', component: ConsultTemplatesDetails },
  { path: '/consult_templates/:id/edit', name: 'Edit Consult Template', component: ConsultTemplatesEdit },
  { path: '/consult_templates', name: 'Consult Templates', component: ConsultTemplatesList },
  { path: '/consult_templates/new', name: 'New Consult Template', component: ConsultTemplatesNew },
  { path: '/animals', name: 'Patients', component: AnimalList },
  { path: paths.animalDetails, name: 'Patient Details', component: AnimalDetails },
  { path: '/animals/:id/medical_history', name: 'Medical History', component: AnimalMedicalHistory },
  { path: '/employees', name: 'Employees', component: EmployeesList },
  { path: paths.employeeDetails, name: 'Employee Details', component: EmployeeDetails },
  { path: '/employees/:id/edit', name: 'Edit Employee', component: EmployeeEdit },
  { path: '/employees/new', name: 'Add New Employee', component: EmployeeNew },
  { path: '/estimate_templates', name: 'Estimate Templates', component: EstimateTemplatesList },
  { path: '/estimate_templates/new', name: 'New Estimate Template', component: EstimateTemplatesNew },
  { path: '/estimate_templates/:id', name: 'Estimate Template Details', component: EstimateTemplatesDetails },
  { path: '/estimate_templates/:id/edit', name: 'Edit Estimate Template', component: EstimateTemplatesEdit },
  { path: '/health_plans', name: 'Health Plans', component: HealthPlansList },
  { path: '/health_plans/new', name: 'Add Health Plan', component: HealthPlansNew },
  { path: '/health_plans/:id', name: 'Health Plan Details', component: HealthPlansDetails },
  { path: '/health_plans/:id/edit', name: 'Edit Health Plan', component: HealthPlansEdit },
  { path: '/health_plans/:id/products/new', name: 'Add Product to Health Plan', component: HealthPlansAddProduct },
  {
    path: '/health_plans/:planId/products/:productId/edit',
    name: 'Edit Health Plan Product',
    component: HealthPlansEditProduct
  },
  { path: '/health_plans/:id/triggers/new', name: 'Add Trigger to Health Plan', component: HealthPlansAddTrigger },
  {
    path: '/health_plans/:planId/triggers/:triggerId/edit',
    name: 'Edit Health Plan Trigger',
    component: HealthPlansEditTrigger
  },
  { path: '/hospital_sheet/:id', name: 'Hospital Sheet Details', component: HospitalSheetDetails },
  { path: paths.huddleList, name: 'Huddle', component: HuddleList },
  { path: '/invoices', name: 'Invoices', component: InvoiceDetails },
  { path: paths.invoiceDetails, name: 'Invoice Details', component: InvoiceDetails },
  { path: '/master_problems', name: 'Master Problems', component: MasterProblemsList },
  { path: '/master_problems/:id', name: 'Master Problem Details', component: MasterProblemsDetail },
  { path: '/master_problems/new', name: 'New Master Problem', component: MasterProblemsNew },
  { path: '/master_problems/:id/edit', name: 'Edit Master Problem', component: MasterProblemsEdit },
  { path: '/memos', name: 'Memos', component: MemosInbox },
  { path: '/prescriptions', name: 'Prescription Details', component: PrescriptionDetails },
  { path: '/prescriptions/:id', name: 'Prescription Details', component: PrescriptionDetails },
  { path: '/previous_clinics', name: 'Previous Clinics', component: PreviousClinicsQueue },
  { path: '/previous_clinics/:id', name: 'Previous Clinic Details', component: PreviousClinicDetails },
  { path: '/previous_clinics/:id/edit', name: 'Edit Previous Clinic', component: PreviousClinicEdit },
  { path: '/products', name: 'Products', component: ProductsList },
  { path: '/products/:id', name: 'Product Details', component: ProductsDetails },
  { path: '/products/:id/edit', name: 'Edit Product', component: ProductsEdit },
  { path: '/products/new', name: 'Add Product', component: ProductsNew },
  { path: '/refills', name: 'Refills', component: RefillsQueue },
  { path: '/refills-v2', name: 'Refills', component: RefillsQueueV2 },
  { path: '/schedule', name: 'Schedule', component: Schedule },
  { path: '/scheduling_categories', name: 'Scheduling Categories', component: SchedulingCategoryList },
  { path: '/scheduling_categories/:id/edit', name: 'Edit Scheduling Category', component: SchedulingCategoryEdit },
  { path: '/scheduling_categories/new', name: 'New Scheduling Category', component: SchedulingCategoryNew },
  { path: '/scheduling_resources/sort', name: 'Sort Schedulable Doctors', component: SchedulingResourcesSort },
  { path: paths.searchResults, name: 'Search Results', component: SearchResults },
  { path: '/employee_tasks', name: 'Tasks Queue', component: TasksQueue },
  { path: '/whiteboard', name: 'Whiteboard', component: WhiteboardOverview }
];

export const idleTimeoutPausePaths = ['/whiteboard'];

export default routes;
