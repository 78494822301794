import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';

import { createHealthPlanProduct } from 'api/HealthPlanProduct';
import { fetchHealthPlan } from 'api/HealthPlans';

import { HealthPlan } from 'types/HealthPlan';
import { HealthPlanProduct } from 'types/HealthPlanProduct';

import ProductForm from './ProductForm';

const AddProduct = (): JSX.Element => {
  const navigate = useNavigate();
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [healthPlan, setHealthPlan] = React.useState<HealthPlan>();
  const [healthPlanProduct, setHealthPlanProduct] = React.useState<HealthPlanProduct>({} as HealthPlanProduct);

  useEffect(() => {
    fetchHealthPlan(id, setHealthPlan);
    setHealthPlanProduct({ health_plan_id: Number(id) });
  }, [id]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createHealthPlanProduct(healthPlanProduct, handleResolve);
  };

  const handleResolve = () => {
    navigate(`/health_plans/${healthPlan?.id}`);
  };

  return (
    <div>
      {healthPlan && (
        <CCard style={{ width: '35rem' }}>
          <CCardHeader>
            <h3>Add Product to Plan</h3>
          </CCardHeader>
          <CCardBody>
            <ProductForm
              handleSubmit={handleSubmit}
              handleCancel={handleResolve}
              setHealthPlanProduct={setHealthPlanProduct}
              healthPlan={healthPlan}
              healthPlanProduct={healthPlanProduct}
            />
          </CCardBody>
        </CCard>
      )}
    </div>
  );
};

export default AddProduct;
