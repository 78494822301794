import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M13.499 5.37a.5.5 0 0 0-.143 0 1.93 1.93 0 0 1-1.86-1.935 1.935 1.935 0 1 1 2.002 1.935M12.726 10.83c1.027.173 2.16-.007 2.955-.54 1.057-.705 1.057-1.86 0-2.565-.803-.532-1.95-.712-2.978-.532M4.477 5.37a.5.5 0 0 1 .142 0 1.93 1.93 0 0 0 1.86-1.935A1.935 1.935 0 1 0 4.477 5.37M5.248 10.83c-1.027.173-2.16-.007-2.955-.54-1.057-.705-1.057-1.86 0-2.565.803-.532 1.95-.712 2.978-.532M8.999 10.972a.5.5 0 0 0-.143 0 1.93 1.93 0 0 1-1.86-1.935 1.935 1.935 0 1 1 3.87 0c-.007 1.05-.832 1.905-1.867 1.935M6.817 13.335c-1.058.704-1.058 1.86 0 2.564 1.2.803 3.165.803 4.365 0 1.057-.704 1.057-1.86 0-2.564-1.193-.796-3.165-.796-4.365 0" />
    </g>
  </svg>
);
export default SvgPeople;
