import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';

import { createHealthPlan } from 'api/HealthPlans';

import { HealthPlan } from 'types/HealthPlan';

import Form from './Form';

const New = (): JSX.Element => {
  const navigate = useNavigate();
  const [healthPlan, setHealthPlan] = useState<HealthPlan>({} as HealthPlan);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (event.target.type === 'checkbox') {
      setHealthPlan({ ...healthPlan, [event.target.id]: 'checked' in event.target ? event.target.checked : false });
    } else if (event.target.id === 'minimum_age_in_months') {
      setHealthPlan({ ...healthPlan, [event.target.id]: parseInt(event.target.value) });
    } else {
      setHealthPlan({ ...healthPlan, [event.target.id]: event.target.value });
    }
  };

  const updateError = () => {
    setIsSubmitting(false);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    createHealthPlan(healthPlan, updateSuccess, updateError);
  };

  const updateSuccess = (healthPlan: HealthPlan) => {
    setIsSubmitting(false);
    navigate(`/health_plans/${healthPlan.id}`);
  };

  const handleCancel = () => {
    navigate(`/health_plans`);
  };

  return (
    <CCard style={{ width: '35rem' }}>
      <CCardHeader>
        <h3>Create Health Plan</h3>
      </CCardHeader>
      <CCardBody>
        <Form
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleChange={handleChange}
          healthPlan={healthPlan}
          isSubmitting={isSubmitting}
        />
      </CCardBody>
    </CCard>
  );
};

export default New;
