import { Clinic } from './Clinic';
import { Employee } from './Employee';

export enum PimResourceStatus {
  Active = 'active',
  Disabled = 'disabled'
}

export type PimResource = {
  clinic_id: number;
  clinic: Clinic;
  created_at: string;
  display_name: string;
  employee: Employee;
  employee_id: number;
  id: number;
  pim_id: number;
  pim_name: string;
  status: PimResourceStatus;
  updated_at: string;
};
