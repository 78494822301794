import axios from 'axios';

import { AnesthesiaSheet } from 'types/AnesthesiaSheet';

function createAnesthesiaSheet(
  params: Partial<AnesthesiaSheet>,
  onSuccess: (anesthesiaSheet: AnesthesiaSheet) => void
) {
  const url = `/pim/anesthesia_sheets`;
  axios.post<AnesthesiaSheet>(url, { anesthesia_sheet: params }).then((data) => onSuccess(data.data.record));
}

const fetchAnesthesiaSheet = (
  anesthsiaSheetId: string | number,
  onSuccess: (anesthesiaSheet: AnesthesiaSheet) => void
) => {
  const url = `/pim/anesthesia_sheets/${anesthsiaSheetId}`;
  axios.get<AnesthesiaSheet>(url).then((data) => onSuccess(data.data.record));
};

export { createAnesthesiaSheet, fetchAnesthesiaSheet };
