import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  CAlert,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CLoadingButton,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchCustomers } from 'api/Customers';

import { CustomerListItem } from 'types/Customer';

const Dashboard = (): JSX.Element => {
  const [customerList, setCustomerList] = React.useState<CustomerListItem[]>();
  const [searchText, setSearchText] = React.useState<string>('');
  const [infoMessage, setInfoMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const setSearchResultMessage = useCallback((customerCount: number) => {
    if (customerCount > 0) {
      setInfoMessage(`${customerCount} customer${customerCount > 1 ? 's' : ''} found.`);
    } else {
      setInfoMessage('No customers found.');
    }
  }, []);

  const updateCustomerList = useCallback(
    (customers: CustomerListItem[]) => {
      setIsLoading(false);
      setSearchResultMessage(customers.length);
      setCustomerList(customers);
    },
    [setSearchResultMessage]
  );

  const fetchCustomerList = useCallback(() => {
    setInfoMessage('');
    setIsLoading(true);
    fetchCustomers(searchText, updateCustomerList);
  }, [searchText, updateCustomerList]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetchCustomerList();
  };

  return (
    <CRow>
      <CCol xs={12}>
        {infoMessage && (
          <CAlert color="info" dismissible>
            {infoMessage}
          </CAlert>
        )}
        <CCard className="mb-4">
          <CCardHeader>
            <CForm className="row g-3" onSubmit={handleSubmit}>
              <CCol xs={8}>
                <CFormInput
                  type="text"
                  id="searchInput"
                  label="Search Customers"
                  placeholder="Search by name or email"
                  onChange={(event) => setSearchText(event.target.value)}
                />
              </CCol>
              <CCol className="align-self-end" xs={4}>
                <CLoadingButton color="primary" type="submit" loading={isLoading} disabled={!searchText}>
                  Search
                </CLoadingButton>
              </CCol>
            </CForm>
          </CCardHeader>
          {customerList && customerList.length > 0 && (
            <CCardBody>
              <CTable>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>Name</CTableHeaderCell>
                    <CTableHeaderCell>Email</CTableHeaderCell>
                    <CTableHeaderCell>Clinic</CTableHeaderCell>
                    <CTableHeaderCell>Membership Plan</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {customerList.map((customer) => (
                    <CTableRow key={customer.id}>
                      <CTableDataCell>
                        <Link to={`/customers/${customer.id}`}>
                          {customer.first_name} {customer.last_name}
                        </Link>
                      </CTableDataCell>
                      <CTableDataCell>
                        <Link to={`/customers/${customer.id}`}>{customer.email}</Link>
                      </CTableDataCell>
                      <CTableDataCell>{customer.clinic?.name}</CTableDataCell>
                      <CTableDataCell>{customer.membership?.plan?.name}</CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CCardBody>
          )}
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Dashboard;
