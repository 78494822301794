import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { fetchEmployeesByQuery } from 'api/Employees';
import { updateHospitalSheet } from 'api/HospitalSheet';

import { HospitalSheet } from 'types/HospitalSheet';
import { Option } from 'types/Option';

import { employeeToOption } from 'utils/selectOptions';

import { ConfirmationModal } from 'components/ConfirmationModal';

type Props = {
  hospitalSheet: HospitalSheet;
  hideModal: () => void;
  onSuccess: (hospitalSheet: HospitalSheet) => void;
};

export const ReassignCaseOwners = ({ hospitalSheet, hideModal, onSuccess }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedCaseOwner, setSelectedCaseOwner] = useState<Option | null>(
    hospitalSheet.case_owner ? employeeToOption(hospitalSheet.case_owner) : null
  );
  const [selectedTech, setSelectedTech] = useState<Option | null>(
    hospitalSheet.assigned_nurse ? employeeToOption(hospitalSheet.assigned_nurse) : null
  );

  const updateCaseOwners = () => {
    setIsLoading(true);
    if (hospitalSheet && selectedCaseOwner && selectedTech) {
      updateHospitalSheet(
        hospitalSheet.id,
        {
          ...hospitalSheet,
          case_owner_id: Number(selectedCaseOwner.value),
          assigned_nurse_id: Number(selectedTech.value)
        },
        onSuccess
      );
    }
  };

  return (
    <ConfirmationModal
      isVisible
      isLoading={isLoading}
      onClose={hideModal}
      isDisabled={!(selectedCaseOwner && selectedTech)}
      onConfirm={updateCaseOwners}
      modalBody={`Current case owners will be removed.`}
      confirmButtonLabel={`Yes`}
      modalHeader="Reassign case owners?"
    >
      <label className="form-label mt-3 mb-1">Case Owner</label>
      <AsyncSelect<Option>
        aria-label="Case Owner"
        placeholder="Type to search..."
        value={selectedCaseOwner}
        onChange={(selected) => setSelectedCaseOwner(selected as Option)}
        loadOptions={(inputValue: string, callback: (options: Option[]) => void) => {
          fetchEmployeesByQuery(inputValue, { vets_only: true }).then((options) => {
            callback(options.map(employeeToOption));
          });
        }}
        defaultOptions
        isSearchable
        required
      />

      <label className="form-label mt-3 mb-1">Tech Assigned</label>
      <AsyncSelect<Option>
        aria-label="Tech Assigned"
        placeholder="Type to search..."
        value={selectedTech}
        onChange={(selected) => setSelectedTech(selected as Option)}
        loadOptions={(inputValue: string, callback: (options: Option[]) => void) => {
          fetchEmployeesByQuery(inputValue).then((options) => {
            callback(options.map(employeeToOption));
          });
        }}
        defaultOptions
        isSearchable
        required
      />
    </ConfirmationModal>
  );
};
