import * as React from 'react';

import { CButton, CCol, CForm, CFormTextarea, CLoadingButton } from '@coreui/react-pro';

import { AnimalHealthPlan } from 'types/AnimalHealthPlan';

import styles from './DisableAnimalHealthPlanForm.module.scss';

type Props = {
  loading: boolean;
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  animalHealthPlan?: AnimalHealthPlan;
};

export const DisableAnimalHealthPlanForm = ({ loading, hideModal, onSubmit, animalHealthPlan }: Props) => {
  return (
    <CForm className="row g-3" onSubmit={onSubmit}>
      <CCol className="mb-3">
        <div className="d-flex align-items-center justify-content-between form-label">
          <label htmlFor="audit_comment">Reason for Disabling</label>
          <div className={styles.required}>Required</div>
        </div>

        <CFormTextarea id="audit_comment" name="audit_comment" required />
      </CCol>

      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <CLoadingButton color="primary" shape="rounded-pill" loading={loading} type="submit">
          Disable
        </CLoadingButton>
        <CButton color="primary" shape="rounded-pill" variant="outline" type="button" onClick={hideModal}>
          Cancel
        </CButton>
      </div>
    </CForm>
  );
};
