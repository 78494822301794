import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CButton, CCard, CCol, CForm, CFormSelect, CLoadingButton, CRow } from '@coreui/react-pro';

import { fetchConsultTemplate, fetchConsultTemplates } from 'api/ConsultTemplates';

import { compileConsultTemplate, consultTemplateForm, FormLine } from 'types/ConsultTemplate';
import { Option } from 'types/Option';
import { PhysicalExam } from 'types/PhysicalExam';

import { isEnabled } from 'utils/filters';
import { reactSelectStyles } from 'utils/reactSelect';
import { physicalExamToOption, toOption } from 'utils/selectOptions';

import SvgClipboard from 'assets/images/SvgClipboard';

import { ConsultTemplateForm } from 'components/ConsultTemplateForm';
import { FormAuditData } from 'components/FormAuditData';
import { IconLabel } from 'components/IconLabel';
import { RichTextEditor } from 'components/RichTextEditor';

import styles from './PhysicalExamForm.module.scss';

type Props = {
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  physicalExam?: PhysicalExam;
  pastPhysicalExams: PhysicalExam[];
};

export const PhysicalExamForm = ({ loading, hideForm, onSubmit, physicalExam, pastPhysicalExams }: Props) => {
  const [exam, setExam] = useState(physicalExam?.exam ?? '');

  const [templateForm, setTemplateForm] = useState<FormLine[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<Option | null>();
  const [selectedPastPhysicalExam, setSelectedPastPhysicalExam] = useState('');

  const loadConsultTemplates = (inputValue: string, callback: (options: Option[]) => void) => {
    fetchConsultTemplates({
      queryText: inputValue,
      templateType: 'PhysicalExamTemplate',
      onSuccess: (templates) => {
        callback(templates.map(toOption));
      }
    });
  };

  const loadTemplate = (templateId: string) => {
    fetchConsultTemplate(templateId, (template) => {
      const form = consultTemplateForm(template);
      setTemplateForm(form);

      const newExam = compileConsultTemplate(form);
      setExam(newExam);
    });
  };

  const handleFormChange = (form: FormLine[]) => {
    setTemplateForm(form);

    const newExam = compileConsultTemplate(form);
    setExam(newExam);
  };

  const clearSelectedTemplate = () => {
    setSelectedTemplate(null);
    setTemplateForm(undefined);
  };

  const clearSelectedPastPhysicalExam = () => {
    setSelectedPastPhysicalExam('');
  };

  const handlePastPhsyicalExamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPastPhysicalExam(event.target.value);

    const pastPhysicalExam = pastPhysicalExams.find((past) => past.id.toString() === event.target.value);
    setExam(pastPhysicalExam?.exam ?? '');

    clearSelectedTemplate();
  };

  const handleTemplateChange = (selectedOption: Option | null) => {
    setSelectedTemplate(selectedOption);
    if (selectedOption) {
      loadTemplate(selectedOption.value);
    }

    clearSelectedPastPhysicalExam();
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{physicalExam ? 'Edit' : 'New'} Physical Exam</h2>

      {!physicalExam && (
        <CRow className="mb-3">
          <CCol>
            <label htmlFor="template" className="form-label">
              <IconLabel icon={SvgClipboard} label="Use Template" />
            </label>
            <AsyncSelect<Option>
              id="template"
              name="template"
              aria-label="Use Template"
              placeholder="Type to search..."
              value={selectedTemplate}
              onChange={handleTemplateChange}
              loadingMessage={() => 'Loading...'}
              loadOptions={loadConsultTemplates}
              styles={reactSelectStyles}
              defaultOptions
              isSearchable
            />
          </CCol>
          <CCol>
            <label htmlFor="past_physical_exam_select" className="form-label">
              <IconLabel icon={SvgClipboard} label="Use Past Physical Exam" />
            </label>
            <CFormSelect
              id="past_physical_exam_select"
              value={selectedPastPhysicalExam}
              onChange={handlePastPhsyicalExamChange}
              options={[{ label: '', value: '' }, ...pastPhysicalExams.filter(isEnabled).map(physicalExamToOption)]}
            />
          </CCol>
        </CRow>
      )}

      <CCol className="mb-3">
        {templateForm && (
          <CCard className="p-3">
            <ConsultTemplateForm form={templateForm} updateForm={handleFormChange} />
          </CCard>
        )}

        <RichTextEditor
          label="Exam"
          value={exam}
          hidden={!!templateForm}
          onChange={setExam}
          id="exam"
          name="exam"
          text="Required"
          required
        />
      </CCol>

      <div className="d-flex align-items-center justify-content-between">
        {physicalExam && <FormAuditData item={physicalExam} />}
        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton
            loading={loading}
            color="primary"
            type="submit"
            className={styles.button}
            shape="rounded-pill"
          >
            {physicalExam ? 'Update' : 'Create'}
          </CLoadingButton>
          <CButton
            type="button"
            shape="rounded-pill"
            className={styles.button}
            color="primary"
            variant="outline"
            onClick={hideForm}
          >
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
