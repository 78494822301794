import * as React from 'react';
import type { SVGProps } from 'react';

const SvgFileCabinet = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M12.996 6.813v5.854c0 1.333-.333 2-2 2h-6c-1.667 0-2-.667-2-2V6.813M3.328 1.334h9.334q2 0 2 2v1.333q0 2-2 2H3.328q-2 0-2-2V3.334q0-2 2-2M6.781 9.334h2.427" />
    </g>
  </svg>
);
export default SvgFileCabinet;
