import { DateSelectArg, EventChangeArg, EventClickArg, EventContentArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import luxon2Plugin from '@fullcalendar/luxon2';
import FullCalendar from '@fullcalendar/react';
import { ResourceLabelContentArg } from '@fullcalendar/resource';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import cn from 'classnames';
import { add, addMinutes } from 'date-fns';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';

import { cilCheckCircle, cilWarning } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CContainer, CLoadingButton, CNav, CNavItem, CNavLink } from '@coreui/react-pro';

import { createAnesthesiaSheet, fetchAnesthesiaSheet } from 'api/AnesthesiaSheets';
import { fetchClinic } from 'api/Clinics';
import { fetchConsult } from 'api/Consults';
import {
  createBatchHospitalEvents,
  createHospitalEvent,
  updateBatchHospitalEvents,
  updateHospitalEvent
} from 'api/HospitalEvent';
import { createHospitalEventSchedule, updateHospitalEventSchedule } from 'api/HospitalEventSchedule';
import { fetchHospitalSheet, updateHospitalSheet } from 'api/HospitalSheet';

import { AnesthesiaSheet } from 'types/AnesthesiaSheet';
import { Clinic } from 'types/Clinic';
import { Consult } from 'types/Consult';
import { HospitalEvent } from 'types/HospitalEvent';
import { HospitalEventSchedule } from 'types/HospitalEventSchedule';
import { HospitalSheet } from 'types/HospitalSheet';

import { useAuth } from 'hooks/useAuth';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { usePoll } from 'hooks/usePoll';

import { ClinicContext } from 'contexts/ClinicContext';

import { compactDateTimeDisplay } from 'utils/dates';

import SvgPlus from 'assets/images/SvgPlus';

import { AppointmentStatusSelect } from 'components/AppointmentStatusSelect';
import { ConfirmationModal } from 'components/ConfirmationModal';

import styles from './HospitalSheetDetails.module.scss';

import AnesthesiaEventForm from './AnesthesiaEventForm';
import { AttachmentsButton } from './AttachmentsButton';
import HospitalEventForm from './HospitalEventForm';
import HospitalScheduleForm from './HospitalScheduleForm';
import { HospitalSheetSignalment } from './HospitalSheetSignalment';

type Resource = {
  id: string;
  title: string;
  groupName: string;
  schedule: HospitalEventSchedule;
};

type Event = {
  id: string;
  title: string;
  backgroundColor: string;
  start: string;
  end: string;
  resourceId: string;
  display?: string;
  hospital_event?: HospitalEvent;
  schedule?: HospitalEventSchedule;
};

const HospitalSheetDetails = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const auth = useAuth();
  const [activeTab, setActiveTab] = useState<'hospital' | 'anesthesia'>('hospital');
  const [hospitalSheet, setHospitalSheet] = useState<HospitalSheet | undefined>(undefined);
  const [consult, setConsult] = useState<Consult>();

  const [anesthesiaSheetId, setAnesthesiaSheetId] = useState<number | undefined>(undefined);
  const [anesthesiaSheet, setAnesthesiaSheet] = useState<AnesthesiaSheet | undefined>(undefined);

  const [currentClinic, setCurrentClinic] = useState<Clinic | undefined>(undefined);
  const [events, setEvents] = useState<Event[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);
  const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);

  const [scheduleToAdd, setScheduleToAdd] = useState<Partial<HospitalEventSchedule> | undefined>();
  const [eventToAdd, setEventToAdd] = useState<Partial<HospitalEvent> | undefined>();
  const [eventsToAdd, setEventsToAdd] = useState<Partial<HospitalEvent>[] | undefined>();
  const [activeTime, setActiveTime] = useState<string>();

  const calendarRef = useRef<FullCalendar>(null);

  useDocumentTitle('Hospital Sheet', hospitalSheet?.animal.name);

  const { clinicContext } = useContext(ClinicContext);
  useEffect(() => {
    if (clinicContext) fetchClinic(Number(clinicContext), setCurrentClinic);
  }, [clinicContext]);

  const fetch = useCallback(() => {
    if (activeTab === 'hospital') fetchHospitalSheet(id, handleHospitalFetchSuccess);
    else if (anesthesiaSheetId) fetchAnesthesiaSheet(anesthesiaSheetId, handleAnesthesiaFetchSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, anesthesiaSheetId, activeTab]);
  usePoll(fetch);

  const handleHospitalFetchSuccess = (sheet: HospitalSheet) => {
    setHospitalSheet(sheet);
    aggregateResources(sheet.hospital_event_schedules);
    aggregateEvents(sheet.hospital_event_schedules);
    resizeSheet();
  };

  const handleAnesthesiaFetchSuccess = (sheet: AnesthesiaSheet) => {
    setAnesthesiaSheetId(sheet.id);
    setAnesthesiaSheet(sheet);
    aggregateResources(sheet.hospital_event_schedules);
    aggregateEvents(sheet.hospital_event_schedules);
    resizeSheet();
  };

  useEffect(() => {
    if (!selectedDay && currentClinic && hospitalSheet) {
      const today = DateTime.fromISO(hospitalSheet.hospitalized_at, { zone: currentClinic.time_zone })
        .startOf('day')
        .toJSDate();
      if (calendarRef.current) {
        calendarRef.current.getApi().gotoDate(today);
        setSelectedDay(today.toDateString());
      }

      // fetch the anesthesia sheet from the consult, if exists
      fetchConsult(hospitalSheet.consult_id, (consult) => {
        setConsult(consult);
        if (consult.anesthesia_sheet) setAnesthesiaSheetId(consult.anesthesia_sheet.id);
      });
    }
  }, [hospitalSheet, selectedDay, currentClinic]);

  useEffect(() => {
    // reset all forms when switching tabs
    setScheduleToAdd(undefined);
    setEventToAdd(undefined);
    setActiveTime(undefined);

    if (activeTab === 'anesthesia') {
      if (!anesthesiaSheetId) {
        createAnesthesiaSheet(
          {
            animal_id: hospitalSheet?.animal_id,
            clinic_id: hospitalSheet?.clinic_id,
            consult_id: hospitalSheet?.consult_id,
            case_owner_id: hospitalSheet?.case_owner_id,
            assigned_nurse_id: hospitalSheet?.assigned_nurse_id
          },
          handleAnesthesiaFetchSuccess
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const aggregateResources = (schedules: HospitalEventSchedule[]) => {
    setResources(
      schedules.map((schedule) => ({
        id: schedule.id.toString(),
        title: schedule.title,
        groupName: schedule.group || 'treatment',
        schedule: schedule
      }))
    );
  };

  const aggregateEvents = (schedules: HospitalEventSchedule[]) => {
    const taskEvents: Event[] = [];
    schedules.forEach((schedule) => {
      const hospitalEvents: Event[] = schedule.hospital_events.map((event) => {
        return {
          start: event.start_time,
          end: event.end_time,
          resourceId: schedule.id.toString(),
          title: getStatus(event),
          id: event.id.toString(),
          backgroundColor: '#FFFFFF',
          hospital_event: event,
          resourceEditable: false,
          schedule: schedule
        };
      });
      taskEvents.push(...hospitalEvents);
    });
    setEvents(taskEvents);
  };

  const getStatus = (event: HospitalEvent) => {
    const timeStarted = new Date() >= new Date(event.start_time);
    const pastTime = new Date() >= new Date(event.end_time);

    if (event.completed_at) return 'completed';
    if (timeStarted && !pastTime && !event.completed_at) return 'due_now';
    if (pastTime && !event.completed_at) return 'overdue';
    return 'not due yet';
  };

  const createTreatment = () => {
    setEventToAdd(undefined);
    const now = new Date();
    const m = (Math.round(now.getMinutes() / 15) * 15) % 60;
    const h = now.getMinutes() > 52 ? (now.getHours() === 23 ? 0 : now.getHours() + 1) : now.getHours();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), h, m);

    setScheduleToAdd({
      whiteboard_sheet_id: activeTab === 'hospital' ? hospitalSheet?.id : anesthesiaSheet?.id,
      start_time: start.toISOString(),
      end_time: addMinutes(start, 30).toISOString(),
      duration_in_minutes: 1,
      interval_in_minutes: 30,
      instructions: ''
    });
  };

  const handleCreateSchedule = (
    scheduleToSave: Partial<HospitalEventSchedule>,
    intervalUnit: string,
    isRepeating: boolean,
    isMonitoring: boolean
  ) => {
    if (!isRepeating && scheduleToSave && scheduleToSave.start_time) {
      const schedule = {
        ...scheduleToSave,
        end_time: add(new Date(scheduleToSave.start_time), {
          minutes: scheduleToSave.duration_in_minutes
        }).toISOString(),
        interval_in_minutes: scheduleToSave.duration_in_minutes,
        duration_in_minutes: scheduleToSave.duration_in_minutes,
        group: isMonitoring ? 'monitoring' : 'treatment'
      };
      if (scheduleToSave.id)
        updateHospitalEventSchedule(scheduleToSave.id, schedule, () => onScheduleChanged('updated'));
      else createHospitalEventSchedule(schedule, () => onScheduleChanged('created'));
    }

    if (
      isRepeating &&
      scheduleToSave &&
      scheduleToSave.end_time &&
      scheduleToSave.start_time &&
      scheduleToSave.interval_in_minutes
    ) {
      const schedule = {
        ...scheduleToSave,
        interval_in_minutes:
          intervalUnit === 'minutes' ? scheduleToSave.interval_in_minutes : scheduleToSave.interval_in_minutes * 60,
        duration_in_minutes: scheduleToSave.duration_in_minutes,
        group: isMonitoring ? 'monitoring' : 'treatment'
      };
      if (scheduleToSave.id)
        updateHospitalEventSchedule(scheduleToSave.id, schedule, () => onScheduleChanged('updated'));
      else createHospitalEventSchedule(schedule, () => onScheduleChanged('created'));
    }
  };

  const handleRemoveSchedule = (schedule: Partial<HospitalEventSchedule>) => {
    if (schedule.id)
      updateHospitalEventSchedule(schedule.id, { ...schedule, status: 'disabled' }, () => onScheduleChanged('removed'));
  };

  const onScheduleChanged = (message: string) => {
    toast.success(`Treatment ${message}!`);
    if (activeTab === 'hospital') fetchHospitalSheet(id, handleHospitalFetchSuccess);
    else if (anesthesiaSheetId) fetchAnesthesiaSheet(anesthesiaSheetId, handleAnesthesiaFetchSuccess);
    setScheduleToAdd(undefined);
    resizeSheet();
  };

  const handleSubmitEvent = (eventToSave: Partial<HospitalEvent>) => {
    if (eventToSave) {
      if (eventToSave.id)
        updateHospitalEvent(eventToSave.id, eventToSave, () =>
          onEventCreated(`Event ${eventToSave.completed_at ? 'completed' : 'updated'}!`)
        );
      else createHospitalEvent(eventToSave, () => onEventCreated('Event created!'));
    }
  };

  const handleSubmitBatchEvents = (eventsToSave: Partial<HospitalEvent>[]) => {
    if (eventsToSave) {
      // Note: Rails still needs an id to route to the update action,
      //   we agreed to use the first event id in the batch
      if (eventsToSave[0].id)
        updateBatchHospitalEvents(eventsToSave[0].id, eventsToSave, () => onEventCreated('Events updated!'));
      else createBatchHospitalEvents(eventsToSave, () => onEventCreated('Event created!'));
    }
  };

  const onEventCreated = (message: string) => {
    toast.success(message);
    if (activeTab === 'hospital') fetchHospitalSheet(id, handleHospitalFetchSuccess);
    else if (anesthesiaSheetId) fetchAnesthesiaSheet(anesthesiaSheetId, handleAnesthesiaFetchSuccess);
    setEventToAdd(undefined);
    setEventsToAdd(undefined);
    resizeSheet();
  };

  const handleRescheduleEvent = (event: EventChangeArg) => {
    const newValues = event.event.toPlainObject();
    const eventToSave = newValues.extendedProps.hospital_event;

    updateHospitalEvent(eventToSave.id, { ...eventToSave, start_time: newValues.start, end_time: newValues.end }, () =>
      onEventCreated('Event rescheduled!')
    );
  };

  const handleCellSelect = (event: DateSelectArg) => {
    if (event.resource) {
      const schedule: HospitalEventSchedule = event.resource._resource.extendedProps.schedule;
      const activeSheet = activeTab === 'hospital' ? hospitalSheet : anesthesiaSheet;
      // a standard schedule
      if (schedule.group === 'treatment') {
        setScheduleToAdd(undefined);
        setActiveTime(undefined);
        setEventToAdd({
          hospital_event_schedule_id: Number(event.resource.id),
          start_time: event.startStr,
          end_time: event.endStr,
          hospital_event_schedule: schedule
        });
      } else {
        // an anesthesia or monitoring schedule
        setActiveTime(event.start.toISOString());
        // create an array of new events, one for each group type schedule
        const newEvents = activeSheet?.hospital_event_schedules
          .filter((s) => s.group === schedule.group)
          .map((s) => ({
            hospital_event_schedule_id: s.id,
            start_time: event.start.toISOString(),
            end_time: event.end.toISOString(),
            hospital_event_schedule: s
          }));
        setEventsToAdd(newEvents);
      }
    }
  };

  const handleEventSelect = (clickInfo: EventClickArg) => {
    const event = clickInfo.event;
    const schedule = event.extendedProps.schedule;
    const activeSheet = activeTab === 'hospital' ? hospitalSheet : anesthesiaSheet;
    if (schedule.group === 'treatment') {
      if (event._def.resourceIds) {
        const resourceId = event._def.resourceIds[0];
        const resource = resources.find((r) => r.id === resourceId);
        const hospitalEvent = event._def.extendedProps.hospital_event;
        setScheduleToAdd(undefined);
        setEventToAdd({ ...hospitalEvent, hospital_event_schedule: resource?.schedule });
      }
    } else {
      const time = event.start?.toISOString();
      // loop through each schedule to find the event at that time
      const eventsToEdit = activeSheet?.hospital_event_schedules
        .filter((s) => s.group === schedule.group)
        .map((s) => {
          const ev = s.hospital_events.find((event) => new Date(event.start_time).toISOString() === time);
          return { ...ev, hospital_event_schedule: s };
        });
      setEventsToAdd(eventsToEdit as HospitalEvent[]);
      setActiveTime(time);
    }
  };

  const renderResourceCell = (resource: ResourceLabelContentArg) => {
    const schedule = resource.resource._resource.extendedProps.schedule as HospitalEventSchedule;
    if (!schedule) return null;
    if (schedule.group === 'anesthesia') return <span>{schedule.title}</span>;
    else
      return (
        <span>
          <CButton color="link" className="p-0" onClick={() => setScheduleToAdd(schedule)}>
            {schedule.title}
          </CButton>
        </span>
      );
  };

  const renderEventCell = (e: EventContentArg) => {
    const event = e.event._def;
    const schedule = event.extendedProps.schedule;
    if (schedule.group === 'anesthesia') {
      return <div className={cn(styles.eventCell, styles.cellPlain)}>{event.extendedProps.hospital_event.value}</div>;
    } else if (schedule.group === 'treatment') {
      const hospitalEvent = event.extendedProps.hospital_event;
      const completed = hospitalEvent.completed_at;
      const pastTime = new Date() >= new Date(hospitalEvent.end_time);

      return (
        <div
          className={cn(styles.eventCell, {
            [styles.cellWarning]: pastTime && !completed,
            [styles.cellSuccess]: completed
          })}
        >
          {completed && <CIcon icon={cilCheckCircle} className="text-success" />}
          {pastTime && !completed && <CIcon icon={cilWarning} className="text-danger" />}
        </div>
      );
    } else {
      // schedule.group === monitoring
      const hospitalEvent = event.extendedProps.hospital_event;
      const completed = !!hospitalEvent.value;
      const pastTime = new Date() >= new Date(hospitalEvent.end_time);

      return (
        <div
          className={cn(styles.eventCell, {
            [styles.cellWarning]: pastTime && !completed,
            [styles.cellPlain]: completed
          })}
        >
          {pastTime && !completed && <CIcon icon={cilWarning} className="text-danger" />}
          {hospitalEvent.value}
        </div>
      );
    }
  };

  const [showDischargeModal, setShowDischargeModal] = useState(false);
  const confirmHospitalDischargeModal = () => {
    if (hospitalSheet) {
      const uncompletedEvents = events.filter((event) => event.hospital_event?.completed_at === null);
      return (
        <ConfirmationModal
          isVisible={showDischargeModal}
          isLoading={isDischargeLoading}
          onClose={() => setShowDischargeModal(false)}
          onConfirm={dischargePatient}
          modalBody={`${
            uncompletedEvents.length === 0
              ? 'All tasks are completed.'
              : `${uncompletedEvents.length} tasks are still outstanding.`
          } Once discharged, the hospital sheet will no longer be editable.`}
          confirmButtonLabel={`Yes, discharge`}
          modalHeader={`Discharge ${hospitalSheet.animal.name}?`}
        />
      );
    }
  };

  const [isDischargeLoading, setIsDischargeLoading] = useState(false);
  const dischargePatient = () => {
    if (!hospitalSheet) return;
    setIsDischargeLoading(true);
    updateHospitalSheet(
      hospitalSheet.id,
      {
        ...hospitalSheet,
        discharged_at: new Date().toISOString(),
        discharged_by_employee_id: auth.employee?.id || null
      },
      dischargeSuccess
    );
  };

  const dischargeSuccess = () => {
    setIsDischargeLoading(false);
    setShowDischargeModal(false);
    toast.success('Patient discharged!');
    fetchHospitalSheet(id, handleHospitalFetchSuccess);
  };

  const attachSuccess = (message: string) => {
    toast.success(message);
    fetchHospitalSheet(id, handleHospitalFetchSuccess);
  };

  const resizeSheet = () => {
    setTimeout(() => {
      const calendar = calendarRef.current;
      if (calendar) {
        calendar.getApi().updateSize();
      }
    }, 500);
  };

  return (
    <>
      <CContainer className={cn('mw-100', styles.whiteboard)}>
        {hospitalSheet && consult && selectedDay && (
          <HospitalSheetSignalment
            animal={hospitalSheet.animal}
            hospitalSheet={hospitalSheet}
            day={selectedDay}
            fetchHospitalSheet={() => fetchHospitalSheet(id, handleHospitalFetchSuccess)}
          />
        )}

        <div className="d-flex flex-row align-items-center justify-content-end gap-2">
          {consult?.appointment && (
            <div className="d-flex flex-row align-items-center gap-1">
              Status: <AppointmentStatusSelect appointment={consult.appointment} />
            </div>
          )}

          {hospitalSheet && (
            <Link to={generatePath(paths.appointmentDetails, { id: hospitalSheet.consult.appointment_id })}>
              View Appointment
            </Link>
          )}
        </div>

        <div className="d-flex justify-content-between align-items-start w-100 mb-2" style={{ height: 'max-content' }}>
          <div style={{ display: 'flex', height: 'max-content', gap: '10px' }}>
            {!hospitalSheet?.discharged_at && (
              <CButton onClick={createTreatment} size="sm" className={styles.actionButton}>
                New Treatment
              </CButton>
            )}

            {hospitalSheet && <AttachmentsButton hospitalSheet={hospitalSheet} onSuccess={attachSuccess} />}
          </div>

          <div className="d-flex flex-row align-items-end gap-2">
            {hospitalSheet?.discharged_at ? (
              <div>
                Discharged at {compactDateTimeDisplay(hospitalSheet.discharged_at)}
                {hospitalSheet.discharged_by_employee &&
                  ` by ${hospitalSheet.discharged_by_employee.full_name_with_title}`}
              </div>
            ) : (
              <CLoadingButton
                onClick={() => setShowDischargeModal(true)}
                variant="outline"
                color="danger"
                size="sm"
                loading={isDischargeLoading}
              >
                Discharge
              </CLoadingButton>
            )}
          </div>
        </div>

        <CNav variant="tabs">
          <CNavItem>
            <CNavLink active={activeTab === 'hospital'} onClick={() => setActiveTab('hospital')}>
              Hospital Sheet
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              active={activeTab === 'anesthesia'}
              onClick={() => setActiveTab('anesthesia')}
              className={styles.navLink}
            >
              {!anesthesiaSheetId && <SvgPlus />}
              {!anesthesiaSheetId && 'Add '}Anesthesia Sheet
            </CNavLink>
          </CNavItem>
        </CNav>

        <FullCalendar
          schedulerLicenseKey="0956875066-fcs-1680552874"
          plugins={[interactionPlugin, resourceTimelinePlugin, luxon2Plugin]}
          ref={calendarRef}
          initialView="resourceTimeline"
          selectLongPressDelay={1}
          events={events}
          resources={resources}
          resourceGroupField="groupName"
          titleFormat={{ month: 'long', year: 'numeric', day: 'numeric', weekday: 'long' }}
          nowIndicator={true}
          editable={!hospitalSheet?.discharged_at && activeTab === 'hospital'} // allows dragging
          selectable={!hospitalSheet?.discharged_at}
          headerToolbar={{ left: '', center: '', right: '' }}
          height={'auto'}
          initialDate={selectedDay}
          timeZone={currentClinic?.time_zone}
          slotDuration={activeTab === 'hospital' ? '00:15:00' : '00:05:00'}
          slotMinTime={'08:00:00'}
          slotMaxTime={'19:00:00'}
          slotLabelInterval={activeTab === 'hospital' ? '00:30:00' : '00:05:00'}
          slotLabelFormat={
            activeTab === 'hospital'
              ? { hour: 'numeric', minute: '2-digit' }
              : { hour12: true, hour: 'numeric', minute: '2-digit', meridiem: 'narrow' }
          }
          businessHours={{
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            startTime: '08:00',
            endTime: '19:00'
          }}
          eventBorderColor={'#FFFFFF'}
          eventMinHeight={100}
          eventContent={renderEventCell}
          eventClick={handleEventSelect}
          eventChange={handleRescheduleEvent}
          select={handleCellSelect}
          resourceAreaWidth={activeTab === 'hospital' ? '25%' : '15%'}
          resourceAreaHeaderContent={activeTab === 'hospital' ? 'Tasks' : ''}
          resourceLabelContent={renderResourceCell}
          resourceGroupLabelClassNames={styles.groupRow}
        />
      </CContainer>

      {scheduleToAdd && selectedDay && hospitalSheet && (
        <HospitalScheduleForm
          handleSubmit={handleCreateSchedule}
          handleRemove={handleRemoveSchedule}
          handleCancel={() => setScheduleToAdd(undefined)}
          setHospitalSchedule={setScheduleToAdd}
          selectedDay={selectedDay}
          hospitalSheet={hospitalSheet}
          hospitalSchedule={scheduleToAdd}
        />
      )}

      {eventToAdd && selectedDay && hospitalSheet && (
        <HospitalEventForm
          handleSubmit={handleSubmitEvent}
          handleCancel={() => setEventToAdd(undefined)}
          setHospitalEvent={setEventToAdd}
          selectedDay={selectedDay}
          hospitalSheet={hospitalSheet}
          hospitalEvent={eventToAdd}
        />
      )}

      {confirmHospitalDischargeModal()}

      {activeTime && currentClinic && hospitalSheet && eventsToAdd && (
        <AnesthesiaEventForm
          handleCancel={() => setActiveTime(undefined)}
          handleSubmit={handleSubmitBatchEvents}
          setEvents={setEventsToAdd}
          hospitalEvents={eventsToAdd}
          activeTime={activeTime}
          currentClinic={currentClinic}
          hospitalSheet={hospitalSheet}
        />
      )}
    </>
  );
};

export default HospitalSheetDetails;
