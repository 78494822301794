import { RefObject, useEffect } from 'react';

/**
 * Custom React hook that triggers a callback when a user clicks outside of a specified element (via ref),
 * while allowing certain exceptions where clicks inside specified class names are ignored (e.g., modals, dropdowns).
 *
 * @param {RefObject<HTMLElement | undefined>} ref - The primary element's ref to monitor for outside clicks.
 * @param {() => void} callback - The function to be called when a click outside the ref occurs.
 * @param {string[]} [exceptionClasses=[]] - An optional array of class names. If a click occurs inside an element
 *        with one of these class names (or its ancestors), the callback will not be triggered.
 *
 * Reference: https://coreui.io/blog/how-to-detect-a-click-outside-of-a-react-component/
 */
export const useClickOutside = (
  ref: RefObject<HTMLElement | undefined>,
  callback: () => void,
  exceptionClasses: string[] = []
) => {
  const handleClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    const isClickOutsideRef = ref.current && !ref.current.contains(target);

    const isClickInsideException = exceptionClasses.some((className) => {
      return target.closest(`.${className}`) !== null;
    });

    if (isClickOutsideRef && !isClickInsideException) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
