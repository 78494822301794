import * as React from 'react';
import { Link } from 'react-router-dom';

import { CImage } from '@coreui/react-pro';

import { Animal } from 'types/Animal';

import { generateCloudinaryPath } from 'utils/cloudinary';
import { compactDateDisplay, dateDisplay } from 'utils/dates';

import { ReactComponent as DotIcon } from 'assets/images/dot.svg';

import { Pill } from 'components/Pill';

import styles from './PatientCard.module.scss';

type Props = {
  animal: Animal;
};

export const PatientCard = ({ animal }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {animal.photo_provider_id && (
          <CImage
            fluid
            className={styles.patientImage}
            aria-label={`${animal.name}'s photo`}
            src={generateCloudinaryPath({
              imagePublicId: animal.photo_provider_id
            })}
          />
        )}
        <div className={styles.column}>
          <h3 className={styles.patientName}>
            <Link to={`/animals/${animal.id}`}>{animal.name}</Link>
            {animal.membership?.status && <Pill label={animal.membership.status}></Pill>}
          </h3>
          <div className={styles.row}>
            {animal.species.name}
            {animal.species.name && animal.sex?.name && <DotIcon />}
            {animal.sex?.name}
          </div>
          <div className={styles.row}>{animal.breed?.name}</div>
        </div>
      </div>

      <div className={styles.dateSection}>
        <div className={styles.row}>
          <div className={styles.left}>Birthdate</div>
          <div className={styles.right}>{animal.date_of_birth && dateDisplay(animal.date_of_birth)}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.left}>Created On</div>
          <div className={styles.right}>{animal.created_at && dateDisplay(animal.created_at)}</div>
        </div>
      </div>

      <div className={styles.appointmentsSection}>
        <div className={styles.row}>
          <div className={styles.left}>Next Appointment</div>
          <div className={styles.right}>
            {animal.next_appointment_json?.start_time ? (
              <Link to={`/appointments/${animal.next_appointment_json.id}`}>
                {compactDateDisplay(animal.next_appointment_json.start_time)}
              </Link>
            ) : (
              'None'
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.left}>Last Appointment</div>
          <div className={styles.right}>
            {animal.last_appointment_json?.start_time ? (
              <Link to={`/appointments/${animal.last_appointment_json.id}`}>
                {compactDateDisplay(animal.last_appointment_json.start_time)}
              </Link>
            ) : (
              'None'
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
