import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  CAlert,
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CLoadingButton,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchAnimals } from 'api/Animals';

import { AnimalListItem } from 'types/Animal';

const List = (): JSX.Element => {
  const [animalList, setAnimalList] = React.useState<AnimalListItem[]>();
  const [searchText, setSearchText] = React.useState<string>('');
  const [infoMessage, setInfoMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const setSearchResultMessage = useCallback((patientsCount: number) => {
    if (patientsCount > 0) {
      setInfoMessage(`${patientsCount} patient${patientsCount > 1 ? 's' : ''} found.`);
    } else {
      setInfoMessage('No patients found.');
    }
  }, []);

  const updateAnimalList = useCallback(
    (animals: AnimalListItem[]) => {
      setIsLoading(false);
      setSearchResultMessage(animals.length);
      setAnimalList(animals);
    },
    [setSearchResultMessage]
  );

  const fetchAnimalList = useCallback(() => {
    setInfoMessage('');
    setIsLoading(true);
    fetchAnimals(searchText, updateAnimalList);
  }, [searchText, updateAnimalList]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetchAnimalList();
  };

  return (
    <CRow>
      <CCol xs={12}>
        {infoMessage && (
          <CAlert color="info" dismissible>
            {infoMessage}
          </CAlert>
        )}
        <CCard className="mb-4">
          <CCardHeader>
            <CForm name="patientSearchForm" className="row g-3" onSubmit={handleSubmit}>
              <CCol xs={8}>
                <CFormInput
                  type="text"
                  id="searchInput"
                  label="Search Patients"
                  placeholder="Search by name"
                  onChange={(event) => setSearchText(event.target.value)}
                />
              </CCol>
              <CCol className="align-self-end" xs={4}>
                <CLoadingButton color="primary" type="submit" loading={isLoading} disabled={!searchText}>
                  Search
                </CLoadingButton>
              </CCol>
            </CForm>
          </CCardHeader>
          {animalList && animalList.length > 0 && (
            <CCardBody role="listitem">
              <CTable>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>Name</CTableHeaderCell>
                    <CTableHeaderCell>Species</CTableHeaderCell>
                    <CTableHeaderCell>Sex</CTableHeaderCell>
                    <CTableHeaderCell>Breed</CTableHeaderCell>
                    <CTableHeaderCell>Owner</CTableHeaderCell>
                    <CTableHeaderCell>Clinic</CTableHeaderCell>
                    <CTableHeaderCell>Membership Plan</CTableHeaderCell>
                    <CTableHeaderCell>Membership Status</CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {animalList.map((animal) => (
                    <CTableRow key={animal.id}>
                      <CTableDataCell>
                        <Link to={`/animals/${animal.id}`}>{animal.name}</Link>

                        {animal.archived_at && (
                          <CBadge className="ms-2" color="secondary" shape="rounded-pill">
                            Archived
                          </CBadge>
                        )}
                      </CTableDataCell>
                      <CTableDataCell>{animal.species && animal.species.name}</CTableDataCell>
                      <CTableDataCell style={{ textTransform: 'capitalize' }}>{animal.sex?.name}</CTableDataCell>
                      <CTableDataCell>{animal.breed && animal.breed.name}</CTableDataCell>
                      <CTableDataCell>
                        <Link to={`/customers/${animal.customer.id}`}>
                          {animal.customer.first_name} {animal.customer.last_name}
                        </Link>
                      </CTableDataCell>
                      <CTableDataCell>{animal.customer?.clinic?.name}</CTableDataCell>
                      <CTableDataCell>{animal.membership?.plan?.name}</CTableDataCell>
                      <CTableDataCell>
                        {animal?.membership && (
                          <CBadge
                            className="text-capitalize"
                            color={animal.membership.status === 'active' ? 'success' : 'secondary'}
                          >
                            {animal.membership.status}
                          </CBadge>
                        )}
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CCardBody>
          )}
        </CCard>
      </CCol>
    </CRow>
  );
};

export default List;
