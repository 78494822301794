import React, { createContext, useState } from 'react';

import { getCookieCurrentClinic } from 'utils/sessionManager';

type ClinicContextType = {
  clinicContext: string | undefined;
  setClinicContext: (clinic: string | undefined) => void;
};

export const ClinicContext = createContext<ClinicContextType>({
  clinicContext: getCookieCurrentClinic(),
  setClinicContext: () => {
    // do nothing
  }
});

export const ClinicProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [clinicContext, setClinicContext] = useState<string | undefined>();

  return <ClinicContext.Provider value={{ clinicContext, setClinicContext }}>{children}</ClinicContext.Provider>;
};
