import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CAlert, CContainer } from '@coreui/react-pro';

import { updateEmployee, updateEmployeeWithoutRoles } from 'api/Employees';
import { createPimResource, deletePimResource } from 'api/PimResources';

import { Employee, isOrganizationAdmin } from 'types/Employee';
import { PimResource } from 'types/PimResource';

import { useAuth } from 'hooks/useAuth';

import Form from './Form';

const EmployeeEdit = (): JSX.Element => {
  type EmployeeEditParams = {
    id: string;
  };
  const { id } = useParams<keyof EmployeeEditParams>() as EmployeeEditParams;
  const auth = useAuth();
  const navigate = useNavigate();

  const [authorized, setAuthorized] = React.useState(false);

  const [deletedPimResources, setDeletedPimResources] = React.useState<Partial<PimResource>[]>([]);
  const [createdPimResources, setCreatedPimResources] = React.useState<Partial<PimResource>[]>([]);

  const updateDeletedPimResources = (pimResource: Partial<PimResource>) => {
    setDeletedPimResources([...deletedPimResources, pimResource]);
    setCreatedPimResources(createdPimResources.filter((resource) => resource.clinic_id !== pimResource.clinic_id));
  };

  const updateCreatedPimResources = (pimResource: Partial<PimResource>) => {
    setCreatedPimResources([...createdPimResources, pimResource]);
    setDeletedPimResources(deletedPimResources.filter((resource) => resource.clinic_id !== pimResource.clinic_id));
  };

  useEffect(() => {
    if (id && auth.employee) {
      if (auth.employee.id.toString() === id || isOrganizationAdmin(auth.employee)) {
        setAuthorized(true);
      }
    }
  }, [id, auth.employee]);

  const updatePimResources = async () => {
    const promises = createdPimResources.map((pimResource) => {
      return new Promise((resolve) => {
        if (pimResource.clinic_id) {
          createPimResource(pimResource.clinic_id, id, (data) => {
            resolve(data);
          });
        }
      });
    });

    await Promise.all(
      promises.concat(
        deletedPimResources.map((pimResource) => {
          return new Promise((resolve) => {
            deletePimResource(pimResource, (data) => {
              resolve(data);
            });
          });
        })
      )
    );

    toast.success('Employee updated!');
    navigate(`/employees/${id}`);
    return true;
  };

  const handleSubmit = async (employee: Employee) => {
    const isOrgAdmin = auth.employee && isOrganizationAdmin(auth.employee);
    if (isOrgAdmin) {
      return await updateEmployee(id, employee).then(updatePimResources).catch(updateError);
    } else {
      return await updateEmployeeWithoutRoles(id, employee).then(updatePimResources).catch(updateError);
    }
  };

  const updateError = (error: Error) => {
    return true;
  };

  const handleCancel = () => {
    navigate(`/employees/${id}`);
  };

  return (
    <CContainer fluid>
      {authorized ? (
        <>
          <h1 className="mb-4">Edit Employee</h1>
          <Form
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            employeeId={id}
            updateDeletedPimResources={updateDeletedPimResources}
            updateCreatedPimResources={updateCreatedPimResources}
          />
        </>
      ) : (
        <CAlert color={'warning'}>You are not authorized to edit this employee.</CAlert>
      )}
    </CContainer>
  );
};

export default EmployeeEdit;
