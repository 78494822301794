import cn from 'classnames';
import { formatDistance } from 'date-fns';
import * as React from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { CommunicationLog } from 'types/CommunicationLog';

import { compactDateTimeDisplay } from 'utils/dates';
import { pluralize } from 'utils/strings';

import { ReactComponent as ClipboardIcon } from 'assets/images/clipboard.svg';
import { ReactComponent as IncomingUnderlinedIcon } from 'assets/images/incoming-underlined.svg';
import { ReactComponent as LetterboxIcon } from 'assets/images/letterbox.svg';
import { ReactComponent as OutgoingUnderlinedIcon } from 'assets/images/outgoing-underlined.svg';
import { ReactComponent as PawIcon } from 'assets/images/paw.svg';

import styles from './CommunicationLogItem.module.scss';

type Props = {
  communicationLog: CommunicationLog;
};

export const CommunicationLogItem = ({ communicationLog }: Props) => {
  const contextLink = (communicationLog: CommunicationLog) => {
    const { context_id, context_display_name, context_type, consult_id } = communicationLog;
    if (!context_type) return null;

    const to =
      {
        AnimalHealthPlan: `/consults/${consult_id}?step=health-plans`,
        AnimalMasterProblem: `/consults/${consult_id}?step=master-problems`,
        Assessment: `/consults/${consult_id}?step=assessments`,
        Appointment: `/appointments/${context_id}`,
        Consult: `/consults/${context_id}`,
        ConsultPlan: `/consults/${consult_id}?step=consult-plans`,
        ConsultPresentingProblem: `/consults/${consult_id}?step=presenting-problems`,
        DiagnosticRequest: `/consults/${consult_id}?step=diagnostics`,
        DiagnosticResult: `/consults/${consult_id}?step=diagnostics`,
        Estimate: `/estimates/${context_id}`,
        HealthStatus: `/consults/${consult_id}?step=health-statuses`,
        History: `/consults/${consult_id}?step=history`,
        Invoice: `/invoices/${context_id}`,
        PhysicalExam: `/consults/${consult_id}?step=physical-exams`,
        Prescription: `/consults/${consult_id}?step=medications`,
        PrescriptionItem: `/consults/${consult_id}?step=medications`,
        Procedure: `/consults/${consult_id}?step=procedures`,
        Vaccination: `/consults/${consult_id}?step=vaccinations`
      }[context_type] || '/';

    return <Link to={to}>{context_display_name}</Link>;
  };

  return (
    <div className={styles.wrapper}>
      <div className={cn('d-flex align-item-center justify-content-between', styles.dates)}>
        <div className={styles.date}>{compactDateTimeDisplay(communicationLog.sent_at)}</div>
        <div className={styles.dateInWords}>
          {formatDistance(new Date(communicationLog.sent_at), new Date(), { addSuffix: true })}
        </div>
      </div>

      <div className={cn('d-flex', styles.communicationWrapper)}>
        <div className={cn('d-flex flex-column', styles.column, styles.communicationContext)}>
          <div className={cn('d-flex align-items-center justify-content-between flex-wrap', styles.toFrom)}>
            <div className={cn('d-flex pb-3')}>
              <div className={styles.label}>
                <IncomingUnderlinedIcon className={styles.icon} />
                <div>From:</div>
              </div>
              <Link
                to={`/employees/${communicationLog.created_by_employee.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {communicationLog.created_by_employee.full_name_with_title}
              </Link>
            </div>

            <div className="d-flex pb-3">
              <div className={styles.label}>
                <OutgoingUnderlinedIcon className={styles.icon} />
                <div>To:</div>
              </div>
              <Link to={`/customers/${communicationLog.customer.id}`} target="_blank" rel="noopener noreferrer">
                {communicationLog.customer.first_name} {communicationLog.customer.last_name}
              </Link>
            </div>
          </div>

          <div className={cn('d-flex', styles.contextItem)}>
            <div className={styles.label}>
              <PawIcon className={styles.icon} />
              {pluralize('Animal', communicationLog.animals.length)}:
            </div>
            {communicationLog.animals.map((animal, index) => (
              <Fragment key={animal.id}>
                {index > 0 && ', '}
                <Link to={`/animals/${animal.id}`}>{animal.name}</Link>
              </Fragment>
            ))}
          </div>

          <div className={cn('d-flex', styles.contextItem)}>
            <div className={styles.label}>
              <LetterboxIcon className={styles.icon} />
              <div>Topic:</div>
            </div>
            <div className={styles.value}>{communicationLog.topic}</div>
          </div>

          {communicationLog.context_id && (
            <div className={cn('d-flex', styles.contextItem)}>
              <div className={styles.label}>
                <ClipboardIcon className={styles.icon} />
                <div>Context:</div>
              </div>
              <div>{contextLink(communicationLog)}</div>
            </div>
          )}
        </div>

        <div className={cn(styles.column, styles.communication)}>
          <div className={cn('text-capitalize', styles.communicationHeader)}>
            {communicationLog.communication_type.replace('_', '-')}
          </div>
          <div>{communicationLog.body}</div>
        </div>
      </div>
    </div>
  );
};
