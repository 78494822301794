import * as React from 'react';
import type { SVGProps } from 'react';

const SvgNose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 14" {...props}>
    <path
      fill="currentColor"
      d="M17.473 7.95a.527.527 0 0 0-.528.527v.65c0 .99-.386 1.922-1.086 2.623-.7.7-1.632 1.086-2.623 1.086a3.713 3.713 0 0 1-3.709-3.709V7.145a1.2 1.2 0 0 0 .653-.805l.195-.783a.615.615 0 0 1 1.155-.113l.24.509c.263.561.834.891 1.453.841a1.455 1.455 0 0 0 1.292-1.058c.325-1.135.043-2.444-.754-3.5A5.1 5.1 0 0 0 11.86.71c-.843-.4-1.805-.602-2.86-.602-1.06 0-2.023.202-2.865.6-.763.36-1.42.887-1.899 1.522C3.441 3.288 3.16 4.6 3.486 5.738c.17.592.677 1.006 1.292 1.056.62.05 1.19-.28 1.454-.841l.239-.509a.615.615 0 0 1 1.155.113l.195.783a1.2 1.2 0 0 0 .652.805v1.982a3.713 3.713 0 0 1-3.71 3.709c-.99 0-1.921-.386-2.622-1.086a3.69 3.69 0 0 1-1.086-2.623v-.65a.527.527 0 0 0-1.055 0v.65c0 1.272.495 2.468 1.395 3.369.9.9 2.097 1.395 3.369 1.395A4.77 4.77 0 0 0 9 11.304a4.77 4.77 0 0 0 4.236 2.587 4.73 4.73 0 0 0 3.369-1.396c.9-.9 1.395-2.096 1.395-3.368v-.65a.527.527 0 0 0-.527-.528M7.028 4.035c-.644 0-1.237.376-1.511.96l-.24.508a.41.41 0 0 1-.414.239.4.4 0 0 1-.363-.296c-.236-.822-.019-1.787.58-2.58C5.558 2.23 6.704 1.163 9 1.163c2.29 0 3.438 1.07 3.919 1.707.598.794.816 1.757.582 2.575a.4.4 0 0 1-.363.297.41.41 0 0 1-.415-.24l-.239-.508a1.68 1.68 0 0 0-1.512-.96c-.768 0-1.434.522-1.62 1.267l-.196.783c-.026.155-.285.155-.312 0l-.195-.783a1.67 1.67 0 0 0-1.62-1.266"
    />
  </svg>
);
export default SvgNose;
