import axios from 'axios';

import { HealthStatus } from 'types/HealthStatus';

const fetchHealthStatusesByAnimal = (animalId: string, onSuccess: (healthStatus: HealthStatus[]) => void) => {
  const url = `/pim/health_statuses?animal_id=${animalId}`;
  axios.get<HealthStatus[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchHealthStatusesForConsult = (
  consultId: number | string,
  onSuccess: (healthStatuses: HealthStatus[]) => void
) => {
  const url = `/pim/health_statuses`;
  axios.get<HealthStatus[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const createHealthStatus = (
  params: Partial<HealthStatus>,
  { onSuccess, onError }: { onSuccess: (status: HealthStatus) => void; onError: (error: string) => void }
) => {
  const url = `/pim/health_statuses`;
  axios
    .post<HealthStatus>(url, { health_status: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError(error.message));
};

const updateHealthStatus = (
  healthStatusId: number,
  params: Partial<HealthStatus>,
  { onSuccess, onError }: { onSuccess: (status: HealthStatus) => void; onError: (error: string) => void }
) => {
  const url = `/pim/health_statuses/${healthStatusId}`;
  axios
    .put<HealthStatus>(url, { health_status: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError(error.message));
};

export { fetchHealthStatusesByAnimal, fetchHealthStatusesForConsult, createHealthStatus, updateHealthStatus };
