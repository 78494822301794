import axios, { AxiosResponse } from 'axios';

export type RecordsType = 'vaccinations' | 'diagnostics' | '';

export const downloadSoapRecords = (animalId: number, recordsType: RecordsType = '') => {
  const url = `/pim/clinical_records/full_soap?animal_id=${animalId}&type=${recordsType}`;
  axios.get(url, { responseType: 'blob' }).then((response: AxiosResponse) => {
    const regExpFilename = /filename="(?<filename>.*)"/;
    const filename: string | null =
      regExpFilename.exec(response.headers['content-disposition'])?.groups?.filename ?? null;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.download = filename ?? 'soap_records.pdf';
    link.type = response.headers['content-type'];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
