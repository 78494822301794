import * as React from 'react';

import { CModal, CModalBody, CModalHeader } from '@coreui/react-pro';

import { AttachmentType } from 'types/AttachmentType';

import { AttachmentForm } from './AttachmentForm';

type Props = {
  attachmentTypes: AttachmentType[];
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  hideModal: () => void;
  loading: boolean;
};

export const NewAttachmentModal = ({ hideModal, ...rest }: Props) => {
  return (
    <CModal backdrop="static" className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
      <CModalHeader>
        <h4>Upload Attachment</h4>
      </CModalHeader>
      <CModalBody>
        <AttachmentForm hideModal={hideModal} {...rest} />
      </CModalBody>
    </CModal>
  );
};
