import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CCard, CCardBody, CCardHeader, CSpinner } from '@coreui/react-pro';

import { fetchAppointmentType, updateAppointmentType } from 'api/AppointmentTypes';

import { AppointmentType } from 'types/AppointmentType';

import Form from './Form';

const Edit = (): JSX.Element => {
  type EditParams = {
    id: string;
  };
  const { id } = useParams<keyof EditParams>() as EditParams;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appointmentType, setAppointmentType] = useState<AppointmentType>({} as AppointmentType);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.type === 'checkbox') {
      setAppointmentType({
        ...appointmentType,
        [event.target.id]: 'checked' in event.target ? event.target.checked : false
      });
    } else {
      setAppointmentType({ ...appointmentType, [event.target.id]: event.target.value });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, errorCallback: (error: string) => void) => {
    event.preventDefault();
    updateAppointmentType(
      id,
      { ...appointmentType, deposit_amount: appointmentType.deposit_amount * 100 },
      updateSuccess,
      errorCallback
    );
  };

  const setColor = (color: string) => {
    setAppointmentType({ ...appointmentType, color: color });
  };

  function handleAppointmentLoaded(appointmentType: AppointmentType) {
    setAppointmentType({ ...appointmentType, deposit_amount: appointmentType.deposit_amount / 100 });
    setIsLoading(false);
  }

  useEffect(() => {
    fetchAppointmentType(id, handleAppointmentLoaded);
  }, [id]);

  const updateSuccess = (appointmentType: AppointmentType) => {
    navigate(`/appointments/types/${appointmentType.id}`);
  };

  const handleCancel = () => {
    navigate(`/appointments/types/${id}`);
  };

  const loadingState = () => {
    return <CSpinner color="primary" />;
  };

  function loadedState() {
    return (
      <CCard style={{ width: '50rem' }}>
        <CCardHeader>
          <h3>Edit Appointment Type</h3>
        </CCardHeader>
        <CCardBody>
          <Form
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleChange={handleChange}
            appointmentType={appointmentType}
            setColor={setColor}
          />
        </CCardBody>
      </CCard>
    );
  }

  return isLoading ? loadingState() : loadedState();
};

export default Edit;
