import axios from 'axios';

import { DispenseRecord } from 'types/DispenseRecord';
import { Pagination } from 'types/Pagination';

const fetchDispenseRecord = (
  dispenseRecordId: number,
  { onSuccess, onError }: { onSuccess: (dispenseRecord: DispenseRecord) => void; onError?: (error: string) => void }
) => {
  const url = `/pim/dispense_records/${dispenseRecordId}`;

  axios
    .get<DispenseRecord>(url)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError?.(error.response.data.error));
};

const fetchDispenseRecords = (
  params: { status: string; fulfilled_at?: Date | null },
  {
    onSuccess,
    page,
    setPagination
  }: {
    onSuccess: (dispenseRecords: DispenseRecord[]) => void;
    page?: number;
    setPagination: (pagination: Pagination) => void;
  }
) => {
  const url = '/pim/dispense_records';
  axios
    .get<DispenseRecord[]>(url, {
      params: {
        ...params,
        page: page || 1
      }
    })
    .then((data) => {
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
      onSuccess(data.data.record);
    });
};

const updateDispenseRecord = (
  dispenseRecordId: number,
  dispense_record: Partial<DispenseRecord>,
  { onSuccess, onError }: { onSuccess: (dispense_record: DispenseRecord) => void; onError: (error: string) => void }
) => {
  const url = `/pim/dispense_records/${dispenseRecordId}`;

  axios
    .put<DispenseRecord>(url, { dispense_record })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError(error.response.data.error));
};

export { fetchDispenseRecord, fetchDispenseRecords, updateDispenseRecord };
