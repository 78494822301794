import { Cloudinary } from '@cloudinary/url-gen';
import { thumbnail } from '@cloudinary/url-gen/actions/resize';
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { FocusOn } from '@cloudinary/url-gen/qualifiers/focusOn';
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity';

interface GenerateCloudinaryPathParams {
  imagePublicId: string;
  borderSize?: number;
  width?: number;
  height?: number;
}

export const generateCloudinaryPath = ({
  imagePublicId,
  borderSize = 0,
  width = 1000,
  height = 1000
}: GenerateCloudinaryPathParams) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'drtreat-com'
    },
    url: {
      secure: true
    }
  });
  const imageCld = cld.image(imagePublicId);
  const URL = imageCld
    .resize(thumbnail().width(width).height(height).gravity(focusOn(FocusOn.face())))
    .roundCorners(byRadius(borderSize))
    .toURL();
  return URL;
};

interface UploadFileToCloudinaryParams {
  file: File | string;
  uploadPreset: string;
  cloudName: string;
}

export async function uploadFileToCloudinary({ file, uploadPreset, cloudName }: UploadFileToCloudinaryParams) {
  const data = new FormData();
  data.append('file', file);
  data.append('upload_preset', uploadPreset);

  const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, {
    method: 'post',
    body: data
  });

  return response.json();
}

export const uploadAnimalPhoto = async (file: File) => {
  const data = new FormData();
  data.append('file', file);
  data.append('upload_preset', 'pet_photo');

  const response = await fetch(`https://api.cloudinary.com/v1_1/drtreat-com/upload`, {
    method: 'post',
    body: data
  });

  return response.json();
};
