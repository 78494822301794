import {
  DiscoverResult,
  ErrorResponse,
  ISdkManagedPaymentIntent,
  loadStripeTerminal,
  Reader,
  Terminal
} from '@stripe/terminal-js';
import { toast } from 'react-toastify';

import { fetchInvoice } from 'api/Invoices';
import { createPaymentIntent, getConnectionToken, recordPayment } from 'api/StripePayments';

import { Invoice } from 'types/Invoice';
import { Payment } from 'types/Payment';

/* 
  This class follows the Stripe Terminal example for collecting physical payments.
  Find the docs here: https://stripe.com/docs/terminal/quickstart?platform=web
  Reader: WisePOS E, Architecture: JavaScript SDK, Frontend: JavaScript, Backend: Ruby

  The process is designed so that it can be reused; simply create a new instance of PhysicalPayments, 
  and then call beginPhysicalCardPayment() to start the process. 
  - `emitTerminalStatus` is called to update the UI with the current status of the process
  - `paymentFailed` is called if the process fails at any point; it ends the process prematurely and allows the user to resubmit
  - `paymentSuccessful` is called when the process completes successfully
*/
export class PhysicalPayments {
  paymentAmount: number;
  invoice: Invoice;
  emitTerminalStatus: (status: string) => void;
  paymentFailed: (status: string) => void;
  paymentSuccessful: (invoice: Invoice) => void;

  terminal: Terminal | undefined;

  currentStatus: 'initial' | 'collecting-payment' | 'canceled' = 'initial';

  constructor(
    paymentAmount: number,
    invoice: Invoice,
    emitTerminalStatus: (status: string) => void,
    paymentFailed: (status: string) => void,
    paymentSuccessful: (invoice: Invoice) => void
  ) {
    this.paymentAmount = paymentAmount;
    this.invoice = invoice;
    this.emitTerminalStatus = emitTerminalStatus;
    this.paymentFailed = paymentFailed;
    this.paymentSuccessful = paymentSuccessful;
  }

  cancelPaymentCollection() {
    if (this.terminal) {
      this.terminal.clearReaderDisplay();
      if (this.currentStatus === 'collecting-payment') this.terminal.cancelCollectPaymentMethod();
      this.emitTerminalStatus('Canceling payment collection');
      this.currentStatus = 'canceled';
    }
  }

  beginPhysicalCardPayment() {
    this.emitTerminalStatus('Connecting to Stripe Terminal...');
    this.currentStatus = 'initial';

    loadStripeTerminal()
      .then((StripeTerminal) => {
        if (!StripeTerminal) {
          this.paymentFailed('Failed to load Stripe Terminal');
          return;
        }
        this.terminal = StripeTerminal.create({
          onFetchConnectionToken: getConnectionToken,
          onUnexpectedReaderDisconnect: () =>
            this.paymentFailed('Unexpected disconnect from Stripe Terminal. Please try again.')
        });

        this.discoverReaders();
      })
      .catch((error) => {
        this.paymentFailed(`Failed to load Stripe Terminal: ${error.message}`);
      });
  }

  discoverReaders() {
    if (this.currentStatus === 'canceled') return;
    this.emitTerminalStatus('Discovering readers...');

    const config = { simulated: process.env.NODE_ENV === 'development' };
    this.terminal
      ?.discoverReaders(config)
      .then((discoverResult) => {
        if ((discoverResult as ErrorResponse).error) {
          this.paymentFailed('Failed to discover readers: ' + (discoverResult as ErrorResponse).error);
        } else if ((discoverResult as DiscoverResult).discoveredReaders.length === 0) {
          this.paymentFailed('No available readers. Please make sure the reader is plugged in and try again.');
        } else {
          const discoveredReaders = (discoverResult as DiscoverResult).discoveredReaders;
          this.connectReader(discoveredReaders[0]);
        }
      })
      .catch((error) => {
        this.paymentFailed(`Failed to discover readers: ${error.message}`);
      });
  }

  connectReader(discoveredReader: Reader) {
    if (this.currentStatus === 'canceled') return;
    this.emitTerminalStatus('Connecting to reader...');

    this.terminal
      ?.connectReader(discoveredReader)
      .then((connectResult) => {
        if ((connectResult as ErrorResponse).error) {
          this.paymentFailed('Failed to connect: ' + (connectResult as ErrorResponse).error);
        } else if ('reader' in connectResult) {
          this.emitTerminalStatus('Connected to reader: ' + connectResult.reader.label);
          this.beginPaymentFlow();
        } else {
          this.paymentFailed('Unexpected connect result: ' + JSON.stringify(connectResult));
        }
      })
      .catch((error) => {
        this.paymentFailed(`Failed to connect: ${error.message}`);
      });
  }

  beginPaymentFlow() {
    if (this.currentStatus === 'canceled') return;
    this.emitTerminalStatus('Creating payment intent...');

    if (this.invoice.id)
      createPaymentIntent({
        customer_id: this.invoice.customer_id,
        invoice_id: this.invoice.id,
        amount: this.paymentAmount
      })
        .then((intent) => {
          this.collectPaymentMethod(intent.client_secret);
        })
        .catch((error) => {
          this.paymentFailed(`Failed to create payment intent: ${error.message}`);
        });
  }

  collectPaymentMethod(clientSecret: string) {
    if (this.currentStatus === 'canceled') return;
    this.emitTerminalStatus('Waiting for payment method...');
    toast.info('Enter payment card in reader now.');

    // When connected to a physical reader, the connected reader waits for a card to be presented
    this.terminal?.setSimulatorConfiguration({ testCardNumber: '4242424242424242' });
    this.terminal
      ?.collectPaymentMethod(clientSecret)
      .then((result) => {
        this.currentStatus = 'collecting-payment';
        if ((result as ErrorResponse).error) {
          this.paymentFailed('Failed to collect payment method: ' + (result as ErrorResponse).error);
        } else if ('paymentIntent' in result) {
          this.processPayment(result.paymentIntent);
        } else {
          this.paymentFailed('Unexpected collect payment method result: ' + JSON.stringify(result));
        }
      })
      .catch((error) => {
        this.paymentFailed(`Failed to collect payment method: ${error.message}`);
      });
  }

  processPayment(paymentIntent: ISdkManagedPaymentIntent) {
    if (this.currentStatus === 'canceled') return;
    this.emitTerminalStatus('Processing payment...');

    this.terminal
      ?.processPayment(paymentIntent)
      .then((result) => {
        if ((result as ErrorResponse).error) {
          this.paymentFailed('Error processing payment: ' + (result as ErrorResponse).error);
        } else if ('paymentIntent' in result) {
          const paymentIntentId = result.paymentIntent.id;
          if (result.paymentIntent.status === 'succeeded') {
            this.logPayment(paymentIntentId);
          }
        }
      })
      .catch((error) => {
        this.paymentFailed(`Error processing payment: ${error.message}`);
      });
  }

  logPayment(paymentIntentId: string) {
    if (this.invoice.id)
      recordPayment({
        payment_intent_id: paymentIntentId,
        invoice_id: this.invoice.id,
        amount: this.paymentAmount,
        customer_id: this.invoice.customer_id
      })
        .then((result) => {
          this.emitTerminalStatus('Payment successful!');
          if (this.invoice.id) fetchInvoice(this.invoice.id.toString(), this.paymentSuccessful);
        })
        .catch((error) => {
          this.paymentFailed(`Error logging payment: ${error.message}`);
        });
  }
}

export const generatePaymentLink = (payment: Payment) => {
  let baseUrl = 'https://dashboard.stripe.com/';
  if (process.env.NODE_ENV === 'development') baseUrl += 'test/';
  return new URL(`payments/${payment.payment_provider_id}`, baseUrl).toString();
};
