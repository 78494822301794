import axios from 'axios';

import { CalendarEvent } from '../types/CalendarEvent';

const fetchEvents = (
  startTime: Date,
  endTime: Date,
  clinicId: number,
  onSuccess: (events: CalendarEvent[]) => void
) => {
  const url = '/pim/events';
  const params = { start_time: startTime, end_time: endTime, clinic_id: clinicId };
  axios.get<CalendarEvent[]>(url, { params }).then((data) => onSuccess(data.data.record));
};

export { fetchEvents };
