import cn from 'classnames';
import * as React from 'react';

import { Assessment } from 'types/Assessment';
import { ConsultPlan } from 'types/ConsultPlan';
import { ConsultPresentingProblem } from 'types/ConsultPresentingProblem';
import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { DiagnosticResult } from 'types/DiagnosticResult';
import { EmployeeTask } from 'types/EmployeeTask';
import { Estimate } from 'types/Estimate';
import { ForwardBooking } from 'types/ForwardBooking';
import { HealthStatus } from 'types/HealthStatus';
import { History } from 'types/History';
import { Invoice } from 'types/Invoice';
import { PhysicalExam } from 'types/PhysicalExam';
import { Prescription } from 'types/Prescription';
import { Procedure } from 'types/Procedure';
import { Vaccination } from 'types/Vaccination';

import { compactDateTimeDisplay } from 'utils/dates';

import { ReactComponent as DotIcon } from 'assets/images/dot.svg';

import styles from './FormAuditData.module.scss';

type Props = {
  item:
    | Assessment
    | ConsultPlan
    | ConsultPresentingProblem
    | DiagnosticRequest
    | DiagnosticResult
    | EmployeeTask
    | ForwardBooking
    | HealthStatus
    | History
    | Partial<Estimate>
    | Partial<Invoice>
    | PhysicalExam
    | Prescription
    | Procedure
    | Vaccination;
};

export const FormAuditData = ({ item }: Props) => {
  const metaData = [
    {
      label: 'ID',
      value: item.id
    },
    {
      label: 'Created',
      value: item.created_at && compactDateTimeDisplay(item.created_at)
    }
  ].filter((obj) => obj.value);

  // We have to check this, because the invoice and estimate forms use partial types in their forms, so `item` could be
  // defined but have no values we want to display.
  if (metaData.length === 0) return null;

  return (
    <div className={cn('d-flex align-items-center gap-3', styles.container)}>
      {metaData.map(({ label, value }, index) => (
        <React.Fragment key={label}>
          {index > 0 && <DotIcon />}
          <div>
            {label}: <span className="fw-semibold">{value}</span>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
