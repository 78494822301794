import { Editor } from '@tinymce/tinymce-react';
import cn from 'classnames';
import { tinyMCEApiKey } from 'parsedEnv';
import * as React from 'react';
import { useState } from 'react';

import SvgPencil from 'assets/images/SvgPencil';

import { IconLabel } from 'components/IconLabel';

import styles from './RichTextEditor.module.scss';

type Props = {
  id: string;
  label?: string;
  name: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  text?: string;
  value?: string;
  required?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  editorType?: EditorType;
  autoFocus?: boolean;
};

type EditorType = 'rich-text' | 'document';

export const RichTextEditor = ({
  autoFocus,
  placeholder,
  disabled = false,
  text,
  name,
  onChange,
  value,
  id,
  label,
  hidden = false,
  required = false,
  editorType = 'rich-text'
}: Props) => {
  const [contentInternal, setContentInternal] = useState(value);

  const handleHiddenTextareaChange = () => {
    // noop
  };

  const plugins = () => {
    let result = ['link', 'lists', 'autoresize', 'tinymcespellchecker'];

    if (editorType === 'document') {
      result = [...result, 'table', 'mergetags'];
    }

    return result;
  };

  const toolbar = () => {
    let result = ['undo redo', 'bold italic underline strikethrough', 'link', 'numlist bullist', 'spellchecker'];

    if (editorType === 'document') {
      result = [...result, 'mergetags', 'alignleft aligncenter alignright', 'outdent indent', 'hr', 'table', 'styles'];
    }

    return result;
  };

  // HiddenTextArea lets us use the `required` attribute.
  //
  // When RichTextEditor is hidden, this input's `required` attribute must be false because the user can't see or
  // resolve such a validation.
  const HiddenTextarea = () => (
    <textarea
      onChange={handleHiddenTextareaChange}
      style={{ opacity: 0, height: 0 }}
      id={id}
      name={name}
      value={contentInternal}
      required={required && !hidden}
    />
  );

  return (
    <div className={cn({ 'd-none': hidden })}>
      {(label || text) && (
        <div className="d-flex align-items-center justify-content-between form-label">
          {label && (
            <label htmlFor={id}>
              <IconLabel icon={SvgPencil} label={label} />
            </label>
          )}
          {text && <div className={cn('ms-auto', styles.text)}>{text}</div>}
        </div>
      )}

      <Editor
        apiKey={tinyMCEApiKey}
        init={{
          plugins: plugins().join(' '),
          toolbar: toolbar().join(' | '),
          height: 150,
          menubar: false,
          placeholder: placeholder,
          statusbar: false,
          auto_focus: autoFocus ? true : undefined,
          branding: false,
          spellchecker_languages: 'Medical English (US)=en_US-medical,English (United States)=en_US'
        }}
        disabled={disabled}
        value={value}
        onEditorChange={(content, editor) => {
          setContentInternal(content);
          onChange?.(content);
        }}
      />

      <HiddenTextarea />
    </div>
  );
};
