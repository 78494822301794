import axios from 'axios';

import { Room } from 'types/Room';

const fetchRooms = (onSuccess: (rooms: Room[]) => void) => {
  const url = `/pim/rooms`;
  axios.get<Room[]>(url).then((data) => onSuccess(data.data.record));
};

export { fetchRooms };
