import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  CAlert,
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CLoadingButton,
  CRow,
  CSmartTable
} from '@coreui/react-pro';

import { MasterProblem } from 'types/MasterProblem';
import { calculatePages, Pagination } from 'types/Pagination';

import { compactDateTimeDisplay } from 'utils/dates';

import { fetchMasterProblems } from '../../api/MasterProblems';

const List = (): JSX.Element => {
  const [masterProblemsList, setMasterProblemsList] = React.useState<MasterProblem[]>();
  const [searchText, setSearchText] = React.useState<string>('');
  const [infoMessage, setInfoMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<Pagination>({ page: 1, perPage: 25, total: 1 });

  const setSearchResultMessage = useCallback((problemCount: number) => {
    if (problemCount > 0) {
      setInfoMessage(`${problemCount} master problem${problemCount > 1 ? 's' : ''} found.`);
    } else {
      setInfoMessage('No master problems found.');
    }
  }, []);

  useEffect(() => {
    setSearchResultMessage(pagination.total);
  }, [pagination.total, setSearchResultMessage]);

  useEffect(() => {
    if (searchText.length === 0) {
      fetchMasterProblems('', setMasterProblemsList, setPagination);
    }
  }, [searchText]);

  useEffect(() => {
    fetchMasterProblems('', setMasterProblemsList, setPagination);
  }, []);

  const updateProblemList = useCallback((masterProblems: MasterProblem[]) => {
    setIsLoading(false);
    setMasterProblemsList(masterProblems);
  }, []);

  const updatePage = useCallback(
    (page: number) => {
      setPagination((prev) => ({ ...prev, page }));
      setIsLoading(true);
      fetchMasterProblems(searchText, updateProblemList, setPagination, page);
    },
    [searchText, updateProblemList]
  );

  const fetchProblemList = useCallback(() => {
    setInfoMessage('');
    setIsLoading(true);
    fetchMasterProblems(searchText, updateProblemList, setPagination);
  }, [searchText, updateProblemList]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetchProblemList();
  };

  return (
    <CRow>
      <CCol xs={12}>
        {infoMessage && (
          <CAlert color="info" dismissible>
            {infoMessage}
          </CAlert>
        )}
        <CCard className="mb-4">
          <CCardHeader>
            <CForm className="row g-3" onSubmit={handleSubmit}>
              <CCol xs={8}>
                <CFormInput
                  type="text"
                  id="searchInput"
                  label="Search Master Problems"
                  placeholder="Search by name"
                  onChange={(event) => setSearchText(event.target.value)}
                />
              </CCol>
              <CCol className="align-self-end" xs={2}>
                <CLoadingButton color="primary" type="submit" loading={isLoading} disabled={!searchText}>
                  Search
                </CLoadingButton>
              </CCol>
              <CCol className="align-self-end text-end" xs={2}>
                <CButton color="primary" href={'/master_problems/new'}>
                  New
                </CButton>
              </CCol>
            </CForm>
          </CCardHeader>
          {masterProblemsList && masterProblemsList.length > 0 && (
            <CCardBody role="listitem">
              <CSmartTable
                items={masterProblemsList}
                itemsPerPage={25}
                pagination={{
                  external: true
                }}
                paginationProps={{
                  align: 'center',
                  activePage: pagination.page,
                  pages: calculatePages(pagination)
                }}
                onActivePageChange={(activePage) => updatePage(activePage)}
                columns={[
                  {
                    key: 'name',
                    label: 'Name'
                  },
                  {
                    key: 'status',
                    label: 'Status'
                  },
                  {
                    key: 'created_at',
                    label: 'Created At'
                  },
                  {
                    key: 'updated_at',
                    label: 'Updated At'
                  }
                ]}
                scopedColumns={{
                  name: (masterProblem: MasterProblem) => {
                    return (
                      <td className="py-2">
                        <Link to={`/master_problems/${masterProblem.id}`}>{masterProblem.name}</Link>
                      </td>
                    );
                  },
                  status: (masterProblem: MasterProblem) => {
                    return (
                      <td className="py-2">
                        <CBadge color={masterProblem.status === 'active' ? 'success' : 'secondary'}>
                          {masterProblem.status}
                        </CBadge>
                      </td>
                    );
                  },
                  created_at: (masterProblem: MasterProblem) => {
                    return <td className="py-2">{compactDateTimeDisplay(masterProblem.created_at)}</td>;
                  },
                  updated_at: (masterProblem: MasterProblem) => {
                    return (
                      <td className="py-2">
                        {compactDateTimeDisplay(masterProblem.updated_at)} (
                        {masterProblem.updated_by_employee.last_name})
                      </td>
                    );
                  }
                }}
              ></CSmartTable>
            </CCardBody>
          )}
        </CCard>
      </CCol>
    </CRow>
  );
};

export default List;
