import cn from 'classnames';
import React from 'react';

import { Customer } from 'types/Customer';

import { toCurrency } from 'utils/price';

import { ReactComponent as DotIcon } from 'assets/images/dot.svg';

import styles from './BillingDetails.module.scss';

export const BillingDetails = ({ customer }: { customer: Customer }) => {
  return (
    <div className={styles.root}>
      <div className={cn(styles.detail, styles.topRow)}>
        <span className={styles.left}>
          YTD Spend <DotIcon /> {new Date().getFullYear()}
        </span>
        <span className={styles.right}>{toCurrency(customer.ytd_revenue)}</span>
      </div>
      <div className={cn(styles.detail, styles.highlightedRow)}>
        <span className={styles.left}>Unpaid Balance</span>
        <span className={styles.right}>{toCurrency(customer.unpaid_balance)}</span>
      </div>
      <div className={styles.detail}>
        <span className={styles.left}>Total Spend</span>
        <span className={styles.right}>{toCurrency(customer.total_revenue)}</span>
      </div>

      <div className={styles.cardDetails}>
        <div className={styles.left}>Card Number</div>
        <div className={styles.right}>
          {customer.membership?.credit_card_last_4 ? `x${customer.membership?.credit_card_last_4}` : 'None'}
        </div>
      </div>
    </div>
  );
};
