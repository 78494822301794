import * as React from 'react';
import type { SVGProps } from 'react';

const SvgTherometer = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 18" {...props}>
    <g fill="currentColor">
      <path d="M7.498 10.594V2.5c0-1.379-1.121-2.5-2.5-2.5a2.503 2.503 0 0 0-2.5 2.5v8.094a4.11 4.11 0 0 0-1.627 3.279A4.13 4.13 0 0 0 4.998 18a4.13 4.13 0 0 0 4.127-4.127 4.11 4.11 0 0 0-1.627-3.279m-2.5 6.327a3.05 3.05 0 0 1-3.048-3.048 3.04 3.04 0 0 1 1.384-2.549.54.54 0 0 0 .244-.451V2.5c0-.784.637-1.42 1.42-1.42.784 0 1.421.636 1.421 1.42v8.373a.54.54 0 0 0 .244.451 3.04 3.04 0 0 1 1.383 2.549 3.05 3.05 0 0 1-3.048 3.048" />
      <path d="M5.538 12.162V5.03a.54.54 0 1 0-1.079 0v7.13a1.794 1.794 0 1 0 1.08 0" />
    </g>
  </svg>
);
export default SvgTherometer;
