import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CCard, CCardBody, CCardHeader, CSpinner } from '@coreui/react-pro';

import { fetchMasterProblem, updateMasterProblem } from 'api/MasterProblems';

import { MasterProblem } from 'types/MasterProblem';

import Form from './Form';

const Edit = (): JSX.Element => {
  type EditParams = {
    id: string;
  };
  const { id } = useParams<keyof EditParams>() as EditParams;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [masterProblem, setMasterProblem] = useState<MasterProblem>({} as MasterProblem);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.type === 'checkbox') {
      setMasterProblem({
        ...masterProblem,
        [event.target.id]: 'checked' in event.target ? (event.target.checked ? 'active' : 'disabled') : null
      });
    } else {
      setMasterProblem({ ...masterProblem, [event.target.id]: event.target.value });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, errorCallback: (error: string) => void) => {
    event.preventDefault();
    updateMasterProblem(id, masterProblem, updateSuccess, errorCallback);
  };

  function handleMasterProblemLoaded(masterProblem: MasterProblem) {
    setMasterProblem(masterProblem);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchMasterProblem(id, handleMasterProblemLoaded);
  }, [id]);

  const updateSuccess = (masterProblem: MasterProblem) => {
    navigate(`/master_problems/${masterProblem.id}`);
  };

  const handleCancel = () => {
    navigate(`/master_problems/${id}`);
  };

  const loadingState = () => {
    return <CSpinner color="primary" />;
  };

  function loadedState() {
    return (
      <CCard style={{ width: '35rem' }}>
        <CCardHeader>
          <h3>Edit Master Problem</h3>
        </CCardHeader>
        <CCardBody>
          <Form
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            handleChange={handleChange}
            masterProblem={masterProblem}
          />
        </CCardBody>
      </CCard>
    );
  }

  return isLoading ? loadingState() : loadedState();
};

export default Edit;
