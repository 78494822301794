import axios from 'axios';

import { SchedulingCategory } from 'types/SchedulingCategory';
import { SchedulingCategoryInput } from 'types/SchedulingCategoryInput';

const fetchSchedulingCategories = (onSuccess: (data: SchedulingCategory[]) => void) => {
  const url = `/pim/scheduling_categories`;
  axios.get<SchedulingCategory[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchSchedulingCategory = (schedulingCategoryId: number, onSuccess: (data: SchedulingCategory) => void) => {
  const url = `/pim/scheduling_categories/${schedulingCategoryId}`;
  axios.get<SchedulingCategory>(url).then((data) => onSuccess(data.data.record));
};

const createSchedulingCategory = (
  schedulingCategory: SchedulingCategoryInput,
  onSuccess: (data: SchedulingCategory) => void
) => {
  const url = `/pim/scheduling_categories`;
  axios
    .post<SchedulingCategory>(url, { scheduling_category: schedulingCategory })
    .then((data) => onSuccess(data.data.record));
};

const updateSchedulingCategory = (
  schedulingCategoryId: number,
  schedulingCategory: SchedulingCategoryInput,
  onSuccess: (data: SchedulingCategory) => void
) => {
  const url = `/pim/scheduling_categories/${schedulingCategoryId}`;
  axios
    .put<SchedulingCategory>(url, { scheduling_category: schedulingCategory })
    .then((data) => onSuccess(data.data.record));
};

const deleteSchedulingCategory = (schedulingCategory: SchedulingCategory, onSuccess: () => void) => {
  const url = `/pim/scheduling_categories/${schedulingCategory.id}`;
  axios.delete(url).then(() => onSuccess());
};

export {
  fetchSchedulingCategories,
  fetchSchedulingCategory,
  createSchedulingCategory,
  updateSchedulingCategory,
  deleteSchedulingCategory
};
