import { Option } from './Option';

export type ListFilter = {
  name: string;
  key: string;
  value?: string;
  type: string;
  options?: string[] | Option[]; // used in selects
};

export const transformFilters = (filters: ListFilter[], initial = {}) => {
  return filters
    .map((filter) => {
      return { [filter.key]: filter.value };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), initial);
};
