import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M8.978 9a3.745 3.745 0 0 0 3.74-3.75 3.745 3.745 0 0 0-3.74-3.75 3.745 3.745 0 0 0-3.74 3.75A3.745 3.745 0 0 0 8.977 9M15.404 16.5c0-2.902-2.88-5.25-6.427-5.25s-6.426 2.348-6.426 5.25" />
    </g>
  </svg>
);
export default SvgPerson;
