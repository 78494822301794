import * as React from 'react';
import { useCallback, useState } from 'react';
import { TasksListTable } from 'views/employee_tasks/TasksListTable';

import { CFormCheck, CRow } from '@coreui/react-pro';

import { fetchEmployeeTasks } from 'api/EmployeeTasks';

import { Consult } from 'types/Consult';
import { EmployeeTask } from 'types/EmployeeTask';
import { Pagination } from 'types/Pagination';

import { usePoll } from 'hooks/usePoll';

type Props = {
  consult: Consult;
};

export const TasksStep = ({ consult }: Props) => {
  const [employeeTasks, setEmployeeTasks] = useState<EmployeeTask[]>([]);
  const [tasksPagination, setTasksPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });
  const [showJustPending, setShowJustPending] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const toggleShowJustPending = () => setShowJustPending(!showJustPending);

  const fetch = useCallback(() => {
    const filterParams = {
      animal_id: consult.animal_id.toString(),
      consult_id: consult.id.toString(),
      status: showJustPending ? 'pending' : undefined
    };
    fetchEmployeeTasks({ onSuccess: handleSuccess, setPagination: setTasksPagination, filterParams });
  }, [consult.animal_id, consult.id, showJustPending]);
  usePoll(fetch);

  const handleSuccess = (tasks: EmployeeTask[]) => {
    setIsLoading(false);
    setEmployeeTasks(tasks);
  };

  return (
    <CRow className="mb-3">
      <CFormCheck
        className="mb-2 ms-3"
        label="Show only pending tasks"
        aria-label="Show only pending tasks"
        onChange={toggleShowJustPending}
        checked={showJustPending}
      />

      <TasksListTable
        employeeTasks={employeeTasks}
        pagination={tasksPagination}
        isLoading={isLoading}
        setPagination={setTasksPagination}
        refetchTasks={fetch}
        animal={consult.animal}
        consult={consult}
      />
    </CRow>
  );
};
