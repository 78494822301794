import * as React from 'react';
import type { SVGProps } from 'react';

const SvgView = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.683 9c0 1.485-1.2 2.685-2.685 2.685A2.68 2.68 0 0 1 6.313 9c0-1.485 1.2-2.685 2.685-2.685s2.685 1.2 2.685 2.685"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.003 15.203c2.647 0 5.115-1.56 6.832-4.26.675-1.058.675-2.835 0-3.893-1.717-2.7-4.185-4.26-6.832-4.26S3.888 4.35 2.17 7.05c-.675 1.058-.675 2.835 0 3.893 1.718 2.7 4.185 4.26 6.833 4.26"
    />
  </svg>
);
export default SvgView;
