import cn from 'classnames';
import React from 'react';

import { CImage } from '@coreui/react-pro';

import { Employee } from 'types/Employee';

import { generateCloudinaryPath } from 'utils/cloudinary';

import UnisexDoctorImg from 'assets/images/avatars/unisex-doctor.svg';

interface Props {
  employee: Employee;
  width?: number;
  height?: number;
  borderSize?: number;
  className?: string;
}

const EmployeePhoto: React.FC<Props> = ({ employee, width = 50, height = 50, borderSize = 1000, className }) => {
  let src = UnisexDoctorImg;
  if (employee.photo_provider_id) {
    const avatarSource = generateCloudinaryPath({
      imagePublicId: employee.photo_provider_id,
      borderSize: borderSize
    });

    src = avatarSource;
  }

  return (
    <CImage
      alt={`${employee.full_name_with_title} photo`}
      className={cn('mx-0 my-0', className)}
      height={height}
      src={src}
      width={width}
    />
  );
};

export default EmployeePhoto;
