import { compactDateTimeDisplay, isValidDate } from './dates';

const snakeCaseToTitleCase = (snakeCase: string) => {
  const words = snakeCase.split('_');
  const titleCase = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  return titleCase;
};

const toKebabCase = (input: string) => {
  const words = input.split(' ');
  const kebabCase = words.map((word) => word.toLowerCase()).join('-');
  return kebabCase;
};

const formatLogItem = (item: string | number | null): string | number => {
  if (item === null) {
    return '';
  }
  if (typeof item === 'string' && isValidDate(item)) {
    return compactDateTimeDisplay(item);
  } else if (typeof item === 'boolean') {
    return item === true ? 'True' : 'False';
  }
  return item;
};

const pluralize = (word: string, count: number, { plural }: { plural?: string } = {}) => {
  const pluralized = plural || `${word}s`;

  return count === 1 ? word : pluralized;
};

const capitalize = (word?: string) => {
  if (word) return word.charAt(0).toUpperCase() + word.slice(1);
  else return;
};

const formatWithBreaks = (text: string) => {
  return text.replace(/\n/g, '<br>');
};

const stripHTMLtags = (html: string) => {
  return html.replace(/<[^>]*>?/gm, '');
};

const isNumeric = (value: string) => {
  return !isNaN(parseFloat(value));
};

export {
  formatWithBreaks,
  stripHTMLtags,
  snakeCaseToTitleCase,
  toKebabCase,
  formatLogItem,
  pluralize,
  capitalize,
  isNumeric
};
