import { addDays } from 'date-fns';
import * as React from 'react';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import { CModal, CModalBody, CModalHeader } from '@coreui/react-pro';

import { createEmployeeTask } from 'api/EmployeeTasks';

import { ContextType } from 'types/ContextType';
import { ContextItem, EmployeeTask, EmployeeTaskType } from 'types/EmployeeTask';
import { Option } from 'types/Option';

import { ClinicContext } from 'contexts/ClinicContext';

import { TaskForm } from './TaskForm';

type Props = {
  animal_id?: number;
  clinic_id?: number;
  consult_id?: number;
  disablePresets?: boolean;
  hideModal: () => void;
  initialReason?: EmployeeTaskType;
  contextItem?: ContextItem;
  contextType?: ContextType;
  contextOptions?: Option[];
  callback?: () => void;
};

export const NewTaskModal = ({
  hideModal,
  animal_id,
  clinic_id,
  consult_id,
  disablePresets,
  initialReason = 'CallbackTask',
  contextItem,
  contextType,
  contextOptions,
  callback
}: Props) => {
  const [loading, setLoading] = useState(false);

  const { clinicContext } = useContext(ClinicContext);

  const [task, setTask] = useState<Partial<EmployeeTask>>({
    context_id:
      contextOptions && contextOptions.length > 0
        ? Number(contextOptions[0].value)
        : contextItem
        ? contextItem.id
        : undefined,
    context_type: contextType,
    context: contextItem,
    type: initialReason,
    due_at: addDays(new Date(), 1).toISOString(),
    clinic_id: clinic_id || Number(clinicContext),
    consult_id: consult_id,
    animal_id: animal_id,
    status: 'pending'
  });

  const onSubmit = () => {
    setLoading(true);
    createEmployeeTask(task, { onSuccess: handleSuccess, onError: handleError });
  };

  const handleSuccess = (_task: EmployeeTask) => {
    toast.success('Task created successfully');
    setLoading(false);
    hideModal();
    if (callback) callback();
  };

  const handleError = (_error: string) => {
    setLoading(false);
  };

  return (
    <CModal className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
      <CModalHeader>
        <h4 className="mb-0">New Task</h4>
      </CModalHeader>
      <CModalBody>
        <TaskForm
          task={task}
          setTask={setTask}
          disablePresets={disablePresets}
          contextOptions={contextOptions}
          hideModal={hideModal}
          isLoading={loading}
          onSubmit={onSubmit}
        />
      </CModalBody>
    </CModal>
  );
};
