import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';

import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CInputGroup,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { fetchProducts } from 'api/Products';

import { HealthPlan } from 'types/HealthPlan';
import { HealthPlanProduct } from 'types/HealthPlanProduct';
import { Product } from 'types/Product';

import { toOption } from 'utils/selectOptions';

type AddProductFormProps = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
  setHealthPlanProduct: (healthPlanProduct: HealthPlanProduct) => void;
  healthPlan: HealthPlan;
  healthPlanProduct: HealthPlanProduct;
};

type Option = {
  value: string;
  label: string;
};

const ProductForm = ({
  handleSubmit,
  handleCancel,
  healthPlan,
  setHealthPlanProduct,
  healthPlanProduct
}: AddProductFormProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Option>();
  const [productError, setProductError] = useState<string>();

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    setProductError(undefined);

    if (!healthPlanProduct.product_id) {
      setProductError('Product is required');
      setIsSubmitting(false);
      return;
    }

    handleSubmit(event);
  };

  const handleProductChange = (selected?: Option) => {
    if (selected && selected.value) {
      setHealthPlanProduct({ ...healthPlanProduct, product_id: Number(selected.value) });
      setSelectedProduct(selected);
      setProductError(undefined);
    }
  };

  const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (event.target.type === 'checkbox') {
      setHealthPlanProduct({
        ...healthPlanProduct,
        [event.target.id]: 'checked' in event.target ? event.target.checked : false
      });
    } else {
      setHealthPlanProduct({ ...healthPlanProduct, [event.target.id]: event.target.value });
    }
  };

  return (
    <CForm onSubmit={submit}>
      <CRow className="mb-3">
        <CCol>
          <CFormLabel htmlFor="name" className="mb-0">
            Health Plan
          </CFormLabel>
          <CFormInput type="text" id="name" value={healthPlan.name} disabled />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol>
          <CFormLabel htmlFor="product_id" className="mb-0">
            Product
          </CFormLabel>
          <AsyncSelect<Option>
            id="product_id"
            aria-label="Product"
            onChange={(selected) => handleProductChange(selected as Option)}
            loadOptions={(inputValue, callback) => {
              if (inputValue.length < 3) return;

              fetchProducts(inputValue, (products: Product[]) => {
                callback(products.map(toOption));
              });
            }}
            placeholder="Search for a product..."
            isSearchable
            value={
              selectedProduct
                ? selectedProduct
                : healthPlanProduct && healthPlanProduct.product
                ? toOption(healthPlanProduct.product)
                : undefined
            }
          />
          {productError && (
            <CFormLabel htmlFor="product_id" className="mb-0 mt-1" style={{ color: 'var(--cui-danger)' }}>
              {productError}
            </CFormLabel>
          )}
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol>
          <CFormLabel htmlFor="fulfillment_duration" className="mb-0">
            Fulfillment Duration
          </CFormLabel>
          <CInputGroup>
            <CFormInput
              type="number"
              id="fulfillment_duration"
              aria-label="Fulfillment Duration"
              name="fulfillment_duration"
              onChange={handleOtherChange}
              min={1}
              value={healthPlanProduct?.fulfillment_duration || undefined}
            />
            <CFormSelect
              id="fulfillment_duration_unit"
              aria-label="Fulfillment Duration Unit"
              name="fulfillment_duration_unit"
              options={['', 'days', 'weeks', 'months', 'years']}
              onChange={handleOtherChange}
              value={healthPlanProduct?.fulfillment_duration_unit || undefined}
            />
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol xs={6}>
          <CFormLabel htmlFor="per_quantity" className="col-form-label">
            Per Quantity?
          </CFormLabel>
          <CFormSwitch
            id="per_quantity"
            aria-label="Per Quantity"
            name="per_quantity"
            checked={healthPlanProduct?.per_quantity ?? false}
            onChange={handleOtherChange}
          />
        </CCol>
        {healthPlanProduct.per_quantity && (
          <CCol xs={6}>
            <CFormLabel htmlFor="quantity" className="col-form-label">
              Quantity
            </CFormLabel>
            <CFormInput
              type="number"
              id="quantity"
              aria-label="Quantity"
              name="quantity"
              value={healthPlanProduct?.quantity || undefined}
              onChange={handleOtherChange}
              min={1}
            />
          </CCol>
        )}
      </CRow>

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
        <CButton color="secondary" disabled={isSubmitting} onClick={handleCancel}>
          Cancel
        </CButton>
        <CLoadingButton color="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Save
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default ProductForm;
