import axios from 'axios';

import { MasterProblem } from 'types/MasterProblem';
import { Pagination } from 'types/Pagination';

function fetchMasterProblems(
  queryText: string,
  onSuccess: (masterProblems: MasterProblem[]) => void,
  setPagination: (pagination: Pagination) => void,
  page?: number
) {
  const url = `/pim/master_problems`;
  axios
    .get<MasterProblem[]>(url, {
      params: {
        query: queryText,
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
    });
}

function fetchMasterProblem(problemId: string, onSuccess: (problem: MasterProblem) => void) {
  const url = `/pim/master_problems/${problemId}`;
  axios.get<MasterProblem>(url).then((data) => onSuccess(data.data.record));
}

function updateMasterProblem(
  masterProblemId: string,
  params: MasterProblem,
  onSuccess: (masterProblem: MasterProblem) => void,
  onError?: (error: string) => void
) {
  const url = `/pim/master_problems/${masterProblemId}`;
  axios
    .put<MasterProblem>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

function createMasterProblem(
  params: MasterProblem,
  onSuccess: (masterProblem: MasterProblem) => void,
  onError?: (error: string) => void
) {
  const url = `/pim/master_problems`;
  axios
    .post<MasterProblem>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

function fetchMasterProblemsByQuery(query: string) {
  const url = '/pim/master_problems';

  return axios
    .get<MasterProblem[]>(url, { params: { query: query } })
    .then((response) => response.data.record)
    .catch((error) => []);
}

export {
  fetchMasterProblemsByQuery,
  fetchMasterProblems,
  fetchMasterProblem,
  createMasterProblem,
  updateMasterProblem
};
