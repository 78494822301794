import { Settings } from 'luxon';
import * as React from 'react';
import { useEffect } from 'react';

import { Employee } from 'types/Employee';

import { signOut } from '../api/Authentication';
import { fetchCurrentEmployee } from '../api/Employees';

interface AuthContextType {
  employee: Employee | null;
  loginSuccess: (newEmployee: Employee, callback: (employee: Employee) => void) => void;
  isCalendarAdmin: () => boolean;
  isDeveloper: () => boolean;
  isOrganizationAdmin: () => boolean;
  logout: VoidFunction;
}

export const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [employee, setEmployee] = React.useState<Employee | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const loginSuccess = (newEmployee: Employee, callback: (employee: Employee) => void) => {
    Settings.defaultZone = newEmployee.organization.time_zone || 'UTC';
    setEmployee(newEmployee);
    callback(newEmployee);
  };

  const logout = () => {
    signOut({
      onSuccess: () => {
        document.location = '/';
      }
    });
  };

  const hasRole = (role: string) => !!employee?.roles?.find((r) => r.name === role);

  const isCalendarAdmin = () => hasRole('calendar admin');
  const isDeveloper = () => hasRole('developer');
  const isOrganizationAdmin = () => hasRole('organization admin');

  useEffect(() => {
    fetchCurrentEmployee({
      onSuccess: (employee: Employee) => {
        Settings.defaultZone = employee.organization.time_zone || 'UTC';
        setEmployee(employee);
        setIsLoading(false);
      },
      onError: () => {
        setEmployee(null);
        setIsLoading(false);
      }
    });
  }, [setEmployee]);

  if (isLoading) {
    return <></>;
  }

  const value = { employee, loginSuccess, isOrganizationAdmin, isCalendarAdmin, isDeveloper, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuth };
