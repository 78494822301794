import * as React from 'react';

import { CCol, CFormCheck, CFormInput, CFormLabel, CFormTextarea, CRow } from '@coreui/react-pro';

import { FormLine, FormLineOption } from 'types/ConsultTemplate';

type Props = {
  formLine: FormLine;
  updateItem: (formLine: FormLine, option: FormLineOption) => void;
  lineIndex: number;
};

export const ConsultTemplateFormItem = ({ formLine, updateItem, lineIndex }: Props) => {
  return (
    <CRow className="mb-3">
      {formLine.options.length === 0 ? (
        <CFormLabel>{formLine.label}</CFormLabel>
      ) : (
        formLine.options.map((option, index) => (
          <CCol key={option.label}>
            {option.type === 'label' && <CFormLabel>{option.label}</CFormLabel>}

            {option.type === 'text' && (
              <CFormInput
                label={option.label}
                type="text"
                id={`${formLine.label}-${lineIndex}-${option.label}-${option.tabIndex}`}
                tabIndex={option.tabIndex}
                value={option.selectedValue}
                onChange={(event) => updateItem(formLine, { ...option, selectedValue: event.target.value })}
              />
            )}

            {option.type === 'textarea' && (
              <CFormTextarea
                label={option.label}
                id={`${formLine.label}-${lineIndex}-${option.label}-${option.tabIndex}`}
                tabIndex={option.tabIndex}
                rows={2}
                value={option.selectedValue}
                onChange={(event) => updateItem(formLine, { ...option, selectedValue: event.target.value })}
              />
            )}

            {option.type === 'select' && (
              <div className="d-flex flex-column">
                <CFormLabel>{option.label}</CFormLabel>

                <div>
                  {option.options?.map((optionLabel) => (
                    <CFormCheck
                      inline
                      key={optionLabel}
                      type="radio"
                      name={`${formLine.label}-${lineIndex}-${option.label}-${optionLabel}-${option.tabIndex}`}
                      id={`${formLine.label}-${lineIndex}-${option.label}-${optionLabel}-${option.tabIndex}`}
                      tabIndex={option.tabIndex}
                      label={optionLabel}
                      checked={option.selectedValue === optionLabel}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateItem(formLine, { ...option, selectedValue: optionLabel });
                        // set the focus to the next tabbble element
                        const nextElement = document.querySelectorAll(
                          "[tabindex='" + (event.target.tabIndex + 1) + "']"
                        )[0];

                        if (nextElement) {
                          const id = nextElement.getAttribute('id');
                          if (id) document.getElementById(id)?.focus();
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </CCol>
        ))
      )}
    </CRow>
  );
};
