import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { RichTextEditor } from 'components/RichTextEditor';

import styles from './InternalNotes.module.scss';

type Props = {
  value: string;
  onChange: (value: string) => void;
  id?: string;
  name?: string;
};

export const InternalNotes = ({ onChange, value, id = 'internal_notes', name = 'internal_notes' }: Props) => {
  const [expanded, setExpanded] = useState(!!value);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <label className={cn('ms-auto', styles.switch)}>
          <input type="checkbox" onChange={toggleExpanded} checked={expanded} />
          <div className={cn(styles.slider, styles.round)} />
          <span className={cn(expanded ? styles.on : styles.off)}>Internal Note</span>
        </label>
      </div>

      {expanded && <RichTextEditor label="Internal Note" value={value} onChange={onChange} id={id} name={name} />}
    </>
  );
};
