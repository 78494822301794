import axios from 'axios';

import { Employee } from '../types/Employee';

const signInUrl = '/pim/sign_in';

const signIn = (
  email: string,
  password: string,
  { onSuccess, onError }: { onSuccess: (employee: Employee) => void; onError: () => void }
) => {
  axios
    .post<Employee>(signInUrl, { employee: { email: email, password: password } })
    .then((data) => onSuccess(data.data.record))
    .catch(() => {
      onError();
    });
};

const signOut = ({ onSuccess }: { onSuccess: () => void }) => {
  const url = '/pim/sign_out';
  axios
    .delete(url)
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onSuccess();
    });
};

const requestPasswordReset = (
  email: string,
  { onSuccess, onError }: { onSuccess: () => void; onError: () => void }
) => {
  const url = '/pim/password';
  axios
    .post(url, { employee: { email: email } })
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });
};

const resetPassword = (
  resetPasswordToken: string,
  password: string,
  passwordConfirmation: string,
  { onSuccess, onError }: { onSuccess: () => void; onError: () => void }
) => {
  const url = '/pim/password';
  axios
    .put(url, {
      employee: {
        reset_password_token: resetPasswordToken,
        password: password,
        password_confirmation: passwordConfirmation
      }
    })
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });
};

export { signIn, signInUrl, signOut, requestPasswordReset, resetPassword };
