export const consultDetailsSteps = [
  'treatment-plans', // AKA estimates
  'health-statuses',
  'soap', // Contains presenting problems, history, physical exams, assessments, and consult plans
  'rx-vacc', // Contains prescriptions and vaccinations
  'diagnostics',
  'procedures',
  'communications',
  'tasks',
  'summary' // Contains forward bookings, documents, invoices, and summaries
] as const;

export type ConsultDetailsStep = (typeof consultDetailsSteps)[number];
