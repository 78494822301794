import axios from 'axios';

import { Consult } from 'types/Consult';

const fetchConsult = (consultId: string | number, onSuccess: (consult: Consult) => void) => {
  const url = `/pim/consults/${consultId}`;
  axios.get<Consult>(url).then((data) => onSuccess(data.data.record));
};

const fetchConsultsByAnimal = (animalId: string | number) => {
  const url = `/pim/consults/`;

  const params = animalId ? { animal_id: animalId } : {};

  return axios
    .get<Consult[]>(url, { params: params })
    .then((response) => response.data.record)
    .catch((error) => []);
};

type AnimalOrCustomerId =
  | { customer_id: string | number; animal_id?: never }
  | { customer_id?: never; animal_id: string | number };

const fetchFinancialConsults = ({
  params,
  onSuccess
}: {
  params: AnimalOrCustomerId;
  onSuccess: (consults: Consult[]) => void;
}) => {
  const url = `/pim/consults/financial_consults`;

  axios.get<Consult[]>(url, { params: params }).then((data) => onSuccess(data.data.record));
};

export { fetchConsult, fetchConsultsByAnimal, fetchFinancialConsults };
