import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CCol, CContainer, CRow } from '@coreui/react-pro';

import { reactSelectStyles } from 'utils/reactSelect';

import { fetchAnimal, fetchAnimalsByQuery } from '../api/Animals';
import { fetchCustomer, fetchCustomersByQuery } from '../api/Customers';
import { Animal } from '../types/Animal';
import { Customer } from '../types/Customer';
import { animalToOption, customerToOption } from '../utils/selectOptions';

type Props = {
  setAnimal: (animal: Animal | undefined) => void;
  setCustomer: (customer: Customer | undefined) => void;
};

const PatientChooser: React.FC<Props> = ({ setAnimal, setCustomer }: Props) => {
  type Option = {
    value?: string;
    label?: string;
  };

  const MIN_QUERY_LENGTH = 3;
  const [selectedAnimalOption, setSelectedAnimalOption] = useState<Option | null>(null);
  const [animalOptions, setAnimalOptions] = useState<Option[]>([]);
  const [selectedCustomerOption, setSelectedCustomerOption] = useState<Option | null>(null);
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);

  const loadAnimalOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchAnimalsByQuery(inputValue, { members: true }).then((options) => {
      callback(options.map(animalToOption));
    });
  };

  const loadCustomerOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchCustomersByQuery(inputValue, { members: true }).then((options) => {
      callback(options.map(customerToOption));
    });
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const handleAnimalSelectChange = (option: Option | null) => {
    setSelectedAnimalOption(option);

    if (option?.value) {
      fetchAnimal(option.value, (animal: Animal) => {
        if (animal?.customer) {
          setAnimal(animal);
          const customerOption = customerToOption(animal.customer);
          setSelectedCustomerOption(customerOption);
          setCustomerOptions([customerOption]);
          fetchCustomer(animal.customer.id, setCustomer);
        }
      });
    } else {
      setCustomer(undefined);
      setSelectedCustomerOption(null);
    }
  };

  const handleCustomerSelectChange = (option: Option | null) => {
    setSelectedCustomerOption(option);

    if (option?.value) {
      fetchCustomer(option.value, (fetchedCustomer: Customer) => {
        setCustomer(fetchedCustomer);
        if (fetchedCustomer?.animals) {
          const options = fetchedCustomer.animals.map((a) => {
            return { value: String(a.id), label: `"${a.name}" ${fetchedCustomer.last_name} - ${a.id}` };
          });
          setAnimalOptions(options);
          if (options.length === 1) {
            handleAnimalSelectChange(options[0]);
            fetchAnimal(options[0].value, (animal: Animal) => {
              setAnimal(animal);
            });
          }
        }
      });
    } else {
      setAnimal(undefined);
      setSelectedAnimalOption(null);
    }
  };

  return (
    <CContainer sm className="m-0 p-0">
      <CRow>
        <CCol sm={6}>
          <label htmlFor="animal" className="form-label">
            Patient
          </label>
          <AsyncSelect<Option>
            id="animal"
            aria-label="Patient"
            placeholder="Type to search..."
            styles={reactSelectStyles}
            value={selectedAnimalOption}
            onChange={handleAnimalSelectChange}
            loadingMessage={loadingMessage}
            defaultOptions={animalOptions}
            loadOptions={loadAnimalOptions}
            isClearable
            isSearchable
            required
          />
        </CCol>
        <CCol sm={6}>
          <label htmlFor="client" className="form-label">
            Client
          </label>
          <AsyncSelect<Option>
            id="client"
            aria-label="Client"
            placeholder="Type to search..."
            styles={reactSelectStyles}
            value={selectedCustomerOption}
            onChange={handleCustomerSelectChange}
            loadingMessage={loadingMessage}
            defaultOptions={customerOptions}
            loadOptions={loadCustomerOptions}
            isClearable
            isSearchable
          />
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default PatientChooser;
