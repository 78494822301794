import { addMinutes } from 'date-fns';
import { DateTime } from 'luxon';
import * as React from 'react';

import {
  CButton,
  CCloseButton,
  CCol,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
  CSidebar,
  CSidebarFooter,
  CSidebarHeader
} from '@coreui/react-pro';

import { HospitalEvent } from 'types/HospitalEvent';
import { HospitalSheet } from 'types/HospitalSheet';

import { useAuth } from 'hooks/useAuth';

import { compactDateTimeDisplay, pickerTimeDisplay } from 'utils/dates';

import { Pill } from 'components/Pill';

type HospitalEventFormProps = {
  handleSubmit: (event: Partial<HospitalEvent>) => void;
  handleCancel: () => void;
  setHospitalEvent: (event: Partial<HospitalEvent> | undefined) => void;
  hospitalSheet: HospitalSheet;
  hospitalEvent: Partial<HospitalEvent>;
  selectedDay: string;
};

const HospitalEventForm = ({
  handleSubmit,
  handleCancel,
  setHospitalEvent,
  hospitalSheet,
  hospitalEvent,
  selectedDay
}: HospitalEventFormProps): JSX.Element => {
  const auth = useAuth();

  const getStatus = (start_time: string, completed_at: string | null | undefined) => {
    const hoursNow = new Date().getHours();
    const hoursEvent = new Date(start_time).getHours();

    if (completed_at) return 'completed';
    if (hoursNow === hoursEvent && !completed_at) return 'due_now';
    if (hoursNow > hoursEvent && !completed_at) return 'overdue';
    return 'not due yet';
  };

  const handleEventTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedTime = event.target.value;
    const id = event.target.id;

    if (changedTime) {
      const day = selectedDay ? new Date(selectedDay) : new Date();
      const [hours, minutes] = changedTime.split(':');

      day.setHours(Number(hours));
      day.setMinutes(Number(minutes));

      if (id === 'start_time') {
        const updatedEndDate = addMinutes(day, 15);
        setHospitalEvent({
          ...hospitalEvent,
          start_time: day.toISOString(),
          end_time: updatedEndDate.toISOString()
        });
      } else {
        setHospitalEvent({ ...hospitalEvent, end_time: day.toISOString() });
      }
    }
  };

  const handleComplete = () => {
    const completedEvent = {
      ...hospitalEvent,
      completed_at: DateTime.local().toISO(),
      completed_by_employee_id: auth.employee?.id
    };
    setHospitalEvent(completedEvent);
    handleSubmit(completedEvent);
  };

  return (
    <>
      <CSidebar
        colorScheme="light"
        overlaid
        placement="end"
        size="xl"
        style={{ overflowY: 'scroll', marginRight: '0' }}
        className="d-flex justify-content-between flex-column"
      >
        <div>
          <CSidebarHeader className="bg-transparent">
            <h5 className="text-center">
              {hospitalSheet.discharged_at ? 'View' : hospitalEvent.id ? 'Edit' : 'New'} event
              <CCloseButton className="float-end" onClick={() => setHospitalEvent(undefined)} />
            </h5>
          </CSidebarHeader>

          <div style={{ padding: '0px 20px' }}>
            <CRow className="mb-3">
              <CCol>
                <CFormLabel htmlFor="title" className="col-sm-3 col-form-label d-flex justify-content-between w-100">
                  Title
                  {hospitalEvent.id && hospitalEvent.start_time && (
                    <Pill label={getStatus(hospitalEvent.start_time, hospitalEvent.completed_at)} />
                  )}
                </CFormLabel>

                <CFormInput
                  type="text"
                  id="title"
                  value={hospitalEvent.hospital_event_schedule?.title}
                  disabled
                  required
                />
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <CFormLabel htmlFor="title" className="col-sm-3 col-form-label d-flex justify-content-between w-100">
                  Instructions
                </CFormLabel>

                <CFormInput
                  type="text"
                  id="instructions"
                  value={hospitalEvent.hospital_event_schedule?.instructions}
                  disabled
                  required
                />
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <CFormInput
                  label="Start Time"
                  type="time"
                  id="start_time"
                  onChange={handleEventTimeChange}
                  value={hospitalEvent.start_time ? pickerTimeDisplay(hospitalEvent.start_time) : undefined}
                  disabled={!!hospitalSheet.discharged_at || !!hospitalEvent.completed_at}
                  required
                />
              </CCol>
              <CCol>
                <CFormInput
                  label="End Time"
                  type="time"
                  id="end_time"
                  onChange={handleEventTimeChange}
                  value={hospitalEvent.end_time ? pickerTimeDisplay(hospitalEvent.end_time) : undefined}
                  disabled={!!hospitalSheet.discharged_at || !!hospitalEvent.completed_at}
                  required
                />
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <CFormTextarea
                  label="Notes"
                  value={hospitalEvent.notes}
                  onChange={(event) => setHospitalEvent({ ...hospitalEvent, notes: event.target.value })}
                  disabled={!!hospitalSheet.discharged_at || !!hospitalEvent.completed_at}
                />
              </CCol>
              <CCol>
                <CFormTextarea
                  label="Value"
                  value={hospitalEvent.value}
                  onChange={(event) => setHospitalEvent({ ...hospitalEvent, value: event.target.value })}
                  disabled={!!hospitalSheet.discharged_at || !!hospitalEvent.completed_at}
                />
              </CCol>
            </CRow>

            {hospitalEvent.completed_at && hospitalEvent.completed_by_employee && (
              <CRow className="mb-3">
                <CCol>
                  Completed by {hospitalEvent.completed_by_employee.full_name_with_title} at{' '}
                  {compactDateTimeDisplay(hospitalEvent.completed_at)}
                </CCol>
              </CRow>
            )}

            {!hospitalSheet.discharged_at && !hospitalEvent.completed_at && (
              <CRow>
                <CCol>
                  <CButton color="primary" className="me-2" onClick={() => handleSubmit(hospitalEvent)}>
                    {hospitalEvent.id ? 'Update' : 'Create'}
                  </CButton>
                  {!hospitalEvent.completed_at && (
                    <CButton color="success" className="me-2" onClick={handleComplete}>
                      {!hospitalEvent.id && 'Create &'} Complete
                    </CButton>
                  )}
                  <CButton variant="outline" onClick={handleCancel}>
                    Cancel
                  </CButton>
                </CCol>
              </CRow>
            )}
          </div>
        </div>
        <CSidebarFooter>
          {hospitalEvent.updated_at && hospitalEvent.updated_at && (
            <CRow className="mb-3">
              <CCol>Last Updated {compactDateTimeDisplay(hospitalEvent.updated_at)}</CCol>
            </CRow>
          )}
        </CSidebarFooter>
      </CSidebar>
    </>
  );
};

export default HospitalEventForm;
