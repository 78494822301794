import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import {
  CCol,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow
} from '@coreui/react-pro';

import { emailDocument } from 'api/Documents';

import { Document } from 'types/Document';

import { useAuth } from 'hooks/useAuth';

type Props = {
  document: Document;
  onClose: () => void;
  onSuccess: (document: Document) => void;
};

export const EmailDocumentModal = ({ document, onClose, onSuccess }: Props) => {
  const auth = useAuth();
  const [isEmailingDocument, setIsEmailingDocument] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>(document.customer?.email || '');
  const [shouldSelfBCC, setShouldSelfBCC] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | undefined>();

  const handleSubmit = () => {
    if (!emailAddress.includes('@') || !emailAddress.includes('.')) {
      setEmailError('Email must be a valid email address.');
      return;
    } else {
      setEmailError(undefined);
    }

    setIsEmailingDocument(true);
    const bccAddress = shouldSelfBCC ? auth.employee?.email : undefined;
    emailDocument(document.id, { onSuccess: handleSuccess, onError: handleError }, emailAddress, bccAddress);
  };

  const handleSuccess = (document: Document) => {
    setIsEmailingDocument(false);
    toast.success('Document sent!');
    onSuccess(document);
  };

  const handleError = (message: string) => {
    toast.error(message);
    setIsEmailingDocument(false);
  };

  return (
    <CModal visible={true} onClose={onClose} backdrop="static">
      <CModalHeader>
        <h4>Confirm Email Address</h4>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CFormLabel htmlFor="emailAddress" className="col-sm-2 col-form-label">
            Email To:
          </CFormLabel>
          <CCol sm={10}>
            <CFormInput
              type="email"
              id="emailAddress"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              placeholder="Email Address"
              feedbackInvalid={emailError}
              invalid={emailError !== undefined}
            />
          </CCol>
        </CRow>

        <CFormCheck
          id="selfBCC"
          label="BCC myself"
          className="mt-3"
          onChange={(e) => setShouldSelfBCC(e.target.checked)}
          checked={shouldSelfBCC}
        />

        <CModalFooter className="p-0" style={{ border: 'none' }}>
          <CLoadingButton loading={isEmailingDocument} disabled={isEmailingDocument} onClick={handleSubmit}>
            Send
          </CLoadingButton>
        </CModalFooter>
      </CModalBody>
    </CModal>
  );
};
