import * as React from 'react';
import type { SVGProps } from 'react';

const SvgScale = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.8}
      d="M18.179 1.96A3 3 0 0 0 15.967 1H4.028C2.298 1 .89 2.509 1.007 4.236l.528 7.786c.023.339.55.303.526-.036L1.533 4.2c-.097-1.426 1.066-2.673 2.495-2.673h11.939c1.43 0 2.592 1.247 2.495 2.673l-.81 11.94a2.51 2.51 0 0 1-2.496 2.333H4.839a2.51 2.51 0 0 1-2.495-2.334l-.184-2.711c-.023-.34-.549-.304-.526.035l.184 2.712A3.04 3.04 0 0 0 4.838 19h10.318a3.04 3.04 0 0 0 3.022-2.825l.81-11.94a3 3 0 0 0-.81-2.274Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.8}
      d="M12.652 7.135c0 .145.118.263.264.263h1.055a.264.264 0 0 0 .263-.263c0-2.336-1.9-4.237-4.236-4.237a4.24 4.24 0 0 0-4.236 4.237c0 .145.118.263.263.263h5.45c.34 0 .34-.527 0-.527h-1.277L9.05 4.826c-.166-.296-.626-.038-.46.258l1.003 1.787H6.298c.057-.81.375-1.548.87-2.131l.362.361c.24.24.614-.132.373-.372l-.366-.367a3.7 3.7 0 0 1 1.212-.72l.132.492c.087.327.597.192.509-.137L9.257 3.5a3.7 3.7 0 0 1 1.407-.014l-.13.485c-.088.328.421.463.51.136l.13-.49c.452.152.866.388 1.222.69l-.349.35c-.24.24.133.612.373.372l.353-.353c.526.594.866 1.356.925 2.195h-.782a.264.264 0 0 0-.264.264Z"
    />
    <path
      fill="currentColor"
      d="M3.652 2.688a.933.933 0 0 0 0 1.863.933.933 0 0 0 0-1.864m0 1.335a.405.405 0 1 1 .001-.81.405.405 0 0 1 0 .81M16.344 4.55a.933.933 0 0 0 0-1.862.933.933 0 0 0 0 1.863m0-1.335a.405.405 0 1 1-.001.81.405.405 0 0 1 0-.81M3.53 16.24a.933.933 0 0 0 1.863 0 .933.933 0 0 0-1.864 0m.93-.404a.405.405 0 1 1 0 .81.405.405 0 0 1 0-.81M14.604 16.24a.933.933 0 0 0 1.863 0 .933.933 0 0 0-1.863 0m.931-.404a.405.405 0 1 1 0 .81.405.405 0 0 1 0-.81"
    />
  </svg>
);
export default SvgScale;
