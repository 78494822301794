import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDislike = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.524 2.572c-.984 0-1.97.399-2.709 1.175-1.478 1.551-1.478 4.043 0 5.595l1.122 1.177 4.486 4.71a.64.64 0 0 0 .931 0l4.488-4.71 1.121-1.177c1.479-1.552 1.479-4.044 0-5.595a3.735 3.735 0 0 0-5.418 0l-.656.688-.657-.688a3.74 3.74 0 0 0-2.708-1.175m6.73 1.275c.639 0 1.279.262 1.779.786 1 1.05 1 2.773 0 3.822L12.91 9.633 8.89 13.854 4.867 9.633 3.746 8.455c-1-1.05-1-2.773 0-3.822a2.43 2.43 0 0 1 1.778-.776c.64 0 1.278.251 1.778.776l1.12 1.178a.643.643 0 0 0 .932 0l1.122-1.178c.5-.524 1.138-.786 1.778-.786"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.643 0a.643.643 0 0 0-.455 1.097l16.715 16.715a.643.643 0 0 0 .909-.91L1.097.189A.64.64 0 0 0 .643 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDislike;
