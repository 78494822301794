import { Memo, MemoTree } from 'types/Memo';

export const getRecipients = (memo: Memo, currentEmployeeId?: number) => {
  return memo.memo_recipients
    .filter((recipient) => recipient.employee_id !== currentEmployeeId)
    .map((recipient) => recipient.employee.full_name_with_title)
    .join(', ');
};

export const hasReadMessage = (memo: Memo, currentEmployeeId?: number) => {
  return memo.memo_recipients.find((recipient) => recipient.employee_id === currentEmployeeId)?.read;
};

export const getUnreadCount = (memo: MemoTree, currentEmployeeId?: number): number => {
  if (!currentEmployeeId) return 0;
  let unreadCount = 0;

  if (Array.isArray(memo)) {
    for (const childMemo of memo) {
      unreadCount += getUnreadCount(childMemo, currentEmployeeId);
    }
  } else {
    if (!hasReadMessage(memo, currentEmployeeId)) unreadCount++;
  }

  return unreadCount;
};
