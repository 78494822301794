import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { CFormSelect, CSpinner } from '@coreui/react-pro';

import { updateAppointment } from 'api/Appointments';
import { fetchAppointmentStatuses } from 'api/AppointmentStatuses';

import { Appointment } from 'types/Appointment';
import { AppointmentStatus } from 'types/AppointmentStatus';

type Props = {
  appointment: Appointment;
};

const appointmentStatusToOption = (appointmentStatus: AppointmentStatus): string => appointmentStatus.name;

export const AppointmentStatusSelect = ({ appointment }: Props) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [appointmentStatusOptions, setAppointmentStatusOptions] = useState<string[]>();

  useEffect(() => {
    fetchAppointmentStatuses((statuses) => {
      setAppointmentStatusOptions(statuses.map(appointmentStatusToOption));
    });
  }, []);

  const [selectedAppointmentStatusOption, setSelectedAppointmentStatusOption] = useState<string | null>(
    appointment.status || null
  );

  const handleAppointmentStatusSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value;
    setSelectedAppointmentStatusOption(selected);

    setIsUpdating(true);
    updateAppointment(
      appointment.id,
      { status: selected },
      { onSuccess: handleUpdateSuccess, onError: handleUpdateError }
    );
  };

  const handleUpdateSuccess = () => {
    setIsUpdating(false);
    toast.success('Appointment status updated!');
  };

  const handleUpdateError = (error: string) => {
    setIsUpdating(false);
    toast.error(error);
  };

  return (
    <div className="d-flex align-items-center gap-2" style={{ position: 'relative' }}>
      <CFormSelect
        id="appointment_status"
        size="sm"
        aria-label="Appointment Status"
        className="text-truncate"
        disabled={isUpdating}
        options={appointmentStatusOptions}
        value={selectedAppointmentStatusOption ?? ''}
        onChange={handleAppointmentStatusSelectChange}
      />
      {isUpdating && (
        <CSpinner size="sm" color="primary" style={{ background: 'white', position: 'absolute', right: '13px' }} />
      )}
    </div>
  );
};
