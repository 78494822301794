import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { CCard, CCol, CFormCheck, CRow, CSpinner } from '@coreui/react-pro';

import { fetchAnimal } from 'api/Animals';
import { fetchClinic } from 'api/Clinics';
import { fetchConsult, fetchConsultsByAnimal } from 'api/Consults';
import { fetchCustomer, fetchCustomersByQuery } from 'api/Customers';
import { fetchEmployee, fetchEmployeesByQuery } from 'api/Employees';
import {
  fetchPrescriptionItem,
  fetchPrescriptionItemsByAnimal,
  fetchPrescriptionItemsForConsult
} from 'api/PrescriptionItems';
import { fetchVaccinationsByAnimal, fetchVaccinationsForConsult } from 'api/Vaccinations';

import { Animal } from 'types/Animal';
import { Clinic } from 'types/Clinic';
import { Consult } from 'types/Consult';
import { Customer } from 'types/Customer';
import { DocumentContextObjects } from 'types/DocumentContextObjects';
import { Employee } from 'types/Employee';
import { Option } from 'types/Option';
import { PrescriptionItem } from 'types/PrescriptionItem';

import { reactSelectStyles } from 'utils/reactSelect';
import {
  animalToOption,
  consultToOption,
  customerToOption,
  employeeToOption,
  prescriptionItemToOption,
  vaccinationToOption
} from 'utils/selectOptions';

type FormProps = {
  contextObjects: DocumentContextObjects;
  setContextObjects: (contextObjects: Partial<DocumentContextObjects>) => void;
};

const DocumentInputs = ({ contextObjects, setContextObjects }: FormProps): JSX.Element => {
  const [defaultConsults, setDefaultConsults] = useState<Option[] | undefined>(undefined);
  const [useConsultInfo, setUseConsultInfo] = useState<boolean>(contextObjects.consult !== undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (contextObjects.animal) {
      setIsLoading(true);
      fetchConsultsByAnimal(contextObjects.animal.id).then((options) => {
        setDefaultConsults(options.map(consultToOption));
        setIsLoading(false);
      });
    }
  }, [contextObjects.animal]);

  // when the consult is initially selected (or passed in),
  //  fetch all of the consult's info
  useEffect(() => {
    if (contextObjects.consult) fetchConsultInfo(contextObjects.consult);
    else if (contextObjects.animal) resetAnimalInfo(contextObjects.animal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextObjects.consult]);

  // even if a consult is selected, user might want to use all of the animal's info
  const toggleUseConsultInfo = () => {
    if (!useConsultInfo) {
      if (contextObjects.consult) fetchConsultInfo(contextObjects.consult);
    } else {
      if (contextObjects.animal) resetAnimalInfo(contextObjects.animal);
    }
    setUseConsultInfo(!useConsultInfo);
  };

  // reset back to all of the animal's info
  const resetAnimalInfo = (animal: Animal) => {
    setIsLoading(true);
    fetchPrescriptionItemsByAnimal(animal.id, (prescriptions) => {
      fetchVaccinationsByAnimal(animal.id, (vaccinations) => {
        setIsLoading(false);
        setContextObjects({
          ...contextObjects,
          vaccinations,
          prescriptions,
          clinic: contextObjects.clinic,
          customer: contextObjects.customer,
          employee: undefined
        });
      });
    });
  };

  // scope everything to the consult
  // I am so sorry for this. We have to get a bunch of info from the consult, but can
  //  only set the context object once, so we have to nest them...
  const fetchConsultInfo = (consult: Consult) => {
    setIsLoading(true);
    fetchEmployee(consult.employee_id, (employee: Employee) => {
      fetchClinic(consult.clinic_id, (clinic: Clinic) => {
        fetchPrescriptionItemsForConsult(consult.id, {
          onSuccess: (prescriptions) => {
            fetchVaccinationsForConsult(consult.id, (vaccinations) => {
              setIsLoading(false);
              setContextObjects({ ...contextObjects, vaccinations, prescriptions, employee, clinic });
            });
          }
        });
      });
    });
  };

  return (
    <CCard
      className="mb-3 p-3"
      style={{ background: 'var(--background-blue', boxShadow: 'none', border: '1px solid var(--primary-blue' }}
    >
      <CRow>
        <CCol>
          <h5>
            Inputs
            {isLoading && <CSpinner color="primary" size="sm" className="ms-2" />}
          </h5>
        </CCol>
      </CRow>
      <CRow className="mb-2">
        <CCol sm={3}>
          <label className="form-label mb-0">Customer</label>
          <AsyncSelect<Option>
            aria-label="Customer"
            placeholder="Search by name..."
            styles={reactSelectStyles}
            value={contextObjects?.customer && customerToOption(contextObjects.customer)}
            onChange={(option) =>
              option &&
              fetchCustomer(option.value, (customer: Customer) => setContextObjects({ ...contextObjects, customer }))
            }
            loadOptions={(inputValue: string, callback: (options: Option[]) => void) => {
              fetchCustomersByQuery(inputValue, { members: false }).then((options) => {
                callback(options.map(customerToOption));
              });
            }}
            isSearchable
          />
        </CCol>
        <CCol sm={3}>
          <label className="form-label mb-0">Animal</label>
          <Select<Option>
            aria-label="Animal"
            placeholder="Search by name..."
            styles={reactSelectStyles}
            value={contextObjects?.animal && animalToOption(contextObjects.animal)}
            onChange={(option) => {
              if (!option) return;
              fetchAnimal(option.value, (animal: Animal) => setContextObjects({ ...contextObjects, animal }));
            }}
            options={contextObjects.customer?.animals?.map(animalToOption)}
            isSearchable
          />
        </CCol>
        {defaultConsults && (
          <CCol sm={3}>
            <label className="form-label mb-0">Consult</label>
            <Select<Option>
              aria-label="Consult"
              placeholder="Search by ID..."
              styles={reactSelectStyles}
              value={contextObjects?.consult && consultToOption(contextObjects.consult)}
              onChange={(option) => {
                if (!option) setContextObjects({ ...contextObjects, consult: undefined });
                if (option)
                  fetchConsult(option.value, (consult: Consult) => {
                    setContextObjects({ ...contextObjects, consult });
                  });
              }}
              options={defaultConsults}
              isSearchable
              isClearable
            />
          </CCol>
        )}

        <CCol sm={3}>
          <label className="form-label mb-0">Vet/Employee</label>
          <AsyncSelect<Option>
            aria-label="Vet/Employee"
            placeholder="Search by name..."
            styles={reactSelectStyles}
            value={contextObjects?.employee && employeeToOption(contextObjects.employee)}
            onChange={(option) =>
              option &&
              fetchEmployee(option.value, (employee: Employee) => setContextObjects({ ...contextObjects, employee }))
            }
            loadOptions={(inputValue: string, callback: (options: Option[]) => void) => {
              fetchEmployeesByQuery(inputValue).then((options) => {
                callback(options.map(employeeToOption));
              });
            }}
            isSearchable
          />
        </CCol>
      </CRow>

      <CRow className="mb-1">
        {contextObjects.prescriptions && (
          <CCol sm={3}>
            <label className="form-label mb-0">Prescription</label>
            <Select<Option>
              aria-label="Prescription"
              placeholder="Select"
              styles={reactSelectStyles}
              value={contextObjects?.prescriptionItem && prescriptionItemToOption(contextObjects.prescriptionItem)}
              onChange={(option) =>
                option &&
                fetchPrescriptionItem(option.value, (item: PrescriptionItem) =>
                  setContextObjects({ ...contextObjects, prescriptionItem: item })
                )
              }
              isSearchable
            />
          </CCol>
        )}
        {contextObjects.vaccinations && (
          <CCol sm={3}>
            <label className="form-label mb-0">Vaccination</label>
            <Select<Option>
              aria-label="Vaccination"
              placeholder="Select"
              styles={reactSelectStyles}
              value={contextObjects?.vaccination && vaccinationToOption(contextObjects.vaccination)}
              onChange={(option) =>
                option &&
                setContextObjects({
                  ...contextObjects,
                  vaccination: contextObjects.vaccinations?.find((vax) => vax.id.toString() === option.value)
                })
              }
              options={contextObjects.vaccinations?.map(vaccinationToOption)}
              isSearchable
            />
          </CCol>
        )}
        <CCol sm={6} className="d-flex align-items-center">
          {contextObjects.consult && (
            <CFormCheck
              className="mb-2"
              label={"Use this consult's prescriptions/vaccinations"}
              checked={useConsultInfo}
              onChange={() => toggleUseConsultInfo()}
            />
          )}
        </CCol>
      </CRow>
    </CCard>
  );
};

export default DocumentInputs;
