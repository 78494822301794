import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.999 14.667c3.666 0 6.666-3 6.666-6.667s-3-6.666-6.666-6.666-6.667 3-6.667 6.666 3 6.667 6.667 6.667M5.332 8h5.333M8 10.667V5.334"
    />
  </svg>
);
export default SvgPlus;
