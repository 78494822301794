import axios from 'axios';

import { HospitalEventSchedule } from 'types/HospitalEventSchedule';

const fetchHospitalEventSchedule = (
  hospitalEventScheduleId: string | number,
  onSuccess: (HospitalEventSchedule: HospitalEventSchedule) => void
) => {
  const url = `/pim/hospital_event_schedules/${hospitalEventScheduleId}`;
  axios.get<HospitalEventSchedule>(url).then((data) => onSuccess(data.data.record));
};

const createHospitalEventSchedule = (
  params: Partial<HospitalEventSchedule>,
  onSuccess: (HospitalEventSchedule: HospitalEventSchedule) => void
) => {
  const url = `/pim/hospital_event_schedules`;
  axios
    .post<HospitalEventSchedule>(url, { hospital_event_schedule: params })
    .then((data) => onSuccess(data.data.record));
};

const updateHospitalEventSchedule = (
  hospitalEventScheduleId: string | number,
  params: Partial<HospitalEventSchedule>,
  onSuccess: (HospitalEventSchedule: HospitalEventSchedule) => void
) => {
  const url = `/pim/hospital_event_schedules/${hospitalEventScheduleId}`;
  axios
    .put<HospitalEventSchedule>(url, { hospital_event_schedule: params })
    .then((data) => onSuccess(data.data.record));
};

export { fetchHospitalEventSchedule, createHospitalEventSchedule, updateHospitalEventSchedule };
