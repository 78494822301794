import * as React from 'react';
import type { SVGProps } from 'react';

const SvgConversation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={19} fill="none" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M9.539 1.566a.414.414 0 0 0-.179-.557A6 6 0 0 0 6.618.35C3.445.35.85 2.804.85 5.837c0 1.76.876 3.39 2.355 4.423l-.643 1.927a.42.42 0 0 0 .114.438.42.42 0 0 0 .503.042l2.267-1.457a6.1 6.1 0 0 0 2.193.027 5 5 0 0 0-.026.518c0 3.032 2.595 5.486 5.769 5.486.394 0 .788-.038 1.172-.113l2.267 1.457a.42.42 0 0 0 .503-.043.42.42 0 0 0 .114-.437l-.642-1.927c1.478-1.032 2.354-2.663 2.354-4.423 0-3.033-2.595-5.487-5.768-5.487q-.517 0-1.02.087.025-.255.025-.518c0-1.537-.684-3.012-1.875-4.048l-.098.113.098-.113a.414.414 0 0 0-.544.625c1.012.88 1.59 2.129 1.59 3.423 0 2.56-2.208 4.658-4.94 4.658-.391 0-.782-.043-1.161-.13a.4.4 0 0 0-.316.056l-1.421.914.371-1.115a.414.414 0 0 0-.175-.483c-1.404-.867-2.238-2.326-2.238-3.9 0-2.56 2.208-4.658 4.94-4.658.827 0 1.644.196 2.363.566a.414.414 0 0 0 .558-.179Zm0 0-.133-.068zm6.37 14.572.371 1.114-1.42-.913a.4.4 0 0 0-.317-.056c-.379.086-.77.13-1.161.13-2.732 0-4.94-2.098-4.94-4.658q0-.37.06-.732c1.803-.594 3.205-2.017 3.695-3.791a5.2 5.2 0 0 1 1.185-.135c2.731 0 4.94 2.097 4.94 4.658 0 1.574-.834 3.032-2.238 3.9a.414.414 0 0 0-.175.483Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
      d="M13.385 11.35a2.17 2.17 0 0 0-2.166 2.166c0 1.092.81 1.744 2.166 1.744 1.357 0 2.166-.652 2.166-1.744a2.17 2.17 0 0 0-2.166-2.166Zm0 3.381c-.612 0-1.638-.158-1.638-1.215a1.64 1.64 0 0 1 1.638-1.638 1.64 1.64 0 0 1 1.638 1.638c0 1.057-1.026 1.215-1.638 1.215Zm-1.057-5.495c-.54 0-.898.668-.898 1.11a.9.9 0 0 0 1.796 0c0-.442-.358-1.11-.898-1.11Zm0 1.48a.37.37 0 0 1-.37-.37c0-.25.21-.581.37-.581.159 0 .37.331.37.58a.37.37 0 0 1-.37.37Zm2.109-1.48c-.54 0-.898.668-.898 1.11a.9.9 0 0 0 1.797 0c0-.442-.359-1.11-.899-1.11Zm0 1.48a.37.37 0 0 1-.37-.37c0-.25.211-.581.37-.581.16 0 .37.331.37.58a.37.37 0 0 1-.37.37Zm1.696-.212c-.54 0-.899.668-.899 1.11a.9.9 0 0 0 1.797 0c0-.442-.358-1.11-.898-1.11Zm0 1.48a.37.37 0 0 1-.37-.37c0-.25.21-.581.37-.581.159 0 .37.331.37.581a.37.37 0 0 1-.37.37Zm-5.5-1.48c-.54 0-.899.668-.899 1.11a.9.9 0 0 0 1.797 0c0-.442-.358-1.11-.898-1.11Zm0 1.48a.37.37 0 0 1-.37-.37c0-.25.21-.581.37-.581.159 0 .37.331.37.581a.37.37 0 0 1-.37.37Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.3}
      d="M9.157 4.577H7.88V3.301a.414.414 0 0 0-.414-.414h-1.69a.414.414 0 0 0-.415.414v1.276H4.085a.414.414 0 0 0-.415.415v1.69c0 .23.186.415.415.415H5.36v1.276c0 .229.186.414.414.414h1.691a.414.414 0 0 0 .414-.414V7.097h1.277a.414.414 0 0 0 .414-.415v-1.69a.414.414 0 0 0-.414-.415Zm-.414 1.691H7.466a.414.414 0 0 0-.414.414V7.96H6.19V6.682a.414.414 0 0 0-.415-.414H4.5v-.862h1.276a.414.414 0 0 0 .415-.414V3.715h.862v1.277c0 .228.185.414.414.414h1.277z"
    />
  </svg>
);
export default SvgConversation;
