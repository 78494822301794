import cn from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CBadge, CButton, CPopover } from '@coreui/react-pro';

import { fetchAnimalMasterProblemsByAnimal } from 'api/AnimalMasterProblems';

import { Animal, compactAge } from 'types/Animal';
import { AnimalMasterProblem } from 'types/AnimalMasterProblem';
import { HospitalSheet } from 'types/HospitalSheet';

import { compactDateDisplay } from 'utils/dates';

import { ReactComponent as DotIcon } from 'assets/images/dot.svg';

import { AnimalPhoto } from 'components/AnimalPhoto';

import styles from './ResourceOverviewCell.module.scss';

import { ReassignCaseOwners } from './ReassignCaseOwners';

type Props = {
  animal: Animal;
  hospitalSheet: HospitalSheet;
  day: string;
  fetchHospitalSheet: () => void;
};

export const HospitalSheetSignalment = ({ animal, hospitalSheet, day, fetchHospitalSheet }: Props): JSX.Element => {
  const [masterProblems, setMasterProblems] = useState<AnimalMasterProblem[]>([]);
  useEffect(() => {
    fetchAnimalMasterProblemsByAnimal(animal.id, (problems) =>
      setMasterProblems(problems.filter((problem) => problem.important))
    );
  }, [animal.id]);

  const spaceItems = (items: (string | React.ReactNode | undefined | null)[]) => {
    let hasFirstItem = false;
    return items.map((item) => {
      if (item === null || item === undefined) return null;
      const separatedItem = (
        <>
          {hasFirstItem && <DotIcon />}
          <span>{item}</span>
        </>
      );
      hasFirstItem = true;
      return separatedItem;
    });
  };

  const [showReassignCaseOwnerModal, setShowReassignCaseOwnerModal] = useState(false);
  const caseOwnerReassignedSuccess = () => {
    setShowReassignCaseOwnerModal(false);
    toast.success('Case owners reassigned!');
    fetchHospitalSheet();
  };

  return (
    <div style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'row' }}>
      <AnimalPhoto className="d-block" animal={animal} height={95} width={95} />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <div style={{}}>
            <h1 className="d-flex justify-content-between">
              <span>
                Whiteboard: <Link to={`/animals/${animal.id}`}>{animal.name}</Link>
              </span>
            </h1>
            <div className={styles.infoRow}>{spaceItems([animal.species?.name, animal.breed?.name])}</div>
            <div className={styles.infoRow}>
              {spaceItems([
                animal.sex?.name,
                `${animal.weight} ${animal.weight_unit}`,
                compactAge(animal),
                animal.fas_score
              ])}
            </div>
          </div>

          <div style={{ textAlign: 'right' }}>
            <h1>{day}</h1>
            <div className={cn(styles.infoRow, 'justify-content-end')}>
              {spaceItems([animal.fas_score, animal.resuscitate ? 'Resuscitate' : 'Do not resuscitate'])}
            </div>
            <div className={cn(styles.infoRow, 'justify-content-end')}>
              {spaceItems([
                hospitalSheet.case_owner?.full_name_with_title,
                hospitalSheet.assigned_nurse?.full_name_with_title,
                !hospitalSheet.discharged_at ? (
                  <CButton color="info" size="sm" key="reassign" onClick={() => setShowReassignCaseOwnerModal(true)}>
                    Reassign
                  </CButton>
                ) : null
              ])}
            </div>
          </div>
        </div>
        {masterProblems.length > 0 && (
          <div className={styles.infoRow} style={{ flexWrap: 'wrap' }}>
            Master Problems:
            {masterProblems.map((masterProblem) => (
              <CPopover
                key={masterProblem.id}
                title={
                  <Link to={`/master_problems/${masterProblem.master_problem_id}`}>
                    {masterProblem.master_problem.name}
                  </Link>
                }
                placement="bottom"
                trigger="click"
                content={
                  <div>
                    {masterProblem.internal_notes && (
                      <span>
                        <b>Internal Notes</b>
                        <p dangerouslySetInnerHTML={{ __html: masterProblem.internal_notes }}></p>
                      </span>
                    )}
                    {masterProblem.specifics && (
                      <span>
                        <b>Specifics</b>
                        <p dangerouslySetInnerHTML={{ __html: masterProblem.specifics }}></p>
                      </span>
                    )}
                    <p className="mb-0">
                      Added on {compactDateDisplay(masterProblem.created_at)} ({masterProblem.employee.last_name})
                    </p>
                  </div>
                }
              >
                <CBadge color="primary" style={{ cursor: 'pointer' }}>
                  {masterProblem.master_problem.name}
                </CBadge>
              </CPopover>
            ))}
          </div>
        )}
      </div>

      {showReassignCaseOwnerModal && hospitalSheet && (
        <ReassignCaseOwners
          hospitalSheet={hospitalSheet}
          hideModal={() => setShowReassignCaseOwnerModal(false)}
          onSuccess={caseOwnerReassignedSuccess}
        />
      )}
    </div>
  );
};

export default HospitalSheetSignalment;
