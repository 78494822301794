import * as React from 'react';

import { CModal, CModalBody, CModalHeader } from '@coreui/react-pro';

import { Animal } from 'types/Animal';
import { Context } from 'types/Context';

import { MessageForm } from 'components/MessageForm';

type Props = {
  animals?: Animal[];
  subjectAnimal?: Animal;
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  contexts: Context[];
};

export const NewMessageModal = ({ hideModal, ...rest }: Props) => (
  <CModal backdrop="static" className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
    <CModalHeader>
      <h4>New Message</h4>
    </CModalHeader>
    <CModalBody>
      <MessageForm hideModal={hideModal} {...rest} />
    </CModalBody>
  </CModal>
);
