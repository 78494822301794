import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5}>
      <path d="M9.946 2.7 3.787 9.217c-.232.248-.457.735-.502 1.073l-.278 2.43c-.098.877.533 1.477 1.402 1.327l2.415-.412c.338-.06.81-.308 1.043-.563l6.157-6.517c1.066-1.125 1.545-2.408-.112-3.975-1.65-1.553-2.902-1.005-3.968.12" />
      <path d="M8.918 3.787a4.595 4.595 0 0 0 4.088 3.863M2.25 16.5h13.5" />
    </g>
  </svg>
);
export default SvgPencil;
