import * as React from 'react';

import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CSmartTable } from '@coreui/react-pro';

import { FormattedLogEvent, LogEvent } from 'types/LogEvent';

import { formatLogItem } from 'utils/strings';

type Props = {
  events: LogEvent[];
  title: string;
};

export const Changelog = ({ events, title }: Props): JSX.Element => {
  const [formattedChangeLog, setFormattedChangeLog] = React.useState<FormattedLogEvent[]>([]);

  React.useEffect(() => {
    populateChangeLog(events);
  }, [events]);

  const populateChangeLog = (events: LogEvent[]) => {
    const formattedLogs: FormattedLogEvent[] = [];
    events.forEach((event) => {
      // Grab when, who, and first array of what from each event
      const whatSize = event.what.length;
      const firstLog = {
        class_name: event.class_name,
        when: event.when,
        who: event.who,
        what: event.what[0],
        _cellProps: {
          when: { color: 'light', rowSpan: whatSize, scope: 'rowgroup' },
          who: { color: 'light', rowSpan: whatSize, scope: 'rowgroup' }
        }
      };
      formattedLogs.push(firstLog);

      // Grab each subsequent array of what from each event
      const formattedEvents: FormattedLogEvent[] = event.what.slice(1).map((item) => {
        return {
          class_name: '',
          when: '',
          who: '',
          what: item
        };
      });
      formattedLogs.push(...formattedEvents);
    });
    setFormattedChangeLog(formattedLogs);
  };

  return (
    <CAccordion className="mt-3">
      <CAccordionItem itemKey={1}>
        <CAccordionHeader>{title}</CAccordionHeader>
        <CAccordionBody>
          <CSmartTable
            tableHeadProps={{ color: 'secondary' }}
            items={formattedChangeLog}
            itemsPerPage={25}
            pagination={true}
            tableProps={{
              bordered: true,
              'aria-label': title
            }}
            style={{ width: '100%' }}
            columns={[
              'when',
              { key: 'class_name', label: 'Type' },
              'who',
              { key: 'key', label: 'Field' },
              'from',
              'to'
            ]}
            scopedColumns={{
              who: (event: FormattedLogEvent) => {
                return <td className="py-2">{event.who}</td>;
              },
              when: (event: FormattedLogEvent) => {
                return <td className="py-2">{formatLogItem(event.when)}</td>;
              },
              class_name: (event: FormattedLogEvent) => {
                return <td className="py-2">{event.class_name}</td>;
              },
              key: (event: FormattedLogEvent) => (
                <td key={`key_${event.what.key}`} style={{ borderWidth: 1, borderColor: 'var(--cui-border-color)' }}>
                  {formatLogItem(event.what.key)}
                </td>
              ),
              from: (event: FormattedLogEvent) => (
                <td
                  key={`from_${event.what.from}`}
                  style={{ borderWidth: 1, borderColor: 'var(--cui-border-color)', wordBreak: 'break-word' }}
                >
                  {formatLogItem(event.what.from)}
                </td>
              ),
              to: (event: FormattedLogEvent) => (
                <td
                  key={`to_${event.what.to}`}
                  style={{ borderWidth: 1, borderColor: 'var(--cui-border-color)', wordBreak: 'break-word' }}
                >
                  {formatLogItem(event.what.to)}
                </td>
              )
            }}
          />
        </CAccordionBody>
      </CAccordionItem>
    </CAccordion>
  );
};
