import cn from 'classnames';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CFormCheck,
  CFormInput,
  CLink,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalHeader,
  CSmartTable
} from '@coreui/react-pro';

import { fetchMembershipCancelReasons } from 'api/CancelReasons';
import { cancelPlan, reactivatePlan, upgradePlan } from 'api/Memberships';

import { CancelReason } from 'types/CancelReason';
import { Customer } from 'types/Customer';
import { MembershipHistory } from 'types/MembershipHistory';

import { compactDateDisplay, dateDisplay, dateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

import { ReactComponent as CrownIcon } from 'assets/images/crown.svg';

import styles from './MembershipModal.module.scss';

type Props = {
  hideModal: () => void;
  customer: Customer;
  updateCustomer: () => void;
};

export const MembershipModal = ({ hideModal, customer, updateCustomer }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmState, setConfirmState] = useState<'cancel' | 'upgrade' | 'reactivate'>();

  const [cancelReasons, setCancelReasons] = useState<CancelReason[]>();
  const [selectedReasons, setSelectedReasons] = useState<number[]>([]);
  const otherReasonId = useMemo(() => cancelReasons?.find((r) => r.name === 'Other')?.id, [cancelReasons]);
  const [enableOtherReasonField, setEnableOtherReasonField] = useState<boolean>(false);
  const [reasonText, setReasonText] = useState<string>('');
  const [requiredItem, setRequiredItem] = useState<string>();

  const membershipHistories =
    customer.membership?.membership_histories?.map((membershipHistory: MembershipHistory) => {
      return {
        ...membershipHistory,
        name: membershipHistory.plan.name
      };
    }) ?? [];

  const handleUpgradePlan = () => {
    if (customer.membership && customer.membership.plan.upgrade_plan) {
      setIsLoading(true);
      upgradePlan(customer.membership.id, customer.membership.plan.upgrade_plan?.slug, () =>
        planUpdateSucceeded('Plan upgraded successfully!')
      );
    }
  };

  const selectCancel = () => {
    setConfirmState('cancel');
    fetchMembershipCancelReasons(setCancelReasons);
  };

  const handleCancelPlan = () => {
    if (selectedReasons.length === 0) {
      setRequiredItem('reason');
      return;
    } else if (otherReasonId && selectedReasons.includes(otherReasonId) && !reasonText) {
      setRequiredItem('text');
      return;
    } else {
      setRequiredItem(undefined);
    }

    if (customer.membership) {
      setIsLoading(true);
      cancelPlan(customer.membership.id, { cancelReasons: selectedReasons, cancelReasonText: reasonText }, () =>
        planUpdateSucceeded('Plan canceled successfully!')
      );
    }
  };

  const handleReactivatePlan = () => {
    if (customer.membership) {
      setIsLoading(true);
      reactivatePlan(customer.membership.id, () => planUpdateSucceeded('Plan reactivated successfully!'));
    }
  };

  const planUpdateSucceeded = (message: string) => {
    setIsLoading(false);
    updateCustomer();
    toast.success(message);
    setConfirmState(undefined);
  };

  const toggleReason = (selectedId: number | undefined, newValue: boolean) => {
    if (!selectedId) return;
    setRequiredItem(undefined);
    if (selectedReasons.includes(selectedId)) {
      if (selectedId === otherReasonId) {
        setEnableOtherReasonField(false);
      }
      setSelectedReasons(selectedReasons.filter((r) => r !== selectedId));
    } else {
      setSelectedReasons([...selectedReasons, selectedId]);
      if (selectedId === otherReasonId) {
        setEnableOtherReasonField(true);
      }
    }
  };

  const handleReasonUpdate = (reason: string) => {
    setRequiredItem(undefined);
    setReasonText(reason);
  };

  return (
    <CModal className="show d-block" backdrop="static" visible size="xl" fullscreen="sm" onClose={hideModal}>
      <CModalHeader>
        <h2 className="mb-0">Membership Plan</h2>
      </CModalHeader>
      <CModalBody className={styles.root}>
        <div className={styles.header}>
          <div className={styles.planDetails}>
            <div className={cn(styles.row, styles.bottomBorder)}>
              <div className={styles.left}>
                <div className={styles.badge}>
                  <CrownIcon />
                </div>

                <div className={styles.column}>
                  <h3 className="mb-0">{customer.membership?.plan.name}</h3>
                  {customer.membership?.current_period_end && (
                    <span>
                      {customer.membership?.status === 'canceled'
                        ? 'Ended On: '
                        : customer.membership?.cancel_at_period_end
                        ? 'Ends On: '
                        : 'Renews On: '}{' '}
                      {dateDisplay(customer.membership.current_period_end)}
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.right}>
                {customer.membership?.plan.is_upgradable && (
                  <CButton onClick={() => setConfirmState('upgrade')} shape="rounded-pill">
                    Upgrade Plan
                  </CButton>
                )}
                {!customer.membership?.plan.is_upgradable && customer.membership?.status === 'active' && (
                  <CButton onClick={selectCancel} shape="rounded-pill">
                    Cancel Membership
                  </CButton>
                )}
                {(customer.membership?.status === 'canceling' || customer.membership?.status === 'canceled') && (
                  <CButton onClick={() => setConfirmState('reactivate')} shape="rounded-pill">
                    Reactivate
                  </CButton>
                )}
              </div>
            </div>
            <div className={cn(styles.row, { [styles.noPadding]: customer.membership?.promo_code })}>
              <div className={styles.left}>
                <div className={styles.column}>Plan Price:</div>
                <h3 className="mb-0">{customer.membership && toCurrency(customer.membership.total_price)}</h3>
              </div>
              <div className={styles.right}>
                {customer.payment_provider_id && (
                  <CLink
                    href={`https://dashboard.stripe.com/customers/${customer.payment_provider_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View in Stripe
                  </CLink>
                )}
              </div>
            </div>
            {customer.membership?.promo_code && (
              <div className={styles.row}>
                <div className={cn(styles.left, styles.blueText)}>
                  <div className={styles.column}>Promo Code:</div>
                  <h3 className="mb-0">sdkjhfsd{customer.membership.promo_code}</h3>
                </div>
              </div>
            )}
          </div>

          {confirmState === 'upgrade' && (
            <div className={styles.confirmSection}>
              {customer.membership && (
                <span>
                  Upgrade subscription and charge <b>{toCurrency(customer.membership.upgrade_price)}</b> to card ending
                  in x{customer.membership.credit_card_last_4}?
                </span>
              )}
              <div className={styles.buttonRow}>
                <CButton variant="outline" shape="rounded-pill" onClick={() => setConfirmState(undefined)}>
                  Cancel
                </CButton>
                <CLoadingButton shape="rounded-pill" onClick={handleUpgradePlan} loading={isLoading}>
                  Confirm
                </CLoadingButton>
              </div>
            </div>
          )}

          {confirmState === 'cancel' && (
            <div className={styles.confirmSection}>
              <span>
                Cancel subscription? The customer&#39;s plan will not be renewed at the end of their subscription period
                ({customer.membership?.current_period_end && compactDateDisplay(customer.membership.current_period_end)}
                ).
              </span>
              {cancelReasons && (
                <div className={styles.cancelCheckmarks}>
                  <h3 className="d-flex justify-content-between">
                    Cancel Reason
                    {requiredItem && <div className={styles.requiredText}>{requiredItem} required</div>}
                  </h3>
                  <div className={styles.reasonsContainer}>
                    {cancelReasons.map((reason) => (
                      <CFormCheck
                        key={reason.id}
                        className={styles.checkmark}
                        id={reason.id?.toString()}
                        label={reason.name}
                        onChange={(event) => toggleReason(reason.id, event.target.checked)}
                        checked={reason.id !== undefined && selectedReasons.includes(reason.id)}
                      />
                    ))}
                  </div>
                  {enableOtherReasonField && (
                    <CFormInput
                      className={cn({ [styles.requiredInput]: requiredItem === 'text' })}
                      placeholder="Why is the customer canceling?"
                      value={reasonText}
                      onChange={(e) => handleReasonUpdate(e.target.value)}
                    />
                  )}
                </div>
              )}
              <div className={styles.buttonRow}>
                <CButton variant="outline" shape="rounded-pill" onClick={() => setConfirmState(undefined)}>
                  Cancel
                </CButton>
                <CLoadingButton shape="rounded-pill" onClick={handleCancelPlan} loading={isLoading}>
                  Confirm
                </CLoadingButton>
              </div>
            </div>
          )}

          {confirmState === 'reactivate' && (
            <div className={styles.confirmSection}>
              <span>
                Reactivate subscription? The customer&#39;s plan will be renewed at the end of their subscription period
                ({customer.membership?.current_period_end && compactDateDisplay(customer.membership.current_period_end)}
                ).
              </span>
              <div className={styles.buttonRow}>
                <CButton variant="outline" shape="rounded-pill" onClick={() => setConfirmState(undefined)}>
                  Cancel
                </CButton>
                <CLoadingButton shape="rounded-pill" onClick={handleReactivatePlan} loading={isLoading}>
                  Confirm
                </CLoadingButton>
              </div>
            </div>
          )}
        </div>
        <div className={styles.body}>
          <div className={cn(styles.row, styles.bottomBorder)}>
            <div className={styles.left}>Preferred Clinic</div>
            <div className={styles.right}>{customer.clinic?.name}</div>
          </div>
          <div className={cn(styles.row, styles.bottomBorder)}>
            <div className={styles.left}>Created On</div>
            <div className={styles.right}>{customer.membership && dateTimeDisplay(customer.membership.created_at)}</div>
          </div>
          <div className={cn(styles.row, styles.bottomBorder)}>
            <div className={styles.left}>Renewed On</div>
            <div className={styles.right}>
              {customer.membership?.current_period_start && dateTimeDisplay(customer.membership.current_period_start)}
            </div>
          </div>
          <div className={cn(styles.row, styles.bottomBorder)}>
            <div className={styles.left}>Upgraded On</div>
            <div className={styles.right}>
              <div className={styles.right}>
                {customer.membership?.upgraded_from_payg_at &&
                  dateTimeDisplay(customer.membership.upgraded_from_payg_at)}
              </div>
            </div>
          </div>
          <div className={cn(styles.row, styles.bottomBorder)}>
            <div className={styles.left}>Downgraded On</div>
            <div className={styles.right}>
              <div className={styles.right}>
                {customer.membership?.downgraded_to_payg_at &&
                  dateTimeDisplay(customer.membership.downgraded_to_payg_at)}
              </div>
            </div>
          </div>
          <div className={cn(styles.row, { [styles.noPadding]: customer.membership?.cancel_reasons })}>
            <div className={styles.left}>Status</div>
            <div className={styles.right}>
              <div
                className={cn({
                  [styles.pinkText]:
                    customer.membership?.status === 'canceling' || customer.membership?.status === 'canceled'
                })}
                style={{ textTransform: 'capitalize' }}
              >
                {customer.membership?.status}
              </div>
            </div>
          </div>
          {customer.membership?.cancel_reasons && customer.membership.cancel_reasons.length > 0 && (
            <div className={cn(styles.row, styles.cancelReasons)}>
              <div>{customer.membership.cancel_reasons.join(', ')}</div>
            </div>
          )}
          <div className={styles.row}>
            <CAccordion className={styles.membershipTable}>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader className={styles.membershipHistoryButton}>Membership History</CAccordionHeader>
                <CAccordionBody className="p-0 pt-2">
                  <CSmartTable
                    itemsPerPage={12}
                    pagination={membershipHistories.length > 12}
                    tableHeadProps={{ color: 'none' }}
                    items={membershipHistories}
                    tableProps={{
                      bordered: false,
                      'aria-label': 'Membership History'
                    }}
                    paginationProps={{ align: 'center', pages: membershipHistories.length }}
                    columns={['updated_at', { label: 'Plan Name', key: 'name' }, 'status']}
                    scopedColumns={{
                      updated_at: (membershipHistory: MembershipHistory) => {
                        return <td className="py-2">{dateTimeDisplay(membershipHistory.updated_at)}</td>;
                      },
                      name: (membershipHistory: MembershipHistory) => {
                        return <td className="py-2">{membershipHistory.plan.name}</td>;
                      },
                      status: (membershipHistory: MembershipHistory) => (
                        <td className="py-2" style={{ textTransform: 'capitalize' }}>
                          <div
                            className={cn({
                              [styles.pinkText]:
                                membershipHistory.status === 'canceling' || membershipHistory.status === 'canceled'
                            })}
                          >
                            {membershipHistory.status}
                          </div>
                        </td>
                      )
                    }}
                  />
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </div>
        </div>
      </CModalBody>
    </CModal>
  );
};
