import { portalUrl } from 'parsedEnv';
import * as React from 'react';
import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DocumentsList } from 'views/documents/DocumentsList';
import { NewDocumentModal } from 'views/documents/NewDocumentModal';

import { cilClock, cilEnvelopeClosed, cilExitToApp, cilFile, cilFolderOpen, cilSync } from '@coreui/icons';
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CNav,
  CNavItem,
  CNavLink
} from '@coreui/react-pro';

import { fetchAppointmentsByCustomer } from 'api/Appointments';
import { fetchClinic } from 'api/Clinics';
import { fetchCommunicationLogsByCustomer } from 'api/CommunicationLogs';
import { fetchContextsForCustomer } from 'api/Contexts';
import { createLoginToken, fetchCustomer, resyncCustomer, triggerResetPasswordEmail } from 'api/Customers';
import { fetchDocumentsByCustomer } from 'api/Documents';
import { fetchEstimatesForCustomer } from 'api/Estimates';
import { fetchInvoicesForCustomer, syncRecentInvoices } from 'api/Invoices';

import { Appointment } from 'types/Appointment';
import { Clinic } from 'types/Clinic';
import { CommunicationLog } from 'types/CommunicationLog';
import { Context } from 'types/Context';
import { Customer } from 'types/Customer';
import { CustomerDetailsTab, customerDetailsTabs } from 'types/CustomerDetailsTab';
import { Document } from 'types/Document';
import { Estimate, estimateStatuses } from 'types/Estimate';
import { Invoice, invoiceStatuses } from 'types/Invoice';
import { ListFilter, transformFilters } from 'types/ListFilter';
import { Pagination } from 'types/Pagination';
import { topics } from 'types/Topic';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { ClinicContext } from 'contexts/ClinicContext';

import { toOption } from 'utils/selectOptions';
import { pluralize } from 'utils/strings';

import { CommunicationLogList } from 'components/CommunicationLogList';

import styles from './Details.module.scss';

import { AccountDetails } from './AccountDetails';
import { AppointmentsList } from './AppointmentsList';
import { BillingDetails } from './BillingDetails';
import { EstimatesList } from './EstimatesList';
import { InfoCard } from './InfoCard';
import { InternalNote } from './InternalNote';
import { InvoicesList } from './InvoicesList';
import { PatientCard } from './PatientCard';

export type Table = (typeof customerDetailsTabs)[number];

const Details = () => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [customer, setCustomer] = useState<Customer>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const scrollRef = createRef<HTMLDivElement>();

  const scrollUp = () => scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const { clinicContext } = useContext(ClinicContext);
  const [clinic, setClinic] = useState<Clinic>();

  const [appointments, setAppointments] = useState<Appointment[]>();
  const [appointmentsPagination, setAppointmentsPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });
  const [appointmentsFilters, setAppointmentsFilters] = useState<ListFilter[]>([]);

  const [invoices, setInvoices] = useState<Invoice[]>();
  const [invoicesPagination, setInvoicesPagination] = useState<Pagination>({ page: 1, perPage: 12, total: 1 });
  const [invoicesFilters, setInvoicesFilters] = useState<ListFilter[]>([
    { name: 'Start Date', key: 'start_date', value: undefined, type: 'date' },
    { name: 'End Date', key: 'end_date', value: undefined, type: 'date' },
    { name: 'Status', key: 'status', value: undefined, type: 'select', options: ['', ...invoiceStatuses] },
    { name: 'Payment Status', key: 'payment_status', value: undefined, type: 'select', options: ['', 'paid', 'unpaid'] }
  ]);

  const [estimates, setEstimates] = useState<Estimate[]>();
  const [estimatesPagination, setEstimatesPagination] = useState<Pagination>({ page: 1, perPage: 12, total: 1 });
  const [estimatesFilters, setEstimatesFilters] = useState<ListFilter[]>([
    { name: 'Start Date', key: 'start_date', value: undefined, type: 'date' },
    { name: 'End Date', key: 'end_date', value: undefined, type: 'date' },
    { name: 'Status', key: 'status', value: undefined, type: 'select', options: ['', ...estimateStatuses] }
  ]);

  const communicationLogListRef = useRef<HTMLDivElement>(null);
  const [communicationLogs, setCommunicationLogs] = useState<CommunicationLog[]>();
  const [communicationLogsPagination, setCommunicationLogsPagination] = useState<Pagination>({
    page: 1,
    perPage: 12,
    total: 1
  });
  const [communicationsFilters, setCommunicationsFilters] = useState<ListFilter[]>([]);

  const [documents, setDocuments] = useState<Document[]>();
  const [documentsPagination, setDocumentsPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });
  const [documentsFilters, setDocumentsFilters] = useState<ListFilter[]>([
    { name: 'Start Date', key: 'start_date', value: undefined, type: 'date' },
    { name: 'End Date', key: 'end_date', value: undefined, type: 'date' },
    { name: 'Status', key: 'status', value: undefined, type: 'select', options: ['', 'signed', 'unsigned'] },
    { name: 'Name', key: 'name', value: undefined, type: 'input' }
  ]);

  const [contexts, setContexts] = useState<Context[]>();

  const [showNewDocumentModal, setShowNewDocumentModal] = useState<boolean>(false);

  const [currentTable, setCurrentTable] = useState<CustomerDetailsTab>(customerDetailsTabs[0]);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  useDocumentTitle('Customer', customer && `${customer.first_name} ${customer.last_name}`);

  useEffect(() => {
    clinicContext && fetchClinic(clinicContext, setClinic);
  }, [clinicContext]);

  useEffect(() => {
    fetchCustomer(id, setCustomer);
    fetchAppointmentsByCustomer(id, {
      params: {},
      onSuccess: setAppointments,
      setPagination: setAppointmentsPagination
    });
    fetchCommunicationLogsByCustomer(id, {}, setCommunicationLogs, setCommunicationLogsPagination);
    fetchContextsForCustomer(id, setContexts);
    fetchEstimatesForCustomer(id, {}, setEstimates, setEstimatesPagination);
    fetchInvoicesForCustomer(id, {}, setInvoices, setInvoicesPagination);
    fetchDocumentsByCustomer(id, { params: {}, onSuccess: setDocuments, setPagination: setDocumentsPagination });
  }, [id]);

  useEffect(() => {
    if (customer?.animals && customer.animals.length > 0) {
      const defaultFilters: ListFilter[] = [
        { name: 'Start Date', key: 'start_date', value: undefined, type: 'date' },
        { name: 'End Date', key: 'end_date', value: undefined, type: 'date' },
        { name: 'Topic', key: 'topic', value: undefined, type: 'select', options: ['', ...topics] }
      ];

      const animals = customer.animals.map(toOption);
      defaultFilters.push({
        name: 'Animal',
        key: 'animal_id',
        value: undefined,
        type: 'select',
        options: [{ value: '', label: '' }, ...animals]
      });
      setCommunicationsFilters(defaultFilters);
    }
  }, [customer]);

  useEffect(() => {
    let tabFromParams = searchParams.get('tab');

    if (!tabFromParams || !customerDetailsTabs.includes(tabFromParams as CustomerDetailsTab)) {
      tabFromParams = customerDetailsTabs[0];
    }

    setCurrentTable(tabFromParams as CustomerDetailsTab);
  }, [searchParams, setSearchParams]);

  const handleTableClick = (tab: CustomerDetailsTab) => {
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  };

  const displayResetPasswordMessage = () => {
    setIsLoading(false);
    toast.success('Reset password email sent!');
  };

  const handleOpenBecomeCustomer = (token: string) => {
    setIsLoading(false);

    if (customer?.email) {
      const becomeCustomerUrl = new URL(
        `${portalUrl}/logout?token=${token}&email=${encodeURIComponent(customer.email)}`
      );

      window.open(becomeCustomerUrl, '_blank');
    }
  };

  const handlePlanChanged = () => {
    fetchCustomer(id, setCustomer);
  };

  const handleResetPassword = () => {
    setIsLoading(true);
    triggerResetPasswordEmail(id, displayResetPasswordMessage);
  };

  const handleBecomeCustomer = () => {
    setIsLoading(true);
    createLoginToken(id, handleOpenBecomeCustomer);
  };

  const handleOpenInEzyVet = () => {
    if (customer) {
      window.open(`https://drtreat.usw2.ezyvet.com/?recordclass=Contact&recordid=${customer.pim_id}`, '_blank');
    }
  };

  const handleUpdateCommunicationLogPage = (page: number) => {
    setCommunicationLogsPagination((prev) => ({ ...prev, page }));
    fetchCommunicationLogsByCustomer(
      id,
      transformFilters(communicationsFilters, { page: page }),
      setCommunicationLogs,
      setCommunicationLogsPagination
    );

    if (communicationLogListRef?.current) {
      communicationLogListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleFetchCommunicationLogs = () => {
    setCommunicationLogsPagination((prev) => ({ ...prev, page: 1 }));
    fetchCommunicationLogsByCustomer(
      id,
      transformFilters(communicationsFilters, { page: 1 }),
      setCommunicationLogs,
      setCommunicationLogsPagination
    );
  };

  const handleResyncInvoices = () => {
    setIsLoading(true);

    if (customer) {
      syncRecentInvoices(customer.id, displayInvoiceResyncSucceeded);
    }
  };

  const displayInvoiceResyncSucceeded = (invoices: Invoice[]) => {
    setIsLoading(false);

    if (customer) {
      setInvoices(invoices);
    }

    toast.success('Invoices resynced!');
  };

  const handleUpdateInvoicePage = (page: number) => {
    setInvoicesPagination((prev) => ({ ...prev, page }));
    const paidFilter = invoicesFilters.find((filter) => filter.key === 'payment_status');
    let initial;
    if (paidFilter?.value !== '') {
      initial = { fully_paid: paidFilter?.value === 'paid', page: page };
    } else {
      initial = { page: page };
    }
    fetchInvoicesForCustomer(id, transformFilters(invoicesFilters, initial), setInvoices, setInvoicesPagination);
  };

  const handleUpdateEstimatePage = (page: number) => {
    setEstimatesPagination((prev) => ({ ...prev, page }));
    fetchEstimatesForCustomer(
      id,
      transformFilters(estimatesFilters, { page: page }),
      setEstimates,
      setEstimatesPagination
    );
  };

  const handleEstimatesFilterChange = (filters: ListFilter[]) => {
    setEstimatesFilters(filters);
    fetchEstimatesForCustomer(id, transformFilters(filters, {}), setEstimates, setEstimatesPagination);
  };

  const handleInvoicesFilterChange = (filters: ListFilter[]) => {
    setInvoicesFilters(filters);
    const paidFilter = filters.find((filter) => filter.key === 'payment_status');
    let initial;
    if (paidFilter?.value !== '') {
      initial = { fully_paid: paidFilter?.value === 'paid' };
    } else {
      initial = {};
    }
    fetchInvoicesForCustomer(id, transformFilters(filters, initial), setInvoices, setInvoicesPagination);
  };

  const handleResyncCustomer = () => {
    setIsLoading(true);

    if (customer) {
      resyncCustomer(id, displayCustomerResyncSucceeded);
    }
  };

  const displayCustomerResyncSucceeded = (customer: Customer, message: string) => {
    setIsLoading(false);

    if (customer) {
      setCustomer(customer);
    }

    toast.success(message);
  };

  const handleDocumentSuccess = (document: Document) => {
    setShowNewDocumentModal(false);
    navigate(`/documents/${document.id}`);
  };

  const actionsMenuItems = (_customer: Customer) => {
    return [
      {
        label: 'Resync Recent Invoices',
        icon: cilSync,
        onClick: handleResyncInvoices
      },
      {
        label: 'Open in EzyVet',
        icon: cilExitToApp,
        onClick: handleOpenInEzyVet
      },
      {
        label: 'Become Customer',
        icon: cilFolderOpen,
        onClick: handleBecomeCustomer
      },
      {
        label: 'Send Reset Password Email',
        icon: cilEnvelopeClosed,
        onClick: handleResetPassword
      },
      {
        label: 'Full Resync',
        icon: cilClock,
        onClick: handleResyncCustomer
      },
      {
        label: 'New Document',
        icon: cilFile,
        onClick: () => setShowNewDocumentModal(true)
      }
    ];
  };

  const handleAppointmentsActivePageChange = (page: number) => {
    setAppointmentsPagination({ ...appointmentsPagination, page });
    fetchAppointmentsByCustomer(id, {
      params: transformFilters(appointmentsFilters, { page: page }),
      onSuccess: setAppointments,
      setPagination: setAppointmentsPagination
    });
  };

  const handleCommunicationsFilterChange = (filters: ListFilter[]) => {
    setCommunicationsFilters(filters);
    fetchCommunicationLogsByCustomer(
      id,
      transformFilters(filters),
      setCommunicationLogs,
      setCommunicationLogsPagination
    );
  };

  const handleAppointmentsFilterChange = (filters: ListFilter[]) => {
    setAppointmentsFilters(filters);
    fetchAppointmentsByCustomer(id, {
      params: transformFilters(filters),
      onSuccess: setAppointments,
      setPagination: setAppointmentsPagination
    });
  };

  const handleDocumentsFilterChange = (filters: ListFilter[]) => {
    setDocumentsFilters(filters);
    const signedFilter = filters.find((filter) => filter.key === 'status');
    let initial;
    if (signedFilter?.value !== '') {
      initial = { signed: signedFilter?.value === 'signed' };
    } else {
      initial = {};
    }
    fetchDocumentsByCustomer(id, {
      params: transformFilters(filters, initial),
      onSuccess: setDocuments,
      setPagination: setDocumentsPagination
    });
  };

  const handleDocumentsPageChange = (page: number) => {
    setDocumentsPagination({ ...documentsPagination, page });
    const signedFilter = documentsFilters.find((filter) => filter.key === 'status');
    let initial;
    if (signedFilter?.value !== '') {
      initial = { animal_id: id, signed: signedFilter?.value === 'signed', page: page };
    } else {
      initial = { animal_id: id, page: page };
    }
    fetchDocumentsByCustomer(id, {
      params: transformFilters(documentsFilters, initial),
      onSuccess: setDocuments,
      setPagination: setDocumentsPagination
    });
  };

  const renderTable = (table: Table) => {
    switch (table) {
      case 'appointments':
        return (
          <AppointmentsList
            appointments={appointments}
            filters={appointmentsFilters}
            handleFilterChange={handleAppointmentsFilterChange}
            handleActivePageChange={handleAppointmentsActivePageChange}
            pagination={appointmentsPagination}
          />
        );
      case 'communications':
        if (customer) {
          return (
            <CommunicationLogList
              communicationLogs={communicationLogs}
              contexts={contexts}
              customer={customer}
              fetchCommunicationLogs={handleFetchCommunicationLogs}
              filters={communicationsFilters}
              handleFilterChange={handleCommunicationsFilterChange}
              pagination={communicationLogsPagination}
              ref={communicationLogListRef}
              updatePage={handleUpdateCommunicationLogPage}
            />
          );
        }
        break;
      case 'documents':
        return (
          <DocumentsList
            documents={documents}
            pagination={documentsPagination}
            handleCreateNewDocument={() => setShowNewDocumentModal(true)}
            updatePage={handleDocumentsPageChange}
            filters={documentsFilters}
            handleFilterChange={handleDocumentsFilterChange}
          />
        );
      case 'invoices':
        if (customer) {
          return (
            <InvoicesList
              invoices={invoices}
              customer={customer}
              fetchInvoices={() => fetchInvoicesForCustomer(id, {}, setInvoices, setInvoicesPagination)}
              handleResyncInvoices={handleResyncInvoices}
              isLoading={isLoading}
              pagination={invoicesPagination}
              updatePage={handleUpdateInvoicePage}
              filters={invoicesFilters}
              handleFilterChange={handleInvoicesFilterChange}
              scrollUp={scrollUp}
            />
          );
        }
        break;
      case 'treatment-plans':
        if (customer) {
          return (
            <EstimatesList
              fetchEstimates={() => fetchEstimatesForCustomer(id, {}, setEstimates, setEstimatesPagination)}
              estimates={estimates}
              customer={customer}
              pagination={estimatesPagination}
              updatePage={handleUpdateEstimatePage}
              filters={estimatesFilters}
              handleFilterChange={handleEstimatesFilterChange}
              scrollUp={scrollUp}
            />
          );
        }
        break;
      default:
        return null;
    }
  };

  if (!customer) return null;

  return (
    <div className="d-flex" style={{ gap: '30px' }}>
      <div className={styles.mainPage}>
        <div className="d-flex flex-row" style={{ gap: '23px', position: 'relative' }}>
          <InfoCard
            customer={customer}
            actionsMenuItems={actionsMenuItems(customer)}
            updateCustomer={handlePlanChanged}
          />
          <InternalNote customer={customer} onNoteSaved={setCustomer} />
        </div>

        <CNav variant="pills" className="mt-3" style={{ marginBottom: '19px' }} ref={scrollRef}>
          {[
            customerDetailsTabs.map((table) => (
              <CNavItem key={table}>
                <CNavLink
                  role="button"
                  className="text-capitalize"
                  onClick={() => handleTableClick(table)}
                  active={table === currentTable}
                >
                  {table.split('-').join(' ')}
                </CNavLink>
              </CNavItem>
            ))
          ]}
        </CNav>

        {renderTable(currentTable)}

        {showNewDocumentModal && (
          <NewDocumentModal
            onClose={() => setShowNewDocumentModal(false)}
            docContextObjects={{ customer: customer, clinic: clinic }}
            onSuccess={handleDocumentSuccess}
            isVisible={showNewDocumentModal}
          />
        )}
      </div>
      <div className={styles.sidePanel}>
        <div className={styles.mainContent}>
          <h2 className="m-0">{customer.animals && pluralize('Patient', customer.animals.length)}</h2>
          {customer.animals?.map((animal) => (
            <PatientCard key={animal.id} animal={animal} />
          ))}

          <h2 className="m-0">Billing</h2>
          <BillingDetails customer={customer} />
        </div>

        <CAccordion className={styles.accountAccordion}>
          <CAccordionItem itemKey={1} className={styles.accordionItem}>
            <CAccordionHeader>
              <h2 className="m-0">Account Details</h2>
            </CAccordionHeader>
            <CAccordionBody className="p-0">
              <AccountDetails customer={customer} />
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </div>
    </div>
  );
};

export default Details;
