import axios from 'axios';

import { PetSpecies } from 'types/PetSpecies';

const fetchSpecies = (onSuccess: (species: PetSpecies[]) => void) => {
  const url = `/pim/species`;
  axios.get<PetSpecies[]>(url).then((data) => onSuccess(data.data.record));
};

export { fetchSpecies };
