import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDrawPicture = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.783 6.425a1.285 1.285 0 1 0 0-2.57 1.285 1.285 0 0 0 0 2.57"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.352 1.285h-2.57c-3.212 0-4.497 1.285-4.497 4.497v3.855c0 3.213 1.285 4.498 4.497 4.498h3.855c3.213 0 4.498-1.285 4.498-4.498V6.425"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12.297 1.664 9.965 3.996a.75.75 0 0 0-.193.392l-.128.893c-.045.322.18.547.501.502l.893-.129a.83.83 0 0 0 .392-.193l2.332-2.332c.405-.405.591-.867 0-1.458-.597-.604-1.06-.412-1.465-.007"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M11.963 1.998c.2.707.752 1.26 1.459 1.459"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m1.716 12.176 3.167-2.127c.508-.34 1.24-.302 1.696.09l.212.186c.501.43 1.311.43 1.812 0l2.673-2.294c.501-.43 1.31-.43 1.812 0l1.047.9"
    />
  </svg>
);
export default SvgDrawPicture;
