import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';

import { createMasterProblem } from 'api/MasterProblems';

import { MasterProblem } from 'types/MasterProblem';

import Form from './Form';

const MasterProblemNew = (): JSX.Element => {
  const navigate = useNavigate();
  const [masterProblem, setMasterProblem] = useState<MasterProblem>({ status: 'active' } as MasterProblem);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.type === 'checkbox') {
      setMasterProblem({
        ...masterProblem,
        [event.target.id]: 'checked' in event.target ? (event.target.checked ? 'active' : 'disabled') : null
      });
    } else {
      setMasterProblem({ ...masterProblem, [event.target.id]: event.target.value });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, errorCallback: (error: string) => void) => {
    createMasterProblem(masterProblem, updateSuccess, errorCallback);
  };

  const updateSuccess = (masterProblem: MasterProblem) => {
    navigate(`/master_problems/${masterProblem.id}`);
  };

  const handleCancel = () => {
    navigate(`/master_problems`);
  };

  return (
    <CCard style={{ width: '35rem' }}>
      <CCardHeader>
        <h3>New Master Problem</h3>
      </CCardHeader>
      <CCardBody>
        <Form
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleChange={handleChange}
          masterProblem={masterProblem}
        />
      </CCardBody>
    </CCard>
  );
};

export default MasterProblemNew;
