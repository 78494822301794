import * as React from 'react';
import { createRef, useEffect, useState } from 'react';
import { generatePath, Link, useLocation, useParams } from 'react-router-dom';
import { paths } from 'routes';

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchAnimalHealthPlans } from 'api/AnimalHealthPlans';
import { fetchAnimalMasterProblemsByAnimal } from 'api/AnimalMasterProblems';
import { fetchAnimal } from 'api/Animals';
import { fetchAssessmentsByAnimal } from 'api/Assessments';
import { fetchConsultPlansByAnimal } from 'api/ConsultPlans';
import { fetchDiagnosticRequestsByAnimal } from 'api/DiagnosticRequests';
import { fetchHistoriesByAnimal } from 'api/Histories';
import { fetchPhysicalExamsByAnimal } from 'api/PhysicalExams';
import { fetchPrescriptionItemsByAnimal } from 'api/PrescriptionItems';
import { fetchProceduresByAnimal } from 'api/Procedures';
import { fetchVaccinationsByAnimal } from 'api/Vaccinations';

import { Animal } from 'types/Animal';
import { AnimalHealthPlan } from 'types/AnimalHealthPlan';
import { AnimalMasterProblem } from 'types/AnimalMasterProblem';
import { Assessment } from 'types/Assessment';
import { ConsultDetailsStep } from 'types/ConsultDetailsStep';
import { ConsultPlan } from 'types/ConsultPlan';
import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { History } from 'types/History';
import { MedicalHistoryItem } from 'types/MedicalHistoryItem';
import { medicalHistoryTabs } from 'types/MedicalHistoryTabs';
import { PhysicalExam } from 'types/PhysicalExam';
import { Prescription } from 'types/Prescription';
import { PrescriptionItem } from 'types/PrescriptionItem';
import { Procedure } from 'types/Procedure';
import { Vaccination } from 'types/Vaccination';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { compactDateDisplay, compactDateTimeDisplay } from 'utils/dates';

import { DiagnosticRequestDetails } from 'components/DiagnosticRequestDetails';
import { MedicalHistoryTable } from 'components/MedicalHistoryTable';
import { Pill } from 'components/Pill';
import { Signalment } from 'components/Signalment';

const MedicalHistory = (): JSX.Element => {
  type MedicalHistoryParams = {
    id: string;
  };

  const { id } = useParams<keyof MedicalHistoryParams>() as MedicalHistoryParams;

  const [animal, setAnimal] = useState<Animal>();
  const [consultPlans, setConsultPlans] = useState<ConsultPlan[]>();
  const [animalMasterProblems, setAnimalMasterProblems] = useState<AnimalMasterProblem[]>();
  const [prescriptionItems, setPrescriptionItems] = useState<PrescriptionItem[]>();
  const [healthPlans, setHealthPlans] = useState<AnimalHealthPlan[]>();
  const [vaccinations, setVaccinations] = useState<Vaccination[]>();
  const [assessments, setAssessments] = useState<Assessment[]>();
  const [procedures, setProcedures] = useState<Procedure[]>();
  const [physicalExams, setPhysicalExams] = useState<PhysicalExam[]>();
  const [histories, setHistories] = useState<History[]>();
  const [diagnosticRequests, setDiagnosticRequests] = useState<DiagnosticRequest[]>();

  useDocumentTitle('Medical History', animal?.name);

  const location = useLocation();
  const scrollRef = createRef<HTMLDivElement>();
  const scrollUp = () => scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

  useEffect(() => {
    fetchAnimal(id, setAnimal);
    fetchConsultPlansByAnimal(id, setConsultPlans);
    fetchAnimalMasterProblemsByAnimal(id, setAnimalMasterProblems);
    fetchPrescriptionItemsByAnimal(id, setPrescriptionItems);
    fetchAnimalHealthPlans({ animal_id: id }, setHealthPlans);
    fetchVaccinationsByAnimal(id, setVaccinations);
    fetchAssessmentsByAnimal(id, setAssessments);
    fetchProceduresByAnimal(id, setProcedures);
    fetchPhysicalExamsByAnimal(id, setPhysicalExams);
    fetchHistoriesByAnimal(id, setHistories);
    fetchDiagnosticRequestsByAnimal(id, setDiagnosticRequests);
  }, [id]);

  const updatedAt = (
    item: Exclude<MedicalHistoryItem, Prescription>,
    step?: ConsultDetailsStep,
    consult_id?: number | string | null
  ) => (
    <td>
      {item.updated_by_employee && <div>{item.updated_by_employee.full_name_with_title}</div>}
      {consult_id ? (
        <Link
          to={{
            pathname: generatePath(paths.consultDetails, { id: consult_id }),
            ...(step && { search: `?step=${step}` })
          }}
        >
          {compactDateTimeDisplay(item.updated_at)}
        </Link>
      ) : (
        compactDateTimeDisplay(item.updated_at)
      )}
    </td>
  );

  const handleAnimalUpdate = () => {
    fetchAnimal(id, setAnimal);
  };

  const status = {
    status: (item: MedicalHistoryItem) => (
      <td>
        <Pill label={item.status} />
      </td>
    )
  };

  const applyAnimalMasterProblemStyles = (item: AnimalMasterProblem) => {
    return { ...item, ...(item.important && { _props: { color: 'info' } }) };
  };

  const applyPrescriptionItemStyles = (item: PrescriptionItem) => {
    return { ...item, ...(item.current && { _props: { color: 'info' } }) };
  };

  const isLoading =
    !animal ||
    !consultPlans ||
    !animalMasterProblems ||
    !prescriptionItems ||
    !healthPlans ||
    !assessments ||
    !vaccinations ||
    !procedures ||
    !physicalExams ||
    !histories ||
    !diagnosticRequests;

  return (
    <CCard>
      <CCardHeader ref={scrollRef}>
        <h1>Medical History</h1>
      </CCardHeader>

      <CCardBody>
        <CContainer fluid>
          {isLoading ? (
            <CSpinner color="primary" />
          ) : (
            <>
              <CRow className="mb-3">
                <CCol>
                  <Signalment onUpdateSuccess={handleAnimalUpdate} animal={animal} showPatientNotes />
                </CCol>

                <CCol>
                  <div>
                    <div className="mb-3 h5 d-flex align-items-center">
                      <h2>Client</h2>
                    </div>

                    <CTable aria-label="Client" small borderless>
                      <CTableBody>
                        {[animal.customer.first_name, animal.customer.last_name, animal.customer.id].every(
                          (attr) => attr
                        ) && (
                          <CTableRow>
                            <CTableHeaderCell>Name</CTableHeaderCell>
                            <CTableDataCell>
                              <Link to={`/customers/${animal.customer.id}`}>
                                {[animal.customer.first_name, animal.customer.last_name].join(' ')}
                              </Link>
                            </CTableDataCell>
                          </CTableRow>
                        )}

                        {animal.customer.phone && (
                          <CTableRow>
                            <CTableHeaderCell>Phone</CTableHeaderCell>
                            <CTableDataCell>{animal.customer.phone}</CTableDataCell>
                          </CTableRow>
                        )}

                        {animal.customer.email && (
                          <CTableRow>
                            <CTableHeaderCell>Email</CTableHeaderCell>
                            <CTableDataCell>{animal.customer.email}</CTableDataCell>
                          </CTableRow>
                        )}
                      </CTableBody>
                    </CTable>
                  </div>
                </CCol>
              </CRow>

              <CNav variant="pills" className="mt-3" style={{ marginBottom: '19px' }}>
                {[
                  medicalHistoryTabs.map((table) => (
                    <CNavItem key={table}>
                      <CNavLink
                        role="button"
                        className="text-capitalize"
                        href={`#${table}-link`}
                        active={location.hash === `#${table}-link`}
                      >
                        {table.split('-').join(' ')}
                      </CNavLink>
                    </CNavItem>
                  ))
                ]}
              </CNav>

              <CRow className="mb-4">
                <CCol>
                  <div onClick={scrollUp} className="mb-5" id={'master-problems-link'}></div>
                  <MedicalHistoryTable
                    name="Master Problems"
                    items={animalMasterProblems.map(applyAnimalMasterProblemStyles)}
                    caption="Master problems, with flagged problems in blue"
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '5%' } },
                      { key: 'started_on', label: 'Started', _style: { width: '10%' } },
                      'name',
                      'specifics',
                      'internal_notes'
                    ]}
                    scopedColumns={{
                      started_on: (item: AnimalMasterProblem) => (
                        <td>{item.started_on && compactDateDisplay(item.started_on)}</td>
                      ),
                      name: (item: AnimalMasterProblem) => <td>{item.master_problem.name}</td>,
                      specifics: (item: AnimalMasterProblem) => (
                        <td dangerouslySetInnerHTML={{ __html: item.specifics ?? '' }} />
                      ),
                      internal_notes: (item: AnimalMasterProblem) => (
                        <td dangerouslySetInnerHTML={{ __html: item.internal_notes ?? '' }} />
                      ),
                      ...status,
                      updated_at: (item: AnimalMasterProblem) => updatedAt(item, undefined, item.consult_id)
                    }}
                  />

                  <CButton
                    id={'health-plans-link'}
                    onClick={scrollUp}
                    color="link"
                    variant="ghost"
                    size="sm"
                    className="ps-0 fw-medium mb-5"
                  >
                    Back to top
                  </CButton>
                  <MedicalHistoryTable
                    name="Health Plans"
                    items={healthPlans}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '5%' } },
                      'name',
                      'importance',
                      'fulfilled_at',
                      'next_due_date'
                    ]}
                    scopedColumns={{
                      name: (item: AnimalHealthPlan) => <td>{item.health_plan.name}</td>,
                      importance: (item: AnimalHealthPlan) => (
                        <td>
                          <Pill label={item.health_plan.importance} />
                        </td>
                      ),
                      fulfilled_at: (item: AnimalHealthPlan) => (
                        <td>{item.fulfilled_at && compactDateDisplay(item.fulfilled_at)}</td>
                      ),
                      next_due_date: (item: AnimalHealthPlan) => (
                        <td>{item.next_due_date && compactDateDisplay(item.next_due_date)}</td>
                      ),
                      ...status,
                      updated_at: (item: AnimalHealthPlan) => updatedAt(item)
                    }}
                  />

                  <CButton
                    id={'histories-link'}
                    onClick={scrollUp}
                    color="link"
                    variant="ghost"
                    size="sm"
                    className="ps-0 fw-medium mb-5"
                  >
                    Back to top
                  </CButton>
                  <MedicalHistoryTable
                    name="Histories"
                    items={histories}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '5%' } },
                      { key: 'history' },
                      { key: 'internal_notes' }
                    ]}
                    scopedColumns={{
                      created_at: (item: History) => <td>{compactDateDisplay(item.created_at)}</td>,
                      history: (item: History) => <td dangerouslySetInnerHTML={{ __html: item.history || '' }} />,
                      internal_notes: (item: History) => (
                        <td dangerouslySetInnerHTML={{ __html: item.internal_notes }} />
                      ),
                      updated_at: (item: History) => updatedAt(item, 'soap', item.consult_id),
                      ...status
                    }}
                  />

                  <CButton
                    id={'physical-exams-link'}
                    onClick={scrollUp}
                    color="link"
                    variant="ghost"
                    size="sm"
                    className="ps-0 fw-medium mb-5"
                  >
                    Back to top
                  </CButton>
                  <MedicalHistoryTable
                    name="Physical Exams"
                    items={physicalExams}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '10%' } },
                      'exam',
                      'internal_notes'
                    ]}
                    scopedColumns={{
                      created_at: (item: PhysicalExam) => <td>{compactDateDisplay(item.created_at)}</td>,
                      exam: (item: PhysicalExam) => <td dangerouslySetInnerHTML={{ __html: item.exam || '' }} />,
                      internal_notes: (item: PhysicalExam) => (
                        <td dangerouslySetInnerHTML={{ __html: item.internal_notes }} />
                      ),
                      updated_at: (item: PhysicalExam) => updatedAt(item, 'soap', item.consult_id),
                      ...status
                    }}
                  />

                  <CButton
                    id={'assessments-link'}
                    onClick={scrollUp}
                    color="link"
                    variant="ghost"
                    size="sm"
                    className="ps-0 fw-medium mb-5"
                  >
                    Back to top
                  </CButton>
                  <MedicalHistoryTable
                    name="Assessments"
                    items={assessments}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '10%' } },
                      'evaluation',
                      { key: 'view', _style: { width: '10%' } }
                    ]}
                    scopedColumns={{
                      evaluation: (item: Assessment) => <td dangerouslySetInnerHTML={{ __html: item.evaluation }} />,
                      updated_at: (item: Assessment) => updatedAt(item, 'soap', item.consult_id),
                      ...status
                    }}
                  />

                  <CButton
                    id={'consult-plans-link'}
                    onClick={scrollUp}
                    color="link"
                    variant="ghost"
                    size="sm"
                    className="ps-0 fw-medium mb-5"
                  >
                    Back to top
                  </CButton>
                  <MedicalHistoryTable
                    name="Consult Plans"
                    items={consultPlans}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '10%' } },
                      'plan',
                      'customer_discussion',
                      'internal_notes'
                    ]}
                    scopedColumns={{
                      plan: (item: ConsultPlan) => <td dangerouslySetInnerHTML={{ __html: item.plan ?? '' }} />,
                      customer_discussion: (item: ConsultPlan) => (
                        <td dangerouslySetInnerHTML={{ __html: item.customer_discussion ?? '' }} />
                      ),
                      internal_notes: (item: ConsultPlan) => (
                        <td dangerouslySetInnerHTML={{ __html: item.internal_notes ?? '' }} />
                      ),
                      updated_at: (item: ConsultPlan) => updatedAt(item, 'soap', item.consult_id),
                      ...status
                    }}
                  />

                  <CButton
                    id={'vaccinations-link'}
                    onClick={scrollUp}
                    color="link"
                    variant="ghost"
                    size="sm"
                    className="ps-0 fw-medium mb-5"
                  >
                    Back to top
                  </CButton>
                  <MedicalHistoryTable
                    name="Vaccinations"
                    items={vaccinations}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '10%' } },
                      { key: 'date_of_administration', _style: { width: '10%' } },
                      { key: 'date_of_next_administration', _style: { width: '10%' } },
                      { key: 'administration_status', label: 'Administration Status', _style: { width: '10%' } },
                      'product_name',
                      'quantity',
                      'historical',
                      'internal_notes'
                    ]}
                    scopedColumns={{
                      internal_notes: (item: Vaccination) => (
                        <td dangerouslySetInnerHTML={{ __html: item.internal_notes ?? '' }} />
                      ),
                      date_of_administration: (item: Vaccination) => (
                        <td>{item.date_of_administration && compactDateDisplay(item.date_of_administration)}</td>
                      ),
                      date_of_next_administration: (item: Vaccination) => (
                        <td>
                          {item.date_of_next_administration && compactDateDisplay(item.date_of_next_administration)}
                        </td>
                      ),
                      product_name: (item: Vaccination) => <td>{item.product?.name}</td>,
                      administration_status: (item: Vaccination) => (
                        <td>
                          <Pill label={item.administration_status} />
                        </td>
                      ),
                      historical: (item: Vaccination) => <td>{item.historical ? 'true' : 'false'}</td>,
                      quantity: (item: Vaccination) => <td>{item.quantity && item.quantity}</td>,
                      updated_at: (item: Vaccination) => updatedAt(item, 'rx-vacc', item.consult_id),
                      ...status
                    }}
                  />

                  <CButton
                    id={'medications-link'}
                    onClick={scrollUp}
                    color="link"
                    variant="ghost"
                    size="sm"
                    className="ps-0 fw-medium mb-5"
                  >
                    Back to top
                  </CButton>
                  <MedicalHistoryTable
                    name="Medications"
                    items={prescriptionItems.map(applyPrescriptionItemStyles)}
                    caption="Medications, with currently taken medications in blue"
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '10%' } },
                      { key: 'date_of_prescription', label: 'Date of Prescription', _style: { width: '10%' } },
                      { key: 'current', label: 'Current', _style: { width: '10%' } },
                      { key: 'chronic', label: 'Chronic', _style: { width: '10%' } },
                      { key: 'employee', label: 'Prescribed By', _style: { width: '10%' } },
                      { key: 'name' },
                      { key: 'quantity' },
                      { key: 'remaining', label: 'Refills Left' }
                    ]}
                    scopedColumns={{
                      date_of_prescription: (item: PrescriptionItem) => (
                        <td>{compactDateDisplay(item.prescription.date_of_prescription)}</td>
                      ),
                      employee: (item: PrescriptionItem) => <td>{item.prescription.employee?.full_name_with_title}</td>,
                      name: (item: PrescriptionItem) => <td>{item.product?.name}</td>,
                      current: (item: PrescriptionItem) => <td>{item.current ? 'Yes' : 'No'}</td>,
                      chronic: (item: PrescriptionItem) => <td>{item.chronic ? 'Yes' : 'No'}</td>,
                      ...status,
                      updated_at: (item: PrescriptionItem) => updatedAt(item, 'rx-vacc', item.prescription.consult_id),
                      remaining: (item: PrescriptionItem) => (
                        <td>
                          {item.remaining}{' '}
                          {item.refill_expires_at && ` until ${compactDateDisplay(item.refill_expires_at)}`}
                        </td>
                      )
                    }}
                  />

                  <CButton
                    id={'diagnostics-link'}
                    onClick={scrollUp}
                    color="link"
                    variant="ghost"
                    size="sm"
                    className="ps-0 fw-medium mb-5"
                  >
                    Back to top
                  </CButton>
                  <MedicalHistoryTable
                    name="Diagnostics"
                    items={diagnosticRequests}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '10%' } },
                      { key: 'employee', _style: { width: '10%' } },
                      { key: 'reference_number', _style: { width: '10%' } },
                      { key: 'diagnostics', label: 'Summaries' }
                    ]}
                    scopedColumns={{
                      employee: (item: DiagnosticRequest) => <td>{item.employee.full_name_with_title}</td>,
                      diagnostics: (item: DiagnosticRequest) => (
                        <td>
                          <DiagnosticRequestDetails item={item} />
                        </td>
                      ),
                      updated_at: (item: DiagnosticRequest) => updatedAt(item, 'diagnostics', item.consult_id),
                      ...status
                    }}
                  />

                  <CButton
                    id={'procedures-link'}
                    onClick={scrollUp}
                    color="link"
                    variant="ghost"
                    size="sm"
                    className="ps-0 fw-medium mb-5"
                  >
                    Back to top
                  </CButton>
                  <MedicalHistoryTable
                    name="Procedures"
                    items={procedures}
                    columns={[
                      { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
                      { key: 'status', _style: { width: '10%' } },
                      { key: 'procedure_name', _style: { width: '10%' } },
                      'internal_notes'
                    ]}
                    scopedColumns={{
                      procedure_name: (item: Procedure) => <td>{item.product.name}</td>,
                      internal_notes: (item: Procedure) => (
                        <td dangerouslySetInnerHTML={{ __html: item.internal_notes }} />
                      ),
                      updated_at: (item: Procedure) => updatedAt(item, 'procedures', item.consult_id),
                      ...status
                    }}
                  />
                </CCol>
              </CRow>
            </>
          )}
        </CContainer>
      </CCardBody>
    </CCard>
  );
};

export default MedicalHistory;
