import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { fetchEmployeesByQuery } from 'api/Employees';
import { createHospitalSheet } from 'api/HospitalSheet';

import { Appointment } from 'types/Appointment';
import { HospitalSheet } from 'types/HospitalSheet';
import { Option } from 'types/Option';

import { employeeToOption } from 'utils/selectOptions';

import { ConfirmationModal } from 'components/ConfirmationModal';

type Props = {
  appointment: Appointment;
  hideModal: () => void;
  onSuccess: (hospitalSheet: HospitalSheet) => void;
};

export const SendToHospitalModal = ({ appointment, hideModal, onSuccess }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedCaseOwner, setSelectedCaseOwner] = useState<Option | null>(employeeToOption(appointment.employee));
  const [selectedTech, setSelectedTech] = useState<Option | null>(null);

  const sendToHospital = () => {
    setIsLoading(true);
    if (appointment && selectedCaseOwner && selectedTech) {
      createHospitalSheet(
        {
          animal_id: appointment.animal_id,
          clinic_id: appointment.clinic.id,
          consult_id: appointment.consult_id,
          case_owner_id: Number(selectedCaseOwner.value),
          assigned_nurse_id: Number(selectedTech.value)
        },
        onSuccess
      );
    }
  };

  return (
    <ConfirmationModal
      isVisible
      isLoading={isLoading}
      onClose={hideModal}
      isDisabled={!(selectedCaseOwner && selectedTech)}
      onConfirm={sendToHospital}
      modalBody={`Admit ${appointment.animal.name} to the hospital?`}
      confirmButtonLabel={`Yes, admit`}
      modalHeader="Send to hospital?"
    >
      <label className="form-label mt-3 mb-1">Case Owner</label>
      <AsyncSelect<Option>
        aria-label="Case Owner"
        placeholder="Type to search..."
        value={selectedCaseOwner}
        onChange={(selected) => setSelectedCaseOwner(selected as Option)}
        loadOptions={(inputValue: string, callback: (options: Option[]) => void) => {
          fetchEmployeesByQuery(inputValue, { vets_only: true }).then((options) => {
            callback(options.map(employeeToOption));
          });
        }}
        defaultOptions
        isSearchable
        required
      />

      <label className="form-label mt-3 mb-1">Tech Assigned</label>
      <AsyncSelect<Option>
        aria-label="Tech Assigned"
        placeholder="Type to search..."
        value={selectedTech}
        onChange={(selected) => setSelectedTech(selected as Option)}
        loadOptions={(inputValue: string, callback: (options: Option[]) => void) => {
          fetchEmployeesByQuery(inputValue).then((options) => {
            callback(options.map(employeeToOption));
          });
        }}
        defaultOptions
        isSearchable
        required
      />
    </ConfirmationModal>
  );
};
