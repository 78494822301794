import { differenceInYears, parse } from 'date-fns';
import * as React from 'react';

import { CBadge } from '@coreui/react-pro';

import { Animal } from 'types/Animal';
import { Appointment } from 'types/Appointment';

export const PossibleParvoBadge = ({ animal, appointment }: { animal: Animal; appointment: Appointment }) => {
  if (animal.date_of_birth && animal.species.name === 'Dog') {
    const date = parse(animal.date_of_birth, 'yyyy-MM-dd', new Date());
    const age = differenceInYears(new Date(), date);

    if (
      age < 1 &&
      appointment.visit_reason_list.some((reason) =>
        ['vomiting', 'diarrhea or stool change', 'coughing or sneezing'].includes(reason.toLowerCase())
      )
    ) {
      return (
        <CBadge className="text-capitalize rounded-pill w-auto" color="danger" style={{ height: 'fit-content' }}>
          Possible Parvo
        </CBadge>
      );
    }
  }
  return null;
};
