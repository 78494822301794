import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { cilOptions, cilPencil } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
  CSpinner
} from '@coreui/react-pro';

import { fetchClinic } from 'api/Clinics';
import { fetchConsultTemplate } from 'api/ConsultTemplates';

import { Clinic } from 'types/Clinic';
import { cleanConsultTemplateType, ConsultTemplate } from 'types/ConsultTemplate';
import { DocumentContextObjects } from 'types/DocumentContextObjects';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { ClinicContext } from 'contexts/ClinicContext';

import { compactDateTimeDisplay } from 'utils/dates';
import { replaceInputs, replaceKeywords, replaceSignature } from 'utils/documents';

import { Pill } from 'components/Pill';

import styles from './Details.module.scss';

import DocumentInputs from './DocumentInputs';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [template, setTemplate] = useState<ConsultTemplate>();

  const { clinicContext } = useContext(ClinicContext);
  const [clinic, setClinic] = useState<Clinic>();
  const [contextObjects, setContextObjects] = useState<DocumentContextObjects>({});

  useDocumentTitle('Document Template', template?.name);

  useEffect(() => {
    fetchConsultTemplate(id, setTemplate);
  }, [id]);

  useEffect(() => {
    clinicContext && fetchClinic(clinicContext, setClinic);
  }, [clinicContext]);

  useEffect(() => {
    if (!template) return;
    const replaced = replaceSignature(replaceInputs(replaceKeywords(template.content, { ...contextObjects, clinic })));
    setTemplate({ ...template, content: replaced });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextObjects]);

  return (
    <>
      {template ? (
        <CCard>
          <CCardHeader className="d-flex flex-row align-items-center justify-content-between">
            <strong style={{ fontSize: '30px' }}>{template.name}</strong>

            <CDropdown alignment="end">
              <CDropdownToggle color="transparent" caret={false} className="p-0">
                <CIcon icon={cilOptions} aria-label="options" className="text-high-emphasis" />
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem
                  role="button"
                  className="align-items-center"
                  href={`/document_templates/${template.id}/edit`}
                >
                  <CIcon icon={cilPencil} aria-label="edit" className="me-2 text-high-emphasis" />
                  Edit
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CCardHeader>
          <CCardBody>
            <CRow className="mb-3">
              <CCol sm={6}>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Type</div>
                </CRow>
                <CRow className="ps-3">{cleanConsultTemplateType(template.type)}</CRow>
              </CCol>
              <CCol sm={6}>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Status</div>
                </CRow>
                <CRow className="ps-3">
                  <Pill label={template.status} />
                </CRow>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Description</div>
                </CRow>
                <CRow className="ps-3">{template.description}</CRow>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol sm={6}>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Created At</div>
                </CRow>
                <CRow className="ps-3">{template.created_at && compactDateTimeDisplay(template.created_at)}</CRow>
              </CCol>
              <CCol sm={6}>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Updated At</div>
                </CRow>
                <CRow className="ps-3">{template.updated_at && compactDateTimeDisplay(template.updated_at)}</CRow>
              </CCol>
            </CRow>

            <CRow>
              <CCol>
                <div style={{ fontWeight: 'bold' }}>Preview values</div>
              </CCol>
            </CRow>
            <DocumentInputs contextObjects={contextObjects} setContextObjects={setContextObjects} />

            <CRow className="mb-3">
              <CCol>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Content</div>
                </CRow>
                <CRow className="ps-3">
                  <CCard className="my-3 p-3">
                    <div className={styles.documentRoot} dangerouslySetInnerHTML={{ __html: template.content }} />
                  </CCard>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      ) : (
        <CSpinner />
      )}
    </>
  );
};
export default Details;
