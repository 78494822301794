import * as React from 'react';
import type { SVGProps } from 'react';

const SvgOutgoing = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m8.666 7.333 5.467-5.467M14.667 4.533v-3.2h-3.2M7.334 1.333H6.001C2.667 1.333 1.334 2.667 1.334 6v4c0 3.333 1.333 4.667 4.667 4.667h4c3.333 0 4.666-1.334 4.666-4.667V8.667"
    />
  </svg>
);
export default SvgOutgoing;
