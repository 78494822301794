import { formatDuration, intervalToDuration } from 'date-fns';

import { Animal } from 'types/Animal';

export const desexedDisplay = (animal: Animal): string => {
  switch (animal.sex?.name) {
    case 'male':
      return animal.desexed ? 'Male - Neutered' : 'Male - Not Neutered';
    case 'female':
      return animal.desexed ? 'Female - Spayed' : 'Female - Not Spayed';
    default:
      return 'Unknown';
  }
};

export const ageDisplay = (dateOfBirth: string): string => {
  const duration: Duration = intervalToDuration({
    start: new Date(dateOfBirth),
    end: new Date()
  });

  if (duration.years) {
    return formatDuration(duration, { format: ['years', 'months'] });
  } else if (duration.months) {
    return formatDuration(duration, { format: ['months', 'days'] });
  } else {
    return formatDuration(duration, { format: ['days'] });
  }
};
