import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPlusMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
      <path d="M14.642 10V6c0-3.333-1.333-4.666-4.667-4.666h-4C2.642 1.334 1.31 2.667 1.31 6v4c0 3.334 1.333 4.667 4.666 4.667h4c3.334 0 4.667-1.333 4.667-4.667M13.142 2.167 2.176 13.133" />
      <path strokeMiterlimit={10} d="M10.701 12V8.666M12.33 10.334H8.995M6.995 5H3.662" />
    </g>
  </svg>
);
export default SvgPlusMinus;
