import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIncomingUnderlined = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="M6 14.667h4c3.333 0 4.666-1.333 4.666-4.667V6c0-3.333-1.333-4.666-4.666-4.666H6C2.666 1.334 1.333 2.667 1.333 6v4c0 3.334 1.333 4.667 4.667 4.667"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="M7.06 8.893h2.826V6.067M9.887 8.893 6.113 5.12M4 11.007c2.593.866 5.407.866 8 0"
    />
  </svg>
);
export default SvgIncomingUnderlined;
