export const reactSelectStyles = {
  menu: (base: object) => ({
    ...base,
    zIndex: 3 // Ensure the select options are above any TinyMCE editor header (z-index 2)
  }),
  control: (base: object) => ({
    ...base,
    borderRadius: '16px',
    borderColor: '#ececec'
  })
};
