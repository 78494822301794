import axios from 'axios';

import { PimResource } from 'types/PimResource';

const createPimResource = (
  clinicId: number | string,
  employeeId: number | string,
  onSuccess: (pimResource: PimResource) => void
) => {
  const url = '/pim/pim_resources';
  const params = {
    pim_resource: {
      employee_id: employeeId,
      clinic_id: clinicId
    }
  };
  axios.post<PimResource>(url, params).then((data) => onSuccess(data.data.record));
};

const deletePimResource = (pimResource: Partial<PimResource>, onSuccess: (pimResource: PimResource) => void) => {
  const url = `/pim/pim_resources/${pimResource.id}`;
  axios.delete<PimResource>(url).then((data) => onSuccess(data.data.record));
};

const fetchPimResources = (clinicId: number | string, onSuccess: (pimResources: PimResource[]) => void) => {
  const url = '/pim/pim_resources';
  const params = { clinic_id: clinicId };
  axios.get<PimResource[]>(url, { params }).then((data) => onSuccess(data.data.record));
};

const updatePimResourceSortOrder = (sortOrder: number[], onSuccess: (pimResources: PimResource[]) => void) => {
  const url = '/pim/pim_resources/update_sort_order';
  axios.put<PimResource[]>(url, { pim_resources: sortOrder }).then((data) => onSuccess(data.data.record));
};

export { fetchPimResources, updatePimResourceSortOrder, createPimResource, deletePimResource };
