import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InternalNote as AnimalInternalNote } from 'views/animals/InternalNote';
import { InternalNote as CustomerInternalNote } from 'views/customers/InternalNote';

import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CListGroup,
  CListGroupItem,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSpinner,
  CTabContent,
  CTabPane
} from '@coreui/react-pro';

import { fetchAnimal } from 'api/Animals';
import { cancelAppointment, createCancelFee, createNoShowFee, fetchAppointment } from 'api/Appointments';
import { fetchConsult } from 'api/Consults';
import { fetchCustomer } from 'api/Customers';
import { fetchEstimatesForConsult } from 'api/Estimates';
import { fetchInvoicesForAppointment } from 'api/Invoices';
import { fetchPaymentsForAppointment } from 'api/Payments';

import { Animal } from 'types/Animal';
import { Appointment } from 'types/Appointment';
import { CancelReason } from 'types/CancelReason';
import { Consult } from 'types/Consult';
import { Customer } from 'types/Customer';
import { Estimate } from 'types/Estimate';
import { HospitalSheet } from 'types/HospitalSheet';
import { Invoice } from 'types/Invoice';
import { LogEvent } from 'types/LogEvent';
import { Pagination } from 'types/Pagination';
import { Payment } from 'types/Payment';
import { TelehealthRecording } from 'types/TelehealthRecording';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { compactDateDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

import { ReactComponent as PersonalCardIcon } from 'assets/images/personalcard.svg';
import SvgConsult from 'assets/images/SvgConsult';
import SvgDocument from 'assets/images/SvgDocument';
import SvgHospital from 'assets/images/SvgHospital';
import SvgPlay from 'assets/images/SvgPlay';
import SvgVideo from 'assets/images/SvgVideo';

import { ActionsMenu } from 'components/ActionsMenu';
import { CancelAppointmentModal } from 'components/CancelAppointmentModal';
import { Changelog } from 'components/Changelog';

import styles from './Details.module.scss';

import { fetchAppointmentChangeLog } from '../../api/ChangeLog';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { AppointmentDetailsCard } from './AppointmentDetailsCard';
import { AppointmentSignalment } from './AppointmentSignalment';
import { EstimatesList } from './EstimatesList';
import { InvoicesList } from './InvoicesList';
import { OwnerInfoCard } from './OwnerInfoCard';
import { PaymentsTable } from './PaymentsTable';
import { SendToHospitalModal } from './SendToHospitalModal';
import { VisitDetailsCard } from './VisitDetailsCard';

const appointmentTabs = ['payments', 'invoices', 'treatment-plans'] as const;

type Tab = (typeof appointmentTabs)[number];

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const navigate = useNavigate();
  const [animal, setAnimal] = useState<Animal>();
  const [customer, setCustomer] = useState<Customer>();
  const [appointment, setAppointment] = useState<Appointment>();
  const [consult, setConsult] = useState<Consult>();
  const [changelog, setChangelog] = useState<LogEvent[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<Invoice[] | undefined>();
  const [invoicesPagination, setInvoicesPagination] = useState<Pagination>({ page: 1, perPage: 12, total: 1 });
  const [estimates, setEstimates] = useState<Estimate[] | undefined>();
  const [payments, setPayments] = useState<Payment[] | undefined>();
  const [selectedCancelReason, setSelectedCancelReason] = useState<CancelReason | undefined>();

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<Tab>((searchParams.get('tab') as Tab) ?? appointmentTabs[0]);

  useDocumentTitle(
    'Appointment',
    appointment && `${appointment.animal.name}'s ${compactDateDisplay(appointment.client_start_time)}`
  );

  useEffect(() => {
    fetchAppointment(id, setAppointment);
    fetchAppointmentChangeLog(id, setChangelog);
    fetchInvoicesForAppointment(id, {}, setInvoices, setInvoicesPagination);
    fetchPaymentsForAppointment(id, setPayments);
  }, [id]);

  useEffect(() => {
    if (appointment?.consult_id) {
      fetchConsult(appointment.consult_id, setConsult);
      fetchEstimatesForConsult(appointment.consult_id, setEstimates);
    }
  }, [appointment?.consult_id]);

  useEffect(() => {
    if (appointment?.animal_id) fetchAnimal(appointment.animal_id, setAnimal);
  }, [appointment?.animal_id]);

  useEffect(() => {
    if (animal) fetchCustomer(animal.customer_id, setCustomer);
  }, [animal]);

  useEffect(() => {
    let tabFromParams = searchParams.get('tab');

    if (!tabFromParams || !appointmentTabs.includes(tabFromParams as Tab)) {
      tabFromParams = appointmentTabs[0];
    }

    setCurrentTab(tabFromParams as Tab);
  }, [searchParams, setSearchParams]);

  const handleTabClick = (tab: Tab) => {
    searchParams.set('tab', tab);
    setSearchParams(searchParams);
  };

  const cancel = () => {
    setIsLoading(true);
    setShowCancelModal(false);
    if (appointment) {
      cancelAppointment(
        appointment.id,
        Number(selectedCancelReason?.id) || undefined,
        selectedCancelReason?.name,
        cancelSuccess,
        turnOffLoadingIndicator
      );
    }
  };

  const cancelSuccess = (updatedAppointment: Appointment) => {
    setIsLoading(false);
    setAppointment(updatedAppointment);
    toast.success('Appointment canceled.');
  };

  const turnOffLoadingIndicator = (message: string) => {
    setIsLoading(false);
  };

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const confirmCancelModal = () => {
    if (appointment) {
      return (
        <CancelAppointmentModal
          isVisible={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onConfirm={cancel}
          modalBody={`Cancel appointment and notify client?`}
          confirmButtonLabel="Yes"
          cancelButtonLabel="No"
          modalHeader="Cancel Appointment"
          onReasonSelected={setSelectedCancelReason}
        />
      );
    }
  };

  const chargeNoShowFee = () => {
    setIsLoading(true);
    setShowNoShowFeeModal(false);
    if (appointment) {
      createNoShowFee(appointment.id, noShowFeeSuccess, turnOffLoadingIndicator);
    }
  };

  const noShowFeeSuccess = (updatedAppointment: Appointment) => {
    setIsLoading(false);
    setAppointment(updatedAppointment);
    toast.success('No-show fee charged.');
  };

  const [showNoShowFeeModal, setShowNoShowFeeModal] = useState<boolean>(false);
  const confirmNoShowFeeModal = () => {
    if (appointment) {
      return (
        <ConfirmationModal
          isVisible={showNoShowFeeModal}
          onClose={() => setShowNoShowFeeModal(false)}
          onConfirm={chargeNoShowFee}
          modalBody={`Charge client ${toCurrency(appointment.no_show_fee_price)} no-show fee?`}
          confirmButtonLabel={`Charge ${toCurrency(appointment.no_show_fee_price)}`}
          modalHeader="No-Show Fee"
        />
      );
    }
  };

  const chargeCancelFee = () => {
    setIsLoading(true);
    setShowCancelFeeModal(false);
    if (appointment) {
      createCancelFee(appointment.id, cancelFeeSuccess, turnOffLoadingIndicator);
    }
  };

  const cancelFeeSuccess = (updatedAppointment: Appointment) => {
    setIsLoading(false);
    setAppointment(updatedAppointment);
    toast.success('Cancellation fee charged.');
  };

  const [showCancelFeeModal, setShowCancelFeeModal] = useState<boolean>(false);
  const confirmCancelFeeModal = () => {
    if (appointment) {
      return (
        <ConfirmationModal
          isVisible={showCancelFeeModal}
          onClose={() => setShowCancelFeeModal(false)}
          onConfirm={chargeCancelFee}
          modalBody={`Charge client ${toCurrency(appointment.cancel_fee_price)} cancellation fee?`}
          confirmButtonLabel={`Charge ${toCurrency(appointment.cancel_fee_price)}`}
          modalHeader="Cancellation Fee"
        />
      );
    }
  };

  const [showHospitalAdmitModal, setShowHospitalAdmitModal] = useState<boolean>(false);

  const hospitalSheetSuccess = (hospitalSheet: HospitalSheet) => {
    setIsLoading(false);
    toast.success('Patient sent to hospital!');
    navigate('/whiteboard');
    setShowHospitalAdmitModal(false);
  };

  const handleUpdateInvoicePage = (page: number) => {
    setInvoicesPagination((prev) => ({ ...prev, page }));
    fetchInvoicesForAppointment(id, { page }, setInvoices, setInvoicesPagination);
  };

  const actionsMenuItems = (appt: Appointment) => {
    const feePaid = appt.no_show_fee_amount_paid > 0 || appt.cancel_fee_amount_paid > 0;

    return [
      {
        label: 'Edit',
        href: `/appointments/${appt.id}/edit`
      },
      {
        label: 'Cancel',
        onClick: () => setShowCancelModal(true),
        disabled: !!appt.canceled_at
      },
      {
        label: 'Charge No-Show Fee',
        onClick: () => setShowNoShowFeeModal(true),
        disabled: appt.no_show_fee_price === 0 || feePaid
      },
      {
        label: 'Charge Cancellation Fee',
        onClick: () => setShowCancelFeeModal(true),
        disabled: !appt.cancel_fee_eligible
      }
    ];
  };

  const renderTable = (tab: Tab) => {
    switch (tab) {
      case 'payments':
        return (
          <CTabPane role="tabpanel" aria-label="payments-tab" visible>
            <PaymentsTable payments={payments} />
          </CTabPane>
        );
      case 'invoices':
        return (
          <CTabPane role="tabpanel" aria-label="invoices-tab" visible>
            <InvoicesList
              invoices={invoices}
              customer={appointment?.customer}
              pagination={invoicesPagination}
              updatePage={handleUpdateInvoicePage}
            />
          </CTabPane>
        );
      case 'treatment-plans':
        return (
          <CTabPane role="tabpanel" aria-label="treatment-plans-tab" visible>
            <EstimatesList estimates={estimates} customer={appointment?.customer} />
          </CTabPane>
        );
      default:
        return null;
    }
  };

  const telehealthRecordings = (recordings: TelehealthRecording[]) => {
    return (
      <CDropdown alignment="end">
        <CDropdownToggle shape="rounded-pill" size="sm" caret={false} aria-label="Telehealth recordings list">
          <SvgVideo /> Telehealth Recordings
        </CDropdownToggle>
        <CDropdownMenu>
          {recordings.map((item, index) => (
            <CDropdownItem
              className="align-items-center"
              href={item.recording_url}
              target="_blank"
              key={item.id}
              role="button"
            >
              <SvgVideo className="me-2" />
              <span>Recording {index + 1}</span>
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
    );
  };

  return (
    <>
      {isLoading && <CSpinner />}

      {appointment && (
        <div className={styles.root}>
          <div className={styles.mainPage}>
            <div className={styles.headerRow}>
              <h1 className="m-0">Appointment Details</h1>
              <div className={styles.headerButtons}>
                <CButton
                  href={`/appointments/${appointment.id}/checkout`}
                  shape="rounded-pill"
                  color="success"
                  size="sm"
                  className={styles.headerButton}
                >
                  Checkout
                </CButton>
                {consult?.hospital_sheet ? (
                  <CButton
                    href={`/hospital_sheet/${consult.hospital_sheet.id}`}
                    shape="rounded-pill"
                    size="sm"
                    className={styles.headerButton}
                  >
                    <SvgDocument /> Hospital Sheet
                  </CButton>
                ) : (
                  <CButton
                    onClick={() => setShowHospitalAdmitModal(true)}
                    shape="rounded-pill"
                    size="sm"
                    className={styles.headerButton}
                  >
                    <SvgHospital /> Send to Hospital
                  </CButton>
                )}
                {appointment.status !== 'Complete' && appointment.telehealth_host_url && (
                  <CButton
                    href={appointment.telehealth_host_url}
                    target="_blank"
                    shape="rounded-pill"
                    size="sm"
                    className={styles.headerButton}
                  >
                    <SvgPlay /> Begin Telehealth
                  </CButton>
                )}
                {appointment.telehealth_recordings.length > 0 &&
                  telehealthRecordings(appointment.telehealth_recordings)}
                {consult && (
                  <CButton
                    href={`/consults/${consult.id}`}
                    shape="rounded-pill"
                    size="sm"
                    className={styles.headerButton}
                  >
                    <SvgConsult /> Consult
                  </CButton>
                )}
                <ActionsMenu items={actionsMenuItems(appointment)} />
              </div>
            </div>
            <CRow>
              <CCol sm={5}>
                <CRow className="mb-2">{animal && <AppointmentSignalment animal={animal} />}</CRow>
                <CRow>
                  {animal && (
                    <AnimalInternalNote animal={animal} onNoteSaved={setAnimal} title="Patient's Internal Notes" />
                  )}
                </CRow>
              </CCol>

              <CCol sm={7}>
                <CRow className="mb-4">
                  {animal && (
                    <AppointmentDetailsCard animal={animal} appointment={appointment} setAppointment={setAppointment} />
                  )}
                </CRow>
                <CRow>
                  <VisitDetailsCard appointment={appointment} />
                </CRow>
              </CCol>
            </CRow>

            <h2 className="mt-2">Finances</h2>
            <CNav variant="pills" style={{ marginBottom: '19px' }} role="list">
              {[
                appointmentTabs.map((tab) => (
                  <CNavItem key={tab}>
                    <CNavLink
                      role="button"
                      className="text-capitalize"
                      onClick={() => handleTabClick(tab)}
                      active={tab === currentTab}
                    >
                      {tab.split('-').join(' ')}
                    </CNavLink>
                  </CNavItem>
                ))
              ]}
            </CNav>

            <CTabContent>{renderTable(currentTab)}</CTabContent>

            <CRow className="mb-4">
              <Changelog events={changelog} title="Appointment Change Log" />
            </CRow>
          </div>

          <CCol className={styles.sidePanel}>
            <div className={styles.panelMainContent}>
              <h2 className="m-0">Client Information</h2>
              {customer && <OwnerInfoCard customer={customer} />}

              {customer && (
                <CustomerInternalNote customer={customer} onNoteSaved={setCustomer} title="Client Internal Notes" />
              )}
            </div>
            <CAccordion className={styles.accountAccordion}>
              <CAccordionItem itemKey={1}>
                <CAccordionHeader>
                  <h2 className="m-0">Account Details</h2>
                </CAccordionHeader>
                <CAccordionBody className="p-0">
                  <CListGroup>
                    <CListGroupItem className={styles.accordionDetail}>
                      <span className={styles.accordionLeft}>
                        <PersonalCardIcon /> PIM ID
                      </span>
                      <span className={styles.accordionRight}>{appointment.pim_id}</span>
                    </CListGroupItem>
                    <CListGroupItem className={styles.accordionDetail}>
                      <span className={styles.accordionLeft}>
                        <PersonalCardIcon /> PIM Name
                      </span>
                      <span className={styles.accordionRight}>{appointment.pim_name}</span>
                    </CListGroupItem>
                    <CListGroupItem className={styles.accordionDetail}>
                      <span className={styles.accordionLeft}>
                        <PersonalCardIcon /> PIM Type
                      </span>
                      <span className={styles.accordionRight}>{appointment.pim_type}</span>
                    </CListGroupItem>
                  </CListGroup>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </CCol>
        </div>
      )}

      {confirmCancelModal()}
      {confirmNoShowFeeModal()}
      {confirmCancelFeeModal()}
      {showHospitalAdmitModal && appointment && (
        <SendToHospitalModal
          appointment={appointment}
          hideModal={() => setShowHospitalAdmitModal(false)}
          onSuccess={hospitalSheetSuccess}
        />
      )}
    </>
  );
};
export default Details;
