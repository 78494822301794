import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={16} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M13.125 4.875v6.25c0 1.875-.937 3.125-3.125 3.125H5c-2.187 0-3.125-1.25-3.125-3.125v-6.25C1.875 3 2.813 1.75 5 1.75h5c2.188 0 3.125 1.25 3.125 3.125"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9.063 3.313v1.25c0 .687.562 1.25 1.25 1.25h1.25M5 8.625h2.5M5 11.125h5"
    />
  </svg>
);
export default SvgDocument;
