import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import { CButton, CCol, CDatePicker, CForm, CFormInput, CFormSelect, CLoadingButton, CRow } from '@coreui/react-pro';

import { fetchAnimal, fetchAnimalsByQuery } from 'api/Animals';
import { fetchClinics } from 'api/Clinics';
import { fetchCustomer, fetchCustomersByQuery } from 'api/Customers';
import { fetchEmployeesByQuery } from 'api/Employees';

import { Animal } from 'types/Animal';
import { Customer } from 'types/Customer';
import {
  EmployeeTask,
  EmployeeTaskStatus,
  EmployeeTaskType,
  employeeTaskTypeOptions,
  getLinkNameForContext,
  getLinkUrlToContext
} from 'types/EmployeeTask';
import { Option } from 'types/Option';

import { useAuth } from 'hooks/useAuth';

import { compactDateTimeDisplay, getPaddedTime } from 'utils/dates';
import { reactSelectStyles } from 'utils/reactSelect';
import { animalToOption, customerToOption, employeeToOption, toOption } from 'utils/selectOptions';

import { RichTextEditor } from 'components/RichTextEditor';

type Props = {
  task: Partial<EmployeeTask>;
  setTask: (task: EmployeeTask) => void;
  isLoading: boolean;
  disablePresets?: boolean;
  contextOptions?: Option[];
  hideModal: () => void;
  onSubmit: (taskToUpdate: EmployeeTask) => void;
};

const MIN_QUERY_LENGTH = 3;

export const TaskForm = ({ task, setTask, isLoading, disablePresets, contextOptions, hideModal, onSubmit }: Props) => {
  const auth = useAuth();

  const [reasonOptions] = useState<Option[]>(employeeTaskTypeOptions);
  const [selectedReason, setSelectedReason] = useState<EmployeeTaskType>(task.type ?? 'CallbackTask');

  const [date, setDate] = useState<Date | null>(task.due_at ? new Date(task.due_at) : new Date());
  const initialTime = task.due_at ? getPaddedTime(new Date(task.due_at)) : getPaddedTime(new Date());
  const [time, setTime] = useState<string>(initialTime);

  const [selectedContext, setSelectedContext] = useState<number | undefined>(task.context_id || undefined);

  const [selectedCustomerOption, setSelectedCustomerOption] = useState<Option | null>(null);
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [, setSelectedCustomer] = useState<Customer | null>(null);

  const [selectedAnimalOption, setSelectedAnimalOption] = useState<Option | null>(null);
  const [animalOptions, setAnimalOptions] = useState<Option[]>([]);

  const [clinicOptions, setClinicOptions] = useState<Option[]>();
  const [selectedClinic, setSelectedClinic] = useState<number | undefined>(task.clinic_id);

  const [assigneeOptions, setAssigneeOptions] = useState<Option[]>([]);
  const [selectedAssignees, setSelectedAssignees] = useState<Option[]>(
    task.assignees ? task.assignees.map(employeeToOption) : []
  );

  useEffect(() => {
    if (task.animal_id) {
      fetchAnimal(task.animal_id, (animal: Animal) => {
        setSelectedAnimalOption(animalToOption(animal));

        if (animal?.customer) {
          const customerOption = customerToOption(animal.customer);
          setSelectedCustomerOption(customerOption);
          setCustomerOptions([customerOption]);
          setSelectedCustomer(animal.customer);
          fetchCustomer(animal.customer.id, setSelectedCustomer);
        }
      });
    }
  }, [task.animal_id]);

  useEffect(() => {
    fetchClinics((clinics) => {
      setClinicOptions(clinics.map(toOption));
    });
  }, []);

  useEffect(() => {
    if (auth?.employee && assigneeOptions.length === 0) {
      const me = {
        label: `Me (${auth.employee.full_name_with_title})`,
        value: auth.employee.id.toString()
      };
      setAssigneeOptions(
        [me].concat([
          { label: 'Any Vet', value: 'vet' },
          { label: 'Any Vet Tech', value: 'vet_tech' },
          { label: 'Any CSR', value: 'csr' }
        ])
      );
    }
  }, [assigneeOptions.length, auth, setAssigneeOptions]);

  const handleReasonSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value as EmployeeTaskType;
    setSelectedReason(selected);
    setTask({ ...task, type: selected });
  };

  const handleClinicSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value;
    setSelectedClinic(Number(selected));
    setTask({ ...task, clinic_id: Number(selected) });
  };

  const handleContextChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedContext(Number(event.target.value));
    setTask({ ...task, context_id: Number(event.target.value) });
  };

  const handleDateChange = (start: Date | null) => {
    if (!start) return;
    const [hours, minutes] = time.split(':');

    start.setHours(Number(hours));
    start.setMinutes(Number(minutes));

    setDate(start);
    setTask({ ...task, due_at: start.toISOString() });
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedStartTime = event.target.value;

    if (changedStartTime && date) {
      const [hours, minutes] = changedStartTime.split(':');
      setTime(changedStartTime);
      date?.setHours(Number(hours));
      date?.setMinutes(Number(minutes));

      setTask({ ...task, due_at: date.toISOString() });
    }
  };

  const loadAnimalOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchAnimalsByQuery(inputValue, { members: true }).then((options) => {
      callback(options.map(animalToOption));
    });
  };

  const loadEmployeeOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchEmployeesByQuery(inputValue).then((options) => {
      if (inputValue) callback(options.map(employeeToOption));
      else callback(options.map(employeeToOption).concat(assigneeOptions));
    });
  };

  const loadCustomerOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchCustomersByQuery(inputValue, { members: true }).then((options) => {
      callback(options.map(customerToOption));
    });
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const markComplete = () => {
    const completedTask = { ...task, status: 'completed' as EmployeeTaskStatus };
    setTask(completedTask);
    onSubmit(completedTask);
  };

  const markDisabled = () => {
    const disabledTask = { ...task, status: 'disabled' as EmployeeTaskStatus };
    setTask(disabledTask);
    onSubmit(disabledTask);
  };

  const handleAssigneesChange = (value: Option[] | null) => {
    if (value) {
      setSelectedAssignees(value);
      setTask({
        ...task,
        employee_ids: value.map((v) => Number(v.value)),
        vet: value.some((v) => v.value === 'vet'),
        vet_tech: value.some((v) => v.value === 'vet_tech'),
        csr: value.some((v) => v.value === 'csr')
      });
    }
  };

  const handleAnimalSelectChange = (option: Option | null) => {
    setSelectedAnimalOption(option);
    setTask({ ...task, animal_id: Number(option?.value) });

    if (option?.value) {
      fetchAnimal(option.value, (animal: Animal) => {
        if (animal?.customer) {
          const customerOption = customerToOption(animal.customer);
          setSelectedCustomerOption(customerOption);
          setCustomerOptions([customerOption]);
          setSelectedCustomer(animal.customer);
          fetchCustomer(animal.customer.id, setSelectedCustomer);
        }
      });
    } else {
      setSelectedCustomerOption(null);
      setSelectedCustomer(null);
    }
  };

  const handleCustomerSelectChange = (option: Option | null) => {
    setSelectedCustomerOption(option);

    if (option?.value) {
      fetchCustomer(option.value, (customer: Customer) => {
        setSelectedCustomer(customer);
        if (customer?.animals) {
          const options = customer.animals.map(animalToOption);
          setAnimalOptions(options);
          setSelectedAnimalOption(options[0]);
        }
      });
    } else {
      setSelectedAnimalOption(null);
      setSelectedCustomer(null);
    }
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(task);
  };

  return (
    <div>
      <CForm className="g-3" onSubmit={submitForm}>
        {task.created_by_employee && task.created_at && (
          <CRow className="mb-3">
            <CCol sm>
              Created by {task.created_by_employee?.full_name_with_title} on {compactDateTimeDisplay(task.created_at)}
            </CCol>
          </CRow>
        )}
        <CRow className="mb-3">
          <CCol sm>
            <CFormInput hidden name="due_at" value={date?.toISOString()} />
            <CDatePicker
              label="Due On"
              date={date}
              locale="en-US"
              firstDayOfWeek={0}
              format="MMM dd, yyyy"
              onDateChange={handleDateChange}
              cleaner={false}
              required
              disabled={task.status !== 'pending'}
              inputReadOnly={task.status !== 'pending'}
            />
            <div className="form-text">Required</div>
          </CCol>
          <CCol sm>
            <CFormInput
              label="Due At"
              type="time"
              onChange={handleTimeChange}
              value={time}
              readOnly={task.status !== 'pending'}
              disabled={task.status !== 'pending'}
            />
            <div className="form-text">Required</div>
          </CCol>
        </CRow>

        <CRow className="mb-3">
          <CCol sm>
            <CFormSelect
              className="form-select"
              id="reason"
              name="reason"
              label="Reason"
              onChange={handleReasonSelectChange}
              value={selectedReason}
              options={reasonOptions}
              required
              disabled={task.status !== 'pending'}
            />
            <div className="form-text">Required</div>
          </CCol>

          <CCol sm>
            <label htmlFor="assignee" className="form-label">
              Assigned To
            </label>
            <AsyncSelect<Option, boolean>
              id="assignee"
              styles={reactSelectStyles}
              name="assignee"
              isMulti={true}
              aria-label="Assigned To"
              placeholder="Type to search..."
              value={selectedAssignees}
              onChange={(options) => handleAssigneesChange(options as Option[])}
              loadOptions={loadEmployeeOptions}
              defaultOptions={assigneeOptions}
              isClearable
              isSearchable
              isDisabled={task.status !== 'pending'}
              required
            />
          </CCol>
        </CRow>

        <CRow className="mb-3">
          <CCol sm>
            <CFormInput hidden id="animal_id" name="animal_id" value={selectedAnimalOption?.value ?? ''} />
            <label htmlFor="animal" className="form-label">
              Patient
            </label>
            <AsyncSelect<Option>
              id="animal"
              aria-label="Patient"
              placeholder="Type to search..."
              styles={reactSelectStyles}
              value={selectedAnimalOption}
              onChange={handleAnimalSelectChange}
              loadingMessage={loadingMessage}
              defaultOptions={animalOptions}
              loadOptions={loadAnimalOptions}
              isClearable
              isSearchable
              required
              isDisabled={disablePresets && task.animal_id !== undefined}
            />
            <div className="form-text">Required</div>
          </CCol>

          <CCol sm>
            <label htmlFor="client" className="form-label">
              Client
            </label>
            <AsyncSelect<Option>
              id="client"
              aria-label="Client"
              placeholder="Type to search..."
              styles={reactSelectStyles}
              value={selectedCustomerOption}
              onChange={handleCustomerSelectChange}
              loadingMessage={loadingMessage}
              defaultOptions={customerOptions}
              loadOptions={loadCustomerOptions}
              isClearable
              isSearchable
            />
          </CCol>
        </CRow>

        <CRow className="mb-3">
          {contextOptions && contextOptions.length > 0 ? (
            <CCol sm>
              <CFormSelect
                className="form-select"
                id="context"
                name="context"
                label="Context"
                onChange={handleContextChange}
                value={selectedContext}
                options={contextOptions}
                required
                disabled={task.status !== 'pending'}
              />
              <div className="form-text">Required</div>
            </CCol>
          ) : task.context ? (
            <CCol sm>
              <label className="form-label w-100">Context</label>
              <Link to={getLinkUrlToContext(task)} target="_blank">
                {getLinkNameForContext(task)}
              </Link>
            </CCol>
          ) : task.context_type ? (
            <CCol sm>
              <label className="form-label w-100">Context</label>
              {task.context_type}{' '}
              {task.consult_id ? (
                <Link to={`/consults/${task.consult_id}`} target="_blank">
                  for Consult {task.consult_id}
                </Link>
              ) : (
                ''
              )}
            </CCol>
          ) : null}

          <CCol sm>
            <CFormInput hidden id="clinic" name="clinic" value={selectedClinic ?? ''} />
            <CFormSelect
              className="form-select"
              id="clinic"
              name="clinic"
              label="Clinic"
              onChange={handleClinicSelectChange}
              value={selectedClinic}
              options={clinicOptions}
              required
              disabled={disablePresets && task.clinic_id !== undefined}
              readOnly={task.status !== 'pending'}
            />
            <div className="form-text">Required</div>
          </CCol>
        </CRow>

        <CRow className="mb-3">
          <CCol sm>
            <RichTextEditor
              id="task_directions"
              label="Task Directions"
              value={task.body}
              onChange={(changedBody) => setTask({ ...task, body: changedBody })}
              placeholder="Note any information or directions on what needs to be done"
              name="task_directions"
              required={task.status === 'pending'}
              text={task.status === 'pending' ? 'Required' : undefined}
              disabled={task.status !== 'pending'}
            />
          </CCol>
        </CRow>

        {task.status === 'pending' && (
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <CLoadingButton loading={isLoading} color="primary" shape="rounded-pill" type="submit">
              {task.id ? 'Update' : 'Create'}
            </CLoadingButton>
            {task.id && (
              <CLoadingButton
                loading={isLoading}
                color="success"
                type="button"
                shape="rounded-pill"
                onClick={markComplete}
              >
                Update and Mark Complete
              </CLoadingButton>
            )}
            {task.id && (
              <CLoadingButton
                loading={isLoading}
                color="danger"
                type="button"
                shape="rounded-pill"
                onClick={markDisabled}
              >
                Disable
              </CLoadingButton>
            )}
            <CButton variant="outline" type="button" shape="rounded-pill" onClick={hideModal}>
              Cancel
            </CButton>
          </div>
        )}
      </CForm>
    </div>
  );
};
