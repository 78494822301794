import * as React from 'react';
import type { SVGProps } from 'react';

const SvgSex = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m3.06 9.785 2.222-2.222a4.2 4.2 0 1 0-.845-.846L2.214 8.94l-.847-.847a.599.599 0 1 0-.846.847l.846.847L.183 10.97a.598.598 0 1 0 .846.846l1.185-1.185.846.847a.599.599 0 1 0 .846-.847zm4.729-8.567a2.993 2.993 0 1 1 0 5.986 2.993 2.993 0 0 1 0-5.986"
    />
    <path
      fill="currentColor"
      d="M17.366 6.007h-2.993a.599.599 0 1 0 0 1.197h1.548l-3.232 3.232a4.2 4.2 0 1 0 .846.846l3.232-3.232v1.548a.598.598 0 0 0 1.197 0V6.605a.6.6 0 0 0-.598-.598M10.183 16.78a2.993 2.993 0 1 1 0-5.985 2.993 2.993 0 0 1 0 5.985"
    />
  </svg>
);
export default SvgSex;
