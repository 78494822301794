import axios, { AxiosError } from 'axios';

import { ApiResponse } from 'api/ResponseTypes';

import { PhysicalExam } from 'types/PhysicalExam';

const fetchPhysicalExamsByAnimal = (animalId: string, onSuccess: (physicalExam: PhysicalExam[]) => void) => {
  const url = `/pim/physical_exams?animal_id=${animalId}`;
  axios.get<PhysicalExam[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchPhysicalExamsForConsult = (
  consultId: number | string,
  onSuccess: (physicalExams: PhysicalExam[]) => void
) => {
  const url = `/pim/physical_exams`;
  axios.get<PhysicalExam[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const createPhysicalExam = (
  physicalExam: Partial<PhysicalExam>,
  { onSuccess, onError }: { onSuccess: (physicalExam: PhysicalExam) => void; onError: (error: string) => void }
) => {
  const url = `/pim/physical_exams`;

  axios
    .post<PhysicalExam>(url, { physical_exam: physicalExam })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<PhysicalExam>>) => onError(error.message));
};

const updatePhysicalExam = (
  physicalExamId: number,
  params: object,
  { onSuccess, onError }: { onSuccess: (physicalExam: PhysicalExam) => void; onError: (error: string) => void }
) => {
  const url = `/pim/physical_exams/${physicalExamId}`;

  axios
    .put<PhysicalExam>(url, { physical_exam: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<PhysicalExam>>) => onError(error.message));
};

export { fetchPhysicalExamsForConsult, fetchPhysicalExamsByAnimal, createPhysicalExam, updatePhysicalExam };
