import { rollbar } from 'App';
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig, isAxiosError } from 'axios';
import { toast } from 'react-toastify';

import { getCookieCurrentClinic } from 'utils/sessionManager';

import { signInUrl } from '../Authentication';
import { fetchCurrentEmployeeUrl } from '../Employees';
import { ApiError } from './ApiError';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  Missing = 404,
  UnprocessableEntity = 422,
  InternalServerError = 500
}

export const onRequestInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const currentClinic = getCookieCurrentClinic();
  if (currentClinic) {
    config.headers['HTTP-CLINIC-ID'] = currentClinic;
  }
  if (config.toastOnError !== false) {
    config.toastOnError = true;
  }
  return config;
};

export function onResponseErrorInterceptor(error: Error | AxiosError) {
  if (isAxiosError<ApiError>(error)) {
    if (error.config?.url === signInUrl || error.config?.url === fetchCurrentEmployeeUrl) {
      return Promise.reject(error);
    }

    const { response } = error;

    switch (response?.status) {
      case StatusCode.InternalServerError: {
        if (process.env.NODE_ENV !== 'development') {
          rollbar.error(error);
        }
        if (error.config?.toastOnError !== false) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorResponse: AxiosResponse<any, unknown> = response;
          toast.error(errorResponse?.data?.message, { autoClose: false });
        }
        break;
      }
      case StatusCode.Forbidden: {
        const currentLocation = window.location.pathname;
        window.location.href = `/?redirect=${encodeURIComponent(currentLocation)}`;
        break;
      }
      case StatusCode.Unauthorized: {
        const currentLocation = window.location.pathname;
        window.location.href = `/?redirect=${encodeURIComponent(currentLocation)}`;
        break;
      }
      case StatusCode.UnprocessableEntity: {
        if (error.config?.toastOnError !== false) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorResponse: AxiosResponse<any, unknown> = response;
          toast.error(errorResponse?.data?.message, { autoClose: false });
        }
        break;
      }
      case StatusCode.Missing: {
        if (error.config?.toastOnError !== false) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errorResponse: AxiosResponse<any, unknown> = response;
          toast.error(errorResponse?.statusText, { autoClose: false });
        }
        break;
      }
      default: {
        if (process.env.NODE_ENV !== 'development') {
          rollbar.info(error);
        }
      }
    }
  }

  return Promise.reject(error);
}
