import axios from 'axios';

import { EstimateTemplate } from 'types/EstimateTemplate';
import { Pagination } from 'types/Pagination';

function fetchEstimateTemplates(
  queryText: string,
  onSuccess: (templates: EstimateTemplate[]) => void,
  setPagination?: (pagination: Pagination) => void,
  page?: number
) {
  const url = `/pim/estimate_templates`;
  axios
    .get<EstimateTemplate[]>(url, {
      params: {
        query: queryText,
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      if (setPagination)
        setPagination({
          page: page || 1,
          perPage: Number(data.headers['per-page']),
          total: Number(data.headers.total)
        });
    });
}

function fetchEstimateTemplate(templateId: string, onSuccess: (template: EstimateTemplate) => void) {
  const url = `/pim/estimate_templates/${templateId}`;
  axios.get<EstimateTemplate>(url).then((data) => onSuccess(data.data.record));
}

function updateEstimateTemplate(
  templateId: string,
  params: EstimateTemplate,
  onSuccess: (template: EstimateTemplate) => void
) {
  const url = `/pim/estimate_templates/${templateId}`;
  axios.put<EstimateTemplate>(url, params).then((data) => onSuccess(data.data.record));
}

function createEstimateTemplate(
  params: EstimateTemplate,
  onSuccess: (template: EstimateTemplate) => void,
  onError?: (error: string) => void
) {
  const url = `/pim/estimate_templates`;
  axios
    .post<EstimateTemplate>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

export { fetchEstimateTemplates, fetchEstimateTemplate, updateEstimateTemplate, createEstimateTemplate };
