import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPawCross = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="currentColor"
      d="M10.994 6.87c-.069.554-.482.97-.923.932s-.743-.518-.674-1.071c.068-.554.468-.86.91-.821.44.038.756.407.687.96m.714.938c-.422-.037-.805.253-.87.775-.066.523.222.976.644 1.012s.818-.357.884-.88c.066-.522-.236-.87-.658-.907m-5.12 1.787c.422-.036.71-.49.645-1.012-.066-.522-.449-.812-.87-.775-.423.036-.725.385-.659.907.066.523.462.917.884.88m2.086-2.9c-.07-.553-.469-.86-.91-.82-.441.038-.757.407-.688.96.069.554.482.97.924.932.44-.039.743-.518.674-1.072m.29 5.478c1.205 0 2.075-.218 2.075-1.02 0-.483-1.02-2.636-2.074-2.636-1.055 0-2.074 2.153-2.074 2.636 0 .802.87 1.02 2.074 1.02m2.145.975a.703.703 0 0 0-.703.704v2.039a.704.704 0 0 1-.703.703H8.297a.704.704 0 0 1-.703-.703v-2.04a.703.703 0 1 0-1.407 0v2.04c0 1.163.947 2.109 2.11 2.109h1.406c1.163 0 2.11-.946 2.11-2.11v-2.038a.703.703 0 0 0-.704-.704M6.891 4.781a.703.703 0 0 0 .703-.703V2.11c0-.387.315-.703.703-.703h1.406c.388 0 .703.316.703.703v1.97a.703.703 0 0 0 1.406 0v-1.97C11.813.946 10.867 0 9.704 0H8.297c-1.163 0-2.11.946-2.11 2.11v1.968c0 .388.315.703.704.703m9 1.407h-2.04a.703.703 0 1 0 0 1.406h2.04c.387 0 .703.315.703.703v1.406a.704.704 0 0 1-.703.703h-2.778a.703.703 0 1 0 0 1.406h2.778c1.163 0 2.109-.946 2.109-2.109V8.297c0-1.163-.946-2.11-2.11-2.11M4.746 10.406H2.11a.704.704 0 0 1-.703-.703V8.297c0-.388.316-.703.703-.703h1.97a.703.703 0 1 0 0-1.407h-1.97C.946 6.188 0 7.135 0 8.298v1.406c0 1.163.946 2.11 2.11 2.11h2.636a.703.703 0 1 0 0-1.407"
    />
  </svg>
);
export default SvgPawCross;
