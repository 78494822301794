import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { paths } from 'routes';

import { CRow, CSmartTable } from '@coreui/react-pro';

import { Payment } from 'types/Payment';

import { compactDateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';
import { generatePaymentLink } from 'utils/stripePayments';

type Props = {
  payments?: Payment[];
};

export const PaymentsTable = ({ payments }: Props) => {
  const columns = [
    {
      key: 'id',
      label: 'ID',
      _props: { scope: 'col' }
    },
    {
      key: 'created_at',
      label: 'Payment Date',
      _props: { scope: 'col' }
    },
    {
      key: 'type',
      label: 'Payment Type',
      _props: { scope: 'col' }
    },
    {
      key: 'amount',
      label: 'Amount',
      _props: { scope: 'col' }
    },
    {
      key: 'payable_details',
      label: 'Payable Type',
      _props: { scope: 'col' }
    },
    {
      key: 'payment_provider_name',
      label: 'Payment Provider',
      _props: { scope: 'col' }
    }
  ];

  const linkForPayableType = (payment: Payment) => {
    switch (payment.payable_type) {
      case 'Invoice':
        return generatePath(paths.invoiceDetails, { id: payment.payable_id });
      case 'Appointment':
        return generatePath(paths.appointmentDetails, { id: payment.payable_id });
      default:
        return '';
    }
  };

  return (
    <CRow className="mb-3">
      <CSmartTable
        itemsPerPage={12}
        pagination
        tableHeadProps={{ color: 'dark' }}
        items={payments ?? []}
        tableProps={{
          'aria-label': 'Payments'
        }}
        columns={columns}
        scopedColumns={{
          id: (payment: Payment) => <td>#{payment.id}</td>,
          created_at: (payment: Payment) => <td>{compactDateTimeDisplay(payment.created_at)}</td>,
          type: (payment: Payment) => <td>{payment.type}</td>,
          amount: (payment: Payment) => <td>{toCurrency(payment.amount)}</td>,
          payable_details: (payment: Payment) => (
            <td>
              <Link to={linkForPayableType(payment)}>{`${payment.payable_type} - ${payment.payable_id}`}</Link>
            </td>
          ),
          payment_provider_name: (payment: Payment) => (
            <td>
              {payment.payment_provider_name === 'Stripe' ? (
                <Link to={generatePaymentLink(payment)}>{payment.payment_provider_name}</Link>
              ) : (
                payment.payment_provider_name
              )}
              {payment.payment_provider_id && ` - ${payment.payment_provider_id}`}
            </td>
          )
        }}
      />
    </CRow>
  );
};
