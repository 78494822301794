import axios, { AxiosResponse } from 'axios';

import { Attachment } from 'types/Attachment';
import { Pagination } from 'types/Pagination';

const downloadAttachedFile = (attachment: Attachment) => {
  const url = `/pim/attachments/${attachment.id}/download`;
  axios.get(url, { responseType: 'blob' }).then((response: AxiosResponse) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', attachment.filename);
    document.body.appendChild(link);
    link.click();
  });
};

/*
  This is a general use function to attach files to any record.
    type: the class the record belongs to, e.g. 'HospitalSheet', 'Animal'
    type_id: the id of the record
*/
const createAttachments = (
  formData: FormData,
  type: string,
  type_id: string | number,
  onSuccess: (attachments: Attachment[]) => void
) => {
  const url = `/pim/attachments`;
  axios
    .post<Attachment[]>(
      url,
      { attachments: formData, record_type: type, record_id: type_id },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((data) => onSuccess(data.data.record));
};

const createAttachmentsV2 = (
  formData: FormData,
  { onSuccess, onError }: { onSuccess: (attachments: Attachment[]) => void; onError: () => void }
) => {
  const url = `/pim/attachments`;
  axios
    .post<Attachment[]>(url, formData)
    .then((data) => onSuccess(data.data.record))
    .catch(onError);
};

const deleteAttachment = (attachmentId: number | string, onSuccess: (attachments: Attachment[]) => void) => {
  const url = `/pim/attachments/${attachmentId}`;

  axios.delete<Attachment[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchAttachmentsForAnimal = (
  animalId: number | string,
  {
    onSuccess,
    page,
    setPagination
  }: { onSuccess: (attachments: Attachment[]) => void; page?: number; setPagination: (pagination: Pagination) => void }
) => {
  const url = `/pim/attachments`;

  axios
    .get<Attachment[]>(url, { params: { record_id: animalId, record_type: 'Animal', page: page || 1 } })
    .then((data) => {
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
      onSuccess(data.data.record);
    });
};

export { createAttachmentsV2, downloadAttachedFile, createAttachments, deleteAttachment, fetchAttachmentsForAnimal };
