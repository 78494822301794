import * as React from 'react';

import { Animal } from 'types/Animal';

import { Pill } from './Pill';

export const NewPatientBadge = ({ animal }: { animal: Animal }) => {
  if (animal.last_appointment_json && !animal.last_appointment_json?.start_time) {
    return <Pill label="new_patient" />;
  }
  return null;
};
