import * as React from 'react';
import type { SVGProps } from 'react';

const SvgNeedle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="m18.752 3.912-2.664-2.664a.85.85 0 0 0-1.199 0l-.132.132a.85.85 0 0 0 0 1.199l.629.629-1.727 1.727-1.299-1.298a.85.85 0 0 0-1.202 0l-.204.203a.85.85 0 0 0-.25.602c0 .227.09.44.25.601l.415.415-3.547 3.547a.265.265 0 0 0 .375.375l3.547-3.547 2.423 2.423-.796.795-.846-.846a.265.265 0 0 0-.375.376l.846.846-.773.773-.846-.846a.265.265 0 1 0-.375.375l.846.846-.774.774-.846-.847a.265.265 0 1 0-.375.376l.846.846-.773.773-.846-.846a.265.265 0 0 0-.375.375l.846.846-1.122 1.121-.508-.508-1.405-1.405-.51-.51 1.397-1.396a.265.265 0 0 0-.376-.375l-1.583 1.584a.265.265 0 0 0 0 .375l.509.509-.438.437a.85.85 0 0 0 0 1.202l.134.134-4.57 4.506a.265.265 0 1 0 .373.378l4.572-4.509.07.07a.847.847 0 0 0 1.201 0l.438-.437.509.508a.265.265 0 0 0 .375 0l5.925-5.925.415.415a.85.85 0 0 0 1.203 0l.203-.204a.845.845 0 0 0 0-1.203l-1.298-1.298.577-.577a.265.265 0 1 0-.375-.375l-.577.577-.656-.656 1.727-1.727.656.656-.377.377a.265.265 0 1 0 .375.376l.377-.378.629.629a.845.845 0 0 0 1.199 0l.132-.132a.85.85 0 0 0 0-1.199ZM6.92 14.11a.32.32 0 0 1-.45 0l-.256-.255-.324-.324a.32.32 0 0 1 0-.451l.438-.438 1.03 1.03zm9.162-5.869a.32.32 0 0 1-.094.226l-.204.204a.32.32 0 0 1-.452 0l-.602-.602-3.4-3.4a.32.32 0 0 1 0-.453l.203-.204a.32.32 0 0 1 .452 0l1.486 1.486 2.517 2.517c.06.06.094.14.094.226Zm2.295-3.505-.132.132a.317.317 0 0 1-.449 0l-1.834-1.834-.013-.015-.014-.012-.803-.803a.32.32 0 0 1 0-.449l.132-.132a.317.317 0 0 1 .449 0l2.664 2.664a.32.32 0 0 1 0 .449Z"
    />
  </svg>
);
export default SvgNeedle;
