import axios from 'axios';

import { LogEvent } from 'types/LogEvent';

const fetchAppointmentChangeLog = (apptId: string, onSuccess: (log: LogEvent[]) => void) => {
  const url = `/pim/appointments/${apptId}/change_log`;
  axios.get<LogEvent[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchInvoiceChangeLog = (apptId: string, onSuccess: (log: LogEvent[]) => void) => {
  const url = `/pim/invoices/${apptId}/change_log`;
  axios.get<LogEvent[]>(url).then((data) => onSuccess(data.data.record));
};

export { fetchAppointmentChangeLog, fetchInvoiceChangeLog };
