import axios from 'axios';

import { PetSex } from 'types/PetSex';

const fetchSexes = (onSuccess: (sexes: PetSex[]) => void) => {
  const url = `/pim/sexes`;
  axios.get<PetSex[]>(url).then((data) => onSuccess(data.data.record));
};

export { fetchSexes };
