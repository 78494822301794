import cn from 'classnames';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { cilShare } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CBadge,
  CButton,
  CCallout,
  CFormTextarea,
  CLoadingButton,
  CSmartPagination,
  CSpinner
} from '@coreui/react-pro';

import { fetchMemos, markRead, replyToMemo } from 'api/Memos';

import { Memo, MemoTree } from 'types/Memo';
import { calculatePages, Pagination } from 'types/Pagination';

import { useAuth } from 'hooks/useAuth';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { usePoll } from 'hooks/usePoll';

import { compactDateTimeDisplay } from 'utils/dates';
import { getRecipients, getUnreadCount, hasReadMessage } from 'utils/memos';

import { ReactComponent as DotIcon } from 'assets/images/dot.svg';
import SvgChat from 'assets/images/SvgChat';

import EmployeePhoto from 'components/EmployeePhoto';
import { Pill } from 'components/Pill';

import styles from './Inbox.module.scss';

import { SendMemoForm } from './SendMemoForm';

type LoadState = 'initial' | 'replying' | 'fetch-after-reply';

const Inbox = (): JSX.Element => {
  const auth = useAuth();

  const [loadState, setLoadState] = useState<LoadState>();
  const [memos, setMemos] = useState<Memo[]>();
  const [pagination, setPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });

  const [showNewMessage, setShowNewMessage] = useState(false);
  const [expandedMemo, setExpandedMemo] = useState<Memo>();

  const [replyingMemo, setReplyingMemo] = useState<Memo>();
  const [replyBody, setReplyBody] = useState<string>();
  const [replyError, setReplyError] = useState<string>();

  useDocumentTitle('Memos');

  const fetch = useCallback(() => {
    fetchMemos({
      onSuccess: handleFetchSuccess,
      setPagination,
      page: pagination.page
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page]);
  usePoll(fetch);

  const handleFetchSuccess = (memos: Memo[]) => {
    setMemos(memos);
    setLoadState(undefined);
    if (expandedMemo) {
      setExpandedMemo(memos.find((memo) => memo.id === expandedMemo.id));
    }
  };

  const handleNewMessage = () => {
    if (auth.employee) {
      setShowNewMessage(true);
    }
  };

  const handleMemoSent = (memo: Memo) => {
    setShowNewMessage(false);
    setExpandedMemo(memo);
    fetchMemos({
      onSuccess: handleFetchSuccess,
      setPagination,
      page: 1
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getMostRecentMemo: any = (memo: MemoTree, current?: Memo) => {
    let mostRecent;
    if (Array.isArray(memo)) {
      for (const childMemo of memo) {
        mostRecent = getMostRecentMemo(childMemo, mostRecent);
      }
    } else {
      // if this child has a more recent created_at than the current, return it
      if (!current || memo.created_at > current.created_at) {
        mostRecent = memo;
      }
    }
    return mostRecent;
  };

  const markThreadRead = (memo: MemoTree) => {
    let markedAnyRead = 0;
    if (Array.isArray(memo)) {
      for (const childMemo of memo) {
        markedAnyRead += markThreadRead(childMemo);
      }
    } else {
      if (!hasReadMessage(memo, auth.employee?.id)) {
        markRead({ memo_id: memo.id });
        markedAnyRead++;
      }
    }
    return markedAnyRead;
  };

  const toggleExpanded = (memo: Memo) => {
    setExpandedMemo(memo);
    if (memo.reply_tree_json) {
      const markedAnyRead = markThreadRead(memo.reply_tree_json);
      if (markedAnyRead > 0) {
        fetchMemos({
          onSuccess: handleFetchSuccess,
          setPagination,
          page: pagination.page
        });
      }
    }
  };

  const handleSetReplyingMemo = (memo: Memo) => {
    setReplyingMemo(memo);
  };

  const replyTo = () => {
    const replyMemo = replyingMemo || expandedMemo;
    if (replyBody && replyMemo) {
      setLoadState('replying');
      setReplyError(undefined);
      replyToMemo({ memo_id: replyMemo.id, body: replyBody, onSuccess: handleReplySent, onError: handleMemoError });
    } else {
      if (!replyBody) setReplyError('Required');
    }
  };

  const handleMemoError = (message: string) => {
    toast.error(`Error sending memo: ${message}`);
    setLoadState(undefined);
  };

  const handleReplySent = (memo: Memo) => {
    toast.success('Reply sent!');
    setReplyBody('');
    setReplyingMemo(undefined);
    setLoadState('fetch-after-reply');
    fetchMemos({
      onSuccess: handleFetchSuccess,
      setPagination,
      page: pagination.page
    });
  };

  const renderMemoPreview = (memo: Memo) => {
    const mostRecentMemo = getMostRecentMemo(memo.reply_tree_json);
    return (
      <div
        key={memo.id}
        className={cn(styles.memo, {
          [styles.expanded]: expandedMemo?.id === memo.id,
          [styles.read]: memo.reply_tree_json && getUnreadCount(memo.reply_tree_json, auth.employee?.id) === 0
        })}
        onClick={() => toggleExpanded(memo)}
      >
        <EmployeePhoto employee={mostRecentMemo.sending_employee} />
        <div className={styles.memoDetails}>
          <div className={styles.date}>
            {compactDateTimeDisplay(memo.created_at)}
            {memo.important && <Pill label="important" />}
            {memo.reply_tree_json && getUnreadCount(memo.reply_tree_json, auth.employee?.id) > 0 && (
              <CBadge color="primary" style={{ height: 'max-content' }}>
                {getUnreadCount(memo.reply_tree_json, auth.employee?.id)}
              </CBadge>
            )}
          </div>
          <div className={cn(styles.highlightedText, styles.clamped)}>Subject: {memo.subject}</div>

          <div className={cn(styles.subject, styles.clamped)}>
            {!hasReadMessage(memo, auth.employee?.id) && <DotIcon className="me-1" />}
            {mostRecentMemo.sending_employee.full_name_with_title}: {mostRecentMemo.body}
          </div>
        </div>
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderMemos: JSX.Element | any = (memos: MemoTree) => {
    if (Array.isArray(memos)) {
      return memos.map(renderMemos);
    }
    return renderMemo(memos);
  };

  const renderMemo = (memo: Memo) => {
    return (
      <div
        key={memo.id}
        className={cn(styles.memoContent, {
          [styles.me]: memo.sending_employee_id === auth.employee?.id
        })}
      >
        {/* TODO need memo.parent returned */}
        {memo.parent_id !== expandedMemo?.id && <div className={styles.replyTo}>{memo.parent?.body}</div>}
        <div className={styles.contentSender}>
          <div className={styles.sender}>
            <EmployeePhoto employee={memo.sending_employee} width={25} height={25} />
            {memo.sending_employee_id === auth.employee?.id ? 'Me' : memo.sending_employee.full_name_with_title}
          </div>
          <div className={styles.date}>{compactDateTimeDisplay(memo.created_at)}</div>
        </div>
        <div className={styles.contentRow}>
          <div className={styles.contentBody}>{memo.body}</div>
          {memo.sending_employee_id !== auth.employee?.id && (
            <CButton color="link" style={{ width: 'fit-content' }} onClick={() => handleSetReplyingMemo(memo)}>
              <CIcon icon={cilShare} />
            </CButton>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {showNewMessage && <SendMemoForm onSuccess={handleMemoSent} onCancel={() => setShowNewMessage(false)} />}

      <div className={styles.memoList}>
        <div className={styles.leftPane}>
          <div>
            <div className={styles.leftHeader}>
              <h1 className="pt-2">
                <SvgChat className="me-2" />
                Memos
              </h1>
              <CButton shape="rounded-pill" size="sm" onClick={handleNewMessage}>
                New
              </CButton>
            </div>

            {loadState === 'initial' && (
              <div className="d-flex justify-content-center">
                <CSpinner color="primary" />
              </div>
            )}

            {memos && memos.filter((memo) => !memo.parent_id).map((memo) => renderMemoPreview(memo))}
            {memos && memos.length === 0 && <div className="text-center">No memos</div>}
          </div>
          {memos && memos.length > 0 && (
            <CSmartPagination
              align="center"
              className={styles.pagination}
              pages={calculatePages(pagination)}
              activePage={pagination.page}
              onActivePageChange={(page) => setPagination({ ...pagination, page })}
            />
          )}
        </div>

        {expandedMemo && (
          <div className={styles.rightPane}>
            <div className={styles.memoHeader}>
              <div className={styles.headerRow}>
                <div>Subject: {expandedMemo.subject}</div>
                <div>{expandedMemo.important && <Pill label="important" />}</div>
              </div>
              <div>
                <div>Sent to: {getRecipients(expandedMemo, auth.employee?.id)}</div>
              </div>
            </div>

            <div className={styles.memoRepliesWrapper}>
              <div className={styles.memoRepliesList}>
                {renderMemos(expandedMemo.reply_tree_json)}
                {loadState === 'fetch-after-reply' && (
                  <div className="d-flex justify-content-end">
                    <CSpinner color="primary" />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.replySection}>
              {replyingMemo && (
                <div className={styles.replyTo}>
                  Reply to:
                  <CCallout color="primary" className={styles.replyToCallout}>
                    <div className={styles.replyToText}>
                      {replyingMemo.sending_employee.full_name_with_title}: {replyingMemo.body}
                    </div>
                  </CCallout>
                </div>
              )}
              <CFormTextarea
                id="reply-body"
                name="reply-body"
                value={replyBody}
                onChange={(event) => setReplyBody(event.target.value)}
                required
                feedbackInvalid={replyError}
                invalid={!!replyError}
              />
              <CLoadingButton
                onClick={() => replyTo()}
                shape="rounded-pill"
                className="mt-2"
                loading={loadState === 'replying'}
              >
                Reply
              </CLoadingButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Inbox;
