import * as React from 'react';
import { useState } from 'react';

import {
  CButton,
  CCol,
  CDatePicker,
  CForm,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { createCommunicationLog } from 'api/CommunicationLogs';

import { CommunicationLog } from 'types/CommunicationLog';
import { EmployeeTask } from 'types/EmployeeTask';
import { getTopicFromContext } from 'types/Topic';

import { getPaddedTime } from 'utils/dates';

type Props = {
  task: EmployeeTask;
  method: 'message' | 'log';
  onSuccess: (communicationLog: CommunicationLog) => void;
  onCancel: () => void;
};

export const TaskCommunicationForm = ({ task, method, onSuccess, onCancel }: Props) => {
  const [time, setTime] = useState(getPaddedTime(new Date()));
  const [date, setDate] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState(false);

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = event.target.value;
    setTime(newTime);

    if (date) {
      const newDate = new Date(date);
      const [hours, minutes] = newTime.split(':');

      newDate.setHours(Number(hours));
      newDate.setMinutes(Number(minutes));

      setDate(newDate);
    }
  };

  const handleCreate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);

    const formJson: Partial<CommunicationLog> = {
      consult_id: task.consult_id,
      topic: task.context_type ? getTopicFromContext[task.context_type] : 'Other',
      context_id: task.context_id,
      context_type: task.context_type,
      employee_task_id: task.id,
      ...Object.fromEntries(formData.entries())
    };
    if (method === 'message') formJson['communication_type'] = 'message';

    createCommunicationLog(formJson, { onSuccess: handleSuccess, onError: handleError });
  };

  const handleSuccess = (communicationLog: CommunicationLog) => {
    setLoading(false);
    onSuccess(communicationLog);
  };

  const handleError = () => setLoading(false);

  return (
    <CForm className="g-3" onSubmit={handleCreate}>
      {method === 'log' && (
        <CRow className="mb-2">
          <CCol>
            <CFormInput hidden name="sent_at" value={date?.toISOString()} />
            <CDatePicker label="Date" firstDayOfWeek={0} onDateChange={setDate} date={date} cleaner={false} required />
          </CCol>
          <CCol>
            <CFormInput label="Time" type="time" onChange={handleTimeChange} value={time} required />
          </CCol>
          <CCol>
            <CFormSelect
              name="communication_type"
              id="communication_type"
              label="Communication Type"
              options={[
                { label: '', value: '' },
                { label: 'In-Person', value: 'in_person' },
                { label: 'Phone', value: 'phone' }
              ]}
              required
            />
          </CCol>
        </CRow>
      )}
      <CRow>
        <CCol>
          <CFormTextarea id="body" label="Body" name="body" rows={3} required />
        </CCol>
      </CRow>

      <div className="mt-2 mb-2 d-grid gap-2 d-md-flex justify-content-md-end">
        <CLoadingButton loading={loading} color="primary" size="sm" type="submit">
          {method === 'message' ? 'Send' : 'Log'}
        </CLoadingButton>
        <CButton color="secondary" type="button" size="sm" onClick={onCancel}>
          Cancel
        </CButton>
      </div>
    </CForm>
  );
};
