import axios from 'axios';

import { PaymentIntent, PaymentIntentParams, RecordPaymentParams } from 'types/StripePayments';

const getConnectionToken = () => {
  const url = `/pim/stripe_payments/connection_token`;
  return axios.post<string>(url).then((data) => {
    return data.data.record;
  });
};

const createPaymentIntent = (params: PaymentIntentParams) => {
  const url = `/pim/stripe_payments/create_payment_intent`;
  return axios.post<PaymentIntent>(url, params).then((data) => {
    return data.data.record;
  });
};

const recordPayment = (params: RecordPaymentParams) => {
  const url = `/pim/stripe_payments/record_payment`;
  return axios.post<PaymentIntent>(url, params).then((data) => {
    return data.data.record;
  });
};

export { getConnectionToken, createPaymentIntent, recordPayment };
