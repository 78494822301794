import axios, { AxiosError } from 'axios';

import { ApiResponse } from 'api/ResponseTypes';

import { Prescription } from 'types/Prescription';

const fetchPrescription = (id: number, { onSuccess }: { onSuccess: (prescription: Prescription) => void }) => {
  const url = `/pim/prescriptions/${id}`;

  axios.get<Prescription>(url).then((data) => onSuccess(data.data.record));
};

const createPrescription = (
  prescription: Partial<Prescription>,
  params: { enqueue: boolean },
  { onSuccess, onError }: { onSuccess: (prescription: Prescription) => void; onError: (error: string) => void }
) => {
  const url = `/pim/prescriptions`;

  axios
    .post<Prescription>(url, { prescription }, { params })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Prescription>>) => onError(error.message));
};

const updatePrescription = (
  prescriptionId: number,
  prescription: Partial<Prescription>,
  params: { enqueue: boolean },
  { onSuccess, onError }: { onSuccess: (prescription: Prescription) => void; onError: (error: string) => void }
) => {
  const url = `/pim/prescriptions/${prescriptionId}`;

  axios
    .put<Prescription>(url, { prescription }, { params })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Prescription>>) => onError(error.message));
};

export { fetchPrescription, createPrescription, updatePrescription };
