import axios from 'axios';

import { Animal, AnimalListItem } from 'types/Animal';

const fetchAnimals = (queryText: string, onSuccess: (animals: AnimalListItem[]) => void) => {
  const url = '/pim/animals';
  axios
    .get<AnimalListItem[]>(url, {
      params: {
        query: queryText
      }
    })
    .then((data) => onSuccess(data.data.record));
};

const fetchAnimalsByQuery = (query: string, { members = false }) => {
  const url = `/pim/animals/`;

  const params = members ? { query: query, members: members } : { query: query };
  return axios
    .get<Animal[]>(url, { params: params })
    .then((response) => response.data.record)
    .catch((error) => []);
};

const fetchAnimal = (animalId: string | number, onSuccess: (animal: Animal) => void) => {
  const url = `/pim/animals/${animalId}`;
  axios.get<Animal>(url).then((data) => onSuccess(data.data.record));
};

const updateAnimal = (
  animalId: number,
  params: object,
  { onSuccess, onError }: { onSuccess: (animal: Animal) => void; onError?: (error: string) => void }
) => {
  const url = `/pim/animals/${animalId}`;
  axios
    .put<Animal>(url, { animal: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError && onError(error.response?.data.message || error.message));
};

const attachAttachmentsToAnimal = (
  animalId: number | string,
  formData: FormData,
  { onSuccess }: { onSuccess: (animal: Animal) => void }
) => {
  const url = `/pim/animals/${animalId}/attach_files`;

  axios.post<Animal>(url, formData).then((data) => onSuccess(data.data.record));
};

export { attachAttachmentsToAnimal, fetchAnimals, fetchAnimalsByQuery, fetchAnimal, updateAnimal };
