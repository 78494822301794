import React from 'react';
import { Link } from 'react-router-dom';

import { CBadge, CSmartTable } from '@coreui/react-pro';

import { HealthPlan, importanceColor, statusColor, typeDisplay } from 'types/HealthPlan';
import { calculatePages, Pagination } from 'types/Pagination';

type HealthPlanTableProps = {
  healthPlansList: HealthPlan[];
  pagination: Pagination;
  setPagination: (pagination: Pagination) => void;
};

const HealthPlansTable = ({ healthPlansList, pagination, setPagination }: HealthPlanTableProps): JSX.Element => {
  return (
    <CSmartTable
      items={healthPlansList}
      itemsPerPage={25}
      pagination={{
        external: true
      }}
      paginationProps={{
        activePage: pagination.page,
        pages: calculatePages(pagination),
        align: 'center'
      }}
      onActivePageChange={(activePage) => setPagination({ ...pagination, page: activePage })}
      columnSorter
      sorterValue={{ column: 'name', state: 'asc' }}
      columns={[
        'name',
        'status',
        'type',
        'importance',
        'recurring',
        {
          key: 'minimum_age_in_months',
          label: 'Min Age (Months)'
        }
      ]}
      scopedColumns={{
        name: (healthPlan: HealthPlan) => {
          return (
            <td className="py-2">
              <Link to={`/health_plans/${healthPlan.id}`}>{healthPlan.name}</Link>
            </td>
          );
        },
        status: (healthPlan: HealthPlan) => {
          return (
            <td className="py-2">
              <CBadge
                color={statusColor(healthPlan.status)}
                shape="rounded-pill"
                aria-label="draft-status"
                aria-hidden={true}
              >
                {healthPlan.status}
              </CBadge>
            </td>
          );
        },
        type: (healthPlan: HealthPlan) => {
          return <td className="py-2">{typeDisplay(healthPlan.type)}</td>;
        },
        importance: (healthPlan: HealthPlan) => {
          return (
            <td className="py-2">
              <CBadge shape="rounded-pill" color={importanceColor(healthPlan.importance)}>
                {healthPlan.importance}
              </CBadge>
            </td>
          );
        },
        recurring: (healthPlan: HealthPlan) => {
          return <td className="py-2">{healthPlan.recurring ? 'Yes' : 'No'}</td>;
        }
      }}
    ></CSmartTable>
  );
};

export default HealthPlansTable;
