import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

import {
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { fetchEmployeesByQuery } from 'api/Employees';
import { createMemo } from 'api/Memos';

import { Memo } from 'types/Memo';
import { Option } from 'types/Option';

import { useAuth } from 'hooks/useAuth';

import { employeeToOption } from 'utils/selectOptions';

import styles from './SendMemoForm.module.scss';

type Props = {
  onSuccess: (memo: Memo) => void;
  onCancel: () => void;
};

export const SendMemoForm = ({ onSuccess, onCancel }: Props) => {
  const auth = useAuth();
  const [messageRecipients, setMessageRecipients] = useState<Option[]>([]);
  const [newMemo, setNewMemo] = useState<Partial<Memo>>({ sending_employee_id: auth.employee?.id });
  const [isLoading, setIsLoading] = useState(false);
  const [toLoadingMessage, setToLoadingMessage] = useState<string>('Loading...');

  const loadEmployeeOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < 3) setToLoadingMessage('Type at least 3 letters');
    else {
      setToLoadingMessage('Loading...');
      fetchEmployeesByQuery(inputValue).then((options) => {
        if (inputValue) callback(options.map(employeeToOption));
        else callback(options.map(employeeToOption));
      });
    }
  };

  const handleSendMemo = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!newMemo) return;

    setIsLoading(true);
    const recipients = messageRecipients.map((recipient) => Number(recipient.value));
    createMemo({
      params: { ...newMemo, recipient_ids: recipients },
      onSuccess: handleMemoSent,
      onError: handleMemoError
    });
  };

  const handleMemoSent = (memo: Memo) => {
    toast.success('Memo sent!');
    setIsLoading(false);
    onSuccess(memo);
  };

  const handleMemoError = (message: string) => {
    toast.error(`Error sending memo: ${message}`);
    setIsLoading(false);
  };

  return (
    <CForm className={styles.form} onSubmit={handleSendMemo}>
      <h2 className="mb-2">New Memo</h2>
      <CRow>
        <CCol xs={9}>
          <CFormLabel htmlFor="to" className="form-label mb-0 mt-2">
            To
          </CFormLabel>
          <AsyncSelect<Option, boolean>
            id="to"
            className={styles.select}
            name="to"
            isMulti={true}
            aria-label="To"
            placeholder="Type to search..."
            value={messageRecipients}
            onChange={(value) => {
              if (value) setMessageRecipients(value as Option[]);
            }}
            loadingMessage={() => {
              return toLoadingMessage;
            }}
            loadOptions={loadEmployeeOptions}
            isClearable
            isSearchable
            required
          />
        </CCol>
        <CCol xs={3} className="d-flex align-items-center">
          <CFormCheck
            id="important"
            name="important"
            label="Mark Important"
            checked={newMemo?.important}
            onChange={(event) => setNewMemo({ ...newMemo, important: event.target.checked })}
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12}>
          <CFormLabel htmlFor="subject" className="form-label mb-0 mt-2">
            Subject
          </CFormLabel>
          <CFormInput
            id="subject"
            name="subject"
            onChange={(event) => setNewMemo({ ...newMemo, subject: event.target.value })}
            required
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol xs={12}>
          <CFormLabel htmlFor="body" className="form-label mb-0 mt-2">
            Body
          </CFormLabel>
          <CFormTextarea
            id="body"
            name="body"
            onChange={(event) => setNewMemo({ ...newMemo, body: event.target.value })}
            required
          />
        </CCol>
      </CRow>
      <div className={styles.buttons}>
        <CLoadingButton
          loading={isLoading}
          className={styles.button}
          shape="rounded-pill"
          color="primary"
          type="submit"
        >
          Send
        </CLoadingButton>
        <CButton
          type="button"
          shape="rounded-pill"
          className={styles.button}
          color="primary"
          variant="outline"
          onClick={() => onCancel()}
        >
          Close
        </CButton>
      </div>
    </CForm>
  );
};
