import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { cilOptions, cilPencil } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
  CSpinner
} from '@coreui/react-pro';

import { fetchConsultTemplate } from 'api/ConsultTemplates';

import {
  cleanConsultTemplateType,
  compileConsultTemplate,
  ConsultTemplate,
  consultTemplateForm,
  FormLine
} from 'types/ConsultTemplate';

import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { compactDateTimeDisplay } from 'utils/dates';

import { ConsultTemplateForm } from 'components/ConsultTemplateForm';
import { Pill } from 'components/Pill';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [template, setTemplate] = useState<ConsultTemplate>();
  const [previewForm, setPreviewForm] = useState<FormLine[]>();
  const [compiledPreviewForm, setCompiledPreviewForm] = useState<string>();

  useDocumentTitle('Consult Template', template?.name);

  useEffect(() => {
    fetchConsultTemplate(id, setTemplate);
  }, [id]);

  useEffect(() => {
    if (template) {
      const form = consultTemplateForm(template);
      setPreviewForm(form);
      setCompiledPreviewForm(compileConsultTemplate(form));
    }
  }, [template]);

  const handlePreviewFormUpdate = (form: FormLine[]) => {
    setPreviewForm(form);
    setCompiledPreviewForm(compileConsultTemplate(form));
  };

  return (
    <>
      {template ? (
        <CCard>
          <CCardHeader className="d-flex flex-row align-items-center justify-content-between">
            <strong style={{ fontSize: '30px' }}>{template.name}</strong>

            <CDropdown alignment="end">
              <CDropdownToggle color="transparent" caret={false} className="p-0">
                <CIcon icon={cilOptions} aria-label="options" className="text-high-emphasis" />
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem
                  role="button"
                  className="align-items-center"
                  href={`/consult_templates/${template.id}/edit`}
                >
                  <CIcon icon={cilPencil} aria-label="edit" className="me-2 text-high-emphasis" />
                  Edit
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CCardHeader>
          <CCardBody>
            <CRow className="mb-3">
              <CCol sm={6}>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Type</div>
                </CRow>
                <CRow className="ps-3">{cleanConsultTemplateType(template.type)}</CRow>
              </CCol>
              <CCol sm={6}>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Status</div>
                </CRow>
                <CRow className="ps-3">
                  <Pill label={template.status} />
                </CRow>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Description</div>
                </CRow>
                <CRow className="ps-3">{template.description}</CRow>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol sm={6}>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Created At</div>
                </CRow>
                <CRow className="ps-3">{template.created_at && compactDateTimeDisplay(template.created_at)}</CRow>
              </CCol>
              <CCol sm={6}>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Updated At</div>
                </CRow>
                <CRow className="ps-3">{template.updated_at && compactDateTimeDisplay(template.updated_at)}</CRow>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Content</div>
                </CRow>
                <CRow className="ps-3">
                  <div className="ps-0" style={{ whiteSpace: 'pre-line' }}>
                    {template.content}
                  </div>
                </CRow>
              </CCol>
            </CRow>

            <CRow className="mb-3">
              <CCol>
                <CRow className="ps-1">
                  <div style={{ fontWeight: 'bold' }}>Previews</div>
                </CRow>
                <CRow className="ps-3 d-flex flex-wrap">
                  <CCol className="ps-0">
                    <CCard className="my-3 p-3">
                      <div className="mb-2 fw-bold">Form Preview</div>
                      {previewForm && <ConsultTemplateForm form={previewForm} updateForm={handlePreviewFormUpdate} />}
                    </CCard>
                  </CCol>
                  <CCol style={{ whiteSpace: 'pre-line' }}>
                    <CCard className="my-3 p-3">
                      <div className="mb-2 fw-bold">Output Preview</div>
                      {compiledPreviewForm && <div dangerouslySetInnerHTML={{ __html: compiledPreviewForm }}></div>}
                    </CCard>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      ) : (
        <CSpinner />
      )}
    </>
  );
};
export default Details;
