import React, { useState } from 'react';

import {
  CAlert,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { requestPasswordReset } from '../../api/Authentication';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    requestPasswordReset(email, {
      onSuccess: () => {
        setLoading(false);
        setMessage("We've sent you an email with instructions to reset your password.");
        setError('');
      },
      onError: () => {
        setLoading(false);
        setError('Error resetting password. Please try again.');
        setMessage('');
      }
    });
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-start p-4">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol xs={7}>
            <CCard>
              <CCardBody>
                <CForm onSubmit={handleSubmit} autoComplete="off" className="d-flex flex-column align-items-center">
                  <h5 className="mt-3 mb-5">Enter your email address to receive a password reset link</h5>
                  <CInputGroup className="mb-4 d-flex align-items-center">
                    <CFormLabel htmlFor="email" style={{ textAlign: 'right', width: '80px' }} className="pe-2">
                      Email
                    </CFormLabel>
                    <CFormInput
                      type="text"
                      id="email"
                      placeholder="Enter your email address"
                      autoComplete="off"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </CInputGroup>
                  {message && <CAlert color="success">{message}</CAlert>}
                  {error && <CAlert color="danger">{error}</CAlert>}
                  <CLoadingButton color="primary" type="submit" className="mb-5" loading={loading}>
                    Submit
                  </CLoadingButton>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;
