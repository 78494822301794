import { DispenseRecord } from 'types/DispenseRecord';

const requiresLogs = (dispenseRecord: DispenseRecord) => {
  return dispenseRecord.needs_lot_number || dispenseRecord.needs_expiration_date;
};

const hasLogs = (dispenseRecord: DispenseRecord) => {
  return dispenseRecord.dispense_logs && dispenseRecord.dispense_logs.length > 0;
};

const isAwaitingLogs = (dispenseRecord: DispenseRecord) => {
  return requiresLogs(dispenseRecord) && !hasLogs(dispenseRecord);
};

export { isAwaitingLogs, hasLogs };
