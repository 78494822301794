import axios from 'axios';

import { AppointmentStatus } from 'types/AppointmentStatus';

const fetchAppointmentStatuses = (onSuccess: (appointmentStatuses: AppointmentStatus[]) => void) => {
  const url = `/pim/appointment_statuses`;
  axios.get<AppointmentStatus[]>(url).then((data) => onSuccess(data.data.record));
};

export { fetchAppointmentStatuses };
