import * as React from 'react';
import type { SVGProps } from 'react';

const SvgWindyHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 12" {...props}>
    <path
      fill="currentColor"
      d="M15.89 2.218c-1.164 0-2.111.947-2.111 2.11h1.055c0-.581.474-1.055 1.055-1.055a1.056 1.056 0 0 1 0 2.11h-3.415c.136-.427.214-.889.214-1.395 0-2.137-1.31-3.88-3.423-3.88C8.044.107 6.97.76 6.362 1.74A3.42 3.42 0 0 0 3.459.107C1.362.107 0 1.833 0 3.988c0 2.787 2.305 4.25 5.372 6.964l1.063.94.956-.845c1.457-1.289 2.146-1.9 2.777-2.497h3.61a1.056 1.056 0 0 1 0 2.11c-.59 0-1.09-.483-1.09-1.055h-1.055c0 .565.228 1.097.642 1.498a2.15 2.15 0 0 0 1.504.613c1.164 0 2.11-.947 2.11-2.111s-.946-2.11-2.11-2.11h-2.572c.29-.33.559-.678.785-1.056h3.897C17.053 6.44 18 5.492 18 4.33c0-1.164-.947-2.111-2.11-2.111m-4.54 3.166H7.412v1.055h3.3c-.263.338-.573.67-.92 1.01l-.048.045H6.357V8.55H8.61a255 255 0 0 1-2.177 1.933l-.363-.321C3.116 7.546 1.055 6.235 1.055 3.988c0-1.36.752-2.825 2.404-2.825 1.31 0 2.375 1.083 2.375 2.427H6.89c0-1.344 1.066-2.427 2.376-2.427 1.636 0 2.368 1.419 2.368 2.825 0 .509-.1.967-.283 1.396"
    />
  </svg>
);
export default SvgWindyHeart;
