import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { cilBeaker } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { getRefillsQueue, updateRefill } from 'api/Refills';

import { Refill } from 'types/Refill';

import { ClinicContext } from 'contexts/ClinicContext';

import { compactDateTimeDisplay, dateDisplay, dateTimeDisplay } from 'utils/dates';

const RefillsQueue = (): JSX.Element => {
  const [queue, setQueue] = useState<Refill[]>([]);
  const [currentRefill, setCurrentRefill] = useState<Refill | null>(null);
  const [isLoadingQueue, setIsLoadingQueue] = useState<boolean>(true);

  const { clinicContext } = useContext(ClinicContext);
  useEffect(() => {
    getRefillsQueue(loadingQueueComplete);
  }, [clinicContext]);

  function loadingQueueComplete(queue: Refill[]) {
    setQueue(queue);
    setIsLoadingQueue(false);
  }

  const handleOpenInEzyVet = () => {
    if (currentRefill) {
      window.open(
        `https://drtreat.usw2.ezyvet.com/?recordclass=Animal&recordid=${currentRefill.prescription_item.prescription.animal.pim_id}`,
        '_blank'
      );
    }
  };

  const handleApproved = () => {
    if (currentRefill) {
      updateRefill(currentRefill.id, 'approved', handleUpdateSuccess);
    }
  };

  const handleDenied = () => {
    if (currentRefill) {
      updateRefill(currentRefill.id, 'denied', handleUpdateSuccess);
    }
  };

  const handleUpdateSuccess = (refill: Refill) => {
    setCurrentRefill(null);
    if (queue.length > 1) {
      setQueue(queue.filter((r: Refill) => r.id !== refill.id));
    } else {
      getRefillsQueue(setQueue);
    }
  };

  function prescriptionItemTable(refill: Refill) {
    return (
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>Start Date</CTableHeaderCell>
            <CTableHeaderCell>Drug Name</CTableHeaderCell>
            <CTableHeaderCell>Prescriber</CTableHeaderCell>
            <CTableHeaderCell>Quantity</CTableHeaderCell>
            <CTableHeaderCell>Remaining</CTableHeaderCell>
            <CTableHeaderCell>EzyVet</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow>
            <CTableDataCell>
              {refill.prescription_item.date_start && dateDisplay(refill.prescription_item.date_start)}
            </CTableDataCell>
            <CTableDataCell>{refill.prescription_item.product?.name}</CTableDataCell>
            <CTableDataCell>{refill.prescription_item.prescription.prescribing_veterinarian_name}</CTableDataCell>
            <CTableDataCell>{refill.prescription_item.quantity}</CTableDataCell>
            <CTableDataCell>{refill.prescription_item.remaining}</CTableDataCell>
            <CTableDataCell>
              <CButton variant="outline" onClick={handleOpenInEzyVet}>
                Open in EzyVet
              </CButton>
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    );
  }

  function getGroupItem(refill: Refill) {
    return (
      <CListGroupItem
        key={refill.id}
        component="button"
        className="d-flex justify-content-between align-items-center"
        active={!!(currentRefill && currentRefill.id === refill.id)}
        onClick={() => {
          setCurrentRefill(refill);
        }}
      >
        {`"${refill.prescription_item.prescription.animal.name}" ${refill.prescription_item.prescription.animal.customer.last_name}`}{' '}
        ({compactDateTimeDisplay(refill.created_at)})
      </CListGroupItem>
    );
  }

  function refillForm(refill: Refill) {
    const animal = refill.prescription_item.prescription.animal;
    const customer = refill.prescription_item.prescription.animal.customer;
    return (
      <CCard>
        <CCardHeader>
          <h4>
            <Link to={`/animals/${animal.id}`}>{animal.name}</Link> -{' '}
            <Link to={`/customers/${customer.id}`}>
              {customer.first_name} {customer.last_name}
            </Link>
          </h4>
        </CCardHeader>
        <CCardBody>
          <p>Refill requested on {dateTimeDisplay(refill.created_at)} for the following medication:</p>
          {prescriptionItemTable(refill)}
          <CForm>
            <div className="d-sm-flex justify-content-center">
              <CButton color="success" className="me-3" onClick={handleApproved}>
                Approved
              </CButton>
              <CButton color="dark" onClick={handleDenied}>
                Denied
              </CButton>
            </div>
          </CForm>
        </CCardBody>
      </CCard>
    );
  }

  return (
    <>
      <h4 className="pb-4">
        <CIcon className="me-2 icon-xl" icon={cilBeaker} />
        Refills Queue
      </h4>
      {queue.length > 0 && (
        <CContainer className="mx-lg-0 mb-5">
          <CRow>
            <CCol className="col-4">
              <CListGroup>{queue.map((refill) => getGroupItem(refill))}</CListGroup>
            </CCol>
            <CCol>
              {currentRefill && refillForm(currentRefill)}
              {!currentRefill && <p>Select a refill request from the list to the left.</p>}
            </CCol>
          </CRow>
        </CContainer>
      )}
      {isLoadingQueue && <CSpinner color="primary" />}
      {!isLoadingQueue && queue.length === 0 && <p>No refill requests in the queue.</p>}
    </>
  );
};

export default RefillsQueue;
