import * as React from 'react';
import type { SVGProps } from 'react';

const SvgCart = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5}>
      <path d="M1.5 1.5h1.305c.81 0 1.448.697 1.38 1.5l-.622 7.47a2.097 2.097 0 0 0 2.092 2.272h7.988c1.08 0 2.024-.885 2.107-1.957l.405-5.625c.09-1.245-.855-2.258-2.108-2.258H4.365M12.188 16.5a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875M6.188 16.5a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875M6.75 6h9" />
    </g>
  </svg>
);
export default SvgCart;
