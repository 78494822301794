import * as React from 'react';
import { Link } from 'react-router-dom';

import { CCol, CContainer, CRow } from '@coreui/react-pro';

import { AbbreviatedAppointment } from 'types/Appointment';

import { compactDateTimeDisplay } from 'utils/dates';

import styles from './UpcomingAppointmentsCard.module.scss';

type Props = {
  futureAppointment?: Partial<AbbreviatedAppointment>;
  pastAppointment?: Partial<AbbreviatedAppointment>;
};

export const UpcomingAppointmentsCard: React.FC<Props> = ({ futureAppointment, pastAppointment }) => {
  return (
    <div>
      <h5 className="mb-1">Appointment(s)</h5>
      <CContainer className={styles.container}>
        {futureAppointment && futureAppointment.start_time && (
          <CRow className="mb-3">
            <CCol xl={7} xxl={5} className={styles.label}>
              <span>Upcoming</span>
            </CCol>
            <CCol xl={5} className={styles.date}>
              <Link to={`/appointments/${futureAppointment.id}`}>
                {compactDateTimeDisplay(futureAppointment.start_time)}
              </Link>
            </CCol>
          </CRow>
        )}

        {pastAppointment && pastAppointment.start_time && (
          <CRow>
            <CCol xl={7} xxl={5} className={styles.label}>
              <span>Last</span>
            </CCol>
            <CCol xl={5} className={styles.date}>
              <Link to={`/appointments/${pastAppointment.id}`}>
                {compactDateTimeDisplay(pastAppointment.start_time)}
              </Link>
            </CCol>
          </CRow>
        )}
      </CContainer>
    </div>
  );
};
