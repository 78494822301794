import * as React from 'react';
import type { SVGProps } from 'react';

const SvgClipboard = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.4}
      d="M5.333 8.133H10M5.333 10.8h2.92M6.666 4h2.667c1.333 0 1.333-.667 1.333-1.333 0-1.333-.666-1.333-1.333-1.333H6.666c-.666 0-1.333 0-1.333 1.333S6 4 6.666 4"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.4}
      d="M10.667 2.68C12.887 2.8 14 3.62 14 6.667v4c0 2.667-.667 4-4 4H6c-3.333 0-4-1.333-4-4v-4C2 3.627 3.113 2.8 5.333 2.68"
    />
  </svg>
);
export default SvgClipboard;
