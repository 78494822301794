import * as React from 'react';
import { Fragment } from 'react';

import { identity } from 'utils/identity';

import { ReactComponent as DotIcon } from 'assets/images/dot.svg';

interface Props {
  items: (string | undefined | React.ReactNode)[];
  separator?: React.ReactNode;
}

export const SpacedItems: React.FC<Props> = ({ items, separator = <DotIcon /> }) => {
  let hasFirstItem = false;
  const separatedItems = items.filter(identity).map((item, index) => {
    const separatedItem = (
      <Fragment key={index}>
        {hasFirstItem && separator}
        <span>{item}</span>
      </Fragment>
    );
    hasFirstItem = true;
    return separatedItem;
  });

  return <>{separatedItems}</>;
};
