import axios from 'axios';

import { CancelReason } from 'types/CancelReason';

function fetchAppointmentCancelReasons(
  onSuccess: (cancelReasons: CancelReason[]) => void,
  onError?: (error: string) => void
) {
  const url = '/pim/appointment_cancel_reasons';
  axios
    .get<CancelReason[]>(url)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

const fetchMembershipCancelReasons = (onSuccess: (cancelReasons: CancelReason[]) => void) => {
  const url = `/pim/cancel_reasons/`;
  axios.get<CancelReason[]>(url).then((data) => onSuccess(data.data.record));
};

export { fetchAppointmentCancelReasons, fetchMembershipCancelReasons };
