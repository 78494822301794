import axios from 'axios';

import { HealthPlan } from 'types/HealthPlan';
import { Pagination } from 'types/Pagination';
import { Status } from 'types/Status';

type HealthPlanQueryParams = {
  query?: string;
  status?: Status;
  product_id?: string;
  trigger_type?: string;
  trigger_id?: string;
};

const fetchHealthPlansByAnimal = (animalId: string, onSuccess: (healthPlans: HealthPlan[]) => void) => {
  const url = `/pim/health_plans?animal_id=${animalId}`;
  axios.get<HealthPlan[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchHealthPlans = (
  onSuccess: (healthPlan: HealthPlan[]) => void,
  setPagination: (pagination: Pagination) => void,
  page?: number
) => {
  const url = `/pim/health_plans`;

  axios
    .get<HealthPlan[]>(url, {
      params: {
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
    });
};

const fetchHealthPlansBy = (
  params: HealthPlanQueryParams,
  onSuccess: (healthPlan: HealthPlan[]) => void,
  setPagination: (pagination: Pagination) => void,
  page?: number
) => {
  const url = '/pim/health_plans';

  axios
    .get<HealthPlan[]>(url, {
      params: {
        query: params.query,
        status: params.status,
        product_id: params.product_id,
        trigger_type: params.trigger_type,
        trigger_id: params.trigger_id,
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
    });
};

const fetchHealthPlansByQuery = (query: string) => {
  const url = '/pim/health_plans';

  return axios
    .get<HealthPlan[]>(url, { params: { query: query } })
    .then((response) => response.data.record)
    .catch((error) => []);
};

const fetchHealthPlanAnimalCount = (
  healthPlanId: string,
  onSuccess: (animalCount: number) => void,
  onError?: (error: string) => void
) => {
  const url = `/pim/health_plans/${healthPlanId}/count_animals_for_health_plan`;
  axios
    .get<{ animal_count: number }>(url)
    .then((data) => onSuccess(data.data.record.animal_count))
    .catch((error) => {
      if (onError) onError(error);
    });
};

const fetchHealthPlan = (healthPlanId: string, onSuccess: (healthPlan: HealthPlan) => void) => {
  const url = `/pim/health_plans/${healthPlanId}`;
  axios.get<HealthPlan>(url).then((data) => onSuccess(data.data.record));
};

function createHealthPlan(params: HealthPlan, onSuccess: (healthPlan: HealthPlan) => void, onError?: () => void) {
  const url = `/pim/health_plans`;
  axios
    .post<HealthPlan>(url, { health_plan: params })
    .then((data) => onSuccess(data.data.record))
    .catch(() => {
      if (onError) {
        onError();
      }
    });
}

function updateHealthPlan(
  healthPlanId: string,
  params: HealthPlan,
  onSuccess: (healthPlan: HealthPlan) => void,
  onError?: () => void
) {
  const url = `/pim/health_plans/${healthPlanId}`;
  axios
    .put<HealthPlan>(url, { health_plan: params })
    .then((data) => onSuccess(data.data.record))
    .catch(() => {
      if (onError) {
        onError();
      }
    });
}

export {
  fetchHealthPlansByAnimal,
  fetchHealthPlans,
  fetchHealthPlanAnimalCount,
  fetchHealthPlansBy,
  fetchHealthPlansByQuery,
  fetchHealthPlan,
  createHealthPlan,
  updateHealthPlan
};
