import axios from 'axios';

import { HospitalSheet } from 'types/HospitalSheet';

type HospitalSheetQueryParams = {
  discharged?: boolean;
  start_time?: string;
  end_time?: string;
};

const fetchHospitalSheetsBy = (
  params: HospitalSheetQueryParams,
  onSuccess: (hospitalSheet: HospitalSheet[]) => void
) => {
  const url = '/pim/hospital_sheets';

  axios
    .get<HospitalSheet[]>(url, {
      params: {
        discharged: params.discharged,
        start_time: params.start_time,
        end_time: params.end_time
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
    });
};

const fetchHospitalSheet = (hospitalSheetId: string | number, onSuccess: (hospitalSheet: HospitalSheet) => void) => {
  const url = `/pim/hospital_sheets/${hospitalSheetId}`;
  axios.get<HospitalSheet>(url).then((data) => onSuccess(data.data.record));
};

function createHospitalSheet(params: Partial<HospitalSheet>, onSuccess: (hospitalSheet: HospitalSheet) => void) {
  const url = `/pim/hospital_sheets`;
  axios.post<HospitalSheet>(url, { hospital_sheet: params }).then((data) => onSuccess(data.data.record));
}

const updateHospitalSheet = (
  hospitalSheetId: string | number,
  params: HospitalSheet,
  onSuccess: (hospitalSheet: HospitalSheet) => void
) => {
  const url = `/pim/hospital_sheets/${hospitalSheetId}`;
  axios.put<HospitalSheet>(url, { hospital_sheet: params }).then((data) => onSuccess(data.data.record));
};

export { fetchHospitalSheetsBy, fetchHospitalSheet, createHospitalSheet, updateHospitalSheet };
