import * as React from 'react';
import type { SVGProps } from 'react';

const SvgChart = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5}>
      <path d="M1.5 1.5v12.75a2.247 2.247 0 0 0 2.25 2.25H16.5" />
      <path d="m3.75 12.75 3.443-4.02a1.497 1.497 0 0 1 2.197-.082l.712.712a1.503 1.503 0 0 0 2.198-.082l3.45-4.028" />
    </g>
  </svg>
);
export default SvgChart;
