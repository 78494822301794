import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPaw = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.308 12.563c-.18 2.167 1.455 3.937 3.63 3.937h3.593c2.445 0 4.125-1.973 3.72-4.387C13.823 9.578 11.379 7.5 8.806 7.5c-2.79 0-5.265 2.28-5.498 5.063M7.854 5.625a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75M12.977 6.525a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M15.75 9.525a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25M2.979 8.025a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
    />
  </svg>
);
export default SvgPaw;
