import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { cilCamera, cilCheckAlt, cilExitToApp, cilOptions, cilPencil, cilX } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImage,
  CListGroup,
  CListGroupItem,
  CRow
} from '@coreui/react-pro';

import { fetchEmployee } from 'api/Employees';

import { Employee, isOrganizationAdmin } from 'types/Employee';
import { PimResourceStatus } from 'types/PimResource';

import { generateCloudinaryPath } from 'utils/cloudinary';
import { compactDateTimeDisplay } from 'utils/dates';

import { useAuth } from '../../hooks/useAuth';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const auth = useAuth();
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [employee, setEmployee] = useState<Employee>();
  const [canEdit, setCanEdit] = useState<boolean>(false);

  useEffect(() => {
    if (auth.employee) {
      setCanEdit(auth.employee.id.toString() === id || isOrganizationAdmin(auth.employee));
    }
  }, [auth.employee, id]);

  useEffect(() => {
    fetchEmployee(id, setEmployee);
  }, [id]);

  const schedulableClinics = useMemo(() => {
    const clinicNames = employee?.pim_resources
      ?.filter((resource) => resource.status === PimResourceStatus.Active)
      .map((resource) => resource.clinic?.name);
    return clinicNames?.join(', ');
  }, [employee?.pim_resources]);

  const handleOpenInEzyVet = () => {
    if (employee && employee.pim_id) {
      window.open(`https://drtreat.usw2.ezyvet.com/?recordclass=User&recordid=${employee.pim_id}`, '_blank');
    }
  };

  const avatarSource = useMemo(() => {
    return {
      uri:
        employee?.photo_provider_id &&
        generateCloudinaryPath({
          imagePublicId: employee.photo_provider_id,
          borderSize: 1000
        })
    };
  }, [employee?.photo_provider_id]);

  return (
    <>
      {employee && (
        <CCard>
          <CCardHeader className="d-flex flex-row align-items-center justify-content-between">
            <h3>{employee.full_name_with_title}</h3>

            <CDropdown alignment="end">
              <CDropdownToggle color="transparent" caret={false} className="p-0">
                <CIcon icon={cilOptions} aria-label="options" className="text-high-emphasis" />
              </CDropdownToggle>
              <CDropdownMenu>
                {canEdit && (
                  <CDropdownItem role="button" className="align-items-center" href={`/employees/${employee.id}/edit`}>
                    <CIcon icon={cilPencil} aria-label="edit" className="me-2 text-high-emphasis" />
                    Edit
                  </CDropdownItem>
                )}
                {employee.pim_id && (
                  <CDropdownItem role="button" className="align-items-center" onClick={handleOpenInEzyVet}>
                    <CIcon icon={cilExitToApp} aria-label="exit" className="me-2 text-high-emphasis" />
                    Open in EzyVet
                  </CDropdownItem>
                )}
              </CDropdownMenu>
            </CDropdown>
          </CCardHeader>
          <CCardBody>
            <CRow className="mb-3">
              <h4 className="d-flex align-items-center">
                <CIcon className="me-2 icon-l" icon={cilCamera} aria-hidden />
                <div>Photo</div>
              </h4>
              <div>
                {avatarSource.uri ? (
                  <CImage
                    alt={employee.full_name_with_title}
                    fluid
                    src={avatarSource.uri}
                    style={{ height: '200px' }}
                  />
                ) : (
                  <i>No photo provided</i>
                )}
              </div>
            </CRow>
            <CRow className="mb-5">
              <CCol>
                <CListGroup>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Email</CCol>
                      <CCol>{employee.email}</CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Bio</CCol>
                      <CCol>{employee.bio ?? <i>None provided</i>}</CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Qualifications</CCol>
                      <CCol>{employee.qualifications}</CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Registration Number</CCol>
                      <CCol>{employee.registration_number}</CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Created On</CCol>
                      <CCol>{compactDateTimeDisplay(employee.created_at)}</CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Updated On</CCol>
                      <CCol>{compactDateTimeDisplay(employee.updated_at)}</CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Is Active</CCol>
                      <CCol>
                        {employee.active ? (
                          <CIcon icon={cilCheckAlt} aria-label="yes" />
                        ) : (
                          <CIcon icon={cilX} aria-label="no" />
                        )}
                      </CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Is Schedulable</CCol>
                      <CCol>
                        {employee.schedulable ? (
                          <CIcon icon={cilCheckAlt} aria-label="yes" />
                        ) : (
                          <CIcon icon={cilX} aria-label="no" />
                        )}
                      </CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Is Vet</CCol>
                      <CCol>
                        {employee.vet ? (
                          <CIcon icon={cilCheckAlt} aria-label="yes" />
                        ) : (
                          <CIcon icon={cilX} aria-label="no" />
                        )}
                      </CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Is Vet Tech</CCol>
                      <CCol>
                        {employee.vet_tech ? (
                          <CIcon icon={cilCheckAlt} aria-label="yes" />
                        ) : (
                          <CIcon icon={cilX} aria-label="no" />
                        )}
                      </CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Is CSR</CCol>
                      <CCol>
                        {employee.csr ? (
                          <CIcon icon={cilCheckAlt} aria-label="yes" />
                        ) : (
                          <CIcon icon={cilX} aria-label="no" />
                        )}
                      </CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Signature on File</CCol>
                      <CCol>
                        {employee.signature_photo_provider_id ? (
                          <CIcon icon={cilCheckAlt} aria-label="yes" />
                        ) : (
                          <CIcon icon={cilX} aria-label="no" />
                        )}
                      </CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Idle Timeout in Minutes</CCol>
                      <CCol>{employee.idle_timeout_in_minutes}</CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Schedulable Clinics</CCol>
                      <CCol>{schedulableClinics}</CCol>
                    </CRow>
                  </CListGroupItem>
                  <CListGroupItem>
                    <CRow>
                      <CCol sm={4}>Roles</CCol>
                      <CCol className="text-capitalize">{employee.roles.map((role) => role.name).join(', ')}</CCol>
                    </CRow>
                  </CListGroupItem>
                </CListGroup>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      )}
    </>
  );
};
export default Details;
