import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
  CSpinner
} from '@coreui/react-pro';

import { fetchCustomer, updateCustomer } from 'api/Customers';
import { fetchEmployeesByQuery } from 'api/Employees';

import { Customer } from 'types/Customer';
import { Option } from 'types/Option';

import { employeeToOption } from 'utils/selectOptions';

const MIN_QUERY_LENGTH = 3;

const Edit = (): JSX.Element => {
  type CustomerEditParams = {
    id: string;
  };
  const { id } = useParams<keyof CustomerEditParams>() as CustomerEditParams;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | undefined>();

  const [selectedPreferredEmployeeOption, setSelectedPreferredEmployeeOption] = useState<Option | null>(null);

  useEffect(() => {
    fetchCustomer(id, (customer: Customer) => {
      setCustomer(customer);

      if (customer.preferred_employee) {
        setSelectedPreferredEmployeeOption(employeeToOption(customer.preferred_employee));
      }

      setIsLoading(false);
    });
  }, [id]);

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const loadEmployeeOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchEmployeesByQuery(inputValue, { vets_only: true }).then((options) => {
      callback(options.map(employeeToOption));
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.type === 'checkbox') {
      setCustomer({ ...customer, [event.target.id]: 'checked' in event.target ? event.target.checked : false });
    } else {
      setCustomer({ ...customer, [event.target.id]: event.target.value });
    }
  };

  const handlePreferredEmployeeSelectChange = (option: Option | null) => {
    const newPreferredEmployeeId = option?.value ?? '';
    setCustomer({ ...customer, preferred_employee_id: newPreferredEmployeeId });
    setSelectedPreferredEmployeeOption(option);
  };

  const handleSuccesss = (customer: Customer) => {
    setIsSubmitting(false);
    navigate(`/customers/${customer.id}`);
  };

  const handleError = (error: string) => {
    setIsSubmitting(false);
  };

  const handleCancel = () => {
    setIsSubmitting(false);
    navigate(`/customers/${id}`);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!customer.email.includes('@') || !customer.email.includes('.')) {
      setEmailError('Email must be a valid email address.');
      setIsSubmitting(false);
      return;
    } else {
      setEmailError(undefined);
    }

    const updateParams = {
      first_name: customer.first_name,
      last_name: customer.last_name,
      email: customer.email,
      phone: customer.phone,
      preferred_employee_id: customer.preferred_employee_id
    };

    updateCustomer(id, updateParams, {
      onSuccess: handleSuccesss,
      onError: handleError
    });
  };

  const loadingState = () => {
    return <CSpinner color="primary" />;
  };

  function loadedState() {
    return (
      <>
        <CCard>
          <CCardHeader>
            <h3>Edit Customer</h3>
          </CCardHeader>
          <CCardBody>
            <CForm onSubmit={handleSubmit}>
              <CRow className="mb-3">
                <CFormLabel htmlFor="first_name" className="col-sm-3 col-form-label">
                  First Name
                </CFormLabel>
                <CCol sm={9}>
                  <CFormInput
                    type="text"
                    id="first_name"
                    required
                    value={customer.first_name || ''}
                    onChange={handleChange}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CFormLabel htmlFor="last_name" className="col-sm-3 col-form-label">
                  Last Name
                </CFormLabel>
                <CCol sm={9}>
                  <CFormInput id="last_name" required value={customer.last_name || ''} onChange={handleChange} />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CFormLabel htmlFor="email" className="col-sm-3 col-form-label">
                  Email
                </CFormLabel>
                <CCol sm={9}>
                  <CFormInput
                    id="email"
                    type="email"
                    required
                    value={customer.email || ''}
                    feedbackInvalid={emailError}
                    invalid={emailError !== undefined}
                    onChange={handleChange}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3 align-items-center">
                <CFormLabel htmlFor="phone" className="col-sm-3 col-form-label">
                  Phone Number
                </CFormLabel>
                <CCol sm={9}>
                  <CFormInput id="phone" required value={customer.phone || ''} onChange={handleChange} />
                </CCol>
              </CRow>

              <CRow className="mb-3 align-items-center">
                <CFormLabel htmlFor="preferred_employee_id" className="col-sm-3 col-form-label">
                  Preferred Vet
                </CFormLabel>

                <CCol sm={9}>
                  <AsyncSelect<Option>
                    id="preferred_employee_id"
                    placeholder="Type to search..."
                    aria-label="Preferred Vet"
                    value={selectedPreferredEmployeeOption}
                    onChange={handlePreferredEmployeeSelectChange}
                    loadingMessage={loadingMessage}
                    loadOptions={loadEmployeeOptions}
                    isClearable
                    isSearchable
                  />
                </CCol>
              </CRow>

              <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
                <CButton color="secondary" disabled={isSubmitting} onClick={handleCancel}>
                  Cancel
                </CButton>
                <CLoadingButton color="primary" type="submit" loading={isSubmitting} disabled={isSubmitting}>
                  Update
                </CLoadingButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </>
    );
  }

  return isLoading ? loadingState() : loadedState();
};

export default Edit;
