import { Clinic } from './Clinic';
import { Organization } from './Organization';
import { PimResource } from './PimResource';
import { Role } from './Role';

export type Employee = {
  active: boolean;
  bio?: string;
  code: string;
  created_at: string;
  clinics: Clinic[];
  email: string;
  first_name: string;
  full_name_with_title: string;
  id: number;
  idle_timeout_in_minutes: number;
  last_name: string;
  organization_id: number;
  organization: Organization;
  password?: string;
  password_confirmation?: string;
  photo_provider_id?: string;
  pim_id: number;
  pim_name: string;
  pim_resources: Partial<PimResource>[];
  qualifications: string | null;
  registration_number: string | null;
  roles: Role[];
  salesperson: boolean;
  schedulable: boolean;
  signature_photo_provider_id?: string;
  title?: string;
  updated_at: string;
  vet: boolean;
  vet_tech: boolean;
  csr: boolean;
};

export function isOrganizationAdmin(employee: Employee) {
  const isAdmin = employee.roles.some((role) => role.name.match(/organization admin/i));
  return isAdmin;
}

export function idleTimeoutInMilliseconds(employee?: Employee | null) {
  if (!employee) {
    return 10 * 60 * 1000;
  }
  return employee.idle_timeout_in_minutes * 60 * 1000;
}
