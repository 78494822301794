import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import {
  CButton,
  CCol,
  CDatePicker,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CInputGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CMultiSelect,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSpinner
} from '@coreui/react-pro';

import { updateAnimal } from 'api/Animals';
import { fetchBreeds } from 'api/Breeds';
import { fetchSexes } from 'api/Sex';
import { fetchSpecies } from 'api/Species';

import { Animal } from 'types/Animal';
import { Breed } from 'types/Breed';
import { PetSex } from 'types/PetSex';
import { PetSpecies } from 'types/PetSpecies';

import { reactSelectStyles } from 'utils/reactSelect';

import SvgCalendar from 'assets/images/SvgCalendar';
import SvgChecklist from 'assets/images/SvgChecklist';
import SvgDislike from 'assets/images/SvgDislike';
import SvgMicroChip from 'assets/images/SvgMicrochip';
import SvgNeedle from 'assets/images/SvgNeedle';
import SvgPaw from 'assets/images/SvgPaw';
import SvgPawCross from 'assets/images/SvgPawCross';
import SvgScale from 'assets/images/SvgScale';
import SvgSex from 'assets/images/SvgSex';

import { IconLabel } from 'components/IconLabel';

import { compactDateDisplay } from '../../utils/dates';

interface SelectOption {
  value: number;
  text: string;
  selected: boolean;
}

interface Option {
  value: string;
  label: string;
}

type EditAnimalProps = {
  animal: Animal;
  setAnimal: (animal: Animal) => void;
  onSuccess: (animal: Animal) => void;
  onCancel: () => void;
};

const EditAnimalModal = ({ animal, setAnimal, onSuccess, onCancel }: EditAnimalProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<'basic' | 'medical' | 'behavior'>('basic');

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [species, setSpecies] = useState<SelectOption[]>();
  const [breeds, setBreeds] = useState<SelectOption[]>();
  const [sexes, setSexes] = useState<Option[]>();
  const personalities = [
    { value: 'timid', label: 'timid' },
    { value: 'social', label: 'social' },
    { value: 'calm', label: 'calm' },
    { value: 'excitable', label: 'excitable' }
  ];
  const dislikes = [
    { value: 'Vet clinics', label: 'Vet clinics' },
    { value: 'Exam rooms', label: 'Exam rooms' },
    { value: 'Unfamiliar people', label: 'Unfamiliar people' },
    { value: 'Unfamiliar animals', label: 'Unfamiliar animals' },
    { value: 'Up on the exam table', label: 'Up on the exam table' },
    { value: 'Ear exam/cleaning', label: 'Ear exam/cleaning' },
    { value: 'Rectal temperature', label: 'Rectal temperature' },
    { value: 'Nail trim', label: 'Nail trim' }
  ];

  const [birthdate, setBirthdate] = useState<string>();
  const [deathdate, setDeathdate] = useState<string>();
  const [rabiesdate, setRabiesdate] = useState<string>();
  const [desexdate, setDesexdate] = useState<string>();

  const handleDateChange = (id: string, date?: Date | null, formattedDate?: string | null) => {
    setAnimal({ ...animal, [id]: formattedDate });
  };

  const handleSexChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event) {
      setAnimal({ ...animal, sex_id: Number(event.target.value) });
    }
  };

  const handleSpeciesChange = (selected?: SelectOption[]) => {
    if (selected && selected.length > 0 && selected[0].value !== animal.species_id) {
      setAnimal({ ...animal, species_id: selected[0].value, breed: null });
      setBreeds(undefined);
    }
  };

  const handleBreedChange = (selected?: SelectOption[]) => {
    if (selected && selected[0] && selected[0].value) {
      const breed = { id: selected[0].value, name: selected[0].text, class_name: 'Breed' };
      setAnimal({ ...animal, breed_id: selected[0].value, breed: breed });
    }
  };

  const handlePersonalitiesChange = (selected?: Option[]) => {
    if (selected && selected[0] && selected[0].value) {
      setAnimal({ ...animal, personality_trait_list: selected.map((trait) => trait.value) });
    } else {
      setAnimal({ ...animal, personality_trait_list: [] });
    }
  };

  const handleDislikesChange = (selected?: Option[]) => {
    if (selected && selected[0] && selected[0].value) {
      setAnimal({ ...animal, pet_peeve_list: selected.map((trait) => trait.value) });
    } else {
      setAnimal({ ...animal, pet_peeve_list: [] });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (event.target.type === 'checkbox' && 'checked' in event.target) {
      // clear any dependent fields
      if (event.target.id === 'desexed' && event.target.checked === false) {
        setAnimal({ ...animal, [event.target.id]: event.target.checked, date_of_desex: null });
        setDesexdate(undefined);
      } else if (event.target.id === 'dead' && event.target.checked === false) {
        setAnimal({ ...animal, [event.target.id]: event.target.checked, date_of_death: null, death_reason: null });
        setDeathdate(undefined);
      } else {
        setAnimal({ ...animal, [event.target.id]: event.target.checked });
      }
    } else {
      setAnimal({ ...animal, [event.target.id]: event.target.value });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    // backend expects a comma separated string for personality_trait_list and pet_peeve_list
    const animalToSave = {
      ...animal,
      personality_trait_list: animal.personality_trait_list?.join(', '),
      pet_peeve_list: animal.pet_peeve_list?.join(', '),
      breed_id: animal.breed?.id
    };
    updateAnimal(animal.id, animalToSave, {
      onSuccess: updateSuccess,
      onError: updateError
    });
  };

  useEffect(() => {
    // Death, Rabies, and Desex dates are datetimes rather than dates, so we need to remove their time component
    if (animal.date_of_birth) setBirthdate(compactDateDisplay(animal.date_of_birth));
    if (animal.date_of_death) setDeathdate(compactDateDisplay(animal.date_of_death.substring(0, 10)));
    if (animal.date_of_rabies_vaccination)
      setRabiesdate(compactDateDisplay(animal.date_of_rabies_vaccination.substring(0, 10)));
    if (animal.date_of_desex) setDesexdate(compactDateDisplay(animal.date_of_desex.substring(0, 10)));
  }, [animal]);

  useEffect(() => {
    fetchSpecies((species: PetSpecies[]) => {
      setSpecies(
        species.map((species) => {
          return {
            value: species.id,
            text: species.name,
            selected: species.id === animal.species_id
          };
        })
      );
    });
  }, [animal.sex?.id, animal.species_id, animal.id]);

  useEffect(() => {
    if (!sexes)
      fetchSexes((sexes: PetSex[]) => {
        setSexes(
          sexes.map((sex) => {
            return {
              value: sex.id.toString(),
              label: sex.name
            };
          })
        );
      });
  }, [animal, sexes]);

  useEffect(() => {
    if (animal.species_id) {
      fetchBreeds(animal.species_id, (breeds: Breed[]) => {
        setBreeds(
          breeds.map((breed) => {
            return {
              value: breed.id,
              text: breed.name,
              selected: animal.breed?.id ? breed.id === animal.breed?.id : false
            };
          })
        );
      });
    }
  }, [animal.breed?.id, animal.species_id]);

  const updateSuccess = (animal: Animal) => {
    setIsSubmitting(false);
    onSuccess(animal);
  };

  const updateError = (error: string) => {
    setIsSubmitting(false);
  };

  const handleCancel = () => {
    setIsSubmitting(false);
    onCancel();
  };

  return (
    <CModal className="show d-block" visible size="lg" fullscreen="sm" onClose={onCancel}>
      <CModalHeader>
        <h3>Edit Animal</h3>
      </CModalHeader>
      <CModalBody>
        <CForm onSubmit={handleSubmit}>
          <CNav variant="underline" className="mb-3" color="primary">
            <CNavItem>
              <CNavLink role="button" active={activeTab === 'basic'} onClick={() => setActiveTab('basic')}>
                Basic Info
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink role="button" active={activeTab === 'medical'} onClick={() => setActiveTab('medical')}>
                Medical Care Info
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink role="button" active={activeTab === 'behavior'} onClick={() => setActiveTab('behavior')}>
                Behavior Notes
              </CNavLink>
            </CNavItem>
          </CNav>

          {activeTab === 'basic' && (
            <div>
              <CRow className="mb-3">
                <CCol xs={6}>
                  <CFormInput
                    type="text"
                    id="name"
                    name="name"
                    label={<IconLabel icon={SvgPaw} label="Name" />}
                    value={animal.name || ''}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormSelect
                    id="sex"
                    name="sex"
                    label={<IconLabel icon={SvgSex} label="Sex" />}
                    options={sexes}
                    value={animal.sex_id}
                    onChange={(selected) => handleSexChange(selected)}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol xs={6}>
                  <CDatePicker
                    id="date_of_birth"
                    label={<IconLabel icon={SvgCalendar} label="Date of Birth" />}
                    date={birthdate || null}
                    locale="en-US"
                    firstDayOfWeek={0}
                    format="yyyy-MM-dd"
                    maxDate={new Date()}
                    onDateChange={(date: Date | null, formattedDate?: string | undefined) =>
                      handleDateChange('date_of_birth', date, formattedDate)
                    }
                  />
                </CCol>
                <CCol xs={6}>
                  {species && (
                    <CMultiSelect
                      id="species"
                      multiple={false}
                      label={<IconLabel icon={SvgPaw} label="Species" />}
                      options={species}
                      virtualScroller
                      visibleItems={8}
                      optionsStyle="text"
                      onChange={(selected) => handleSpeciesChange(selected as SelectOption[])}
                    />
                  )}
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol xs={6}>
                  {breeds && (
                    <CMultiSelect
                      id="breed"
                      label={<IconLabel icon={SvgPaw} label="Breed" />}
                      multiple={false}
                      options={breeds}
                      virtualScroller
                      visibleItems={8}
                      optionsStyle="text"
                      onChange={(selected) => handleBreedChange(selected as SelectOption[])}
                    />
                  )}
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="weight">
                    <IconLabel icon={SvgScale} label="Weight" />
                  </CFormLabel>
                  <CInputGroup>
                    <CFormInput
                      min={0}
                      type="text"
                      id="weight"
                      aria-label="weight"
                      value={animal.weight || ''}
                      onChange={handleChange}
                    />
                    <CFormSelect
                      id="weight_unit"
                      aria-label="weight_unit"
                      options={['lb', 'kg']}
                      value={animal.weight_unit || 'kg'}
                      onChange={handleChange}
                    />
                  </CInputGroup>
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol sm={12}>
                  <CFormTextarea
                    id="internal_notes"
                    value={animal.internal_notes || ''}
                    rows={5}
                    label={<IconLabel icon={SvgChecklist} label="Internal Notes" />}
                    onChange={handleChange}
                  />
                </CCol>
              </CRow>
            </div>
          )}

          {activeTab === 'medical' && (
            <div>
              <CRow className="mb-3">
                <CCol xs={6}>
                  <CFormInput
                    type="text"
                    id="rabies_number"
                    name="rabies_number"
                    label={<IconLabel icon={SvgNeedle} label="Rabies Number" />}
                    value={animal.rabies_number || ''}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={6}>
                  <CDatePicker
                    id="date_of_rabies_vaccination"
                    label={<IconLabel icon={SvgCalendar} label="Date of Rabies Vaccination" />}
                    date={rabiesdate || null}
                    locale="en-US"
                    firstDayOfWeek={0}
                    format="yyyy-MM-dd"
                    onDateChange={(date: Date | null, formattedDate?: string | undefined) =>
                      handleDateChange('date_of_rabies_vaccination', date, formattedDate)
                    }
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol xs={6}>
                  <CFormInput
                    type="text"
                    id="microchip_number"
                    name="microchip_number"
                    label={<IconLabel icon={SvgMicroChip} label="Microchip Number" />}
                    value={animal.microchip_number || ''}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormInput
                    type="text"
                    id="insurance_provider_name"
                    label={<IconLabel icon={SvgPawCross} label="Insurance Provider" />}
                    value={animal.insurance_provider_name || ''}
                    onChange={handleChange}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3 align-items-center">
                <CCol xs={6} className="d-flex justify-content-between align-items-center">
                  <CFormLabel htmlFor="resuscitate" className="col-sm-3 col-form-label w-auto">
                    Should Resuscitate
                  </CFormLabel>
                  <CFormSwitch id="resuscitate" checked={animal.resuscitate || false} onChange={handleChange} />
                </CCol>
                <CCol xs={6} className="d-flex justify-content-between align-items-center">
                  <CFormLabel htmlFor="needs_sedation" className="col-sm-3 col-form-label w-auto">
                    Needs Sedation
                  </CFormLabel>
                  <CFormSwitch id="needs_sedation" checked={animal.needs_sedation || false} onChange={handleChange} />
                </CCol>
              </CRow>
              <CRow className="mb-3 align-items-center">
                <CCol xs={6} className="d-flex justify-content-between align-items-center">
                  <CFormLabel htmlFor="desexed" className="col-sm-3 col-form-label w-auto">
                    Is Desexed
                  </CFormLabel>
                  <CFormSwitch id="desexed" checked={animal.desexed || false} onChange={handleChange} />
                </CCol>
                {animal.desexed && (
                  <CCol xs={6}>
                    <CDatePicker
                      id="date_of_desex"
                      date={desexdate || null}
                      label={<IconLabel icon={SvgCalendar} label="Date of Desex" />}
                      locale="en-US"
                      firstDayOfWeek={0}
                      format="yyyy-MM-dd"
                      onDateChange={(date: Date | null, formattedDate?: string | undefined) =>
                        handleDateChange('date_of_desex', date, formattedDate)
                      }
                    />
                  </CCol>
                )}
              </CRow>
              <CRow className="mb-3">
                <CCol xs={6} className="d-flex justify-content-between align-items-center">
                  <CFormLabel htmlFor="dead" className="col-sm-3 col-form-label w-auto">
                    Is Dead
                  </CFormLabel>
                  <CFormSwitch id="dead" checked={animal.dead || false} onChange={handleChange} />
                </CCol>
                <CCol xs={6}>
                  {animal.dead && (
                    <CDatePicker
                      id="date_of_death"
                      label={<IconLabel icon={SvgCalendar} label="Date of Death" />}
                      date={deathdate || null}
                      locale="en-US"
                      firstDayOfWeek={0}
                      format="yyyy-MM-dd"
                      onDateChange={(date: Date | null, formattedDate?: string | undefined) =>
                        handleDateChange('date_of_death', date, formattedDate)
                      }
                    />
                  )}
                </CCol>
              </CRow>
              {animal.dead && (
                <CRow className="mb-3">
                  <CCol xs={12}>
                    <CFormTextarea
                      id="death_reason"
                      value={animal.death_reason || ''}
                      label={<IconLabel icon={SvgChecklist} label="Death Reason" />}
                      onChange={handleChange}
                    />
                  </CCol>
                </CRow>
              )}
            </div>
          )}

          {activeTab === 'behavior' && (
            <div>
              <CRow className="mb-3 align-items-center">
                <CFormLabel htmlFor="hostile" className="col-sm-3 col-form-label">
                  Is Hostile
                </CFormLabel>
                <CCol xs={9}>
                  <CFormSwitch id="hostile" checked={animal.hostile || false} onChange={handleChange} />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol xs={6}>
                  <CFormSelect
                    id="favorite_treat"
                    name="favorite_treat"
                    label={<IconLabel icon={SvgPaw} label="Favorite Treat" />}
                    options={['', 'Chicken', 'Beef', 'Hypoallergenic treat', 'Metabolic treat']}
                    value={animal.favorite_treat || undefined}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormInput
                    type="text"
                    id="fas_score"
                    label={<IconLabel icon={SvgPawCross} label="FAS Score" />}
                    value={animal.fas_score || ''}
                    onChange={handleChange}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3 align-items-center">
                <CCol xs={12}>
                  <IconLabel icon={SvgPaw} label="Personality" />
                  <Select<Option, boolean>
                    isMulti
                    name="personalities"
                    styles={reactSelectStyles}
                    options={personalities}
                    onChange={(selected) => handlePersonalitiesChange(selected as Option[])}
                    value={animal.personality_trait_list?.map((trait) => ({ value: trait, label: trait }))}
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol xs={12}>
                  <IconLabel icon={SvgDislike} label="Dislikes" />
                  <Select<Option, boolean>
                    isMulti
                    name="dislikes"
                    styles={reactSelectStyles}
                    options={dislikes}
                    onChange={(selected) => handleDislikesChange(selected as Option[])}
                    value={animal.pet_peeve_list?.map((peeve) => ({ value: peeve, label: peeve }))}
                  />
                </CCol>
              </CRow>
            </div>
          )}

          <div className="d-grid gap-4 d-sm-flex justify-content-sm-end mt-3">
            <CButton color="secondary" disabled={isSubmitting} onClick={handleCancel}>
              Cancel
            </CButton>
            <CButton color="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting && <CSpinner size="sm" />} Save
            </CButton>
          </div>
        </CForm>
      </CModalBody>
    </CModal>
  );
};

export default EditAnimalModal;
