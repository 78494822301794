import axios from 'axios';

import { Breed } from 'types/Breed';

const fetchBreeds = (speciesId: number, onSuccess: (breeds: Breed[]) => void) => {
  const url = `/pim/breeds?species_id=${speciesId}`;
  axios.get<Breed[]>(url).then((data) => onSuccess(data.data.record));
};

export type IdexxBreed = {
  code: string;
  name: string;
  speciesCode: string;
};
const fetchIdexxBreeds = (onSuccess: (breeds: IdexxBreed[]) => void) => {
  const url = `/pim/breeds?vendor=idexx`;
  axios.get<IdexxBreed[]>(url).then((data) => onSuccess(data.data.record));
};

export type AntechBreed = {
  id: number;
  name: string;
  breedExtId: string;
  speciesExtId: string;
};

export type AntechSpecies = {
  id: number;
  name: string;
  breed: AntechBreed[];
};

const fetchAntechBreeds = (onSuccess: (species: AntechSpecies[]) => void) => {
  const url = `/pim/breeds?vendor=antech`;
  axios.get<AntechSpecies[]>(url).then((data) => onSuccess(data.data.record));
};

const updateBreed = (breedId: number, breed: Partial<Breed>, onSuccess: (breed: Breed) => void) => {
  const url = `/pim/breeds/${breedId}`;
  axios.put<Breed>(url, breed).then((data) => onSuccess(data.data.record));
};

export { fetchBreeds, fetchIdexxBreeds, fetchAntechBreeds, updateBreed };
