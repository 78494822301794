import * as React from 'react';
import type { SVGProps } from 'react';

const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M8.954 18.959c-.393 0-.787-.1-1.147-.3a2.27 2.27 0 0 1-1.163-2V12.2c0-.425-.27-1.05-.525-1.375L3.006 7.5C2.49 6.975 2.09 6.06 2.09 5.384V3.45c0-1.341 1-2.391 2.27-2.391h10.927c1.254 0 2.27 1.033 2.27 2.308v1.85c0 .875-.517 1.883-1.008 2.375a.61.61 0 0 1-.868 0 .636.636 0 0 1 0-.883c.303-.309.647-1 .647-1.492v-1.85c0-.583-.467-1.058-1.04-1.058H4.357c-.582 0-1.04.5-1.04 1.141v1.934c0 .308.245.916.565 1.241L7.037 10c.418.525.827 1.409.827 2.2v4.459c0 .55.369.816.533.908.352.2.77.192 1.098-.008l1.146-.75c.238-.142.467-.6.467-.909 0-.341.279-.625.615-.625s.614.284.614.625c0 .75-.459 1.609-1.04 1.967l-1.139.75a2.3 2.3 0 0 1-1.204.342"
    />
    <path
      fill="currentColor"
      d="M13.165 14.391c-1.786 0-3.236-1.475-3.236-3.291 0-1.817 1.45-3.292 3.236-3.292S16.4 9.283 16.4 11.1s-1.45 3.291-3.236 3.291m0-5.333c-1.106 0-2.007.917-2.007 2.042s.9 2.041 2.007 2.041c1.106 0 2.007-.916 2.007-2.041s-.902-2.042-2.007-2.042"
    />
    <path
      fill="currentColor"
      d="M16.277 14.892a.6.6 0 0 1-.434-.183l-.82-.834a.636.636 0 0 1 0-.883.61.61 0 0 1 .87 0l.818.833a.637.637 0 0 1 0 .884.62.62 0 0 1-.434.183"
    />
  </svg>
);
export default SvgFilter;
