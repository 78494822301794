import * as React from 'react';
import type { SVGProps } from 'react';

const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.575 13.613H4.36c-2.106 0-2.806-1.4-2.806-2.806V5.193c0-2.106.7-2.806 2.806-2.806h4.214c2.106 0 2.806.7 2.806 2.806v5.614c0 2.106-.706 2.806-2.806 2.806"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m13.228 11.4-1.853-1.3V5.893l1.853-1.3c.907-.633 1.654-.246 1.654.867v5.08c0 1.113-.747 1.5-1.654.86M7.883 7.334a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
    />
  </svg>
);
export default SvgVideo;
