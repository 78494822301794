import * as React from 'react';
import type { SVGProps } from 'react';

const SvgViewConversation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      d="M8.8 14.247c-.44.586-1.16.586-1.6 0l-1-1.334a.77.77 0 0 0-.534-.266h-.333c-2.667 0-4-.667-4-4V5.313q0-4 4-4h5.333q4 0 4 4v3.334"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M12.133 14.267a2.133 2.133 0 1 0 0-4.267 2.133 2.133 0 0 0 0 4.267M14.667 14.667 14 14M10.665 7.333h.006M7.997 7.333h.006M5.33 7.333h.006"
    />
  </svg>
);
export default SvgViewConversation;
