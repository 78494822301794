import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CAlert, CCol, CRow, CSmartTable, CSpinner } from '@coreui/react-pro';

import { fetchAppointmentTypes } from 'api/AppointmentTypes';

import { AppointmentType } from 'types/AppointmentType';

import { ColorSquare } from '../../components/ColorSquare';

const List = (): JSX.Element => {
  const [appointmentTypes, setAppointmentTypes] = React.useState<AppointmentType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchAppointmentTypes(loadingSuccess, loadingFailed);
  }, []);

  function loadingSuccess(appointmentType: AppointmentType[]) {
    setAppointmentTypes(appointmentType);
    setIsLoading(false);
  }

  function loadingFailed(_: string) {
    setIsLoading(false);
  }

  return (
    <CRow>
      <CCol xs={12}>
        {isLoading && <CSpinner color="primary" />}
        {!isLoading && (!appointmentTypes || appointmentTypes.length === 0) && (
          <CAlert color="dark">No appointment types</CAlert>
        )}
        {appointmentTypes && appointmentTypes.length > 0 && (
          <CSmartTable
            items={appointmentTypes}
            itemsPerPage={25}
            tableHeadProps={{ color: 'dark' }}
            tableProps={{ bordered: true }}
            columnSorter
            columns={[
              {
                key: 'name_in_pim',
                label: 'Name'
              },
              'color',
              {
                key: 'duration',
                label: 'Duration (m)'
              },
              'dropoff',
              'tech_appointment',
              'client_schedulable'
            ]}
            scopedColumns={{
              name_in_pim: (type: AppointmentType) => {
                return (
                  <td className="py-2">
                    <Link to={`/appointments/types/${type.id}`}>{type.name_in_pim}</Link>
                  </td>
                );
              },
              color: (type: AppointmentType) => {
                return (
                  <td className="py-2 d-flex justify-content-center">
                    <ColorSquare color={type.color} />
                  </td>
                );
              },
              dropoff: (type: AppointmentType) => {
                return <td className="py-2">{type.dropoff ? 'Yes' : 'No'}</td>;
              },
              tech_appointment: (type: AppointmentType) => {
                return <td className="py-2">{type.tech_appointment ? 'Yes' : 'No'}</td>;
              },
              deposit_amount: (type: AppointmentType) => {
                return <td className="py-2">${type.deposit_amount / 100}</td>;
              },
              client_schedulable: (type: AppointmentType) => {
                return <td className="py-2">{type.client_schedulable ? 'Yes' : 'No'}</td>;
              }
            }}
          ></CSmartTable>
        )}
      </CCol>
    </CRow>
  );
};

export default List;
