import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from '@hello-pangea/dnd';
import cn from 'classnames';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { paths } from 'routes';

import {
  CButton,
  CCol,
  CDatePicker,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchClinic } from 'api/Clinics';
import { fetchDocument } from 'api/Documents';
import { fetchEstimateTemplate, fetchEstimateTemplates } from 'api/EstimateTemplates';
import { fetchCurrentPriceForProduct, ProductPricePayload } from 'api/ProductPrices';

import { Animal } from 'types/Animal';
import { Clinic } from 'types/Clinic';
import { Consult } from 'types/Consult';
import { Document } from 'types/Document';
import { Estimate, EstimateStatus, estimateStatuses } from 'types/Estimate';
import { EstimateItem } from 'types/EstimateItem';
import { EstimateTemplate } from 'types/EstimateTemplate';
import { Option } from 'types/Option';

import { useAuth } from 'hooks/useAuth';

import { ClinicContext } from 'contexts/ClinicContext';

import { zonedToUtc } from 'utils/dates';
import { isEnabled } from 'utils/filters';
import { toCurrency } from 'utils/price';
import { reactSelectStyles } from 'utils/reactSelect';
import { animalToOption, consultToOption, estimateTemplateToOption } from 'utils/selectOptions';

import SvgCalendar from 'assets/images/SvgCalendar';
import SvgClipboard from 'assets/images/SvgClipboard';
import SvgOutgoing from 'assets/images/SvgOutgoing';
import SvgPaw from 'assets/images/SvgPaw';
import SvgPercentageBordered from 'assets/images/SvgPercentageBordered';
import SvgPerson from 'assets/images/SvgPerson';
import SvgPlus from 'assets/images/SvgPlus';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { FormAuditData } from 'components/FormAuditData';
import { IconButton } from 'components/IconButton';
import { IconLabel } from 'components/IconLabel';

import styles from './EstimateForm.module.scss';

import { EstimateItemForm } from './EstimateItemForm';

type Props = {
  handleSubmit: () => Promise<void>;
  handleCancel: () => void;
  estimate: Partial<Estimate>;
  setEstimate: React.Dispatch<React.SetStateAction<Partial<Estimate> | undefined>>;
  isLoading?: boolean;
  consults?: Consult[];
  animals?: Animal[];
  animal_id?: number;
};

export const EstimateForm = ({
  handleSubmit,
  handleCancel,
  estimate,
  setEstimate,
  isLoading = false,
  consults,
  animals,
  animal_id
}: Props): JSX.Element => {
  const auth = useAuth();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [isAddingTemplateItems, setIsAddingTemplateItems] = useState<boolean>(false);
  const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);

  const [isApplyingDiscount, setIsApplyingDiscount] = useState<boolean>(false);
  const [showDiscountConfirmation, setShowDiscountConfirmation] = useState<boolean>(false);
  const [overallDiscount, setOverallDiscount] = useState<number>(
    estimate?.discount_fixed ? estimate.discount_fixed / 100 : estimate?.discount_percentage || 0
  );
  const [overallDiscountType, setOverallDiscountType] = useState<string>(
    estimate?.discount_fixed ? 'fixed' : 'percentage'
  );

  const [document, setDocument] = useState<Document>();
  const [consultOptions, setConsultOptions] = useState<Option[]>([]);

  const { clinicContext } = React.useContext(ClinicContext);
  const [clinic, setClinic] = useState<Clinic>();

  useEffect(() => {
    if (clinicContext) fetchClinic(clinicContext, setClinic);
  }, [clinicContext]);

  useEffect(() => {
    if (estimate.document_id) fetchDocument(estimate.document_id, { onSuccess: setDocument, onError: toast.error });
    else setDocument(undefined);
  }, [estimate]);

  useEffect(() => {
    const fetchProductPrices = async () => {
      if (!estimate.estimate_items) return;
      const items = await Promise.all(
        estimate.estimate_items.map(async (estimateItem) => {
          const currentPrice = await fetchCurrentPriceForProduct({
            productId: estimateItem.product_id?.toString(),
            urgent_care: estimate.urgent_care,
            discount_fixed: estimateItem.discount_fixed,
            discount_percentage: estimateItem.discount_percentage,
            quantity: estimateItem.quantity,
            quantity_low: estimateItem.quantity_low,
            quantity_high: estimateItem.quantity_high
          } as ProductPricePayload);

          const estimateItemWithPrice = {
            ...estimateItem,
            price: currentPrice.calculated_prices.selected.price,
            price_before_tax: currentPrice.calculated_prices.selected.subtotal,
            price_before_tax_low: currentPrice.calculated_prices.low.subtotal,
            price_before_tax_high: currentPrice.calculated_prices.high.subtotal,
            tax: currentPrice.calculated_prices.selected.tax,
            tax_low: currentPrice.calculated_prices.low.tax,
            tax_high: currentPrice.calculated_prices.high.tax,
            total: currentPrice.calculated_prices.selected.total,
            total_low: currentPrice.calculated_prices.low.total,
            total_high: currentPrice.calculated_prices.high.total
          };
          return estimateItemWithPrice;
        })
      );

      setEstimate((prevEstimate) => ({
        ...prevEstimate,
        estimate_items_attributes: items,
        estimate_items: items
      }));
    };

    fetchProductPrices();
    if (consults) {
      if (animal_id) {
        setConsultOptions(consults.filter((consult) => consult.animal_id === animal_id).map(consultToOption));
      } else {
        setConsultOptions(consults.map(consultToOption));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Calculate the values when the estimate items change
  useEffect(() => {
    const calculate = async () => {
      if (estimate.estimate_items) {
        const { subtotal, tax, total_low, total_high } = await calculateValues(estimate.estimate_items);

        if (
          subtotal !== estimate.subtotal ||
          tax !== estimate.tax ||
          total_low !== estimate.total_low ||
          total_high !== estimate.total_high
        ) {
          setEstimate((prevEstimate) => ({ ...prevEstimate, subtotal, tax, total_low, total_high }));
        }
      }
    };
    calculate();
  }, [estimate, setEstimate]);

  const toggleIsUrgentCare = async () => {
    if (estimate.estimate_items) {
      const items = await Promise.all(
        estimate.estimate_items.map(async (item) => {
          const currentPrice = await fetchCurrentPriceForProduct({
            productId: item.product_id?.toString(),
            urgent_care: !estimate.urgent_care,
            discount_fixed: item.discount_fixed,
            discount_percentage: item.discount_percentage,
            quantity: item.quantity,
            quantity_low: item.quantity_low,
            quantity_high: item.quantity_high
          } as ProductPricePayload);
          const estimateItemWithPrice = {
            ...item,
            price: currentPrice.calculated_prices.selected.price,
            price_before_tax: currentPrice.calculated_prices.selected.subtotal,
            price_before_tax_low: currentPrice.calculated_prices.low.subtotal,
            price_before_tax_high: currentPrice.calculated_prices.high.subtotal,
            tax: currentPrice.calculated_prices.selected.tax,
            tax_low: currentPrice.calculated_prices.low.tax,
            tax_high: currentPrice.calculated_prices.high.tax,
            total: currentPrice.calculated_prices.selected.total,
            total_low: currentPrice.calculated_prices.low.total,
            total_high: currentPrice.calculated_prices.high.total
          };
          return estimateItemWithPrice;
        })
      );
      setEstimate((prevEstimate) => ({
        ...prevEstimate,
        estimate_items_attributes: items,
        estimate_items: items,
        urgent_care: !estimate.urgent_care
      }));
    } else {
      setEstimate((prevEstimate) => ({
        ...prevEstimate,
        urgent_care: !estimate.urgent_care
      }));
    }
  };

  const calculateValues = async (items: EstimateItem[]) => {
    const activeItems = items.filter((item) => item.status !== 'disabled' && item.status !== 'rejected');

    const subtotal = activeItems.reduce((acc, item) => acc + (item.price_before_tax || 0), 0);
    const tax = activeItems.reduce((acc, item) => acc + (item.tax || 0), 0);
    const total_low = activeItems.reduce((acc, item) => acc + (item.total_low || 0), 0);
    const total_high = activeItems.reduce((acc, item) => acc + (item.total_high || 0), 0);

    return { subtotal, tax, total_low, total_high };
  };

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateEstimateItems(estimate.estimate_items)) {
      await handleSubmit();
    }
  };

  const validateEstimateItems = (estimateItems: EstimateItem[] | undefined) => {
    if (estimateItems && estimateItems.length > 0) {
      submitRef?.current?.setCustomValidity('');
      return true;
    } else {
      submitRef?.current?.setCustomValidity('Please add at least one item.');
      submitRef?.current?.reportValidity();
      return false;
    }
  };

  const clearEstimateItemsValidation = () => submitRef?.current?.setCustomValidity('');

  const handleConsultChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setEstimate({
      ...estimate,
      consult_id: event.target.value ? Number(event.target.value) : null,
      animal_id: event.target.value
        ? consults?.find((consult) => consult.id === Number(event.target.value))?.animal_id
        : animal_id
    });
  };

  const handleAnimalChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (consults) {
      setConsultOptions(
        consults.filter((consult) => consult.animal_id === Number(event.target.value)).map(consultToOption)
      );
    }
    setEstimate({ ...estimate, animal_id: event.target.value ? Number(event.target.value) : undefined });
  };

  const addPlaceholderEstimateItem = () => {
    const items = estimate.estimate_items
      ? [...estimate.estimate_items, { key: Date.now(), status: 'pending' } as EstimateItem]
      : [{ key: Date.now(), status: 'pending' } as EstimateItem]; // Create new array with one item
    setEstimate({ ...estimate, estimate_items_attributes: items, estimate_items: items });
  };

  const handleEstimateItemChange = async (estimateItem: EstimateItem, key: number | undefined) => {
    if (!estimate.estimate_items || !estimateItem.product_id) return; //If there are no items, do nothing

    clearEstimateItemsValidation();

    const items = await Promise.all(
      estimate.estimate_items.map(async (item) => {
        if (item.id ? item.id === estimateItem.id : item.key === key) {
          const currentPrice = await fetchCurrentPriceForProduct({
            productId: estimateItem.product_id?.toString(),
            urgent_care: estimate.urgent_care,
            discount_fixed: estimateItem.discount_fixed,
            discount_percentage: estimateItem.discount_percentage,
            quantity: estimateItem.quantity,
            quantity_low: estimateItem.quantity_low,
            quantity_high: estimateItem.quantity_high
          } as ProductPricePayload);

          const estimateItemWithPrice = {
            ...estimateItem,
            price: currentPrice.calculated_prices.selected.price,
            price_before_tax: currentPrice.calculated_prices.selected.subtotal,
            price_before_tax_low: currentPrice.calculated_prices.low.subtotal,
            price_before_tax_high: currentPrice.calculated_prices.high.subtotal,
            tax: currentPrice.calculated_prices.selected.tax,
            tax_low: currentPrice.calculated_prices.low.tax,
            tax_high: currentPrice.calculated_prices.high.tax,
            total: currentPrice.calculated_prices.selected.total,
            total_low: currentPrice.calculated_prices.low.total,
            total_high: currentPrice.calculated_prices.high.total
          };
          return estimateItemWithPrice;
        } else {
          return item;
        }
      })
    );

    setEstimate((prevEstimate) => ({
      ...prevEstimate,
      estimate_items_attributes: items,
      estimate_items: items
    }));
  };

  const removeItem = (key: number | undefined, id: number | undefined) => {
    if (!estimate.estimate_items) return; //If there are no items, do nothing
    let items: EstimateItem[];
    if (key) {
      // Since key is only present for items added during this session, we can safely remove it
      items = estimate.estimate_items.filter((item) => item.key !== key);
    } else {
      // Key does not come from api, so we need to set status to disabled for soft delete
      items = estimate.estimate_items.map((item) => {
        let newItem = item;
        if (item.id === id) {
          newItem = { ...item, status: 'disabled' };
        }
        return newItem;
      });
    }
    setEstimate((prevEstimate) => ({
      ...prevEstimate,
      estimate_items_attributes: items,
      estimate_items: items
    }));
  };

  const handleTemplateSelected = (value: Option | null) => {
    setIsAddingTemplateItems(true);
    if (value && value.value) fetchEstimateTemplate(value.value, addProductsFromTemplate);
  };

  const addProductsFromTemplate = async (template: EstimateTemplate) => {
    let items = estimate.estimate_items ? [...estimate.estimate_items] : [];
    if (items.length && items[0].product_id === undefined) items.shift();

    const skippedItems: EstimateItem[] = [];
    const estimateTemplateItems = template.estimate_template_items ?? [];
    const templateItems = await Promise.all(
      estimateTemplateItems.map(async (templateItem, index, array) => {
        const alreadyExists = estimate.estimate_items?.find(
          (estimateItem) => estimateItem.product_id === templateItem.product_id
        );
        if (index === array.length - 1)
          setTimeout(() => {
            setIsAddingTemplateItems(false);
          }, 500);

        if (alreadyExists?.status === 'disabled') {
          // item had previously existed, so re-enable it and update the pricing according to template
          const currentPrice = await fetchCurrentPriceForProduct({
            productId: alreadyExists.product_id?.toString(),
            urgent_care: estimate.urgent_care,
            quantity: alreadyExists.quantity ?? 1,
            quantity_low: alreadyExists.quantity_low ?? 1,
            quantity_high: alreadyExists.quantity_high ?? 1,
            discount_fixed: alreadyExists.discount_fixed,
            discount_percentage: alreadyExists.discount_percentage
          } as ProductPricePayload);

          const calculatedPrice = currentPrice.calculated_prices;
          alreadyExists.status = 'pending';
          alreadyExists.quantity = templateItem.quantity;
          alreadyExists.quantity_low = templateItem.quantity;
          alreadyExists.quantity_high = templateItem.quantity;
          alreadyExists.discount_percentage = templateItem.discount_percentage;
          alreadyExists.discount_fixed = templateItem.discount_fixed;
          alreadyExists.price = calculatedPrice.selected.price;
          alreadyExists.price_low = calculatedPrice.low.price;
          alreadyExists.price_high = calculatedPrice.high.price;
          alreadyExists.price_before_tax = calculatedPrice.selected.subtotal;
          alreadyExists.price_before_tax_low = calculatedPrice.low.subtotal;
          alreadyExists.price_before_tax_high = calculatedPrice.high.subtotal;
          alreadyExists.tax = calculatedPrice.selected.tax;
          alreadyExists.tax_low = calculatedPrice.low.tax;
          alreadyExists.tax_high = calculatedPrice.high.tax;
          alreadyExists.total = calculatedPrice.selected.total;
          alreadyExists.total_low = calculatedPrice.low.total;
          alreadyExists.total_high = calculatedPrice.high.total;

          return alreadyExists;
        } else if (alreadyExists) {
          skippedItems.push(alreadyExists);
        }

        if (templateItem.product && templateItem.product.id) {
          const currentPrice = await fetchCurrentPriceForProduct({
            productId: templateItem.product.id.toString(),
            urgent_care: estimate.urgent_care,
            quantity: templateItem.quantity ?? 1,
            quantity_low: templateItem.quantity ?? 1,
            quantity_high: templateItem.quantity ?? 1,
            discount_percentage: templateItem.discount_percentage,
            discount_fixed: templateItem.discount_fixed
          } as ProductPricePayload);

          const calculatedPrice = currentPrice.calculated_prices;

          return {
            key: templateItem.product?.id ? Date.now() + templateItem.product?.id : Date.now(),
            product: templateItem.product,
            product_id: templateItem.product_id,
            quantity: templateItem.quantity,
            quantity_low: templateItem.quantity,
            quantity_high: templateItem.quantity,
            discount_percentage: templateItem.discount_percentage,
            discount_fixed: templateItem.discount_fixed,
            price: calculatedPrice.selected.price,
            price_low: calculatedPrice.low.price,
            price_high: calculatedPrice.high.price,
            price_before_tax: calculatedPrice.selected.subtotal,
            price_before_tax_low: calculatedPrice.low.subtotal,
            price_before_tax_high: calculatedPrice.high.subtotal,
            status: 'pending',
            tax: calculatedPrice.selected.tax,
            tax_low: calculatedPrice.low.tax,
            tax_high: calculatedPrice.high.tax,
            total: calculatedPrice.selected.total,
            total_low: calculatedPrice.low.total,
            total_high: calculatedPrice.high.total
          } as EstimateItem;
        }
      })
    );

    //Remove elements in skippedItems from items array
    items = items.filter((item) => !skippedItems.includes(item));

    //Combine template items with estimate items and remove all undefined
    items = [...items, ...templateItems].filter((item) => item !== undefined) as EstimateItem[];

    const { subtotal, tax, total_low, total_high } = await calculateValues(items);

    setEstimate((prevEstimate) => ({
      ...prevEstimate,
      estimate_items_attributes: items,
      estimate_items: items,
      total_low,
      total_high,
      subtotal,
      tax
    }));
    setShowTemplateModal(false);
    if (skippedItems.length > 0)
      toast.info(
        'These items were not added because they already exist in the estimate: ' +
          skippedItems.map((item) => item.product?.name).join(', ')
      );
  };

  const updateDiscountType = async (newDiscountType: string) => {
    setOverallDiscountType(newDiscountType);
    maybeApplyOverallDiscount();
  };

  const maybeApplyOverallDiscount = () => {
    if (!estimate.estimate_items) return;
    const atLeastOneItemHasDiscount = estimate.estimate_items.some(
      (item) => item.discount_percentage || item.discount_fixed
    );
    if (atLeastOneItemHasDiscount) setShowDiscountConfirmation(true);
    else applyOverallDiscount();
  };

  const applyOverallDiscount = async () => {
    if (!estimate.estimate_items) return;
    setIsApplyingDiscount(true);

    const items = await Promise.all(
      estimate.estimate_items.map(async (item) => {
        if (overallDiscountType === 'percentage') {
          item.discount_percentage = overallDiscount;
          item.discount_fixed = undefined;
        } else if (overallDiscount) {
          item.discount_percentage = undefined;
          item.discount_fixed = overallDiscount * 100;
        }

        const currentPrice = await fetchCurrentPriceForProduct({
          productId: item.product_id?.toString(),
          urgent_care: estimate.urgent_care,
          quantity: item.quantity ?? 1,
          quantity_low: item.quantity_low ?? 1,
          quantity_high: item.quantity_high ?? 1,
          discount_percentage: item.discount_percentage,
          discount_fixed: item.discount_fixed
        } as ProductPricePayload);

        const calculatedPrice = currentPrice.calculated_prices;
        item.price = calculatedPrice.selected.price;
        item.price_low = calculatedPrice.low.price;
        item.price_high = calculatedPrice.high.price;
        item.price_before_tax = calculatedPrice.selected.subtotal;
        item.price_before_tax_low = calculatedPrice.low.subtotal;
        item.price_before_tax_high = calculatedPrice.high.subtotal;
        item.tax = calculatedPrice.selected.tax;
        item.tax_low = calculatedPrice.low.tax;
        item.tax_high = calculatedPrice.high.tax;
        item.total = calculatedPrice.selected.total;
        item.total_low = calculatedPrice.low.total;
        item.total_high = calculatedPrice.high.total;

        return item;
      })
    );

    setEstimate((prevEstimate) => ({
      ...prevEstimate,
      estimate_items_attributes: items,
      estimate_items: items,
      discount_percentage: overallDiscountType === 'percentage' ? overallDiscount : undefined,
      discount_fixed: overallDiscountType === 'fixed' ? overallDiscount : undefined
    }));

    setIsApplyingDiscount(false);
    setShowDiscountConfirmation(false);
  };

  const totalDisplay: string = estimate.total_low
    ? estimate.total_high
      ? `${toCurrency(estimate.total_low)} - ${toCurrency(estimate.total_high)}`
      : toCurrency(estimate.total_low)
    : estimate.total_high
    ? toCurrency(estimate.total_high)
    : '$0';

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) return;

    const nonDeletedItems = Array.from(estimate.estimate_items?.filter(isEnabled) ?? []);
    const deletedItems = Array.from(estimate.estimate_items?.filter((item) => !isEnabled(item)) ?? []);

    const [reorderedItem] = nonDeletedItems.splice(result.source.index, 1);
    nonDeletedItems.splice(result.destination.index, 0, reorderedItem);
    setEstimate((prevEstimate) => ({
      ...prevEstimate,
      estimate_items: nonDeletedItems.concat(deletedItems),
      estimate_items_attributes: nonDeletedItems.concat(deletedItems)
    }));
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={submit}>
      <h2 className="mb-3 text-capitalize">{estimate.id ? 'Edit' : 'New'} Treatment Plan</h2>

      <CRow className="mb-3">
        <CCol className="mb-3" xs={6}>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="name">
              <IconLabel icon={SvgPerson} label="Name" />
            </label>
            <div className={styles.required}> Required</div>
          </div>
          <CFormInput
            type="text"
            id="name"
            autoFocus={!estimate.name}
            value={estimate.name ?? ''}
            required
            onChange={(event) => setEstimate({ ...estimate, name: event.target.value })}
          />
        </CCol>
        <CCol xs={6}>
          <CFormLabel htmlFor="effective_date">
            <IconLabel icon={SvgCalendar} label="Effective Date" />
          </CFormLabel>
          <CDatePicker
            date={estimate.effective_date ? zonedToUtc(new Date(estimate.effective_date), clinic) : undefined}
            format="yyyy-MM-dd"
            id="effective_date"
            locale="en-US"
            onDateChange={(event) => setEstimate({ ...estimate, effective_date: event?.toISOString() })}
          />
        </CCol>
        {animals && animals.length > 1 && (
          <CCol xs={6}>
            <div className="d-flex align-items-center justify-content-between">
              <CFormLabel htmlFor="animal">
                <IconLabel icon={SvgPaw} label="Animal" />
              </CFormLabel>
            </div>
            <CFormSelect
              id="animal"
              value={estimate.animal_id ?? undefined}
              options={[{ label: '', value: '' }, ...animals.map(animalToOption)]}
              onChange={handleAnimalChange}
            />
          </CCol>
        )}
        {consults && consults.length > 0 && (
          <CCol xs={animals && animals.length > 1 ? 3 : 6}>
            <div className="d-flex align-items-center justify-content-between">
              <CFormLabel htmlFor="consult">
                <IconLabel icon={SvgClipboard} label="Consult" />
              </CFormLabel>
            </div>
            <CFormSelect
              id="consult"
              value={estimate.consult_id ?? undefined}
              options={[{ label: '', value: '' }, ...consultOptions]}
              onChange={handleConsultChange}
            />
          </CCol>
        )}

        <CCol xs={animals && animals.length > 1 ? 3 : 6}>
          <div className="d-flex align-items-center justify-content-between">
            <CFormLabel htmlFor="name">
              <IconLabel icon={SvgClipboard} label="Status" />
            </CFormLabel>
          </div>
          <CFormSelect
            type="text"
            id="status"
            value={estimate.status}
            options={[...estimateStatuses]}
            className="text-capitalize"
            onChange={(event) => setEstimate({ ...estimate, status: event.target.value as EstimateStatus })}
          />
        </CCol>

        <CCol xs={animals && animals.length > 1 ? 3 : 6} className="d-flex align-items-end justify-content-end">
          <div className="w-auto">
            {document && document.signed && (
              <Link to={generatePath(paths.documentDetails, { id: document.id })}>View signed document</Link>
            )}
          </div>
        </CCol>
      </CRow>

      <div>
        <CTable hover align="middle" className={styles.table}>
          <CTableHead color="dark">
            <CTableRow>
              <CTableHeaderCell style={{ width: '3%' }} />
              <CTableHeaderCell style={{ width: '30%' }}>Item</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '10%' }}>Qty Low</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '10%' }}>Qty High</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '8%' }}>Price</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '12%' }}>Discount</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '10%' }}>Total</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '2%' }}>Can Decline?</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '4%' }}>Actions</CTableHeaderCell>
            </CTableRow>
          </CTableHead>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(dropProvided, dropSnapshot) => (
                <CTableBody
                  ref={dropProvided.innerRef}
                  className={styles.droppable}
                  {...dropProvided.droppableProps}
                  suppressContentEditableWarning={true}
                >
                  {estimate.estimate_items?.filter(isEnabled).map((item, index) => {
                    const key = item.id ? item.id : item.key;
                    return (
                      <Draggable key={key} draggableId={key?.toString() ?? ''} index={index}>
                        {(dragProvided, dropSnapshot) => (
                          <EstimateItemForm
                            draggableProvided={dragProvided}
                            estimateItem={item}
                            handleChange={handleEstimateItemChange}
                            index={index}
                            isDragging={dropSnapshot.isDragging}
                            isOrgAdmin={auth?.isOrganizationAdmin()}
                            key={key}
                            ref={dragProvided.innerRef}
                            removeItem={removeItem}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {dropProvided.placeholder}
                </CTableBody>
              )}
            </Droppable>
          </DragDropContext>
        </CTable>

        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex align-items-center">
            <IconButton icon={SvgPlus} label="Add Item" onClick={addPlaceholderEstimateItem} />
            <IconButton
              icon={SvgPlus}
              className="ms-2"
              label="Add From Template"
              onClick={() => setShowTemplateModal(true)}
              disabled={isAddingTemplateItems}
            />
          </div>

          <div style={{ width: 370 }} className="d-flex flex-column gap-3">
            <CFormCheck
              id="is-urgent-care"
              label="Urgent Care?"
              onChange={toggleIsUrgentCare}
              checked={estimate.urgent_care}
            />

            {auth?.isOrganizationAdmin() && (
              <div className="d-flex justify-content-between align-items-end">
                <div className="flex-grow-1">
                  <CFormLabel htmlFor="discount">
                    <IconLabel icon={SvgPercentageBordered} label="Discount All Items" />
                  </CFormLabel>
                  <CInputGroup>
                    <CFormInput
                      type="number"
                      className="w-auto"
                      id="discount"
                      min={0}
                      max={overallDiscountType === 'percentage' ? 100 : undefined}
                      step={0.01}
                      value={overallDiscount}
                      onChange={(event) => setOverallDiscount(parseFloat(event.target.value))}
                    />
                    <CFormSelect
                      id="Discount Type"
                      name="discount_type"
                      aria-label="Discount All Items Type"
                      options={[
                        { value: 'percentage', label: '%' },
                        { value: 'fixed', label: '$' }
                      ]}
                      onChange={(event) => updateDiscountType(event.target.value)}
                      value={overallDiscountType}
                    />
                  </CInputGroup>
                </div>
                <IconButton
                  icon={SvgOutgoing}
                  onClick={maybeApplyOverallDiscount}
                  label="Apply"
                  className="ms-2"
                  disabled={isApplyingDiscount}
                />
              </div>
            )}

            <div className="d-flex align-items-center justify-content-between">
              <div>Subtotal</div>
              <div>{estimate.subtotal && toCurrency(estimate.subtotal)}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div>Tax</div>
              <div>{estimate.tax && toCurrency(estimate.tax)}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div>Total</div>
              <div>{totalDisplay}</div>
            </div>
          </div>
        </div>

        <div className={'d-flex align-items-center justify-content-between mt-4'}>
          {estimate && <FormAuditData item={estimate} />}
          <div className={cn('ms-auto d-flex', styles.buttons)}>
            <CLoadingButton
              className={styles.button}
              type="submit"
              shape="rounded-pill"
              disabled={isLoading}
              loading={isLoading}
              ref={submitRef}
            >
              {estimate.id ? 'Update' : 'Create'}
            </CLoadingButton>
            <CButton
              shape="rounded-pill"
              variant="outline"
              className={styles.button}
              disabled={isLoading}
              onClick={handleCancel}
            >
              Close
            </CButton>
          </div>
        </div>
      </div>

      <CModal focus={false} backdrop="static" visible={showTemplateModal} onClose={() => setShowTemplateModal(false)}>
        <CModalHeader>
          <h4>Add From Template</h4>
        </CModalHeader>
        <CModalBody>
          <AsyncSelect<Option>
            aria-label="Add From Template"
            placeholder="Type to search..."
            onChange={(value) => handleTemplateSelected(value)}
            loadingMessage={(input: { inputValue: string }) => 'Loading...'}
            autoFocus
            styles={reactSelectStyles}
            defaultOptions
            loadOptions={(inputValue, callback) => {
              fetchEstimateTemplates(inputValue, (templates: EstimateTemplate[]) => {
                callback(templates.map(estimateTemplateToOption));
              });
            }}
            isSearchable
            required
          />
        </CModalBody>
      </CModal>

      <ConfirmationModal
        isVisible={showDiscountConfirmation}
        onClose={() => setShowDiscountConfirmation(false)}
        onConfirm={applyOverallDiscount}
        confirmButtonLabel={'Yes, apply'}
        modalHeader={'Apply discount to all items?'}
        modalBody={'This will overwrite the discount on all items in the estimate.'}
      />
    </CForm>
  );
};
