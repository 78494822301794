import axios, { AxiosError } from 'axios';

import { ApiResponse } from 'api/ResponseTypes';

import { History } from 'types/History';

const fetchHistoriesByAnimal = (animalId: string, onSuccess: (histories: History[]) => void) => {
  const url = `/pim/histories?animal_id=${animalId}`;
  axios.get<History[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchHistoriesForConsult = (consultId: number | string, onSuccess: (histories: History[]) => void) => {
  const url = `/pim/histories`;
  axios.get<History[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const createHistory = (
  history: Partial<History>,
  { onSuccess, onError }: { onSuccess: (history: History) => void; onError: (error: string) => void }
) => {
  const url = `/pim/histories`;

  axios
    .post<History>(url, { history })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<History>>) => onError(error.message));
};

const updateHistory = (
  historyId: number,
  params: object,
  { onSuccess, onError }: { onSuccess: (history: History) => void; onError: (error: string) => void }
) => {
  const url = `/pim/histories/${historyId}`;

  axios
    .put<History>(url, { history: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<History>>) => onError(error.message));
};

export { fetchHistoriesForConsult, fetchHistoriesByAnimal, createHistory, updateHistory };
