import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import { idleTimeoutPausePaths } from 'routes';

import { idleTimeoutInMilliseconds } from 'types/Employee';

import { useAuth } from 'hooks/useAuth';

import { setLastPath } from 'utils/sessionManager';

import { ConfirmationModal } from './ConfirmationModal';

const AppLogout = ({ children }: { children: JSX.Element }) => {
  const LAST_ACTIVE_TIME = 'lastActiveTime';
  const auth = useAuth();
  const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] = React.useState<boolean>(false);
  const timeout = idleTimeoutInMilliseconds(auth.employee);
  const promptBeforeIdle = 15_000;
  const [remaining, setRemaining] = useState<number>(timeout);
  const [isPaused, setIsPaused] = useState<boolean>(false);

  const location = useLocation();

  const onActive = () => {
    setShowLogoutConfirmationModal(false);
  };

  const onAction = () => {
    const now = new Date().getTime();
    localStorage.setItem(LAST_ACTIVE_TIME, now.toString());
  };

  const onIdle = () => {
    setShowLogoutConfirmationModal(false);

    if (auth.employee) {
      setLastPath(auth.employee.id);
    }

    auth.logout();
  };

  const onPrompt = () => {
    if (auth.employee) {
      setShowLogoutConfirmationModal(true);
    }
  };

  const { resume, pause, getRemainingTime, activate, start, reset } = useIdleTimer({
    onActive,
    onAction,
    onIdle,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    startManually: true,
    startOnMount: false,
    stopOnIdle: true,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  });

  useEffect(() => {
    if (auth.employee) {
      start();
    } else {
      reset();
    }
  }, [auth.employee, start, reset]);

  useEffect(() => {
    if (idleTimeoutPausePaths.includes(location.pathname)) {
      pause();
      setIsPaused(true);
    } else if (isPaused) {
      resume();
      setIsPaused(false);
    }
  }, [resume, isPaused, location.pathname, pause]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  useEffect(() => {
    const lastActiveTime = parseInt(localStorage.getItem(LAST_ACTIVE_TIME) || '0', 10);
    const now = new Date().getTime();

    if (now - lastActiveTime > timeout) {
      localStorage.removeItem(LAST_ACTIVE_TIME);
      if (auth.employee) {
        auth.logout();
      }
    }
  }, [auth, timeout]);

  return (
    <div>
      {children}
      <ConfirmationModal
        isVisible={showLogoutConfirmationModal}
        onClose={() => handleStillHere()}
        onConfirm={() => handleStillHere()}
        modalBody={`Logging out in ${remaining} seconds`}
        confirmButtonLabel={`Continue`}
        modalHeader="Are you still here?"
      />
    </div>
  );
};

export default AppLogout;
