import * as React from 'react';

import { CCol, CFormInput, CRow } from '@coreui/react-pro';

import { DiagnosticResultItem } from 'types/DiagnosticResultItem';

type Props = {
  index: number;
  resultItem?: DiagnosticResultItem;
};

export const DiagnosticResultItemForm = ({ index, resultItem }: Props) => {
  const [itemName, setItemName] = React.useState(resultItem?.name ?? '');
  const [unit, setUnit] = React.useState(resultItem?.unit ?? '');
  const [itemValue, setItemValue] = React.useState(resultItem?.value ?? '');
  const [qualifier, setQualifier] = React.useState(resultItem?.qualifier ?? '');
  const [rangeLow, setRangeLow] = React.useState(resultItem?.range_low ?? '');
  const [rangeHigh, setRangeHigh] = React.useState(resultItem?.range_high ?? '');

  const nestedName = (name: string) => `diagnostic_result_items_attributes[${index}][${name}]`;

  return (
    <div>
      <CFormInput hidden id="id" name={nestedName('id')} value={resultItem?.id} />
      <CRow className="mb-2">
        <CCol xs={2}>
          <CFormInput
            id="name"
            name={nestedName('name')}
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            placeholder="Name"
          />
        </CCol>
        <CCol xs={2}>
          <CFormInput
            id="value"
            name={nestedName('value')}
            value={itemValue}
            onChange={(e) => setItemValue(e.target.value)}
            placeholder="Value"
          />
        </CCol>
        <CCol xs={2}>
          <CFormInput
            id="unit"
            name={nestedName('unit')}
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            placeholder="Unit"
          />
        </CCol>
        <CCol xs={2}>
          <CFormInput
            id="qualifier"
            name={nestedName('qualifier')}
            value={qualifier}
            onChange={(e) => setQualifier(e.target.value)}
            placeholder="Qualifier"
          />
        </CCol>
        <CCol xs={2}>
          <CFormInput
            id="range_low"
            name={nestedName('range_low')}
            value={rangeLow}
            onChange={(e) => setRangeLow(e.target.value)}
            placeholder="Range Low"
          />
        </CCol>
        <CCol xs={2}>
          <CFormInput
            id="range_high"
            name={nestedName('range_high')}
            value={rangeHigh}
            onChange={(e) => setRangeHigh(e.target.value)}
            placeholder="Range High"
          />
        </CCol>
      </CRow>
    </div>
  );
};
