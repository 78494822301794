import * as React from 'react';

import { CModal, CModalBody, CModalHeader } from '@coreui/react-pro';

import { AnimalHealthPlan } from 'types/AnimalHealthPlan';

import { DisableAnimalHealthPlanForm } from './DisableAnimalHealthPlanForm';

type Props = {
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  animalHealthPlan: AnimalHealthPlan;
};

export const DisableAnimalHealthPlanModal = ({ hideModal, onSubmit, loading, animalHealthPlan }: Props) => {
  return (
    <CModal className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
      <CModalHeader>
        <h4>Disable Health Plan</h4>
      </CModalHeader>
      <CModalBody>
        <DisableAnimalHealthPlanForm
          onSubmit={onSubmit}
          animalHealthPlan={animalHealthPlan}
          hideModal={hideModal}
          loading={loading}
        />
      </CModalBody>
    </CModal>
  );
};
