import * as React from 'react';
import type { SVGProps } from 'react';

const SvgHeartbeat = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="currentColor"
      d="M9 16.718a.46.46 0 0 1-.206-.05c-.049-.024-1.201-.614-2.707-1.71-1.39-1.011-3.351-2.662-4.798-4.785a8 8 0 0 1-.572-.97.456.456 0 0 1 .408-.66H4.06l.744-1.065a.456.456 0 0 1 .778.05l.892 1.702 1.501-3.214a.456.456 0 0 1 .86.095l.936 4.264 1.321-2.828a.456.456 0 0 1 .817-.02l.972 1.856.45-.645a.46.46 0 0 1 .374-.195h2.884c.87-1.937.599-3.866-.744-5.203a3.953 3.953 0 0 0-5.567 0c-.26.26-.588.566-.935.891l-.03.027a.456.456 0 0 1-.623 0l-.03-.029c-.347-.326-.674-.63-.934-.89a3.954 3.954 0 0 0-5.567 0C1.142 4.35.728 5.718.989 7.19a.456.456 0 1 1-.898.16 5.4 5.4 0 0 1 .133-2.53 5.07 5.07 0 0 1 1.29-2.126 4.87 4.87 0 0 1 6.854 0c.18.179.398.385.632.606.235-.22.453-.426.633-.606a4.87 4.87 0 0 1 6.854 0c.812.799 1.33 1.849 1.472 2.98.143 1.139-.091 2.36-.675 3.53a.46.46 0 0 1-.42.251h-2.922l-.744 1.065a.457.457 0 0 1-.778-.047l-.893-1.703-1.5 3.212a.456.456 0 0 1-.86-.095l-.936-4.263-1.32 2.828a.456.456 0 0 1-.818.019l-.971-1.855-.45.644a.46.46 0 0 1-.374.195h-2.39l.135.204c1.37 2.01 3.24 3.585 4.568 4.554q1.147.84 2.39 1.534c.833-.46 4.008-2.325 6.306-5.206a.457.457 0 1 1 .713.569c-1.454 1.824-3.226 3.236-4.457 4.1-1.333.937-2.314 1.44-2.356 1.46a.46.46 0 0 1-.207.05"
    />
  </svg>
);
export default SvgHeartbeat;
