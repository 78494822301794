import axios, { AxiosError, AxiosResponse } from 'axios';

import { ApiResponse } from 'api/ResponseTypes';

import { Assessment } from 'types/Assessment';

const fetchAssessmentsByAnimal = (animalId: string, onSuccess: (assessment: Assessment[]) => void) => {
  const url = `/pim/assessments?animal_id=${animalId}`;
  axios.get(url).then((data: AxiosResponse) => onSuccess(data.data.record));
};

const fetchAssessmentsForConsult = (consultId: number | string, onSuccess: (assessments: Assessment[]) => void) => {
  const url = `/pim/assessments`;
  axios.get(url, { params: { consult_id: consultId } }).then((data: AxiosResponse) => onSuccess(data.data.record));
};

const createAssessment = (
  assessment: Partial<Assessment>,
  { onSuccess, onError }: { onSuccess: (assessment: Assessment) => void; onError: (error: string) => void }
) => {
  const url = `/pim/assessments`;

  axios
    .post<Assessment>(url, { assessment: assessment })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Assessment>>) => onError(error.message));
};

const updateAssessment = (
  assessmentId: number,
  params: object,
  { onSuccess, onError }: { onSuccess: (assessment: Assessment) => void; onError: (error: string) => void }
) => {
  const url = `/pim/assessments/${assessmentId}`;

  axios
    .put<Assessment>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Assessment>>) => onError(error.message));
};

export { fetchAssessmentsForConsult, fetchAssessmentsByAnimal, createAssessment, updateAssessment };
