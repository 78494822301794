import * as React from 'react';
import { Link } from 'react-router-dom';

import { CImage } from '@coreui/react-pro';

import { Attachment } from 'types/Attachment';

type Props = {
  attachments: Attachment[];
};

export const DiagnosticResultAttachments = ({ attachments }: Props) => {
  return (
    <ul className="list-unstyled">
      {attachments.map((attachment: Attachment) => (
        <li key={attachment.id} className="my-2">
          <Link to={attachment.private_url} target="_blank" rel="noopener noreferrer">
            {attachment.content_type.startsWith('image/') && (
              <CImage
                src={attachment.private_url}
                className="me-2"
                alt={attachment.filename}
                width={48}
                height={48}
                rounded
              />
            )}

            {attachment.filename}
          </Link>
        </li>
      ))}
    </ul>
  );
};
