import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import {
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CLoadingButton,
  CRow,
  CSmartTable
} from '@coreui/react-pro';

import { fetchClinics } from 'api/Clinics';

import { Option } from 'types/Option';
import { Product } from 'types/Product';
import { Owner, ProductPrice } from 'types/ProductPrice';

import { useAuth } from 'hooks/useAuth';

import { ownerToOption } from 'utils/selectOptions';

type Props = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleCancel: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  product: Product;
  productPrices: Partial<ProductPrice>[];
  setProductPrices: (productPrices: Partial<ProductPrice>[]) => void;
  isLoading: boolean;
};

const Form = ({
  handleSubmit,
  handleCancel,
  product,
  handleChange,
  productPrices,
  setProductPrices,
  isLoading
}: Props): JSX.Element => {
  const [ownerOptions, setOwnerOptions] = useState<Option[]>();

  const [owners, setOwners] = useState<Owner[]>();

  const auth = useAuth();

  useEffect(() => {
    fetchClinics((clinics) => {
      const owners: Owner[] = clinics.map((clinic) => {
        return {
          owner_name: clinic.name,
          owner_id: clinic.id,
          owner_type: 'Clinic'
        };
      });

      const filteredClinics = clinics.filter((clinic) => {
        return productPrices.some(
          (price) => price.status === 'active' && price.owner_id === clinic.id && price.owner_type === 'Clinic'
        );
      });

      const orgClinicOptions = clinics.map((clinic) =>
        ownerToOption(clinic, 'Clinic', filteredClinics.includes(clinic))
      );

      if (auth.employee?.organization) {
        orgClinicOptions.unshift(
          ownerToOption(
            auth.employee?.organization,
            'Organization',
            productPrices.some(
              (price) =>
                price.status === 'active' &&
                price.owner_id === auth.employee?.organization.id &&
                price.owner_type === 'Organization'
            )
          )
        );

        setOwnerOptions(orgClinicOptions);

        setOwners([
          ...owners,
          {
            owner_name: auth.employee?.organization.name,
            owner_id: auth.employee?.organization.id,
            owner_type: 'Organization'
          }
        ]);
      } else {
        setOwnerOptions(orgClinicOptions);
        setOwners(owners);
      }
    });
  }, [auth.employee?.organization, product.id, productPrices]);

  const addPlaceholderPrice = () => {
    //Find first owner option that is not disabled
    const ownerOption = ownerOptions?.find((owner) => !owner.disabled);

    //Find first owner that matches the owner option
    const owner = owners?.find((owner) => `${owner.owner_id}_${owner.owner_type}` === ownerOption?.value);

    setProductPrices([
      ...productPrices,
      {
        key: Date.now(),
        owner_id: owner?.owner_id,
        owner_type: owner?.owner_type
      }
    ]);
  };

  const updateProductPriceCurrency = (
    value: number | undefined,
    price: ProductPrice,
    key: 'cost' | 'price' | 'dispense_fee' | 'markup_fixed' | 'markup_percentage' | 'tax_rate'
  ) => {
    const updatedPrices: Partial<ProductPrice>[] = productPrices?.map((productPrice) => {
      if ((value && !!price.id && productPrice.id === price.id) || (!!price.key && productPrice.key === price.key)) {
        return {
          ...productPrice,
          [key]: value,
          product_prices_attributes: { ...price.product_prices_attributes, [key]: value }
        };
      }
      return productPrice;
    });

    setProductPrices(updatedPrices);
  };

  const updateProductPrice = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    price: ProductPrice
  ) => {
    const updatedPrices = productPrices.map((productPrice) => {
      if ((!!price.id && productPrice.id === price.id) || (!!price.key && productPrice.key === price.key)) {
        const value =
          event.target.type === 'checkbox'
            ? 'checked' in event.target
              ? event.target.checked
              : false
            : event.target.value;
        return {
          ...productPrice,
          [event.target.id]: value,
          product_price_attributes: {
            ...price.product_prices_attributes,
            id: productPrice.id,
            [event.target.id]: value
          }
        };
      }
      return productPrice;
    });

    setProductPrices(updatedPrices);
  };

  const handleSelectedOwnerChanged = (event: React.ChangeEvent<HTMLSelectElement>, price: ProductPrice) => {
    const ownerToUpdate = ownerOptions?.find((owner) => owner.value === event.target.value);
    if (ownerToUpdate) {
      const owner = owners?.find((owner) => `${owner.owner_id}_${owner.owner_type}` === ownerToUpdate.value);

      const updatedPrices = productPrices.map((productPrice) => {
        if ((!!price.id && productPrice.id === price.id) || (!!price.key && productPrice.key === price.key)) {
          return {
            ...productPrice,
            owner_id: owner?.owner_id,
            owner_type: owner?.owner_type,
            product_prices_attributes: {
              ...price.product_prices_attributes,
              owner_id: owner?.owner_id,
              owner_type: owner?.owner_type
            }
          };
        }
        return productPrice;
      });

      const updatedOwnerOptions = ownerOptions?.map((option) => {
        if (option.value === event.target.value) {
          return { ...option, disabled: true };
        }
        return option;
      });

      setOwnerOptions(updatedOwnerOptions);
      setProductPrices(updatedPrices);
    }
  };

  const handleSelectedStatusChanged = (event: React.ChangeEvent<HTMLSelectElement>, price: ProductPrice) => {
    const updatedPrices = productPrices.map((productPrice) => {
      if ((!!price.id && productPrice.id === price.id) || (!!price.key && productPrice.key === price.key)) {
        return {
          ...productPrice,
          status: event.target.value as 'active' | 'disabled',
          product_prices_attributes: {
            ...price.product_prices_attributes,
            status: event.target.value as 'active' | 'disabled'
          }
        };
      }
      return productPrice;
    });

    setProductPrices(updatedPrices);
  };

  const selectedProductOwner = (price: ProductPrice): string => {
    const owner = productPrices.find((productPrice) => {
      return price.id === productPrice.id;
    });

    if (owner) {
      return `${owner.owner_id}_${owner.owner_type}`;
    } else return '';
  };

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    handleSubmit(event);
  };

  return (
    <CForm onSubmit={submit}>
      <h4 className="mb-3">Basic Info</h4>
      <CRow className="mb-3">
        <CFormLabel htmlFor="name" className="col-sm-3 col-form-label">
          Name
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput type="text" id="name" value={product.name || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="display_name" className="col-sm-3 col-form-label">
          Display Name
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput type="text" id="display_name" value={product.display_name || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="code" className="col-sm-3 col-form-label">
          Code
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput type="text" id="code" value={product.code || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="description" className="col-sm-3 col-form-label">
          Description
        </CFormLabel>
        <CCol sm={9}>
          <CFormTextarea id="description" value={product.description || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="product_type" className="col-sm-3 col-form-label">
          Product Type
        </CFormLabel>
        <CCol sm={9}>
          <CFormSelect
            id="product_type"
            value={product.product_type ?? ''}
            options={['Procedure', 'Medication', 'Diagnostic', 'Standard', 'Fee', 'Discount', 'Vaccination']}
            onChange={handleChange}
          />
        </CCol>
      </CRow>

      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="status" className="col-sm-3 col-form-label">
          Is Active
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch
            id="status"
            checked={product.status === 'active'}
            onChange={(event) =>
              handleChange({
                ...event,
                target: {
                  ...event.target,
                  value: event.target.checked ? 'active' : 'disabled',
                  id: 'status'
                }
              })
            }
          />
        </CCol>
      </CRow>

      <h4 className="mb-3 mt-5">Administration Info</h4>

      {product.product_type === 'Medication' && (
        <CRow className="mb-3">
          <CFormLabel htmlFor="default_instructions" className="col-sm-3 col-form-label">
            Default Instructions
          </CFormLabel>
          <CCol sm={9}>
            <CFormTextarea
              id="default_instructions"
              value={product.default_instructions ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CRow>
      )}

      <CRow className="mb-3">
        <CFormLabel htmlFor="dosage_form" className="col-sm-3 col-form-label">
          Dosage Form
        </CFormLabel>
        <CCol sm={9}>
          <CFormSelect
            id="dosage_form"
            value={product.dosage_form ?? ''}
            options={['', 'liquid', 'tablet', 'capsule', 'powder', 'injectable', 'cream', 'chewable']}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="concentration" className="col-sm-3 col-form-label">
          Concentration
        </CFormLabel>
        <CCol sm={9}>
          <CInputGroup>
            <CFormInput
              type="text"
              id="concentration"
              value={product.concentration || ''}
              onChange={handleChange}
              floatingLabel="Amount"
            />
            <CFormInput
              type="text"
              id="concentration_unit"
              value={product.concentration_unit || ''}
              onChange={handleChange}
              floatingLabel="Unit"
            />
            <CInputGroupText id="concentration_separator">/</CInputGroupText>
            <CFormInput
              type="text"
              id="concentration_volume"
              value={product.concentration_volume || ''}
              onChange={handleChange}
              floatingLabel="Volume"
            />
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="concentration_diluted" className="col-sm-3 col-form-label">
          Concentration Diluted
        </CFormLabel>
        <CCol sm={9}>
          <CInputGroup>
            <CFormInput
              type="text"
              id="concentration_diluted"
              value={product.concentration_diluted || ''}
              onChange={handleChange}
              floatingLabel="Amount"
            />
            <CFormInput
              type="text"
              id="concentration_diluted_unit"
              value={product.concentration_diluted_unit || ''}
              onChange={handleChange}
              floatingLabel="Unit"
            />
            <CInputGroupText id="concentration_diluted_separator">/</CInputGroupText>
            <CFormInput
              type="text"
              id="concentration_diluted_volume"
              value={product.concentration_diluted_volume || ''}
              onChange={handleChange}
              floatingLabel="Volume"
            />
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="lowest_dispensable_quantity" className="col-sm-3 col-form-label">
          Lowest Dispensible Quantity
        </CFormLabel>
        <CCol sm={9}>
          <CInputGroup>
            <CFormInput
              type="text"
              id="lowest_dispensable_quantity"
              value={product.lowest_dispensable_quantity || ''}
              onChange={handleChange}
              floatingLabel="Amount"
            />
            <CFormInput
              type="text"
              id="lowest_dispensable_unit"
              value={product.lowest_dispensable_unit || ''}
              onChange={handleChange}
              floatingLabel="Unit"
            />
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="default_dosage_calculation_method" className="col-sm-3 col-form-label">
          Default Dosage Calculation Method
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput
            type="text"
            id="default_dosage_calculation_method"
            value={product.default_dosage_calculation_method || ''}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="default_infusion_rate_per" className="col-sm-3 col-form-label">
          Default Infusion Rate Per
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput
            type="text"
            id="default_infusion_rate_per"
            value={product.default_infusion_rate_per || ''}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="default_route_of_administration" className="col-sm-3 col-form-label">
          Default Route of Administration
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput
            type="text"
            id="default_route_of_administration"
            value={product.default_route_of_administration || ''}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="is_constant_rate_infusion" className="col-sm-3 col-form-label">
          Is Constant Rate Infusion
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch
            id="is_constant_rate_infusion"
            checked={product.is_constant_rate_infusion}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="controlled" className="col-sm-3 col-form-label">
          Is Controlled
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="controlled" checked={product.controlled} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="preventative" className="col-sm-3 col-form-label">
          Is Preventative
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="preventative" checked={product.preventative} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="taken_with_food" className="col-sm-3 col-form-label">
          Is Taken With Food
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="taken_with_food" checked={product.taken_with_food || false} onChange={handleChange} />
        </CCol>
      </CRow>

      <h4 className="mb-3 mt-5">Misc Info</h4>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="has_direct_cost" className="col-sm-3 col-form-label">
          Has Direct Cost
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="has_direct_cost" checked={product.has_direct_cost} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="is_container" className="col-sm-3 col-form-label">
          Is Container
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="is_container" checked={product.is_container} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="is_derived_price" className="col-sm-3 col-form-label">
          Is Derived Price
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="is_derived_price" checked={product.is_derived_price} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="is_purchased" className="col-sm-3 col-form-label">
          Is Purchased
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="is_purchased" checked={product.is_purchased} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="is_sold" className="col-sm-3 col-form-label">
          Is Sold
        </CFormLabel>
        <CCol sm={9}>
          <CFormSwitch id="is_sold" checked={product.is_sold} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="tracking_level" className="col-sm-3 col-form-label">
          Tracking Level
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput type="text" id="tracking_level" value={product.tracking_level || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="warning" className="col-sm-3 col-form-label">
          Warning
        </CFormLabel>
        <CCol sm={9}>
          <CFormInput type="text" id="warning" value={product.warning || ''} onChange={handleChange} />
        </CCol>
      </CRow>

      <CRow className="mb-1">
        <CSmartTable
          tableProps={{ align: 'middle' }}
          items={productPrices}
          columns={[
            { key: 'owner', _style: { width: '20%' } },
            { key: 'cost', _style: { width: '12%' } },
            { key: 'price', _style: { width: '12%' } },
            'dispense_fee',
            { key: 'markup_based_pricing', _style: { width: '5%' } },
            'markup_percentage',
            { key: 'status', _style: { width: '15%' } },
            'tax_rate'
          ]}
          scopedColumns={{
            owner: (price: ProductPrice) => {
              return (
                <td className="py-2">
                  <CFormSelect
                    aria-label={`Select Owner for ${price.owner?.name}`}
                    id="owner"
                    value={selectedProductOwner(price)}
                    options={ownerOptions}
                    onChange={(event) => handleSelectedOwnerChanged(event, price)}
                  />
                </td>
              );
            },
            cost: (price: ProductPrice) => {
              return (
                <td className="py-2">
                  <NumericFormat
                    aria-label={`Enter Cost for ${price.owner?.name}`}
                    value={price.cost ? price.cost / 100 : ''}
                    valueIsNumericString={true}
                    customInput={CFormInput}
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={(values, _) => {
                      updateProductPriceCurrency(
                        values.floatValue ? values.floatValue * 100 : undefined,
                        price,
                        'cost'
                      );
                    }}
                  />
                </td>
              );
            },
            price: (price: ProductPrice) => {
              return (
                <td className="py-2">
                  <NumericFormat
                    aria-label={`Enter Price for ${price.owner?.name}`}
                    value={price.price ? price.price / 100 : ''}
                    valueIsNumericString={true}
                    customInput={CFormInput}
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={(values, _) => {
                      updateProductPriceCurrency(
                        values.floatValue ? values.floatValue * 100 : undefined,
                        price,
                        'price'
                      );
                    }}
                  />
                </td>
              );
            },
            dispense_fee: (price: ProductPrice) => {
              return (
                <td className="py-2">
                  <NumericFormat
                    aria-label={`Enter Dispense Fee for ${price.owner?.name}`}
                    value={price.dispense_fee ? price.dispense_fee / 100 : ''}
                    valueIsNumericString={true}
                    customInput={CFormInput}
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={(values, _) => {
                      updateProductPriceCurrency(
                        values.floatValue ? values.floatValue * 100 : undefined,
                        price,
                        'dispense_fee'
                      );
                    }}
                  />
                </td>
              );
            },
            markup_based_pricing: (price: ProductPrice) => {
              return (
                <td className="py-2">
                  <CFormCheck
                    aria-label={`Is the price for ${price.owner?.name} markup based?`}
                    key={`markup_based_pricing_${price.id}`}
                    id={`markup_based_pricing`}
                    onChange={(event) => updateProductPrice(event, price)}
                    checked={price.markup_based_pricing}
                  />
                </td>
              );
            },
            markup_percentage: (price: ProductPrice) => {
              return (
                <td className="py-2">
                  <NumericFormat
                    aria-label={`Enter Markup Percentage for ${price.owner?.name}`}
                    value={price.markup_percentage ?? ''}
                    valueIsNumericString={true}
                    customInput={CFormInput}
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={(values, _) => {
                      updateProductPriceCurrency(values.floatValue, price, 'markup_percentage');
                    }}
                  />
                </td>
              );
            },
            status: (price: ProductPrice) => {
              return (
                <td className="py-2">
                  <CFormSelect
                    aria-label={`Select Status for ${price.owner?.name}`}
                    id={`status-${price.id}`}
                    value={price.status ?? ''}
                    options={[
                      { value: 'active', label: 'Active' },
                      { value: 'disabled', label: 'Disabled' }
                    ]}
                    onChange={(event) => handleSelectedStatusChanged(event, price)}
                  />
                </td>
              );
            },
            tax_rate: (price: ProductPrice) => {
              return (
                <td className="py-2">
                  <NumericFormat
                    aria-label={`Enter Tax Rate for ${price.owner?.name}`}
                    value={price.tax_rate ?? ''}
                    valueIsNumericString={true}
                    customInput={CFormInput}
                    suffix="%"
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={(values, _) => {
                      updateProductPriceCurrency(values.floatValue, price, 'tax_rate');
                    }}
                  />
                </td>
              );
            }
          }}
        />
      </CRow>
      <CRow className="mb-5">
        <CCol sm={9}>
          <CLoadingButton
            size="sm"
            loading={isLoading}
            disabled={ownerOptions?.every((owner) => owner.disabled)}
            onClick={addPlaceholderPrice}
          >
            Add Price
          </CLoadingButton>
        </CCol>
      </CRow>

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
        <CLoadingButton loading={isLoading} color="secondary" onClick={handleCancel}>
          Cancel
        </CLoadingButton>
        <CLoadingButton color="primary" type="submit" loading={isLoading}>
          Save
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default Form;
