import { isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

const countryCode = 'US';

const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return '';
  if (!isPossiblePhoneNumber(phoneNumber, countryCode)) return phoneNumber;

  return parsePhoneNumber(phoneNumber, countryCode).formatNational();
};

export { formatPhoneNumber };
