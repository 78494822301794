import * as React from 'react';

import { CModal, CModalBody, CModalHeader } from '@coreui/react-pro';

import { DiagnosticResult } from 'types/DiagnosticResult';
import { Employee } from 'types/Employee';

import { DiagnosticResultForm } from './DiagnosticResultForm';

type Props = {
  hideForm: () => void;
  loading: boolean;
  diagnosticResult: DiagnosticResult;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  employee: Employee | null;
};

export const EditDiagnosticResultModal = ({ hideForm, loading, onSubmit, diagnosticResult, employee }: Props) => (
  <CModal className="show d-block" visible size="xl" fullscreen="sm" onClose={hideForm}>
    <CModalHeader>
      <h4>Edit Diagnostic Result</h4>
    </CModalHeader>
    <CModalBody>
      <DiagnosticResultForm
        onSubmit={onSubmit}
        diagnosticResult={diagnosticResult}
        hideForm={hideForm}
        loading={loading}
        employee={employee}
      />
    </CModalBody>
  </CModal>
);
