import { HealthStatus } from 'types/HealthStatus';

export const filterMetadata = (item: HealthStatus) => {
  const {
    animal_id,
    class_name,
    consult_id,
    created_at,
    created_by_employee,
    created_by_employee_id,
    employee_id,
    id,
    internal_notes,
    status,
    updated_at,
    updated_by_employee,
    updated_by_employee_id,
    ...filtered
  } = item;

  return filtered;
};
