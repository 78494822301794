import cn from 'classnames';
import React from 'react';

import { CButton, CDatePicker, CDateRangePicker, CFormInput, CFormSelect, CPopover, CRow } from '@coreui/react-pro';

import { ListFilter } from 'types/ListFilter';

import SvgFilter from 'assets/images/SvgFilter';

import styles from './Filters.module.scss';

interface Props {
  setFilters: (filters: ListFilter[]) => void;
  filters: ListFilter[];
  useRange?: boolean;
}

const Filters: React.FC<Props> = ({ setFilters, filters, useRange }) => {
  const filtersComponent = () => {
    return (
      <div>
        Filter By:
        {useRange && (
          <CRow className={styles.filterRow}>
            <CDateRangePicker
              label="Date range"
              locale="en-US"
              placeholder={['Start', 'End']}
              startDate={filters.find((f) => f.key === 'start_date')?.value}
              onStartDateChange={(date: Date | null) => {
                setFilters(
                  filters.map((f) => (f.key === 'start_date' ? { ...f, value: date?.toISOString() ?? '' } : f))
                );
              }}
              endDate={filters.find((f) => f.key === 'end_date')?.value}
              onEndDateChange={(date: Date | null) => {
                setFilters(filters.map((f) => (f.key === 'end_date' ? { ...f, value: date?.toISOString() ?? '' } : f)));
              }}
            />
          </CRow>
        )}
        {filters.map((filter) => (
          <CRow
            key={filter.key}
            className={cn({ [styles.filterRow]: filter.key !== 'start_date' && filter.key !== 'end_date' })}
          >
            {filter.type === 'date' && filter.key !== 'start_date' && filter.key !== 'end_date' && (
              <CDatePicker
                id={filter.key}
                label={filter.name}
                date={filter.value}
                locale="en-US"
                firstDayOfWeek={0}
                format="yyyy-MM-dd"
                onDateChange={(date: Date | null) => {
                  setFilters(
                    filters.map((f) => (f.key === filter.key ? { ...f, value: date?.toISOString() ?? '' } : f))
                  );
                }}
              />
            )}
            {filter.type === 'select' && filter.options && (
              <CFormSelect
                className={cn('form-select', styles.select)}
                id={filter.key}
                label={filter.name}
                onChange={(event) =>
                  setFilters(filters.map((f) => (f.key === filter.key ? { ...f, value: event.target.value } : f)))
                }
                value={filter.value}
                options={filter.options}
              />
            )}
            {filter.type === 'input' && (
              <CFormInput
                className={styles.input}
                id={filter.key}
                label={filter.name}
                onChange={(event) =>
                  setFilters(filters.map((f) => (f.key === filter.key ? { ...f, value: event.target.value } : f)))
                }
                value={filter.value}
              />
            )}
          </CRow>
        ))}
      </div>
    );
  };

  return (
    <>
      <CPopover content={filtersComponent()} placement="bottom" className={styles.popover}>
        <CButton variant="outline" shape="rounded-pill">
          <SvgFilter height={16} /> Filters
        </CButton>
      </CPopover>
    </>
  );
};

export default Filters;
