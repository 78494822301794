import * as React from 'react';
import type { SVGProps } from 'react';

const SvgLetter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5}>
      <path d="M12.718 15.375H5.237c-2.245 0-3.74-1.125-3.74-3.75v-5.25c0-2.625 1.495-3.75 3.74-3.75h7.481c2.244 0 3.74 1.125 3.74 3.75v5.25c0 2.625-1.496 3.75-3.74 3.75" />
      <path d="m12.719 6.75-2.342 1.875c-.77.615-2.035.615-2.806 0L5.237 6.75" />
    </g>
  </svg>
);
export default SvgLetter;
