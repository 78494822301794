import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { CButton, CForm, CLoadingButton } from '@coreui/react-pro';

import { VisitSummary } from 'types/VisitSummary';

import { RichTextEditor } from 'components/RichTextEditor';

import styles from './VisitSummaryForm.module.scss';

type Props = {
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  visitSummary?: VisitSummary;
};

export const VisitSummaryForm = ({ loading, hideForm, onSubmit, visitSummary }: Props) => {
  const [summary, setSummary] = useState(visitSummary?.summary || '');

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-0">{visitSummary ? 'Edit' : 'New'} Summary</h2>

      <RichTextEditor
        name="summary"
        id="summary"
        label="Summary"
        autoFocus
        value={summary}
        onChange={setSummary}
        required
        text="Required"
      />

      <div className={cn('d-flex justify-content-end', styles.buttons)}>
        <CLoadingButton loading={loading} shape="rounded-pill" className={styles.button} color="primary" type="submit">
          {visitSummary ? 'Update' : 'Create'}
        </CLoadingButton>
        <CButton
          type="button"
          shape="rounded-pill"
          className={styles.button}
          color="primary"
          variant="outline"
          onClick={hideForm}
        >
          Close
        </CButton>
      </div>
    </CForm>
  );
};
