import { Employee } from 'types/Employee';
import { Status } from 'types/Status';

import { HealthPlanProduct } from './HealthPlanProduct';
import { HealthPlanTrigger } from './HealthPlanTrigger';

export type HealthPlan = {
  animal_count: number;
  appointment_type_id: number;
  created_at: string;
  details: string | null;
  fulfilled_at: string;
  fulfilled_by: string;
  health_plan_triggers: HealthPlanTrigger[];
  health_plan_products: HealthPlanProduct[];
  id: number;
  importance: HealthPlanImportance;
  minimum_age_in_months: number;
  name: string;
  next_due_date: string;
  recurring: boolean;
  status: Status;
  type: HealthPlanType;
  updated_at: string;
  updated_by_employee: Employee | null;
  why_recommended: string | null;
};

type HealthPlanType = 'DiagnosticPlan' | 'AppointmentPlan' | 'MedicationPlan' | 'VaccinePlan';

type HealthPlanImportance = 'non-core' | 'core' | 'required' | 'low' | 'medium' | 'high'; // low/medium/high are legacy and not used

export const importanceColor = (importance: string) => {
  return { required: 'danger', core: 'warning', 'non-core': 'success' }[importance.toLowerCase()];
};

export const statusColor = (status: string) => {
  return { active: 'success', disabled: 'secondary', draft: 'info' }[status.toLowerCase()];
};

export const typeDisplay = (type: string) => {
  return {
    VaccinePlan: 'Vaccine Plan',
    MedicationPlan: 'Medication Plan',
    DiagnosticPlan: 'Diagnostic Plan',
    AppointmentPlan: 'Appointment Plan',
    ProcedurePlan: 'Procedure Plan'
  }[type];
};
