import axios, { AxiosError } from 'axios';

import { Document } from 'types/Document';
import { Pagination } from 'types/Pagination';

import { ApiResponse } from './ResponseTypes';

export type DocumentsFilterParams = {
  animal_id?: string | number;
  name?: string;
  date_start?: string;
  date_end?: string;
  page?: number;
};

const fetchDocumentsByCustomer = (
  customerId: number | string,
  {
    onSuccess,
    params,
    setPagination
  }: {
    onSuccess: (documents: Document[]) => void;
    params: DocumentsFilterParams;
    setPagination: (pagination: Pagination) => void;
  }
) => {
  const url = `/pim/documents`;

  axios
    .get<Document[]>(url, {
      params: {
        customer_id: customerId,
        ...params
      }
    })
    .then((data) => {
      setPagination({
        page: params.page || 1,
        perPage: Number(data.headers['per-page']),
        total: Number(data.headers.total)
      });
      onSuccess(data.data.record);
    });
};

const fetchDocumentsByAnimal = ({
  onSuccess,
  params,
  setPagination
}: {
  onSuccess: (documents: Document[]) => void;
  params: DocumentsFilterParams;
  setPagination: (pagination: Pagination) => void;
}) => {
  const url = `/pim/documents`;

  axios.get<Document[]>(url, { params: params }).then((data) => {
    setPagination({
      page: params.page || 1,
      perPage: Number(data.headers['per-page']),
      total: Number(data.headers.total)
    });
    onSuccess(data.data.record);
  });
};

const fetchDocumentsByConsult = (
  consultId: number | string,
  {
    onSuccess,
    page,
    setPagination
  }: { onSuccess: (documents: Document[]) => void; page?: number; setPagination: (pagination: Pagination) => void }
) => {
  const url = `/pim/documents`;

  axios.get<Document[]>(url, { params: { consult_id: consultId, page: page || 1 } }).then((data) => {
    setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
    onSuccess(data.data.record);
  });
};

const fetchDocument = (
  documentId: string | number,
  { onSuccess, onError }: { onSuccess: (document: Document) => void; onError: (error: string) => void }
) => {
  const url = `/pim/documents/${documentId}`;
  axios
    .get<Document>(url)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Document>>) => onError(error.message));
};

const createDocument = (
  document: Partial<Document>,
  { onSuccess, onError }: { onSuccess: (document: Document) => void; onError: (error: string) => void }
) => {
  const url = `/pim/documents`;

  axios
    .post<Document>(url, { document: document })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Document>>) => onError(error.message));
};

const updateDocument = (
  documentId: number | string,
  params: Partial<Document>,
  { onSuccess, onError }: { onSuccess: (document: Document) => void; onError: (error: string) => void }
) => {
  const url = `/pim/documents/${documentId}`;
  axios
    .put<Partial<Document>, Document>(url, { document: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Document>>) => onError(error.message));
};

const emailDocument = (
  documentId: number | string,
  { onSuccess, onError }: { onSuccess: (document: Document) => void; onError: (error: string) => void },
  emailAddress?: string,
  bccAddress?: string
) => {
  const url = `/pim/documents/${documentId}/email_to_customer`;
  axios
    .post<Partial<Document>, Document>(url, { email: emailAddress, bcc: bccAddress })
    .then((data) => onSuccess(data))
    .catch((error: AxiosError<ApiResponse<Document>>) => onError(error.message));
};

const sendExternalPrescriptionToMixlab = (
  documentId: number | string,
  { onSuccess, onError }: { onSuccess: (document: Document) => void; onError: (error: string) => void }
) => {
  const url = `/pim/documents/${documentId}/send_external_prescription_via_email`;
  axios
    .post<Partial<Document>, Document>(url)
    .then((data) => onSuccess(data))
    .catch((error: AxiosError<ApiResponse<Document>>) => onError(error.message));
};

export {
  fetchDocumentsByCustomer,
  fetchDocumentsByAnimal,
  fetchDocumentsByConsult,
  fetchDocument,
  updateDocument,
  createDocument,
  emailDocument,
  sendExternalPrescriptionToMixlab
};
