import React, { useEffect } from 'react';

import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchSchedulingCategories } from 'api/SchedulingCategories';

import { SchedulingCategory } from 'types/SchedulingCategory';

import { ColorSquare } from '../../components/ColorSquare';

const SchedulingCategoryList: React.FC = () => {
  const [schedulingCategories, setSchedulingCategories] = React.useState<SchedulingCategory[]>([]);
  useEffect(() => {
    fetchSchedulingCategories(setSchedulingCategories);
  }, []);

  return (
    <>
      <h1>Scheduling Categories</h1>
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Name</CTableHeaderCell>
            <CTableHeaderCell scope="col">Color</CTableHeaderCell>
            <CTableHeaderCell scope="col">Appointment Types</CTableHeaderCell>
            <CTableHeaderCell scope="col"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {schedulingCategories.map((schedulingCategory: SchedulingCategory) => (
            <CTableRow key={schedulingCategory.id}>
              <CTableHeaderCell scope="row">{schedulingCategory.name}</CTableHeaderCell>
              <CTableDataCell>
                <ColorSquare color={schedulingCategory.color} />
              </CTableDataCell>
              <CTableDataCell>
                <ul>
                  {schedulingCategory.appointment_types.length === 0 && <li>None</li>}
                  {schedulingCategory.appointment_types.map((appointmentType) => (
                    <li key={appointmentType.id}>{appointmentType.name_in_pim}</li>
                  ))}
                </ul>
              </CTableDataCell>
              <CTableDataCell>
                <CButton href={`/scheduling_categories/${schedulingCategory.id}/edit`}>Edit</CButton>
              </CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
      <CButton color="primary" href="/scheduling_categories/new">
        New Scheduling Category
      </CButton>
    </>
  );
};

export default SchedulingCategoryList;
