import cn from 'classnames';
import * as React from 'react';
import { Fragment, useState } from 'react';

import { CButton, CButtonGroup, CForm, CLoadingButton } from '@coreui/react-pro';

import { DispenseLog } from 'types/DispenseLog';
import { DispenseRecord } from 'types/DispenseRecord';

import { FormDivider } from 'components/FormDivider';

import styles from './DispenseLogsForm.module.scss';

import { DispenseLogForm } from './DispenseLogForm';

type Props = {
  loading: boolean;
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  dispenseLogs?: DispenseLog[];
  dispenseRecord: DispenseRecord;
};

export const DispenseLogsForm = ({ dispenseRecord, dispenseLogs, onSubmit, hideModal, loading }: Props) => {
  const [validated, setValidated] = useState(false);
  const [formCount, setFormCount] = useState(1);

  const incrementForms = () => {
    setFormCount(formCount + 1);
  };

  const decrementForms = () => {
    setFormCount(formCount - 1);
  };

  const dispenseLogsOrForms = dispenseLogs || Array.from({ length: formCount });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const isValid = form.checkValidity();
    setValidated(true);

    if (isValid) {
      onSubmit(event);
    }
  };

  return (
    <CForm validated={validated} noValidate role="form" className="g-3" onSubmit={handleSubmit}>
      <div className="d-flex align-items-center justify-content-between mb-2">
        {dispenseRecord.prescription_item?.product?.name && (
          <strong>{dispenseRecord.prescription_item.product.name}</strong>
        )}
        {!dispenseLogs && (
          <CButtonGroup
            className={cn('ms-auto', styles.buttonGroup)}
            role="group"
            aria-label="Add or remove dispense logs"
          >
            <CButton size="sm" onClick={incrementForms}>
              Add
            </CButton>
            <CButton size="sm" onClick={decrementForms} disabled={formCount === 1}>
              Remove
            </CButton>
          </CButtonGroup>
        )}
      </div>

      {dispenseLogsOrForms.map((item, index) => {
        const lastItem = index === dispenseLogsOrForms.length - 1;

        return (
          <Fragment key={index}>
            <DispenseLogForm
              requiresLotNumber={dispenseRecord.needs_lot_number}
              requiresExpirationDate={dispenseRecord.needs_expiration_date}
              dispenseLog={item}
              index={index}
            />
            {!lastItem && <FormDivider />}
          </Fragment>
        );
      })}

      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <CLoadingButton loading={loading} color="primary" type="submit" shape="rounded-pill">
          {dispenseLogs ? 'Update Dispense Logs' : 'Create Dispense Logs'}
        </CLoadingButton>
        <CButton color="primary" variant="outline" shape="rounded-pill" type="button" onClick={hideModal}>
          Cancel
        </CButton>
      </div>
    </CForm>
  );
};
