import * as ActionCable from '@rails/actioncable';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useAuth } from '../hooks/useAuth';

type CableContextType = {
  cable?: ActionCable.Consumer;
};
const CableContext = React.createContext({} as CableContextType);

const CableProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const [cable, setCable] = useState<ActionCable.Consumer>();

  useEffect(() => {
    if (auth.employee) {
      const consumer = ActionCable.createConsumer(`/cable`);
      setCable(consumer);
      return () => {
        consumer.disconnect();
      };
    }
  }, [auth.employee]);

  return <CableContext.Provider value={{ cable }}>{children}</CableContext.Provider>;
};

const useCable = () => React.useContext(CableContext);

export { CableProvider, useCable };
