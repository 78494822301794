import cn from 'classnames';
import * as React from 'react';

import { CButton } from '@coreui/react-pro';

import styles from './IconButton.module.scss';

type Props = {
  icon: React.ElementType;
  label?: string;

  // Button passed props
  className?: string;
  disabled?: boolean;
  href?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  variant?: 'outline';
};

export const IconButton = ({ icon: Icon, label, className, ...rest }: Props) => {
  return (
    <CButton
      shape="rounded-pill"
      className={cn('d-flex align-items-center text-nowrap', styles.button, className)}
      {...rest}
    >
      <Icon className="whiteColor" height={16} aria-hidden />
      {label && <span className="ms-1">{label}</span>}
    </CButton>
  );
};
