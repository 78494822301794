import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  CAlert,
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CLoadingButton,
  CRow,
  CSmartTable
} from '@coreui/react-pro';

import { Employee, isOrganizationAdmin } from 'types/Employee';
import { calculatePages, Pagination } from 'types/Pagination';

import { fetchEmployees } from '../../api/Employees';
import { useAuth } from '../../hooks/useAuth';

const List = (): JSX.Element => {
  const auth = useAuth();

  const [employeeList, setEmployees] = React.useState<Employee[]>();
  const [searchText, setSearchText] = React.useState<string>('');
  const [infoMessage, setInfoMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [pagination, setPagination] = React.useState<Pagination>({ page: 1, perPage: 25, total: 1 });

  const [showInactive, setShowInactive] = React.useState<boolean>(false);
  const toggleShowInactive = () => setShowInactive(!showInactive);

  const setSearchResultMessage = useCallback((employeeCount: number) => {
    if (employeeCount > 0) {
      setInfoMessage(`${employeeCount} employee${employeeCount > 1 ? 's' : ''} found.`);
    } else {
      setInfoMessage('No employees found.');
    }
  }, []);

  useEffect(() => {
    setSearchResultMessage(pagination.total);
  }, [pagination.total, setSearchResultMessage]);

  useEffect(() => {
    if (searchText.length === 0) {
      fetchEmployees('', showInactive, setEmployees, setPagination);
    }
  }, [searchText, showInactive]);

  useEffect(() => {
    fetchEmployees('', showInactive, setEmployees, setPagination);
  }, [showInactive]);

  const updateEmployerList = useCallback((customers: Employee[]) => {
    setIsLoading(false);
    setEmployees(customers);
  }, []);

  const updatePage = useCallback(
    (page: number) => {
      setPagination((prev) => ({ ...prev, page }));
      setIsLoading(true);
      fetchEmployees(searchText, showInactive, updateEmployerList, setPagination, page);
    },
    [searchText, showInactive, updateEmployerList]
  );

  const fetchCustomerList = useCallback(() => {
    setInfoMessage('');
    setIsLoading(true);
    fetchEmployees(searchText, showInactive, updateEmployerList, setPagination);
  }, [searchText, showInactive, updateEmployerList]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetchCustomerList();
  };

  return (
    <CRow>
      <CCol xs={12}>
        {infoMessage && (
          <CAlert color="info" dismissible>
            {infoMessage}
          </CAlert>
        )}
        <CCard className="mb-4">
          <CCardHeader>
            <CForm className="row g-3" onSubmit={handleSubmit}>
              <CCol xs={8}>
                <CFormInput
                  type="text"
                  id="searchInput"
                  label="Search Employees"
                  placeholder="Search by name or email"
                  onChange={(event) => setSearchText(event.target.value)}
                />
              </CCol>
              <CCol className="align-self-end" xs={2}>
                <CLoadingButton color="primary" type="submit" loading={isLoading} disabled={!searchText}>
                  Search
                </CLoadingButton>
              </CCol>
              <CCol className="align-self-end text-end" xs={2}>
                {auth.employee && isOrganizationAdmin(auth.employee) ? (
                  <CButton color="primary" href={'/employees/new'}>
                    New
                  </CButton>
                ) : null}
              </CCol>
              <CFormCheck
                className="mb-2 ms-2"
                id={`show-inactive-checkbox`}
                label="Show inactive employees"
                onChange={toggleShowInactive}
                checked={showInactive}
              />
            </CForm>
          </CCardHeader>
          {employeeList && employeeList.length > 0 && (
            <CCardBody role="listitem">
              <CSmartTable
                items={employeeList}
                itemsPerPage={25}
                pagination={{
                  external: true
                }}
                paginationProps={{
                  activePage: pagination.page,
                  pages: calculatePages(pagination)
                }}
                onActivePageChange={(activePage) => updatePage(activePage)}
                columns={[
                  {
                    key: 'name',
                    label: 'Name'
                  },
                  'email',
                  'schedulable_clinics',
                  {
                    key: 'active',
                    label: 'Is Active'
                  }
                ]}
                scopedColumns={{
                  name: (employee: Employee) => {
                    return (
                      <td className="py-2">
                        <Link to={`/employees/${employee.id}`}>
                          {employee.title ? 'Dr. ' : ''}
                          {employee.first_name} {employee.last_name}
                        </Link>
                      </td>
                    );
                  },
                  email: (employee: Employee) => {
                    return <td className="py-2">{employee.email || ''}</td>;
                  },
                  schedulable_clinics: (employee: Employee) => {
                    return (
                      <td className="py-2">
                        {employee?.pim_resources?.map((resource) => resource.clinic?.name).join(', ')}
                      </td>
                    );
                  },
                  active: (employee: Employee) => {
                    return (
                      <td className="py-2">
                        <CBadge color={employee.active ? 'success' : 'danger'}>
                          {employee.active ? 'Active' : 'Inactive'}
                        </CBadge>
                      </td>
                    );
                  }
                }}
              ></CSmartTable>
            </CCardBody>
          )}
        </CCard>
      </CCol>
    </CRow>
  );
};

export default List;
