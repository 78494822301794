import axios from 'axios';

import { ConsultTemplate, ConsultTemplateType } from 'types/ConsultTemplate';
import { Pagination } from 'types/Pagination';

function fetchConsultTemplates({
  queryText,
  templateType,
  onSuccess,
  setPagination,
  page
}: {
  queryText?: string; // `undefined` does not filter by query
  templateType?: ConsultTemplateType; // `undefined` does not filter by type
  onSuccess: (templates: ConsultTemplate[]) => void;
  setPagination?: (pagination: Pagination) => void;
  page?: number;
}) {
  const url = `/pim/consult_templates`;

  axios
    .get<ConsultTemplate[]>(url, {
      params: {
        type: templateType,
        query: queryText,
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      if (setPagination)
        setPagination({
          page: page || 1,
          perPage: Number(data.headers['per-page']),
          total: Number(data.headers.total)
        });
    });
}

function fetchDocumentTemplates(
  queryText: string,
  onSuccess: (templates: ConsultTemplate[]) => void,
  setPagination?: (pagination: Pagination) => void,
  page?: number
) {
  const url = `/pim/consult_templates`;
  axios
    .get<ConsultTemplate[]>(url, {
      params: {
        type: 'DocumentTemplate',
        query: queryText,
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      if (setPagination)
        setPagination({
          page: page || 1,
          perPage: Number(data.headers['per-page']),
          total: Number(data.headers.total)
        });
    });
}

function fetchConsultTemplate(templateId: string, onSuccess: (template: ConsultTemplate) => void) {
  const url = `/pim/consult_templates/${templateId}`;
  axios.get<ConsultTemplate>(url).then((data) => onSuccess(data.data.record));
}

function updateConsultTemplate(
  templateId: string,
  params: Partial<ConsultTemplate>,
  onSuccess: (template: ConsultTemplate) => void
) {
  const url = `/pim/consult_templates/${templateId}`;
  axios.put<ConsultTemplate>(url, { consult_template: params }).then((data) => onSuccess(data.data.record));
}

function createConsultTemplate(
  params: Partial<ConsultTemplate>,
  onSuccess: (template: ConsultTemplate) => void,
  onError?: (error: string) => void
) {
  const url = `/pim/consult_templates`;
  axios
    .post<ConsultTemplate>(url, { consult_template: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
}

export {
  fetchConsultTemplates,
  fetchDocumentTemplates,
  fetchConsultTemplate,
  updateConsultTemplate,
  createConsultTemplate
};
