import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { TemplateSelector } from 'views/consult_templates/TemplateSelector';

import { CButton, CCard, CCol, CForm, CLoadingButton } from '@coreui/react-pro';

import { fetchConsultTemplate } from 'api/ConsultTemplates';

import { compileConsultTemplate, consultTemplateForm, FormLine } from 'types/ConsultTemplate';
import { History } from 'types/History';

import { ConsultTemplateForm } from 'components/ConsultTemplateForm';
import { FormAuditData } from 'components/FormAuditData';
import { RichTextEditor } from 'components/RichTextEditor';

import styles from './HistoryForm.module.scss';

type Props = {
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  history?: History;
};

export const HistoryForm = ({ loading, hideForm, onSubmit, history }: Props) => {
  const [historyField, setHistoryField] = useState(history?.history ?? '');

  const [templateForm, setTemplateForm] = useState<FormLine[]>();

  const loadTemplate = (templateId: string) => {
    fetchConsultTemplate(templateId, (template) => {
      const form = consultTemplateForm(template);
      setTemplateForm(form);

      const newHistoryField = compileConsultTemplate(form);
      setHistoryField(newHistoryField);
    });
  };

  const formChanged = (form: FormLine[]) => {
    setTemplateForm(form);

    const newHistoryField = compileConsultTemplate(form);
    setHistoryField(newHistoryField);
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{history ? 'Edit' : 'New'} History</h2>

      {!history && (
        <CCol className="mb-3">
          <TemplateSelector templateType="HistoryTemplate" templateChanged={loadTemplate} />
        </CCol>
      )}

      <CCol className="mb-3">
        {templateForm && (
          <CCard className="p-3">
            <ConsultTemplateForm form={templateForm} updateForm={formChanged} />
          </CCard>
        )}

        <RichTextEditor
          required
          label="History"
          value={historyField}
          hidden={!!templateForm}
          onChange={setHistoryField}
          id="history"
          name="history"
          text="Required"
        />
      </CCol>

      <div className="d-flex align-items-center justify-content-between">
        {history && <FormAuditData item={history} />}
        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton
            loading={loading}
            className={styles.button}
            shape="rounded-pill"
            color="primary"
            type="submit"
          >
            {history ? 'Update' : 'Create'}
          </CLoadingButton>
          <CButton variant="outline" className={styles.button} shape="rounded-pill" type="button" onClick={hideForm}>
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
