import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { paths } from 'routes';

import { cilAnimal, cilCalendar, cilPeople } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CCloseButton, CCol, CImage, CRow, CSidebar, CSidebarHeader, CSpinner } from '@coreui/react-pro';

import { fetchAnimal } from 'api/Animals';
import {
  cancelAndChargeAppointment,
  cancelAndRefundAppointment,
  cancelAppointment,
  updateAppointment
} from 'api/Appointments';
import { fetchCustomerUnpaidInvoices } from 'api/Invoices';
import { fetchPaymentsForAppointment } from 'api/Payments';

import { Animal } from 'types/Animal';
import { Appointment, AppointmentAction } from 'types/Appointment';
import { CancelReason } from 'types/CancelReason';
import { Invoice } from 'types/Invoice';
import { Payment } from 'types/Payment';
import { Room } from 'types/Room';

import { useClickOutside } from 'hooks/useClickOutside';

import { ageDisplay, desexedDisplay } from 'utils/animal';
import { generateCloudinaryPath } from 'utils/cloudinary';
import { compactDateDisplay, dateDisplay, timeDisplay } from 'utils/dates';
import { renderMarkdown } from 'utils/markdown';
import { toCurrency } from 'utils/price';
import { toOption } from 'utils/selectOptions';

import { AppointmentStatusSelect } from 'components/AppointmentStatusSelect';
import { CancelAppointmentModal } from 'components/CancelAppointmentModal';
import { NewPatientBadge } from 'components/NewPatientBadge';
import { Pill } from 'components/Pill';
import { PossibleParvoBadge } from 'components/PossibleParvoBadge';

import styles from './AppointmentSidebar.module.scss';

type Props = {
  appointment: Appointment;
  rooms: Room[];
  isVisible: boolean;
  onClose: () => void;
};

type Modals = 'cancel' | 'charge-and-cancel' | 'refund-and-cancel';
type Actions =
  | 'cancel-appointment'
  | 'charge-and-cancel-appointment'
  | 'refund-and-cancel-appointment'
  | 'update-room'
  | 'update-appointment-status';

type Option = {
  value: string;
  label: string;
};

export const AppointmentSidebar = ({ appointment, isVisible, onClose, rooms }: Props) => {
  const [modalVisible, setModalVisible] = useState<Modals>();
  const [networkAction, setNetworkAction] = useState<Actions>();
  const [animal, setAnimal] = useState<Animal>();
  const [payments, setPayments] = useState<Payment[] | undefined>();
  const [invoices, setInvoices] = useState<Invoice[] | undefined>();
  const [selectedCancelReason, setSelectedCancelReason] = useState<CancelReason | undefined>();

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, onClose, ['modal']);

  useEffect(() => {
    fetchAnimal(appointment.animal.id, setAnimal);
    fetchPaymentsForAppointment(String(appointment.id), setPayments);
    fetchCustomerUnpaidInvoices(String(appointment.customer?.id), setInvoices);
  }, [appointment.animal.id, appointment.customer?.id, appointment.id]);

  const [selectedRoomOption, setSelectedRoomOption] = useState<Option | null | undefined>(
    appointment.room ? toOption(appointment.room) : null
  );
  const [roomOptions] = useState<Option[]>([{ label: 'Unassigned', value: '' }, ...rooms.map(toOption)]);

  const ListItem = ({ label, value }: { label: string; value?: ReactNode }) => (
    <li className="d-flex justify-content-between mb-1">
      <div className="text-secondary">{label}:</div>
      <div>{value || 'None'}</div>
    </li>
  );

  const showCancelModal = () => setModalVisible('cancel');
  const showChargeAndCancelModal = () => setModalVisible('charge-and-cancel');
  const showRefundAndCancelModal = () => setModalVisible('refund-and-cancel');

  const isCancelModalVisible = modalVisible === 'cancel';
  const isChargeAndCancelModalVisible = modalVisible === 'charge-and-cancel';
  const isRefundAndCancelModalVisible = modalVisible === 'refund-and-cancel';

  const isCancelAppointmentLoading = networkAction === 'cancel-appointment';
  const isChargeAndCancelAppointmentLoading = networkAction === 'charge-and-cancel-appointment';
  const isRefundAndCancelAppointmentLoading = networkAction === 'refund-and-cancel-appointment';

  const handleCancelSuccess = () => {
    toast.success('Appointment canceled!');

    setNetworkAction(undefined);
    hideModal();
    onClose();
  };

  const handleCancelError = () => {
    setNetworkAction(undefined);
  };

  const handleCancel = () => {
    setNetworkAction('cancel-appointment');
    cancelAppointment(
      appointment.id,
      Number(selectedCancelReason?.id),
      selectedCancelReason?.name,
      handleCancelSuccess,
      handleCancelError
    );
  };

  const handleChargeAndCancel = () => {
    setNetworkAction('charge-and-cancel-appointment');
    cancelAndChargeAppointment(
      appointment.id,
      Number(selectedCancelReason?.id) || undefined,
      selectedCancelReason?.name,
      handleCancelSuccess,
      handleCancelError
    );
  };

  const handleRefundAndCancel = () => {
    setNetworkAction('refund-and-cancel-appointment');
    cancelAndRefundAppointment(
      appointment.id,
      Number(selectedCancelReason?.id),
      selectedCancelReason?.name,
      handleCancelSuccess,
      handleCancelError
    );
  };

  const hideModal = () => {
    setModalVisible(undefined);
  };

  const handleUpdateSuccess = () => {
    setNetworkAction(undefined);
    toast.success('Appointment updated!');
  };

  const handleUpdateError = () => {
    setNetworkAction(undefined);
  };

  const isRoomUpdateLoading = networkAction === 'update-room';
  const handleRoomSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = roomOptions.find((option: Option) => option.value === event.target.value);
    if (!selected) return;
    setSelectedRoomOption(selected);

    let room_id;
    if (selected.value === '') {
      room_id = null;
    } else {
      room_id = Number(selected?.value);
    }

    setNetworkAction('update-room');
    updateAppointment(appointment.id, { room_id }, { onSuccess: handleUpdateSuccess, onError: handleUpdateError });
  };

  const isActionAvailable = (action: AppointmentAction) => appointment.available_actions.includes(action);
  const canChargeAndCancel = isActionAvailable('cancel-and-charge');
  const canRefundAndCancel = isActionAvailable('cancel-and-refund');
  const canCancel = isActionAvailable('cancel');

  return (
    <CSidebar
      ref={ref}
      colorScheme="light"
      overlaid
      placement="end"
      size="xl"
      visible={isVisible}
      style={{ overflowY: 'scroll' }}
    >
      <CSidebarHeader className="bg-transparent">
        <CCloseButton className="float-end" onClick={onClose} />
      </CSidebarHeader>
      <h5 className="text-center">Appointment Details</h5>

      <div className="p-4">
        <CRow className="mb-3">
          <CCol xs>
            <label htmlFor="room_id" className="form-label text-secondary">
              Room: {isRoomUpdateLoading && <CSpinner size="sm" />}
            </label>
            <select
              className="form-select form-select-sm"
              id="room_id"
              onChange={handleRoomSelectChange}
              value={selectedRoomOption?.value}
            >
              {roomOptions.map((option: Option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </CCol>
          <CCol xs>
            <label htmlFor="appointment_status" className="form-label text-secondary">
              Status:
            </label>
            <AppointmentStatusSelect appointment={appointment} />
          </CCol>
        </CRow>

        <div className="mb-2">
          {canChargeAndCancel && (
            <>
              <CButton onClick={showChargeAndCancelModal} size="sm" className="me-2">
                Charge & Cancel
              </CButton>

              <CancelAppointmentModal
                isVisible={isChargeAndCancelModalVisible}
                onClose={hideModal}
                onConfirm={handleChargeAndCancel}
                onReasonSelected={setSelectedCancelReason}
                modalBody={`Cancel appointment, charge ${toCurrency(appointment.cancel_fee_price)}, and notify client?`}
                confirmButtonLabel="Yes, charge & cancel"
                cancelButtonLabel="No, don't charge & cancel"
                modalHeader="Charge Fee & Cancel Appointment"
                isLoading={isChargeAndCancelAppointmentLoading}
              />
            </>
          )}

          {canRefundAndCancel && (
            <>
              <CButton onClick={showRefundAndCancelModal} size="sm" className="me-2">
                Refund & Cancel
              </CButton>

              <CancelAppointmentModal
                isVisible={isRefundAndCancelModalVisible}
                onClose={hideModal}
                onConfirm={handleRefundAndCancel}
                onReasonSelected={setSelectedCancelReason}
                modalBody={`Cancel appointment, refund ${toCurrency(
                  appointment.booking_fee_price_paid
                )}, and notify client?`}
                confirmButtonLabel="Yes, refund & cancel"
                cancelButtonLabel="No, don't refund & cancel"
                modalHeader="Refund Fee & Cancel Appointment"
                isLoading={isRefundAndCancelAppointmentLoading}
              />
            </>
          )}

          {canCancel && (
            <>
              <CButton color="primary" onClick={showCancelModal} size="sm">
                Cancel
              </CButton>

              <CancelAppointmentModal
                isVisible={isCancelModalVisible}
                onClose={hideModal}
                onConfirm={handleCancel}
                onReasonSelected={setSelectedCancelReason}
                modalBody="Cancel appointment and notify client?"
                confirmButtonLabel="Yes, cancel"
                cancelButtonLabel="No, don't cancel"
                modalHeader="Cancel Appointment"
                isLoading={isCancelAppointmentLoading}
              />
            </>
          )}
        </div>

        <div>
          <Link className="me-2" to={`/appointments/${appointment.id}`} target="_blank" rel="noopener,noreferrer">
            Details
          </Link>

          <Link className="me-2" to={`/appointments/${appointment.id}/edit`} target="_blank" rel="noopener,noreferrer">
            Edit
          </Link>

          {appointment.consult_id && (
            <Link className="me-2" to={generatePath(paths.consultDetails, { id: appointment.consult_id })}>
              Consult
            </Link>
          )}

          <Link
            className="me-2"
            to={`/appointments/${appointment.id}/checkout`}
            target="_blank"
            rel="noopener,noreferrer"
          >
            Checkout
          </Link>
        </div>

        <hr />

        <div className="mb-2">
          <h6 className="mb-2 d-flex align-items-center gap-2">
            <CIcon icon={cilCalendar} aria-hidden />
            <span>Details</span>
            {animal && <NewPatientBadge animal={animal} />}
            {animal && <PossibleParvoBadge animal={animal} appointment={appointment} />}
          </h6>

          <ul className="ps-0 mb-0">
            <ListItem label="Doctor/Nurse" value={appointment.employee.full_name_with_title} />
            <ListItem label="Clinic" value={appointment.clinic.name} />
            <ListItem label="Type" value={appointment.appointment_type.name_in_pim} />
            <ListItem label="Date" value={compactDateDisplay(appointment.start_time)} />
            <ListItem label="Start Time" value={timeDisplay(appointment.start_time)} />
            <ListItem label="End Time" value={timeDisplay(appointment.end_time)} />
            <ListItem label="Date Booked" value={compactDateDisplay(appointment.created_at)} />
          </ul>

          {appointment.visit_reason_list.length > 0 && (
            <div className="mb-1">
              <div className="mb-1 text-secondary">Visit Reasons:</div>
              <div>{appointment.visit_reason_list.join(', ')}</div>
            </div>
          )}

          {appointment.notes && (
            <div className="mb-1">
              <div className="mb-1 text-secondary">Notes:</div>
              <div className="mb-0">{renderMarkdown(appointment.notes)}</div>
            </div>
          )}

          {appointment.internal_notes && (
            <div className="mb-1">
              <div className="mb-1 text-secondary">Internal Notes:</div>
              {appointment.internal_notes ? (
                <div className={styles.appointmentNotes}>
                  <div className={styles.scrollableContent}>
                    {appointment.internal_notes ?? renderMarkdown(appointment.internal_notes)}
                  </div>
                </div>
              ) : (
                <div>
                  <i>None entered</i>
                </div>
              )}
            </div>
          )}

          <div className="mt-1 mb-1 text-secondary">All Unpaid Invoices:</div>
          {invoices &&
            invoices.map((invoice) => (
              <li key={invoice.id}>
                <Link to={`/invoices/${invoice.id}`}>
                  {invoice.date_due
                    ? compactDateDisplay(invoice.date_due)
                    : invoice.created_at
                    ? compactDateDisplay(invoice.created_at)
                    : null}
                </Link>{' '}
                for <Link to={`/animals/${invoice.animal_id}`}>{invoice.animal_name}</Link> -{' '}
                {toCurrency(invoice.unpaid_total)}
              </li>
            ))}
          {(!invoices || invoices.length === 0) && <i>No unpaid invoices</i>}

          <div className="mb-1 mt-1 text-secondary">Payments:</div>
          {payments &&
            payments.map((payment) => (
              <li key={payment.id}>
                {payment.type} - {toCurrency(payment.amount)}
              </li>
            ))}
          {(!payments || payments.length === 0) && <i>No payments</i>}
        </div>

        <hr />

        <div className="mb-2">
          <h6 className="mb-2 d-flex align-items-center">
            <CIcon icon={cilAnimal} aria-hidden className="me-2" />
            <span>Patient</span>
          </h6>
          {appointment.animal.photo_provider_id && (
            <CImage
              fluid
              style={{ maxHeight: '100px' }}
              className="mb-2"
              alt={appointment.animal.name ?? 'Patient photo'}
              src={generateCloudinaryPath({
                imagePublicId: appointment.animal.photo_provider_id
              })}
            />
          )}
          <ul className="ps-0 mb-1">
            <ListItem
              label="Name"
              value={
                <Link to={`/animals/${appointment.animal.id}`} target="_blank" rel="noopener,noreferrer">
                  {appointment.animal.name}
                </Link>
              }
            />
            <ListItem
              label="Date of Birth"
              value={appointment.animal.date_of_birth && dateDisplay(appointment.animal.date_of_birth)}
            />
            <ListItem
              label="Age"
              value={appointment.animal.date_of_birth && ageDisplay(appointment.animal.date_of_birth)}
            />
            <ListItem label="Species" value={appointment.animal.species.name} />
            <ListItem label="Plan" value={appointment.plan?.name} />
            <ListItem
              label="Previous Records"
              value={
                appointment.animal.past_records_uploaded ? (
                  'Records uploaded'
                ) : appointment.animal.previous_clinics_requested ? (
                  'Records requested'
                ) : appointment.animal.no_past_records ? (
                  'No previous records'
                ) : (
                  <em>Ask customer for records</em>
                )
              }
            />

            <ListItem label="Sex" value={desexedDisplay(appointment.animal)} />
            <ListItem label="Breed" value={appointment.animal.breed && appointment.animal.breed.name} />
            <ListItem
              label="FAS"
              value={appointment.animal.fas_score && <Pill label={`FAS ${appointment.animal.fas_score}`} />}
            />
            <ListItem
              label="Weight"
              value={
                appointment.animal.weight &&
                appointment.animal.weight > 0 &&
                `${appointment.animal.weight} ${appointment.animal.weight_unit}`
              }
            />
            <ListItem label="Microchip #" value={appointment.animal.microchip_number} />
            <ListItem
              label="Pet Peeves"
              value={
                appointment.animal.pet_peeve_list &&
                appointment.animal.pet_peeve_list.length > 0 &&
                appointment.animal.pet_peeve_list.join(', ')
              }
            />
            <ListItem
              label="Personality Traits"
              value={
                appointment.animal.personality_trait_list &&
                appointment.animal.personality_trait_list.length > 0 &&
                appointment.animal.personality_trait_list.join(', ')
              }
            />
            <ListItem label="Favorite Treat" value={appointment.animal.favorite_treat} />
          </ul>
          <div className="mb-1 mt-1">
            <div className="mb-1 text-secondary">Internal Notes:</div>
            {appointment.animal.internal_notes ? (
              <div className={styles.appointmentNotes}>
                <div className={styles.scrollableContent}>
                  {appointment.animal.internal_notes ?? renderMarkdown(appointment.animal.internal_notes)}
                </div>
              </div>
            ) : (
              <div>
                <i>None entered</i>
              </div>
            )}
          </div>
        </div>

        <hr />

        {appointment.customer && (
          <div className="mb-2">
            <h6 className="mb-2 d-flex align-items-center">
              <CIcon icon={cilPeople} aria-hidden className="me-2" />
              <span>Client</span>
            </h6>
            <ul className="ps-0 mb-0">
              <ListItem
                label="Name"
                value={
                  <Link to={`/customers/${appointment.customer.id}`} target="_blank" rel="noopener,noreferrer">
                    {appointment.customer.first_name} {appointment.customer.last_name}
                  </Link>
                }
              />
              <ListItem label="Phone" value={appointment.customer.phone} />
              <ListItem label="Email" value={appointment.customer.email} />
              <ListItem label="Plan" value={appointment.plan?.name} />
            </ul>
            <div className="mb-1 mt-1">
              <div className="mb-1 text-secondary">Internal Notes:</div>
              {appointment.customer.internal_notes ? (
                <div className={styles.appointmentNotes}>
                  <div className={styles.scrollableContent}>
                    {appointment.customer.internal_notes ?? renderMarkdown(appointment.customer.internal_notes)}
                  </div>
                </div>
              ) : (
                <div>
                  <i>None entered</i>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </CSidebar>
  );
};
