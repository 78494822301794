import * as React from 'react';
import { useState } from 'react';

import {
  CButton,
  CDropdown,
  CDropdownItemPlain,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle
} from '@coreui/react-pro';

import { createAttachments, deleteAttachment } from 'api/Attachments';

import { Attachment } from 'types/Attachment';
import { HospitalSheet } from 'types/HospitalSheet';

import { pluralize } from 'utils/strings';

import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import { ReactComponent as PlusIcon } from 'assets/images/plus-thin.svg';
import SvgTrash from 'assets/images/SvgTrash';

import { ConfirmationModal } from 'components/ConfirmationModal';

type Props = {
  hospitalSheet: HospitalSheet;
  onSuccess: (message: string) => void;
};

export const AttachmentsButton = ({ hospitalSheet, onSuccess }: Props) => {
  const [showAttachFilesModal, setShowAttachFilesModal] = useState(false);
  const [attachmentToDelete, setAttachmentToDelete] = useState<Attachment>();
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isAttachmentDeleting, setIsAttachmentDeleting] = useState(false);

  const attachFilesModal = () => {
    return (
      <CModal visible={showAttachFilesModal} onClose={() => setShowAttachFilesModal(false)}>
        <CModalHeader closeButton>
          <CModalTitle>Add Attachments</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm onSubmit={handleUploadAttachments}>
            <CFormInput
              label="Attached files will be associated with this hospital sheet."
              id="inputFiles"
              name="inputFiles"
              type="file"
              disabled={isUploadLoading}
              multiple
            />
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-end mt-3">
              <CLoadingButton size="sm" loading={isUploadLoading} disabled={isUploadLoading} type="submit">
                Upload
              </CLoadingButton>
              <CButton
                color="secondary"
                size="sm"
                disabled={isUploadLoading}
                onClick={() => setShowAttachFilesModal(false)}
              >
                Cancel
              </CButton>
            </div>
          </CForm>
        </CModalBody>
      </CModal>
    );
  };

  const handleUploadAttachments = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsUploadLoading(true);
    const inputFiles = event.currentTarget.inputFiles;
    createAttachments(inputFiles.files, 'HospitalSheet', hospitalSheet.id, attachSuccess);
  };

  const attachSuccess = (attachments: Attachment[]) => {
    setIsUploadLoading(false);
    setShowAttachFilesModal(false);
    onSuccess(`${pluralize('File', attachments.length)} attached!`);
  };

  const confirmDeleteAttachmentModal = () => {
    if (attachmentToDelete)
      return (
        <ConfirmationModal
          isVisible
          isLoading={isAttachmentDeleting}
          onClose={() => setAttachmentToDelete(undefined)}
          onConfirm={() => handleDeleteAttachment()}
          modalBody={`${attachmentToDelete.name} will be removed from this hospital sheet.`}
          modalHeader={`Delete attachment?`}
          confirmButtonLabel={`Yes, delete it`}
        />
      );
  };

  const handleDeleteAttachment = () => {
    if (attachmentToDelete) {
      setIsAttachmentDeleting(true);
      deleteAttachment(attachmentToDelete.id, deleteSuccess);
    }
  };

  const deleteSuccess = () => {
    setIsAttachmentDeleting(false);
    setAttachmentToDelete(undefined);
    onSuccess('File deleted!');
  };

  return (
    <>
      <CInputGroup size="sm" style={{ height: 'fit-content', flexWrap: 'nowrap' }} color="primary">
        {hospitalSheet.attachments && hospitalSheet.attachments.length > 0 && (
          <CDropdown variant="input-group">
            <CDropdownToggle variant="outline">View Attachments</CDropdownToggle>
            <CDropdownMenu style={{ width: 'max-content' }}>
              {hospitalSheet.attachments.map((attachment) => (
                <CDropdownItemPlain
                  key={attachment.id}
                  className="d-flex justify-content-between align-items-center w-100"
                  style={{ gap: '5px' }}
                >
                  <CFormLabel className="m-0">{attachment.name} </CFormLabel>
                  <div className="d-flex" style={{ gap: '5px' }}>
                    <CButton
                      variant="outline"
                      href={attachment.private_url}
                      className="d-flex justify-content-between align-items-center"
                      size="sm"
                      style={{ height: '30px' }}
                    >
                      <DownloadIcon style={{ width: '15px', height: '15px' }} />
                    </CButton>
                    <CButton
                      variant="outline"
                      color="danger"
                      size="sm"
                      onClick={() => setAttachmentToDelete(attachment)}
                      style={{ height: '30px' }}
                    >
                      <SvgTrash className="danger" style={{ width: '15px', height: '15px' }} />
                    </CButton>
                  </div>
                </CDropdownItemPlain>
              ))}
            </CDropdownMenu>
          </CDropdown>
        )}

        <CButton
          variant="outline"
          size="sm"
          onClick={() => setShowAttachFilesModal(true)}
          style={{ width: 'max-content' }}
        >
          {!hospitalSheet.attachments || hospitalSheet.attachments.length === 0 ? (
            'Add Attachment'
          ) : (
            <PlusIcon style={{ width: '10px', height: '10px' }} />
          )}
        </CButton>
      </CInputGroup>

      {attachFilesModal()}
      {confirmDeleteAttachmentModal()}
    </>
  );
};

export default AttachmentsButton;
