import axios, { AxiosError } from 'axios';

import { PlanningGuide } from 'types/PlanningGuide';

import { ApiResponse } from './ResponseTypes';

const fetchPlanningGuides = (
  startAt: Date,
  endAt: Date,
  clinicId: number,
  onSuccess: (data: PlanningGuide[]) => void
) => {
  const url = `/pim/planning_guides`;
  const params = { start_at: startAt, end_at: endAt, clinic_id: clinicId };
  axios.get<PlanningGuide[]>(url, { params }).then((data) => onSuccess(data.data.record));
};

const fetchPlanningGuide = (planningGuideId: number, onSuccess: (data: PlanningGuide) => void) => {
  const url = `/pim/planning_guides/${planningGuideId}`;
  axios.get<PlanningGuide>(url).then((data) => onSuccess(data.data.record));
};

type PlanningGuideInput = {
  start_at?: string;
  end_at?: string;
  pim_resource_id: string;
  scheduling_category_id: number;
};
const createPlanningGuide = (planningGuide: PlanningGuideInput, onSuccess: (data: PlanningGuide) => void) => {
  const url = `/pim/planning_guides`;
  axios.post<PlanningGuide>(url, planningGuide).then((data) => onSuccess(data.data.record));
};

const updatePlanningGuide = (
  planningGuideId: number,
  planningGuide: PlanningGuideInput,
  onSuccess: (data: PlanningGuide) => void,
  onError: (message: string) => void
) => {
  const url = `/pim/planning_guides/${planningGuideId}`;
  axios
    .put<PlanningGuide>(url, planningGuide)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<PlanningGuide>>) => onError(error.message));
};

const deletePlanningGuide = (planningGuideId: number, onSuccess: (deletedId: number) => void) => {
  const url = `/pim/planning_guides/${planningGuideId}`;
  axios.delete(url).then(() => onSuccess(planningGuideId));
};

export { fetchPlanningGuides, fetchPlanningGuide, createPlanningGuide, updatePlanningGuide, deletePlanningGuide };
