import axios from 'axios';

import { Estimate } from 'types/Estimate';
import { Pagination } from 'types/Pagination';

export type EstimateFilterParams = {
  animal_id?: string | number;
  date_start?: string;
  date_end?: string;
  page?: number;
  status?: string;
};

const fetchEstimates = (
  onSuccess: (estimates: Estimate[]) => void,
  setPagination: (pagination: Pagination) => void,
  page?: number
) => {
  const url = `/pim/estimates`;

  axios
    .get<Estimate[]>(url, {
      params: {
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
    });
};

const fetchEstimatesForCustomer = (
  customerId: number | string,
  params: EstimateFilterParams,
  onSuccess: (estimates: Estimate[]) => void,
  setPagination: (pagination: Pagination) => void
) => {
  const url = `/pim/estimates?customer_id=${customerId}`;
  axios.get<Estimate[]>(url, { params: params }).then((data) => {
    onSuccess(data.data.record);

    setPagination({
      page: params.page || 1,
      perPage: Number(data.headers['per-page']),
      total: Number(data.headers.total)
    });
  });
};

const fetchEstimatesForAnimal = (
  animalId: string,
  params: EstimateFilterParams,
  onSuccess: (estimates: Estimate[]) => void,
  setPagination: (pagination: Pagination) => void
) => {
  const url = `/pim/estimates?animal_id=${animalId}`;
  axios.get<Estimate[]>(url, { params: params }).then((data) => {
    onSuccess(data.data.record);

    setPagination({
      page: params.page || 1,
      perPage: Number(data.headers['per-page']),
      total: Number(data.headers.total)
    });
  });
};

const fetchEstimatesForConsult = (consultId: number | string, onSuccess: (estimates: Estimate[]) => void) => {
  const url = `/pim/estimates`;
  axios.get<Estimate[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const fetchEstimate = (estimateId: string | number, onSuccess: (estimate: Estimate) => void) => {
  const url = `/pim/estimates/${estimateId}`;
  axios.get<Estimate>(url).then((data) => onSuccess(data.data.record));
};

const createEstimate = (
  params: Partial<Estimate>,
  onSuccess: (estimate: Estimate) => void,
  onError?: (error: string) => void
) => {
  const url = `/pim/estimates`;
  axios
    .post<Estimate>(url, { estimate: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};

const updateEstimate = (
  estimateId: string | number,
  params: Partial<Estimate>,
  { onSuccess, onError }: { onSuccess: (estimate: Estimate) => void; onError?: (error: string) => void }
) => {
  const url = `/pim/estimates/${estimateId}`;
  axios
    .put<Estimate>(url, { estimate: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};

export {
  createEstimate,
  fetchEstimate,
  fetchEstimates,
  fetchEstimatesForCustomer,
  fetchEstimatesForAnimal,
  fetchEstimatesForConsult,
  updateEstimate
};
