import axios, { AxiosError } from 'axios';

import { Invoice } from 'types/Invoice';
import { Pagination } from 'types/Pagination';
import { CashPayment, Payment, PaymentMethod } from 'types/Payment';

import { ApiResponse } from './ResponseTypes';

export type InvoiceFilterParams = {
  animal_id?: string | number;
  date_start?: string;
  date_end?: string;
  page?: number;
  status?: string;
  fully_paid?: boolean;
};

const fetchInvoices = (onSuccess: (invoices: Invoice[]) => void) => {
  const url = `/pim/invoices/`;
  axios.get<Invoice[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchInvoicesForAnimal = (
  params: InvoiceFilterParams,
  onSuccess: (invoices: Invoice[]) => void,
  setPagination: (pagination: Pagination) => void
) => {
  const url = `/pim/invoices/`;
  axios
    .get<Invoice[]>(url, {
      params: params
    })
    .then((data) => {
      onSuccess(data.data.record);

      setPagination({
        page: params.page || 1,
        perPage: Number(data.headers['per-page']),
        total: Number(data.headers.total)
      });
    });
};

const fetchInvoicesForCustomer = (
  customerId: number | string,
  params: InvoiceFilterParams,
  onSuccess: (invoices: Invoice[]) => void,
  setPagination: (pagination: Pagination) => void
) => {
  const url = `/pim/invoices/?customer_id=${customerId}`;
  axios.get<Invoice[]>(url, { params: params }).then((data) => {
    onSuccess(data.data.record);

    setPagination({
      page: params.page || 1,
      perPage: Number(data.headers['per-page']),
      total: Number(data.headers.total)
    });
  });
};

const fetchInvoicesForAppointment = (
  appointmentId: string,
  params: { page?: number; perPage?: number },
  onSuccess: (invoices: Invoice[]) => void,
  setPagination: (pagination: Pagination) => void
) => {
  const url = `/pim/invoices/?appointment_id=${appointmentId}`;
  axios.get<Invoice[]>(url, { params: { page: params.page || 1, per_page: params.perPage || 12 } }).then((data) => {
    onSuccess(data.data.record);

    setPagination({
      page: params.page || 1,
      perPage: Number(data.headers['per-page']),
      total: Number(data.headers.total)
    });
  });
};

const fetchInvoicesForConsult = (consultId: number | string, onSuccess: (invoices: Invoice[]) => void) => {
  const url = `/pim/invoices`;
  axios.get<Invoice[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const fetchCustomerUnpaidInvoices = (customerId: string, onSuccess: (invoices: Invoice[]) => void) => {
  const url = `/pim/invoices/unpaid?customer_id=${customerId}`;
  axios.get<Invoice[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchInvoice = (invoiceId: string | number, onSuccess: (invoice: Invoice) => void) => {
  const url = `/pim/invoices/${invoiceId}`;
  axios.get<Invoice>(url).then((data) => onSuccess(data.data.record));
};

const payInvoice = (
  invoiceId: number,
  amount: number,
  onSuccess: (payment: Payment | CashPayment) => void,
  onError: (message: string) => void,
  paymentMethod?: PaymentMethod,
  paymentProviderId?: string
) => {
  const url = `/pim/invoices/${invoiceId}/pay`;
  axios
    .put<Payment | CashPayment>(url, {
      amount: amount,
      payment_provider_name: paymentMethod,
      payment_provider_id: paymentProviderId
    })
    .then((data) => onSuccess(data.data.record))
    .catch((err: AxiosError<ApiResponse<Payment | CashPayment>>) => {
      if (err.response) {
        onError(`Payment failed: ${err.response.data.message}`);
      } else {
        onError('Payment failed: Network error');
      }
    });
};

const syncRecentInvoices = (customerId: number, onSuccess: (invoices: Invoice[]) => void) => {
  const url = `/pim/invoices/sync_recent_invoices?customer_id=${customerId}`;
  axios.get<Invoice[]>(url).then((data) => onSuccess(data.data.record));
};

const createInvoice = (
  params: Partial<Invoice>,
  onSuccess: (invoice: Invoice) => void,
  onError?: (message: string) => void
) => {
  const url = `/pim/invoices`;
  axios
    .post<Invoice>(url, { invoice: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};

const updateInvoice = (
  invoiceId: string | number,
  params: Partial<Invoice>,
  { onSuccess, onError }: { onSuccess: (invoice: Invoice) => void; onError?: (message: string) => void }
) => {
  const url = `/pim/invoices/${invoiceId}`;

  axios
    .put<Invoice>(url, { invoice: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};

export {
  fetchInvoice,
  fetchInvoicesForConsult,
  payInvoice,
  syncRecentInvoices,
  fetchInvoices,
  fetchInvoicesForCustomer,
  fetchInvoicesForAppointment,
  fetchInvoicesForAnimal,
  fetchCustomerUnpaidInvoices,
  createInvoice,
  updateInvoice
};
