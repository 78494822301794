import React from 'react';

export function ColorSquare(props: { color: string }) {
  return (
    <div
      style={{
        border: '1px solid black',
        backgroundColor: props.color,
        width: '25px',
        height: '25px'
      }}
    ></div>
  );
}
