import axios from 'axios';

import { Pagination } from 'types/Pagination';

import { Employee } from '../types/Employee';

function fetchEmployees(
  queryText: string,
  showInactive: boolean,
  onSuccess: (employees: Employee[]) => void,
  setPagination: (pagination: Pagination) => void,
  page?: number
) {
  const url = '/pim/employees';
  axios
    .get<Employee[]>(url, {
      params: {
        query: queryText,
        inactive: showInactive,
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
    });
}

const fetchEmployeesByQuery = (query: string, params?: { vets_only: boolean }) => {
  const url = '/pim/employees';

  return axios
    .get<Employee[]>(url, { params: { query: query, ...params } })
    .then((response) => response.data.record)
    .catch((error) => []);
};

const fetchGenericEmployees = (onSuccess: (employees: Employee[]) => void) => {
  const url = '/pim/employees';

  axios
    .get<Employee[]>(url, { params: { query: 'any' } })
    .then((response) => onSuccess(response.data.record))
    .catch((error) => []);
};

function fetchEmployee(employeeId: string | number, onSuccess: (employees: Employee) => void) {
  const url = `/pim/employees/${employeeId}`;
  axios.get<Employee>(url).then((data) => onSuccess(data.data.record));
}

function employeeParam(employee: Employee): Omit<Employee, 'clinics' | 'organization' | 'pim_resources'> {
  const { organization, clinics, pim_resources, ...restOfEmployee } = employee;
  return restOfEmployee;
}

function updateEmployee(employeeId: string, employee: Employee) {
  const url = `/pim/employees/${employeeId}`;
  return axios.put<Employee>(url, employeeParam(employee)).then((response) => response.data.record);
}

function updateEmployeeWithoutRoles(employeeId: string, employee: Employee) {
  const { organization, clinics, pim_resources, roles, ...restOfEmployee } = employee;
  const url = `/pim/employees/${employeeId}`;
  return axios.put<Employee>(url, restOfEmployee).then((response) => response.data.record);
}

function createEmployee(employee: Employee) {
  const url = `/pim/employees`;
  return axios.post<Employee>(url, employeeParam(employee)).then((response) => response.data.record);
}

export const fetchCurrentEmployeeUrl = '/pim/employees/me';
const fetchCurrentEmployee = ({
  onSuccess,
  onError
}: {
  onSuccess: (employee: Employee) => void;
  onError: () => void;
}) => {
  axios
    .get<Employee>(fetchCurrentEmployeeUrl)
    .then((response) => {
      onSuccess(response.data.record);
    })
    .catch(() => {
      onError();
    });
};

export {
  fetchEmployees,
  fetchEmployeesByQuery,
  fetchGenericEmployees,
  fetchEmployee,
  updateEmployee,
  updateEmployeeWithoutRoles,
  createEmployee,
  fetchCurrentEmployee
};
