import axios from 'axios';

import { HealthPlanProduct } from 'types/HealthPlanProduct';

function fetchHealthPlanProduct(productId: number, onSuccess: (healthPlanProduct: HealthPlanProduct) => void) {
  const url = `/pim/health_plan_products/${productId}`;
  axios.get<HealthPlanProduct>(url).then((data) => onSuccess(data.data.record));
}

function createHealthPlanProduct(params: HealthPlanProduct, onSuccess: (healthPlanProduct: HealthPlanProduct) => void) {
  const url = `/pim/health_plan_products`;
  axios.post<HealthPlanProduct>(url, { health_plan_product: params }).then((data) => onSuccess(data.data.record));
}

function updateHealthPlanProduct(
  productId: number,
  params: HealthPlanProduct,
  onSuccess: (healthPlanProduct: HealthPlanProduct) => void
) {
  const url = `/pim/health_plan_products/${productId}`;
  axios.put<HealthPlanProduct>(url, params).then((data) => onSuccess(data.data.record));
}

export { fetchHealthPlanProduct, createHealthPlanProduct, updateHealthPlanProduct };
