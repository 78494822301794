import React, { useState } from 'react';

import { cilInfo } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CCol, CForm, CFormInput, CFormSelect, CLoadingButton, CPopover, CRow } from '@coreui/react-pro';

import { ConsultTemplate, ConsultTemplateStatus, documentTemplateInstructions } from 'types/ConsultTemplate';

import { renderMarkdown } from 'utils/markdown';

import { RichTextEditor } from 'components/RichTextEditor';

type FormProps = {
  handleSubmit: () => void;
  handleCancel: () => void;
  template: Partial<ConsultTemplate>;
  setTemplate: (template: Partial<ConsultTemplate>) => void;
};

const Form = ({ handleSubmit, handleCancel, template, setTemplate }: FormProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    handleSubmit();
  };

  return (
    <CForm onSubmit={submit}>
      <CRow className="mb-3">
        <CCol xs={6}>
          <CFormInput
            type="text"
            id="name"
            label="Template Name"
            value={template.name || ''}
            required
            onChange={(event) => setTemplate({ ...template, name: event.target.value })}
          />
        </CCol>
        <CCol xs={6}>
          <CFormSelect
            id="status"
            label="Status"
            value={template.status}
            options={[
              { label: 'Active', value: 'active' },
              { label: 'Disabled', value: 'disabled' }
            ]}
            onChange={(event) => setTemplate({ ...template, status: event.target.value as ConsultTemplateStatus })}
          />
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol>
          <CFormInput
            type="text"
            label="Description"
            id="name"
            value={template.description || ''}
            required
            onChange={(event) => setTemplate({ ...template, description: event.target.value })}
          />
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol>
          <CRow className="ps-1 mb-2">
            <div>
              Content
              <CPopover
                title="Template Guidelines"
                content={renderMarkdown(documentTemplateInstructions)}
                placement="right"
                trigger="hover"
              >
                <CIcon icon={cilInfo} color="primary" className="ms-2 text-info" />
              </CPopover>
            </div>
          </CRow>
          <RichTextEditor
            id="content"
            label=""
            name="content"
            value={template.content || ''}
            onChange={(event) => setTemplate({ ...template, content: event })}
            required
            text="Required"
            editorType="document"
          />
        </CCol>
      </CRow>

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
        <CButton color="secondary" disabled={isSubmitting} onClick={handleCancel}>
          Cancel
        </CButton>
        <CLoadingButton color="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          {template.id ? 'Update' : 'Create'}
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default Form;
