import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { Customer } from 'types/Customer';

import { formatPhoneNumber } from 'utils/phoneNumbers';

import { ReactComponent as CallIcon } from 'assets/images/call.svg';
import { ReactComponent as EmailIcon } from 'assets/images/sms.svg';

import styles from './OwnerInfoCard.module.scss';

type Props = {
  customer: Customer;
};

export const OwnerInfoCard = ({ customer }: Props) => {
  return (
    <div className={styles.itemContainer} key={customer.id}>
      <div className={styles.row}>
        <Link to={`/customers/${customer.id}`}>
          {customer.first_name} {customer.last_name}
        </Link>
      </div>

      <div className={cn('d-flex gap-2', styles.row)}>
        <EmailIcon className={styles.icon} />
        <div className={styles.blueText}>{customer.email}</div>
      </div>

      <div className={cn('d-flex gap-2', styles.row)}>
        <CallIcon className={styles.icon} />
        <div className={styles.value}>{formatPhoneNumber(customer.phone)}</div>
      </div>
    </div>
  );
};
