import axios, { AxiosError } from 'axios';

import { Appointment } from 'types/Appointment';
import { Pagination } from 'types/Pagination';

import { ApiResponse } from './ResponseTypes';

export type AppointmentFilterParams = {
  animal_id?: string | number;
  status?: string;
  clinic_id?: string | number;
  date_start?: string;
  date_end?: string;
  page?: number;
};

const fetchAppointmentsByAnimal = ({
  onSuccess,
  params,
  setPagination
}: {
  onSuccess: (appointments: Appointment[]) => void;
  params: AppointmentFilterParams;
  setPagination: (pagination: Pagination) => void;
}) => {
  const url = `/pim/animals/${params.animal_id}/appointments`;

  axios.get<Appointment[]>(url, { params: params }).then((data) => {
    setPagination({
      page: params.page || 1,
      perPage: Number(data.headers['per-page']),
      total: Number(data.headers.total)
    });
    onSuccess(data.data.record);
  });
};

const fetchAppointmentsByCustomer = (
  customerId: string,
  {
    onSuccess,
    params,
    setPagination
  }: {
    onSuccess: (appointments: Appointment[]) => void;
    params: AppointmentFilterParams;
    setPagination: (pagination: Pagination) => void;
  }
) => {
  const url = `/pim/customers/${customerId}/appointments`;

  axios.get<Appointment[]>(url, { params: params }).then((data) => {
    setPagination({
      page: params.page || 1,
      perPage: Number(data.headers['per-page']),
      total: Number(data.headers.total)
    });
    onSuccess(data.data.record);
  });
};

const fetchAppointment = (appointmentId: string, onSuccess: (appointment: Appointment) => void) => {
  const url = `/pim/appointments/${appointmentId}`;
  axios.get<Appointment>(url).then((data) => onSuccess(data.data.record));
};

const fetchAppointments = (
  startTime: Date,
  endTime: Date,
  clinicId: number,
  onSuccess: (appointments: Appointment[]) => void
) => {
  const url = '/pim/appointments';
  const params = { start_time: startTime, end_time: endTime, clinic_id: clinicId };
  axios.get<Appointment[]>(url, { params }).then((data) => onSuccess(data.data.record));
};

const cancelAppointment = (
  appointmentId: number,
  cancelReasonId: number | undefined,
  cancelText: string | undefined,
  onSuccess: (appointments: Appointment) => void,
  onError: (message: string) => void
) => {
  const url = `/pim/appointments/${appointmentId}/cancel`;
  const params = { appointment_cancel_reason_id: cancelReasonId, cancel_reason_text: cancelText };
  axios
    .put<Appointment>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Appointment>>) => onError(error.message));
};

const cancelAndChargeAppointment = (
  appointmentId: number,
  cancelReasonId: number | undefined,
  cancelText: string | undefined,
  onSuccess: (appointments: Appointment) => void,
  onError: (message: string) => void
) => {
  const url = `/pim/appointments/${appointmentId}/cancel?cancel_fee=true`;
  const params = { appointment_cancel_reason_id: cancelReasonId, cancel_reason_text: cancelText };
  axios
    .put<Appointment>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Appointment>>) => onError(error.message));
};

const cancelAndRefundAppointment = (
  appointmentId: number,
  cancelReasonId: number | undefined,
  cancelText: string | undefined,
  onSuccess: (appointments: Appointment) => void,
  onError: (message: string) => void
) => {
  const url = `/pim/appointments/${appointmentId}/cancel?force_refund=true`;
  const params = { appointment_cancel_reason_id: cancelReasonId, cancel_reason_text: cancelText };
  axios
    .put<Appointment>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Appointment>>) => onError(error.message));
};

const rescheduleAppointment = (
  appointmentId: number,
  startTime: Date,
  endTime: Date,
  pimResourceId: number,
  onSuccess: (appointment: Appointment) => void,
  onError: (message: string) => void
) => {
  const url = `/pim/appointments/${appointmentId}/reschedule`;
  const params = { start_time: startTime, end_time: endTime, pim_resource_id: pimResourceId };
  axios
    .put<Appointment>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Appointment>>) => onError(error.message));
};

const createAppointment = (
  appointment: object,
  { onSuccess, onError }: { onSuccess: (appointment: Appointment) => void; onError: (message: string) => void }
) => {
  const url = '/pim/appointments';
  axios
    .post<Appointment>(url, { appointment: appointment })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Appointment>>) => onError(error.message));
};

const createNoShowFee = (
  appointmentId: number,
  onSuccess: (appointments: Appointment) => void,
  onError: (message: string) => void
) => {
  const url = `/pim/appointments/${appointmentId}/no_show_fee`;
  axios
    .post<Appointment>(url)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Appointment>>) => onError(error.message));
};

const createCancelFee = (
  appointmentId: number,
  onSuccess: (appointments: Appointment) => void,
  onError: (message: string) => void
) => {
  const url = `/pim/appointments/${appointmentId}/cancel_fee`;
  axios
    .post<Appointment>(url)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Appointment>>) => onError(error.message));
};

const updateAppointment = (
  appointmentId: number,
  params: Partial<Appointment>,
  { onSuccess, onError }: { onSuccess: (appointment: Appointment) => void; onError?: (message: string) => void }
) => {
  const url = `/pim/appointments/${appointmentId}`;
  axios
    .put<Partial<Appointment>, Appointment>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Appointment>>) => onError?.(error.message));
};

const fetchHuddleAppointments = (
  startTime: Date,
  endTime: Date,
  clinicId: number | string,
  onSuccess: (huddles: Appointment[]) => void
) => {
  const url = '/pim/appointments/huddle';
  const params = { start_time: startTime, end_time: endTime, clinic_id: clinicId };
  axios.get<Appointment[]>(url, { params: params }).then((data) => onSuccess(data.data.record));
};

const fetchApprovalQueue = ({
  onSuccess,
  page,
  setPagination
}: {
  onSuccess: (appointments: Appointment[]) => void;
  page?: number;
  setPagination: (pagination: Pagination) => void;
}) => {
  const url = '/pim/appointments/approval_queue';
  axios.get<Appointment[]>(url, { params: { page: page || 1 } }).then((data) => {
    setPagination({ page: page || 1, perPage: Number(data.headers['per-page']), total: Number(data.headers.total) });
    onSuccess(data.data.record);
  });
};

export {
  fetchAppointmentsByAnimal,
  fetchAppointmentsByCustomer,
  fetchAppointment,
  fetchAppointments,
  cancelAppointment,
  cancelAndChargeAppointment,
  cancelAndRefundAppointment,
  rescheduleAppointment,
  createAppointment,
  createCancelFee,
  createNoShowFee,
  updateAppointment,
  fetchHuddleAppointments,
  fetchApprovalQueue
};
