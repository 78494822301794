import axios from 'axios';

import { ProductPrice } from 'types/ProductPrice';

function fetchProductPrices(productId: string, onSuccess: (price: ProductPrice[]) => void) {
  const url = `/pim/product_prices?product_id=${productId}`;
  axios.get<ProductPrice[]>(url).then((data) => onSuccess(data.data.record));
}

function fetchProductPrice(priceId: string, onSuccess: (price: ProductPrice) => void) {
  const url = `/pim/product_prices/${priceId}`;
  axios.get<ProductPrice>(url).then((data) => onSuccess(data.data.record));
}

export type ProductPricePayload = {
  productId: string;
  urgent_care: boolean;
  discount_percentage?: number;
  discount_fixed?: number;
  quantity: number;
  quantity_low: number;
  quantity_high: number;
};

async function fetchCurrentPriceForProduct(payload: ProductPricePayload) {
  const url = `/pim/products/${payload.productId}/current_price_for_product`;
  const response = await axios.get<ProductPrice>(url, {
    params: {
      urgent_care: payload.urgent_care,
      discount_percentage: payload.discount_percentage,
      discount_fixed: payload.discount_fixed,
      quantity: payload.quantity,
      quantity_low: payload.quantity_low,
      quantity_high: payload.quantity_high
    }
  });
  return response.data.record;
}

export { fetchProductPrices, fetchProductPrice, fetchCurrentPriceForProduct };
