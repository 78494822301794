import axios from 'axios';

import { Context } from 'types/Context';

const fetchContextsForConsult = (consultId: number | string, onSuccess: (contexts: Context[]) => void) => {
  const url = `/pim/communication_logs/contexts_for_consult`;

  axios.get<Context[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const fetchContextsForAnimal = (animalId: number | string, onSuccess: (contexts: Context[]) => void) => {
  const url = `/pim/communication_logs/contexts_for_animal`;

  axios.get<Context[]>(url, { params: { animal_id: animalId } }).then((data) => onSuccess(data.data.record));
};

const fetchContextsForCustomer = (customerId: number | string, onSuccess: (contexts: Context[]) => void) => {
  const url = `/pim/communication_logs/contexts_for_customer`;

  axios.get<Context[]>(url, { params: { customer_id: customerId } }).then((data) => onSuccess(data.data.record));
};

export { fetchContextsForAnimal, fetchContextsForConsult, fetchContextsForCustomer };
