import { format, parseISO } from 'date-fns';
import * as React from 'react';
import { ReactNode, useState } from 'react';

import { CDatePicker, CFormInput } from '@coreui/react-pro';

type Props = {
  date: string | null;
  onChange?: (changedDate: string) => void;
};

type CDatePickerProps = {
  cleaner?: boolean;
  disabled?: boolean;
  id?: string;
  label?: string | ReactNode;
  minDate?: Date | string;
  name?: string;
  required?: boolean;
  text?: string;
};

type IntersectingProps = Props & CDatePickerProps;

/** Takes a date string in ISO format ("yyyy-MM-dd"), stores it internally as a Date
 * object, and returns that date object object in ISO format ("yyyy-MM-dd") via the
 * hidden input or onChange callback.
 *
 * Noteworthy params:
 *
 * @param date - The date string in ISO format ("yyyy-MM-dd")
 * @param onChange - An optional function to call when the date changes (asyncronous updates)
 */
export const DatePicker = ({
  date,
  onChange,
  cleaner = true,
  disabled = false,
  id,
  label,
  name,
  required = false,
  minDate,
  text
}: IntersectingProps) => {
  const convertToDate = (date: string | null) => {
    if (!date) return null;
    return parseISO(date);
  };

  const convertToDateString = (date: Date | null) => {
    if (!date) return '';
    return format(date, 'yyyy-MM-dd');
  };

  const [parsedDate, setParsedDate] = useState<Date | null>(convertToDate(date));

  const handleChange = (changedDate: Date | null) => {
    setParsedDate(changedDate);

    if (changedDate && onChange) {
      onChange(convertToDateString(changedDate));
    }
  };

  return (
    <>
      <CFormInput type="hidden" name={id} value={convertToDateString(parsedDate)} />

      <CDatePicker
        cleaner={cleaner}
        date={parsedDate}
        disabled={disabled}
        firstDayOfWeek={0}
        label={label}
        minDate={minDate}
        onDateChange={handleChange}
        required={required}
        text={text}
      />
    </>
  );
};
