import * as React from 'react';

import { CBadge } from '@coreui/react-pro';

import { colorMap } from 'utils/colors';

export const Pill = ({ label }: { label: string }) => {
  return (
    <CBadge className="text-capitalize rounded-pill w-auto" color={colorMap(label)} style={{ height: 'fit-content' }}>
      {label.split('_').join(' ')}
    </CBadge>
  );
};
