import axios, { AxiosError } from 'axios';

import { ApiResponse } from 'api/ResponseTypes';

import { ForwardBooking } from 'types/ForwardBooking';

const fetchForwardBooking = (
  forwardBookingId: string | number,
  onSuccess: (forwardBooking: ForwardBooking) => void
) => {
  const url = `/pim/forward_bookings/${forwardBookingId}`;

  axios.get<ForwardBooking>(url).then((data) => onSuccess(data.data.record));
};

const fetchForwardBookings = (consultId: string | number, onSuccess: (forwardBooking: ForwardBooking[]) => void) => {
  const url = `/pim/forward_bookings`;

  axios.get<ForwardBooking[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const createForwardBooking = (
  forwardBooking: Partial<ForwardBooking>,
  { onSuccess, onError }: { onSuccess: (forwardBooking: ForwardBooking) => void; onError: (error: string) => void }
) => {
  const url = `/pim/forward_bookings`;

  axios
    .post<ForwardBooking>(url, { forward_booking: forwardBooking })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<ForwardBooking>>) => onError(error.message));
};

const updateForwardBooking = (
  id: number,
  forwardBooking: Partial<ForwardBooking>,
  { onSuccess, onError }: { onSuccess: (forwardBooking: ForwardBooking) => void; onError: (error: string) => void }
) => {
  const url = `/pim/forward_bookings/${id}`;

  axios
    .put<ForwardBooking>(url, { forward_booking: forwardBooking })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<ForwardBooking>>) => onError(error.message));
};

export { fetchForwardBooking, fetchForwardBookings, createForwardBooking, updateForwardBooking };
