import Cookies from 'js-cookie';

const pimCurrentClinicKey = 'pim-current-clinic';

export const setCookieCurrentClinic = (id: string) =>
  Cookies.set(pimCurrentClinicKey, id, { expires: 365, secure: true, sameSite: 'strict' });

export const getCookieCurrentClinic = () => Cookies.get(pimCurrentClinicKey);

export const removeCookieCurrentClinic = () => Cookies.remove(pimCurrentClinicKey);

const lastPathKey = (employeeId: number) => `pim-employee-${employeeId}-lastpath`;

export const getLastPath = (employeeId: number) => localStorage.getItem(lastPathKey(employeeId));

export const setLastPath = (employeeId: number) => {
  const currentPath = window.location.pathname;
  const queryParams = window.location.search;
  const fullPath = `${currentPath}${queryParams}`;

  localStorage.setItem(lastPathKey(employeeId), fullPath);
};

export const removeLastPath = (employeeId: number) => localStorage.removeItem(lastPathKey(employeeId));

const pimSidebarPositionKey = (employeeId: number) => `pim-employee-${employeeId}-sidebar-position`;

export const getSidebarPosition = (employeeId: number) => localStorage.getItem(pimSidebarPositionKey(employeeId));

export const setSidebarPosition = (employeeId: number, position: 'expanded' | 'collapsed') => {
  localStorage.setItem(pimSidebarPositionKey(employeeId), position);
};

export const removeSidebarPosition = (employeeId: number) => localStorage.removeItem(pimSidebarPositionKey(employeeId));
