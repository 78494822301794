import * as React from 'react';
import { useState } from 'react';
import Select from 'react-select';

import { CButton, CForm, CFormInput, CFormSelect, CFormTextarea, CLoadingButton } from '@coreui/react-pro';

import { Animal } from 'types/Animal';
import { Context } from 'types/Context';
import { Option } from 'types/Option';
import { topics } from 'types/Topic';

import { reactSelectStyles } from 'utils/reactSelect';
import { contextToOption, OptionWithTopic, toOption } from 'utils/selectOptions';

type Props = {
  animals?: Animal[]; // All animals the user could message about
  subjectAnimal?: Animal; // Animal in context, e.g. this form is loaded from the animal's page and we want to pre-select that animal
  contexts: Context[];
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  hideModal: () => void;
  loading: boolean;
};

export const MessageForm = ({ animals, subjectAnimal, onSubmit, hideModal, loading, contexts }: Props) => {
  const [contextOptions] = useState<OptionWithTopic[]>([
    { label: '', value: '', topic: '' },
    ...contexts.map(contextToOption)
  ]);

  const [selectedContextOption, setSelectedContextOption] = useState<Option | null | undefined>(null);
  const [selectedTopic, setSelectedTopic] = useState('');

  const selectedContext = contexts.find((context) => contextToOption(context).value === selectedContextOption?.value);

  const isTopicDisabled = !!(selectedContextOption && selectedContextOption.value !== '');

  const handleContextChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = contextOptions.find((c) => c.value === event.target.value);
    setSelectedContextOption(selected);

    if (selected?.topic) {
      setSelectedTopic(selected.topic);
    }
  };

  const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTopic(event.target.value);
  };

  const maybeDefaultAnimal = () => {
    let defaultAttrs = {};
    if (animals && animals.length === 1) {
      defaultAttrs = { defaultValue: [toOption(animals[0])], isDisabled: true };
    }

    if (subjectAnimal) {
      defaultAttrs = { defaultValue: [toOption(subjectAnimal)] };
    }

    return defaultAttrs;
  };

  return (
    <CForm className="g-3" onSubmit={onSubmit}>
      {animals && (
        <div className="mb-2">
          {animals.length === 1 && <CFormInput type="hidden" name="animal_ids" value={animals[0].id} />}
          <label className="form-label">Animal</label>
          <Select
            name="animal_ids"
            isMulti
            options={animals.map(toOption)}
            styles={reactSelectStyles}
            {...maybeDefaultAnimal()}
            delimiter=","
            required
          />
        </div>
      )}

      <div className="mb-2">
        <CFormInput type="hidden" name="topic" value={selectedTopic} />
        <CFormSelect
          id="topic"
          disabled={isTopicDisabled}
          label="Topic"
          onChange={handleTopicChange}
          options={['', ...topics]}
          text="Required"
          value={selectedTopic}
          required
        />
      </div>

      <div className="mb-2">
        <CFormInput type="hidden" name="context_id" value={selectedContext?.context_id ?? ''} />
        <CFormInput type="hidden" name="context_type" value={selectedContext?.context_type ?? ''} />
        <CFormSelect id="context" label="Context" value={selectedContextOption?.value} onChange={handleContextChange}>
          {contextOptions.map((option: Option) => (
            <option key={`${option.value}-${option.label}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </CFormSelect>
      </div>

      <div className="mb-2">
        <CFormTextarea id="body" label="Body" name="body" rows={3} text="Required" required />
      </div>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <CLoadingButton loading={loading} color="primary" shape="rounded-pill" type="submit">
          Send
        </CLoadingButton>
        <CButton variant="outline" type="button" shape="rounded-pill" onClick={hideModal}>
          Cancel
        </CButton>
      </div>
    </CForm>
  );
};
