import axios from 'axios';

import { AppointmentType } from '../types/AppointmentType';
import { PimResource } from '../types/PimResource';

const fetchAvailableAppointmentTypes = (onSuccess: (appointmentTypes: AppointmentType[]) => void) => {
  const url = '/pim/availability/appointment_types';
  axios.get<AppointmentType[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchAvailablePimResources = (
  animalId: number,
  appointmentTypeId: number | string,
  clinicId: number,
  onSuccess: (resources: PimResource[]) => void
) => {
  const url = '/pim/availability/pim_resources';
  const params = { animal_id: animalId, appointment_type_id: appointmentTypeId, clinic_id: clinicId };
  axios.get<PimResource[]>(url, { params }).then((data) => onSuccess(data.data.record));
};

const fetchAvailableDays = (
  appointmentTypeId: number | string,
  pimResourceId: number | undefined,
  clinicId: number,
  startAt: Date,
  endAt: Date,
  onSuccess: (availableDays: string[]) => void
) => {
  const url = '/pim/availability/available_days';
  const params = {
    appointment_type_id: appointmentTypeId,
    pim_resource_id: pimResourceId,
    clinic_id: clinicId,
    start_at: startAt,
    end_at: endAt
  };
  axios.get<string[]>(url, { params }).then((data) => onSuccess(data.data.record));
};

export type AvailableTime = {
  start_at: string;
  end_at: string;
  pim_resource_id: number;
};
const fetchAvailableTimes = (
  appointmentTypeId: number | string,
  pimResourceId: number | undefined,
  clinicId: number,
  startAt: Date,
  onSuccess: (availableTimes: AvailableTime[]) => void
) => {
  const url = '/pim/availability/available_times';
  const params = {
    appointment_type_id: appointmentTypeId,
    pim_resource_id: pimResourceId,
    clinic_id: clinicId,
    start_at: startAt
  };
  axios.get<AvailableTime[]>(url, { params }).then((data) => onSuccess(data.data.record));
};

export { fetchAvailableAppointmentTypes, fetchAvailablePimResources, fetchAvailableDays, fetchAvailableTimes };
