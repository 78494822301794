import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { cilNotes } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CAlert,
  CCol,
  CListGroup,
  CListGroupItem,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchPrescriptionItem } from '../../api/PrescriptionItems';
import { PrescriptionItem } from '../../types/PrescriptionItem';
import { compactDateTimeDisplay } from '../../utils/dates';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [prescriptionItem, setPrescriptionItem] = React.useState<PrescriptionItem>();

  useEffect(() => {
    fetchPrescriptionItem(id, setPrescriptionItem);
  }, [id]);

  return (
    <>
      <h4 className="mb-3">
        <CIcon className="me-2 icon-xl" icon={cilNotes} aria-label="prescription note icon" />
        Prescription Details
      </h4>
      {prescriptionItem ? (
        <>
          <CTable className="mb-5">
            <CTableHead color="secondary">
              <CTableRow>
                <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                <CTableHeaderCell scope="col">Quantity</CTableHeaderCell>
                <CTableHeaderCell scope="col">Remaining</CTableHeaderCell>
                <CTableHeaderCell scope="col">Patient</CTableHeaderCell>
                <CTableHeaderCell scope="col">Prescribed by</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow>
                <CTableDataCell>{prescriptionItem.product?.name}</CTableDataCell>
                <CTableDataCell>{prescriptionItem.quantity}</CTableDataCell>
                <CTableDataCell>{prescriptionItem.remaining}</CTableDataCell>
                <CTableDataCell>
                  <Link to={`/animals/${prescriptionItem.prescription?.animal.id}`}>
                    {prescriptionItem.prescription?.animal.name}
                  </Link>
                </CTableDataCell>
                <CTableDataCell>
                  {prescriptionItem.prescription?.employee?.title} {prescriptionItem.prescription?.employee?.first_name}{' '}
                  {prescriptionItem.prescription?.employee?.last_name}
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>

          <CRow className="mb-5">
            <CCol sm={6}>
              <h5 className="mb-3">Administration Info</h5>
              <CListGroup>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Date Prescribed</CCol>
                    <CCol>
                      {prescriptionItem.prescription?.date_of_prescription &&
                        compactDateTimeDisplay(prescriptionItem.prescription.date_of_prescription)}
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Date Start</CCol>
                    <CCol>{prescriptionItem.date_start && compactDateTimeDisplay(prescriptionItem.date_start)}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Is Active</CCol>
                    <CCol>{prescriptionItem.active ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Instructions</CCol>
                    <CCol>{prescriptionItem.clean_instructions}</CCol>
                  </CRow>
                </CListGroupItem>
              </CListGroup>
            </CCol>

            <CCol sm={6}>
              <h5 className="mb-3">Refill Info</h5>
              <CListGroup>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Can Refill</CCol>
                    <CCol>{prescriptionItem.can_refill ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Refill Pending</CCol>
                    <CCol>{prescriptionItem.refill_pending ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Last Refill Requested At</CCol>
                    <CCol>
                      {prescriptionItem.last_refill_requested_at &&
                        compactDateTimeDisplay(prescriptionItem.last_refill_requested_at)}
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Request Refill Link</CCol>
                    <CCol>
                      {prescriptionItem.refill_link ? (
                        <Link to={prescriptionItem.refill_link}>Click to refill</Link>
                      ) : null}
                    </CCol>
                  </CRow>
                </CListGroupItem>
              </CListGroup>
            </CCol>
          </CRow>

          <CRow className="mb-5">
            <CCol sm={6}>
              <h5 className="mb-3">Product Info</h5>
              <CListGroup>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Description</CCol>
                    <CCol>
                      <Link to={`/products/${prescriptionItem.product?.id}`}>{prescriptionItem.product?.name}</Link>
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Product Type</CCol>
                    <CCol>{prescriptionItem.product?.product_type}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Product Type</CCol>
                    <CCol>{prescriptionItem.product?.product_type}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Description</CCol>
                    <CCol>{prescriptionItem.product?.description}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>PIM ID</CCol>
                    <CCol>{prescriptionItem.product?.pim_id}</CCol>
                  </CRow>
                </CListGroupItem>
              </CListGroup>
            </CCol>

            <CCol sm={6}>
              <h5 className="mb-3">PIM Info</h5>
              <CListGroup>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>PIM ID</CCol>
                    <CCol>{prescriptionItem.pim_id}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>PIM Name</CCol>
                    <CCol>{prescriptionItem.pim_name}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Created At</CCol>
                    <CCol>{prescriptionItem.created_at && compactDateTimeDisplay(prescriptionItem.created_at)}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Updated At</CCol>
                    <CCol>{prescriptionItem.updated_at && compactDateTimeDisplay(prescriptionItem.updated_at)}</CCol>
                  </CRow>
                </CListGroupItem>
              </CListGroup>
            </CCol>
          </CRow>
        </>
      ) : (
        <CAlert color="secondary">Prescription not found</CAlert>
      )}
    </>
  );
};
export default Details;
