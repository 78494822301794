import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CButton, CForm, CFormInput, CFormTextarea, CLoadingButton } from '@coreui/react-pro';

import { fetchHealthPlansByQuery } from 'api/HealthPlans';

import { AnimalHealthPlan } from 'types/AnimalHealthPlan';
import { Option } from 'types/Option';

import { toOption } from 'utils/selectOptions';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as PawCrossIcon } from 'assets/images/paw-cross.svg';

import { DatePicker } from 'components/DatePicker';
import { RichTextEditor } from 'components/RichTextEditor';

import styles from './AnimalHealthPlanForm.module.scss';

type Props = {
  loading: boolean;
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  animalHealthPlan?: AnimalHealthPlan;
};

const MIN_QUERY_LENGTH = 3;

export const AnimalHealthPlanForm = ({ loading, hideModal, onSubmit, animalHealthPlan }: Props) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    animalHealthPlan ? toOption(animalHealthPlan.health_plan) : null
  );

  const [patientNotes, setPatientNotes] = useState(animalHealthPlan?.patient_notes ?? '');
  const [auditRequired, setAuditRequired] = useState(false);

  const handleSelectChange = (selectedOption: Option | null) => {
    setSelectedOption(selectedOption);
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const handleNextDueDateChange = (newDate: string) => {
    if (!animalHealthPlan) return;

    let required = false;
    if (newDate !== animalHealthPlan?.next_due_date) {
      required = true;
    }

    setAuditRequired(required);
  };

  return (
    <CForm className="row g-3" onSubmit={onSubmit}>
      <h2 className="mb-0">{animalHealthPlan ? 'Edit' : 'New'} Health Plan</h2>

      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="form-label d-flex align-items-center">
            <CFormInput hidden id="health_plan_id" name="health_plan_id" value={selectedOption?.value ?? ''} />
            <PawCrossIcon className={styles.icon} />
            <label htmlFor="health_plan">Health Plan</label>
          </div>
          <div className={styles.required}>Required</div>
        </div>

        <AsyncSelect<Option>
          id="health_plan"
          aria-label="Health Plan"
          value={selectedOption}
          onChange={handleSelectChange}
          placeholder="Type to search..."
          loadingMessage={loadingMessage}
          loadOptions={(inputValue, callback) => {
            if (inputValue.length < MIN_QUERY_LENGTH) return;

            fetchHealthPlansByQuery(inputValue).then((options) => {
              callback(options.map(toOption));
            });
          }}
          isClearable
          required
          isSearchable
          styles={{
            control: (base, state) => ({
              ...base,
              borderRadius: '16px',
              borderColor: '#ececec'
            })
          }}
        />
      </div>

      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="form-label d-flex align-items-center">
            <CalendarIcon className={cn(styles.icon, styles.iconBlue)} />
            <label htmlFor="next_due_date">Next Due Date</label>
          </div>
          <div className={styles.required}>Required</div>
        </div>

        <DatePicker
          cleaner={true}
          date={animalHealthPlan?.next_due_date ?? null}
          id="next_due_date"
          onChange={handleNextDueDateChange}
          required={true}
        />
      </div>

      {auditRequired && (
        <div className="mb-3">
          <CFormTextarea
            name="audit_comment"
            id="audit_comment"
            label="Reason for Change"
            text="Required"
            placeholder="Please explain next due date change..."
            required
          />
        </div>
      )}

      <div className="mb-3">
        <RichTextEditor
          id="patient_notes"
          label="Client Communications"
          value={patientNotes}
          name="patient_notes"
          onChange={setPatientNotes}
        />
      </div>

      <div className={cn('d-flex justify-content-end', styles.buttons)}>
        <CLoadingButton loading={loading} color="primary" type="submit" shape="rounded-pill" className={styles.button}>
          {animalHealthPlan ? 'Update' : 'Create'}
        </CLoadingButton>
        <CButton shape="rounded-pill" variant="outline" type="button" onClick={hideModal} className={styles.button}>
          Cancel
        </CButton>
      </div>
    </CForm>
  );
};
