import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { paths } from 'routes';

import { CLoadingButton, CRow, CSmartTable } from '@coreui/react-pro';

import { Customer } from 'types/Customer';
import { Invoice } from 'types/Invoice';
import { calculatePages, Pagination } from 'types/Pagination';

import { compactDateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

import { ActionsMenu } from 'components/ActionsMenu';
import { Pill } from 'components/Pill';

interface Props {
  invoices?: Invoice[];
  customer?: Customer;
  handleResyncInvoices?: () => void;
  isLoading?: boolean;
  pagination: Pagination;
  updatePage: (page: number) => void;
}

export const InvoicesList: React.FC<Props> = ({
  invoices,
  customer,
  handleResyncInvoices,
  isLoading,
  pagination,
  updatePage
}) => {
  return (
    <CRow className="mb-3">
      {handleResyncInvoices && (
        <div className="mb-3 d-flex align-items-center justify-content-end">
          <CLoadingButton
            className="mb-2"
            color="primary"
            disabledOnLoading={true}
            shape="rounded-pill"
            loading={isLoading}
            disabled={isLoading}
            onClick={handleResyncInvoices}
          >
            Resync
          </CLoadingButton>
        </div>
      )}

      <CSmartTable
        pagination={{
          external: true
        }}
        paginationProps={{
          activePage: pagination.page,
          pages: calculatePages(pagination),
          align: 'center'
        }}
        itemsPerPage={pagination.perPage}
        itemsNumber={pagination.total}
        onActivePageChange={updatePage}
        tableHeadProps={{ color: 'dark' }}
        items={invoices ?? []}
        tableProps={{ 'aria-label': 'Invoices' }}
        columns={[
          { key: 'id', label: 'ID' },
          { key: 'created_at', label: 'Invoice Date' },
          { key: 'patient_name', label: 'Patient' },
          'status',
          'total',
          { key: 'total_paid', label: 'Amount Paid' },
          { key: 'unpaid_total', label: 'Amount Due' },
          'actions'
        ]}
        scopedColumns={{
          id: (invoice: Invoice) => <td>#{invoice.id}</td>,
          created_at: (invoice: Invoice) => <td>{compactDateTimeDisplay(invoice.created_at)}</td>,
          patient_name: (invoice: Invoice) => (
            <td>
              <Link to={generatePath(paths.animalDetails, { id: invoice.animal_id })}>{invoice.animal_name}</Link>
            </td>
          ),
          status: (invoice: Invoice) => (
            <td>
              <Pill label={invoice.status} />
            </td>
          ),
          total: (invoice: Invoice) => <td>{toCurrency(invoice.total)}</td>,
          total_paid: (invoice: Invoice) => <td>{toCurrency(invoice.total_paid)}</td>,
          unpaid_total: (invoice: Invoice) => <td>{toCurrency(invoice.unpaid_total)}</td>,
          actions: (invoice: Invoice) => (
            <td>
              <ActionsMenu
                items={[
                  {
                    label: 'View',
                    href: generatePath(paths.invoiceDetails, { id: invoice.id })
                  }
                ]}
              />
            </td>
          )
        }}
      />
    </CRow>
  );
};
