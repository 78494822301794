import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDrag = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5 8" fill="none" {...props}>
    <circle cx={1} cy={1} r={1} fill="currentColor" />
    <circle cx={1} cy={4} r={1} fill="currentColor" />
    <circle cx={1} cy={7} r={1} fill="currentColor" />
    <circle cx={4} cy={1} r={1} fill="currentColor" />
    <circle cx={4} cy={4} r={1} fill="currentColor" />
    <circle cx={4} cy={7} r={1} fill="currentColor" />
  </svg>
);
export default SvgDrag;
