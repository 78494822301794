import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { fetchConsultTemplates } from 'api/ConsultTemplates';

import { ConsultTemplateType } from 'types/ConsultTemplate';
import { Option } from 'types/Option';

import { reactSelectStyles } from 'utils/reactSelect';
import { toOption } from 'utils/selectOptions';

import SvgClipboard from 'assets/images/SvgClipboard';

import { IconLabel } from 'components/IconLabel';

type Props = {
  templateChanged: (templateId: string) => void;
  templateType: ConsultTemplateType;
};

export const TemplateSelector = ({ templateChanged, templateType }: Props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<Option | null>();

  const loadConsultTemplates = (inputValue: string, callback: (options: Option[]) => void) => {
    fetchConsultTemplates({
      queryText: inputValue,
      templateType,
      onSuccess: (templates) => {
        callback(templates.map(toOption));
      }
    });
  };

  const handleTemplateChange = (selectedOption: Option | null) => {
    setSelectedTemplate(selectedOption);
    if (selectedOption) templateChanged(selectedOption.value);
  };

  return (
    <>
      <label htmlFor="template" className="form-label">
        <IconLabel icon={SvgClipboard} label="Use Template" />
      </label>
      <AsyncSelect<Option>
        id="template"
        name="template"
        aria-label="Use Template"
        placeholder="Type to search..."
        value={selectedTemplate}
        onChange={handleTemplateChange}
        loadingMessage={() => 'Loading...'}
        loadOptions={loadConsultTemplates}
        styles={reactSelectStyles}
        defaultOptions
        isSearchable
      />
    </>
  );
};
