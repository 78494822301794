import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import {
  CAlert,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner
} from '@coreui/react-pro';

import { AppointmentType } from 'types/AppointmentType';

type FormProps = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, errorCallback: (error: string) => void) => void;
  handleCancel: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  appointmentType: AppointmentType;
  setColor: (color: string) => void;
};

const Form = ({ handleSubmit, handleCancel, appointmentType, handleChange, setColor }: FormProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>();

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setApiError(undefined);
    setIsSubmitting(true);
    handleSubmit(event, errorCallback);
  };

  function errorCallback(error: string) {
    setApiError(error);
    setIsSubmitting(false);
  }

  return (
    <CForm onSubmit={submit}>
      {apiError && (
        <CAlert color="danger" dismissible>
          Error editing appointment type: {apiError}
        </CAlert>
      )}
      <CRow className="mb-3">
        <CFormLabel htmlFor="name_in_pim" className="col-sm-4 col-form-label">
          Name in PIM
        </CFormLabel>
        <CCol sm={8}>
          <CFormInput type="text" id="name_in_pim" value={appointmentType.name_in_pim || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="pim_id" className="col-sm-4 col-form-label">
          PIM ID
        </CFormLabel>
        <CCol sm={8}>
          <CFormInput type="text" id="pim_id" value={appointmentType.pim_id || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="pim_name" className="col-sm-4 col-form-label">
          PIM Name
        </CFormLabel>
        <CCol sm={8}>
          <CFormInput type="text" id="pim_name" value={appointmentType.pim_name || ''} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="deposit_amount" className="col-sm-4 col-form-label">
          Deposit Amount
        </CFormLabel>
        <CCol sm={8}>
          <CInputGroup>
            <CInputGroupText>$</CInputGroupText>
            <CFormInput
              type="number"
              id="deposit_amount"
              value={appointmentType.deposit_amount}
              onChange={handleChange}
            />
          </CInputGroup>
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="duration" className="col-sm-4 col-form-label">
          Duration (minutes)
        </CFormLabel>
        <CCol sm={8}>
          <CFormInput type="number" id="duration" value={appointmentType.duration} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="max_appointments_per_day" className="col-sm-4 col-form-label">
          Max Appointments/Day Per Vet
        </CFormLabel>
        <CCol sm={8}>
          <CFormInput
            type="number"
            id="max_appointments_per_day"
            min={0}
            value={appointmentType.max_appointments_per_day}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CFormLabel htmlFor="minimum_lead_time_in_hours" className="col-sm-4 col-form-label">
          Minimum Lead Time (Hours)
        </CFormLabel>
        <CCol sm={8}>
          <CFormInput
            type="number"
            id="minimum_lead_time_in_hours"
            min={0}
            value={appointmentType.minimum_lead_time_in_hours}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="cancelable" className="col-sm-4 col-form-label">
          Is Cancelable
        </CFormLabel>
        <CCol sm={8}>
          <CFormSwitch
            id="cancelable"
            aria-label="Cancelable"
            checked={appointmentType.cancelable}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="reschedulable" className="col-sm-4 col-form-label">
          Is Reschedulable
        </CFormLabel>
        <CCol sm={8}>
          <CFormSwitch
            id="reschedulable"
            aria-label="Reschedulable"
            checked={appointmentType.reschedulable}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="dropoff" className="col-sm-4 col-form-label">
          Is Dropoff
        </CFormLabel>
        <CCol sm={8}>
          <CFormSwitch id="dropoff" aria-label="Dropoff" checked={appointmentType.dropoff} onChange={handleChange} />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="tech_appointment" className="col-sm-4 col-form-label">
          Is Tech Appointment
        </CFormLabel>
        <CCol sm={8}>
          <CFormSwitch
            id="tech_appointment"
            aria-label="Tech Appointment"
            checked={appointmentType.tech_appointment}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="client_schedulable" className="col-sm-4 col-form-label">
          Client Schedulable
        </CFormLabel>
        <CCol sm={8}>
          <CFormSwitch
            id="client_schedulable"
            aria-label="Client Schedulable"
            checked={appointmentType.client_schedulable}
            onChange={handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3 align-items-center">
        <CFormLabel htmlFor="colorPicker" className="col-sm-4 col-form-label">
          Color
        </CFormLabel>
        <CCol sm={8}>
          <HexColorPicker id="colorPicker" aria-label="Color" color={appointmentType.color} onChange={setColor} />
        </CCol>
      </CRow>

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
        <CButton color="secondary" disabled={isSubmitting} onClick={handleCancel}>
          Cancel
        </CButton>
        <CButton color="primary" type="submit" disabled={isSubmitting} data-testid={'submit'}>
          {isSubmitting && <CSpinner size="sm" />} Save
        </CButton>
      </div>
    </CForm>
  );
};

export default Form;
