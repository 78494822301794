import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CRow, CSmartTable } from '@coreui/react-pro';

import { fetchAppointmentStatuses } from 'api/AppointmentStatuses';
import { fetchClinics } from 'api/Clinics';

import { Appointment } from 'types/Appointment';
import { ListFilter } from 'types/ListFilter';
import { Option } from 'types/Option';
import { calculatePages, Pagination } from 'types/Pagination';

import { compactDateTimeDisplay } from 'utils/dates';
import { toOption } from 'utils/selectOptions';

import SvgPlus from 'assets/images/SvgPlus';

import Filters from 'components/Filters';
import { IconButton } from 'components/IconButton';

type Props = {
  appointments?: Appointment[];
  handleCreateNewAppointment: () => void;
  pagination: Pagination;
  filters: ListFilter[];
  handleActivePageChange: (page: number) => void;
  handleFilterChange: (filters: ListFilter[]) => void;
};

export const AppointmentsList = ({
  appointments,
  handleCreateNewAppointment,
  handleActivePageChange,
  pagination,
  filters,
  handleFilterChange
}: Props) => {
  const columns = [
    {
      key: 'start_time',
      label: 'Start Time',
      _props: { scope: 'col' }
    },
    {
      key: 'pim_type',
      label: 'Appointment Type',
      _props: { scope: 'col' }
    },
    {
      key: 'clinic_name',
      label: 'Clinic',
      _props: { scope: 'col' }
    },
    {
      key: 'employee',
      label: 'Doctor/Nurse',
      _props: { scope: 'col' }
    },
    {
      key: 'status',
      label: 'Status',
      _props: { scope: 'col' }
    },
    {
      key: 'canceled_at',
      label: 'Cancelled At',
      _props: { scope: 'col' }
    },
    {
      key: 'plan_name',
      label: 'Plan',
      _props: { scope: 'col' }
    }
  ];

  const items =
    appointments?.map((appointment: Appointment) => {
      return {
        ...appointment,
        clinic_name: appointment.clinic.name
      };
    }) ?? [];

  const [clinicOptions, setClinicOptions] = useState<Option[]>([]);
  const [statusOptions, setStatusOptions] = useState<string[]>([]);
  useEffect(() => {
    fetchClinics((clinics) => {
      setClinicOptions([{ label: 'All', value: '' } as Option].concat(clinics.map(toOption)));
    });

    fetchAppointmentStatuses((statuses) => {
      setStatusOptions([''].concat(statuses.map((status) => status.name)));
    });
  }, []);

  useEffect(() => {
    if (clinicOptions.length > 0 && statusOptions.length > 0 && filters.length === 0) {
      handleFilterChange([
        { name: 'Start Date', key: 'start_date', value: undefined, type: 'date' },
        { name: 'End Date', key: 'end_date', value: undefined, type: 'date' },
        { name: 'Status', key: 'status', value: undefined, type: 'select', options: statusOptions },
        { name: 'Clinic', key: 'clinic_id', value: undefined, type: 'select', options: clinicOptions }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicOptions, statusOptions, filters]);

  return (
    <CRow className="mb-3">
      <div className="mb-3 d-flex align-items-center justify-content-end">
        <Filters setFilters={handleFilterChange} filters={filters} useRange />
        <IconButton className="ms-2" onClick={handleCreateNewAppointment} label="New Appointment" icon={SvgPlus} />
      </div>
      <CSmartTable
        pagination={{ external: true }}
        paginationProps={{
          align: 'center',
          activePage: pagination.page,
          pages: calculatePages(pagination)
        }}
        onActivePageChange={handleActivePageChange}
        itemsPerPage={pagination.perPage}
        tableHeadProps={{ color: 'dark' }}
        items={items}
        tableProps={{
          'aria-label': 'Appointments List'
        }}
        columns={columns}
        scopedColumns={{
          start_time: (appointment: Appointment) => {
            return (
              <td className="py-2">
                <Link to={`/appointments/${appointment.id}`}>{compactDateTimeDisplay(appointment.start_time)}</Link>
              </td>
            );
          },
          pim_type: (appointment: Appointment) => <td className="py-2">{appointment.pim_type}</td>,
          clinic_name: (appointment: Appointment) => <td className="py-2">{appointment.clinic.name}</td>,
          employee: (appointment: Appointment) => <td>{appointment.employee.full_name_with_title}</td>,
          status: (appointment: Appointment) => <td className="py-2">{appointment.status}</td>,
          canceled_at: (appointment: Appointment) => (
            <td className="py-2">{appointment.canceled_at && compactDateTimeDisplay(appointment.canceled_at)}</td>
          ),
          plan_name: (appointment: Appointment) => <td className="py-2">{appointment.plan?.name}</td>
        }}
      />
    </CRow>
  );
};
