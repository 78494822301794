import cn from 'classnames';
import * as React from 'react';

import { CContainer } from '@coreui/react-pro';

import { Appointment } from 'types/Appointment';

import { compactDateTimeDisplay } from 'utils/dates';

import SvgPencil from 'assets/images/SvgPencil';

import styles from './VisitDetailsCard.module.scss';

import { HealthPlanItem } from './HealthPlanItem';

type Props = {
  appointment: Appointment;
};

export const VisitDetailsCard: React.FC<Props> = ({ appointment }) => {
  return (
    <div>
      <h2 className={styles.header}>Visit Details</h2>
      <CContainer className={styles.container}>
        <div className="w-50">
          <div>
            <div className={styles.infoRow}>
              <div>Visit Reason</div>
              <div>{appointment.visit_reason_list.join(', ')}</div>
            </div>
            <div className={styles.infoRow}>
              <div>Confirmed At</div>
              <div>{appointment.confirmed_at && compactDateTimeDisplay(appointment.confirmed_at)}</div>
            </div>
            <div className={styles.infoRow}>
              <div>Cancelled Time</div>
              <div>{appointment.canceled_at && compactDateTimeDisplay(appointment.canceled_at)}</div>
            </div>
            <div className={styles.infoRow}>
              <div>Is Dropoff</div>
              <div>{appointment.is_dropoff ? 'Yes' : 'No'}</div>
            </div>
            <div className={styles.infoRow}>
              <div>Client Can Cancel</div>
              <div>
                <div>{appointment.can_cancel ? 'Yes' : 'No'}</div>
              </div>
            </div>
            <div className={styles.infoRow}>
              <div>Client Can Reschedule</div>
              <div>
                <div>{appointment.can_reschedule ? 'Yes' : 'No'}</div>
              </div>
            </div>
            <div className={styles.infoRow}>
              <div>Created At</div>
              <div>{compactDateTimeDisplay(appointment.created_at)}</div>
            </div>
            <div className={styles.infoRow}>
              <div>Updated At</div>
              <div>{compactDateTimeDisplay(appointment.updated_at)}</div>
            </div>
          </div>

          <div className={styles.notes}>
            <div className={styles.notesHeader}>
              <SvgPencil height={16} className={cn('primaryColor', styles.icon)} />
              Notes
            </div>
            <div>{appointment.notes || 'None entered'}</div>
          </div>
        </div>

        <div className={styles.healthPlansSection}>
          <h3 className="m-0">Visit Health Plans</h3>
          {appointment.animal_health_plans.map((healthPlan) => (
            <HealthPlanItem key={healthPlan.id} healthPlan={healthPlan} />
          ))}
          {!appointment.animal_health_plans.length && <div>No health plans for this visit</div>}
        </div>
      </CContainer>
    </div>
  );
};
