import React, { useEffect, useState } from 'react';

import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCard,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import {
  compileConsultTemplate,
  ConsultTemplate,
  consultTemplateForm,
  consultTemplateInstructions,
  ConsultTemplateStatus,
  ConsultTemplateType,
  FormLine
} from 'types/ConsultTemplate';

import { renderMarkdown } from 'utils/markdown';

import SvgPlus from 'assets/images/SvgPlus';

import { ConsultTemplateForm } from 'components/ConsultTemplateForm';
import { IconButton } from 'components/IconButton';

type FormProps = {
  handleSubmit: () => void;
  handleCancel: () => void;
  template: Partial<ConsultTemplate>;
  setTemplate: (template: Partial<ConsultTemplate>) => void;
};

const Form = ({ handleSubmit, handleCancel, template, setTemplate }: FormProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [previewForm, setPreviewForm] = useState<FormLine[]>();
  const [compiledPreviewForm, setCompiledPreviewForm] = useState<string>();
  const [labelKey, setLabelKey] = useState<number>(1);

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    handleSubmit();
  };

  useEffect(() => {
    if (template) {
      const form = consultTemplateForm(template);
      setPreviewForm(form);
      setCompiledPreviewForm(compileConsultTemplate(form));
    }
  }, [template]);

  const handleAddInputClick =
    ({ type, withDefault = false }: { withDefault?: boolean; type: 'text' | 'textarea' | 'select' }) =>
    () => {
      const fieldMap = {
        text: `Label ${labelKey} #INPUT#`,
        textarea: `Label ${labelKey} #TEXTAREA#`,
        select: `Label ${labelKey} #INPUT#[Choice A/Default]`
      };

      const appendedContent = [template?.content, fieldMap[type], withDefault && "'Default'", '\n']
        .filter(Boolean)
        .join('');

      setTemplate({ ...template, content: appendedContent });
      setLabelKey(labelKey + 1);
    };

  const handlePreviewFormUpdate = (form: FormLine[]) => {
    setPreviewForm(form);
    setCompiledPreviewForm(compileConsultTemplate(form));
  };

  return (
    <CForm onSubmit={submit}>
      <CRow className="mb-3">
        <CCol xs={6}>
          <CFormInput
            type="text"
            id="name"
            label="Template Name"
            value={template.name || ''}
            required
            onChange={(event) => setTemplate({ ...template, name: event.target.value })}
          />
        </CCol>
        <CCol xs={3}>
          <CFormSelect
            id="status"
            label="Status"
            value={template.status}
            options={[
              { label: 'Active', value: 'active' },
              { label: 'Disabled', value: 'disabled' }
            ]}
            onChange={(event) => setTemplate({ ...template, status: event.target.value as ConsultTemplateStatus })}
          />
        </CCol>
        <CCol xs={3}>
          <CFormSelect
            id="type"
            label="Type"
            value={template.type}
            options={[
              { label: 'Assessment', value: 'AssessmentTemplate' },
              { label: 'History', value: 'HistoryTemplate' },
              { label: 'Physical Exam', value: 'PhysicalExamTemplate' },
              { label: 'Procedure', value: 'ProcedureTemplate' },
              { label: 'Consult Plan', value: 'ConsultPlanPlanTemplate' },
              { label: 'Customer Discussion', value: 'ConsultPlanCustomerDiscussionTemplate' }
            ]}
            onChange={(event) => setTemplate({ ...template, type: event.target.value as ConsultTemplateType })}
          />
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol>
          <CFormInput
            type="text"
            label="Description"
            id="description"
            value={template.description || ''}
            required
            onChange={(event) => setTemplate({ ...template, description: event.target.value })}
          />
        </CCol>
      </CRow>

      <CCol className="mb-3">
        <CFormTextarea
          rows={10}
          id="content"
          name="content"
          label="Content"
          value={template.content || ''}
          onChange={(event) => setTemplate({ ...template, content: event.target.value })}
          required
        />
      </CCol>

      <CCol className="mb-2 d-flex align-items-center justify-content-end gap-2 flex-wrap">
        <IconButton icon={SvgPlus} onClick={handleAddInputClick({ type: 'text' })} label="Text" />
        <IconButton
          icon={SvgPlus}
          onClick={handleAddInputClick({ type: 'text', withDefault: true })}
          label="Text With Default"
        />
        <IconButton icon={SvgPlus} onClick={handleAddInputClick({ type: 'textarea' })} label="Large Text" />
        <IconButton
          icon={SvgPlus}
          onClick={handleAddInputClick({ type: 'textarea', withDefault: true })}
          label="Large Text With Default"
        />
        <IconButton icon={SvgPlus} onClick={handleAddInputClick({ type: 'select' })} label="Radio" />
        <IconButton
          icon={SvgPlus}
          onClick={handleAddInputClick({ type: 'select', withDefault: true })}
          label="Radio With Default"
        />
      </CCol>

      <CCol>
        <CAccordion>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>Content Guidelines</CAccordionHeader>
            <CAccordionBody>{renderMarkdown(consultTemplateInstructions)}</CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CCol>

      <CRow className="mb-3">
        <CCol>
          <CRow className="ps-1">
            <div>Previews</div>
          </CRow>
          <CRow className="ps-3 d-flex flex-wrap">
            <CCol className="ps-0">
              <CCard className="my-3 p-3">
                <div className="mb-2 fw-bold">Form Preview</div>
                {previewForm && <ConsultTemplateForm form={previewForm} updateForm={handlePreviewFormUpdate} />}
              </CCard>
            </CCol>
            <CCol>
              <CCard className="my-3 p-3">
                <div className="mb-2 fw-bold">Output Preview</div>
                {compiledPreviewForm && <div dangerouslySetInnerHTML={{ __html: compiledPreviewForm }}></div>}
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
        <CButton color="secondary" disabled={isSubmitting} onClick={handleCancel}>
          Cancel
        </CButton>
        <CLoadingButton color="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          {template.id ? 'Update' : 'Create'}
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default Form;
