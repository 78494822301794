import * as React from 'react';
import { useState } from 'react';

import { CCol, CFormInput, CRow } from '@coreui/react-pro';

import { DispenseLog } from 'types/DispenseLog';

import { DatePicker } from 'components/DatePicker';

type Props = {
  dispenseLog?: DispenseLog;
  index: number;
  requiresExpirationDate: boolean;
  requiresLotNumber: boolean;
};

export const DispenseLogForm = ({ requiresLotNumber, requiresExpirationDate, dispenseLog, index }: Props) => {
  const [lotNumber, setLotNumber] = useState(dispenseLog?.lot_number ?? '');

  const handleLotNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLotNumber(event.target.value);
  };

  return (
    <CRow>
      <CFormInput type="hidden" name={`dispense_logs[${index}][id]`} value={dispenseLog?.id ?? ''} />

      {requiresLotNumber && (
        <CCol>
          <CFormInput
            id={`lot-number-${index}`}
            label="Lot Number"
            name={`dispense_logs[${index}][lot_number]`}
            onChange={handleLotNumberChange}
            text="Required"
            type="text"
            value={lotNumber}
            required
          />
        </CCol>
      )}

      {requiresExpirationDate && (
        <CCol>
          <DatePicker
            date={dispenseLog?.expiration_date ?? null}
            id={`dispense_logs[${index}][expiration_date]`}
            label="Expires At"
            required={true}
            text="Required"
          />
        </CCol>
      )}
    </CRow>
  );
};
