export const topics = [
  'Appointments',
  'Consults',
  'Diagnostics',
  'Financial',
  'Health Plans',
  'Master Problems',
  'Prescriptions',
  'Presenting Problems',
  'Procedures',
  'Vaccinations',
  'Visit Summary',
  'Other'
] as const;

export type Topic = (typeof topics)[number];

export const getTopicFromContext: { [key: string]: Topic } = {
  AnimalHealthPlan: 'Health Plans',
  AnimalMasterProblem: 'Master Problems',
  Assessment: 'Consults',
  Consult: 'Consults',
  ConsultPlan: 'Consults',
  ConsultPresentingProblem: 'Presenting Problems',
  DiagnosticRequest: 'Diagnostics',
  DiagnosticResult: 'Diagnostics',
  Estimate: 'Financial',
  HealthStatus: 'Consults',
  History: 'Consults',
  Invoice: 'Financial',
  PhysicalExam: 'Consults',
  PrescriptionItem: 'Prescriptions',
  Procedure: 'Procedures',
  Prescription: 'Prescriptions',
  Vaccination: 'Vaccinations'
};
