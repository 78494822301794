import * as React from 'react';
import type { SVGProps } from 'react';

const SvgDentalTools = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <g fill="currentColor" clipPath="url(#fi_12115276_svg__a)">
      <path d="M3.094.07a2.742 2.742 0 0 0-.492 5.44v3.075a1.62 1.62 0 0 0-1.125 1.54v6.188a1.617 1.617 0 0 0 3.234 0v-6.188a1.62 1.62 0 0 0-1.125-1.54V5.51A2.742 2.742 0 0 0 3.094.07m.633 10.055v6.188a.633.633 0 0 1-1.266 0v-6.188a.633.633 0 0 1 1.266 0M3.094 4.57a1.758 1.758 0 1 1 0-3.515 1.758 1.758 0 0 1 0 3.515M12.938 7.945a4.98 4.98 0 0 0-3.586 1.522 1.62 1.62 0 0 0-.984-.882V1.992l1.978-.99a.492.492 0 0 0-.441-.88l-2.25 1.125a.49.49 0 0 0-.272.44v6.898a1.62 1.62 0 0 0-1.125 1.54v6.188a1.617 1.617 0 0 0 3.22.22 4.99 4.99 0 0 0 8.09-1.731 4.99 4.99 0 0 0-4.63-6.857m-4.43 2.692a4.99 4.99 0 0 0 0 4.601v1.075a.633.633 0 0 1-1.266 0v-6.188a.633.633 0 0 1 1.266 0zm4.43 6.308a4.008 4.008 0 1 1 0-8.015 4.008 4.008 0 0 1 0 8.015" />
      <path d="M14.533 13.234a1.618 1.618 0 0 0-3.19 0 1.42 1.42 0 0 0-.585 1.11c0 .906.956 1.617 2.18 1.617 1.223 0 2.18-.71 2.18-1.617a1.42 1.42 0 0 0-.585-1.11m-1.595 1.742c-.685 0-1.196-.334-1.196-.632a.56.56 0 0 1 .324-.415.49.49 0 0 0 .24-.422v-.02a.633.633 0 1 1 1.265 0v.02a.49.49 0 0 0 .24.422.56.56 0 0 1 .322.415c0 .298-.511.632-1.195.632M12.938 10.898a.492.492 0 1 0 0-.984.492.492 0 0 0 0 .984M10.969 11.742a.492.492 0 1 0 0-.984.492.492 0 0 0 0 .984M14.906 11.742a.492.492 0 1 0 0-.984.492.492 0 0 0 0 .984" />
    </g>
    <defs>
      <clipPath id="fi_12115276_svg__a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDentalTools;
