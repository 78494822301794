import * as React from 'react';
import type { SVGProps } from 'react';

const SvgFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M3.863 1.5v15M3.863 3h8.4c2.025 0 2.475 1.125 1.05 2.55l-.9.9c-.6.6-.6 1.575 0 2.1l.9.9c1.425 1.425.9 2.55-1.05 2.55h-8.4"
    />
  </svg>
);
export default SvgFlag;
