import axios from 'axios';

import { Clinic } from '../types/Clinic';

function fetchClinics(onSuccess: (clinics: Clinic[]) => void) {
  const url = '/pim/clinics';
  axios.get<Clinic[]>(url).then((data) => onSuccess(data.data.record));
}

function fetchClinic(clinicId: number | string, onSuccess: (clinics: Clinic) => void) {
  const url = `/pim/clinics/${clinicId}`;
  axios.get<Clinic>(url).then((data) => onSuccess(data.data.record));
}

function createClinic(clinic: Clinic, onSuccess: (clinic: Clinic) => void, onError: () => void) {
  const url = '/pim/clinics';
  axios
    .post<Clinic>(url, clinic)
    .then((data) => onSuccess(data.data.record))
    .catch(onError);
}

function updateClinic(
  clinicId: number | string,
  clinic: Clinic,
  onSuccess: (clinic: Clinic) => void,
  onError: () => void
) {
  const url = `/pim/clinics/${clinicId}`;
  axios
    .put<Clinic>(url, clinic)
    .then((data) => onSuccess(data.data.record))
    .catch(onError);
}

export { fetchClinics, fetchClinic, createClinic, updateClinic };
