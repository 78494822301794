import cn from 'classnames';
import * as React from 'react';
import { ReactNode, useState } from 'react';

import { CBadge, CFormCheck, CSmartTable } from '@coreui/react-pro';

import { MedicalHistoryItem } from 'types/MedicalHistoryItem';
import { PrescriptionItem } from 'types/PrescriptionItem';

import { toKebabCase } from 'utils/strings';

import styles from './MedicalHistoryTable.module.scss';

type Column =
  | {
      key: string;
      label?: string;
      _style?: { width: string };
    }
  | string;

type Props = {
  caption?: string;
  columns: Column[];
  items: MedicalHistoryItem[];
  name: string;
  newButton?: ReactNode;
  scopedColumns: object;
  secondaryButton?: ReactNode;
  tableControl?: ReactNode;
  withHeader?: boolean;
  visible?: boolean;
};

export const MedicalHistoryTable = ({
  caption,
  columns,
  items,
  name,
  newButton,
  scopedColumns,
  secondaryButton,
  tableControl,
  withHeader = true,
  visible = true,
  ...rest
}: Props): JSX.Element => {
  const [hideItems, setHideItems] = useState(true);

  const toggleHideItems = () => setHideItems(!hideItems);

  const canBeCurrent = items.length && Object.keys(items[0]).includes('current');
  const toggleLabel = canBeCurrent ? 'Hide not currently taking' : 'Hide disabled';

  const itemFilter = (item: MedicalHistoryItem) => {
    let perItemFilter;
    if (canBeCurrent) {
      perItemFilter = (item as PrescriptionItem).current;
    } else {
      perItemFilter = item.status !== 'disabled';
    }

    return !hideItems || perItemFilter;
  };

  return (
    <div className={cn({ 'd-none': !visible })}>
      <div className="d-flex align-items-center justify-content-between mb-4">
        {tableControl}
        {withHeader && (
          <div className="d-flex align-items-center">
            <h2 className="mb-0">{name}</h2>
            <CBadge className="ms-2" color="secondary">
              {items.length}
            </CBadge>
          </div>
        )}

        <div className={cn('d-flex align-items-end gap-3', styles.buttonGroup)}>
          {items.length > 0 && (
            <CFormCheck
              className={cn('mb-2', styles.checkbox)}
              id={`${toKebabCase(name)}-checkbox`}
              label={toggleLabel}
              onChange={toggleHideItems}
              checked={hideItems}
            />
          )}

          {secondaryButton}
          {newButton}
        </div>
      </div>

      <CSmartTable
        columns={columns}
        items={items.filter(itemFilter)}
        itemsPerPage={20}
        pagination={true}
        scopedColumns={scopedColumns}
        paginationProps={{
          pages: Math.ceil(items.filter(itemFilter).length / 20) || 1,
          align: 'center'
        }}
        tableHeadProps={{ color: 'dark' }}
        tableProps={{ className: 'mb-5', 'aria-label': name, caption }}
        {...rest}
      />
    </div>
  );
};
