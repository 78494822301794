import { MedicalHistoryItem } from 'types/MedicalHistoryItem';

type Props = {
  item: MedicalHistoryItem;
  name: string;
  handleSuccess: (message: string) => void;
  handleError: (error: string) => void;
  updater: (
    id: number,
    params: object,
    { onSuccess, onError }: { onSuccess: (item: MedicalHistoryItem) => void; onError: (error: string) => void }
  ) => void;
};

export const statusAction = ({ item, ...rest }: Props) => {
  const handleToggleStatus = ({ item, updater, name, handleSuccess, handleError }: Props) => {
    const newStatus = item.status === 'disabled' ? 'active' : 'disabled';

    updater(
      item.id,
      { status: newStatus },
      {
        onSuccess: () => handleSuccess(`${name} ${newStatus}!`),
        onError: handleError
      }
    );
  };

  return {
    label: item.status === 'disabled' ? 'Activate' : 'Disable',
    onClick: () => handleToggleStatus({ item, ...rest })
  };
};
