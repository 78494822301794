import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CButton, CButtonGroup, CCol, CDatePicker, CForm, CFormInput, CLoadingButton, CRow } from '@coreui/react-pro';

import { fetchEmployeesByQuery } from 'api/Employees';

import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { Employee } from 'types/Employee';
import { Option } from 'types/Option';

import { reactSelectStyles } from 'utils/reactSelect';
import { employeeToOption } from 'utils/selectOptions';

import SvgCalendar from 'assets/images/SvgCalendar';
import SvgPeople from 'assets/images/SvgPeople';

import { FormAuditData } from 'components/FormAuditData';
import { FormDivider } from 'components/FormDivider';
import { IconLabel } from 'components/IconLabel';

import styles from './DiagnosticRequestForm.module.scss';

import { DiagnosticRequestItemForm } from './DiagnosticRequestItemForm';

type Props = {
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  diagnosticRequest?: DiagnosticRequest;
  employee: Employee | null;
};

const MIN_QUERY_LENGTH = 3;

export const DiagnosticRequestForm = ({ loading, hideForm, onSubmit, diagnosticRequest, employee }: Props) => {
  const initialRequestedAt = diagnosticRequest?.requested_at ? new Date(diagnosticRequest.requested_at) : new Date();
  const [dateofRequest] = useState<Date>(initialRequestedAt);
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<Option | null>(
    diagnosticRequest?.employee
      ? employeeToOption(diagnosticRequest.employee)
      : employee?.vet
      ? employeeToOption(employee)
      : null
  );
  const [diagnosticRequestItemCount, setDiagnosticRequestItemCount] = useState(
    diagnosticRequest?.diagnostic_request_items.length || 1
  );

  const loadEmployeeOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchEmployeesByQuery(inputValue).then((options) => {
      callback(options.map(employeeToOption));
    });
  };

  const handleEmployeeSelectChange = (option: Option | null) => {
    setSelectedEmployeeOption(option);
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const incrementDiagnosticRequests = () => {
    setDiagnosticRequestItemCount(diagnosticRequestItemCount + 1);
  };

  const decrementDiagnosticRequests = () => {
    setDiagnosticRequestItemCount(diagnosticRequestItemCount - 1);
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{diagnosticRequest ? 'Edit' : 'New'} Diagnostic Request</h2>

      <CRow className="mb-3">
        <CCol>
          <div className="d-flex align-items-center justify-content-between form-label">
            <label>
              <IconLabel icon={SvgCalendar} label="Date of Request" />
            </label>
            <div className={styles.required}>Required</div>
          </div>

          <CDatePicker
            id="date_of_request"
            date={dateofRequest}
            locale="en-US"
            firstDayOfWeek={0}
            format="yyyy-MM-dd"
            cleaner={false}
            text="Required"
          />
        </CCol>

        <CCol>
          <CFormInput hidden id="employee_id" name="employee_id" value={selectedEmployeeOption?.value ?? ''} />
          <div className="d-flex align-items-center justify-content-between form-label">
            <label>
              <IconLabel icon={SvgPeople} label="Requesting Employee" />
            </label>
            <div className={styles.required}>Required</div>
          </div>
          <AsyncSelect<Option>
            aria-label="Requesting Employee"
            placeholder="Type to search..."
            value={selectedEmployeeOption}
            onChange={handleEmployeeSelectChange}
            loadingMessage={loadingMessage}
            loadOptions={loadEmployeeOptions}
            styles={reactSelectStyles}
            isClearable
            isSearchable
            required
          />
        </CCol>
      </CRow>

      <div className="d-flex justify-content-end mb-3">
        <CButtonGroup
          className={styles.buttonGroup}
          size="sm"
          role="group"
          aria-label="Add or remove diagnostic request items"
        >
          <CButton onClick={incrementDiagnosticRequests}>Add</CButton>
          <CButton onClick={decrementDiagnosticRequests} disabled={diagnosticRequestItemCount === 1}>
            Remove
          </CButton>
        </CButtonGroup>
      </div>

      {Array.from({ length: diagnosticRequestItemCount }).map((_, index) => {
        const lastItem = index === diagnosticRequestItemCount - 1;
        return (
          <div key={index}>
            <DiagnosticRequestItemForm
              index={index}
              requestItem={diagnosticRequest?.diagnostic_request_items[index] || undefined}
            />
            {!lastItem && <FormDivider />}
          </div>
        );
      })}

      <div className="d-flex align-items-center justify-content-between">
        {diagnosticRequest && <FormAuditData item={diagnosticRequest} />}
        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton
            loading={loading}
            color="primary"
            type="submit"
            shape="rounded-pill"
            className={styles.button}
          >
            {diagnosticRequest ? 'Update' : 'Create'}
          </CLoadingButton>
          <CButton
            color="primary"
            type="button"
            onClick={hideForm}
            shape="rounded-pill"
            variant="outline"
            className={styles.button}
          >
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
