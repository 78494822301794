import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CButton, CCallout, CDatePicker, CForm, CFormCheck, CFormInput, CLoadingButton } from '@coreui/react-pro';

import { fetchMasterProblemsByQuery } from 'api/MasterProblems';

import { AnimalMasterProblem } from 'types/AnimalMasterProblem';
import { MasterProblem } from 'types/MasterProblem';

import { reactSelectStyles } from 'utils/reactSelect';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as ClipboardIcon } from 'assets/images/clipboard.svg';
import SvgFlag from 'assets/images/SvgFlag';

import { RichTextEditor } from 'components/RichTextEditor';

import styles from './AnimalMasterProblemForm.module.scss';

type Props = {
  loading: boolean;
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  animalMasterProblem?: AnimalMasterProblem;
  animalMasterProblems: AnimalMasterProblem[];
};

type Option = {
  value: string;
  label: string;
  description?: string;
};

const MIN_QUERY_LENGTH = 3;

const toOption = (masterProblem: MasterProblem): Option => ({
  value: String(masterProblem.id),
  label: masterProblem.name,
  description: masterProblem.description
});

export const AnimalMasterProblemForm = ({
  loading,
  hideModal,
  onSubmit,
  animalMasterProblem,
  animalMasterProblems
}: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(
    animalMasterProblem?.started_on ? new Date(animalMasterProblem.started_on) : new Date()
  );

  const [selectedOption, setSelectedOption] = useState<Option | null>(
    animalMasterProblem ? toOption(animalMasterProblem.master_problem) : null
  );

  const [specifics, setSpecifics] = useState(animalMasterProblem?.specifics ?? '');

  const [isImportant, setIsImportant] = useState(animalMasterProblem?.important ?? false);

  const handleSelectChange = (selectedOption: Option | null) => {
    setSelectedOption(selectedOption);
  };

  const availableMasterProblems = (masterProblem: MasterProblem): boolean => {
    const activeAnimalMasterProblems = animalMasterProblems
      .filter((amp) => amp.status !== 'disabled')
      .map((amp) => amp.master_problem.id);

    return !activeAnimalMasterProblems.includes(masterProblem.id);
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  return (
    <CForm className="row g-3" onSubmit={onSubmit}>
      <h2 className="mb-0">{animalMasterProblem ? 'Edit' : 'New'} Master Problem</h2>

      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="form-label d-flex align-items-center">
            <CalendarIcon className={styles.icon} />
            <label htmlFor="started">Started</label>
          </div>
          <div className={styles.required}>Required</div>
        </div>

        <CDatePicker format="yyyy-MM-dd" onDateChange={setStartDate} id="started" locale="en-US" date={startDate} />
      </div>

      <div className="mb-3">
        <CFormInput hidden id="master_problem_id" name="master_problem_id" value={selectedOption?.value ?? ''} />
        <div className="d-flex align-items-center justify-content-between">
          <div className="form-label d-flex align-items-center">
            <ClipboardIcon className={styles.icon} />
            <label htmlFor="master_problem">Master Problem</label>
          </div>
          <div className={styles.required}>Required</div>
        </div>
        <AsyncSelect<Option>
          id="master_problem"
          aria-label="Master Problem"
          placeholder="Type to search..."
          styles={reactSelectStyles}
          value={selectedOption}
          onChange={handleSelectChange}
          loadingMessage={loadingMessage}
          loadOptions={(inputValue, callback) => {
            if (inputValue.length < MIN_QUERY_LENGTH) return;

            fetchMasterProblemsByQuery(inputValue).then((options) => {
              callback(options.filter(availableMasterProblems).map(toOption));
            });
          }}
          isClearable
          required
          isSearchable
        />

        {selectedOption?.description && <CCallout color="info">{selectedOption.description}</CCallout>}
      </div>

      <div className="mb-3">
        <RichTextEditor label="Specifics" id="specifics" name="specifics" value={specifics} onChange={setSpecifics} />
      </div>

      <div className="d-flex align-items-center justify-content-end">
        <CFormInput hidden id="important" name="important" value={isImportant ? 'true' : 'false'} />
        <CFormCheck
          id={`problem-flag-${animalMasterProblem?.id ?? ''}`}
          key={`problem-flag-${animalMasterProblem?.id ?? ''}`}
          className={styles.checkmark}
          label={
            <>
              <SvgFlag className={cn(styles.icon, styles.iconLeft)} />
              Flag
            </>
          }
          onChange={() => setIsImportant(!isImportant)}
          checked={isImportant}
          reverse={true}
        />
      </div>

      <div className={cn('d-flex justify-content-end', styles.buttons)}>
        <CLoadingButton loading={loading} color="primary" type="submit" shape="rounded-pill" className={styles.button}>
          {animalMasterProblem ? 'Update' : 'Create'}
        </CLoadingButton>
        <CButton type="button" onClick={hideModal} shape="rounded-pill" variant="outline" className={styles.button}>
          Cancel
        </CButton>
      </div>
    </CForm>
  );
};
