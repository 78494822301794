import axios from 'axios';

import { HealthPlanTrigger } from 'types/HealthPlanTrigger';

function fetchHealthPlanTrigger(triggerId: number, onSuccess: (healthPlanTrigger: HealthPlanTrigger) => void) {
  const url = `/pim/health_plan_triggers/${triggerId}`;
  axios.get<HealthPlanTrigger>(url).then((data) => onSuccess(data.data.record));
}

function createHealthPlanTrigger(params: HealthPlanTrigger, onSuccess: (healthPlanTrigger: HealthPlanTrigger) => void) {
  const url = `/pim/health_plan_triggers`;
  axios.post<HealthPlanTrigger>(url, { health_plan_trigger: params }).then((data) => onSuccess(data.data.record));
}

function updateHealthPlanTrigger(
  triggerId: number,
  params: HealthPlanTrigger,
  onSuccess: (healthPlanTrigger: HealthPlanTrigger) => void
) {
  const url = `/pim/health_plan_triggers/${triggerId}`;
  axios.put<HealthPlanTrigger>(url, params).then((data) => onSuccess(data.data.record));
}

export { createHealthPlanTrigger, fetchHealthPlanTrigger, updateHealthPlanTrigger };
