import * as React from 'react';
import type { SVGProps } from 'react';

const SvgStack = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      d="M17.67 1h-9.9c-.739 0-1.34.6-1.34 1.34v1.374H5.054c-.738 0-1.339.6-1.339 1.339v1.374H2.34C1.601 6.427 1 7.028 1 7.767v9.894C1 18.4 1.6 19 2.34 19h9.9c.738 0 1.339-.6 1.339-1.339v-1.375h1.376c.738 0 1.339-.6 1.339-1.339v-1.374h1.376c.738 0 1.34-.601 1.34-1.34V2.34c0-.738-.602-1.339-1.34-1.339Zm-2.08 13.947c0 .35-.285.636-.635.636h-1.376v-1.65a.352.352 0 1 0-.704 0v3.728c0 .35-.285.635-.636.635h-9.9a.636.636 0 0 1-.636-.635V7.766c0-.35.286-.635.636-.635h9.9c.351 0 .636.285.636.635v2.447a.352.352 0 0 0 .704 0V7.766c0-.738-.601-1.339-1.34-1.339H4.42V5.053c0-.35.285-.636.635-.636h9.9c.351 0 .636.285.636.636v9.894Zm2.716-2.714c0 .35-.286.636-.636.636h-1.376V5.053c0-.739-.6-1.34-1.34-1.34h-7.82V2.34c0-.35.285-.635.635-.635h9.901c.35 0 .636.285.636.635z"
    />
  </svg>
);
export default SvgStack;
