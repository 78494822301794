import { ReactNode } from 'react';
import { createRoot } from 'react-dom/client';

export const createNewTab = (component: ReactNode) => {
  const newTab = window.open('', '_blank');

  if (newTab) {
    const div = document.createElement('div');
    div.setAttribute('id', 'root');
    newTab.document.body.appendChild(div);

    const container = newTab.document.getElementById('root');
    if (container) {
      const root = createRoot(container);
      root.render(component);
    }
  }
};
