import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { TemplateSelector } from 'views/consult_templates/TemplateSelector';

import { CButton, CCard, CCol, CDatePicker, CForm, CFormInput, CLoadingButton, CRow } from '@coreui/react-pro';

import { fetchConsultTemplate } from 'api/ConsultTemplates';
import { fetchProcedureProduct } from 'api/Products';

import { compileConsultTemplate, consultTemplateForm, FormLine } from 'types/ConsultTemplate';
import { Procedure } from 'types/Procedure';
import { Product } from 'types/Product';

import SvgCalendar from 'assets/images/SvgCalendar';
import SvgClipboard from 'assets/images/SvgClipboard';

import { ConsultTemplateForm } from 'components/ConsultTemplateForm';
import { FormAuditData } from 'components/FormAuditData';
import { IconLabel } from 'components/IconLabel';
import { RichTextEditor } from 'components/RichTextEditor';

import styles from './ProcedureForm.module.scss';

type Props = {
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  procedure?: Procedure;
};

type Option = {
  value: string;
  label: string;
  description?: string;
};

const toOption = (product: Product): Option => ({
  value: String(product.id),
  label: product.name || '',
  description: product.description
});

const MIN_QUERY_LENGTH = 3;

export const ProcedureForm = ({ loading, hideForm, onSubmit, procedure }: Props) => {
  const [adminDate, setAdminDate] = useState<Date | null>(
    procedure?.date_of_administration ? new Date(procedure.date_of_administration) : new Date()
  );
  const [outcome, setOutcome] = useState(procedure?.outcome ?? '');
  const [templateForm, setTemplateForm] = useState<FormLine[]>();

  const [selectedOption, setSelectedOption] = useState<Option | null>(procedure ? toOption(procedure.product) : null);

  const handleSelectChange = (selectedOption: Option | null) => {
    setSelectedOption(selectedOption);
  };

  const loadTemplate = (templateId: string) => {
    fetchConsultTemplate(templateId, (template) => {
      const form = consultTemplateForm(template);
      setTemplateForm(form);

      const notes = compileConsultTemplate(form);
      setOutcome(notes);
    });
  };

  const formChanged = (form: FormLine[]) => {
    setTemplateForm(form);
    const notes = compileConsultTemplate(form);
    setOutcome(notes);
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{procedure ? 'Edit' : 'New'} Procedure</h2>

      <CRow className="mb-3">
        <CCol>
          <CDatePicker
            timepicker
            id="date_of_administration"
            date={adminDate}
            label={<IconLabel icon={SvgCalendar} label="Date of Administration" />}
            locale="en-US"
            firstDayOfWeek={0}
            format="MMM dd, yyyy hh:mm a"
            onDateChange={setAdminDate}
          />
        </CCol>
        <CCol>
          <CFormInput hidden id="product_id" name="product_id" value={selectedOption?.value ?? ''} />
          <div className="d-flex align-items-center justify-content-between form-label">
            <label htmlFor="product">
              <IconLabel icon={SvgClipboard} label="Product" />
            </label>
            <div className={styles.required}>Required</div>
          </div>
          <AsyncSelect<Option>
            id="product"
            aria-label="Product"
            placeholder="Type to search..."
            value={selectedOption}
            onChange={handleSelectChange}
            loadingMessage={loadingMessage}
            loadOptions={(inputValue, callback) => {
              if (inputValue.length < MIN_QUERY_LENGTH) return;

              fetchProcedureProduct(inputValue).then((options) => {
                callback(options.map(toOption));
              });
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                borderRadius: '16px',
                borderColor: '#ececec'
              })
            }}
            isClearable
            isSearchable
            required
          />
        </CCol>
      </CRow>

      <CRow className="mb-3">
        <CCol>
          {(!procedure || !outcome) && (
            <div className="mb-3">
              <TemplateSelector templateType="ProcedureTemplate" templateChanged={loadTemplate} />
            </div>
          )}
          {templateForm && (
            <CCard className="p-3">
              <ConsultTemplateForm form={templateForm} updateForm={formChanged} />
            </CCard>
          )}
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <RichTextEditor
            label="Outcome"
            value={outcome}
            hidden={!!templateForm}
            onChange={setOutcome}
            id="outcome"
            name="outcome"
            text="Required"
            required
          />
        </CCol>
      </CRow>

      <div className="d-flex align-items-center justify-content-between">
        {procedure && <FormAuditData item={procedure} />}
        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton
            loading={loading}
            color="primary"
            type="submit"
            className={styles.button}
            shape="rounded-pill"
          >
            {procedure ? 'Update' : 'Create'}
          </CLoadingButton>
          <CButton
            type="button"
            shape="rounded-pill"
            className={styles.button}
            color="primary"
            variant="outline"
            onClick={hideForm}
          >
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
