import * as React from 'react';
import { useState } from 'react';

import { CButton, CModal, CModalBody, CModalHeader, CSpinner } from '@coreui/react-pro';

import { updateDocument } from 'api/Documents';

import { Document } from 'types/Document';

import { RichTextEditor } from 'components/RichTextEditor';

type Props = {
  onClose: () => void;
  onSuccess: (document: Document) => void;
  document: Document;
};

export const EditDocumentModal = ({ onClose, onSuccess, document }: Props) => {
  const [documentPreview, setDocumentPreview] = useState<Document | undefined>(document);
  const [isSavingDocument, setIsSavingDocument] = useState<boolean>(false);

  const handleUpdateDocument = () => {
    setIsSavingDocument(true);
    let needsSignature = false;
    if (
      documentPreview?.content &&
      (documentPreview.content.indexOf('#INPUT#') > -1 || documentPreview.content.indexOf('#SIGNATURE#') > -1)
    )
      needsSignature = true;

    if (documentPreview)
      updateDocument(
        document.id,
        { ...documentPreview, pdf: !needsSignature, requires_signature: needsSignature },
        { onSuccess: handleDocumentSuccess, onError: handleDocumentError }
      );
  };

  const handleDocumentSuccess = (document: Document) => {
    setDocumentPreview(undefined);
    setIsSavingDocument(false);
    onSuccess(document);
  };

  const handleDocumentError = () => {
    setIsSavingDocument(false);
  };

  return (
    <CModal
      visible={true}
      onClose={() => {
        setDocumentPreview(undefined);
        onClose();
      }}
      size="xl"
      backdrop="static"
    >
      <CModalHeader>
        <h4>Edit Document</h4>
      </CModalHeader>
      <CModalBody>
        {documentPreview && (
          <div>
            <RichTextEditor
              id="document-preview"
              label="Document Preview"
              name="document preview"
              editorType="document"
              value={documentPreview.content}
              onChange={(value) => setDocumentPreview({ ...documentPreview, content: value })}
              required
            />
            <CButton onClick={handleUpdateDocument} disabled={!documentPreview || isSavingDocument}>
              {isSavingDocument && <CSpinner size="sm" className="me-2" />}
              Update
            </CButton>
          </div>
        )}
      </CModalBody>
    </CModal>
  );
};
