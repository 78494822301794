import axios from 'axios';

import { AnimalHealthPlan } from 'types/AnimalHealthPlan';

export type PlansFilterParams = {
  animal_id?: string | number;
  status?: string;
};

const fetchAnimalHealthPlans = (params: PlansFilterParams, onSuccess: (healthPlan: AnimalHealthPlan[]) => void) => {
  const url = `/pim/animal_health_plans`;
  axios
    .get<AnimalHealthPlan[]>(url, {
      params: params
    })
    .then((data) => onSuccess(data.data.record));
};

const fetchDueAnimalHealthPlans = (animalId: string | number, onSuccess: (healthPlan: AnimalHealthPlan[]) => void) => {
  const url = `/pim/animal_health_plans/due_now?animal_id=${animalId}`;
  axios.get<AnimalHealthPlan[]>(url).then((data) => onSuccess(data.data.record));
};

const createAnimalHealthPlan = (
  animalHealthPlan: Partial<AnimalHealthPlan>,
  { onSuccess, onError }: { onSuccess: (healthPlan: AnimalHealthPlan) => void; onError: (message: string) => void }
) => {
  const url = `/pim/animal_health_plans`;
  axios
    .post<AnimalHealthPlan>(url, animalHealthPlan)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError(error.response.data.message));
};

const updateAnimalHealthPlan = (
  animalHealthPlanId: number,
  animalHealthPlan: Partial<AnimalHealthPlan>,
  { onSuccess, onError }: { onSuccess: (healthPlan: AnimalHealthPlan) => void; onError: (message: string) => void }
) => {
  const url = `/pim/animal_health_plans/${animalHealthPlanId}`;

  axios
    .put<AnimalHealthPlan>(url, { animal_health_plan: animalHealthPlan })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError) onError(error.response.data.message);
    });
};

export { fetchAnimalHealthPlans, fetchDueAnimalHealthPlans, createAnimalHealthPlan, updateAnimalHealthPlan };
