import axios from 'axios';

import { DiagnosticRequest } from 'types/DiagnosticRequest';

const fetchDiagnosticRequestsByAnimal = (
  animalId: string,
  onSuccess: (diagnosticRequest: DiagnosticRequest[]) => void
) => {
  const url = `/pim/diagnostic_requests?animal_id=${animalId}`;
  axios.get<DiagnosticRequest[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchDiagnosticRequestsForConsult = (
  consultId: number | string,
  onSuccess: (diagnosticRequest: DiagnosticRequest[]) => void
) => {
  const url = `/pim/diagnostic_requests`;
  axios
    .get<DiagnosticRequest[]>(url, { params: { consult_id: consultId } })
    .then((data) => onSuccess(data.data.record));
};

const createDiagnosticRequest = (
  params: Partial<DiagnosticRequest>,
  onSuccess: (diagnosticRequests: DiagnosticRequest[]) => void,
  onError?: (error: string) => void
) => {
  const url = `/pim/diagnostic_requests`;
  return axios
    .post<DiagnosticRequest[]>(url, params)
    .then((response) => onSuccess(response.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};

const updateDiagnosticRequest = (
  diagnosticRequestId: number,
  params: Partial<DiagnosticRequest>,
  onSuccess: (diagnosticRequest: DiagnosticRequest) => void,
  onError?: (error: string) => void
) => {
  const url = `/pim/diagnostic_requests/${diagnosticRequestId}`;
  axios
    .put<DiagnosticRequest>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error) => {
      if (onError && error.response) {
        onError(error.response.data.message);
      }
    });
};

const refreshDiagnosticRequest = (
  diagnosticRequestId: number,
  onSuccess: (diagnosticRequest: DiagnosticRequest) => void
) => {
  const url = `/pim/diagnostic_requests/${diagnosticRequestId}/refresh`;
  axios.put<DiagnosticRequest>(url).then((data) => onSuccess(data.data.record));
};

const sendDiagnosticRequestOrder = (
  diagnosticRequestId: number,
  onSuccess: (diagnosticRequest: DiagnosticRequest) => void
) => {
  const url = `/pim/diagnostic_requests/${diagnosticRequestId}/send_order`;
  axios.put<DiagnosticRequest>(url).then((data) => onSuccess(data.data.record));
};

export {
  fetchDiagnosticRequestsForConsult,
  fetchDiagnosticRequestsByAnimal,
  createDiagnosticRequest,
  updateDiagnosticRequest,
  refreshDiagnosticRequest,
  sendDiagnosticRequestOrder
};
