import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { CButton, CCol, CContainer, CRow } from '@coreui/react-pro';

import { Animal } from 'types/Animal';

import { ageDisplay, desexedDisplay } from 'utils/animal';
import { compactDateDisplay, compactDateTimeDisplay } from 'utils/dates';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as ClipboardIcon } from 'assets/images/clipboard.svg';
import { ReactComponent as CrownIcon } from 'assets/images/crown.svg';
import { ReactComponent as GenderIcon } from 'assets/images/gender.svg';
import { ReactComponent as PawIcon } from 'assets/images/paw.svg';
import { ReactComponent as ScaleIcon } from 'assets/images/scale.svg';
import SvgConversation from 'assets/images/SvgConversation';

import { AnimalPhoto } from 'components/AnimalPhoto';
import { NewPatientBadge } from 'components/NewPatientBadge';
import { Pill } from 'components/Pill';

import styles from './AppointmentSignalment.module.scss';

type Props = {
  animal: Animal;
};

export const AppointmentSignalment = ({ animal }: Props) => {
  const isWeightComplete = (animal: Animal) => {
    return [animal.weight, animal.weight_unit].every((attr) => attr);
  };

  return (
    <div>
      <h2 className={styles.header}>
        Patient <NewPatientBadge animal={animal} /> {animal.fas_score && <Pill label={`FAS ${animal.fas_score}`} />}
      </h2>

      <CContainer className={styles.container}>
        <div className={styles.headerRow}>
          <AnimalPhoto height={120} width={120} animal={animal} className={styles.photo} />
          <div className={styles.whiteGroup}>
            <div className="d-flex justify-content-between w-100 mb-2">
              <Link className={styles.name} to={`/animals/${animal.id}`}>
                {animal.name}
              </Link>
              <CButton
                className="text-nowrap"
                style={{ paddingTop: '5px' }}
                shape="rounded-pill"
                size="sm"
                href={`/animals/${animal.id}/medical_history`}
                target="_blank"
              >
                Full Medical History
              </CButton>
            </div>

            <div className="d-flex justify-content-between w-100">
              <div>Plan</div>
              <div>
                <CrownIcon /> {animal.membership?.plan.name}
              </div>
            </div>
            {animal.next_appointment_json?.start_time && (
              <div className="d-flex justify-content-between w-100">
                <div>Upcoming Appointment</div>
                <div>
                  <Link to={`/appointments/${animal.next_appointment_json.id}`}>
                    {compactDateTimeDisplay(animal.next_appointment_json.start_time)}
                  </Link>
                </div>
              </div>
            )}
            {animal.last_appointment_json?.start_time && (
              <div className="d-flex justify-content-between w-100">
                <div>Last Appointment</div>
                <div>
                  <Link to={`/appointments/${animal.last_appointment_json.id}`}>
                    {compactDateTimeDisplay(animal.last_appointment_json.start_time)}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        <CRow>
          <CCol className={styles.details}>
            <div className="d-flex justify-content-between">
              <div>
                <PawIcon className={styles.icon} />
                Species
              </div>
              <div className={styles.value}>{animal.species.name}</div>
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <GenderIcon className={cn(styles.icon, styles.unstroked)} />
                Sex
              </div>
              <div className={styles.value}>{desexedDisplay(animal)}</div>
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <PawIcon className={styles.icon} />
                Breed
              </div>
              {animal.breed && <div className={styles.value}>{animal.breed.name}</div>}
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <SvgConversation height={16} className={cn('primaryColor', styles.icon)} />
                Pet Peeves
              </div>
              <div className={styles.value}>{animal.pet_peeve_list ? animal.pet_peeve_list.join(', ') : 'N/A'}</div>
            </div>
          </CCol>

          <CCol className={styles.details}>
            <div className="d-flex justify-content-between">
              <div>
                <ScaleIcon className={styles.icon} />
                Weight
              </div>
              {isWeightComplete(animal) && (
                <div className={styles.value}>
                  {animal.weight} {animal.weight_unit}
                </div>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <PawIcon className={styles.icon} />
                Age
              </div>
              {animal.date_of_birth && (
                <div className={cn('text-capitalize', styles.value)}>{ageDisplay(animal.date_of_birth)}</div>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <CalendarIcon className={styles.icon} />
                DOB
              </div>
              {animal.date_of_birth && <div className={styles.value}>{compactDateDisplay(animal.date_of_birth)}</div>}
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <ClipboardIcon className={styles.icon} />
                Personality Trait
              </div>
              <div className={styles.value}>
                {animal.personality_trait_list ? animal.personality_trait_list.join(', ') : 'N/A'}
              </div>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};
