import axios, { AxiosError } from 'axios';

import { ApiResponse } from 'api/ResponseTypes';

import { ConsultPresentingProblem } from 'types/ConsultPresentingProblem';

const createConsultPresentingProblem = (
  consultPresentingProblem: Partial<ConsultPresentingProblem>,
  {
    onSuccess,
    onError
  }: { onSuccess: (consultPresentingProblem: ConsultPresentingProblem) => void; onError: (error: string) => void }
) => {
  const url = `/pim/consult_presenting_problems`;

  axios
    .post<ConsultPresentingProblem>(url, { consult_presenting_problem: consultPresentingProblem })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<ConsultPresentingProblem>>) => onError(error.message));
};

const fetchConsultPresentingProblemsForConsult = (
  consultId: number | string,
  onSuccess: (consultPresentingProblems: ConsultPresentingProblem[]) => void
) => {
  const url = `/pim/consult_presenting_problems`;
  axios
    .get<ConsultPresentingProblem[]>(url, { params: { consult_id: consultId } })
    .then((data) => onSuccess(data.data.record));
};

const updateConsultPresentingProblem = (
  consultPresentingProblemId: number,
  params: Partial<ConsultPresentingProblem>,
  {
    onSuccess,
    onError
  }: { onSuccess: (consultPresentingProblem: ConsultPresentingProblem) => void; onError: (error: string) => void }
) => {
  const url = `/pim/consult_presenting_problems/${consultPresentingProblemId}`;
  axios
    .put<Partial<ConsultPresentingProblem>, ConsultPresentingProblem>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<ConsultPresentingProblem>>) => onError(error.message));
};

export { fetchConsultPresentingProblemsForConsult, updateConsultPresentingProblem, createConsultPresentingProblem };
