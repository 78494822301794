import * as React from 'react';

import { CImage } from '@coreui/react-pro';

import { Animal } from 'types/Animal';

import { generateCloudinaryPath } from 'utils/cloudinary';

import { ReactComponent as CatSquare } from 'assets/images/cat-square.svg';
import { ReactComponent as DogSquare } from 'assets/images/dog-square.svg';

interface Props {
  animal: Animal;
  width?: number;
  height?: number;
  className?: string;
}

export const AnimalPhoto: React.FC<Props> = ({ animal, width = 50, height = 50, ...rest }) => {
  if (animal.photo_provider_id) {
    const imageSrc = generateCloudinaryPath({ imagePublicId: animal.photo_provider_id });
    return <CImage src={imageSrc} alt={`${animal.name}'s photo`} width={width} height={height} rounded {...rest} />;
  } else if (animal.species.name === 'Cat') {
    return <CatSquare height={height} />;
  } else {
    return <DogSquare height={height} />;
  }
};
