import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CButton, CCard, CCardBody, CCardHeader, CListGroup, CListGroupItem, CSpinner } from '@coreui/react-pro';

import { getPreviousClinic } from 'api/PreviousClinics';

import { PreviousClinic } from 'types/PreviousClinic';

import { formatPhoneNumber } from 'utils/phoneNumbers';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [previousClinic, setPreviousClinic] = useState<PreviousClinic>();

  useEffect(() => {
    getPreviousClinic(id, setPreviousClinic);
  }, [id]);

  if (!previousClinic) {
    return <CSpinner color="primary" />;
  }

  return (
    <CCard style={{ width: '50rem' }}>
      <CCardHeader className="d-flex justify-content-between align-items-center">
        <h3>{previousClinic.name}</h3>
        <CButton color="primary" href={`/previous_clinics/${previousClinic.id}/edit`}>
          Edit
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CListGroup className="mb-3">
          <CListGroupItem className="d-flex justify-content-between align-items-center">
            Street 1 <span>{previousClinic.street1}</span>
          </CListGroupItem>
          <CListGroupItem className="d-flex justify-content-between align-items-center">
            Street 2 <span>{previousClinic.street2}</span>
          </CListGroupItem>
          <CListGroupItem className="d-flex justify-content-between align-items-center">
            City
            <span>{previousClinic.city}</span>
          </CListGroupItem>
          <CListGroupItem className="d-flex justify-content-between align-items-center">
            State
            <span>{previousClinic.state}</span>
          </CListGroupItem>
          <CListGroupItem className="d-flex justify-content-between align-items-center">
            Zip Code <span>{previousClinic.zip_code}</span>
          </CListGroupItem>
          <CListGroupItem className="d-flex justify-content-between align-items-center">
            Phone Number <span>{formatPhoneNumber(previousClinic.phone)}</span>
          </CListGroupItem>
          <CListGroupItem className="d-flex justify-content-between align-items-center">
            Contact Email <span>{previousClinic.email}</span>
          </CListGroupItem>
        </CListGroup>
      </CCardBody>
    </CCard>
  );
};

export default Details;
