import axios from 'axios';

import { Diagnostic } from 'types/Diagnostic';
import { Pagination } from 'types/Pagination';

const fetchDiagnostics = (
  queryText: string,
  showInactive: boolean,
  onSuccess: (diagnostics: Diagnostic[]) => void,
  setPagination?: (pagination: Pagination) => void,
  page?: number
) => {
  const url = `/pim/diagnostics`;
  axios
    .get<Diagnostic[]>(url, {
      params: {
        query: queryText,
        inactive: showInactive.toString(),
        page: page || 1
      }
    })
    .then((data) => {
      onSuccess(data.data.record);
      setPagination &&
        setPagination({
          page: page || 1,
          perPage: Number(data.headers['per-page']),
          total: Number(data.headers.total)
        });
    });
};

const fetchDiagnostic = (id: string, onSuccess: (diagnostic: Diagnostic) => void) => {
  const url = `/pim/diagnostics/${id}`;
  axios.get<Diagnostic>(url).then((data) => onSuccess(data.data.record));
};

const createDiagnostic = (diagnostic: Diagnostic, onSuccess: (diagnostic: Diagnostic) => void, onError: () => void) => {
  const url = `/pim/diagnostics`;
  axios
    .post<Diagnostic>(url, diagnostic)
    .then((data) => onSuccess(data.data.record))
    .catch(onError);
};

const updateDiagnostic = (
  id: string,
  diagnostic: Diagnostic,
  onSuccess: (diagnostic: Diagnostic) => void,
  onError: () => void
) => {
  const url = `/pim/diagnostics/${id}`;
  axios
    .put<Diagnostic>(url, diagnostic)
    .then((data) => onSuccess(data.data.record))
    .catch(onError);
};

export { fetchDiagnostics, fetchDiagnostic, createDiagnostic, updateDiagnostic };
