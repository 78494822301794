import axios, { AxiosError } from 'axios';

import { ApiResponse } from 'api/ResponseTypes';

import { Vaccination } from 'types/Vaccination';

const fetchVaccinationsByAnimal = (animalId: string | number, onSuccess: (vaccination: Vaccination[]) => void) => {
  const url = `/pim/vaccinations?animal_id=${animalId}`;
  axios.get<Vaccination[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchVaccinationsForConsult = (consultId: number | string, onSuccess: (vaccinations: Vaccination[]) => void) => {
  const url = `/pim/vaccinations`;
  axios.get<Vaccination[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const createVaccination = (
  vaccination: Partial<Vaccination>,
  { onSuccess, onError }: { onSuccess: (vaccination: Vaccination) => void; onError: (error: string) => void }
) => {
  const url = `/pim/vaccinations`;

  axios
    .post<Vaccination>(url, { vaccination: vaccination })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<Vaccination>>) => onError(error.message));
};

const updateVaccination = (
  vaccinationId: number,
  params: Partial<Vaccination>,
  { onSuccess, onError }: { onSuccess: (vaccination: Vaccination) => void; onError: (error: string) => void }
) => {
  const url = `/pim/vaccinations/${vaccinationId}`;
  axios.put<Vaccination>(url, params).then((data) => onSuccess(data.data.record));
};

export { fetchVaccinationsForConsult, createVaccination, updateVaccination, fetchVaccinationsByAnimal };
