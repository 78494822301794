import axios from 'axios';

import { Refill } from 'types/Refill';

const getRefillsQueue = (onSuccess: (refills: Refill[]) => void) => {
  const url = '/pim/refills';
  axios.get<Refill[]>(url).then((data) => onSuccess(data.data.record));
};

const updateRefill = (refillId: number, status: string, onSuccess: (refill: Refill) => void) => {
  const url = `/pim/refills/${refillId}`;
  axios
    .put<Refill>(url, {
      customer_note: '',
      status: status
    })
    .then((data) => onSuccess(data.data.record));
};

export { getRefillsQueue, updateRefill };
