import { addMinutes } from 'date-fns';
import * as React from 'react';
import { useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

import {
  CButton,
  CCloseButton,
  CCol,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormSwitch,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSidebar,
  CSidebarHeader
} from '@coreui/react-pro';

import { fetchProducts } from 'api/Products';

import { HospitalEventSchedule } from 'types/HospitalEventSchedule';
import { HospitalSheet } from 'types/HospitalSheet';
import { Option } from 'types/Option';
import { Product } from 'types/Product';

import { pickerTimeDisplay } from 'utils/dates';
import { toOption } from 'utils/selectOptions';

import { ConfirmationModal } from 'components/ConfirmationModal';

type HospitalScheduleFormProps = {
  handleSubmit: (
    schedule: Partial<HospitalEventSchedule>,
    intervalUnit: string,
    isRepeating: boolean,
    isMonitoring: boolean
  ) => void;
  handleRemove: (schedule: Partial<HospitalEventSchedule>) => void;
  handleCancel: () => void;
  setHospitalSchedule: (event: Partial<HospitalEventSchedule> | undefined) => void;
  hospitalSchedule: Partial<HospitalEventSchedule>;
  hospitalSheet: HospitalSheet;
  selectedDay: string;
};

const HospitalScheduleForm = ({
  handleSubmit,
  handleRemove,
  handleCancel,
  setHospitalSchedule,
  hospitalSchedule,
  hospitalSheet,
  selectedDay
}: HospitalScheduleFormProps): JSX.Element => {
  const [selectedProduct, setSelectedProduct] = useState<Option | null>(
    hospitalSchedule.title ? { label: hospitalSchedule.title, value: '0' } : null
  );
  const [isRepeating, setIsRepeating] = useState(
    hospitalSchedule.duration_in_minutes !== hospitalSchedule.interval_in_minutes
  );
  const [isMonitoring, setIsMonitoring] = useState(hospitalSchedule.group === 'monitoring');
  const [intervalUnit, setIntervalUnit] = useState<string>('minutes');

  const handleScheduleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedTime = event.target.value;
    const id = event.target.id;

    if (changedTime) {
      const day = selectedDay ? new Date(selectedDay) : new Date();
      const [hours, minutes] = changedTime.split(':');

      day.setHours(Number(hours));
      day.setMinutes(Number(minutes));

      if (id === 'start_time') {
        const updatedEndDate = addMinutes(day, hospitalSchedule.duration_in_minutes || 60);
        setHospitalSchedule({
          ...hospitalSchedule,
          start_time: day.toISOString(),
          end_time: updatedEndDate.toISOString()
        });
      } else {
        setHospitalSchedule({ ...hospitalSchedule, end_time: day.toISOString() });
      }
    }
  };

  const handleSaveSchedule = () => {
    handleSubmit(hospitalSchedule, intervalUnit, isRepeating, isMonitoring);
  };

  const [showRemoveScheduleModal, setShowRemoveScheduleModal] = useState(false);
  const handleRemoveSchedule = () => {
    if (hospitalSheet) {
      return (
        <ConfirmationModal
          isVisible={showRemoveScheduleModal}
          onClose={() => setShowRemoveScheduleModal(false)}
          onConfirm={() => handleRemove(hospitalSchedule)}
          modalBody={`This action cannot be undone.  All ${hospitalSchedule.hospital_events?.length} events will also be removed, even if they've been completed.`}
          confirmButtonLabel={`Yes, remove it`}
          modalHeader={`Remove "${hospitalSchedule.title}" from the hospital sheet?`}
        />
      );
    }
  };

  return (
    <>
      <CSidebar
        colorScheme="light"
        overlaid
        placement="end"
        size="xl"
        visible={true}
        style={{ overflowY: 'scroll', marginRight: '0' }}
      >
        <CSidebarHeader className="bg-transparent">
          <h5 className="text-center">
            {hospitalSchedule.id ? 'Edit treatment schedule' : 'Schedule a treatment'}
            <CCloseButton className="float-end" onClick={() => setHospitalSchedule(undefined)} />
          </h5>
        </CSidebarHeader>

        <div style={{ padding: '0px 20px' }}>
          <CRow className="mb-3">
            <CCol>
              <CFormLabel htmlFor="title" className="col-sm-3 col-form-label">
                Title
              </CFormLabel>
              <AsyncCreatableSelect<Option>
                id="title_select"
                aria-label="Title"
                placeholder="Search or type"
                onChange={(selected) => {
                  setSelectedProduct(selected as Option);
                  setHospitalSchedule({ ...hospitalSchedule, title: selected?.label });
                }}
                onCreateOption={(inputValue) => {
                  setSelectedProduct({ label: inputValue, value: inputValue });
                  setHospitalSchedule({ ...hospitalSchedule, title: inputValue });
                }}
                loadOptions={(inputValue, callback) => {
                  if (inputValue.length < 3) return;

                  fetchProducts(inputValue, (products: Product[]) => {
                    callback(products.map(toOption));
                  });
                }}
                isClearable
                isDisabled={!!hospitalSheet.discharged_at}
                formatCreateLabel={(inputValue) => `"${inputValue}"`}
                createOptionPosition="first"
                value={selectedProduct}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: '30px',
                    borderColor: '#ececec'
                  })
                }}
              />
            </CCol>
          </CRow>

          <CRow className="mb-3">
            <CCol>
              <CFormTextarea
                label="Instructions"
                value={hospitalSchedule.instructions}
                onChange={(event) => setHospitalSchedule({ ...hospitalSchedule, instructions: event.target.value })}
                disabled={!!hospitalSheet.discharged_at}
              />
            </CCol>
          </CRow>

          <CRow className="mb-3">
            <CCol>
              <CFormSwitch
                label="Monitoring?"
                checked={isMonitoring}
                onChange={() => setIsMonitoring(!isMonitoring)}
                disabled={!!hospitalSheet.discharged_at}
              />
            </CCol>
          </CRow>

          <CRow className="mb-3">
            <div style={{ width: '100%', height: '1px', background: 'var(--light-gray)' }}></div>
          </CRow>

          <CRow className="mb-3">
            <CCol>
              <CFormInput
                label="Start Time"
                type="time"
                id="start_time"
                onChange={handleScheduleTimeChange}
                value={hospitalSchedule.start_time ? pickerTimeDisplay(hospitalSchedule.start_time) : undefined}
                disabled={!!hospitalSheet.discharged_at}
              />
            </CCol>
            <CCol>
              <CFormInput
                label="Duration (minutes)"
                type="number"
                step={1}
                onChange={(event) =>
                  setHospitalSchedule({ ...hospitalSchedule, duration_in_minutes: Number(event.target.value) })
                }
                value={hospitalSchedule.duration_in_minutes}
                disabled={!!hospitalSheet.discharged_at}
              />
            </CCol>
          </CRow>

          <CRow className="mb-3">
            <CCol>
              <CFormSwitch
                label="Repeating?"
                checked={isRepeating}
                onChange={() => setIsRepeating(!isRepeating)}
                disabled={!!hospitalSheet.discharged_at}
              />
            </CCol>
          </CRow>

          {isRepeating && (
            <CRow className="mb-3">
              <CCol>
                <CInputGroup>
                  <CInputGroupText>Every</CInputGroupText>
                  <CFormInput
                    placeholder="30"
                    type="number"
                    onChange={(event) =>
                      setHospitalSchedule({ ...hospitalSchedule, interval_in_minutes: Number(event.target.value) })
                    }
                    value={hospitalSchedule.interval_in_minutes}
                    disabled={!!hospitalSheet.discharged_at}
                  />
                  <CFormSelect value={intervalUnit} onChange={(event) => setIntervalUnit(event.target.value)}>
                    <option value="minutes">minutes</option>
                    <option value="hours">hours</option>
                  </CFormSelect>
                </CInputGroup>
              </CCol>
            </CRow>
          )}

          {isRepeating && (
            <CRow className="mb-3">
              <CCol>
                <CInputGroup>
                  <CInputGroupText>until</CInputGroupText>
                  <CFormInput
                    type="time"
                    id="end_time"
                    onChange={handleScheduleTimeChange}
                    value={hospitalSchedule.end_time ? pickerTimeDisplay(hospitalSchedule.end_time) : undefined}
                    disabled={!!hospitalSheet.discharged_at}
                  />
                </CInputGroup>
              </CCol>
            </CRow>
          )}

          {!hospitalSheet.discharged_at && (
            <CRow>
              <CCol>
                <CButton color="primary" className="me-2" onClick={handleSaveSchedule}>
                  Save
                </CButton>
                <CButton color="secondary" className="me-2" onClick={handleCancel}>
                  Cancel
                </CButton>
                {hospitalSchedule.id && (
                  <CButton color="danger" onClick={() => setShowRemoveScheduleModal(true)}>
                    Remove
                  </CButton>
                )}
              </CCol>
            </CRow>
          )}
        </div>
      </CSidebar>

      {handleRemoveSchedule()}
    </>
  );
};

export default HospitalScheduleForm;
