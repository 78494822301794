import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { CRow, CSmartTable } from '@coreui/react-pro';

import { fetchAppointmentStatuses } from 'api/AppointmentStatuses';
import { fetchClinics } from 'api/Clinics';

import { Appointment } from 'types/Appointment';
import { ListFilter } from 'types/ListFilter';
import { Option } from 'types/Option';
import { calculatePages, Pagination } from 'types/Pagination';

import { compactDateTimeDisplay } from 'utils/dates';
import { toOption } from 'utils/selectOptions';

import Filters from 'components/Filters';
import { Pill } from 'components/Pill';

type Props = {
  appointments?: Appointment[];
  pagination: Pagination;
  filters: ListFilter[];
  handleActivePageChange: (page: number) => void;
  handleFilterChange: (filters: ListFilter[]) => void;
};

export const AppointmentsList = ({
  handleActivePageChange,
  filters,
  appointments,
  pagination,
  handleFilterChange
}: Props) => {
  const columns = [
    {
      key: 'pim_type',
      label: 'Appointment Type',
      _props: { scope: 'col' }
    },
    {
      key: 'start_time',
      label: 'Date/Time',
      _props: { scope: 'col' }
    },
    {
      key: 'clinic_name',
      label: 'Location',
      _props: { scope: 'col' }
    },
    {
      key: 'employee',
      label: 'Doctor/Nurse',
      _props: { scope: 'col' }
    },
    {
      key: 'patient_name',
      label: 'Patient',
      _props: { scope: 'col' }
    },
    {
      key: 'reasons',
      label: 'Reasons',
      _props: { scope: 'col' }
    },
    {
      key: 'status',
      label: 'Status',
      _props: { scope: 'col' }
    }
  ];

  const items = appointments ?? [];

  const [clinicOptions, setClinicOptions] = useState<Option[]>([]);
  const [statusOptions, setStatusOptions] = useState<string[]>([]);
  useEffect(() => {
    fetchClinics((clinics) => {
      setClinicOptions([{ label: 'All', value: '' } as Option].concat(clinics.map(toOption)));
    });

    fetchAppointmentStatuses((statuses) => {
      setStatusOptions([''].concat(statuses.map((status) => status.name)));
    });
  }, []);

  useEffect(() => {
    if (clinicOptions.length > 0 && statusOptions.length > 0 && filters.length === 0) {
      handleFilterChange([
        { name: 'Start Date', key: 'start_date', value: undefined, type: 'date' },
        { name: 'End Date', key: 'end_date', value: undefined, type: 'date' },
        { name: 'Status', key: 'status', value: undefined, type: 'select', options: statusOptions },
        { name: 'Clinic', key: 'clinic_id', value: undefined, type: 'select', options: clinicOptions }
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicOptions, statusOptions, filters]);

  return (
    <CRow className="mb-3">
      <div className="mb-3 d-flex align-items-center justify-content-end">
        <Filters setFilters={handleFilterChange} filters={filters} useRange />
      </div>
      <CSmartTable
        pagination={{ external: true }}
        paginationProps={{ align: 'center', activePage: pagination.page, pages: calculatePages(pagination) }}
        onActivePageChange={handleActivePageChange}
        itemsPerPage={pagination.perPage}
        tableHeadProps={{ color: 'dark' }}
        items={items}
        tableProps={{
          'aria-label': 'Appointments List'
        }}
        columns={columns}
        scopedColumns={{
          start_time: (appointment: Appointment) => (
            <td>
              <Link to={`/appointments/${appointment.id}`}>{compactDateTimeDisplay(appointment.start_time)}</Link>
            </td>
          ),
          patient_name: (appointment: Appointment) => (
            <td>
              <Link to={`/animals/${appointment.animal_id}`}>{appointment.animal.name}</Link>
            </td>
          ),
          pim_type: (appointment: Appointment) => <td>{appointment.appointment_type.name_in_pim}</td>,
          employee: (appointment: Appointment) => <td>{appointment.employee.full_name_with_title}</td>,
          clinic_name: (appointment: Appointment) => <td>{appointment.clinic.name}</td>,
          status: (appointment: Appointment) => (
            <td>
              <Pill label={appointment.status} />
            </td>
          ),
          reasons: (appointment: Appointment) => <td>{appointment.visit_reason_list.join(', ')}</td>
        }}
      />
    </CRow>
  );
};
