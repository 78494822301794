import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Employee } from 'types/Employee';

import { useAuth } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';

import { getLastPath, removeLastPath } from 'utils/sessionManager';

import LoginForm from './LoginForm';

const Login = () => {
  const auth = useAuth();
  const query = useQuery();
  const navigate = useNavigate();

  /**
   * Directs the employee to the following places, in order of priority:
   *
   * 1. A redirect query parameter in the URL: ?redirect=/some-path
   * 2. The path they were trying to visit to visit before logging in
   * 3. A path they were visiting when idle timeout occurred
   * 4. The root path
   *
   * While always clearing the last path visited.
   */
  const handleRedirect = (employee: Employee) => {
    const redirectQuery = query.get('redirect');
    const currentPath = window.location.pathname;
    const lastPath = getLastPath(employee.id);

    let path = '/';
    if (redirectQuery) {
      path = redirectQuery;
    } else if (currentPath !== '/') {
      path = currentPath;
    } else if (lastPath) {
      path = lastPath;
    }

    removeLastPath(employee.id);
    navigate({ pathname: path }, { replace: true });
  };

  const handleSuccess = (employee: Employee) => {
    auth.loginSuccess(employee, handleRedirect);
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-start p-4">
      <LoginForm onSuccess={handleSuccess} />
    </div>
  );
};

export default Login;
