import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import AsyncSelect from 'react-select/async';

import { CButton, CCard, CCol, CForm, CFormSelect, CLoadingButton, CRow } from '@coreui/react-pro';

import { fetchConsultTemplate, fetchConsultTemplates } from 'api/ConsultTemplates';

import { Assessment } from 'types/Assessment';
import { compileConsultTemplate, consultTemplateForm, FormLine } from 'types/ConsultTemplate';
import { Option } from 'types/Option';

import { isEnabled } from 'utils/filters';
import { reactSelectStyles } from 'utils/reactSelect';
import { assessmentToOption, toOption } from 'utils/selectOptions';

import SvgClipboard from 'assets/images/SvgClipboard';

import { ConsultTemplateForm } from 'components/ConsultTemplateForm';
import { FormAuditData } from 'components/FormAuditData';
import { IconLabel } from 'components/IconLabel';
import { RichTextEditor } from 'components/RichTextEditor';

import styles from './AssessmentForm.module.scss';

type Props = {
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  assessment?: Assessment;
  pastAssessments: Assessment[];
};

export const AssessmentForm = ({ loading, hideForm, onSubmit, assessment, pastAssessments }: Props) => {
  const [evaluation, setEvaluation] = useState(assessment?.evaluation ?? '');

  const [templateForm, setTemplateForm] = useState<FormLine[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<Option | null>();
  const [selectedPastAssessment, setSelectedPastAssessment] = useState('');

  const loadConsultTemplates = (inputValue: string, callback: (options: Option[]) => void) => {
    fetchConsultTemplates({
      queryText: inputValue,
      templateType: 'AssessmentTemplate',
      onSuccess: (templates) => {
        callback(templates.map(toOption));
      }
    });
  };

  const loadTemplate = (templateId: string) => {
    fetchConsultTemplate(templateId, (template) => {
      const form = consultTemplateForm(template);
      setTemplateForm(form);

      const newEvalution = compileConsultTemplate(form);
      setEvaluation(newEvalution);
    });
  };

  const handleFormChange = (form: FormLine[]) => {
    setTemplateForm(form);

    const newEvaluation = compileConsultTemplate(form);
    setEvaluation(newEvaluation);
  };

  const clearSelectedTemplate = () => {
    setSelectedTemplate(null);
    setTemplateForm(undefined);
  };

  const clearSelectedPastAssessment = () => {
    setSelectedPastAssessment('');
  };

  const handlePastAssessmentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPastAssessment(event.target.value);

    const pastAssessment = pastAssessments.find((past) => past.id.toString() === event.target.value);
    setEvaluation(pastAssessment?.evaluation ?? '');

    clearSelectedTemplate();
  };

  const handleTemplateChange = (selectedOption: Option | null) => {
    setSelectedTemplate(selectedOption);
    if (selectedOption) {
      loadTemplate(selectedOption.value);
    }

    clearSelectedPastAssessment();
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{assessment ? 'Edit' : 'New'} Assessment</h2>

      {!assessment && (
        <CRow className="mb-3">
          <CCol>
            <label htmlFor="template" className="form-label">
              <IconLabel icon={SvgClipboard} label="Use Template" />
            </label>
            <AsyncSelect<Option>
              id="template"
              name="template"
              aria-label="Use Template"
              placeholder="Type to search..."
              value={selectedTemplate}
              onChange={handleTemplateChange}
              loadingMessage={() => 'Loading...'}
              loadOptions={loadConsultTemplates}
              styles={reactSelectStyles}
              defaultOptions
              isSearchable
            />
          </CCol>

          <CCol>
            <label htmlFor="past_assessment_select" className="form-label">
              <IconLabel icon={SvgClipboard} label="Use Past Assessment" />
            </label>
            <CFormSelect
              id="past_assessment_select"
              value={selectedPastAssessment}
              onChange={handlePastAssessmentChange}
              options={[{ label: '', value: '' }, ...pastAssessments.filter(isEnabled).map(assessmentToOption)]}
            />
          </CCol>
        </CRow>
      )}

      <CCol className="mb-3">
        {templateForm && (
          <CCard className="p-3">
            <ConsultTemplateForm form={templateForm} updateForm={handleFormChange} />
          </CCard>
        )}

        <RichTextEditor
          name="evaluation"
          id="evaluation"
          label="Evaluation"
          value={evaluation}
          hidden={!!templateForm}
          onChange={setEvaluation}
          text="Required"
          required
        />
      </CCol>

      <div className="d-flex align-items-center justify-content-between">
        {assessment && <FormAuditData item={assessment} />}
        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton
            loading={loading}
            color="primary"
            type="submit"
            className={styles.button}
            shape="rounded-pill"
          >
            {assessment ? 'Update' : 'Create'}
          </CLoadingButton>
          <CButton
            type="button"
            shape="rounded-pill"
            className={styles.button}
            color="primary"
            variant="outline"
            onClick={hideForm}
          >
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
