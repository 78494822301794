import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { TemplateSelector } from 'views/consult_templates/TemplateSelector';

import { CButton, CCard, CCol, CForm, CLoadingButton } from '@coreui/react-pro';

import { fetchConsultTemplate } from 'api/ConsultTemplates';

import { ConsultPlan } from 'types/ConsultPlan';
import { compileConsultTemplate, consultTemplateForm, FormLine } from 'types/ConsultTemplate';

import { ConsultTemplateForm } from 'components/ConsultTemplateForm';
import { FormAuditData } from 'components/FormAuditData';
import { RichTextEditor } from 'components/RichTextEditor';

import styles from './ConsultPlanForm.module.scss';

type Props = {
  loading: boolean;
  hideForm: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  consultPlan?: ConsultPlan;
};

export const ConsultPlanForm = ({ loading, hideForm, onSubmit, consultPlan }: Props) => {
  const [plan, setPlan] = useState(consultPlan?.plan ?? '');
  const [planTemplateForm, setPlanTemplateForm] = useState<FormLine[]>();
  const [customerDiscussion, setCustomerDiscussion] = useState(consultPlan?.customer_discussion ?? '');
  const [discussionTemplateForm, setDiscussionTemplateForm] = useState<FormLine[]>();

  const loadPlanTemplate = (templateId: string) => {
    fetchConsultTemplate(templateId, (template) => {
      const form = consultTemplateForm(template);
      setPlanTemplateForm(form);

      const newPlan = compileConsultTemplate(form);
      setPlan(newPlan);
    });
  };

  const planFormChanged = (form: FormLine[]) => {
    setPlanTemplateForm(form);

    const newPlan = compileConsultTemplate(form);
    setPlan(newPlan);
  };

  const loadDiscussionTemplate = (templateId: string) => {
    fetchConsultTemplate(templateId, (template) => {
      const form = consultTemplateForm(template);
      setDiscussionTemplateForm(form);

      const newDiscussion = compileConsultTemplate(form);
      setCustomerDiscussion(newDiscussion);
    });
  };

  const discussionFormChanged = (form: FormLine[]) => {
    setDiscussionTemplateForm(form);

    const newDiscussion = compileConsultTemplate(form);
    setCustomerDiscussion(newDiscussion);
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={onSubmit}>
      <h2 className="mb-3">{consultPlan ? 'Edit' : 'New'} Consult Plan</h2>

      {!consultPlan && (
        <CCol className="mb-3">
          <TemplateSelector templateType="ConsultPlanPlanTemplate" templateChanged={loadPlanTemplate} />
        </CCol>
      )}

      <CCol className="mb-3">
        {planTemplateForm && (
          <CCard className="p-3">
            <ConsultTemplateForm form={planTemplateForm} updateForm={planFormChanged} />
          </CCard>
        )}

        <RichTextEditor
          name="plan"
          id="plan"
          label="Plan"
          value={plan}
          hidden={!!planTemplateForm}
          onChange={setPlan}
        />
      </CCol>

      {!consultPlan && (
        <CCol className="mb-3">
          <TemplateSelector
            templateType="ConsultPlanCustomerDiscussionTemplate"
            templateChanged={loadDiscussionTemplate}
          />
        </CCol>
      )}

      <CCol className="mb-3">
        {discussionTemplateForm && (
          <CCard className="p-3">
            <ConsultTemplateForm form={discussionTemplateForm} updateForm={discussionFormChanged} />
          </CCard>
        )}

        <RichTextEditor
          name="customer_discussion"
          id="customer_discussion"
          label="Customer Discussion"
          value={customerDiscussion}
          hidden={!!discussionTemplateForm}
          onChange={setCustomerDiscussion}
        />
      </CCol>

      <div className="d-flex align-items-center justify-content-between">
        {consultPlan && <FormAuditData item={consultPlan} />}
        <div className={cn('ms-auto d-flex', styles.buttons)}>
          <CLoadingButton
            loading={loading}
            color="primary"
            type="submit"
            className={styles.button}
            shape="rounded-pill"
          >
            {consultPlan ? 'Update' : 'Create'}
          </CLoadingButton>
          <CButton
            type="button"
            shape="rounded-pill"
            className={styles.button}
            color="primary"
            variant="outline"
            onClick={hideForm}
          >
            Close
          </CButton>
        </div>
      </div>
    </CForm>
  );
};
