import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { resetPassword } from '../../api/Authentication';
import { getPasswordStrength } from '../../utils/passwordStrength';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordScore, setPasswordScore] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!password) {
      return;
    }
    const result = getPasswordStrength(password);
    setPasswordScore(result.score);

    const resultFeedback = result.feedback.warning || result.feedback.suggestions.join(' ');
    setPasswordStrength(`Password too weak. ${resultFeedback}`);
  }, [password]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!token) {
      return;
    }
    setErrorMessage('');
    setLoading(true);
    resetPassword(token, password, confirmPassword, {
      onSuccess: () => {
        setLoading(false);
        navigate('/login');
      },
      onError: () => {
        setLoading(false);
        setErrorMessage('Failed to update password.');
      }
    });
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-start p-4">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol xs={6}>
            <CCard>
              <CCardBody>
                <CForm onSubmit={handleSubmit} autoComplete="off" className="d-flex flex-column align-items-center">
                  <h5 className="mb-3">Enter your new password</h5>
                  <CInputGroup className="mb-4 d-flex align-items-center">
                    <CFormLabel htmlFor="password" style={{ textAlign: 'right', width: '150px' }} className="pe-2">
                      Password
                    </CFormLabel>
                    <CFormInput
                      type="password"
                      id="password"
                      placeholder="New password"
                      autoComplete="off"
                      value={password}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      feedbackInvalid={passwordStrength}
                      valid={!!password && passwordScore >= 2}
                      invalid={!!password && passwordScore < 2}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-5 d-flex align-items-center">
                    <CFormLabel
                      htmlFor="confirmPassword"
                      style={{ textAlign: 'right', width: '150px' }}
                      className="pe-2"
                    >
                      Confirm Password
                    </CFormLabel>
                    <CFormInput
                      type="password"
                      id="confirmPassword"
                      placeholder="Confirm new password"
                      autoComplete="off"
                      value={confirmPassword}
                      required
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      feedbackInvalid={'Passwords do not match.'}
                      valid={!!password && !!confirmPassword && confirmPassword === password}
                      invalid={!!password && !!confirmPassword && confirmPassword !== password}
                    />
                  </CInputGroup>
                  {errorMessage.length > 0 && <div className="text-danger mb-3">{errorMessage}</div>}
                  <CLoadingButton color="primary" type="submit" className="mb-4" loading={loading}>
                    Save Password
                  </CLoadingButton>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ResetPassword;
