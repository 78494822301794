import * as React from 'react';
import { useState } from 'react';
import Select from 'react-select';

import {
  CButton,
  CCol,
  CDatePicker,
  CForm,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CRow
} from '@coreui/react-pro';

import { Animal } from 'types/Animal';
import { Context } from 'types/Context';
import { Option } from 'types/Option';
import { topics } from 'types/Topic';

import { getPaddedTime } from 'utils/dates';
import { reactSelectStyles } from 'utils/reactSelect';
import { contextToOption, OptionWithTopic, toOption } from 'utils/selectOptions';

type Props = {
  animals?: Animal[]; // All animals the user could log about
  subjectAnimal?: Animal; // Animal in context, e.g. this form is loaded from the animal's page and we want to pre-select that animal
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  contexts: Context[];
};

export const LogForm = ({ animals, subjectAnimal, onSubmit, hideModal, loading, contexts }: Props) => {
  const [contextOptions] = useState<OptionWithTopic[]>([
    { label: '', value: '', topic: '' },
    ...contexts.map(contextToOption)
  ]);

  const [selectedContextOption, setSelectedContextOption] = useState<Option | null | undefined>(null);
  const [selectedTopic, setSelectedTopic] = useState('');

  const [time, setTime] = useState(getPaddedTime(new Date()));
  const [date, setDate] = useState<Date | null>(new Date());

  const selectedContext = contexts.find((context) => contextToOption(context).value === selectedContextOption?.value);

  const isTopicDisabled = !!(selectedContextOption && selectedContextOption.value !== '');

  const handleContextChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = contextOptions.find((c) => c.value === event.target.value);
    setSelectedContextOption(selected);

    if (selected?.topic) {
      setSelectedTopic(selected.topic);
    }
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = event.target.value;
    setTime(newTime);

    if (date) {
      const newDate = new Date(date);
      const [hours, minutes] = newTime.split(':');

      newDate.setHours(Number(hours));
      newDate.setMinutes(Number(minutes));

      setDate(newDate);
    }
  };

  const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTopic(event.target.value);
  };

  const maybeDefaultAnimal = () => {
    let defaultAttrs = {};
    if (animals && animals.length === 1) {
      defaultAttrs = { defaultValue: [toOption(animals[0])], isDisabled: true };
    }

    if (subjectAnimal) {
      defaultAttrs = { defaultValue: [toOption(subjectAnimal)] };
    }

    return defaultAttrs;
  };

  return (
    <CForm className="g-3" onSubmit={onSubmit}>
      <CRow className="mb-2">
        <CCol>
          <CFormInput hidden name="sent_at" value={date?.toISOString()} />
          <CDatePicker
            label="Date"
            firstDayOfWeek={0}
            onDateChange={setDate}
            date={date}
            text="Required"
            cleaner={false}
            required
          />
        </CCol>
        <CCol>
          <CFormInput label="Time" type="time" onChange={handleTimeChange} value={time} text="Required" required />
        </CCol>
      </CRow>

      {animals && (
        <div className="mb-2">
          {animals.length === 1 && <CFormInput type="hidden" name="animal_ids" value={animals[0].id} />}
          <label className="form-label">Animal</label>
          <Select
            name="animal_ids"
            isMulti
            styles={reactSelectStyles}
            options={animals.map(toOption)}
            {...maybeDefaultAnimal()}
            delimiter=","
            required
          />
        </div>
      )}

      <div className="mb-2">
        <CFormInput type="hidden" name="topic" value={selectedTopic} />
        <CFormSelect
          id="topic"
          disabled={isTopicDisabled}
          label="Topic"
          onChange={handleTopicChange}
          options={['', ...topics]}
          text="Required"
          value={selectedTopic}
          required
        />
      </div>

      <div className="mb-2">
        <CFormInput type="hidden" name="context_id" value={selectedContext?.context_id ?? ''} />
        <CFormInput type="hidden" name="context_type" value={selectedContext?.context_type ?? ''} />
        <CFormSelect id="context" label="Context" value={selectedContextOption?.value} onChange={handleContextChange}>
          {contextOptions.map((option: Option) => (
            <option key={`${option.value}-${option.label}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </CFormSelect>
      </div>

      <div className="mb-2">
        <CFormSelect
          name="communication_type"
          id="communication_type"
          label="Communication Type"
          options={[
            { label: '', value: '' },
            { label: 'In-Person', value: 'in_person' },
            { label: 'Phone', value: 'phone' }
          ]}
          required
          text="Required"
        />
      </div>

      <div className="mb-2">
        <CFormTextarea id="body" label="Body" name="body" rows={3} text="Required" required />
      </div>

      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
        <CLoadingButton loading={loading} color="primary" shape="rounded-pill" type="submit">
          Log
        </CLoadingButton>
        <CButton variant="outline" type="button" shape="rounded-pill" onClick={hideModal}>
          Cancel
        </CButton>
      </div>
    </CForm>
  );
};
