import * as React from 'react';

import { FormLine, FormLineOption } from 'types/ConsultTemplate';

import { ConsultTemplateFormItem } from './ConsultTemplateFormItem';

export const ConsultTemplateForm = ({
  form,
  updateForm
}: {
  form: FormLine[];
  updateForm: (form: FormLine[]) => void;
}) => {
  const updateItem = (formItem: FormLine, option: FormLineOption) => {
    // Find the right FormLine
    const index = form.findIndex((item) => {
      const hasSameOptions = item.options.find((o: FormLineOption) => o.tabIndex === option.tabIndex);
      return item.label === formItem.label && hasSameOptions;
    });

    if (index > -1 && form) {
      const newPreviewForm = [...form];
      // Find the right option in the FormLine
      const optionIndex = newPreviewForm[index].options.findIndex((item) => {
        return item.label === option.label;
      });

      // Replace and update the form, letting parent know
      if (optionIndex > -1 && newPreviewForm[index].options[optionIndex]) {
        newPreviewForm[index].options[optionIndex] = option;
        updateForm(newPreviewForm);
      }
    }
  };

  return (
    <div>
      {form.map((formLine, index) => (
        <ConsultTemplateFormItem key={index} lineIndex={index} formLine={formLine} updateItem={updateItem} />
      ))}
    </div>
  );
};
