import { useRollbarPerson } from '@rollbar/react';
import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

export const ProtectedRoute = () => {
  const auth = useAuth();
  const location = useLocation();
  useRollbarPerson({ user: { id: auth.employee?.id, email: auth.employee?.email } });

  if (!auth.employee) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};
