import * as React from 'react';

import { snakeCaseToTitleCase } from 'utils/strings';

// Takes an object and renders a list of its keys and values, filtering out falsy values. Use this to put data on the
// page when a more final design is not yet available.
export const List = ({ data }: { data: Record<string, unknown> }) => {
  const filtered = Object.entries(data).filter(([_, value]) => value);

  return (
    <ul>
      {filtered.map(([key, value]) => (
        <li key={key}>
          {snakeCaseToTitleCase(key)}: {String(value)}
        </li>
      ))}
    </ul>
  );
};
