import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';

import { fetchHealthPlanProduct, updateHealthPlanProduct } from 'api/HealthPlanProduct';
import { fetchHealthPlan } from 'api/HealthPlans';

import { HealthPlan } from 'types/HealthPlan';
import { HealthPlanProduct } from 'types/HealthPlanProduct';

import ProductForm from './ProductForm';

const EditProduct = (): JSX.Element => {
  const navigate = useNavigate();
  type DetailsParams = {
    planId: string;
    productId: string;
  };
  const { planId, productId } = useParams<keyof DetailsParams>() as DetailsParams;
  const [healthPlan, setHealthPlan] = React.useState<HealthPlan>();
  const [healthPlanProduct, setHealthPlanProduct] = React.useState<HealthPlanProduct>({} as HealthPlanProduct);

  useEffect(() => {
    fetchHealthPlan(planId, setHealthPlan);
    fetchHealthPlanProduct(Number(productId), setHealthPlanProduct);
  }, [planId, productId]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateHealthPlanProduct(Number(productId), healthPlanProduct, handleResolve);
  };

  const handleResolve = () => {
    navigate(`/health_plans/${healthPlan?.id}`);
  };

  return (
    <div>
      {healthPlan && (
        <CCard style={{ width: '35rem' }}>
          <CCardHeader>
            <h3>Edit Product on Plan</h3>
          </CCardHeader>
          <CCardBody>
            <ProductForm
              handleSubmit={handleSubmit}
              handleCancel={handleResolve}
              setHealthPlanProduct={setHealthPlanProduct}
              healthPlan={healthPlan}
              healthPlanProduct={healthPlanProduct}
            />
          </CCardBody>
        </CCard>
      )}
    </div>
  );
};

export default EditProduct;
