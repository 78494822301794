// Nests FormData object from a form under a key, allowing us to send FormData (with files) to a Rails API using strong
// parameters.
export const appendToFormData = (
  formData: FormData,
  strongParameterKey: string,
  data: Record<string, unknown>
): FormData => {
  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      const fullKey = `${strongParameterKey}[${key}]`;
      formData.append(fullKey, value.toString());
    }
  });

  return formData;
};

export const nestFormData = (originalFormData: FormData, keyName: string): FormData => {
  const newFormData = new FormData();

  originalFormData.forEach((value, key) => {
    if (key.indexOf('attachments') !== -1) {
      newFormData.append(key, value);
    } else {
      newFormData.append(`${keyName}[${key}]`, value);
    }
  });

  return newFormData;
};
