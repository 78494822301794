import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { CButton } from '@coreui/react-pro';

import { ContextType } from 'types/ContextType';
import { ContextItem, EmployeeTaskType } from 'types/EmployeeTask';
import { Option } from 'types/Option';

import { ReactComponent as PlusThinIcon } from 'assets/images/plus-thin.svg';

import styles from './NewTaskButton.module.scss';

import { NewTaskModal } from './NewTaskModal';

type Props = {
  buttonText: string;
  disablePresets?: boolean;
  initialReason?: EmployeeTaskType;
  contextItem?: ContextItem;
  contextType?: ContextType;
  contextOptions?: Option[];
  animal_id?: number;
  clinic_id?: number;
  consult_id?: number;
  size?: 'sm' | 'lg';
  callback?: () => void;
};

export const NewTaskButton = ({
  buttonText,
  disablePresets,
  initialReason,
  contextItem,
  contextType,
  contextOptions,
  animal_id,
  clinic_id,
  consult_id,
  size,
  callback
}: Props) => {
  const [callbackModalVisible, setCallbackModalVisible] = useState<boolean>();

  return (
    <>
      <CButton
        size={size}
        variant="outline"
        shape="rounded-pill"
        className="d-flex align-items-center text-nowrap"
        onClick={() => {
          setCallbackModalVisible(true);
        }}
      >
        <PlusThinIcon className={cn(styles.icon, styles.primary)} />
        <span className="ms-1">{buttonText}</span>
      </CButton>

      {callbackModalVisible ? (
        <NewTaskModal
          animal_id={animal_id}
          clinic_id={clinic_id}
          consult_id={consult_id}
          disablePresets={disablePresets}
          initialReason={initialReason}
          hideModal={() => setCallbackModalVisible(false)}
          contextItem={contextItem}
          contextType={contextType}
          contextOptions={contextOptions}
          callback={callback}
        />
      ) : null}
    </>
  );
};
