import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { paths } from 'routes';
import { EditDiagnosticResultModal } from 'views/consults/EditDiagnosticResultModal';
import { NewTaskButton } from 'views/employee_tasks/NewTaskButton';

import { cilChartLine, cilNotes } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CBadge,
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSmartTable,
  CSpinner
} from '@coreui/react-pro';

import {
  fetchDiagnosticResults,
  fetchUnassignedDiagnosticResults,
  updateDiagnosticResult
} from 'api/DiagnosticResults';
import { fetchEmployeesByQuery } from 'api/Employees';

import { DiagnosticResult, DiagnosticStatus } from 'types/DiagnosticResult';
import { DiagnosticResultItem } from 'types/DiagnosticResultItem';
import { Option } from 'types/Option';
import { calculatePages, Pagination } from 'types/Pagination';

import { useAuth } from 'hooks/useAuth';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

import { ClinicContext } from 'contexts/ClinicContext';

import { compactDateTimeDisplay } from 'utils/dates';
import { appendToFormData, nestFormData } from 'utils/formData';
import { employeeToOption } from 'utils/selectOptions';

type LoadState = 'initial' | `status-update-${number}`;
const MIN_QUERY_LENGTH = 3;

const DiagnosticsQueue = (): JSX.Element => {
  const auth = useAuth();
  const [loadState, setLoadState] = useState<LoadState>();
  const [resultsPagination, setResultsPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 0 });
  const [unassignedPagination, setUnassignedPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 0 });
  const [diagnosticResults, setDiagnosticResults] = useState<DiagnosticResult[]>();
  const [unassignedResults, setUnassignedResults] = useState<DiagnosticResult[]>();
  const [editModalVisible, setEditModalVisible] = useState<boolean>();
  const [statusFilter, setStatusFilter] = useState<DiagnosticStatus>('complete');
  const [editingItem, setItemBeingEdited] = useState<DiagnosticResult>();
  const [isNetworkRequestInProgress, setIsNetworkRequestInProgress] = useState(false);
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<Option | null>(
    auth.employee?.vet ? employeeToOption(auth.employee) : null
  );
  type Tab = 'results' | 'unassigned';
  const [activeTab, setActiveTab] = useState<Tab>('results');

  useDocumentTitle('Diagnostics Queue');

  const loadEmployeeOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (inputValue.length < MIN_QUERY_LENGTH) return;

    fetchEmployeesByQuery(inputValue).then((options) => {
      callback(options.map(employeeToOption));
    });
  };

  const handleEmployeeSelectChange = (option: Option | null) => {
    setSelectedEmployeeOption(option);
  };

  const loadingMessage = (input: { inputValue: string }) => {
    if (input.inputValue.length < MIN_QUERY_LENGTH) {
      return `Type at least ${MIN_QUERY_LENGTH} characters to search...`;
    } else {
      return 'Loading...';
    }
  };

  const { clinicContext } = useContext(ClinicContext);
  useEffect(() => {
    setLoadState('initial');

    fetchDiagnosticResults(
      { status: 'complete' },
      {
        onSuccess: handleFetchSuccess,
        setPagination: setResultsPagination
      }
    );

    fetchUnassignedDiagnosticResults(
      { status: 'complete' },
      {
        onSuccess: handleFetchUnassignedSuccess,
        setPagination: setUnassignedPagination
      }
    );
  }, [clinicContext]);

  const handleFetchSuccess = (diagnosticResults: DiagnosticResult[]) => {
    setDiagnosticResults(diagnosticResults);
    setLoadState(undefined);
  };

  const handleFetchUnassignedSuccess = (diagnosticResults: DiagnosticResult[]) => {
    setUnassignedResults(diagnosticResults);
    setLoadState(undefined);
  };

  const handleSuccess = (message: string) => {
    toast.success(message);
    setEditModalVisible(false);
    setIsNetworkRequestInProgress(false);
    setLoadState('initial');
    fetchDiagnosticResults(
      { status: statusFilter },
      {
        onSuccess: handleFetchSuccess,
        setPagination: setResultsPagination,
        page: resultsPagination.page
      }
    );
    fetchUnassignedDiagnosticResults(
      { status: statusFilter },
      {
        onSuccess: handleFetchUnassignedSuccess,
        setPagination: setUnassignedPagination,
        page: unassignedPagination.page
      }
    );
  };

  const handleError = () => {
    setLoadState(undefined);
  };

  const handleFilterChange = ({ status }: { status: DiagnosticStatus }) => {
    setLoadState('initial');

    setStatusFilter(status);

    fetchDiagnosticResults(
      { status: status },
      {
        onSuccess: handleFetchSuccess,
        setPagination: setResultsPagination
      }
    );
  };

  const handlePageChange = (page: number) => {
    setResultsPagination((prev) => ({ ...prev, page }));
    setLoadState('initial');

    fetchDiagnosticResults(
      { status: statusFilter },
      {
        onSuccess: handleFetchSuccess,
        setPagination: setResultsPagination,
        page: page
      }
    );
  };

  function formDataToDiagnosticResultItems(formData: FormData) {
    const nestedName = (index: number, name: string) => `diagnostic_result_items_attributes[${index}][${name}]`;
    let index = 0;
    const diagnosticItems = [];
    while (formData.has(nestedName(index, 'name'))) {
      const item: Partial<DiagnosticResultItem> = {
        name: formData.get(nestedName(index, 'name'))?.toString(),
        value: formData.get(nestedName(index, 'value'))?.toString(),
        unit: formData.get(nestedName(index, 'unit'))?.toString(),
        qualifier: formData.get(nestedName(index, 'qualifier'))?.toString(),
        range_low: formData.get(nestedName(index, 'range_low'))?.toString(),
        range_high: formData.get(nestedName(index, 'range_high'))?.toString()
      };
      const id = formData.get(nestedName(index, 'id'));
      if (id) {
        item.id = Number(id);
      }
      diagnosticItems.push(item);
      index++;
    }
    return diagnosticItems;
  }

  const handleUpdateDiagnosticResult = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setIsNetworkRequestInProgress(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const diagnosticItems = formDataToDiagnosticResultItems(formData);

    const formJson: {
      employee_id?: string;
      customer_id?: string;
      animal_id?: string;
      consult_id?: string;
      status: DiagnosticStatus;
      specifics: string | undefined;
      outcome: string | undefined;
      patient_notes: string | undefined;
      diagnostic_result_items_attributes: Partial<DiagnosticResultItem>[];
      diagnostic_request_id?: number;
    } = {
      employee_id: formData.get('employee_id')?.toString(),
      customer_id: formData.get('customer_id')?.toString(),
      animal_id: formData.get('animal_id')?.toString(),
      consult_id: formData.get('consult_id')?.toString(),
      status: formData.get('status')?.toString() as DiagnosticStatus,
      specifics: formData.get('specifics')?.toString(),
      outcome: formData.get('outcome')?.toString(),
      patient_notes: formData.get('patient_notes')?.toString(),
      diagnostic_result_items_attributes: diagnosticItems
    };

    if (formData.get('diagnostic_request_id')) {
      formJson.diagnostic_request_id = Number(formData.get('diagnostic_request_id'));
    }

    appendToFormData(formData, 'diagnostic_result', formJson);

    updateDiagnosticResult(editingItem.id, nestFormData(formData, 'diagnostic_result'), {
      onSuccess: () => {
        handleSuccess('Diagnostic Result updated!');
      },
      onError: () => handleError
    });
  };

  const resultList = () => (
    <CForm onSubmit={(e) => e.preventDefault()}>
      <CRow className="mb-3">
        <CCol>
          <label className="form-label">Status</label>
          <CFormSelect
            aria-label="Status"
            value={statusFilter}
            onChange={(e) => handleFilterChange({ status: e.target.value as DiagnosticStatus })}
          >
            <option value="complete">Complete</option>
            <option value="released">Released</option>
            <option value="disabled">Disabled</option>
          </CFormSelect>
        </CCol>
        <CCol>
          <CFormInput hidden id="employee_id" name="employee_id" value={selectedEmployeeOption?.value ?? ''} />
          <label className="form-label">Employee</label>
          <AsyncSelect<Option>
            aria-label="Employee"
            placeholder="Type to search..."
            value={selectedEmployeeOption}
            onChange={handleEmployeeSelectChange}
            loadingMessage={loadingMessage}
            loadOptions={loadEmployeeOptions}
            isClearable
            isSearchable
          />
        </CCol>
      </CRow>
      <CSmartTable
        tableHeadProps={{ color: 'dark' }}
        items={activeTab === 'results' ? diagnosticResults : unassignedResults}
        columns={[
          { key: 'created_at', label: 'Created', _style: { width: '15%' } },
          { key: 'updated_at', label: 'Updated', _style: { width: '15%' } },
          { key: 'diagnostic_name', label: 'Diagnostic' },
          { key: 'client', label: 'Client' },
          { key: 'animal', label: 'Animal' },
          { key: 'consult', label: 'Consult' },
          { key: 'employee', label: 'Employee' },
          { key: 'actions', label: 'Actions', _style: { width: '20%' } }
        ]}
        loading={loadState === 'initial'}
        elementCover={<CSpinner color="primary" />}
        itemsPerPage={25}
        pagination={{ external: true }}
        paginationProps={{
          activePage: resultsPagination.page,
          pages: calculatePages(resultsPagination),
          align: 'center'
        }}
        onActivePageChange={(activePage) => handlePageChange(activePage)}
        scopedColumns={{
          created_at: (diagnosticResult: DiagnosticResult) => (
            <td>{compactDateTimeDisplay(diagnosticResult.created_at)}</td>
          ),
          updated_at: (diagnosticResult: DiagnosticResult) => (
            <td>
              {diagnosticResult.patient_notes ? <CIcon aria-hidden icon={cilNotes} className="me-2" /> : null}
              {compactDateTimeDisplay(diagnosticResult.updated_at)}
            </td>
          ),
          diagnostic_name: (diagnosticResult: DiagnosticResult) => <td>{diagnosticResult.diagnostic_name}</td>,
          client: (diagnosticResult: DiagnosticResult) => (
            <td>
              {diagnosticResult.consult?.animal?.customer && (
                <Link to={generatePath(paths.customerDetails, { id: diagnosticResult.consult.animal.customer.id })}>
                  {diagnosticResult.consult.animal.customer.first_name}{' '}
                  {diagnosticResult.consult.animal.customer.last_name}
                </Link>
              )}
            </td>
          ),
          animal: (diagnosticResult: DiagnosticResult) => (
            <td>
              {diagnosticResult.consult?.animal && (
                <Link to={generatePath(paths.animalDetails, { id: diagnosticResult.consult.animal.id })}>
                  {diagnosticResult.consult.animal.name}
                </Link>
              )}
            </td>
          ),
          consult: (diagnosticResult: DiagnosticResult) => (
            <td>
              {diagnosticResult.consult && (
                <Link to={generatePath(paths.consultDetails, { id: diagnosticResult.consult.id })}>
                  {diagnosticResult.consult.id}
                </Link>
              )}
            </td>
          ),
          employee: (diagnosticResult: DiagnosticResult) => (
            <td>
              {diagnosticResult.employee && (
                <Link to={generatePath(paths.employeeDetails, { id: diagnosticResult.employee.id })}>
                  {diagnosticResult.employee.full_name_with_title}
                </Link>
              )}
            </td>
          ),
          actions: (diagnosticResult: DiagnosticResult) => (
            <td className="d-flex">
              <CButton
                size="sm"
                color="primary"
                className="me-2"
                onClick={() => {
                  setItemBeingEdited(diagnosticResult);
                  setEditModalVisible(true);
                }}
              >
                Edit
              </CButton>
              <NewTaskButton
                size="sm"
                buttonText="Callback"
                disablePresets
                contextItem={diagnosticResult}
                contextType="DiagnosticResult"
                initialReason="CallbackTask"
                animal_id={diagnosticResult.animal_id || undefined}
                clinic_id={diagnosticResult.clinic_id || undefined}
                consult_id={diagnosticResult.consult_id || undefined}
              />
            </td>
          )
        }}
      />
    </CForm>
  );

  const unassignedList = () => (
    <CForm onSubmit={(e) => e.preventDefault()}>
      <CSmartTable
        tableHeadProps={{ color: 'dark' }}
        items={activeTab === 'results' ? diagnosticResults : unassignedResults}
        columns={[
          { key: 'date', label: 'Date', _style: { width: '15%' } },
          { key: 'reference_number', label: 'Reference Number' },
          { key: 'outcome', label: 'Outcome' },
          { key: 'actions', label: 'Actions', _style: { width: '5%' } }
        ]}
        loading={loadState === 'initial'}
        elementCover={<CSpinner color="primary" />}
        itemsPerPage={25}
        pagination={{ external: true }}
        paginationProps={{
          activePage: resultsPagination.page,
          pages: calculatePages(resultsPagination),
          align: 'center'
        }}
        onActivePageChange={(activePage) => handlePageChange(activePage)}
        scopedColumns={{
          date: (diagnosticResult: DiagnosticResult) => <td>{compactDateTimeDisplay(diagnosticResult.created_at)}</td>,
          reference_number: (diagnosticResult: DiagnosticResult) => <td>{diagnosticResult.reference_number}</td>,
          outcome: (diagnosticResult: DiagnosticResult) => (
            <td style={{ maxHeight: '100px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {diagnosticResult.outcome}
            </td>
          ),
          actions: (diagnosticResult: DiagnosticResult) => (
            <td>
              <CButton
                size="sm"
                color="primary"
                onClick={() => {
                  setItemBeingEdited(diagnosticResult);
                  setEditModalVisible(true);
                }}
              >
                Edit
              </CButton>
            </td>
          )
        }}
      />
    </CForm>
  );

  return (
    <>
      <h4 className="pb-2">
        <CIcon className="me-2 icon-xl" icon={cilChartLine} aria-hidden />
        Diagnostic Results
      </h4>
      {editModalVisible && editingItem !== undefined ? (
        <EditDiagnosticResultModal
          diagnosticResult={editingItem}
          hideForm={() => setEditModalVisible(false)}
          loading={isNetworkRequestInProgress}
          onSubmit={handleUpdateDiagnosticResult}
          employee={auth.employee}
        />
      ) : null}

      <CNav role="list" variant="tabs" className="mb-2">
        <CNavItem>
          <CNavLink href="#" active={activeTab === 'results'} onClick={() => setActiveTab('results')}>
            Results Queue
            {resultsPagination.total > 0 && (
              <CBadge color="dark" className="ms-2">
                {resultsPagination.total}
              </CBadge>
            )}
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink active={activeTab === 'unassigned'} onClick={() => setActiveTab('unassigned')}>
            Unassigned
            {unassignedPagination.total > 0 && (
              <CBadge color="dark" className="ms-2">
                {unassignedPagination.total}
              </CBadge>
            )}
          </CNavLink>
        </CNavItem>
      </CNav>
      {activeTab === 'results' ? resultList() : unassignedList()}
    </>
  );
};

export default DiagnosticsQueue;
