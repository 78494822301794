import * as React from 'react';
import { Fragment } from 'react';

import { CSmartTable } from '@coreui/react-pro';

import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { DiagnosticRequestItem } from 'types/DiagnosticRequestItem';
import { DiagnosticResultItem } from 'types/DiagnosticResultItem';

import { DiagnosticResultAttachments } from 'components/DiagnosticResultAttachments';
import { List } from 'components/List';
import { Pill } from 'components/Pill';

const applyResultStyles = (item: DiagnosticResultItem) => {
  const isLower = item.range_low && Number(item.value) < Number(item.range_low);
  const isHigher = item.range_high && Number(item.value) > Number(item.range_high);

  return { ...item, ...((isLower || isHigher) && { _props: { color: 'danger' } }) };
};

export const DiagnosticRequestDetails = ({ item }: { item: DiagnosticRequest }) => {
  const formatAttributes = (data: DiagnosticRequestItem) => {
    return { name: data.diagnostic.name, code: data.diagnostic.code, reference_number: item.reference_number };
  };

  return (
    <>
      {item.diagnostic_request_items.map((requestItem: DiagnosticRequestItem) => (
        <Fragment key={requestItem.id}>
          <div className="mb-2 fw-semibold">Request Summary</div>
          <List key={requestItem.id} data={formatAttributes(requestItem)} />
        </Fragment>
      ))}

      {item.diagnostic_result?.diagnostic_result_items && (
        <>
          <div className="d-flex align-items-center mb-2">
            <div className="me-2 fw-semibold">Result Summary</div>
            <Pill label={item.diagnostic_result.status} />
          </div>

          {item.diagnostic_result.attachments && (
            <DiagnosticResultAttachments attachments={item.diagnostic_result.attachments} />
          )}

          <CSmartTable
            columns={['name', 'value', 'unit', 'range_low', 'range_high', 'qualifier']}
            items={item.diagnostic_result.diagnostic_result_items.map(applyResultStyles)}
            tableHeadProps={{ color: 'dark' }}
            tableProps={{ caption: 'Diagnostic results, with out-of-range values in red' }}
            itemsPerPage={item.diagnostic_result.diagnostic_result_items.length}
          />
        </>
      )}
    </>
  );
};
