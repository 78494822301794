import * as React from 'react';

import {
  CButton,
  CCloseButton,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSidebar,
  CSidebarHeader
} from '@coreui/react-pro';

import { Clinic } from 'types/Clinic';
import { HospitalEvent } from 'types/HospitalEvent';
import { HospitalSheet } from 'types/HospitalSheet';

import { timeDisplay } from 'utils/dates';

type AnesthesiaEventFormProps = {
  handleSubmit: (event: Partial<HospitalEvent>[]) => void;
  handleCancel: () => void;
  setEvents: (event: Partial<HospitalEvent>[] | undefined) => void;
  hospitalSheet: HospitalSheet;
  hospitalEvents: Partial<HospitalEvent>[];
  currentClinic: Clinic;
  activeTime: string;
};

const AnesthesiaEventForm = ({
  handleSubmit,
  handleCancel,
  setEvents,
  hospitalSheet,
  hospitalEvents,
  currentClinic,
  activeTime
}: AnesthesiaEventFormProps): JSX.Element => {
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(hospitalEvents);
  };

  return (
    <>
      <CSidebar
        colorScheme="light"
        overlaid
        placement="end"
        size="xl"
        visible={true}
        style={{ overflowY: 'scroll', marginRight: '0' }}
        className="d-flex justify-content-between flex-column"
      >
        <div>
          <CSidebarHeader className="bg-transparent">
            <h5 className="text-center">
              {timeDisplay(activeTime, { timezone: currentClinic?.time_zone })}
              <CCloseButton className="float-end" onClick={handleCancel} />
            </h5>
          </CSidebarHeader>

          <CForm onSubmit={submitForm}>
            <div style={{ padding: '0px 20px' }}>
              {hospitalEvents.map((event) => (
                <CRow className="mb-3" key={event.hospital_event_schedule_id}>
                  <CCol className="d-flex align-items-center gap-3">
                    <CInputGroup>
                      <CInputGroupText style={{ width: '40%' }}>{event.hospital_event_schedule?.title}</CInputGroupText>
                      <CFormInput
                        type={event.hospital_event_schedule?.group === 'anesthesia' ? 'number' : 'text'}
                        step={0.01}
                        id={event.hospital_event_schedule?.title}
                        value={event.value}
                        onChange={(e) => {
                          const newEvents = hospitalEvents.map((he) => {
                            if (he.hospital_event_schedule_id === event.hospital_event_schedule_id) {
                              return { ...he, value: e.target.value };
                            }
                            return he;
                          });
                          setEvents(newEvents);
                        }}
                      />
                    </CInputGroup>
                  </CCol>
                </CRow>
              ))}
            </div>

            {!hospitalSheet.discharged_at && (
              <CRow>
                <CCol className="d-flex justify-content-end gap-2" style={{ padding: '0px 20px' }}>
                  <CButton variant="outline" type="button" onClick={handleCancel}>
                    Cancel
                  </CButton>
                  <CButton color="primary" className="me-2" type="submit">
                    {hospitalEvents[0].id ? 'Update' : 'Create'}
                  </CButton>
                </CCol>
              </CRow>
            )}
          </CForm>
        </div>
      </CSidebar>
    </>
  );
};

export default AnesthesiaEventForm;
