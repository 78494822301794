import axios from 'axios';

import { Role } from 'types/Role';

const fetchRoles = (onSuccess: (roles: Role[]) => void) => {
  const url = `/pim/roles`;
  axios.get<Role[]>(url).then((data) => onSuccess(data.data.record));
};

export { fetchRoles };
