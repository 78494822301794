import axios from 'axios';

import { Procedure } from 'types/Procedure';

const fetchProceduresByAnimal = (animalId: string, onSuccess: (procedure: Procedure[]) => void) => {
  const url = `/pim/procedures?animal_id=${animalId}`;
  axios.get<Procedure[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchProceduresForConsult = (consultId: number | string, onSuccess: (procedures: Procedure[]) => void) => {
  const url = `/pim/procedures`;
  axios.get<Procedure[]>(url, { params: { consult_id: consultId } }).then((data) => onSuccess(data.data.record));
};

const createProcedure = (
  params: Partial<Procedure>,
  { onSuccess, onError }: { onSuccess: (procedure: Procedure) => void; onError: (error: string) => void }
) => {
  const url = `/pim/procedures`;
  axios
    .post<Procedure>(url, { procedure: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError(error.message));
};

const updateProcedure = (
  procedureId: number,
  params: Partial<Procedure>,
  { onSuccess, onError }: { onSuccess: (procedure: Procedure) => void; onError: (error: string) => void }
) => {
  const url = `/pim/procedures/${procedureId}`;
  axios
    .put<Procedure>(url, { procedure: params })
    .then((data) => onSuccess(data.data.record))
    .catch((error) => onError(error.message));
};

export { fetchProceduresForConsult, fetchProceduresByAnimal, createProcedure, updateProcedure };
