import * as React from 'react';

type Props = {
  icon: React.ElementType;
  label: string;
};

// Component to display an icon and a label together for our form fields.
// Wrap it in a `<label>, `<CFormLabel />`, or (best choice) pass it as the `label` prop to a CoreUI form field.
export const IconLabel = ({ icon: Icon, label }: Props) => {
  return (
    <div className="d-flex align-items-center">
      <Icon className="primaryColor" height={14} style={{ marginRight: '10px' }} aria-hidden />
      <span>{label}</span>
    </div>
  );
};
