import * as React from 'react';
import type { SVGProps } from 'react';

const SvgMicroChip = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M17.628 6.296a.36.36 0 0 0 .361-.36V4.854a.36.36 0 0 0-.36-.361H16.19v-.9c0-.991-.807-1.799-1.799-1.799h-.899V.361A.36.36 0 0 0 13.131 0h-1.08a.36.36 0 0 0-.361.361V1.8h-1.8V.36A.36.36 0 0 0 9.53 0H8.45a.36.36 0 0 0-.362.361V1.8H6.29V.36A.36.36 0 0 0 5.928 0h-1.08c-.188 0-.35.16-.35.361V1.8h-.9c-.992 0-1.8.807-1.8 1.799v.9H.362a.36.36 0 0 0-.361.36v1.08c0 .199.16.362.361.362H1.8v1.799H.36A.36.36 0 0 0 0 8.46v1.08c0 .198.16.361.361.361H1.8v1.8H.36a.36.36 0 0 0-.361.36v1.08c0 .199.16.362.361.362H1.8v.9c0 .99.807 1.798 1.799 1.798h.9v1.438c0 .198.159.361.36.361h1.08a.36.36 0 0 0 .362-.361V16.2h1.799v1.438c0 .198.16.361.361.361h1.08a.36.36 0 0 0 .361-.361V16.2h1.8v1.438c0 .198.159.361.36.361h1.08a.36.36 0 0 0 .362-.361V16.2h.9c.99 0 1.798-.807 1.798-1.799v-.91h1.438a.36.36 0 0 0 .361-.361v-1.08a.36.36 0 0 0-.361-.361H16.2v-1.8h1.438A.36.36 0 0 0 18 9.53V8.45a.36.36 0 0 0-.361-.362H16.2V6.29h1.427zm-14.03 8.096V3.598h10.794v10.794z"
    />
    <path
      fill="currentColor"
      d="M12.232 5.397H5.758a.36.36 0 0 0-.361.36v6.478c0 .198.16.361.361.361h6.477a.36.36 0 0 0 .361-.361V5.758a.366.366 0 0 0-.364-.361"
    />
  </svg>
);
export default SvgMicroChip;
