import * as React from 'react';

import { CModal, CModalBody, CModalHeader } from '@coreui/react-pro';

import { AppointmentStatus } from 'types/AppointmentStatus';
import { AppointmentType } from 'types/AppointmentType';
import { Clinic } from 'types/Clinic';
import { Room } from 'types/Room';

import { NewAppointmentForm } from './NewAppointmentForm';

type Props = {
  appointmentTypes: AppointmentType[];
  appointmentStatuses: AppointmentStatus[];
  clinic: Clinic;
  hideModal: () => void;
  loading: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  selectedStart: Date;
  selectedEnd: Date;
  selectedResource: { id: string; title: string };
  rooms: Room[];
};

export const NewAppointmentModal = ({ hideModal, ...rest }: Props) => (
  <CModal className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
    <CModalHeader>
      <h4>New Appointment</h4>
    </CModalHeader>
    <CModalBody>
      <NewAppointmentForm hideModal={hideModal} {...rest} />
    </CModalBody>
  </CModal>
);
