import * as React from 'react';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { NewTaskButton } from 'views/employee_tasks/NewTaskButton';

import { createProcedure, fetchProceduresForConsult, updateProcedure } from 'api/Procedures';

import { Consult } from 'types/Consult';
import { Procedure } from 'types/Procedure';

import { usePoll } from 'hooks/usePoll';

import { compactDateTimeDisplay } from 'utils/dates';
import { procedureToOption } from 'utils/selectOptions';
import { statusAction } from 'utils/status';

import SvgPlus from 'assets/images/SvgPlus';

import { ActionsMenu } from 'components/ActionsMenu';
import { IconButton } from 'components/IconButton';
import { MedicalHistoryTableV2 } from 'components/MedicalHistoryTableV2';
import { Pill } from 'components/Pill';
import { TableAuditData } from 'components/TableAuditData';

import { ProcedureForm } from './ProcedureForm';

type Form = 'new-procedure' | 'edit-procedure';

type Props = {
  consult: Consult;
  scrollUp: () => void;
};

export const ProceduresStep = ({ consult, scrollUp }: Props) => {
  const [procedures, setProcedures] = useState<Procedure[]>([]);

  const [visibleForm, setVisibleForm] = useState<Form>();
  const [editingItem, setEditingItem] = useState<Procedure>();
  const [formKey, setFormKey] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(() => {
    fetchProceduresForConsult(consult.id, (procedures) => {
      if (procedures.length === 0) {
        setVisibleForm('new-procedure');
      }

      setProcedures(procedures);
    });
  }, [consult]);
  usePoll(fetch);

  const hideForm = () => {
    setVisibleForm(undefined);
    setEditingItem(undefined);
  };

  const remountForm = () => {
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleNewClick = () => {
    remountForm();
    scrollUp();
    setVisibleForm('new-procedure');
  };

  const handleEditClick = (item: Procedure) => () => {
    remountForm();
    scrollUp();
    setEditingItem(item);
    setVisibleForm('edit-procedure');
  };

  const handleUpdateSuccess = (message: string) => {
    hideForm();
    toast.success(message);
    fetch();

    setLoading(false);
  };

  const handleCreateSuccess = (procedure: Procedure) => {
    toast.success('Procedure created!');
    fetch();

    remountForm();
    setEditingItem(procedure);
    setVisibleForm('edit-procedure');

    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
  };

  const handleCreateProcedure = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const adminDate = formData.get('date_of_administration-date');
    const formJson = {
      consult_id: consult.id,
      ...Object.fromEntries(formData.entries()),
      date_of_administration: new Date(adminDate?.toString() || '').toString()
    };

    createProcedure(formJson, {
      onSuccess: handleCreateSuccess,
      onError: handleError
    });
  };

  const handleUpdateProcedure = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!editingItem) return;
    setLoading(true);

    const form = event.currentTarget;
    const formData = new FormData(form);
    const adminDate = formData.get('date_of_administration-date');
    const formJson = {
      ...Object.fromEntries(formData.entries()),
      date_of_administration: new Date(adminDate?.toString() || '').toString()
    };

    updateProcedure(editingItem.id, formJson, {
      onSuccess: () => handleUpdateSuccess('Procedure updated!'),
      onError: handleError
    });
  };

  const renderForm = (visibleForm?: Form) => {
    switch (visibleForm) {
      case 'new-procedure':
        return <ProcedureForm key={formKey} hideForm={hideForm} loading={loading} onSubmit={handleCreateProcedure} />;
      case 'edit-procedure':
        if (editingItem) {
          return (
            <ProcedureForm
              key={formKey}
              procedure={editingItem}
              hideForm={hideForm}
              loading={loading}
              onSubmit={handleUpdateProcedure}
            />
          );
        }
        break;
      default:
        return null;
    }
  };

  return (
    <MedicalHistoryTableV2
      name="Procedures"
      items={procedures}
      form={renderForm(visibleForm)}
      columns={[
        { key: 'updated', label: 'Updated' },
        { key: 'date_of_administration', label: 'Date of Admin' },
        { key: 'procedure_name' },
        { key: 'status' },
        { key: 'outcome' },
        { key: 'actions' }
      ]}
      scopedColumns={{
        updated: (item: Procedure) => <TableAuditData item={item} handleClick={handleEditClick(item)} />,
        status: (item: Procedure) => (
          <td>
            <Pill label={item.status} />
          </td>
        ),
        date_of_administration: (item: Procedure) => (
          <td>{item.date_of_administration && compactDateTimeDisplay(item.date_of_administration)}</td>
        ),
        procedure_name: (item: Procedure) => <td>{item.product.name}</td>,
        outcome: (item: Procedure) => <td dangerouslySetInnerHTML={{ __html: item.outcome }} />,
        actions: (item: Procedure) => (
          <td>
            <ActionsMenu
              items={[
                {
                  label: 'Edit',
                  onClick: handleEditClick(item)
                },
                statusAction({
                  item,
                  handleSuccess: handleUpdateSuccess,
                  handleError,
                  name: 'Procedure',
                  updater: updateProcedure
                })
              ]}
            />
          </td>
        )
      }}
      newButton={<IconButton icon={SvgPlus} onClick={handleNewClick} label="New Procedure" />}
      secondaryButton={
        <NewTaskButton
          buttonText="New Task"
          contextOptions={procedures.filter((procedure) => procedure.status !== 'disabled').map(procedureToOption)}
          contextType={
            procedures.filter((procedure) => procedure.status !== 'disabled').length > 0 ? 'Procedure' : undefined
          }
          consult_id={consult.id}
          animal_id={consult.animal_id}
          disablePresets
        />
      }
    />
  );
};
