import * as React from 'react';
import type { SVGProps } from 'react';

const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.48 14.667a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M6.328 8.153V7.04c0-1.387.98-1.953 2.18-1.26l.967.56.966.56c1.2.693 1.2 1.827 0 2.52l-.966.56-.967.56c-1.2.693-2.18.127-2.18-1.26z"
    />
  </svg>
);
export default SvgPlay;
