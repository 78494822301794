import { Animal } from './Animal';
import { Customer } from './Customer';
import { Employee } from './Employee';
import { InvoiceItem } from './InvoiceItem';
import { Payment } from './Payment';

export type Invoice = {
  active: boolean;
  animal?: Animal;
  animal_id?: number;
  animal_name?: string;
  appointment_id?: number | null;
  approved_by_employee?: Employee;
  approved_by_employee_id?: number;
  consult_id?: number | null;
  created_at: string;
  customer_id: number;
  customer: Customer;
  date_approved?: string;
  date_due?: string;
  discount_percentage: number;
  discount_fixed: number;
  fully_paid: boolean;
  id: number;
  invoice_items?: InvoiceItem[];
  invoice_items_attributes?: InvoiceItem[];
  name?: string;
  payments: Payment[];
  pim_code?: number;
  pim_consult_id?: number;
  pim_customer_reference_number?: number;
  pim_id?: number;
  pim_name?: string;
  subtotal: number;
  status: InvoiceStatus;
  tax: number;
  total: number;
  total_paid: number;
  transaction_date?: string;
  unpaid_total: number;
  updated_at: string;
  updated_by_employee?: Employee | null;
  urgent_care?: boolean;
};

export const invoiceStatuses = ['draft', 'approved', 'disabled'] as const;
export type InvoiceStatus = (typeof invoiceStatuses)[number];

export const statusColor = (status: InvoiceStatus) => {
  if (status === 'approved') {
    return 'success';
  } else if (status === 'draft') {
    return 'warning';
  } else if (status === 'disabled') {
    return 'danger';
  }
};
