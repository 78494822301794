import axios from 'axios';

import { Payment } from 'types/Payment';

const fetchPayments = (onSuccess: (payments: Payment[]) => void) => {
  const url = `/pim/payments/`;
  axios.get<Payment[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchPaymentsForAppointment = (appointmentId: string, onSuccess: (payments: Payment[]) => void) => {
  const url = `/pim/payments/?appointment_id=${appointmentId}`;
  axios.get<Payment[]>(url).then((data) => onSuccess(data.data.record));
};

const fetchPayment = (paymentId: string, onSuccess: (payment: Payment) => void) => {
  const url = `/pim/payments/${paymentId}`;
  axios.get<Payment>(url).then((data) => onSuccess(data.data.record));
};

export { fetchPayments, fetchPaymentsForAppointment, fetchPayment };
