import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { cilOptions, cilPencil } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CAlert,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner
} from '@coreui/react-pro';

import { fetchAppointmentType } from 'api/AppointmentTypes';

import { AppointmentType } from 'types/AppointmentType';

import { compactDateTimeDisplay } from 'utils/dates';

import { ColorSquare } from '../../components/ColorSquare';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [appointmentType, setAppointmentType] = React.useState<AppointmentType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchAppointmentType(id, loadingSuccess, loadingFailed);
  }, [id]);

  function loadingSuccess(appointmentType: AppointmentType) {
    setAppointmentType(appointmentType);
    setIsLoading(false);
  }

  function loadingFailed(_: string) {
    setIsLoading(false);
  }

  return (
    <>
      {isLoading && <CSpinner color="primary" />}
      {!isLoading && !appointmentType && <CAlert color="dark">No appointment type found</CAlert>}
      {appointmentType && (
        <CCard>
          <CCardHeader className="d-flex flex-row align-items-center justify-content-between">
            <strong style={{ fontSize: '30px' }}>{appointmentType.name_in_pim}</strong>

            <CDropdown alignment="end">
              <CDropdownToggle color="transparent" caret={false} className="p-0">
                <CIcon icon={cilOptions} className="text-high-emphasis" aria-label="options" />
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem
                  role="button"
                  className="align-items-center"
                  href={`/appointments/types/${appointmentType.id}/edit`}
                >
                  <CIcon icon={cilPencil} className="me-2 text-high-emphasis" aria-label="edit" />
                  Edit
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CCardHeader>
          <CCardBody>
            <CListGroup>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>ID</CCol>
                  <CCol>{appointmentType.id}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>PIM ID</CCol>
                  <CCol>{appointmentType.pim_id}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>PIM Name</CCol>
                  <CCol>{appointmentType.pim_name}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Name in PIM</CCol>
                  <CCol>{appointmentType.name_in_pim}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Color</CCol>
                  <CCol>
                    <ColorSquare color={appointmentType.color} />
                  </CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Deposit Amount</CCol>
                  <CCol>${appointmentType.deposit_amount / 100}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Duration</CCol>
                  <CCol>{appointmentType.duration} minutes</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Max Appointments/Day Per Vet</CCol>
                  <CCol>{appointmentType.max_appointments_per_day}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Minimum Lead Time (Hours)</CCol>
                  <CCol>{appointmentType.minimum_lead_time_in_hours}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Is Cancelable</CCol>
                  <CCol>{appointmentType.cancelable ? 'Yes' : 'No'}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Is Reschedulable</CCol>
                  <CCol>{appointmentType.reschedulable ? 'Yes' : 'No'}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Is Dropoff</CCol>
                  <CCol>{appointmentType.dropoff ? 'Yes' : 'No'}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Is Tech Appointment</CCol>
                  <CCol>{appointmentType.tech_appointment ? 'Yes' : 'No'}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Client Schedulable</CCol>
                  <CCol>{appointmentType.client_schedulable ? 'Yes' : 'No'}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Created On</CCol>
                  <CCol>{compactDateTimeDisplay(appointmentType.created_at)}</CCol>
                </CRow>
              </CListGroupItem>
              <CListGroupItem>
                <CRow>
                  <CCol sm={4}>Updated On</CCol>
                  <CCol>{compactDateTimeDisplay(appointmentType.updated_at)}</CCol>
                </CRow>
              </CListGroupItem>
            </CListGroup>
          </CCardBody>
        </CCard>
      )}
    </>
  );
};
export default Details;
