import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

import { CButton, CCol, CDatePicker, CForm, CFormTextarea, CLoadingButton, CRow } from '@coreui/react-pro';

import { createAnimalHealthPlan } from 'api/AnimalHealthPlans';
import { fetchHealthPlansBy } from 'api/HealthPlans';

import { Animal } from 'types/Animal';
import { HealthPlan } from 'types/HealthPlan';
import { Pagination } from 'types/Pagination';

type AddHealthPlanToAnimalFormProps = {
  animal: Animal;
  onSuccess: () => void;
  onCancel: () => void;
};

type Option = {
  value: string;
  label: string;
};

const AddHealthPlanToAnimal = ({ animal, onSuccess, onCancel }: AddHealthPlanToAnimalFormProps): JSX.Element => {
  const [pagination, setPagination] = useState<Pagination>({ page: 1, perPage: 25, total: 1 });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [selectedHealthPlan, setSelectedHealthPlan] = useState<Option>();
  const [nextDueDate, setNextDueDate] = useState<Date | null>();
  const [internalNotes, setInternalNotes] = useState<string>();
  const [patientNotes, setPatientNotes] = useState<string>();

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (selectedHealthPlan)
      createAnimalHealthPlan(
        {
          animal_id: animal.id,
          health_plan_id: Number(selectedHealthPlan?.value),
          next_due_date: nextDueDate?.toString(),
          internal_notes: internalNotes,
          patient_notes: patientNotes
        },
        {
          onSuccess: () => {
            toast.success('Health Plan successfully added to animal');
            onSuccess();
          },
          onError: (errorMessage: string) => {
            setIsSubmitting(false);
          }
        }
      );
  };

  const toOption = (healthPlan: HealthPlan): Option => ({
    value: String(healthPlan.id),
    label: healthPlan.name
  });

  const handlePlanSelect = (selected?: Option) => {
    if (selected && selected.value) {
      setSelectedHealthPlan(selected);
    }
  };

  return (
    <CForm onSubmit={submit}>
      <CRow className="mb-3">
        <CCol>
          <label htmlFor="health_plan" className="form-label">
            Health Plan
          </label>
          <AsyncSelect<Option>
            id="health_plan"
            aria-label="Product"
            onChange={(selected) => handlePlanSelect(selected as Option)}
            loadOptions={(inputValue, callback) => {
              fetchHealthPlansBy(
                { query: inputValue, status: 'active' },
                (healthPlan: HealthPlan[]) => {
                  callback(healthPlan.map(toOption));
                },
                setPagination,
                pagination.page
              );
            }}
            defaultOptions
            placeholder="Search for a health plan..."
            isSearchable
            required
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol>
          <CDatePicker
            label="Next Due Date"
            onDateChange={setNextDueDate}
            id="started"
            locale="en-US"
            date={nextDueDate}
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol>
          <CFormTextarea
            id="internalNotes"
            label="Internal Notes"
            value={internalNotes}
            onChange={(event) => setInternalNotes(event.target.value)}
            placeholder="Notes for internal use..."
            name="internal_notes"
            rows={3}
            text={
              <>
                <Link to="https://daringfireball.net/projects/markdown/">Markdown</Link> supported
              </>
            }
          />
        </CCol>
      </CRow>
      <CRow className="mb-3">
        <CCol>
          <CFormTextarea
            id="patientNotes"
            label="Client Communications"
            onChange={(event) => setPatientNotes(event.target.value)}
            value={patientNotes}
            name="patient_notes"
            rows={3}
            text={
              <>
                <Link to="https://daringfireball.net/projects/markdown/">Markdown</Link> supported
              </>
            }
          />
        </CCol>
      </CRow>

      <div className="d-grid gap-4 d-sm-flex justify-content-sm-end">
        <CButton color="secondary" disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </CButton>
        <CLoadingButton color="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Add Plan
        </CLoadingButton>
      </div>
    </CForm>
  );
};

export default AddHealthPlanToAnimal;
