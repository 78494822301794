import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HealthPlansTable from 'views/health_plans/HealthPlansTable';

import { cilCart, cilOptions, cilPencil } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CAlert,
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CListGroup,
  CListGroupItem,
  CRow,
  CSmartTable,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchHealthPlansBy } from 'api/HealthPlans';
import { fetchProductPrices } from 'api/ProductPrices';
import { fetchProduct } from 'api/Products';

import { HealthPlan } from 'types/HealthPlan';
import { Pagination } from 'types/Pagination';
import { Product } from 'types/Product';
import { ProductPrice } from 'types/ProductPrice';

import { compactDateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

const Details = (): JSX.Element => {
  type DetailsParams = {
    id: string;
  };
  const { id } = useParams<keyof DetailsParams>() as DetailsParams;
  const [product, setProduct] = useState<Product>();
  const [productPrices, setProductPrices] = useState<ProductPrice[]>();

  const [healthPlansTriggerList, setHealthTriggerPlans] = useState<HealthPlan[]>([]);
  const [healthPlansTriggerPagination, setHealthPlansTriggerPagination] = useState<Pagination>({
    page: 1,
    perPage: 25,
    total: 1
  });

  const [healthPlansFulfillList, setHealthFulfillPlans] = useState<HealthPlan[]>([]);
  const [healthPlansFulfillPagination, setHealthPlansFulfillPagination] = useState<Pagination>({
    page: 1,
    perPage: 25,
    total: 1
  });

  useEffect(() => {
    fetchProduct(id, setProduct);
    fetchProductPrices(id, setProductPrices);
    fetchHealthPlansBy(
      {
        trigger_type: 'Product',
        trigger_id: id
      },
      setHealthFulfillPlans,
      setHealthPlansFulfillPagination
    );
    fetchHealthPlansBy(
      {
        product_id: id
      },
      setHealthTriggerPlans,
      setHealthPlansTriggerPagination
    );
  }, [id]);

  return (
    <CCard>
      <CCardHeader className="d-flex flex-row align-items-center justify-content-between">
        <strong style={{ fontSize: '30px' }}>
          <CIcon className="me-2 icon-xl" icon={cilCart} aria-label="product icon" />
          Product Details
        </strong>

        <CDropdown alignment="end">
          <CDropdownToggle color="transparent" caret={false} className="p-0">
            <CIcon icon={cilOptions} aria-label="options" className="text-high-emphasis" />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem role="button" className="align-items-center" href={`/products/${product?.id}/edit`}>
              <CIcon icon={cilPencil} aria-label="edit" className="me-2 text-high-emphasis" />
              Edit
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CCardHeader>
      {product ? (
        <CCardBody>
          <CTable className="mb-5">
            <CTableHead color="secondary">
              <CTableRow>
                <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                <CTableHeaderCell scope="col">Display Name</CTableHeaderCell>
                <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                <CTableHeaderCell scope="col">Product Type</CTableHeaderCell>
                <CTableHeaderCell scope="col">ID</CTableHeaderCell>
                <CTableHeaderCell scope="col">Status</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              <CTableRow>
                <CTableDataCell>{product.name}</CTableDataCell>
                <CTableDataCell>{product.display_name}</CTableDataCell>
                <CTableDataCell>{product.description}</CTableDataCell>
                <CTableDataCell>{product.product_type}</CTableDataCell>
                <CTableDataCell>{product.id}</CTableDataCell>
                <CTableDataCell>
                  <CBadge color={product.status === 'active' ? 'success' : 'secondary'}>{product.status}</CBadge>
                </CTableDataCell>
              </CTableRow>
            </CTableBody>
          </CTable>

          <CRow className="mb-5">
            <CCol>
              <h5 className="mb-3">Administration Info</h5>
              <CListGroup>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Default Intructions</CCol>
                    <CCol>{product.default_instructions}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Dosage Form</CCol>
                    <CCol>{product.dosage_form}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Concentration</CCol>
                    <CCol>
                      {product.concentration} {product.concentration_unit}/{product.concentration_volume}
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Concentration Diluted</CCol>
                    <CCol>
                      {product.concentration_diluted} {product.concentration_diluted_unit}/
                      {product.concentration_diluted_volume}
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Lowest Dispensible Quantity</CCol>
                    <CCol>
                      {product.lowest_dispensable_quantity} {product.lowest_dispensable_unit}
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Default Dosage Calculation Method</CCol>
                    <CCol>{product.default_dosage_calculation_method}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Default Infusion Rate Per</CCol>
                    <CCol>{product.default_infusion_rate_per}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Default Route of Administration</CCol>
                    <CCol>{product.default_route_of_administration}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Is Constant Rate Infusion</CCol>
                    <CCol>{product.is_constant_rate_infusion ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Is Controlled</CCol>
                    <CCol>{product.controlled ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Is Preventative</CCol>
                    <CCol>{product.preventative ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Is Taken With Food</CCol>
                    <CCol>{product.taken_with_food ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
              </CListGroup>
            </CCol>
          </CRow>

          <CRow className="mb-5">
            <CCol sm={6}>
              <h5 className="mb-3">Misc Info</h5>
              <CListGroup>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Has Direct Cost</CCol>
                    <CCol>{product.has_direct_cost ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Is Container</CCol>
                    <CCol>{product.is_container ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Is Derived Price</CCol>
                    <CCol>{product.is_derived_price ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Is Purchased</CCol>
                    <CCol>{product.is_purchased ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Is Sold</CCol>
                    <CCol>{product.is_sold ? 'Yes' : 'No'}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Product Group Name / Description</CCol>
                    <CCol>
                      {!product.product_group?.name && !product.product_group?.description
                        ? 'None provided'
                        : product.product_group?.name + '/' + product.product_group?.description}
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Sheet Group Name / ID</CCol>
                    <CCol>
                      {!product.sheet_group && !product.sheet_group_name
                        ? 'None provided'
                        : product.sheet_group_name + '/' + product.sheet_group}
                    </CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Tracking Level</CCol>
                    <CCol>{product.tracking_level}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Warning</CCol>
                    <CCol>{product.warning}</CCol>
                  </CRow>
                </CListGroupItem>
              </CListGroup>
            </CCol>

            <CCol sm={6}>
              <h5 className="mb-3">Office Info</h5>
              <CListGroup>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Code</CCol>
                    <CCol>{product.code}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>PIM ID</CCol>
                    <CCol>{product.pim_id}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>PIM Name</CCol>
                    <CCol>{product.pim_name}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Created At</CCol>
                    <CCol>{compactDateTimeDisplay(product.created_at)}</CCol>
                  </CRow>
                </CListGroupItem>
                <CListGroupItem>
                  <CRow>
                    <CCol sm={4}>Updated At</CCol>
                    <CCol>{product.updated_at && compactDateTimeDisplay(product.updated_at)}</CCol>
                  </CRow>
                </CListGroupItem>
              </CListGroup>
            </CCol>
          </CRow>

          <CRow className="mb-5">
            <h5>Pricing</h5>
            <CSmartTable
              items={productPrices}
              columns={[
                'owner',
                'cost',
                'price',
                'dispense_fee',
                'markup_based_pricing',
                'markup_percentage',
                'status',
                'tax_rate'
              ]}
              scopedColumns={{
                owner: (price: ProductPrice) => {
                  return <td className="py-2">{price.owner?.name}</td>;
                },
                cost: (price: ProductPrice) => {
                  return <td className="py-2">{price.cost ? toCurrency(price.cost) : null}</td>;
                },
                price: (price: ProductPrice) => {
                  return <td className="py-2">{price.price ? toCurrency(price.price) : null}</td>;
                },
                dispense_fee: (price: ProductPrice) => {
                  return <td className="py-2">{price.dispense_fee ? toCurrency(price.dispense_fee) : null}</td>;
                },
                markup_based_pricing: (price: ProductPrice) => {
                  return (
                    <td className="py-2">{price.markup_based_pricing ? `Yes (${price.markup_percentage}%)` : 'No'}</td>
                  );
                },
                markup_percentage: (price: ProductPrice) => {
                  return <td className="py-2">{price.markup_percentage ? `${price.markup_percentage}%` : null}</td>;
                },
                status: (price: ProductPrice) => {
                  return (
                    <td className="py-2">
                      <CBadge
                        color={price.status === 'active' ? 'success' : 'secondary'}
                        shape="rounded-pill"
                        aria-label="price-status"
                      >
                        {price.status}
                      </CBadge>
                    </td>
                  );
                },
                tax_rate: (price: ProductPrice) => {
                  return <td className="py-2">{price.tax_rate ? `${price.tax_rate}%` : null}</td>;
                }
              }}
            ></CSmartTable>
          </CRow>

          <CRow className="mb-5">
            <h5>Can Trigger These Health Plans</h5>
            <HealthPlansTable
              healthPlansList={healthPlansTriggerList}
              pagination={healthPlansTriggerPagination}
              setPagination={setHealthPlansTriggerPagination}
            />
          </CRow>

          <CRow>
            <h5>Can Fulfill These Health Plans</h5>
            <HealthPlansTable
              healthPlansList={healthPlansFulfillList}
              pagination={healthPlansFulfillPagination}
              setPagination={setHealthPlansFulfillPagination}
            />
          </CRow>
        </CCardBody>
      ) : (
        <CAlert color="secondary">Product not found</CAlert>
      )}
    </CCard>
  );
};
export default Details;
