import { z } from 'zod';

const schema = z.object({
  REACT_APP_PORTAL_URL: z.string(),
  REACT_APP_ROLLBAR_ACCESS_TOKEN: z.string(),
  REACT_APP_TINYMCE_API_KEY: z.string(),
  REACT_APP_ALGOLIA_APP_ID: z.string(),
  REACT_APP_ALGOLIA_API_KEY: z.string()
});

const {
  REACT_APP_PORTAL_URL: portalUrl,
  REACT_APP_ROLLBAR_ACCESS_TOKEN: rollbarToken,
  REACT_APP_TINYMCE_API_KEY: tinyMCEApiKey,
  REACT_APP_ALGOLIA_APP_ID: algoliaAppId,
  REACT_APP_ALGOLIA_API_KEY: algoliaApiKey
} = schema.parse(process.env);

const algoliaCustomerIndex = process.env.NODE_ENV === 'production' ? 'production_drtreat' : 'staging_drtreat';
const algoliaAnimalIndex =
  process.env.NODE_ENV === 'production' ? 'production_animals_drtreat' : 'staging_animals_drtreat';

export {
  portalUrl,
  rollbarToken,
  tinyMCEApiKey,
  algoliaAppId,
  algoliaApiKey,
  algoliaCustomerIndex,
  algoliaAnimalIndex
};
