import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from '@hello-pangea/dnd';
import cn from 'classnames';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

import {
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react-pro';

import { fetchEstimateTemplate, fetchEstimateTemplates } from 'api/EstimateTemplates';
import { fetchCurrentPriceForProduct, ProductPricePayload } from 'api/ProductPrices';

import { Animal } from 'types/Animal';
import { Consult } from 'types/Consult';
import { EstimateTemplate } from 'types/EstimateTemplate';
import { Invoice, InvoiceStatus, invoiceStatuses } from 'types/Invoice';
import { InvoiceItem } from 'types/InvoiceItem';
import { Option } from 'types/Option';
import { ProductPrice } from 'types/ProductPrice';

import { useAuth } from 'hooks/useAuth';

import { isEnabled } from 'utils/filters';
import { toCurrency } from 'utils/price';
import { reactSelectStyles } from 'utils/reactSelect';
import { animalToOption, consultToOption, estimateTemplateToOption } from 'utils/selectOptions';

import SvgClipboard from 'assets/images/SvgClipboard';
import SvgLetter from 'assets/images/SvgLetter';
import SvgOutgoing from 'assets/images/SvgOutgoing';
import SvgPaw from 'assets/images/SvgPaw';
import SvgPercentageBordered from 'assets/images/SvgPercentageBordered';
import SvgPlus from 'assets/images/SvgPlus';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { FormAuditData } from 'components/FormAuditData';
import { IconButton } from 'components/IconButton';
import { IconLabel } from 'components/IconLabel';

import styles from './InvoiceForm.module.scss';

import { InvoiceItemForm } from './InvoiceItemForm';

type Props = {
  handleSubmit: () => Promise<void>;
  handleCancel: () => void;
  invoice: Partial<Invoice>;
  setInvoice: React.Dispatch<React.SetStateAction<Partial<Invoice> | undefined>>;
  isLoading?: boolean;
  consults?: Consult[];
  animals?: Animal[];
  animal_id?: number;
};

export const InvoiceForm = ({
  handleSubmit,
  handleCancel,
  invoice,
  setInvoice,
  isLoading = false,
  consults,
  animals,
  animal_id
}: Props): JSX.Element => {
  const auth = useAuth();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [isAddingTemplateItems, setIsAddingTemplateItems] = useState<boolean>(false);
  const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);

  const [isApplyingDiscount, setIsApplyingDiscount] = useState<boolean>(false);
  const [showDiscountConfirmation, setShowDiscountConfirmation] = useState<boolean>(false);
  const [overallDiscount, setOverallDiscount] = useState<number | undefined>(
    invoice?.discount_fixed ? invoice.discount_fixed / 100 : invoice?.discount_percentage || undefined
  );
  const [overallDiscountType, setOverallDiscountType] = useState<string>(
    invoice?.discount_fixed ? 'fixed' : 'percentage'
  );

  const [consultOptions, setConsultOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (consults) {
      if (animal_id) {
        setConsultOptions(consults.filter((consult) => consult.animal_id === animal_id).map(consultToOption));
      } else {
        setConsultOptions(consults.map(consultToOption));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Calculate the values when the invoice items change
  useEffect(() => {
    const calculate = async () => {
      if (invoice.invoice_items) {
        const { subtotal, tax, total } = await calculateValues(invoice.invoice_items);

        if (subtotal !== invoice.subtotal || tax !== invoice.tax || total !== invoice.total) {
          setInvoice({ ...invoice, subtotal, tax, total });
        }
      }
    };
    calculate();
  }, [invoice, setInvoice]);

  const toggleIsUrgentCare = async () => {
    if (invoice.invoice_items) {
      const items = await Promise.all(
        invoice.invoice_items.map(async (item) => {
          const currentPrice = await fetchCurrentPriceForProduct({
            productId: item.product_id?.toString(),
            urgent_care: !invoice.urgent_care,
            discount_fixed: item.discount_fixed,
            discount_percentage: item.discount_percentage,
            quantity: item.quantity
          } as ProductPricePayload);
          const invoiceItemWithPrice = {
            ...item,
            price: currentPrice.calculated_prices.selected.price,
            subtotal: currentPrice.calculated_prices.selected.subtotal,
            tax: currentPrice.calculated_prices.selected.tax,
            total: currentPrice.calculated_prices.selected.total
          } as InvoiceItem;
          return invoiceItemWithPrice;
        })
      );
      setInvoice({
        ...invoice,
        invoice_items_attributes: items,
        invoice_items: items,
        urgent_care: !invoice.urgent_care
      });
    } else {
      setInvoice({
        ...invoice,
        urgent_care: !invoice.urgent_care
      });
    }
  };

  const calculateValues = async (items: InvoiceItem[]) => {
    const activeItems = items.filter((item) => item.status !== 'disabled');

    const subtotal = activeItems.reduce((acc, item) => acc + (item.subtotal || 0), 0);
    const tax = activeItems.reduce((acc, item) => acc + (item.tax || 0), 0);
    const total = activeItems.reduce((acc, item) => acc + (item.total || 0), 0);

    return { subtotal, tax, total };
  };

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateInvoiceItems(invoice.invoice_items)) {
      await handleSubmit();
    }
  };

  const validateInvoiceItems = (invoiceItems: InvoiceItem[] | undefined) => {
    if (invoiceItems && invoiceItems.length > 0) {
      submitRef?.current?.setCustomValidity('');
      return true;
    } else {
      submitRef?.current?.setCustomValidity('Please add at least one item.');
      submitRef?.current?.reportValidity();
      return false;
    }
  };

  const clearInvoiceItemsValidation = () => submitRef?.current?.setCustomValidity('');

  const addPlaceholderInvoiceItem = () => {
    const items = invoice.invoice_items
      ? [...invoice.invoice_items, { key: Date.now() + invoice.invoice_items.length } as InvoiceItem]
      : [{ key: Date.now() + 1 } as InvoiceItem]; // Create new array with one item
    setInvoice({ ...invoice, invoice_items_attributes: items, invoice_items: items });
  };

  const handleInvoiceItemChange = async (invoiceItem: InvoiceItem, key: number | undefined) => {
    if (!invoice.invoice_items) return; //If there are no items, do nothing

    clearInvoiceItemsValidation();

    const items = await Promise.all(
      invoice.invoice_items.map(async (item) => {
        if (item.id ? item.id === invoiceItem.id : item.key === key) {
          const currentPrice = await fetchCurrentPriceForProduct({
            productId: invoiceItem.product_id?.toString(),
            urgent_care: invoice.urgent_care,
            discount_fixed: invoiceItem.discount_fixed,
            discount_percentage: invoiceItem?.discount_percentage,
            quantity: invoiceItem.quantity
          } as ProductPricePayload);
          const invoiceItemWithPrice = {
            ...invoiceItem,
            price: currentPrice.calculated_prices.selected.price,
            subtotal: currentPrice.calculated_prices.selected.subtotal,
            tax: currentPrice.calculated_prices.selected.tax,
            total: currentPrice.calculated_prices.selected.total
          } as InvoiceItem;
          return invoiceItemWithPrice;
        } else {
          return item;
        }
      })
    );
    setInvoice((prevInvoice) => ({ ...prevInvoice, invoice_items_attributes: items, invoice_items: items }));
  };

  const removeItem = (key: number | undefined, id: number | undefined) => {
    if (!invoice.invoice_items) return; //If there are no items, do nothing
    let items: InvoiceItem[];
    if (key) {
      items = invoice.invoice_items.filter((item) => item.key !== key);
    } else {
      items = invoice.invoice_items.map((item) => {
        let newItem = item;
        if (item.id === id) {
          newItem = { ...item, status: 'disabled' };
        }
        return newItem;
      });
    }
    setInvoice((prevInvoice) => ({ ...prevInvoice, invoice_items_attributes: items, invoice_items: items }));
  };

  const handleTemplateSelected = (value: Option | null) => {
    setIsAddingTemplateItems(true);
    if (value && value.value) fetchEstimateTemplate(value.value, addProductsFromTemplate);
  };

  const addProductsFromTemplate = async (template: EstimateTemplate) => {
    let items = invoice.invoice_items ? [...invoice.invoice_items] : [];
    if (items.length && items[0].product_id === undefined) items.shift();

    const skippedItems: InvoiceItem[] = [];
    const invoiceTemplateItems = template.estimate_template_items ?? [];
    const templateItems = await Promise.all(
      invoiceTemplateItems.map(async (templateItem, index, array) => {
        const alreadyExists = invoice.invoice_items?.find(
          (invoiceItem) => invoiceItem.product_id === templateItem.product_id
        );
        if (index === array.length - 1)
          setTimeout(() => {
            setIsAddingTemplateItems(false);
          }, 500);

        if (alreadyExists?.status === 'disabled') {
          // item had previously existed, so re-enable it and update the pricing according to template
          const currentPrice = await fetchCurrentPriceForProduct({
            productId: alreadyExists.product_id?.toString(),
            urgent_care: invoice.urgent_care,
            quantity: alreadyExists.quantity ?? 1,
            discount_fixed: alreadyExists.discount_fixed,
            discount_percentage: alreadyExists.discount_percentage
          } as ProductPricePayload);

          const calculatedPrice = currentPrice.calculated_prices;
          alreadyExists.status = 'approved';
          alreadyExists.quantity = templateItem.quantity;
          alreadyExists.discount_percentage = templateItem.discount_percentage;
          alreadyExists.discount_fixed = templateItem.discount_fixed;
          alreadyExists.price = calculatedPrice.selected.price;
          alreadyExists.tax = calculatedPrice.selected.tax;
          alreadyExists.total = calculatedPrice.selected.total;

          return alreadyExists;
        } else if (alreadyExists) {
          skippedItems.push(alreadyExists);
        }

        if (templateItem.product && templateItem.product.id) {
          const currentPrice = await fetchCurrentPriceForProduct({
            productId: templateItem.product.id.toString(),
            urgent_care: invoice.urgent_care,
            quantity: templateItem.quantity ?? 1,
            discount_percentage: templateItem.discount_percentage,
            discount_fixed: templateItem.discount_fixed
          } as ProductPricePayload);

          const calculatedPrice = currentPrice.calculated_prices;
          items.push({
            key: templateItem.product_id ? Date.now() + templateItem.product_id : Date.now(),
            product: templateItem.product,
            product_id: templateItem.product_id,
            quantity: templateItem.quantity,
            discount_percentage: templateItem.discount_percentage,
            discount_fixed: templateItem.discount_fixed,
            price: calculatedPrice.selected.price,
            subtotal: calculatedPrice.selected.subtotal,
            tax: calculatedPrice.selected.tax,
            total: calculatedPrice.selected.total
          } as InvoiceItem);
        }
      })
    );
    //Remove elements in skippedItems from items array
    items = items.filter((item) => !skippedItems.includes(item));

    //Combine template items with estimate items and remove all undefined
    items = [...items, ...templateItems].filter((item) => item !== undefined) as InvoiceItem[];

    const { subtotal, tax, total } = await calculateValues(items);
    setInvoice({ ...invoice, invoice_items_attributes: items, invoice_items: items, total, subtotal, tax });
    setShowTemplateModal(false);
    if (skippedItems.length > 0)
      toast.info(
        'These items were not added because they already exist in the invoice: ' +
          skippedItems.map((item) => item.product?.name).join(', ')
      );
  };

  const maybeApplyOverallDiscount = () => {
    if (!invoice.invoice_items) return;
    const atLeastOneItemHasDiscount = invoice.invoice_items.some(
      (item) => item.discount_percentage || item.discount_fixed
    );
    if (atLeastOneItemHasDiscount) setShowDiscountConfirmation(true);
    else applyOverallDiscount();
  };

  const applyOverallDiscount = async () => {
    if (!invoice.invoice_items) return;
    setIsApplyingDiscount(true);

    const items = await Promise.all(
      invoice.invoice_items.map(async (item) => {
        if (overallDiscountType === 'percentage') {
          item.discount_percentage = overallDiscount;
          item.discount_fixed = 0;
        } else if (overallDiscountType === 'fixed') {
          item.discount_percentage = 0;
          item.discount_fixed = overallDiscount;
        }

        const onSuccess: (productPrice: ProductPrice) => void = (productPrice) => {
          const calculatedPrice = productPrice.calculated_prices;
          item.price = calculatedPrice.selected.price;
          item.subtotal = calculatedPrice.selected.subtotal;
          item.tax = calculatedPrice.selected.tax;
          item.total = calculatedPrice.selected.total;
        };

        const currentPrice = await fetchCurrentPriceForProduct({
          productId: item.product_id?.toString(),
          urgent_care: invoice.urgent_care,
          quantity: item.quantity ?? 1,
          discount_percentage: item.discount_percentage,
          discount_fixed: item.discount_fixed
        } as ProductPricePayload);
        onSuccess(currentPrice);
        return item;
      })
    );

    const { subtotal, tax, total } = await calculateValues(items);

    setInvoice({
      ...invoice,
      invoice_items_attributes: items,
      invoice_items: items,
      total,
      subtotal,
      tax,
      discount_percentage: overallDiscountType === 'percentage' ? overallDiscount : undefined,
      discount_fixed: overallDiscountType === 'fixed' ? overallDiscount : undefined
    });

    setIsApplyingDiscount(false);
    setShowDiscountConfirmation(false);
  };

  const handleConsultChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setInvoice({
      ...invoice,
      consult_id: event.target.value ? Number(event.target.value) : undefined,
      animal_id: event.target.value
        ? consults?.find((consult) => consult.id === Number(event.target.value))?.animal_id
        : undefined
    });
  };

  const handleAnimalChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (consults) {
      const filteredConsults = consults
        .filter((consult) => consult.animal_id === Number(event.target.value))
        .map(consultToOption);

      setConsultOptions(filteredConsults);
    }
    setInvoice({ ...invoice, animal_id: event.target.value ? Number(event.target.value) : undefined });
  };

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) return;

    const items = Array.from(invoice.invoice_items ?? []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setInvoice({ ...invoice, invoice_items_attributes: items, invoice_items: items });
  };

  return (
    <CForm className={cn('mb-4', styles.form)} onSubmit={submit}>
      <h2 className="mb-3 text-capitalize">{invoice.fully_paid ? 'View' : invoice.id ? 'Edit' : 'New'} Invoice</h2>

      <div>
        <CRow className="mb-3">
          <CCol className="mb-3" xs={6}>
            <div className="d-flex align-items-center justify-content-between">
              <CFormLabel htmlFor="name">
                <IconLabel icon={SvgLetter} label="Name" />
              </CFormLabel>
              <div className={styles.required}>Required</div>
            </div>
            <CFormInput
              type="text"
              id="name"
              value={invoice.name ?? ''}
              autoFocus={!invoice.name}
              required
              onChange={(event) => setInvoice({ ...invoice, name: event.target.value })}
              disabled={invoice.fully_paid}
            />
          </CCol>

          {animals && animals.length > 1 && (
            <CCol xs={6}>
              <div className="d-flex align-items-center justify-content-between">
                <CFormLabel htmlFor="animal">
                  <IconLabel icon={SvgPaw} label="Animal" />
                </CFormLabel>
              </div>
              <CFormSelect
                id="animal"
                value={invoice.animal_id ?? undefined}
                options={[{ label: '', value: '' }, ...animals.map(animalToOption)]}
                onChange={handleAnimalChange}
                disabled={invoice.fully_paid}
              />
            </CCol>
          )}
          {consultOptions && consultOptions.length > 0 && (
            <CCol>
              <div className="d-flex align-items-center justify-content-between">
                <CFormLabel htmlFor="consult">
                  <IconLabel icon={SvgClipboard} label="Consult" />
                </CFormLabel>
              </div>
              <CFormSelect
                id="consult"
                value={invoice.consult_id ?? undefined}
                options={[{ label: '', value: '' }, ...consultOptions]}
                onChange={handleConsultChange}
                disabled={invoice.fully_paid}
              />
            </CCol>
          )}

          <CCol>
            <div className="d-flex align-items-center justify-content-between">
              <CFormLabel htmlFor="status">
                <IconLabel icon={SvgClipboard} label="Status" />
              </CFormLabel>
            </div>
            <CFormSelect
              type="text"
              id="status"
              value={invoice.status}
              options={[...invoiceStatuses]}
              className="text-capitalize"
              onChange={(event) => setInvoice({ ...invoice, status: event.target.value as InvoiceStatus })}
              disabled={invoice.fully_paid}
            />
          </CCol>
        </CRow>

        <CTable hover align="middle" className={styles.table}>
          <CTableHead color="dark">
            <CTableRow>
              <CTableHeaderCell style={{ width: '3%' }} />
              <CTableHeaderCell style={{ width: '40%' }}>Item</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '10%' }}>Qty</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '10%' }}>Price</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '20%' }}>Discount</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '10%' }}>Total</CTableHeaderCell>
              <CTableHeaderCell style={{ width: '7%' }}>Actions</CTableHeaderCell>
            </CTableRow>
          </CTableHead>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(dropProvided, dropSnapshot) => (
                <CTableBody ref={dropProvided.innerRef} className={styles.droppable} {...dropProvided.droppableProps}>
                  {invoice.invoice_items?.filter(isEnabled).map((item, index) => {
                    const key = item.id ? item.id : item.key;
                    return (
                      <Draggable
                        key={key}
                        draggableId={key?.toString() ?? ''}
                        index={index}
                        isDragDisabled={invoice.fully_paid}
                      >
                        {(dragProvided, dropSnapshot) => (
                          <InvoiceItemForm
                            index={index}
                            key={key}
                            invoiceItem={item}
                            draggableProvided={dragProvided}
                            handleChange={handleInvoiceItemChange}
                            removeItem={removeItem}
                            isDragging={dropSnapshot.isDragging}
                            isOrgAdmin={auth?.isOrganizationAdmin()}
                            ref={dragProvided.innerRef}
                            isDisabled={invoice.fully_paid || false}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {dropProvided.placeholder}
                </CTableBody>
              )}
            </Droppable>
          </DragDropContext>
        </CTable>

        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex align-items-center">
            {!invoice.fully_paid && <IconButton icon={SvgPlus} label="Add Item" onClick={addPlaceholderInvoiceItem} />}
            {!invoice.fully_paid && (
              <IconButton
                icon={SvgPlus}
                label="Add From Template"
                className="ms-2"
                onClick={() => setShowTemplateModal(true)}
                disabled={isAddingTemplateItems}
              />
            )}
          </div>

          <div style={{ width: 370 }} className="d-flex flex-column gap-3">
            {!invoice.fully_paid && (
              <CFormCheck
                id="is-urgent-care"
                label="Urgent Care?"
                onChange={toggleIsUrgentCare}
                checked={invoice.urgent_care}
              />
            )}

            {auth?.isOrganizationAdmin() && !invoice.fully_paid && (
              <div className="d-flex justify-content-between align-items-end">
                <div className="flex-grow-1">
                  <CFormLabel htmlFor="discount">
                    <IconLabel icon={SvgPercentageBordered} label="Discount All Items" />
                  </CFormLabel>
                  <CInputGroup>
                    <CFormInput
                      type="number"
                      className="w-auto"
                      id="discount"
                      aria-label="Discount"
                      max={overallDiscountType === 'percentage' ? 100 : undefined}
                      min={0}
                      step={0.01}
                      value={overallDiscount}
                      onChange={(event) => setOverallDiscount(parseFloat(event.target.value))}
                    />
                    <CFormSelect
                      id="Discount Type"
                      name="discount_type"
                      aria-label="Discount type"
                      options={[
                        { value: 'percentage', label: '%' },
                        { value: 'fixed', label: '$' }
                      ]}
                      onChange={(event) => setOverallDiscountType(event.target.value)}
                      value={overallDiscountType}
                    />
                  </CInputGroup>
                </div>

                <IconButton
                  icon={SvgOutgoing}
                  onClick={maybeApplyOverallDiscount}
                  label="Apply"
                  className="ms-2"
                  disabled={isApplyingDiscount}
                />
              </div>
            )}

            <div className="d-flex align-items-center justify-content-between">
              <div>Subtotal</div>
              <div>{invoice.subtotal && toCurrency(invoice.subtotal)}</div>
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <div>Tax</div>
              <div>{invoice.tax && toCurrency(invoice.tax)}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div>Total</div>
              <div>{invoice.total && toCurrency(invoice.total)}</div>
            </div>
          </div>
        </div>

        <div className={'d-flex align-items-center justify-content-between mt-4'}>
          {invoice && <FormAuditData item={invoice} />}
          <div className={cn('ms-auto d-flex', styles.buttons)}>
            <CLoadingButton
              className={styles.button}
              color="primary"
              shape="rounded-pill"
              type="submit"
              disabled={isLoading}
              loading={isLoading}
              ref={submitRef}
              hidden={invoice.fully_paid}
            >
              {invoice.id ? 'Update' : 'Create'}
            </CLoadingButton>
            <CButton
              className={styles.button}
              color="primary"
              shape="rounded-pill"
              variant="outline"
              disabled={isLoading}
              onClick={handleCancel}
            >
              Close
            </CButton>
          </div>
        </div>
      </div>

      <CModal focus={false} backdrop="static" visible={showTemplateModal} onClose={() => setShowTemplateModal(false)}>
        <CModalHeader>
          <h4>Add From Template</h4>
        </CModalHeader>
        <CModalBody>
          <AsyncSelect<Option>
            aria-label="Add From Template"
            placeholder="Type to search..."
            onChange={(value) => handleTemplateSelected(value)}
            loadingMessage={(input: { inputValue: string }) => 'Loading...'}
            autoFocus
            defaultOptions
            styles={reactSelectStyles}
            loadOptions={(inputValue, callback) => {
              fetchEstimateTemplates(inputValue, (templates: EstimateTemplate[]) => {
                callback(templates.map(estimateTemplateToOption));
              });
            }}
            isSearchable
            required
          />
        </CModalBody>
      </CModal>

      <ConfirmationModal
        isVisible={showDiscountConfirmation}
        onClose={() => setShowDiscountConfirmation(false)}
        onConfirm={applyOverallDiscount}
        confirmButtonLabel={'Yes, apply'}
        modalHeader={'Apply discount to all items?'}
        modalBody={'This will overwrite the discount on all items in the invoice.'}
      />
    </CForm>
  );
};
