import * as React from 'react';

import { CModal, CModalBody } from '@coreui/react-pro';

import { AnimalHealthPlanForm } from './AnimalHealthPlanForm';

type Props = {
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
};

export const NewAnimalHealthPlanModal = ({ hideModal, onSubmit, loading }: Props) => {
  return (
    <CModal backdrop="static" className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
      <CModalBody>
        <AnimalHealthPlanForm onSubmit={onSubmit} hideModal={hideModal} loading={loading} />
      </CModalBody>
    </CModal>
  );
};
