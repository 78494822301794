import { Animal } from './Animal';
import { Employee } from './Employee';
import { EstimateItem } from './EstimateItem';

export type Estimate = {
  id: number;
  client_name?: string;
  client_id?: number;
  consult_id?: number | null;
  created_at: string;
  created_by_employee_id: number;
  created_by_employee: Employee;
  discount_percentage: number;
  discount_fixed: number;
  document_id?: number;
  phone?: string;
  effective_date?: string;
  name?: string;
  animal?: Animal;
  animal_id?: number;
  tax?: number;
  subtotal?: number;
  total?: number;
  total_low?: number;
  total_high?: number;
  status: EstimateStatus;
  updated_at: string;
  updated_by_employee_id: number;
  updated_by_employee?: Employee | null;
  urgent_care?: boolean;
  estimate_items?: EstimateItem[];
  estimate_items_attributes?: EstimateItem[];
};

export const estimateStatuses = ['pending', 'approved', 'rejected', 'released'] as const;
export type EstimateStatus = (typeof estimateStatuses)[number];
export type EstimateItemStatus = 'pending' | 'approved' | 'rejected' | 'disabled';

export const statusColor = (status: string) => {
  return { approved: 'success', pending: 'warning', rejected: 'danger', released: 'info' }[status.toLowerCase()];
};
