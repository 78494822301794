import { Animal } from 'types/Animal';
import { AnimalHealthPlan } from 'types/AnimalHealthPlan';
import { AnimalMasterProblem } from 'types/AnimalMasterProblem';
import { AppointmentStatus } from 'types/AppointmentStatus';
import { AppointmentType } from 'types/AppointmentType';
import { Assessment } from 'types/Assessment';
import { AttachmentType } from 'types/AttachmentType';
import { Breed } from 'types/Breed';
import { Clinic } from 'types/Clinic';
import { Consult } from 'types/Consult';
import { ConsultPlan } from 'types/ConsultPlan';
import { ConsultPresentingProblem } from 'types/ConsultPresentingProblem';
import { ConsultTemplate } from 'types/ConsultTemplate';
import { Context } from 'types/Context';
import { Customer } from 'types/Customer';
import { Diagnostic } from 'types/Diagnostic';
import { DiagnosticRequest } from 'types/DiagnosticRequest';
import { Employee } from 'types/Employee';
import { Estimate } from 'types/Estimate';
import { EstimateTemplate } from 'types/EstimateTemplate';
import { HealthPlan } from 'types/HealthPlan';
import { HealthStatus } from 'types/HealthStatus';
import { History } from 'types/History';
import { Invoice } from 'types/Invoice';
import { MasterProblem } from 'types/MasterProblem';
import { Option } from 'types/Option';
import { Organization } from 'types/Organization';
import { PetSex } from 'types/PetSex';
import { PetSpecies } from 'types/PetSpecies';
import { PhysicalExam } from 'types/PhysicalExam';
import { PimResource } from 'types/PimResource';
import { PrescriptionItem } from 'types/PrescriptionItem';
import { Procedure } from 'types/Procedure';
import { Product } from 'types/Product';
import { Room } from 'types/Room';
import { Vaccination } from 'types/Vaccination';

import { identity } from 'utils/identity';

import { compactDateDisplay, compactDateTimeDisplay } from './dates';

// Functions that return an Option type with value and label for inputs, including Core UI's CFormSelect component.
// https://coreui.io/react/docs/forms/select/
type Optionable =
  | Animal
  | AttachmentType
  | Breed
  | Clinic
  | ConsultTemplate
  | Diagnostic
  | Estimate
  | HealthPlan
  | Invoice
  | MasterProblem
  | PetSex
  | PetSpecies
  | Product
  | Room;

export type DescribableOption = Option & { description?: string };
export type OptionWithTopic = Option & { topic: string };

// This is the general function for most types.
const toOption = (item: Optionable): Option => ({
  value: String(item.id),
  label: item.name ?? ''
});

const buildLabel = (parts: (string | undefined)[]): string => parts.filter(identity).join(' - ');

// When the item doesn't have a `name` and `id`, add a function here.
const assessmentToOption = (item: Assessment): Option => ({
  value: String(item.id),
  label: buildLabel([compactDateTimeDisplay(item.created_at), item.created_by_employee?.full_name_with_title])
});

const animalHealthPlanToOption = (plan: AnimalHealthPlan): Option => ({
  value: String(plan.id),
  label: `${plan.health_plan.name} ${plan.status === 'disabled' ? ' (disabled)' : ''}`
});

const animalMasterProblemToOption = (problem: AnimalMasterProblem): Option => ({
  value: String(problem.id),
  label: `${problem.master_problem.name} ${problem.status === 'disabled' ? ' (disabled)' : ''}`
});

const animalToOption = (animal: Animal): Option => ({
  value: String(animal.id),
  label: `"${animal.name}" ${animal.customer?.last_name ?? ''} - ${animal.id}`
});

const appointmentStatusToOption = (appointmentStatus: AppointmentStatus) => appointmentStatus.name;

const appointmentTypeToOption = (appointmentType: AppointmentType): Option => ({
  value: String(appointmentType.id),
  label: appointmentType.name_in_pim
});

const customerToOption = (customer: Customer): Option => ({
  value: String(customer.id),
  label: `${customer.first_name} ${customer.last_name} - ${customer.id}`
});

const consultToOption = (consult: Consult): Option => ({
  value: String(consult.id),
  label: [consult.animal.name, compactDateDisplay(consult.date)].join(' - ')
});

const consultPlanToOption = (plan: ConsultPlan): Option => ({
  value: String(plan.id),
  label: `${compactDateTimeDisplay(plan.created_at)}`
});

const consultPresentingProblemToOption = (problem: ConsultPresentingProblem): Option => ({
  value: String(problem.id),
  label: `${problem.presenting_problem.name} ${problem.status === 'disabled' ? ' (disabled)' : ''}`
});

const diagnosticRequestToOption = (diagnosticRequest: DiagnosticRequest): Option => ({
  value: String(diagnosticRequest.id),
  label: `${diagnosticRequest.reference_number} - ${compactDateTimeDisplay(diagnosticRequest.created_at)}`
});

const employeeToOption = (employee: Employee): Option => ({
  value: String(employee.id),
  label: employee.full_name_with_title
});

const healthStatusToOption = (status: HealthStatus): Option => ({
  value: String(status.id),
  label: `${compactDateTimeDisplay(status.created_at)}`
});

const historyToOption = (history: History): Option => ({
  value: String(history.id),
  label: `${compactDateTimeDisplay(history.created_at)}`
});

const pimResourceToOption = (pimResource: PimResource): Option => ({
  value: String(pimResource.id),
  label: pimResource.display_name
});

const productCodeToOption = (product: Product): Option => ({
  value: String(product.code),
  label: product.name || ''
});

const estimateTemplateToOption = (template: EstimateTemplate): Option => ({
  value: String(template.id),
  label: template.name || ''
});

const physicalExamToOption = (item: PhysicalExam): Option => ({
  value: String(item.id),
  label: buildLabel([compactDateTimeDisplay(item.created_at), item.created_by_employee?.full_name_with_title])
});

const prescriptionItemToOption = (item: PrescriptionItem): Option => ({
  value: String(item.id),
  label: `${item.product?.name} ${item.status === 'disabled' ? ' (disabled)' : item.current ? '' : ' (not taking)'}`
});

const procedureToOption = (procedure: Procedure): Option => ({
  value: String(procedure.id),
  label: `${procedure.product.name} ${procedure.status === 'disabled' ? ' (disabled)' : ''}`
});

const productToOption = (product: Product): DescribableOption => ({
  value: String(product.id),
  label: product.name,
  description: product.description
});

const contextToOption = (context: Context): OptionWithTopic => ({
  value: `${context.context_type}-${context.context_id}`,
  label: context.context_display_name,
  topic: context.topic
});

const vaccinationToOption = (vaccination: Vaccination): Option => ({
  value: String(vaccination.id),
  label: `${vaccination.product.name} - ${compactDateDisplay(vaccination.date_of_administration)} ${
    vaccination.status === 'disabled' ? ' (disabled)' : ''
  }`
});

const ownerToOption = (
  owner: Organization | Clinic,
  ownerType: 'Clinic' | 'Organization',
  isDisabled: boolean
): Option => {
  return {
    value: `${owner.id}_${ownerType}`,
    label: owner.name,
    disabled: isDisabled
  };
};

const stringToFractionOption = (item: string): Option => ({ value: item, label: `/ ${item}` });

export {
  assessmentToOption,
  animalHealthPlanToOption,
  animalMasterProblemToOption,
  animalToOption,
  appointmentStatusToOption,
  appointmentTypeToOption,
  consultToOption,
  consultPlanToOption,
  consultPresentingProblemToOption,
  contextToOption,
  customerToOption,
  diagnosticRequestToOption,
  employeeToOption,
  healthStatusToOption,
  historyToOption,
  ownerToOption,
  physicalExamToOption,
  pimResourceToOption,
  prescriptionItemToOption,
  procedureToOption,
  productCodeToOption,
  estimateTemplateToOption,
  productToOption,
  vaccinationToOption,
  stringToFractionOption,
  toOption
};
