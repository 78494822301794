import * as React from 'react';

import { CModal, CModalBody, CModalHeader } from '@coreui/react-pro';

import { DispenseRecord } from 'types/DispenseRecord';

import { DispenseLogsForm } from './DispenseLogsForm';

type Props = {
  hideModal: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  dispenseRecord: DispenseRecord;
};

export const NewDispenseLogsModal = ({ hideModal, ...rest }: Props) => (
  <CModal className="show d-block" visible size="lg" fullscreen="sm" onClose={hideModal}>
    <CModalHeader>
      <h4>New Dispense Logs</h4>
    </CModalHeader>
    <CModalBody>
      <DispenseLogsForm hideModal={hideModal} {...rest} />
    </CModalBody>
  </CModal>
);
