import { DatesSetArg, EventClickArg, EventContentArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import luxon2Plugin from '@fullcalendar/luxon2';
import FullCalendar from '@fullcalendar/react';
import { ResourceLabelContentArg } from '@fullcalendar/resource';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import cn from 'classnames';
import { utcToZonedTime } from 'date-fns-tz';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { CButton, CCloseButton, CContainer, CSidebar, CSidebarHeader } from '@coreui/react-pro';

import { fetchClinic } from 'api/Clinics';
import { fetchHospitalSheetsBy } from 'api/HospitalSheet';

import { Animal } from 'types/Animal';
import { Clinic } from 'types/Clinic';
import { HospitalEvent } from 'types/HospitalEvent';
import { HospitalEventSchedule } from 'types/HospitalEventSchedule';
import { HospitalSheet } from 'types/HospitalSheet';

import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { usePoll } from 'hooks/usePoll';

import { ClinicContext } from 'contexts/ClinicContext';

import { timeDisplay } from 'utils/dates';

import { Pill } from 'components/Pill';

import styles from './WhiteboardStyles.module.scss';

import { ResourceOverviewCell } from './ResourceOverviewCell';

const WhiteboardOverview = (): JSX.Element => {
  type Resource = {
    id: string;
    title: string;
    animal: Animal;
    hospitalSheet: HospitalSheet;
  };

  type Event = {
    id: string;
    title: string;
    backgroundColor: string;
    start: string;
    end: string;
    resourceId: string;
    display?: string;
    tasks?: HospitalEvent[];
  };

  const [currentClinic, setCurrentClinic] = useState<Clinic | undefined>(undefined);
  const [events, setEvents] = useState<Event[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);
  const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);
  const [sidebarEvents, setSidebarEvents] = useState<HospitalEvent[] | undefined>();
  const [sidebarResource, setSidebarResource] = useState<Resource | undefined>();

  const calendarRef = useRef<FullCalendar>(null);

  useDocumentTitle('Whiteboard');

  const { clinicContext } = useContext(ClinicContext);
  useEffect(() => {
    if (clinicContext) fetchClinic(Number(clinicContext), setCurrentClinic);
  }, [clinicContext]);

  useEffect(() => {
    if (!selectedDay && currentClinic) {
      const today = DateTime.local({ zone: currentClinic.time_zone }).startOf('day').toJSDate();
      setSelectedDay(today.toDateString());
    }

    if (selectedDay && currentClinic) {
      const dtSelectedDay = DateTime.fromFormat(selectedDay, 'EEE MMM dd yyyy', { zone: currentClinic.time_zone });
      refetchHospitalSheets(dtSelectedDay);
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(dtSelectedDay.toJSDate());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, currentClinic]);

  const refetchHospitalSheets = (date?: DateTime) => {
    if (!currentClinic) return;
    const dateToFetch = date
      ? date
      : selectedDay
      ? DateTime.fromFormat(selectedDay, 'EEE MMM dd yyyy', { zone: currentClinic.time_zone })
      : DateTime.now();

    if (dateToFetch) {
      const startOfDay = utcToZonedTime(dateToFetch.startOf('day').toJSDate(), currentClinic.time_zone).toISOString();
      const endOfDay = utcToZonedTime(dateToFetch.endOf('day').toJSDate(), currentClinic.time_zone).toISOString();

      const hospitalEvents: Event[] = [];
      fetchHospitalSheetsBy({ start_time: startOfDay, end_time: endOfDay }, (hospitalSheets) => {
        setResources(hospitalSheets.map(hospitalSheetToResource));

        hospitalSheets.forEach((hospitalSheet) => {
          const aggregatedTasks = aggregateTasksByHour(hospitalSheet.hospital_event_schedules);
          hospitalEvents.push(...aggregatedTasks);
        });
        setEvents(hospitalEvents);
      });

      setTimeout(() => {
        const calendar = calendarRef.current;
        if (calendar) {
          calendar.getApi().updateSize();
        }
      }, 500);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetch = useCallback(refetchHospitalSheets, [currentClinic, selectedDay]);
  usePoll(fetch);

  const hospitalSheetToResource = (hospitalSheet: HospitalSheet): Resource => {
    return {
      id: hospitalSheet.id.toString(),
      title: hospitalSheet.animal.name || '',
      animal: hospitalSheet.animal,
      hospitalSheet: hospitalSheet
    };
  };

  const aggregateTasksByHour = (schedule: HospitalEventSchedule[]) => {
    const tasksByHour: Event[] = [];

    schedule.forEach((schedule) => {
      schedule.hospital_events.forEach((event) => {
        const startDate = new Date(event.start_time);
        const startHour = new Date(new Date(event.start_time).setMinutes(0, 0, 0));
        const endHour = new Date(startHour.getTime() + 60 * 60 * 1000); // One hour later
        const hourKey = `T${startDate.getHours()}:`;
        const resourceId = schedule.whiteboard_sheet_id.toString();

        const existingHourItem = tasksByHour.find((item) => {
          const itemStartDate = new Date(item.start);
          return itemStartDate.getHours() === startDate.getHours() && item.resourceId === resourceId;
        });

        if (existingHourItem) {
          existingHourItem.tasks?.push({ ...event, hospital_event_schedule: schedule });
          const count = existingHourItem.tasks?.length;
          existingHourItem.title = count?.toString() || '';
        } else {
          tasksByHour.push({
            start: startHour.toISOString(),
            end: endHour.toISOString(),
            resourceId: resourceId,
            tasks: [{ ...event, hospital_event_schedule: schedule }],
            title: '1',
            id: `${resourceId}-${hourKey}`,
            backgroundColor: '#FFFFFF'
          });
        }
      });
    });

    return tasksByHour;
  };

  const handleDatesSet = (dateInfo: DatesSetArg) => {
    setSelectedDay(dateInfo.start.toDateString());
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    const events: HospitalEvent[] = clickInfo.event._def.extendedProps.tasks;
    setSidebarEvents(events.sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime()));
    const resource = resources.find((r) => r.id === events[0].hospital_event_schedule?.whiteboard_sheet_id.toString());
    setSidebarResource(resource);
  };

  const getStatus = (event: HospitalEvent) => {
    const timeStarted = new Date() >= new Date(event.start_time);
    const pastTime = new Date() >= new Date(event.end_time);

    if (event.completed_at) return 'completed';
    if (timeStarted && !pastTime && !event.completed_at) return 'due_now';
    if (pastTime && !event.completed_at) return 'overdue';
    return 'not due yet';
  };

  const renderResourceCell = (resource: ResourceLabelContentArg) => {
    const animal = resource.resource._resource.extendedProps.animal as Animal;
    const hospitalSheet = resource.resource._resource.extendedProps.hospitalSheet as HospitalSheet;
    if (!animal) return null;
    return <ResourceOverviewCell key={hospitalSheet.id} animal={animal} hospitalSheet={hospitalSheet} />;
  };

  const renderEventCell = (e: EventContentArg) => {
    const event = e.event._def;
    const tasks = event.extendedProps.tasks;
    const completeTasks = tasks?.filter((task: HospitalEvent) => task.completed_at !== null).length;
    const pastTime = new Date() >= new Date(tasks[0].start_time);

    return (
      <div
        className={cn(styles.eventCell, {
          [styles.cellWarning]: pastTime && completeTasks < tasks.length,
          [styles.cellSuccess]: completeTasks === tasks.length
        })}
      >
        {completeTasks}/{tasks.length} tasks complete
      </div>
    );
  };

  return (
    <>
      <CContainer className={cn('mw-100', styles.whiteboard)}>
        <FullCalendar
          schedulerLicenseKey="0956875066-fcs-1680552874"
          plugins={[interactionPlugin, resourceTimelinePlugin, luxon2Plugin]}
          ref={calendarRef}
          initialView="resourceTimeline"
          events={events}
          resources={resources}
          titleFormat={{ month: 'long', year: 'numeric', day: 'numeric', weekday: 'long' }}
          nowIndicator={true}
          navLinks={true}
          eventBorderColor={'#FFFFFF'}
          eventMinHeight={100}
          eventContent={renderEventCell}
          height={'auto'}
          initialDate={selectedDay}
          datesSet={handleDatesSet}
          timeZone={currentClinic?.time_zone}
          slotDuration={'01:00:00'}
          slotMinTime={'08:00:00'}
          slotMaxTime={'19:00:00'}
          businessHours={{
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            startTime: '08:00',
            endTime: '19:00'
          }}
          eventClick={handleEventClick}
          resourceAreaWidth="25%"
          resourceAreaHeaderContent="Patients"
          resourceLabelContent={renderResourceCell}
        />
      </CContainer>

      {sidebarEvents && sidebarEvents.length > 0 && sidebarResource && (
        <CSidebar
          colorScheme="light"
          overlaid
          placement="end"
          size="xl"
          visible={true}
          style={{ overflowY: 'scroll', marginRight: '0' }}
        >
          <CSidebarHeader className="bg-transparent">
            <h5 className="text-center">
              Events due this hour for {sidebarResource.animal.name}
              <CCloseButton className="float-end" onClick={() => setSidebarEvents(undefined)} />
            </h5>
          </CSidebarHeader>

          <div className="d-flex flex-column">
            {sidebarEvents.map((event) => (
              <div key={event.id} className="d-flex justify-content-between align-items-center p-3 border-bottom">
                <div>
                  <h6>{event.hospital_event_schedule?.title}</h6>
                  <p className="m-0">{timeDisplay(event.start_time)}</p>
                </div>
                <Pill label={getStatus(event)} />
              </div>
            ))}
          </div>
          <CButton color="link" href={`/hospital_sheet/${sidebarResource.id}`}>
            View {sidebarResource.animal.name}&apos;s hospital sheet
          </CButton>
        </CSidebar>
      )}
    </>
  );
};

export default WhiteboardOverview;
