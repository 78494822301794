import axios, { AxiosError } from 'axios';

import { AnimalMasterProblem } from 'types/AnimalMasterProblem';

import { ApiResponse } from './ResponseTypes';

const fetchAnimalMasterProblemsByAnimal = (
  animalId: string | number,
  onSuccess: (animalMasterProblem: AnimalMasterProblem[]) => void
) => {
  const url = `/pim/animal_master_problems?animal_id=${animalId}`;
  axios.get<AnimalMasterProblem[]>(url).then((data) => onSuccess(data.data.record));
};

const createAnimalMasterProblem = (
  animalMasterProblem: Partial<AnimalMasterProblem>,
  {
    onSuccess,
    onError
  }: { onSuccess: (animalMasterProblem: AnimalMasterProblem) => void; onError: (error: string) => void }
) => {
  const url = `/pim/animal_master_problems`;

  axios
    .post<AnimalMasterProblem>(url, { animal_master_problem: animalMasterProblem })
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<AnimalMasterProblem>>) => onError(error.message));
};

const updateAnimalMasterProblem = (
  animalMasterProblemId: number,
  params: Partial<AnimalMasterProblem>,
  {
    onSuccess,
    onError
  }: { onSuccess: (animalMasterProblem: AnimalMasterProblem) => void; onError: (error: string) => void }
) => {
  const url = `/pim/animal_master_problems/${animalMasterProblemId}`;
  axios
    .put<Partial<AnimalMasterProblem>, AnimalMasterProblem>(url, params)
    .then((data) => onSuccess(data.data.record))
    .catch((error: AxiosError<ApiResponse<AnimalMasterProblem>>) => onError(error.message));
};

export { fetchAnimalMasterProblemsByAnimal, updateAnimalMasterProblem, createAnimalMasterProblem };
