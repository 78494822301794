import * as React from 'react';
import type { SVGProps } from 'react';

const SvgConsult = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8 11.125h.006M9.875 1.75H4.25A1.25 1.25 0 0 0 3 3v10a1.25 1.25 0 0 0 1.25 1.25h7.5A1.25 1.25 0 0 0 13 13V4.875z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.188 6.125a1.875 1.875 0 0 1 3.637.625C9.825 8 7.95 8.625 7.95 8.625"
    />
  </svg>
);
export default SvgConsult;
