import * as React from 'react';
import { Link } from 'react-router-dom';

import { CButton, CSmartTable } from '@coreui/react-pro';

import { Customer } from 'types/Customer';
import { Invoice } from 'types/Invoice';

import { compactDateTimeDisplay } from 'utils/dates';
import { toCurrency } from 'utils/price';

import { PaymentInvoiceModal } from 'components/PaymentInvoiceModal';
import { Pill } from 'components/Pill';

type Props = {
  invoices: Invoice[];
  customer: Customer;
  onInvoicePaid: () => void;
};

export const CheckoutInvoices: React.FC<Props> = ({ invoices, customer, onInvoicePaid }) => {
  const [invoiceToPay, setInvoiceToPay] = React.useState<Invoice | null>(null);

  const paymentSuccessful = (invoice: Invoice) => {
    setInvoiceToPay(null);
    onInvoicePaid();
  };

  return (
    <>
      <CSmartTable
        tableProps={{ 'aria-label': 'Invoices' }}
        tableHeadProps={{ color: 'dark' }}
        items={invoices}
        columns={[
          { key: 'created_at', label: 'Effective Date' },
          'status',
          'name',
          'total',
          { key: 'total_paid', label: 'Amount Paid' },
          { key: 'unpaid_total', label: 'Amount Due' },
          'actions'
        ]}
        scopedColumns={{
          created_at: (item: Invoice) => (
            <td>
              <Link to={`/invoices/${item.id}`}>{item.created_at && compactDateTimeDisplay(item.created_at)}</Link>
            </td>
          ),
          total: (item: Invoice) => <td>{toCurrency(item.total)}</td>,
          total_paid: (item: Invoice) => <td>{toCurrency(item.total_paid)}</td>,
          unpaid_total: (item: Invoice) => <td>{toCurrency(item.unpaid_total)}</td>,
          status: (item: Invoice) => (
            <td>
              <Pill label={item.status} />
            </td>
          ),
          actions: (item: Invoice) => (
            <td>
              {!item.fully_paid && (
                <CButton size="sm" disabled={item.status !== 'approved'} onClick={() => setInvoiceToPay(item)}>
                  Pay Now
                </CButton>
              )}
            </td>
          )
        }}
      />

      {invoiceToPay && (
        <PaymentInvoiceModal
          invoice={invoiceToPay}
          customer={customer}
          isVisible={!!invoiceToPay}
          onClose={() => setInvoiceToPay(null)}
          onConfirm={paymentSuccessful}
        />
      )}
    </>
  );
};
